<template>
  <div class="modal-mask">
  <div class="modal-wrapper">
  <div class="modal-container">
  <div class="modal-header">
    <h3>Make Take Over</h3>
    <button class="close-button" @click="closeModal">X</button>
  </div>
  <div class="modal-body">
    <form @submit.prevent="submitForm">
      <table class="itemTable">
        <tr style="border: 1px solid rgb(156, 156, 156);">
          <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>                        
          <td style="text-align: left; padding: 5px 5px;">
            <input class="dateInput" type="text" v-model="searchDate.year" />
            <input class="dateInput" type="text" v-model="searchDate.month" />
            <input class="dateInput" type="text" v-model="searchDate.day" />
          </td>
          <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Factory</td> 
          <td style="text-align: left; padding: 5px 5px;">
            <select class="itemInput" v-model="formInputs.factory">
              <option>1</option><option>2</option>
            </select>              
          </td>
          <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Shift</td> 
          <td style="text-align: left; padding: 5px 5px;">
            <select class="itemInput" v-model="formInputs.shift">
              <option>1</option><option>2</option>
            </select>              
          </td>
        </tr>
        <tr>
          <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Line</td>         
          <td style="text-align: left; padding: 5px 5px;">
            <select class="itemInput" v-model="formInputs.line">
              <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
            </select>              
          </td>
          <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Position</td> 
          <td style="text-align: left; padding: 5px 5px;">
            <select class="itemInput" v-model="formInputs.position">
              <option>Leader</option><option>Technician</option>
            </select>              
          </td>        
          <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Writer</td>
          <td style="text-align: left; padding: 5px 5px;">
            <input class="itemInput" type="text" v-model="formInputs.writer" />
          </td>
        </tr>
      </table>
      <br>             
      <table class="itemTable"> <!--Contents Table-->
        <tr style="border: 1px solid rgb(156, 156, 156);">
          <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Contents</td>
          <td style="border: 0"><textarea id="contents" v-model="formInputs.contents" rows="20" cols="120"></textarea></td>
        </tr>
      </table>
      <br> 
      <table class="itemTable"> <!--Remark Table-->
        <tr style="border: 1px solid rgb(156, 156, 156);">
          <td style="background-color: #eaeaea; border: 0; padding: 0px 9px;">Remark</td>
          <td style="border: 0"><input class="titleInput" type="text" v-model="formInputs.remark" /></td>                  
        </tr>
      </table>
      <br>          
      <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button><br>
      {{ saveStatus }}        
    </form>
  </div>        
  </div>      
  </div>
  </div>
</template>
  
<script>   
  import { reactive, ref,  } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, fetchLine } from '../utils.js';
   
  export default {   
    setup(props, { emit }) {       
      const dbTableName = "takeover";      
      const saveStatus = ref('');      
      const isProcessing = ref(false);
      const uniqueLines = ref([]);              
      const formInputs = reactive({ factory: "",  shift: "", position: "", writer: "", line: "", contents: "", remark: "", });
 
      const searchDate = reactive({ ...getCurrentDate()  });        
      
      const submitForm = async () => {

        isProcessing.value = true;
        saveStatus.value = 'Processing...';   

          const columnMapping = {            
            year: 'year',
            month: 'month',
            day: 'day',
            factory: "factory",
            shift: "shift",        
            position : "position",
            writer: "writer",
            line : "line",      
            contents : "contents",        
            remark : "remark",
            confirm: "confirm",    
            engconfirm: "engconfirm",  
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,              
              factory: formInputs.factory,
              shift: formInputs.shift,
              position: formInputs.position,
              writer: formInputs.writer,
              line: formInputs.line,
              contents: formInputs.contents,                        
              remark: formInputs.remark,
              confirm: "Not Yet",
              engconfirm: "Not Yet",
            }
         };
         
         try {
            await axios.post('/api/post', formData); 
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
      };

      const closeModal = () => { emit("onClose"); };

      const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();

    return { saveStatus, isProcessing, formInputs, searchDate, uniqueLines,
             submitForm, closeModal, dbTableName, 
    };
  },     

  props: { onSubmit: { type: Function,  required: true, },}, 
};
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 1200px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 350px; } 
  .costInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .subDateInput { width: 50px; size: 8px; margin-left: 3px; margin-right: 3px; }  
  .titleInput { width: 660px; margin-left: 10px; }  
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 30px; padding: 0px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }
</style>