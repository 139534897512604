<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Attendance Process</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="modalTable">
              <tr>
                <td>
            <div class="form-group">   
              <label class="modalLabel">Line:</label>    
              <select class="modalSelect" id="line" v-model="formInputs.line">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
              </select>   
            </div>
            <div class="form-group">
              <label class="modalLabel">Process:</label>
              <select class="modalSelect" id="process" v-model="formInputs.process">
                <option v-for="process in uniqueProcess" :key="process">{{ process }}</option><option></option>        
              </select> 
            </div>
            <div class="form-group">
              <label class="modalLabel">Process2:</label>
              <input class="modalInput" type="text" id="process2" v-model="formInputs.process2" />
            </div>
            <div class="form-group">
              <label class="modalLabel">Remark:</label>
              <input class="modalInput" type="text" id="remark" v-model="formInputs.remark" />
            </div>
            <div class="form-group">
              <label class="modalLabel">Row No:</label>
              <input class="modalInput" type="text" id="row_no" v-model="formInputs.row_no" />
            </div>
          </td>          
            </tr>
            </table>
            <button type="submit" class="saveButton" >Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { ref } from 'vue';  
  import axios from 'axios';   
  import { fetchData, getColumnMapping , makeFormData, useFormInputs } from "../../utils.js"; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "attendancefetch"      
        const uniqueLines = ref([]);
        const uniqueProcess = ref([]);           
        
        const { formInputs, dataForm } = useFormInputs();
        
        const submitForm = async () => {          

          const columnMapping = getColumnMapping(undefined, formInputs);  
          const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => { emit("onClose");};

        const loadLines = async () => {
          const lines = await fetchData('machinedata', 'line');
          const excludedLines = ["2nd Factory Compressors", "2nd Factory Boilers","2nd Factory Dust collectors",
            "2nd Factory AHU","2nd Factory Transformer","1st Factory Compressor 1",
            "1st Factory Compressor 2","1st Factory Boiler 1","1st Factory Boiler 2",
            "1st Factory Dust collector 1","1st Factory Dust collector 2","1st Factory Dust collector 3",
            "1st Factory Dust collector 4","1st Factory NEW AHU","1st Factory OLD AHU",
            "1st Factory AHU SMT","1st Factory Transformer",
          ];
          uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
          uniqueLines.value.sort((a, b) => a.localeCompare(b));
        }; loadLines(); 

        const loadPorcess = async () => {
          uniqueProcess.value = await fetchData('selectoption', 'process');          
        }; loadPorcess();   
  
      return { dbTableName, formInputs, dataForm, submitForm, closeModal, uniqueLines, uniqueProcess };

    },     
    props: { onSubmit: { type: Function, required: true, }, }, 
};
  
  </script>
    
    <style scoped>    
    * { margin: 0; padding: 0; box-sizing: border-box; }
      
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding: 20px 20px;
      display: flex; justify-content: flex-start; align-items: flex-start;
    }
    
    .modal-wrapper { width: 100%;  max-width: 500px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
    .modalTable { table-layout: fixed; width: 100%; }  
    .modalTable td { width: 20%; vertical-align: top; padding: 0 15px; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    .modalLabel { font-weight: bold; }
  
    .modalInput { width: 100%; font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
    .modalSelect { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    .saveButton {
      background-color: #4CAF50; color: white; padding: 5px 20px; border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px; display: inline-block; transition-duration: 0.4s;
    }  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  </style>