<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Wrtie Peel Tensile Test Result</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="dateTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>                        
                <td>
                  <input class="dateInput" type="text" v-model="formInputs.year" />
                  <input class="dateInput" type="text" v-model="formInputs.month" />
                  <input class="dateInput" type="text" v-model="formInputs.day" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Shift</td> 
                <td>
                  <select class="itemInput" v-model="formInputs.shift">
                    <option>A</option><option>B</option><option>C</option>
                  </select>              
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Project</td> 
                <td>
                  <select class="itemInput" v-model="formInputs.project">
                    <option>EVO</option><option>EV2020</option>
                  </select>              
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Model</td> 
                <td>
                  <select id="station" v-model="formInputs.model">
                    <option v-for="option in modelOptions" :key="option">{{ option }}</option>
                  </select>             
                </td>       
              </tr>
            </table><br>

            <table class="dateTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Operator</td> 
                <td>
                  <input class="nameInput" tpye="text" v-model="formInputs.operator" />                    
                </td>                
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Leader</td> 
                <td>
                  <input class="nameInput" tpye="text" v-model="formInputs.leader" />                    
                </td>                
              </tr>
            </table><br>

            <table class="barcodeTable"> <!--Time Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Peel Barcode</td>                  
                <td>
                  <input class="barcodeInput" type="text" v-model="formInputs.barcode_peel"/>                                      
                </td>                
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Tensile Barcode</td>                  
                <td>
                  <input class="barcodeInput" type="text" v-model="formInputs.barcode_tensile"/>                                      
                </td>                
              </tr>
            </table><br>            

            <table class="itemTable"> <!--Class Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: rgb(145, 188, 252); border: 0; padding: 5px 5px;" colspan="2">Peel Test</td>
                <td style="background-color: rgb(242, 255, 187); border: 0; padding: 5px 5px;" colspan="2">Tensile Test</td>
              </tr>
              <tr v-for="(peel, index) in formInputs.peels" :key="`peel-${index}`">
                <td :style="{ backgroundColor: '#eaeaea' }">Peel {{ index + 1 }}</td>
                <td><input class="resultInput" type="text" v-model="peel.value" /></td>
                <td v-if="index < formInputs.tensiles.length" :style="{ backgroundColor: '#eaeaea' }">Tensile {{ index + 1 }}</td>
                <td v-if="index < formInputs.tensiles.length"><input class="resultInput" type="text" v-model="formInputs.tensiles[index].value" /></td>
                <td v-else colspan="2"></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Peel<br>Result</td>                  
                <td>
                  <input class="resultInput" :class="{'ok-background': formInputs.ok_peel === 'OK', 'ng-background': formInputs.ok_peel === 'NG'}" type="text" v-model="formInputs.ok_peel" />
                </td>  
                 <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Tensile<br>Result</td>                  
                <td>
                  <input class="resultInput" :class="{'ok-background': formInputs.ok_tensile === 'OK', 'ng-background': formInputs.ok_tensile === 'NG'}" type="text" v-model="formInputs.ok_tensile" />
                </td>                            
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;" colspan="2">Total Result</td>                  
                <td colspan="2" style="text-align:left">
                  <input class="resultInput" :class="{'ok-background': formInputs.result === 'OK', 'ng-background': formInputs.result === 'NG'}" type="text" v-model="formInputs.result" />                    
                </td>                
              </tr>
            </table><br>            
          
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}          
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>  
import { reactive, ref, watch  } from 'vue'; 
import axios from 'axios';   
import { updateModel } from './model'; 
import { getColumnMapping, makeFormData, } from '../utils.js';
  
export default {                
  setup(props, { emit }) {

      const saveStatus = ref(''); 
      const isProcessing = ref(false);   
      const modelOptions = ref([]); 
      const dbTableName = "qaspcpeeltensile"           
      const isDataFetched = ref(false);

      const formInputs = reactive({
        id: "",
        year: "",
        month: "",
        day: "",
        shift: "",
        project: "",
        model: "",
        operator: "",
        leader: "",
        barcode_peel: "",
        barcode_tensile: "",
        peels: Array.from({ length: 12 }, () => ({ value: '' })), // 최대 12개의 peel 입력을 위한 객체 배열
        tensiles: Array.from({ length: 12 }, () => ({ value: '' })), // 최대 12개의 tensile 입력을 위한 객체 배열
        ok_peel: "",
        ok_tensile: "",
        result: "",
      });
  
      const submitForm = async () => {   

        isProcessing.value = true; 
        saveStatus.value = 'Processing...';          

        formInputs.peels.forEach((peel, index) => {
          formInputs[`peel${index + 1}`] = peel.value;
        });

        formInputs.tensiles.forEach((tensile, index) => {
          formInputs[`tensile${index + 1}`] = tensile.value;
        });
        
        delete formInputs.peels
        delete formInputs.tensiles
        const columnMapping = getColumnMapping(undefined, formInputs, true);          
        delete columnMapping.peels
        delete columnMapping.tensiles
        const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping, true); 
        const id = formInputs.id;

        console.log("formData", formData);
        
        if (!id) { alert("ID is missing"); return; }

        try {
          await axios.put(`/api/materialput/${id}`, formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        } finally {
          isProcessing.value = false; 
        }
        
        closeModal();
      };    

      const closeModal = () => {  emit("onClose");  };    

     const fetchBasicData = (rowData) => {        
        formInputs.id = rowData.id;
        formInputs.year = rowData.year;
        formInputs.month = rowData.month;
        formInputs.day = rowData.day;
        formInputs.shift = rowData.shift;
        formInputs.project = rowData.project;
        formInputs.model = rowData.model;
        formInputs.operator = rowData.operator;
        formInputs.leader = rowData.leader;
        formInputs.barcode_peel = rowData.barcode_peel;
        formInputs.barcode_tensile = rowData.barcode_tensile;
      };

      const initializePeelsAndTensiles = (rowData) => {        
        formInputs.peels = Array.from({ length: 12 }, (_, i) => {
            const value = rowData[`peel${i + 1}`];
            return value !== null && value !== '' ? { value: value } : null;
        }).filter(item => item !== null); 
        
        formInputs.tensiles = Array.from({ length: 12 }, (_, i) => {
            const value = rowData[`tensile${i + 1}`];
            return value !== null && value !== '' ? { value: value } : null;
        }).filter(item => item !== null);
      };

      const fetchSelectedRowData = async () => {
        try {
            const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
            });
            const rowData = response.data[0];            
     
            fetchBasicData(rowData);                   
            initializePeelsAndTensiles(rowData);            

        } catch (error) {
            console.error("Error fetching row data:", error.message);
        }
      };

      watch(() => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true });

      watch(() => formInputs.project, (newValue) => {
        updateModel(newValue, formInputs.model, modelOptions, formInputs);
        if (!isDataFetched.value) {
          fetchSelectedRowData();
          isDataFetched.value = true;
        }
      });

      watch(() => formInputs.model, (newValue) => {
        updateModel(formInputs.project, newValue, modelOptions, formInputs);       
      });

      watch(
        () => formInputs.peels?.map(peel => peel.value) || [],
        (peelValues) => {
          // 모든 peel 값 중에서 값이 존재하고, 5보다 작은 값이 있는지 확인
          if (peelValues.some(peel => peel === '' || peel === null || Number(peel) < 5)) {
            formInputs.ok_peel = 'NG';
          } else {
            formInputs.ok_peel = 'OK';
          }
        }
      );

      watch(
        () => formInputs.tensiles?.map(tensile => tensile.value) || [],
        (tensileValues) => {
          if (tensileValues.some(tensile => tensile === '' || tensile === null || Number(tensile) < 5)) {
            formInputs.ok_tensile = 'NG';
          } else {
            formInputs.ok_tensile = 'OK';
          }
        }
      );


      watch(
        () => [formInputs.ok_peel, formInputs.ok_tensile ],
        ([ok_peel, ok_tensile]) => {
          if (ok_peel === "NG" || ok_tensile === "NG" || !ok_peel || !ok_tensile ) {
            formInputs.result = 'NG';
          } else {
            formInputs.result = 'OK';
          }
        }
      );  
        
      return { dbTableName, formInputs, submitForm, closeModal, updateModel, modelOptions,          
                saveStatus, isProcessing,                               
      }; 
  },     

  props: { rowId: { type: Number, required: true } },
};

</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}     
.modal-wrapper { width: 100%;  max-width: 800px; }  
.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}  
.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
.modal-header h3 { font-size: 1.2rem; }
.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

label { font-weight: bold; }
input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

.readWindow[readonly] { background-color: #f0f0f0; width: 80px !important; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s;
}

button[type="submit"]:hover { background-color: #45a049; }

.fileUploadButton {
  background-color: #c6c6c6; color: rgb(0, 0, 0);
  padding: 5px 5px; margin-top: 10px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
  display: inline-block; transition-duration: 0.4s;
}
.fileUploadButton:hover { background-color: #5f5f5f; }  
.itemInput { margin-left: 5px; margin-right: 5px; } 

.dateTable { width: 700px; }
.dateTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
.dateTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
.nameInput { margin-left: 5px; margin-right: 5px; width: 150px; } 
.barcodeTable { width: 500px; }
.barcodeTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
.barcodeTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
.barcodeInput { margin-left: 5px; margin-right: 5px; width: 400px; } 
.itemTable { width: 700px; }
.itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
.itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
.resultTable { width: 200px; }
.resultTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }  

.resultInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
.qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
.unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
.payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
.dateInput { width: 50px; size: 8px; margin-left: 10px; }        
  
.disabled { background-color: #ccc; cursor: not-allowed; }    
.ok-background { background-color: rgb(0, 158, 0); font-weight: bold; color: white; text-align: center; margin-left: 5px; margin-right: 5px; width: 80px; } 
.ng-background { background-color: rgb(196, 3, 3); font-weight: bold; color: white; text-align: center; margin-left: 5px; margin-right: 5px; width: 80px; } 
.peelTest {background-color: rgb(145, 188, 252);}
.tensileTest {background-color: rgb(242, 255, 187);}
</style>