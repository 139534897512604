<template>
  <div class="checkTarget">       
  <h class="pagetitle">Master Sample List</h>
    <div class="horizontal-form">
      <div>       
        <input id="year" v-model="formInputs.year" placeholder="Year" />              
      </div>
      <div>          
        <select id="line" v-model="formInputs.line">
          <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
        </select>   
      </div>
      <div>
        <button class="searchButton" type="submit" @click="callSearchData">Search</button>
        <button class="addButton" @click="showModal = true">+ Add</button>         
        <button class="excelDownload" @click="downExcel">Down Load</button>
      </div>        
    </div> 
    <table class="mainTable">
      <tr>          
        <th v-for="(header, index) in target_headers" :key="index" v-html="header" class="sticky-header"></th>          
      </tr>          
      <tr v-for="row in item" :key="row.id"> 
        <td>{{ row.day }}-{{ row.month }}-{{ row.year }}</td>
        <td>{{ row.line }}</td>
        <td>{{ row.partname }}</td>
        <td>{{ row.partnumber }}</td>
        <td>{{ row.process }}</td>
        <td>{{ row.type }}</td>
        <td class="content-cell">{{ row.checkngtype }}</td>
        <td>{{ row.code }}</td>
        <td>{{ row.expireday }}-{{ row.expiremonth }}-{{ row.expireyear }}</td>        
        <td class="fileaddress-cell">{{  getFileName(row.filepath) }}<br>
            <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
        </td>
        <td><button type="submit" @click="openSendModal(row)" class="mobutton">Modify</button></td>
        <td><button :class="['delButton', { disabledButton: !canClick() }]" :disabled="!canClick()" type="submit" @click="delRow(row)">DEL</button></td>
      </tr>            
    </table> 
   
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>  
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                   
  </div>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import Modal from "./masterSampleModal.vue";
import modifyModal from "./masterSampleMoModal.vue";
import axios from "axios";
import { deleteRow, downloadExcel, } from "../../utils.js"; 

export default {         
    components: { Modal, modifyModal },        
    methods: {      
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },   
      downExcel() { downloadExcel(this.item, "masterSample.xlsx"); },      
      delRow(row) {
        if (row.filepath) { alert('First file delete and try again'); }
        else { deleteRow(this.targetTableName, row.id, this.callSearchData); }
      },
      canClick() {    
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;}
        else { return false; }
      },
      downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }
        window.location.href = filepath;
      },      
      getFileName(filePath) {
        if (!filePath) return ''; const parts = filePath.split('-');
        if (parts.length > 1) { return parts.slice(1).join('-'); }
        return filePath;
      },
    },
    setup() {   
      const targetTableName = "mastersample";
      const target_headers = ['Make Date', 'Line', 'Part Name', 'Part Number',
                               'Process','Type', 'Check NG Type','Code', 'Expire Date','Label', 'Modify', 'DEL'      
      ];
      const uniqueLines = ref([]); 
      const targetList = reactive({ data: [], })
      const formInputs = reactive({ year: "", line: "" })
      const showModal = ref(false);    
      const showMoModal = ref(false);      

      const searchData = async () => {     
        const formData = {
          tableName: targetTableName,
          year: formInputs.year,
          line: formInputs.line,
        };        

        axios.get('/api/targetList', { params: formData })
          .then((res) => {
            console.log("Response from /api/target:", res.data);
            targetList.data = res.data; 
          })
          .catch((error) => {
            console.error("Error fetching target data:", error.message);
          });
        };

      const item = computed(() => { return targetList.data.map(d => { return { ...d, }; }); });   

      const fetchLine = () => {
          const tableName = 'mastersample';                   
          axios
            .get('/api/where2', { params: { table_name: tableName } })
            .then((res) => {
              if (res.data && res.data.length > 0) {
                const lines = res.data.map(item => item.line);
                uniqueLines.value = [...new Set(lines)].sort((a, b) => a.localeCompare(b));                
              } 
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };   

        fetchLine();
      
      const callSearchData = () => { searchData(targetTableName); };            
          
      return { item, target_headers, targetTableName, formInputs, callSearchData, uniqueLines, showModal, showMoModal  };        
    }
  }
</script>

<style scoped>
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .parent { margin-left: 10px; display: flex; justify-content: space-between; }
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .searchTd { border: 0px !important;} 
  input { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; margin-bottom: 10px; } 
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; margin-bottom: 10px; } 
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 20px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; margin-bottom: 10px;
  }
  .addButton {
      padding: 5px 10px; background-color: #3498db; color: #fff; border: none; border-radius: 4px;
      font-size: 14px; font-weight: 600; margin-right: 30px; margin-bottom: 10px; cursor: pointer; transition: background-color 0.3s;
  }
  .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
  }  
  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }   
  .mobutton {
    background-color: #4CAF50; color: rgb(255, 255, 255); padding: 4px 12px; margin: 4px 2px;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold;
  }

  .disabledButton { background-color: gray; cursor: not-allowed; }
  .sticky-header {
    position: sticky; top: 0; background-color: white; 
    z-index: 10; background-color: rgb(230, 230, 230) ;  border : 1px solid black;        
    text-align : center;  padding-left : 10px;  padding-right : 10px;  font-size : 12px; 
  }

  .mainTable { margin-left: 10px; font-size: 14px; width: 90%; overflow-x: auto; white-space: nowrap; border-collapse: collapse;}
  .mainTable th, .mainTable td { border: 1px solid #828282;  padding: 8px; text-align: center; }  
  .mainTable td.content-cell { max-width: 600px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
  .horizontal-form { margin-top: 10px; margin-bottom: 10px; margin-left: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }     
  .fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
 
</style>