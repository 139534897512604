<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>SMT Lasder Parameter</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>            
              <td>
              <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div>
              </div>
              </td> 
              <td>
              <div class="form-group">
                <label for="start_hour">Time</label>
                <div class="time-inputs">
                    <label for="start_hour">H:</label>
                    <input type="number" id="start_hour" v-model="timeHour" min="0" max="23" />
                    <label for="start_minute">M:</label>
                    <input type="number" id="start_minute" v-model="timeMinute" min="0" max="59" />
                </div>
              </div> 
              </td>
              <td>
              <div class="form-group">
                <label for="line">Line:</label>
                <select id="line" v-model="formInputs.line">
                  <option>TR2 SMT(1st factory)</option><option>TR3 SMT(2nd factory)</option>
                </select>              
              </div>
              </td>
              <td> 
               <div class="form-group">
                <label for="technician">Technician:</label>
                <input id="technician" v-model="formInputs.technician" />                  
              </div>
              </td>
            </tr>
          </table>
          <hr class="lineUnderRemark"><br>
          <table>
            <tr>              
            <td>
              <div class="form-group">              
              <P class="laserNumber">Laser 1</P>
              <label>Fuse Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs11.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs11.timesec" />
                </div>
              <label>Fuse Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs12.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs12.timesec" />
                </div>
              </div><br>
              <div class="form-group">              
              <label>Thermistor Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs13.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs13.timesec" />
                </div>
              <label>Thermistor Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs14.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs14.timesec" />
                </div>
              </div>                
              </td>
              <td>
              <div class="form-group">              
              <P class="laserNumber">Laser 2</P>
              <label>Fuse Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs21.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs21.timesec" />
                </div>
              <label>Fuse Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs22.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs22.timesec" />
                </div>
              </div><br>
              <div class="form-group">              
              <label>Thermistor Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs23.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs23.timesec" />
                </div>
              <label>Thermistor Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs24.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs24.timesec" />
                </div>
              </div>                
              </td>
              <td>
              <div class="form-group">              
              <P class="laserNumber">Laser 3</P>
              <label>Fuse Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs31.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs31.timesec" />
                </div>
              <label>Fuse Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs32.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs32.timesec" />
                </div>
              </div><br>  
              <div class="form-group">              
              <label>Thermistor Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs33.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs33.timesec" />
                </div>
              <label>Thermistor Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs34.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs34.timesec" />
                </div>
              </div>                
              </td>
              <td>
              <div class="form-group">              
              <P class="laserNumber">Laser 4</P>
              <label>Fuse Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs41.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs41.timesec" />
                </div>
              <label>Fuse Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs42.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs42.timesec" />
                </div>
              </div><br>  
              <div class="form-group">              
              <label>Thermistor Pre</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs43.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs43.timesec" />
                </div>
              <label>Thermistor Melting</label>
                <div class="time-inputs">
                  <label>Power:</label>
                  <input v-model="formInputs44.powerw" />
                  <label>Time:</label>
                  <input v-model="formInputs44.timesec" />
                </div>
              </div>                
              </td>
            </tr>
          </table>                     
            <button class="button" :class="{ 'disabled': isSaving }" type="submit" id="save" @click="submitAllForms" :disabled="isSaving">Save</button>
            <span v-if="isSaving">Saving...</span>
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>  
  import { reactive, computed, ref} from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate } from "../../../utils.js"; 
   
  export default {                    
    setup(props, { emit }) {    
        const isSaving = ref(false);
        const dbTableName = "parasmtlaser"
        const columnMapping = {
          year:'year', month: 'month', day: 'day', time: 'time', line: 'line', lasernumber: 'lasernumber',
          part: 'part', premelt: 'premelt', powerw: 'powerw', timesec: 'timesec', technician: 'technician'
        }

        const formInputs = reactive({ time: "", line: "", technician: "" });
        const formInputs11 = reactive({ lasernumber: "Laser 1", part: "Fuse", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs12 = reactive({ lasernumber: "Laser 1", part: "Fuse", premelt: "Melt", powerw: "", timesec: "", });        
        const formInputs13 = reactive({ lasernumber: "Laser 1", part: "Thermistor", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs14 = reactive({ lasernumber: "Laser 1", part: "Thermistor", premelt: "Melt", powerw: "", timesec: "", });

        const formInputs21 = reactive({ lasernumber: "Laser 2", part: "Fuse", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs22 = reactive({ lasernumber: "Laser 2", part: "Fuse", premelt: "Melt", powerw: "", timesec: "", });        
        const formInputs23 = reactive({ lasernumber: "Laser 2", part: "Thermistor", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs24 = reactive({ lasernumber: "Laser 2", part: "Thermistor", premelt: "Melt", powerw: "", timesec: "", });

        const formInputs31 = reactive({ lasernumber: "Laser 3", part: "Fuse", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs32 = reactive({ lasernumber: "Laser 3", part: "Fuse", premelt: "Melt", powerw: "", timesec: "", });        
        const formInputs33 = reactive({ lasernumber: "Laser 3", part: "Thermistor", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs34 = reactive({ lasernumber: "Laser 3", part: "Thermistor", premelt: "Melt", powerw: "", timesec: "", });

        const formInputs41 = reactive({ lasernumber: "Laser 4", part: "Fuse", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs42 = reactive({ lasernumber: "Laser 4", part: "Fuse", premelt: "Melt", powerw: "", timesec: "", });        
        const formInputs43 = reactive({ lasernumber: "Laser 4", part: "Thermistor", premelt: "Pre", powerw: "", timesec: "", });        
        const formInputs44 = reactive({ lasernumber: "Laser 4", part: "Thermistor", premelt: "Melt", powerw: "", timesec: "", });
  
        const timeHour = computed({
          get: () => {
            const [hour] = formInputs.time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.time.split(":");
            formInputs.time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const timeMinute = computed({
          get: () => {
            const [, minute] = formInputs.time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.time.split(":");
            formInputs.time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });

        const searchDate = reactive({ ...getCurrentDate(), });    

        const submitForm11 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs11.lasernumber, part: formInputs11.part,
              premelt: formInputs11.premelt, powerw: formInputs11.powerw,
              timesec: formInputs11.timesec, technician: formInputs.technician,     
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm12 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs12.lasernumber, part: formInputs12.part,
              premelt: formInputs12.premelt, powerw: formInputs12.powerw,
              timesec: formInputs12.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm13 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs13.lasernumber, part: formInputs13.part,
              premelt: formInputs13.premelt, powerw: formInputs13.powerw,
              timesec: formInputs13.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 
        
        const submitForm14 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs14.lasernumber, part: formInputs14.part,
              premelt: formInputs14.premelt, powerw: formInputs14.powerw,
              timesec: formInputs14.timesec, technician: formInputs.technician,        
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm21 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs21.lasernumber, part: formInputs21.part,
              premelt: formInputs21.premelt, powerw: formInputs21.powerw,
              timesec: formInputs21.timesec, technician: formInputs.technician,        
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm22 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs22.lasernumber, part: formInputs22.part,
              premelt: formInputs22.premelt, powerw: formInputs22.powerw,
              timesec: formInputs22.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm23 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs23.lasernumber, part: formInputs23.part,
              premelt: formInputs23.premelt, powerw: formInputs23.powerw,
              timesec: formInputs23.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 
        
        const submitForm24 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs24.lasernumber, part: formInputs24.part,
              premelt: formInputs24.premelt, powerw: formInputs24.powerw,
              timesec: formInputs24.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm31 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs31.lasernumber, part: formInputs31.part,
              premelt: formInputs31.premelt, powerw: formInputs31.powerw,
              timesec: formInputs31.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm32 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs32.lasernumber, part: formInputs32.part,
              premelt: formInputs32.premelt, powerw: formInputs32.powerw,
              timesec: formInputs32.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm33 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs33.lasernumber, part: formInputs33.part,
              premelt: formInputs33.premelt, powerw: formInputs33.powerw,
              timesec: formInputs33.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 
        
        const submitForm34 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs34.lasernumber, part: formInputs34.part,
              premelt: formInputs34.premelt, powerw: formInputs34.powerw,
              timesec: formInputs34.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm41 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs41.lasernumber, part: formInputs41.part,
              premelt: formInputs41.premelt, powerw: formInputs41.powerw,
              timesec: formInputs41.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm42 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs42.lasernumber, part: formInputs42.part,
              premelt: formInputs42.premelt, powerw: formInputs42.powerw,
              timesec: formInputs42.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitForm43 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs43.lasernumber, part: formInputs43.part,
              premelt: formInputs43.premelt, powerw: formInputs43.powerw,
              timesec: formInputs43.timesec, technician: formInputs.technician,       
            }
         };         
          await axios.post('/api/post', formData);    
        }; 
        
        const submitForm44 = async () => { 
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year, month: searchDate.month, day: searchDate.day,              
              time: formInputs.time, line: formInputs.line,
              lasernumber: formInputs44.lasernumber, part: formInputs44.part,
              premelt: formInputs44.premelt, powerw: formInputs44.powerw,
              timesec: formInputs44.timesec, technician: formInputs.technician,      
            }
         };         
          await axios.post('/api/post', formData);    
        }; 

        const submitAllForms = async () => {
          isSaving.value = true;
          await submitForm11(); await submitForm12(); await submitForm13(); await submitForm14();
          await submitForm21(); await submitForm22(); await submitForm23(); await submitForm24();
          await submitForm31(); await submitForm32(); await submitForm33(); await submitForm34();
          await submitForm41(); await submitForm42(); await submitForm43(); await submitForm44();
          closeModal();  
          isSaving.value = false;       
        };

        const closeModal = () => { emit("onClose"); }; 
          
        return { searchDate, dbTableName, closeModal, timeHour, timeMinute, isSaving,
                submitAllForms, submitForm11, submitForm12, submitForm13,
                formInputs, formInputs11, formInputs12, formInputs13, formInputs14,
                formInputs21, formInputs22, formInputs23, formInputs24,
                formInputs31, formInputs32, formInputs33, formInputs34,
                formInputs41, formInputs42, formInputs43, formInputs44,
        };    
      },     

    props: { onSubmit: { type: Function, required: true, },}, 
  };
  
</script>
    
<style scoped>
  * { margin: 0; padding: 0; box-sizing: border-box; }

  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 1200px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { width: 80%; font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }
  .button.disabled { background-color: #ccc; cursor: not-allowed; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input { width: 70px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }
  .laserNumber {font-size: 1.5rem; font-weight: bold; color: rgb(49, 116, 25); }
  .lineUnderRemark { width: calc(100%);  border-top: 5px solid rgb(105, 105, 105) }     
</style>