<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Invest List</h>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">        
          <div class="searchTd">
            <input class="yearInput" id="year" v-model="formInputs.year" placeholder="Year"/>                
          </div>               
          <div class="searchTd">
              <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
          </div>
          <div class="searchTd">                        
              <button class="addButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="showModal = true">+ New Invest</button>
          </div>
          <div class="searchTd">
              <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
          </div>                                  
        </div>      
      </form>  
    </div>          
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th rowspan="2">Category</th><th rowspan="2">Reason</th><th rowspan="2">Item</th><th rowspan="2">Company</th>
          <th rowspan="2">Department</th><th rowspan="2">Report<br>Date</th><th rowspan="2">PR</th><th rowspan="2">PO</th>
          <th rowspan="2">Asset</th><th colspan="3">Invest Cost</th><th rowspan="2">Offer</th><th rowspan="2">Done Date</th>
          <th rowspan="2" @click="toggleCashOutColumns">Cash Out<br>Total ▶</th>
          <th colspan="2" v-if="colVisible">Cash Out 1</th><th colspan="2" v-if="colVisible">Cash Out 2</th>
          <th colspan="2" v-if="colVisible">Cash Out 3</th><th colspan="2" v-if="colVisible">Cash Out 4</th>
          <th colspan="2" v-if="colVisible">Cash Out 5</th><th rowspan="2">File</th><th rowspan="2">Modify</th>
          <th rowspan="2">+Sub Item</th><th rowspan="2">DEL</th><th rowspan="2">Remark</th>
        </tr>              
        <tr>
          <th>Carry<br>over</th><th>New</th><th>Total</th><th v-if="colVisible">Date</th><th v-if="colVisible">Cash</th>
          <th v-if="colVisible">Date</th><th v-if="colVisible">Cash</th><th v-if="colVisible">Date</th><th v-if="colVisible">Cash</th>
          <th v-if="colVisible">Date</th><th v-if="colVisible">Cash</th><th v-if="colVisible">Date</th><th v-if="colVisible">Cash</th>
        </tr>      
       <tr v-for="row in Item" :key="row.id" :class="{ 'no-subitem': !row.subitem }">        
          <td v-if="!row.subitem">{{ row.category }}</td>
          <td v-if="!row.subitem">{{ row.reason }}</td>
          <td v-if="row.subitem" colspan="2"></td>
          <td style="text-align: left;">
              <span v-if="row.subitem" class="subitem">{{ row.subitem }}</span>
              <span v-else>{{ row.item }}</span>
          </td>
            <td>{{ row.company }}</td>
            <td>{{ row.department }}</td>
            <td>{{ row.reportdate }}</td>
            <td>{{ row.pr }}</td>
            <td>{{ row.po }}</td>
            <td>{{ row.asset }}</td>
            <td :style="row.carryoverprice === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ row.carryoverprice.toFixed(1) }}</td>
            <td :style="row.newprice === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ row.newprice.toFixed(1) }}</td>
            <td :style="row.costTotal === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ typeof row.costTotal === 'number' ? row.costTotal.toFixed(1) : '0' }}</td>
            <td :style="row.offer === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ row.offer.toFixed(1) }}</td>
            <td>{{ row.lastCashOutDate }}</td>
            <td :style="row.cashoutTotal === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ typeof row.cashoutTotal === 'number' ? row.cashoutTotal.toFixed(1) : '0' }}</td>           
            <td v-if="colVisible">{{ row.cashoutdate1 }}</td>
            <td :style="row.cashout1 === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}" v-if="colVisible">{{ row.cashout1.toFixed(1) }}</td>
            <td v-if="colVisible">{{ row.cashoutdate2 }}</td>
            <td :style="row.cashout2 === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}" v-if="colVisible">{{ row.cashout2.toFixed(1) }}</td>
            <td v-if="colVisible">{{ row.cashoutdate3 }}</td>
            <td :style="row.cashout3 === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}" v-if="colVisible">{{ row.cashout3.toFixed(1) }}</td>
            <td v-if="colVisible">{{ row.cashoutdate4 }}</td>
            <td :style="row.cashout4 === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}" v-if="colVisible">{{ row.cashout4.toFixed(1) }}</td>
            <td v-if="colVisible">{{ row.cashoutdate5 }}</td>
            <td :style="row.cashout5 === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}" v-if="colVisible">{{ row.cashout5.toFixed(1) }}</td>                   
            <td>{{ getFileName(row.file) }}<br>
              <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
            </td>   
            <td><button class="moButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>
            <td><button class="subButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSubModal(row)">+Sub Item</button></td>
            <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(row)">DEL</button></td>
            <td>{{ row.remark }}</td>                    
         </tr>
      </table>   
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    <subModal  v-if="showSubModal" :row-id="selectedRowId" @onClose="showSubModal = false; callSearchData();"/>                     
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./investModal.vue";
import modifyModal from "./investMoModal.vue";
import subModal from "./investSubModal.vue";
import { deleteRow, downloadExcel, } from "../utils.js"; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      openSubModal(row) { this.selectedRowId = row.id; this.showSubModal = true; },  
      canClick() {          
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 ||  currentUser.id === 3 ||currentUser.id === 32;}
        else { return false;}
      },    
      downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }
        window.location.href = filepath;
      },  
      getFileName(filePath) {
        if (!filePath) return ''; const parts = filePath.split('-');
        if (parts.length > 1) { return parts.slice(1).join('-'); }
        return filePath;
      },
      downExcel() { downloadExcel(this.Item, "EquipmentLedger.xlsx"); },      
      delRow(row) {
        if (row.filepath) { alert('First file delete and try again'); }
        else { deleteRow(this.dbTableName, row.id, this.callSearchData); }
      },
    },

    components: { Modal, modifyModal, subModal },     

    setup() {                   
        const dbTableName = 'invest';
        const showModal = ref(false);
        const showSubModal = ref(false);
        const showMoModal = ref(false);     
        const formInputs = reactive({ year: "" });                        
        const investData = reactive({ data: [], })   
        
        const sendDate = async () => {const sendDateData = [formInputs.year]; return { sendDateData };};    

        const searchData = async () => {   
          const sendDateData = await sendDate();         
          const formData = {        
                tableName: dbTableName,                                
                year: sendDateData.sendDateData[0],
            };  

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })                      
               .then((res) => { investData.data = res.data
          });  
        };

        const getLastCashOutDate = (row) => {
          const dates = [row.cashoutdate1, row.cashoutdate2, row.cashoutdate3, row.cashoutdate4, row.cashoutdate5];          
          const validDates = dates.filter(date => date);          
          validDates.sort((a, b) => new Date(b) - new Date(a));          
          return validDates.length > 0 ? validDates[0] : "";
        };
 
        const Item = computed(() => {
          return investData.data
            .map(d => {            
              const cashout1 = parseFloat(d.cashout1) || 0;
              const cashout2 = parseFloat(d.cashout2) || 0;
              const cashout3 = parseFloat(d.cashout3) || 0;
              const cashout4 = parseFloat(d.cashout4) || 0;
              const cashout5 = parseFloat(d.cashout5) || 0;
              const carryoverprice = parseFloat(d.carryoverprice) || 0;
              const newprice = parseFloat(d.newprice) || 0;
              
              const costTotal = carryoverprice + newprice;            
              const cashoutTotal = cashout1 + cashout2 + cashout3 + cashout4 + cashout5;
              const lastCashOutDate = getLastCashOutDate(d);

              return { ...d, costTotal, cashoutTotal, lastCashOutDate };
            })
            .sort((a, b) => {
              // 먼저 item으로 정렬합니다.
              if (a.item < b.item) return -1;
              if (a.item > b.item) return 1;

              // item이 같은 경우, subitem의 유무에 따라 정렬합니다. subitem이 없는 경우를 먼저 위치시킵니다.
              if (!a.subitem && b.subitem) return -1;
              if (a.subitem && !b.subitem) return 1;

              // subitem으로 추가 정렬합니다.
              if (a.subitem < b.subitem) return -1;
              if (a.subitem > b.subitem) return 1;

              return 0; // 모든 조건이 같으면 순서를 변경하지 않습니다.
            });
        });        

        const callSearchData = () => { searchData(); };  

        const colVisible = ref(false);        
        const toggleCashOutColumns = () => { colVisible.value = !colVisible.value; };

        return { Item, deleteRow, dbTableName, callSearchData, showModal, showMoModal, showSubModal, 
                 downloadExcel, formInputs, sendDate, colVisible, toggleCashOutColumns,
        };        
    }
  }
</script>

<style scoped> 
  .yearInput { padding: 5px 5px;}
  .table-container { margin-left : 10px; margin-right: 10px;}  
  .disabledButton { background-color: gray; cursor: not-allowed; }
  label { font-size: 17px; font-weight: 600; margin-right: 8px; }
  .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
  .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px; word-wrap: break-word; white-space: normal; }
  th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
      text-align : center; padding: 10px; font-size : 15px; font-weight: 600;
  }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                      border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
  }
  .custom-file-upload:hover { background-color: #009625; }

  .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
  }
  .moButton { padding: 5px 5px; background-color: #347003; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .subButton { padding: 5px 5px; background-color: #4f24eb; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchTd { border: 0px !important;}    
  .word { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .maker { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .factory { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  
  input::placeholder { color: gray; }
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }   
  
  .radio { margin-left: 10px; margin-right: 5px; }      
  .subitem::before { content: '• '; margin-left: 10px; } 
  .no-subitem { background-color: rgb(249, 249, 205); font-weight: bold}
  .horizontal-form { margin-top: 10px; margin-left: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>