<template>
  <div class="container">            
    <h class="pagetitle">Scrap Measurement / Sell</h>        
    <form @submit.prevent="sendDate()" class="searchBar">
      <div class="horizontal-form"> 
        <div>
          <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
          <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
          <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
        </div>   
        <div>~</div> 
        <div>
          <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
          <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
          <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
        </div>
        <div>
          <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
        </div>       
        <div>
          <button class="addMeButton" @click="showMeModal = true">+ Add Measurement</button>   
        </div>
        <div>
          <button class="addSellButton" @click="showSellModal = true">+ Add Sell</button>   
        </div>  
        <div>
          <button class="excelButton" @click="downExcel">Excel</button>                                              
        </div>                          
      </div>      
    </form>   
    <div class="horizontal-form">          
      <div class="tables">
        <label class="tableLabel">Measurement Table</label>
        <table>
          <tr>          
            <th>Date</th><th>Pure Copper(g)</th><th>Coating Copper(g)</th><th>Modify</th><th>DEL</th>
          </tr>        
          <tr v-for="row in item" :key="row.id">            
            <td>{{ row.year}}-{{ row.month }}-{{ row.day }}</td>                  
            <td>{{ row.purecopper }}</td>                
            <td>{{ row.coatingcopper }}</td>                                              
            <td><button type="submit" @click="openMeModal(row)" class="moButton">Modify</button></td>
            <td><button :class="['del', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>                                        
          </tr>
        </table>    
      </div>
      <div class="tables">
        <label class="tableLabel">Sell Table</label>
        <table>
          <tr>          
            <th>Date</th><th>Pure Copper(g)</th><th>Coating Copper(g)</th><th>Trading statement</th><th>Modify</th><th>DEL</th>
          </tr>        
          <tr v-for="row in sellItem" :key="row.id">            
            <td>{{ row.year}}-{{ row.month }}-{{ row.day }}</td>                  
            <td>{{ row.purecopper }}</td>                
            <td>{{ row.coatingcopper }}</td>                                              
            <td>{{ getFileName(row.file) }}<br>
              <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
            </td>  
            <td><button type="submit" @click="openSellModal(row)" class="moButton">Modify</button></td>
            <td><button :class="['del', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(sellTableName, row.id)">DEL</button></td>                                        
          </tr>
        </table>    
      </div>
    </div>
  <MeModal  v-if="showMeModal" @onClose="showMeModal = false; callSearchData();"/>    
  <SellModal  v-if="showSellModal" @onClose="showSellModal = false; callSearchData();"/>    
  <modifyMeModal  v-if="showMeMoModal" :row-id="selectedRowId" @onClose="showMeMoModal = false; callSearchData();"/>
  <modifySellModal  v-if="showSellMoModal" :row-id="selectedRowId" @onClose="showSellMoModal = false; callSearchData();"/>
</div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import MeModal from "./meModal.vue";
import SellModal from "./sellModal.vue";
import modifyMeModal from "./meMoModal.vue";
import modifySellModal from "./sellMoModal.vue";
import axios from 'axios';
import { deleteRow, downloadExcel, } from "../../utils.js"; 


export default {
  components: { MeModal, SellModal, modifyMeModal, modifySellModal },    

  methods: {  
    openMeModal(row) {this.selectedRowId = row.id; this.showMeMoModal = true;},    
    openSellModal(row) {this.selectedRowId = row.id; this.showSellMoModal = true;},    
    downExcel() { downloadExcel(this.item, "Work List.xlsx"); },
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); }, 
    downloadFile(filepath) {
      if (!filepath) { alert('No file available for download'); return; }
      window.location.href = filepath;
    },    
    getFileName(filePath) {
      if (!filePath) return ''; const parts = filePath.split('-');
      if (parts.length > 1) { return parts.slice(1).join('-'); }
      return filePath;
    },
    canDelClick() {    
      const currentUser = this.$route.meta.currentUser;
      console.log('currentUser', currentUser)
      if (currentUser) {            
          return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3  ;
      } else {
          return false;
      }
    },  
  },

  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); 

    const dbTableName = 'scrapmeasurement';  
    const sellTableName = 'scrapsell';      
    const meTableData = reactive({ data: [], });  
    const sellTableData = reactive({ data: [], });  
    const showMeModal = ref(false);     
    const showSellModal = ref(false);     
    const showMeMoModal = ref(false);     
    const showSellMoModal = ref(false);     

    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, ]; return {sendDateData};};     
    const meSearchData = async (tableName) => {
      const sendDateData = await sendDate();
      const formData = {        
          tableName: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],        
      };    
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { 
          meTableData.data = res.data.sort((a, b) => {            
            if (a.year !== b.year) return a.year - b.year;
            if (a.month !== b.month) return a.month - b.month;
            if (a.day !== b.day) return a.day - b.day;            
          });
        });
    };  

    const sellSearchData = async (tableName) => {
      const sendDateData = await sendDate();
      const formData = {        
          tableName: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],        
      };    
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { 
          sellTableData.data = res.data.sort((a, b) => {            
            if (a.year !== b.year) return a.year - b.year;
            if (a.month !== b.month) return a.month - b.month;
            if (a.day !== b.day) return a.day - b.day;            
          });
        });
    };  


    const callSearchData = () => { meSearchData(dbTableName); sellSearchData(sellTableName); };  

    const item = computed(() => { return meTableData.data.map(d => { return { ...d, }; }); });
    const sellItem = computed(() => { return sellTableData.data.map(d => { return { ...d, }; }); });
   

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay, 
      dbTableName, sellTableName, item, sellItem,
      meSearchData, sellSearchData, callSearchData, sendDate, 
      showMeModal, showMeMoModal, showSellModal, showSellMoModal,
    };
  },
  };
</script>

<style scoped>
 * { box-sizing: border-box;}

.container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: auto; margin-right: auto; width: 100%; }
.start,.end { display: flex; flex-direction: row; border: 0px;}
.start input,.end input { margin-right: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px;}
.searchTd { border: 0px;}
.tableLabel {font-weight :bold; margin-bottom: 5px;}

input::placeholder { color: gray;}

label { font-size: 14px; font-weight: 600; margin-right: 8px;}

select,input[type="number"],input[type="text"] {
  padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
  font-size: 14px; width: 100%; max-width: 100px;
}

.del {
  padding: 4px 4px;
  background-color: #bfbebe;
  color: #000000; border: 10px; border-color: #000000;
  font-size: 10px; font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}

.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-left: 10px; margin-top: 10px;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }

.searchBar select,.searchBar input{ width : 100px; margin-right: 5px; padding : 5px; }

label { font-size: 14px; font-weight: 600; margin-right: 8px; }

td { border : 1px solid black; text-align : center; padding: 5px 5px; font-size : 12px; }

th { 
  position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ;
  border : 1px solid black; text-align : center; padding: 5px 5px; font-size : 12px; 
}
.tables {
  display: flex; flex-direction: column; align-items: stretch;
  padding-left : 10px; padding-right : 10px; width: 48%;
}

table { width: 100%; }
.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 10px; }
.disabledButton { background-color: gray !important; cursor: not-allowed !important; }

.gray-background { background-color: gray;} 

.searchButton {
    background-color: #929292;  color: rgb(0, 0, 0);
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    transition: background-color 0.3s; margin-left : 10px; height: 30px; font-size: 15px; width: 100px;
}
.searchButton:hover { background-color: #a4a4a4; }
.excelButton {
    background-color: #2d6302;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    transition: background-color 0.3s; margin-left : 10px; height: 30px; font-size: 15px; width: 100px;
}
.excelButton:hover { background-color: #009625; }
.addMeButton {
    background-color: #007396;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    transition: background-color 0.3s; margin-left : 10px; height: 30px; font-size: 15px; width: 200px;
}
.addMeButton:hover { background-color:  #3498db; }
.addSellButton {
    background-color: #f39315;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    transition: background-color 0.3s; margin-left : 10px; height: 30px; font-size: 15px; width: 100px;
}
.addSellButton:hover { background-color:  #99610c; }
.moButton {
    padding: 5px 5px; background-color: #0d4d01; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
.fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
}

</style>