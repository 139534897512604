<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Take Over</h>        
      <form @submit.prevent="sendDate()" class="searchBar">
          <div class="horizontal-form">            
            <div class="start">
              <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
              <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
              <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
            </div>
            <div class="separator">~</div>
            <div class="end">
              <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
              <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
              <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
            </div>  
            <div class="Team">
              <b>Factory : </b>
              <select id="line" v-model="factory">
                <option>1</option><option>2</option><option></option>
              </select>   
            </div>                  
            <div class="Team">
              <b>Shift : </b> 
              <select id="line" v-model="shift">
                <option>1</option><option>2</option><option></option>
              </select>   
            </div>        
            <div class="Team">
              <b>Position : </b> 
              <select id="line" v-model="position">
                <option>Leader</option><option>Technician</option><option></option>
              </select>   
            </div>                  
            <div class="Team">
              <b>Line : </b>
              <select id="line" v-model="line">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
              </select>   
            </div>                  
            <div class="searchTd">
                <button type="submit" class="searchButton" @click="callSearchData">Search</button>                      
            </div>   
            <div class="searchTd">
                <button class="mobutton" @click="showModal = true">Make Take Over</button>                    
            </div>                                    
          </div>      
      </form>
    </div>      
    <hr>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>                
        </tr>    
        <tr v-for="row in item" :key="row.id" >   
          <td>{{ row.factory }}</td>       
          <td>{{ row.date }}</td>
          <td>{{ row.shift }}</td>          
          <td>{{ row.position }}</td>
          <td>{{ row.writer }}</td>
          <td>{{ row.line }}</td>          
          <td><button type="submit" @click="openViewModal(row)" class="viewButton">Viewer</button></td>          
          <td>{{ row.remark }}</td>
          <td><button type="submit" @click="openSendModal(row)" class="mobutton">Modify</button></td>
          <td><button class="confirmButton" @click="confirmClick(row)">Confirm</button></td>
          <td>{{ row.confirm }}</td>
          <td><button class="engConfirmButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="engConfirmClick(row)">Confirm</button></td>
          <td>{{ row.engconfirm }}</td>
          <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>
        </tr>
    </table>    
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>    
    <takeOverViewModal  v-if="takeOverViewModal" :row-id="selectedRowId" @onClose="takeOverViewModal = false; callSearchData();"/>        
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./takeOverModal.vue";
import modifyModal from "./takeOverMoModal.vue";
import takeOverViewModal from "./takeOverView.vue";
import { deleteRow, fetchLine } from '../utils.js'; 

export default { 
  data() {
    return {    
      columnMapping: {     
        year: 'year',
        month: 'month',
        day: 'day',
        factory: "factory",
        shift: "shift",        
        position : "position",
        writer: "writer",
        line : "line",      
        contents : "contents",        
        remark : "remark",
        confirm: "confirm",          
        engconfirm: "engconfirm",  
      }
    }
  },    

  methods: {
    openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; }, 
    openViewModal(row) { this.selectedRowId = row.id; this.takeOverViewModal = true; },     
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    canClick() {            
      const currentUser = this.$route.meta.currentUser;            
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;}
      else { return false;}
    },
    confirmClick(row) { this.confirmSend(row); this.callSearchData(); },
    engConfirmClick(row) { this.engConfirmSend(row); this.callSearchData(); },

    async confirmSend(row) {               
      const formData = {
        id: row.id,
        tableName: 'takeover',
        columnMapping: this.columnMapping,
        values: {            
          year: row.year,
          month: row.month,
          day: row.day,
          factory: row.factory,
          shift: row.shift,
          position : row.position,
          writer : row.writer,
          line : row.line,            
          contents : row.contents,
          remark : row.remark,
          confirm: 'Confirmed',                   
          engconfirm: row.engconfirm
        }
      };

      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

      if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
      }
      formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));      
      try {
          const response = await axios.put(`/api/materialput/${row.id}`, formData);
          console.log('Server response:', response);        
      } catch (error) {
          console.error('Error updating data:', error);
      }      
    },    
    async engConfirmSend(row) {               
      const formData = {
        id: row.id,
        tableName: 'takeover',
        columnMapping: this.columnMapping,
        values: {            
          year: row.year,
          month: row.month,
          day: row.day,
          factory: row.factory,
          shift: row.shift,
          position : row.position,
          writer : row.writer,
          line : row.line,            
          contents : row.contents,
          remark : row.remark,
          confirm: row.confirm,
          engconfirm: 'Confirmed'
        }
      };

      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

      if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
      }
      formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));      
      try {
          const response = await axios.put(`/api/materialput/${row.id}`, formData);
          console.log('Server response:', response);        
      } catch (error) {
          console.error('Error updating data:', error);
      }      
    },  
  },

  components: { Modal, modifyModal, takeOverViewModal, },    

  setup() {       
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();
    const factory = ref(); const line = ref(); const shift = ref(); const position = ref();
    const dbTableName = 'takeover';
    const showModal = ref(false);
    const showMoModal = ref(false);
    const takeOverViewModal  = ref(false);  
    const uniqueLines = ref([]);  
    const headers = ['Factory', 'Date', 'Shift', 'Position', 'Writer', 'Line', 'Contents', 'Remark', 
                      'Modify', 'Confirm', 'Confirmed', 'Engineer Confirm', 'Engineer Confirmed', 'DEL'
    ];
    const takeOverData = reactive({ data: [], })    
    
    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, factory.value, line.value, shift.value, position.value, ]; return {sendDateData};};         
    
    const searchData = async () => {   
      
      const sendDateData = await sendDate();    

      const formData = {        
          tableName: dbTableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
          factory: sendDateData.sendDateData[6],   
          line: sendDateData.sendDateData[7],          
          shift: sendDateData.sendDateData[8],          
          position: sendDateData.sendDateData[9],          
      };  

      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => {              
          takeOverData.data = res.data; 
        })
        .catch((error) => {
          console.error("Error fetching target data:", error.message);
      });
    };   
    
    const item = computed(() => {
      return takeOverData.data.map(item => {
        const date = `${item.day}.${item.month}.${item.year}`;       
        return { ...item, date, };
      });
    });

    const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
    
    const callSearchData = () => { searchData(dbTableName); };   

    return { item, headers, dbTableName, callSearchData, sendDate, uniqueLines,
             startYear, startMonth, startDay, endYear, endMonth, endDay, line, factory, shift, position,
             showModal, showMoModal, takeOverViewModal, 
    };        
  }
}
</script>

<style scoped>
/* 기본 설정 */
.pagetitle { margin-left: 10px; font-weight: bold;}
.table-container { margin-left: 10px; margin-right: 10px;}
.table {
  width: 100%;  
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 그림자 효과 */
  background-color: #ffffff; /* 밝은 배경색 */
}
.table th {
    padding: 0.75rem;  
    text-align: center; /* Center align text */
    border: 1px solid #808080;
    background-color: #dad8d8; /* 밝은 배경색 */
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }
.table td {
    padding: 0.75rem;
    text-align: center; /* Center align text */
    border: 1px solid #808080;
  }

.table td.content-cell { max-width: 600px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.effect-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.fileaddress-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.remark-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
/* 색상 행 */
.yellowRow { background-color: #ffffcc; }
.redRow { background-color: #ffcccc; }

.mobutton {
    background-color: #4CAF50; color: rgb(255, 255, 255); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}
.viewButton {
    background-color: #6137fa; color: rgb(255, 255, 255); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}
.search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
.start input,.end input { margin-right: 5px; margin-left: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px; }
.searchTd { border: 0px; }
.searchButton {
    background-color: #bebebe; color: rgb(0, 0, 0); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}  
.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 20px; margin-left: 20px; 
}
.fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }
.searchBar select,.searchBar input{ width : 120px; margin-right: 5px; padding : 5px; } 
.signButton { padding: 10px 10px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
              font-size: 12px; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
              margin-right: 5px; cursor: pointer; transition: background-color 0.3s; font-weight: bold; 
}
.cancelButton { padding: 5px 5px; background-color: #d78968; color: #000000; border: none; border-radius: 4px;
              font-size: 10px; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
              margin-right: 5px; cursor: pointer; transition: background-color 0.3s; font-weight: bold; 
}
.delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
}
.disabledButton { background-color: gray; cursor: not-allowed; }
.approved { background-color: rgb(0, 92, 0); color: white; font-weight: bold;}
.confirmButton { padding: 10px 15px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
                font-weight: 600; margin-top: 3px; margin-bottom: 3px; 
                cursor: pointer; transition: background-color 0.3s;        
}
.engConfirmButton { padding: 10px 15px; background-color: #b86705; color: #fff; border: none; border-radius: 4px;
                font-weight: 600; margin-top: 3px; margin-bottom: 3px; 
                cursor: pointer; transition: background-color 0.3s;        
}
.horizontal-form { display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   

</style>