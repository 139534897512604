<template>
  <div class="planning">    
    <div class="act">  
      <form class="searchBar">      
        <button class="nav-button" @click.prevent="fetchDataForPrevWeek">Previous week</button>
        <span id="week-number">{{ currentDateAndWeek }}</span>
        <button class="nav-button" @click.prevent="fetchDataForNextWeek">Next week</button>      
      </form>
    </div>      
    <select class="line-name-input" id="line" v-model="formInputs.lineName">
      <option v-for="line in uniqueLines" :key="line">{{ line }}</option>          
    </select>  
    <button type="submit" class="searchButton" @click="callSearchData">Search</button>    
    <div class="tables">
      <table id="weekplanning" class="custom-table">
        <tr>
          <th>Line</th>
          <th>Station</th>
          <th>Part</th>
          <th v-for="(date, dateIndex) in weekDates" :key="dateIndex" :id="days[dateIndex]">
            {{ days[dateIndex].toUpperCase() }}<br>{{ date.toLocaleDateString() }}
          </th>
        </tr>
        <tr v-for="(item, index) in cycleItem" :key="index">
          <td><b>{{ item.line }}</b></td>
          <td><b>{{ item.station }}</b></td>
          <td><b>{{ item.part }}</b></td>
          <td v-for="(date, dateIndex) in weekDates" :key="dateIndex">
            Plan:
            <span v-if="isScheduled(item, date)">
              O
            </span>
            <br>
            Actual: <button @click="openEditModal(item, date)">
              {{ isScheduled(item, date) ? findCleaningData(item, date)?.result
                 || 'Input' : findCleaningData(item, date)?.result || ''}} </button><br>
            Remark: {{ findCleaningData(item, date)?.remark || '' }}<br>
          </td>
        </tr>        
      </table>         
    </div>
    <editModal v-if="show_edit_Modal" :dbTableName="dbTableName" :cleaning="selectedCleaning" @onClose="show_edit_Modal = false; callSearchData();"></editModal>
  </div>  
</template>

<script>
  import { ref, computed, reactive,  watch } from "vue";
  import axios from "axios";
  import editModal from "./editModal.vue";
  import { fetchData } from "../utils.js"; 

  export default {  
    components: { editModal },       
    methods: {
      fetchDataForPrevWeek() { this.prevWeek(); this.callSearchData(); },  
      fetchDataForNextWeek() { this.nextWeek(); this.callSearchData(); },    
    },

    props: {dbTableName: {type: String, required: true}, cycleTableName: {tpye: String, required: true}},
    setup(props) {                
      const formInputs = reactive({ lineName: "" }); 
      const uniqueLines = ref();
      const show_edit_Modal = ref(false);
      const baseDate = ref(new Date(2023, 0, 1));             
      const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      const currentDate = ref(new Date());
      const currentDateAndWeek = computed(() => {
        const weekNumber = getWeekNumber(currentDate.value);
        return `Week ${weekNumber}`;
      });
      const cycleLengths = { '1 day': 1, '1 week': 7, '2 week': 14, '3 week': 21, '1 month': 30 };
      const weekDays = { "sun": 1, "mon": 2, "tue": 3, "wed": 4, "thu": 5, "fri": 6, "sat": 7 };
      const schedule = reactive({}); 
      const selectedCleaning = reactive({ year: null, month: null, day: null, line: null, station: null, plan: null, result: null, remark: null });

      const weekDates = computed(() => {
        const weekStart = new Date(currentDate.value);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);

        const dates = [];

        for (let i = 0; i < 7; i++) {
          const date = new Date(weekStart);
          date.setDate(weekStart.getDate() + i);
          dates.push(date);
        }

        return dates;
      });
      const prevWeek = () => { currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() - 7)); };
      const nextWeek = () => { currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() + 7)); };
      function getWeekNumber(date) {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const weekNumber = Math.ceil((((date - startOfYear) / 86400000) + startOfYear.getDay() + 1) / 7);
        return weekNumber;
      }           

      const cleaningcycle1st = reactive({data: []}); const cycleItem = computed(() => { return cleaningcycle1st.data.map(d => {return { ...d, };});});       
      const cleaningList1st = reactive({data: []}); const Item = computed(() => { return cleaningList1st.data.map(d => {return { ...d, };});});     

      const cycleSearchData = async (tableName) => {  
        const formData = { tableName: tableName, line: formInputs.lineName, };      
        axios.get('/api/targetList', { params: formData })
          .then((res) => {
            console.log("Response from /api/target:", res.data);
            cleaningcycle1st.data = res.data.sort((a, b) => a.row_no - b.row_no);             
          })            
      };      
      const searchData = async (tableName) => {
          const startWeek = weekDates.value[0];
          const endWeek = weekDates.value[weekDates.value.length - 1];          

          const formData = {
            tableName: tableName,
            line: formInputs.lineName,             
            startYear: startWeek.getFullYear(),
            startMonth: startWeek.getMonth() + 1,
            startDay: startWeek.getDate(),
            endYear: endWeek.getFullYear(),
            endMonth: endWeek.getMonth() + 1,
            endDay: endWeek.getDate(),              
          };

          axios.get('/api/searchPeriod', { params: formData })
            .then((res) => {cleaningList1st.data = res.data });            
      }; 
      
      const getDatesForCycle = (cycle, startDay) => {        
        const dates = [];
        let date = new Date(startDay);
        const oneDay = 24*60*60*1000;         
       
        let endOfYear = new Date((new Date()).getFullYear(), 11, 31);
        
        while(date <= endOfYear) {          
          if(cycle.isDue(date)) {
            dates.push(new Date(date));   
          }
          date = new Date(date.getTime() + oneDay); 
        }
        return dates;
      }; 
      
      const createSchedule = (actions) => {
        actions.forEach(action => {
          const cycle = parseCycle(action.cycle, action.week);
          const weekday = action.week ? weekDays[action.week] : 1;
          const startDay = new Date(baseDate.value.getFullYear(), baseDate.value.getMonth(), weekday);
          const actionSchedule = getDatesForCycle(cycle, startDay);          
          
          actionSchedule.forEach(date => {
            const dateString = date.toISOString().split('T')[0];
            if (!schedule[dateString]) {
              schedule[dateString] = [];
            }
            schedule[dateString].push(action);            
          });
        });
      };

      const parseCycle = (cycle, weekDay) => {
        const [count, unit] = cycle.split(' ');         
        return {
          count: parseInt(count),
          unit: unit,
          lastScheduledMonth: -1, 
          isDue(date) {
            const diffInDays = Math.floor((date - baseDate.value) / (24 * 60 * 60 * 1000));
            switch (unit) {
              case 'day':
                return diffInDays >= count;
              case 'week': 
                return date.getDay() === weekDays[weekDay] && Math.floor(diffInDays / 7) % count === 0;               
              case 'month': {   
                const isDue = date.getDay() === weekDays[weekDay] && date.getMonth() !== this.lastScheduledMonth;
                if (isDue) {
                  this.lastScheduledMonth = date.getMonth();
                }                
                return isDue;
              }
              default:
                throw new Error(`Unknown cycle unit: ${unit}`);
            }
          },
        };
      };      

      const isScheduled = (item, date) => {
        const dateString = date.toISOString().split('T')[0];
        const actionsForThisDay = schedule[dateString] || [];        
        return actionsForThisDay.some(action => action.line === item.line && action.station === item.station && action.part === item.part);
      };    

      const openEditModal = (item, date) => {
        selectedCleaning.year = date.getFullYear();
        selectedCleaning.month = date.getMonth() + 1;
        selectedCleaning.day = date.getDate();
        selectedCleaning.line = item.line;
        selectedCleaning.station = item.station;
        selectedCleaning.part = item.part;
        selectedCleaning.plan = isScheduled(item, date) ? 'O' : ''; 
        selectedCleaning.actual = '';
        show_edit_Modal.value = true;
      };
      
      const findCleaningData = (item, date) => {
        return cleaningList1st.data.find(d =>
          d.year === date.getFullYear() &&
          d.month === date.getMonth() + 1 &&
          d.day === date.getDate() &&
          d.line === item.line &&
          d.station === item.station &&
          d.part === item.part          
        );
      };     

      const callSearchData = () => {
        const actions = cycleItem.value;
        createSchedule(actions);                 
        searchData(props.dbTableName); cycleSearchData(props.cycleTableName); };

      watch(formInputs.lineName, () => {
        callSearchData();
      });
      watch(cycleItem, newValue => { 
        createSchedule(newValue);
      });          

      const loadLines = async () => {
        uniqueLines.value = await fetchData(props.cycleTableName, 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
      }; loadLines();        

      return {     
        cycleSearchData, searchData, days, prevWeek, nextWeek, currentDateAndWeek, weekDays, createSchedule, 
        weekDates, editModal, callSearchData, cycleItem, Item, cycleLengths, schedule, isScheduled,
        openEditModal, show_edit_Modal, selectedCleaning, findCleaningData, formInputs, uniqueLines
      }

    }
  }

</script>

<style scoped>
  .act {
    display: flex;  justify-content: space-between;  align-items: center;
    margin-bottom: 1rem; margin-left : 20px;
  }
  .nav-button {
    background-color: #007bff; color: white; border: none; border-radius: 4px;
    cursor: pointer; font-weight: bold; padding: 0.1rem 0.5rem; transition: background-color 0.3s;
  }

  .nav-button:hover { background-color: #0056b3; }

  button {
    background-color: #9cfca1; color: rgb(0, 0, 0); border: none;
    border-radius: 4px; cursor: pointer; font-weight: bold; padding: 0.1rem 0.5rem;
    transition: background-color 0.3s;
  }

  button:hover { background-color: #65af7b; }

  #week-number { font-size: 1.2rem; font-weight: bold; margin: 0 1rem; }

  th {
      position: sticky; top: 0; background-color: white; z-index: 10;
      background-color: rgb(230, 230, 230) ; border : 1px solid black;        
      text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; 
  }

  td {
      background-color: rgb(255, 255, 255); border : 1px solid black;        
      text-align : left; padding-left : 10px; padding-right : 10px; font-size : 12px;     
  }
  .tables {
    display: flex; flex-direction: column; align-items: stretch;
    padding-left : 20px; padding-right : 20px;
  }

  table { width: 80%; }

  .custom-table { font-size: 10px; width: 80%; overflow-x: auto; white-space: nowrap; }

  .searchBar {
    display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
    margin-bottom: 0px; margin-left: 20px;    
  }

  .excelFile {
    display: flex; align-items: center; justify-content: flex-end;
    width: 700px; padding: 0 10px;  margin-bottom: 0px;
  }

  .right-align { margin-left: auto; }

  .custom-file-upload {
    display: inline-block; padding: 6px 12px; cursor: pointer;
    background-color: #347003; color: #ffffff;
    border-radius: 4px; font-size: 14px; transition: background-color 0.3s;  
    margin-right : 10px; margin-left : 10px;
  }

  .custom-file-upload:hover { background-color: #009625;}

  .listuploadbutton {
    padding: 5px 5px; background-color: #3498db; color: #fff;  border: none;
    border-radius: 4px; font-size: 14px; font-weight: 600; margin-left: 10px;
    cursor: pointer; transition: background-color 0.3s;        
  }  
  .line-name-input{ width : 300px; margin-right: 20px; padding : 5px; margin-left : 20px; margin-bottom: 10px; }
  .searchButton { background-color: #bababa; color: rgb(0, 0, 0); border: none;
    border-radius: 4px; cursor: pointer; font-weight: bold; padding: 0.1rem 0.5rem;
    transition: background-color 0.3s;
  }
</style>