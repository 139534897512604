<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify PFMEA</h3>
          <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="handleSubmit" :disabled="isProcessing">Save</button>
          {{ saveStatus }}
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <table class="lineTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line</td>
                <td>
                  <select v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                  </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Revision No.<br>(00)</td>
                <td>
                  <input class="revisionInput" type="text" v-model="formInputs.revision" />
                </td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--Class Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">              
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process No.</td>                  
                <td style="text-align: left;"><input class="itemInput" type="text" v-model="formInputs.process" /></td>                                 
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process Name</td>                                    
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.process_name" rows="3" cols="40"></textarea></td>                                
              </tr>
            </table><br>                
            <b class="uniquecode" >Unique Code: Once specified as a unique value for this item, it cannot be changed, and it must not overlap in the same Line.</b>
            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #A8D1FF; padding: 5px 5px;">Unique Code</td>
                <td colspan="2" style="text-align: left;"><input class="readonly" type="text" v-model="formInputs.uniquecode" readonly /></td>
                <td style="background-color: #A8D1FF; padding: 5px 5px;">Delete Item<br></td>
                <td colspan="5" style="text-align: left;"><input class="itemInput" type="text" v-model="formInputs.delete_item" /><br>(Write down the Revision No. when deletion began.)</td>
              </tr>


              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #F9FDA2; padding: 5px 5px;">Requirements</td>                  
                <td colspan="8" style="text-align: left;"><textarea id="contents" v-model="formInputs.requirements" rows="3" cols="100"></textarea></td>                  
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" style="background-color: #F9FDA2; padding: 5px 5px;">Potential</td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Failure Mode</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.potential_failure" rows="3" cols="30"></textarea></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Effects of Failure</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.potential_effects" rows="3" cols="30"></textarea></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Caues/Mechanisms<br>of Failure</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.potential_caues" rows="3" cols="30"></textarea></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">SEV</td>
                <td style="text-align: left;">
                  <select class="itemSelect" v-model="formInputs.sev1">
                    <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                    </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">CLASS</td>
                <td style="text-align: left;">
                  <select class="itemSelect" v-model="formInputs.class1">
                    <option>O</option><option></option>
                  </select>
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" style="background-color: #F9FDA2; padding: 5px 5px;">Current<br>Process<br>Control</td>
                <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Prevention</td>
                <td colspan="1" style="text-align: left;"><textarea id="contents" v-model="formInputs.prevention" rows="3" cols="30"></textarea></td>                
                <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Detection</td>
                <td colspan="3" style="text-align: left;"><textarea id="contents" v-model="formInputs.detection" rows="3" cols="80"></textarea></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">OCC</td>
                <td style="text-align: left;">
                  <select class="itemSelect" v-model="formInputs.occ1">
                    <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                    </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">DET</td>
                <td style="text-align: left;">
                  <select class="itemSelect" v-model="formInputs.det1">
                    <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                  </select>
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="1" style="background-color: #F9FDA2; padding: 5px 5px;">RPN</td>                                             
                <td colspan="1" style="text-align: left;"><input class="rpnInput" v-model="formInputs.rpn1" readonly/></td>   
              </tr>               
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" style="background-color: #347003; color: white; padding: 5px 5px;">Action</td>                  
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Recommended<br>Action</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.recommended" rows="3" cols="30"></textarea></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Responsibility<br>and Target<br>Completion<br>Date</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.responsibility" rows="3" cols="30"></textarea></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Action<br>Taken</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.actions" rows="3" cols="30"></textarea></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">SEV</td>
                <td style="text-align: left;">
                  <select class="itemSelect" v-model="formInputs.sev2">
                    <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                    </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">OCC</td>
                <td style="text-align: left;">
                  <select class="itemSelect" v-model="formInputs.occ2">
                    <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                  </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">DET</td>
                <td style="text-align: left;">
                  <select class="itemSelect" v-model="formInputs.det2">
                    <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                    </select>
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="1" style="background-color: #347003; color: white; padding: 5px 5px;">RPN</td>                  
                <td style="text-align: left;">
                  <input class="rpnInput" v-model="formInputs.rpn2" readonly/>
                </td>                  
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">                
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Row No.</td>
                <td style="text-align: left;"><input class="itemInput" type="text" v-model="formInputs.row_no" /></td>
              </tr>                
            </table><br>               
            
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="handleSubmit" :disabled="isProcessing">Save</button>
            {{ saveStatus }}
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { ref, watch} from 'vue';
import axios from 'axios';
import { getColumnMapping, makeFormData, fetchData, useFormInputs,} from "../utils.js";

export default {
  setup(props, { emit }) {
    const dbTableName = "pfmea";
    const isProcessing = ref(false);
    const saveStatus = ref(''); 
    const modifyorNot = ref('');
    const uniqueLines = ref([]); 

    const { formInputs, dataForm } = useFormInputs({id: ""});

    const modiFySubmitForm = async () => {  

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';

      const columnMapping = getColumnMapping(undefined, formInputs, true);   
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping, true);
      const id = formInputs.id; 

      if (!id) { alert("ID is missing"); return; }
      
      try {      
        await axios.put(`/api/materialput/${id}`, formData);
        saveStatus.value = 'Completed';
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }         
      closeModal();
    };   

    const submitForm = async () => {  

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';

      delete formInputs.id; // id 항목 제거
      const columnMapping = getColumnMapping(undefined, formInputs);   
      delete columnMapping.id; // id 항목 제거
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping);      
      
      try {      
        await axios.put(`/api/post`, formData);
        saveStatus.value = 'Completed';
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }         
      closeModal();
    };    

    const handleSubmit = () => {
      if (modifyorNot.value === formInputs.revision) {
        modiFySubmitForm();
      } else {
        submitForm();
      }
    };

    const closeModal = () => { emit("onClose"); };

    const fetchSelectedRowData = async () => {
      try {
        const response = await axios.get("/api/materialrequestadd", {
          params: { tableName: dbTableName, id: props.rowId },
        });
        const rowData = response.data[0];

        formInputs.id = rowData.id;
        formInputs.line = rowData.line;
        formInputs.revision = rowData.revision;        
        formInputs.process = rowData.process;
        formInputs.process_name = rowData.process_name;        
        formInputs.uniquecode = rowData.uniquecode;
        formInputs.delete_item = rowData.delete_item;
        formInputs.requirements = rowData.requirements;
        formInputs.potential_failure = rowData.potential_failure;
        formInputs.potential_effects = rowData.potential_effects;
        formInputs.sev1 = rowData.sev1;
        formInputs.class1 = rowData.class1;        
        formInputs.potential_caues = rowData.potential_caues;
        formInputs.occ1 = rowData.occ1;
        formInputs.prevention = rowData.prevention;
        formInputs.detection = rowData.detection;
        formInputs.det1 = rowData.det1;
        formInputs.recommended = rowData.recommended;    
        formInputs.responsibility = rowData.responsibility;    
        formInputs.actions = rowData.actions;    
        formInputs.sev2 = rowData.sev2;    
        formInputs.occ2 = rowData.occ2;    
        formInputs.det2 = rowData.det2;
        formInputs.row_no = rowData.row_no;        
        modifyorNot.value = rowData.revision;

      } catch (error) {
        console.error("Error fetching row data:", error.message);
      }
    };
    watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } ); 

    watch(
      () => [formInputs.sev1, formInputs.occ1, formInputs.det1],
      ([sev1, occ1, det1]) => {
        formInputs.rpn1 = sev1 * occ1 * det1;
      }
    );

    watch(
      () => [formInputs.sev2, formInputs.occ2, formInputs.det2],
      ([sev2, occ2, det2]) => {
        formInputs.rpn2 = sev2 * occ2 * det2;
      }
    );

    const loadLines = async () => {
        uniqueLines.value = await fetchData('pfmea_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines();  

    return {
      dbTableName, formInputs, submitForm, closeModal, isProcessing, uniqueLines, saveStatus, dataForm, handleSubmit
    };
  },

  props: { rowId: { type: Number, required: true, }, },
};
</script>
      
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }     
    .modal-wrapper { width: 100%;  max-width: 1200px; }  
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }  
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
    .modal-header h3 { font-size: 1.2rem; }
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
        
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }  
    
    .readonly { margin-left: 5px; margin-right: 5px; background-color: #f0f0f0; width: 100px;} 
    .rpnInput { margin-left: 5px; margin-right: 5px; background-color: #f0f0f0; width: 100px; } 
    .itemInput { margin-left: 5px; margin-right: 5px; width: 100px;} 
    .itemSelect { margin-left: 0px; margin-right: 0px; } 
    .revisionInput { width: 50px; size: 8px; margin-left: 10px; }          
    .contentsInput { margin-left: 5px; margin-right: 5px; width: 550px; } 
    .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
    .lineTable {width: 400px;}
    .lineTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; width: 100px !important;}
    .lineTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
    .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
   
    .disabled { background-color: #ccc; cursor: not-allowed; }
    .addLineButton {margin-right: 20px; padding: 5px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }    
    .uniquecode {color: red;}
</style>