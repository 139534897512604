<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Weld Lasder Parameter</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>            
              <td>
              <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
              </div> 
              <div class="form-group">
                <label for="start_hour">Time</label>
                <div class="time-inputs">
                    <label for="start_hour">H:</label>
                    <input type="number" id="start_hour" v-model="timeHour" min="0" max="23" />
                    <label for="start_minute">M:</label>
                    <input type="number" id="start_minute" v-model="timeMinute" min="0" max="59" />
                </div>
              </div>   
              <div class="form-group">
                <label for="line">Line:</label>
                <select v-model="formInputs.line">                        
                  <option>EVO 1</option><option>EVO 2</option><option>EVO 3</option>
                  <option>EVO Hybrid</option><option>EV2020 60kW</option><option>EV2020 4060kW</option>
                  <option></option>
                </select>                  
              </div>
              <div class="form-group">
                <label for="technician">Technician:</label>
                <input id="technician" v-model="formInputs.technician" />                   
              </div>
              </td>
              <td> 
              <div class="form-group">
                <label>Front / Rear:</label>
                <div>                  
                  <input class="radio" type="radio" value="Front" v-model="formInputs.frontrear">
                  <label>Front</label>
                  <input class="radio" type="radio" value="Rear" v-model="formInputs.frontrear">
                  <label>Rear</label>                  
                </div>
              </div>   
              <div class="form-group">
                <label for="part">Parameter Number:</label>
                <div>                  
                  <input class="radio" type="radio" value="Parameter 1" v-model="formInputs.parameternumber">
                  <label>Parameter 1</label>
                  <input class="radio"  type="radio" value="Parameter 2" v-model="formInputs.parameternumber">
                  <label>Parameter 2</label>                  
                </div>            
              </div>     
              <div class="form-group">
                <label for="power">Power(W or %):</label>
                <input id="power" v-model="formInputs.power" />
              </div>     
              <div class="form-group">
                <label for="drawspeed">Draw Speed(mm/s):</label>
                <input id="drawspeed" v-model="formInputs.drawspeed" />
              </div>   
              <div class="form-group">
                <label for="frequency">Frequency(Hz):</label>
                <input id="frequency" v-model="formInputs.frequency" />
              </div>               
                <div class="form-group" style="display: flex; flex-direction: column;">
                  <label for="remark">Remark:</label>
                  <textarea id="remark" v-model="formInputs.remark" rows="4" cols="25"></textarea>              
                </div>                       
              </td>
            </tr>
          </table>                     
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>  
  import { reactive, computed,} from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate } from "../../../utils.js"; 
   
  export default {     

    setup(props, { emit }) {    
        const dbTableName = "parabfaweldlaser"  

        const formInputs = reactive({
          time: "", line: "", frontrear: "", parameternumber: "", power: "", drawspeed: "", frequency: "", technician: "", remark: "",          
        });    
        const columnMapping = {
          year:'year', month: 'month', day: 'day', time: 'time', line: 'line', frontrear: 'frontrear',
          parameternumber: 'parameternumber', power: 'power', drawspeed: 'drawspeed', frequency: 'frequency',
          technician: 'technician', remark: 'remark',
        }
  
        const timeHour = computed({
          get: () => {
            const [hour] = formInputs.time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.time.split(":");
            formInputs.time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const timeMinute = computed({
          get: () => {
            const [, minute] = formInputs.time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.time.split(":");
            formInputs.time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });

        const searchDate = reactive({ ...getCurrentDate(), });    

        const submitForm = async () => {
          if (!formInputs.line || !formInputs.frontrear || !formInputs.parameternumber ) {
            alert("Please fill in all the fields: Line, front/end and Parameter Number.");
            return;
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,              
              time: formInputs.time,
              line: formInputs.line,
              frontrear: formInputs.frontrear,
              parameternumber: formInputs.parameternumber,              
              power: formInputs.power,
              drawspeed: formInputs.drawspeed,              
              frequency: formInputs.frequency,              
              technician: formInputs.technician,   
              remark: formInputs.remark,           
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        }; 
          
          return { searchDate, dbTableName, formInputs, submitForm, closeModal, timeHour, timeMinute, 
          };    
        },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },            
    }, 
  };
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }
  
  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 800px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }

  .fileUploadButton:hover { background-color: #5f5f5f; }    

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input { width: 70px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }
 
  .radio { margin-left: 20px; margin-right: 5px; }
  .disabled { background-color: #ccc; cursor: not-allowed; }
</style>