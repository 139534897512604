import axios from 'axios';
import { reactive } from 'vue';

export const tableNames = [      
    "dailyinventory", "stocksap", 
];

export const tableDataMap = tableNames.reduce((map, tableName) => {
    map[tableName] = reactive({ data: [] });
    return map;
  }, {});
  
export const stockSearchData = async (sendDateData) => {  

const formData = {
    tableNames: tableNames.join(','),
    values: {
    year: sendDateData[0],
    month: sendDateData[1],
    day: sendDateData[2],         
    }
};  

try {
    const response = await axios.get('/api/productionData', { params: formData });
    const data = response.data;

    data.forEach(item => {
    if (tableDataMap[item.tableName]) {
        tableDataMap[item.tableName].data = item.data.sort((a, b) => Number(a.row_no) - Number(b.row_no));
    } else {
        console.warn(`Unknown table name: ${item.tableName}`);
    }
    });

    return tableDataMap;
} catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
}
};