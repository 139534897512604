<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>EOL Connector PIN</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
            <tr>            
              <td>
              <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
              </div> 
              <div class="form-group">
                <label for="start_hour">Time</label>
                <div class="time-inputs">
                    <label for="start_hour">H:</label>
                    <input type="number" id="start_hour" v-model="timeHour" min="0" max="23" />
                    <label for="start_minute">M:</label>
                    <input type="number" id="start_minute" v-model="timeMinute" min="0" max="59" />
                </div>
              </div>      
              </td>
              <td>
              <div class="form-group">
                <label for="line">Line:</label>
                <select id="line" v-model="formInputs.line">
                  <option>TR3 FPCA</option><option>EVO Hybrid</option><option>EVO 1</option>
                  <option>EVO 2</option><option>EVO 3</option>
                </select>              
              </div>
              <div class="form-group">
              <label for="station">Station:</label>                          
              <select id="station" v-model="formInputs.station">
                <option v-for="option in stationOptions" :key="option">{{ option }}</option>
              </select>
            </div>     
              <div class="form-group">
                <label for="part">Part:</label>
                <input type="text" id="part" v-model="formInputs.part" readonly class="readWindow" />              
              </div>
                <div class="form-group">
                  <label for="pin">Pin No.:</label>
                  <input type="text" id="pin" v-model="formInputs.pin" />
                </div>                  
                <div class="form-group" style="display: flex; flex-direction: column;">
                  <label for="remark">Remark:</label>
                  <textarea id="remark" v-model="formInputs.remark" rows="4" cols="25"></textarea>              
                </div>                       
              </td>
            </tr>
          </table>                                 
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref} from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, } from "../../utils.js"; 
   
  export default {                
    setup(props, { emit }) {    
        const dbTableName = "paraeolconnectorpin"   
        const saveStatus = ref(''); 
        const isProcessing = ref(false);                    
        const formInputs = reactive({
          time: "",
          line: "",
          station: "",          
          part: "Connector",
          pin: "",
          remark: "",          
        });
        
        const timeHour = computed({
          get: () => {
            const [hour] = formInputs.time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.time.split(":");
            formInputs.time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const timeMinute = computed({
          get: () => {
            const [, minute] = formInputs.time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.time.split(":");
            formInputs.time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        }); 

        const searchDate = reactive({ ...getCurrentDate(), });          
       
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.line) {
            alert("Please fill in all the fields: year, month, day and line.");
            return;
          }
          const columnMapping = { year:'year', month: 'month', day: 'day',time: 'time', 
                                  line: 'line', station: 'station', part: 'part', pin: 'pin', remark: 'remark', }

          isProcessing.value = true;
          saveStatus.value = 'Processing...';

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,              
              time: formInputs.time,
              line: formInputs.line,
              station: formInputs.station,                            
              part: formInputs.part,
              pin: formInputs.pin,
              remark: formInputs.remark,              
            }
         };

          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }     
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); }; 
        
        const stationOptions = ref([]); 

        const updateStation = () => {
          console.log("updateStation called, line:", formInputs.line);
            if (formInputs.line === 'TR3 FPCA') { stationOptions.value = ['EOL-A', 'EOL-B'];}
            else if (formInputs.line === 'EVO Hybrid') { stationOptions.value = ['EOL-1']; }
            else if (formInputs.line === 'EVO 1') { stationOptions.value = ['EOL-1']; }
            else if (formInputs.line === 'EVO 2') { stationOptions.value = ['EOL-1']; }
            else if (formInputs.line === 'EVO 3') { stationOptions.value = ['EOL-1']; }

            else { stationOptions.value = ['Please select a line']; }            
        };        

        watch(() => [formInputs.line, formInputs.station], updateStation);

          
          return { searchDate, dbTableName, formInputs, submitForm, closeModal, 
                  timeHour, timeMinute, updateStation, stationOptions,  
                  isProcessing, saveStatus
                 };    
        },     

    props: { onSubmit: { type: Function, required: true,}, }, 
  };
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 700px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 100px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }
  .disabled { background-color: #ccc; cursor: not-allowed; }
</style>