<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add PFMEA</h3>
          <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
          {{ saveStatus }}
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="lineTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line</td>
                <td>
                  <select v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                  </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Revision No.<br>(00)</td>
                <td>
                  <input class="revisionInput" type="text" v-model="formInputs.revision" />
                </td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--Class Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">              
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process No.</td>                  
                <td style="text-align: left;"><input class="itemInput" type="text" v-model="formInputs.process" /></td>                                 
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process Name</td>                                    
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.process_name" rows="3" cols="40"></textarea></td>                                
              </tr>
            </table><br>   
            
            <div v-for="(item, index) in items" :key="index">
              <b class="uniquecode" >Unique Code: Once specified as a unique value for this item, it cannot be changed, and it must not overlap in the same Line.</b>
              <table class="itemTable">
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #A8D1FF; padding: 5px 5px;">Unique Code</td>
                  <td colspan="2" style="text-align: left;">
                    <input class="itemInput" type="text" v-model="item.uniquecode" />
                    <button class="uniqueButton" @click.prevent="checkUniqueCode(item.uniquecode, item)">Check</button>                     
                  </td>           
                  <td colspan="6" style="font-size: 12px; text-align:left">{{item.uniqueCodeAvailable}}</td>       
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #F9FDA2; padding: 5px 5px;">Requirements</td>                  
                  <td colspan="8" style="text-align: left;"><textarea id="contents" v-model="item.requirements" rows="3" cols="100"></textarea></td>                  
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td rowspan="2" style="background-color: #F9FDA2; padding: 5px 5px;">Potential</td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Failure Mode</td>
                  <td style="text-align: left;"><textarea id="contents" v-model="item.potential_failure" rows="3" cols="30"></textarea></td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Effects of Failure</td>
                  <td style="text-align: left;"><textarea id="contents" v-model="item.potential_effects" rows="3" cols="30"></textarea></td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Caues/Mechanisms<br>of Failure</td>
                  <td style="text-align: left;"><textarea id="contents" v-model="item.potential_caues" rows="3" cols="30"></textarea></td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">SEV</td>
                  <td style="text-align: left;">
                    <select class="itemSelect" v-model="item.sev1">
                      <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                     </select>
                  </td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">CLASS</td>
                  <td style="text-align: left;">
                    <select class="itemSelect" v-model="item.class1">
                      <option>O</option><option></option>
                    </select>
                  </td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td rowspan="2" style="background-color: #F9FDA2; padding: 5px 5px;">Current<br>Process<br>Control</td>
                  <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Prevention</td>
                  <td colspan="1" style="text-align: left;"><textarea id="contents" v-model="item.prevention" rows="3" cols="30"></textarea></td>                
                  <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Detection</td>
                  <td colspan="3" style="text-align: left;"><textarea id="contents" v-model="item.detection" rows="3" cols="80"></textarea></td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">OCC</td>
                  <td style="text-align: left;">
                    <select class="itemSelect" v-model="item.occ1">
                      <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                     </select>
                  </td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">DET</td>
                  <td style="text-align: left;">
                    <select class="itemSelect" v-model="item.det1">
                      <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                    </select>
                  </td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td rowspan="1" style="background-color: #F9FDA2; padding: 5px 5px;">RPN</td>                                             
                  <td colspan="1" style="text-align: left;"><input class="rpnInput" v-model="item.rpn1" readonly/></td>   
                </tr>               
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td rowspan="2" style="background-color: #347003; color: white; padding: 5px 5px;">Action</td>                  
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Recommended<br>Action</td>
                  <td style="text-align: left;"><textarea id="contents" v-model="item.recommended" rows="3" cols="30"></textarea></td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Responsibility<br>and Target<br>Completion<br>Date</td>
                  <td style="text-align: left;"><textarea id="contents" v-model="item.responsibility" rows="3" cols="30"></textarea></td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Action<br>Taken</td>
                  <td style="text-align: left;"><textarea id="contents" v-model="item.actions" rows="3" cols="30"></textarea></td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">SEV</td>
                  <td style="text-align: left;">
                    <select class="itemSelect" v-model="item.sev2">
                      <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                     </select>
                  </td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">OCC</td>
                  <td style="text-align: left;">
                    <select class="itemSelect" v-model="item.occ2">
                      <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                    </select>
                  </td>
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">DET</td>
                  <td style="text-align: left;">
                    <select class="itemSelect" v-model="item.det2">
                      <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option><option>10</option><option></option>
                     </select>
                  </td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td rowspan="1" style="background-color: #347003; color: white; padding: 5px 5px;">RPN</td>                  
                  <td style="text-align: left;">
                    <input class="rpnInput" v-model="item.rpn2" readonly/>
                  </td>                  
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">                
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Row No.</td>
                  <td style="text-align: left;"><input class="itemInput" type="text" v-model="item.row_no" /></td>
                </tr>                
              </table><br>
            </div>

            <table class="itemTable">
              
            </table><br>
            
            <button class="addLineButton" @click.prevent="addItem">+</button>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { ref, reactive, watch } from 'vue';
import axios from 'axios';
import { getColumnMapping, makeFormData, fetchData } from "../utils.js";

export default {
  setup(props, { emit }) {
    const dbTableName = "pfmea";
    const isProcessing = ref(false);
    const saveStatus = ref('');     
    const uniqueLines = ref([]);    
    const items = ref([{ uniquecode: '', uniqueCodeOk: false, uniqueCodeAvailable: '', 
          sev1: "", occ1: "", det1: "", rpn1: "",
          sev2: "", occ2: "", det2: "", rpn2: "",   
    }]);
          
    const formInputs = reactive({
      line: '',
      revision: '',
      parts: '',
      process: '',
      process_name: '',
      machine: '',
      reaction: ''      
    });

    watch(items, (newItems) => {
      newItems.forEach(item => {
        item.rpn1 = item.sev1 * item.occ1 * item.det1;
        item.rpn2 = item.sev2 * item.occ2 * item.det2;
      });
    }, { deep: true });

    const submitForm = async () => {      

      // Unique Code 중복 검사
      const uniqueCodes = items.value.map(item => item.uniquecode);
      const hasDuplicate = uniqueCodes.some((code, index) => uniqueCodes.indexOf(code) !== index);

      if (hasDuplicate) {
        alert("There is same Unique Code. Please Check it.");
        isProcessing.value = false;
        return;
      }

      const allUniqueCodesChecked = items.value.every(item => item.uniqueCodeOk);

      if (!allUniqueCodesChecked) {
        alert("Please check unique code.");
        isProcessing.value = false;
        return;
      }

      isProcessing.value = true;
      saveStatus.value = 'Processing...';

      for (const item of items.value) {
        
        const filteredItem = { ...item };
        delete filteredItem.uniqueCodeOk;
        delete filteredItem.uniqueCodeAvailable;  
        
        const columnMapping = getColumnMapping(undefined, { ...formInputs, ...filteredItem });
        const formData = makeFormData(undefined, { ...formInputs, ...filteredItem }, dbTableName, columnMapping);       

        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        }
      }

      isProcessing.value = false;
      closeModal();
    };

    const addItem = () => { items.value.push({ uniquecode: '', uniqueCodeOk: false, uniqueCodeAvailable: '',
          sev1: 1, occ1: 1, det1: 1, rpn1: 1,
          sev2: "", occ2: "", det2: "", rpn2: "",    
     }); };   

    const checkUniqueCode = async (uniquecode, item) => {
      try {
        const response = await axios.get('/api/targetList', { params: { tableName: dbTableName, uniquecode } });
        if (response.data.length > 0) {          
          item.uniqueCodeOk = false;
          item.uniqueCodeAvailable = "Database have same Unique Code. Please change Unique Code."
        } else {          
          item.uniqueCodeOk = true;
          item.uniqueCodeAvailable = "Unique Code is available."
        }
      } catch (error) {
        console.error('Error during unique code check:', error);
      }
    };  

    const closeModal = () => { emit("onClose"); };

    const loadLines = async () => {
        uniqueLines.value = await fetchData('pfmea_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines();  

    return {
      dbTableName,
      formInputs,
      submitForm,
      closeModal,
      isProcessing,
      uniqueLines,
      items,
      addItem,
      saveStatus,
      checkUniqueCode,    
    };
  },
};
</script>
      
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }     
    .modal-wrapper { width: 100%;  max-width: 1200px; }  
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }  
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
    .modal-header h3 { font-size: 1.2rem; }
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
        
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }  
     
    .itemInput { margin-left: 5px; margin-right: 5px; width: 100px;} 
    .rpnInput { margin-left: 5px; margin-right: 5px; background-color: #f0f0f0; width: 100px; } 
    .itemSelect { margin-left: 0px; margin-right: 0px; } 
    .revisionInput { width: 50px; size: 8px; margin-left: 10px; }          
    .contentsInput { margin-left: 5px; margin-right: 5px; width: 550px; } 
    .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
    .lineTable {width: 400px;}
    .lineTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; width: 100px !important;}
    .lineTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
    .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
   
    .disabled { background-color: #ccc; cursor: not-allowed; }
    .addLineButton {margin-right: 20px; padding: 5px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }    
    .uniquecode {color: red;}
    .uniqueButton { padding: 5px 5px; background-color: #fad369; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
</style>