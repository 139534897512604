import { reactive } from 'vue';
import axios from 'axios';

const osTarget = reactive({ data: [] });

export const osCostTargetSearchData = async (dateRange) => {
  if (!dateRange || !dateRange.year || !dateRange.month) {
    throw new Error('Invalid date range provided');
  }

  const formData = {
    tableName: "oscostrolling",
    year: dateRange.year,
    month: dateRange.month,
  };

  try {
    const res = await axios.get('/api/yearMonth', { params: formData });
    if (res.data && Array.isArray(res.data.data)) {
      const uniqueData = {};

      res.data.data.forEach(item => {
        const key = `${item.year}-${item.month}-${item.line}`;

        if (!uniqueData[key]) {
          uniqueData[key] = { ...item };
        } else {
          // Update each field only if it exists in the current item
          uniqueData[key].cost_pln = item.cost_pln || uniqueData[key].cost_pln;
          uniqueData[key].target_qty = item.target_qty || uniqueData[key].target_qty;
          uniqueData[key].plan_qty = item.plan_qty || uniqueData[key].plan_qty;
        }
      });

      osTarget.data = Object.values(uniqueData);
    } else {
      osTarget.data = [];
      console.error("Unexpected response format:", res.data);
    }
  } catch (error) {
    osTarget.data = [];
    console.error("Error fetching target data:", error.message);
  }
};

export default osTarget;
