<template>
  <div class="barcodePrint">
    <h3>Barcode Print</h3>
    <div>
      <table>
        <tr>
          <th>Material Code</th>
          <th>Batch</th>
          <th>Quantity</th>
          <th>Unit</th>
        </tr>        
        <tr>
          <td class="inputTd"><input v-model="code"  /></td>
          <td class="inputTd"><input v-model="batch"  /></td>
          <td class="inputTd"><input v-model="quantity" /></td>
          <td class="inputTd">
            <select v-model="unit" @input="updateBarcode">
              <option>EA</option><option>G</option><option>ROL</option><option>CM</option>
            </select>
          </td>
        </tr>
      </table>     
       {{ name }}
    </div>
    <br>    
    <div class="svgDiv">
      <table>
        <tr class="svgTr">
          <td>   
           <svg ref="barcodeElement"></svg>
          </td>          
        </tr>
      </table>
    </div>
    <br>
    <div> 
      <button class="printButton" @click="printBarcode">Print Barcode</button>
    </div>
    <br>
    <div>    
      <label>Barcode Size :</label>
        <input v-model="widthSize" type="number" placeholder="Barcode Size"/>
      <label class="barcodeLabel">Barcode :</label>
        <input class="barcodeInput" v-model="barcode" placeholder="Barcode"/> 
    </div>
  </div>
</template>

<script>
import { ref, watch, } from 'vue';
import JsBarcode from 'jsbarcode';
import axios from 'axios';

export default {
  setup() {
    const barcodeElement = ref(null);    
    const barcode = ref('');
    const code = ref('');
    const batch = ref('');    
    const quantity = ref('');
    const unit = ref('EA');
    const widthSize = ref('20');
    const name = ref('');    
    
    const updateBarcode = () => {
      if (barcodeElement.value) {
        const barcodeValue = `${code.value}-${batch.value}-${quantity.value}-${unit.value}`;
        JsBarcode(barcodeElement.value, barcodeValue, {
          format: "CODE128",
          lineColor: "#000",
          width: 2,
          height: 40,
          displayValue: true
        });
      }
    };

    watch(barcode, (newValue) => {
      const parts = newValue.split('-');
      if (parts.length === 4) { 
        code.value = parts[0]; 
        batch.value = parts[1]; 
        quantity.value = parts[2];
        unit.value = parts[3]; 
      }
    });

    watch([code, batch, quantity, unit], updateBarcode, { immediate: true });

    const printBarcode = () => {
      let printWindow = window.open('', 'PRINT', 'height=600,width=800');
      printWindow.document.write('<html><head><title>Print Barcode</title>');
      printWindow.document.write(`<style>svg{width:${widthSize.value}% !important;height:auto !important;}</style>`);      
      printWindow.document.write('</head><body>');
      printWindow.document.write(barcodeElement.value.outerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close(); // 문서 로드를 마칩니다
      printWindow.focus(); // IE와 Firefox에서 필요함
      printWindow.print();
      printWindow.close();
    };

    const fetchMaterialName = () => {
      const materialTableName = 'materiallist';
      const columnName = 'materialcode';
      axios.get('/api/where', { params: { value: code.value, table_name: materialTableName, column_name: columnName } })
        .then((res) => {          
          if (res.data && res.data.length > 0) {
            name.value = res.data[0].materialname;
          } else {
            name.value = "No Data";
          }
        })
        .catch((error) => {
          console.error('Error fetching material name:', error.message);
          name.value = "";
        });
    };
    
    watch(() => code.value, () => { fetchMaterialName(); });

    return {
      barcodeElement, 
      barcode,
      code,
      batch,
      quantity,
      unit,
      name,
      widthSize,
      updateBarcode,
      printBarcode,   
    };
  }
};
</script>

<style scoped>
  .barcodePrint {margin-left:20px;}
  svg {
    max-width: 100%; /* SVG의 크기를 조절하려면 이 스타일을 사용하세요 */
    height: auto;
  }
  table { width : 500px}
  th { border: 1px solid rgb(122, 122, 122); background-color: rgb(214, 214, 214); text-align: center; font-size: 20px; font-weight: bold;}
  .inputTd { border: 1px  solid rgb(122, 122, 122);}
  .svgDiv { margin-left:150px; }
  .svgTr { border: 1px  solid rgb(122, 122, 122); text-align: center;}
  input { width: 200px; text-align: center; font-size: 20px; font-weight: bold; margin-left: 10px;}
  select { width: 200px; text-align: center; font-size: 20px; font-weight: bold;}
  .printButton {
    background-color: #f6d99b; color: rgb(0, 0, 0); padding: 30px 300px; border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 30px; font-weight: bold; display: inline-block; transition-duration: 0.4s;  
  }  
  .barcodeLabel {margin-left: 20px;}
  .barcodeInput { width: 300px; text-align: center; font-size: 15px; font-weight: bold; padding: 5px;}
</style>
