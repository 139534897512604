
import { reactive, computed, watchEffect, } from 'vue';
import { productionData, addData } from './data.js';

const smtLaserTableData = reactive({ data: [] });
const bfaQrLaserTableData = reactive({ data: [] });
const bfaWeldLaserTableData = reactive({ data: [] });

watchEffect(() => { smtLaserTableData.data = addData[7].data;});
watchEffect(() => { bfaQrLaserTableData.data = addData[8].data;});
watchEffect(() => { bfaWeldLaserTableData.data = addData[9].data;});


const okTR2SMT = () => {const tr2Smt = productionData.find(row => row.line === 'TR2 SMT'); return tr2Smt ? tr2Smt.ok : 0;};
const okTR3SMT = () => {const tr3Smt = productionData.find(row => row.line === 'TR3 SMT'); return tr3Smt ? tr3Smt.ok : 0;};
const okEVO1 = () => {const evo1 = productionData.find(row => row.line === 'EVO 1'); return evo1 ? evo1.ok : 0;};
const okEVO2 = () => {const evo2 = productionData.find(row => row.line === 'EVO 2'); return evo2 ? evo2.ok : 0;};
const okEVO3 = () => {const evo3 = productionData.find(row => row.line === 'EVO 3'); return evo3 ? evo3.ok : 0;};
const okEVOH = () => {const evoh = productionData.find(row => row.line === 'EVO Hybrid'); return evoh ? evoh.ok : 0;};
const okEV202060FR = () => {const ev202060fr = productionData.find(row => row.line === 'EV2020 60kW Front'); return ev202060fr ? ev202060fr.ok : 0;};
const okEV20204060FR = () => {const ev20204060fr = productionData.find(row => row.line === 'EV2020 4060kW Front'); return ev20204060fr ? ev20204060fr.ok : 0;};
const okEV202060RR = () => {const ev202060rr = productionData.find(row => row.line === 'EV2020 60kW Rear'); return ev202060rr ? ev202060rr.ok : 0;};
const okEV20204060RR = () => {const ev20204060rr = productionData.find(row => row.line === 'EV2020 4060kW Rear'); return ev20204060rr ? ev20204060rr.ok : 0;};


const notYetChecked = computed(() => {
  const tr2SmtOk = okTR2SMT();
  const tr3SmtOk = okTR3SMT();  
  const evo1Ok = okEVO1();  
  const evo2Ok = okEVO2();  
  const evo3Ok = okEVO3();  
  const evohOk = okEVOH();  
  const ev202060FROk = okEV202060FR();  
  const ev20204060FROk = okEV20204060FR();  
  const ev202060RROk = okEV202060RR();  
  const ev20204060RROk = okEV20204060RR(); 

  const tr2SmtC = smtLaserTableData.data.some(row => row.line === "TR2 SMT(1st factory)"); 
  const tr3SmtC = smtLaserTableData.data.some(row => row.line === "TR3 SMT(2nd factory)");
  const evo1QrC = bfaQrLaserTableData.data.some(row => row.line === "EVO 1");
  const evo1WeldC = bfaWeldLaserTableData.data.some(row => row.line === "EVO 1");
  const evo2QrC = bfaQrLaserTableData.data.some(row => row.line === "EVO 2");
  const evo2WeldC = bfaWeldLaserTableData.data.some(row => row.line === "EVO 2");
  const evo3QrC = bfaQrLaserTableData.data.some(row => row.line === "EVO 3");
  const evo3WeldC = bfaWeldLaserTableData.data.some(row => row.line === "EVO 3");
  const evohQrC = bfaQrLaserTableData.data.some(row => row.line === "EVO Hybrid");
  const evohWeldC = bfaWeldLaserTableData.data.some(row => row.line === "EVO Hybrid");
  const ev202060QrC = bfaQrLaserTableData.data.some(row => row.line === "EV2020 60kW");
  const ev202060WeldC = bfaWeldLaserTableData.data.some(row => row.line === "EV2020 60kW");
  const ev202040QrC = bfaQrLaserTableData.data.some(row => row.line === "EV2020 4060kW");
  const ev202040WeldC = bfaWeldLaserTableData.data.some(row => row.line === "EV2020 4060kW");

  if ((tr2SmtOk > 0 && !tr2SmtC) || 
      (tr3SmtOk > 0 && !tr3SmtC) ||
      (evo1Ok > 0 && (!evo1QrC || !evo1WeldC)) ||
      (evo2Ok > 0 && (!evo2QrC || !evo2WeldC)) ||
      (evo3Ok > 0 && (!evo3QrC || !evo3WeldC)) ||
      (evohOk > 0 && (!evohQrC || !evohWeldC)) || 
      (ev202060FROk > 0 && (!ev202060QrC || !ev202060WeldC)) ||
      (ev20204060FROk > 0 && (!ev202040QrC || !ev202040WeldC)) ||
      (ev202060RROk > 0 && (!ev202060QrC || !ev202060WeldC)) ||
      (ev20204060RROk > 0 && (!ev202040QrC || !ev202040WeldC))) {
    return true;
  }

  return false;
});

export const parameterColor = computed(() => {
  if (notYetChecked.value) {
    return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // 빨간색
  } 
  return { backgroundColor: 'transparent' }; // Transparent color
});