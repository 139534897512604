<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Attendance</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
                  <div class="form-group date-time-group">
                    <label>Year/Month/Day:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.year"  />
                      <input type="text" id="month" v-model="formInputs.month" />
                      <input type="text" id="day" v-model="formInputs.day" />                  
                    </div> 
                  </div>      
                  <div class="form-group">
                    <label for="shift">Shift:</label>
                    <input type="number" id="shift" v-model="formInputs.shift" />
                  </div>
                  <div class="form-group">
                    <label for="start_hour">Start time</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="start_hour" v-model="startHour" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="start_minute" v-model="startMinute" min="0" max="59" />
                    </div>
                  </div>            
                  <div class="form-group">
                    <label for="end_hour">End time</label>
                    <div class="time-inputs">
                        <label for="end_hour">H:</label>
                        <input type="number" id="end_hour" v-model="endHour" min="0" max="23" />
                        <label for="end_minute">M:</label>
                        <input type="number" id="end_minute" v-model="endMinute" min="0" max="59" />
                    </div>
                  </div>  
                  <div class="time">
                    <label for="time">Time:</label>
                    <input type="number" id="time" v-model="formInputs.worktime" readonly class="readWindow" />              
                  </div>
                  </td>
                  <td>
                    <div class="form-group">
                    <label for="employeenumber">Employee Number:</label>
                    <input id="employeenumber" v-model="formInputs.employeenumber" />
                  </div>            
                  <div class="form-group">
                    <label class="modalLabel" for="oscompany">OS Company:</label>
                    <select class="modalSelect" id="oscompany" v-model="formInputs.oscompany">
                      <option v-for="oscompany in uniqueCompany" :key="oscompany">{{ oscompany }}</option><option></option>   
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" id="name" v-model="formInputs.name" />
                  </div>
                  <div class="form-group">
                    <label for="surname">Surname:</label>
                    <input type="text" id="surname" v-model="formInputs.surname" />
                  </div>
                </td>                               
                <td>
                  <div class="form-group">
                    <label for="factory">Factory:</label>
                    <select id="factory" v-model="formInputs.factory">
                      <option>1</option><option>2</option>
                    </select>
                  </div>   
                  <div class="form-group">
                    <label for="team">Team:</label>
                    <select id="team" v-model="formInputs.team">    
                      <option>Production 1st</option><option>Production 2nd</option><option>QA</option><option>Warehouse</option><option>Inkatom</option>
                    </select>
                  </div>   
                  <div class="form-group">
                    <label for="line">Line:</label>
                    <select class="modalSelect" id="line" v-model="formInputs.line">
                      <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
                    </select>                   
                  </div>
                  <div class="form-group">
                    <label for="process">Process:</label>
                    <select class="modalSelect" id="process" v-model="formInputs.process">
                      <option v-for="process in uniqueProcess" :key="process">{{ process }}</option><option></option>        
                    </select>  
                  </div>  
                  <div class="form-group">
                    <label for="position">Position:</label>
                    <select class="modalSelect" id="position" v-model="formInputs.position">
                      <option v-for="position in uniquePosition" :key="position">{{ position }}</option><option></option> 
                    </select>
                  </div> 
                  <div class="form-group">
                    <label for="remark">Remark:</label>
                    <input type="text" id="remark" v-model="formInputs.remark" />
                  </div> 
                </td>
              </tr>
            </table>         
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch,  ref } from 'vue';  
  import axios from 'axios';   
  import { fetchLine, fetchProcess, fetchPosition, fetchCompany} from "../../utils.js"; 
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "attendanceweekend";
        const uniqueLines = ref([]);     
        const uniqueProcess = ref([]);   
        const uniquePosition = ref([]);
        const uniqueCompany = ref([]);  
        const startHour = ref(0);
        const startMinute = ref(0);
        const endHour = ref(0);
        const endMinute = ref(0);                 
        const formInputs = reactive({   
          id: "",
          factory: "",
          shift: "",
          oscompany: "",
          employeenumber: "",
          name : "",
          surname : "",
          team : "",
          line : "",
          process : "",
          position : "",
          start_time : "",      
          end_time : "",
          worktime : "",
          remark : "",
          confirm: "not yet",          
        });

        const calculateTimeDifference = (start_time, end_time) => {
          if (!start_time || !end_time) {
            return "";
          }
          const [startHour, startMinute] = start_time.split(":").map(Number);
          const [endHour, endMinute] = end_time.split(":").map(Number);

          const startDate = new Date();
          startDate.setHours(startHour, startMinute);

          const endDate = new Date();
          endDate.setHours(endHour, endMinute);

          let timeDifference = (endDate - startDate) / 1000 / 60;

          if (timeDifference < 0) {
            timeDifference += 24 * 60;
          }

          timeDifference = Math.floor(timeDifference);

          return timeDifference;
        };

        watch(
            () => [formInputs.start_time, formInputs.end_time],
            ([start_time, end_time]) => {
              // Update shift based on start_time
              const startHourValue = start_time.split(":")[0];
              formInputs.shift = startHourValue >= 6 && startHourValue <= 17 ? 1 : 2;

              // Update time based on start_time and end_time
              formInputs.worktime = calculateTimeDifference(start_time, end_time);
            }
          );

          const updateStartTime = () => {
            formInputs.start_time = `${startHour.value.toString().padStart(2, "0")}:${startMinute.value.toString().padStart(2, "0")}`;
          };

          const updateEndTime = () => {
            formInputs.end_time = `${endHour.value.toString().padStart(2, "0")}:${endMinute.value.toString().padStart(2, "0")}`;
          };

          watch(startHour, updateStartTime);
          watch(startMinute, updateStartTime);
          watch(endHour, updateEndTime);
          watch(endMinute, updateEndTime);

        const loadLines = async () => {
          const lines = await fetchLine();
          const excludedLines = [ "2nd Factory Compressors", "2nd Factory Boilers", "2nd Factory Dust collectors", "2nd Factory AHU", "2nd Factory Transformer",
                                  "1st Factory Compressor 1", "1st Factory Compressor 2", "1st Factory Boiler 1", "1st Factory Boiler 2", "1st Factory Dust collector 1",
                                  "1st Factory Dust collector 2", "1st Factory Dust collector 3", "1st Factory Dust collector 4", "1st Factory NEW AHU",
                                  "1st Factory OLD AHU", "1st Factory AHU SMT", "1st Factory Transformer",
                                  "Packing", "Production Logistic", "Maintenance", "Leader", "Technician"
          ];
          uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
        }; loadLines();
        const loadProcess = async () => { uniqueProcess.value = await fetchProcess(); }; loadProcess();
        const loadPosition = async () => { uniquePosition.value = await fetchPosition(); }; loadPosition();
        const loadCompany = async () => { uniqueCompany.value = await fetchCompany(); }; loadCompany();

        const submitForm = async () => {
          if (!formInputs.employeenumber || !formInputs.oscompany ) {
            alert("Please fill in all the fields: Employee Number, OS Company.");
            return;
          }
          const columnMapping = {              
            factory: "factory",
            year: 'year',
            month: 'month',
            day: 'day',
            shift: "shift",
            oscompany: "oscompany",
            employeenumber: "employeenumber",
            name : "name",
            surname : "surname",
            team : "team",
            line : "line",
            process : "process",
            position : "position",
            start_time : "start_time",      
            end_time : "end_time",
            worktime : "worktime",
            remark : "remark",
            confirm: "confirm"                
          }     

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {              
              factory: formInputs.factory,
              year: formInputs.year,
              month: formInputs.month,
              day: formInputs.day,
              shift: formInputs.shift,
              oscompany : formInputs.oscompany,
              employeenumber : formInputs.employeenumber,              
              name : formInputs.name,
              surname : formInputs.surname,
              team : formInputs.team,
              line : formInputs.line,
              process : formInputs.process,
              position : formInputs.position,
              start_time : formInputs.start_time,      
              end_time : formInputs.end_time,
              worktime : formInputs.worktime,
              remark : formInputs.remark,
              confirm: formInputs.confirm
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }
         
         await axios.put(`/api/materialput/${id}`, formData);         
         closeModal();
        };
        const closeModal = () => {
          emit("onClose");
        };   

        const parseTimeString = (timeString) => {
            const [hour, minute] = timeString.split(':');
            return { hour: parseInt(hour, 10), minute: parseInt(minute, 10) };
          };
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];


              formInputs.id = rowData.id;
              formInputs.factory = rowData.factory;
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,
              formInputs.shift = rowData.shift,
              formInputs.oscompany = rowData.oscompany,
              formInputs.employeenumber = rowData.employeenumber,              
              formInputs.name = rowData.name,
              formInputs.surname = rowData.surname,
              formInputs.team = rowData.team,
              formInputs.line = rowData.line,
              formInputs.process = rowData.process,
              formInputs.position = rowData.position,
              //formInputs.start_time = rowData.start_time,      
              //formInputs.end_time = rowData.end_time,
              //formInputs.worktime = rowData.worktime,
              formInputs.remark = rowData.remark,
              formInputs.confirm = rowData.confirm       
              
              const { hour: startHourValue, minute: startMinuteValue } = parseTimeString(rowData.start_time);
              const { hour: endHourValue, minute: endMinuteValue } = parseTimeString(rowData.end_time);

              startHour.value = startHourValue;
              startMinute.value = startMinuteValue;
              endHour.value = endHourValue;
              endMinute.value = endMinuteValue;

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  
          

      return { dbTableName, formInputs, submitForm, closeModal,
              calculateTimeDifference, startHour, startMinute, endHour, endMinute, 
              uniqueLines, uniqueProcess, uniquePosition, uniqueCompany                
            };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 1000px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 80px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>