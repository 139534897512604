<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">          
          <h3>Add Inventory Check</h3>              
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
              <label>Year/Month/Day:</label>
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="formInputs.year"  />
                <input type="text" id="month" v-model="formInputs.month" />
                <input type="text" id="day" v-model="formInputs.day" />                  
              </div> 
            </div>                                                       
            <div class="form-group">
              <label for="factory">Factory:</label>
              <select id="factory" v-model="formInputs.factory">            
                <option>1</option><option>2</option>
              </select>                            
            </div>
            <div class="form-group">
              <label for="area">Area:</label>
              <input type="text" id="area" v-model="formInputs.area" />               
            </div>
            <div class="form-group">
              <label for="serialno">Serial No:</label>
              <input type="text" id="serialno" v-model="formInputs.serialno" />               
            </div>
            <div class="form-group">
              <label for="locationcode">Location Code:</label>
              <input type="text" id="locationcode" v-model="formInputs.locationcode" />               
            </div>
            <div class="form-group">
              <label for="locationname">Location Name:</label>
              <input type="text" id="locationname" v-model="formInputs.locationname" readonly class="readWindow" />               
            </div>
            <div class="form-group">
              <label for="state">State:</label>
              <select id="state" v-model="formInputs.state">            
                <option>Good</option><option>NG</option><option>Verification</option>
              </select>                            
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="materialcode">Material Code:</label>
              <input type="text" id="materialcode" v-model="formInputs.materialcode"/>
            </div>                
            <div class="form-group">
              <label for="materialname">Material Name:</label>
              <input type="text" id="materialname" v-model="formInputs.materialname" readonly class="readWindow" />
            </div>                          
            <div class="form-group">
              <label for="batch">Batch:</label>
              <input type="text" id="batch" v-model="formInputs.batch"  />
            </div>  
            <div class="form-group">
              <label for="quantity">Quantity:</label>
              <input type="text" id="quantity" v-model="formInputs.quantity"  />
            </div>  
            <div class="form-group">
              <label for="unit">Unit:</label>
              <select id="unit" v-model="formInputs.unit">            
                <option>EA</option><option>G</option><option>ROL</option><option>CM</option><option></option>
              </select>              
            </div>
            <div class="form-group">
              <label for="remark">Remark:</label>
              <input type="text" id="remark" v-model="formInputs.remark"  />
            </div>  
          </td>
          </tr>
        </table>        
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                      
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, ref } from 'vue';    
  import axios from 'axios';  
  import { fetchmaterialname } from "../../utils.js"; 

  export default {    
    setup(props, { emit }) {          
        const saveStatus = ref(''); 
        const isProcessing = ref(false);  
        const dbTableName = "stockinventory"     
        const formInputs = reactive({
          id: "",
          year: "",
          month: "",
          day: "",
          factory: "",
          area: "",
          serialno: "",
          locationcode: "",
          locationname: "",
          materialcode: "",
          materialname: "",
          batch: "",
          quantity: "",
          unit: "",
          state: "",
          remark: "",                  
        });  

        const submitForm = async () => {         

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = { 
            year: "year",
            month: "month",
            day: "day",
            factory: "factory",
            area: "area",
            serialno: "serialno",
            locationcode: "locationcode",
            locationname: "locationname",
            materialcode: "materialcode",
            materialname: "materialname",
            batch: "batch",
            quantity: "quantity",
            unit: "unit",
            state: "state",
            remark: "remark",
          }      

          const formData = {
            id: formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: formInputs.year,
              month: formInputs.month,
              day: formInputs.day,
              factory: formInputs.factory,
              area: formInputs.area,
              serialno: formInputs.serialno,
              locationcode: formInputs.locationcode,
              locationname: formInputs.locationname,              
              materialcode: formInputs.materialcode,
              materialname: formInputs.materialname,
              batch: formInputs.batch,
              quantity: formInputs.quantity,
              unit: formInputs.unit,
              state: formInputs.state,
              remark: formInputs.remark,
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData); 
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }    
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };   

        watch(() => formInputs.materialcode, () => { fetchmaterialname(formInputs);});

      const locationNames = {
        "1": "TR3 SMT (Cleaning Room)",
        "2": "TR3 Crimping",
        "3": "TR3 FPCA",
        "4": "TR3 FPCA Cabinet",
        "5": "EV2020 4060kW",
        "6": "EV2020 60kW",
        "7": "EVO1",
        "8": "EVO2",
        "9": "EVO Hybrid",
        "10": "Production Warehouse",
        "11": "Finished Product in Warehouse",
        "12": "Disassemble Room",
        "13": "Scrap Room",
        "14": "Near Red Cage",   
      };
      
      const setLocationName = () => {
        if (locationNames[formInputs.locationcode]) {
          formInputs.locationname = locationNames[formInputs.locationcode];
        } else {
          formInputs.locationname = "";
        }
      };

      watch(() => formInputs.locationcode, setLocationName);

      const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id;
              formInputs.factory = rowData.factory;
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,              
              formInputs.area = rowData.area,
              formInputs.serialno = rowData.serialno,
              formInputs.locationcode = rowData.locationcode,
              formInputs.locationname = rowData.locationname,
              formInputs.materialcode = rowData.materialcode,
              formInputs.materialname = rowData.materialname,
              formInputs.batch = rowData.batch,
              formInputs.quantity = rowData.quantity,
              formInputs.unit = rowData.unit,              
              formInputs.state = rowData.state,              
              formInputs.remark = rowData.remark;        

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } ); 

      return { dbTableName, formInputs, submitForm, closeModal, locationNames };

    },
    props: { rowId: { type: Number, required: true, }, }, 
  };   
</script>
    
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 500px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
  </style>