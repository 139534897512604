<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Invest</h3>
          <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}   
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">          
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>    
                  <div class="form-group date-time-group">
                    <label>Year/Month/Day:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.year"  />
                      <input type="text" id="month" v-model="formInputs.month" />
                      <input type="text" id="day" v-model="formInputs.day" />                  
                    </div> 
                  </div>  
                  <div class="form-group">                    
                    <label for="category">Category:</label>
                    <div>
                      <select id="category" v-model="formInputs.category">
                        <option>Current</option><option>Expansion</option><option>IT</option>
                      </select>   
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="reason">Reason:</label>
                    <div>
                      <input type="text" id="reason" v-model="formInputs.reason" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="item">Item:</label>
                    <div>
                      <input type="text" id="item" v-model="formInputs.item" />          
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="subitem">Sub Item:</label>
                    <div>
                      <input type="text" id="subitem" v-model="formInputs.subitem" />          
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="Company">Company:</label>
                    <div>
                      <input type="text" id="company" v-model="formInputs.company" />                  
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="department">Department:</label>                  
                    <div>
                      <input type="text" id="department" v-model="formInputs.department" />                  
                    </div>
                  </div>  
                </td>
                <td>
                  <div class="form-group date-time-group">
                    <label>Report Date(Y/M/D):</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.reyear"  />
                      <input type="text" id="month" v-model="formInputs.remonth" />
                      <input type="text" id="day" v-model="formInputs.reday" />                  
                    </div> 
                  </div>    
                  <div class="form-group">
                    <label for="pr">PR Number:</label>
                    <div>
                      <input type="text" id="pr" v-model="formInputs.pr" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="po">PO Number:</label>
                    <div>
                      <input type="text" id="po" v-model="formInputs.po" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="asset">Asset Number:</label>
                    <div>
                      <input type="text" id="asset" v-model="formInputs.asset" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="carryoverprice">Carry Over Cost:</label>
                    <div>
                      <input type="number" id="carryoverprice" v-model="formInputs.carryoverprice" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="newprice">New Cost:</label>
                    <div>
                      <input type="number" id="newprice" v-model="formInputs.newprice" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="offer">Offer Cost:</label>
                    <div>
                      <input type="number" id="offer" v-model="formInputs.offer" />                  
                    </div>
                  </div> 
                </td>
                <td>
                  <div class="form-group date-time-group">
                    <label>Cash Out Date 1(Y/M/D):</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.codyear1"  />
                      <input type="text" id="month" v-model="formInputs.codmonth1" />
                      <input type="text" id="day" v-model="formInputs.codday1" />                  
                    </div> 
                  </div>  
                  <div class="form-group">
                    <label for="cashout1">Cash Out 1:</label>
                    <div>
                      <input type="number" id="cashout1" v-model="formInputs.cashout1" />                  
                    </div>
                  </div> 
                  <div class="form-group date-time-group">
                    <label>Cash Out Date 2(Y/M/D):</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.codyear2"  />
                      <input type="text" id="month" v-model="formInputs.codmonth2" />
                      <input type="text" id="day" v-model="formInputs.codday2" />                  
                    </div> 
                  </div>  
                  <div class="form-group">
                    <label for="cashout1">Cash Out 2:</label>
                    <div>
                      <input type="number" id="cashout1" v-model="formInputs.cashout2" />                  
                    </div>
                  </div> 
                  <div class="form-group date-time-group">
                    <label>Cash Out Date 3(Y/M/D):</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.codyear3"  />
                      <input type="text" id="month" v-model="formInputs.codmonth3" />
                      <input type="text" id="day" v-model="formInputs.codday3" />                  
                    </div> 
                  </div>  
                  <div class="form-group">
                    <label for="cashout1">Cash Out 3:</label>
                    <div>
                      <input type="number" id="cashout1" v-model="formInputs.cashout3" />                  
                    </div>
                  </div> 
                  <div class="form-group date-time-group">
                    <label>Cash Out Date 4(Y/M/D):</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.codyear4"  />
                      <input type="text" id="month" v-model="formInputs.codmonth4" />
                      <input type="text" id="day" v-model="formInputs.codday4" />                  
                    </div> 
                  </div>  
                  <div class="form-group">
                    <label for="cashout1">Cash Out 4:</label>
                    <div>
                      <input type="number" id="cashout1" v-model="formInputs.cashout4" />                  
                    </div>
                  </div> 
                  <div class="form-group date-time-group">
                    <label>Cash Out Date 5(Y/M/D):</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.codyear5"  />
                      <input type="text" id="month" v-model="formInputs.codmonth5" />
                      <input type="text" id="day" v-model="formInputs.codday5" />                  
                    </div> 
                  </div>  
                  <div class="form-group">
                    <label for="cashout1">Cash Out 5:</label>
                    <div>
                      <input type="number" id="cashout1" v-model="formInputs.cashout5" />                  
                    </div>
                  </div> 
                </td>
                <td>                     
                  <div class="form-group">
                    <label for="type">File Upload:</label>                  
                    <div>           
                      <input type="file" @change="handleFileUpload('file')($event)" /><br>              
                      <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button>
                      <button class="fileUploadButton" type="button" @click="delFile('file')">File Delete</button><br>
                      {{ fileSaveStatus.file }}               
                    </div><br>
                  </div>   
                  <div class="form-group">
                    <label for="remark">Remark:</label>
                    <div>
                      <textarea id="remark" v-model="formInputs.remark" rows="4" cols="25"></textarea>      
                    </div>
                  </div>                     
                 </td>
             </tr>
          </table>        
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';    
  import { uploadFile, fileDel} from "../utils.js";  
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "invest"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const selectedFiles = reactive({ image: null, file: null });     
        const fileSaveStatus = reactive({ image: '', file: '' });                     
        const uploadType = 'invest';        

        const formInputs = reactive({
          year: "", month: "", day: "", category:"", reason:"", item: "", company: "", department: "", reyear: "", remonth: "", reday: "", 
          reportdate: "", pr: "", po: "", asset: "", carryoverprice: "0", newprice: "0", offer: "0",
          codyear1: "", codmonth1: "", codday1: "", cashoutdate1: "", cashout1: "0",
          codyear2: "", codmonth2: "", codday2: "", cashoutdate2: "", cashout2: "0",
          codyear3: "", codmonth3: "", codday3: "", cashoutdate3: "", cashout3: "0",
          codyear4: "", codmonth4: "", codday4: "", cashoutdate4: "", cashout4: "0",
          codyear5: "", codmonth5: "", codday5: "", cashoutdate5: "", cashout5: "0",          
          file: "", filepath: "", remark: "",
        });

        const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

        const submitForm = async () => {
          
          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          formInputs.reportdate = `${formInputs.reyear}-${formInputs.remonth}-${formInputs.reday}`;
          formInputs.cashoutdate1 = `${formInputs.codyear1}-${formInputs.codmonth1}-${formInputs.codday1}`;
          formInputs.cashoutdate2 = `${formInputs.codyear2}-${formInputs.codmonth2}-${formInputs.codday2}`;
          formInputs.cashoutdate3 = `${formInputs.codyear3}-${formInputs.codmonth3}-${formInputs.codday3}`;
          formInputs.cashoutdate4 = `${formInputs.codyear4}-${formInputs.codmonth4}-${formInputs.codday4}`;
          formInputs.cashoutdate5 = `${formInputs.codyear5}-${formInputs.codmonth5}-${formInputs.codday5}`;

          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            category: 'category',
            reason: 'reason',
            item: 'item',
            subitem: 'subitem',
            company: 'company',
            department: 'department',
            reportdate: 'reportdate',
            pr: 'pr',
            po: 'po',
            asset: 'asset',
            carryoverprice: 'carryoverprice',
            newprice: 'newprice',
            offer: 'offer',
            cashoutdate1: 'cashoutdate1',
            cashout1: 'cashout1',
            cashoutdate2: 'cashoutdate2',
            cashout2: 'cashout2',
            cashoutdate3: 'cashoutdate3',
            cashout3: 'cashout3',
            cashoutdate4: 'cashoutdate4',
            cashout4: 'cashout4',
            cashoutdate5: 'cashoutdate5',
            cashout5: 'cashout5',
            file: 'file',
            filepath: 'filepath',
            remark: 'remark' 
          };

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: formInputs.year,
              month: formInputs.month,
              day: formInputs.day,
              category: formInputs.category,
              reason: formInputs.reason,
              item: formInputs.item,
              subitem: formInputs.subitem,
              company: formInputs.company,
              department: formInputs.department,
              reportdate: formInputs.reportdate,
              pr: formInputs.pr,
              po: formInputs.po,
              asset: formInputs.asset,
              carryoverprice: formInputs.carryoverprice,
              newprice: formInputs.newprice,
              offer: formInputs.offer,
              cashoutdate1: formInputs.cashoutdate1,
              cashout1: formInputs.cashout1,
              cashoutdate2: formInputs.cashoutdate2,
              cashout2: formInputs.cashout2,
              cashoutdate3: formInputs.cashoutdate3,
              cashout3: formInputs.cashout3,
              cashoutdate4: formInputs.cashoutdate4,
              cashout4: formInputs.cashout4,
              cashoutdate5: formInputs.cashoutdate5,
              cashout5: formInputs.cashout5,
              file: formInputs.file,
              filepath: formInputs.filepath,
              remark: formInputs.remark,
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData);    
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false;
          }         
              
         closeModal();
        };
        const closeModal = () => { emit("onClose"); };  
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];    
              
              const splitDate = (dateString) => {
                return dateString && dateString.includes('-') ? dateString.split('-') : ['', '', ''];
              };

              const [reyear, remonth, reday] = splitDate(rowData.reportdate);
              const [codyear1, codmonth1, codday1] = splitDate(rowData.cashoutdate1);
              const [codyear2, codmonth2, codday2] = splitDate(rowData.cashoutdate2);
              const [codyear3, codmonth3, codday3] = splitDate(rowData.cashoutdate3);
              const [codyear4, codmonth4, codday4] = splitDate(rowData.cashoutdate4);
              const [codyear5, codmonth5, codday5] = splitDate(rowData.cashoutdate5);             

              formInputs.id = rowData.id,
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,
              formInputs.category = rowData.category,
              formInputs.reason = rowData.reason,
              formInputs.item = rowData.item,
              formInputs.subitem = rowData.subitem,
              formInputs.company = rowData.company,
              formInputs.department = rowData.department,
              formInputs.reyear = reyear,
              formInputs.remonth = remonth,
              formInputs.reday = reday,
              formInputs.pr = rowData.pr,
              formInputs.po = rowData.po,
              formInputs.asset = rowData.asset,
              formInputs.carryoverprice = rowData.carryoverprice,
              formInputs.newprice = rowData.newprice,
              formInputs.offer = rowData.offer,
              formInputs.codyear1 = codyear1,
              formInputs.codmonth1 = codmonth1,
              formInputs.codday1 = codday1,
              formInputs.cashout1 = rowData.cashout1,
              formInputs.codyear2 = codyear2,
              formInputs.codmonth2 = codmonth2,
              formInputs.codday2 = codday2,
              formInputs.cashout2 = rowData.cashout2,
              formInputs.codyear3 = codyear3,
              formInputs.codmonth3 = codmonth3,
              formInputs.codday3 = codday3,
              formInputs.cashout3 = rowData.cashout3,
              formInputs.codyear4 = codyear4,
              formInputs.codmonth4 = codmonth4,
              formInputs.codday4 = codday4,
              formInputs.cashout4 = rowData.cashout4,
              formInputs.codyear5 = codyear5,
              formInputs.codmonth5 = codmonth5,
              formInputs.codday5 = codday5,
              formInputs.cashout5 = rowData.cashout5,
              formInputs.file = rowData.file,
              formInputs.filepath = rowData.filepath,
              formInputs.remark = rowData.remark   

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

        const fileUpload = (type) => { if (formInputs[`${type}path`]) { alert("First delete file");return; }
            uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);
      };
        const delFile = (type) => fileDel(type, formInputs, fileSaveStatus);  

      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing,
               selectedFiles, handleFileUpload, fileSaveStatus, fileUpload, delFile, 
      };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   

.modal-wrapper { width: 100%;  max-width: 1000px; }

.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}

.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

.modal-header h3 { font-size: 1.2rem; }

.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

label { font-weight: bold; }

input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

input[type="number"] { width: 100%;}  

.readWindow[readonly] { background-color: #f0f0f0; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s;
}

button[type="submit"]:hover { background-color: #45a049; }

table { table-layout: fixed; width: 100%; }

td { width: 20%; vertical-align: top; padding: 0 15px; }

.time-inputs { display: flex; gap: 5px; }
.time-inputs input[type="number"] { width: 50px; }
.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 50px; size: 8px; }

.radio { margin-left: 10px; margin-right: 5px; }
.fileUploadButton {
  background-color: #c6c6c6; color: rgb(0, 0, 0);
  padding: 5px 5px; margin-top: 10px; margin-right: 10px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
  display: inline-block; transition-duration: 0.4s;
}
</style>