<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add LPA</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>                                        
                <td>
                  <input class="dateInput" type="text" v-model="searchDate.year" />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Week</td>    
                <td>
                  <input type="text" v-model="formInputs.week" readonly class="readWindow"/>  
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Factory</td> 
                <td>
                  <select class="itemInput" v-model="formInputs.factory">
                    <option>1</option><option>2</option>
                  </select>              
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Team</td> 
                <td>
                  <select v-model="formInputs.team">
                    <option>Production 1st</option><option>Production 2nd</option><option>Quality</option><option>Logistic</option>
                    <option>HR/HSE/GA</option><option></option>
                  </select>             
                </td>
              </tr>
            </table><br>

            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">                
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Auditor</td> 
                <td>
                  <input class="nameInput" type="text" v-model="formInputs.auditor" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Area</td> 
                <td>
                  <input class="nameInput" type="text" v-model="formInputs.area" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Area Leader</td> 
                <td>
                  <input class="nameInput" type="text" v-model="formInputs.arealeader" />
                </td>    
              </tr>
            </table><br>

            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Description of the problem</td>
                <td style="border: 0"><textarea v-model="formInputs.problem" rows="5" cols="100"></textarea></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Containment actions</td>
                <td style="border: 0"><textarea v-model="formInputs.containment" rows="5" cols="100"></textarea></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Root cause</td>
                <td style="border: 0"><textarea v-model="formInputs.rootcause" rows="5" cols="100"></textarea></td>
              </tr>
               <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Corrective action</td>
                <td style="border: 0"><textarea v-model="formInputs.corrective" rows="5" cols="100"></textarea></td>
              </tr>
            </table><br>

            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">  
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Category</td>
                <td>
                  <input class="itemInput" type="text" v-model="formInputs.category" />
                </td>  
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Reoccurance</td> 
                <td>
                  <select class="itemInput" type="text" v-model="formInputs.reoccurance">
                    <option>Yes</option><option>No</option>
                  </select>
                </td>              
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">In charge</td>
                <td>
                  <input class="itemInput" type="text" v-model="formInputs.incharge" />
                </td>
              </tr>
            </table><br>

            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">  
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Due Date(yyyy/mm/dd)</td>                                        
                <td>
                  <input class="dateInput" type="text" v-model="formInputs.dueyear" />
                  <input class="dateInput" type="text" v-model="formInputs.duemonth" />
                  <input class="dateInput" type="text" v-model="formInputs.dueday" />
                </td>   
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Progress</td>
                <td>
                  <select class="itemInput" type="text" v-model="formInputs.progress">
                    <option>Not Yet</option><option>On going</option><option>Done</option>
                  </select>
                </td>     
              </tr>
            </table><br>

            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" style="background-color: #eaeaea; border: 0; padding: 0px 5px;" >Before Image</td>
                <td>
                  <input class="fileInput" type="file" @change="handleFileUpload('imageone')($event)" />
                </td>
                <td rowspan="2" style="background-color: #eaeaea; border: 0; padding: 0px 5px;">After Image</td>
                <td>
                  <input class="fileInput" type="file" @change="handleFileUpload('imagetwo')($event)" />
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td>
                  <button class="fileUploadButton" type="button" @click="fileUpload('imageone')">Image Upload</button><br>
                  {{ fileSaveStatus.imageone }}   
                </td>
                <td>
                  <button class="fileUploadButton" type="button" @click="fileUpload('imagetwo')">Image Upload</button><br>
                  {{ fileSaveStatus.imagetwo }}   
                </td>
              </tr>
            </table><br>

            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}  
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>    
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, getColumnMapping, makeFormData, useFormInputs, uploadFile  } from '../utils.js';
  import { setupPlan } from '../schedule/planning/planUtils.js';  
   
  export default {    
    setup(props, { emit }) {       
      const dbTableName = "lpa";
      const selectedFiles = reactive({ file: null,  imageone: null, imagetwo: null, });     
      const saveStatus = ref('');
      const fileSaveStatus = reactive({ file: '', imageone: '', imagetwo: '', });
      const isProcessing = ref(false);      
      const uploadType = 'LPA';      
      const { currentDateAndWeek } = setupPlan();  

      const { formInputs, dataForm } = useFormInputs({ imageone: "", imageonepath: "", imagetwo: "", imagetwopath: "" });   
      
      formInputs.week = currentDateAndWeek;

      const searchDate = reactive({ ...getCurrentDate()  });        
      
      const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

      const submitForm = async () => {

        isProcessing.value = true;
        saveStatus.value = 'Processing...';                

        const columnMapping = getColumnMapping(searchDate, formInputs);  
        const formData = makeFormData(searchDate, formInputs, dbTableName, columnMapping); 
        console.log("columnMapping", columnMapping);
        console.log("formData", formData);


         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };

      const closeModal = () => { emit("onClose"); };

      const fileUpload = (type) => uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);                    

    return { saveStatus, isProcessing, formInputs, dataForm, searchDate, selectedFiles,
             handleFileUpload, submitForm, closeModal, dbTableName, fileUpload, fileSaveStatus,             
    };
  },     

  props: { onSubmit: { type: Function,  required: true, },}, 
};
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }  
  .modal-wrapper { width: 100%;  max-width: 800px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  .readWindow[readonly] { background-color: #f0f0f0; width: 80px !important; }
  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }
  button[type="submit"]:hover { background-color: #45a049; }
  
  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 100px; } 
  .fileInput { margin-left: 5px; margin-right: 5px; width: 200px; border: 0px; } 
  .categoryInput { margin-left: 5px; margin-right: 5px; width: 50px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }  
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin: 10px 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }    
</style>