<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Document Report (Purchase)</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="submitForm">
      <table class="itemTable">
        <tr style="border: 1px solid rgb(156, 156, 156);">
          <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>                        
          <td>
            <input class="dateInput" type="text" v-model="formInputs.year" />
            <input class="dateInput" type="text" v-model="formInputs.month" />
            <input class="dateInput" type="text" v-model="formInputs.day" />
          </td>
          <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Team</td> 
          <td>
            <select class="itemInput" v-model="formInputs.team">
              <option>Production 1st</option><option>Production 2nd</option><option>Planning</option><option>Quality</option>
              <option>Sales</option><option>Purchase</option><option>HR/HSE/GA</option><option></option>
            </select>              
          </td>
          <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Writer</td>
          <td><input class="itemInput" type="text" v-model="formInputs.writer" /></td>
        </tr>
      </table>
      <br>
      
      <table>
        <tr>
          <td>
            <table class="itemTable"> <!--Title Table-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;">Title</td>
                <td style="border: 0"><input class="titleInput" type="text" v-model="formInputs.title" /></td>                  
              </tr>
            </table>
            <br>
            
            <table class="itemTable"> <!--Contents Table-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Contents</td>
                <td style="border: 0"><textarea id="contents" v-model="formInputs.contents" rows="10" cols="120"></textarea></td>
              </tr>
            </table>
            <br>

            <table class="itemTable"> <!--Item Table-->
              <tr>
                <th>Item No.</th><th>Name</th><th>Unit Cost</th><th>Quantity</th><th>PLN/EUR</th>
              </tr>        
              <tr>  
                <td style="font-weight: bold">Item 1</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item1" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost1" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty1" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit1"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 2</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item2" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost2" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty2" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit2"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 3</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item3" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost3" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty3" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit3"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 4</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item4" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost4" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty4" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit4"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 5</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item5" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost5" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty5" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit5"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 6</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item6" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost6" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty6" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit6"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 7</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item7" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost7" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty7" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit7"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 8</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item8" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost8" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty8" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit8"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 9</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item9" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost9" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty9" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit9"><option>PLN</option><option>EUR</option></select></td>
              </tr>
              <tr>  
                <td style="font-weight: bold">Item 10</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.item10" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.cost10" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty10" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit10"><option>PLN</option><option>EUR</option></select></td>
              </tr>
            </table>
            <br>

            <table class="itemTable"> <!--Information 1 Table-->
              <tr>
                <th>Delivery<br>Year/Month/Day</th><th>Start location of<br>product transportation</th>
                <th>Incoterms</th><th>Delivery Cost</th><th>PLN/EUR</th>         
              </tr>     
              <tr>
                <td>
                  <input class="subDateInput" type="text" v-model="formInputs.deliveryyear" placeholder="YYYY"  />
                  <input class="subDateInput" type="text" v-model="formInputs.deliverymonth" placeholder="MM"  />
                  <input class="subDateInput" type="text" v-model="formInputs.deliveryday" placeholder="DD"  />
                </td>
                <td>
                  <input class="itemInput" v-model="formInputs.fromcountry"  placeholder="Poland, Korea Etc.." />
                </td>
                <td>
                  <input class="itemInput" v-model="formInputs.incotems" placeholder="DAP, DDP, Etc.."/>
                </td>
                <td>
                  <input class="costInput" type="text" v-model="formInputs.deliverycost" />
                </td>
                <td>
                  <select class="unitSelect" type="text" v-model="formInputs.deliveryunit"><option>PLN</option><option>EUR</option></select>
                </td>
              </tr>
            </table>
            <br>

            <table class="itemTable"> <!--Information 2 Table-->
              <tr>
                <th>Profit and loss reflection schedule (Year/Month/Day)</th><th>Payment Condition</th>
              </tr>
              <tr>
                <td>
                  <input class="subDateInput" type="text" v-model="formInputs.payyear" placeholder="YYYY" />
                  <input class="subDateInput" type="text" v-model="formInputs.paymonth" placeholder="MM" />
                  <input class="subDateInput" type="text" v-model="formInputs.payday" placeholder="DD" />
                </td>
                <td>
                  <input class="payConditionInput" type="text" v-model="formInputs.paymentcondition" placeholder="Within 30 days after completion of delivery, Etc.." />
                </td>
              </tr>
            </table>
            <br>
            
            <table class="itemTable"> <!--Total Table-->
              <tr>
                <th colspan="2">Total PLN</th><th colspan="2">Total EUR</th>
              </tr>
              <tr>
                <th>Without Delivery</th><th>With Delivery</th> <th>Without Delivery</th><th>With Delivery</th>
              </tr>
              <tr>
                <td>{{ totalCost.withoutDeliveryPln }}</td><td>{{totalCost.withDeliveryPln}}</td>                  
                <td> {{totalCost.withoutDeliveryEur}}</td><td>{{totalCost.withDeliveryEur}}</td>
              </tr>
            </table>
            <br>

            <table class="itemTable">
              <tr>
                <td style="background-color: #eaeaea">Remark:</td>
                <td><input class="nameInput" type="text" id="remark" v-model="formInputs.remark" /></td>
              </tr>
            </table>
          </td>
          <td class="fileSign">
            <table class="uploadTable">
              <tr>
                <td class="uploadTd">File Upload</td>
                <td> <input type="file" @change="handleFileUpload('file')($event)" /><br>         
                     <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button>
                     <button class="fileUploadButton" type="button" @click="delFile('file')">File Delete</button><br>
                     {{ fileSaveStatus.file }}   
                </td>
              </tr>
              <tr>
                <td class="uploadTd">Image 1 Upload</td>
                <td><input type="file" @change="handleFileUpload('imageone')($event)" /><br>            
                    <button class="fileUploadButton" type="button" @click="fileUpload('imageone')">Image 1 Upload</button>
                    <button class="fileUploadButton" type="button" @click="delFile('imageone')">Image Delete</button><br>
                    {{ fileSaveStatus.imageone }}    
                </td>
              </tr>
              <tr>
                <td class="uploadTd">Image 2 Upload</td>
                <td> <input type="file" @change="handleFileUpload('imagetwo')($event)" /><br>              
                    <button class="fileUploadButton" type="button" @click="fileUpload('imagetwo')">Image 2 Upload</button>
                    <button class="fileUploadButton" type="button" @click="delFile('imagetwo')">Image Delete</button><br>
                    {{ fileSaveStatus.imagetwo }}   
                </td>
              </tr>
            </table>
            <br>

            <table class="itemTable">
              <tr>
                <td class="uploadTd">Sign 1</td>
                <td>
                  <select v-model="formInputs.sign1" :disabled="isSigned1">
                    <option>Production 1st Manager</option><option>Production 2nd Manager</option>
                    <option>Quality Manager</option><option>Sales Manager</option><option>Purchase Manager</option>
                    <option>Logistic Manager</option><option>HR/HSE/GA Manager</option><option>Factory Manager</option>
                    <option>CFO</option><option>CEO</option><option></option>
                  </select>
                </td>                
              </tr>
              <tr>
                <td class="uploadTd">Sign 2</td>
                <td>
                  <select v-model="formInputs.sign2" :disabled="isSigned2">
                    <option>Production 1st Manager</option><option>Production 2nd Manager</option>
                    <option>Quality Manager</option><option>Sales Manager</option><option>Purchase Manager</option>
                    <option>Logistic Manager</option><option>HR/HSE/GA Manager</option><option>Factory Manager</option>
                    <option>CFO</option><option>CEO</option><option></option>
                  </select>
                </td>                
              </tr>
              <tr>
                <td class="uploadTd">Sign 3</td>
                <td>
                  <select v-model="formInputs.sign3" :disabled="isSigned3">
                    <option>Production 1st Manager</option><option>Production 2nd Manager</option>
                    <option>Quality Manager</option><option>Sales Manager</option><option>Purchase Manager</option>
                    <option>Logistic Manager</option><option>HR/HSE/GA Manager</option><option>Factory Manager</option>
                    <option>CFO</option><option>CEO</option><option></option>
                  </select>
                </td>                
              </tr>
              <tr>
                <td class="uploadTd">Sign 4</td>
                <td>
                  <select v-model="formInputs.sign4" :disabled="isSigned4">
                    <option>Production 1st Manager</option><option>Production 2nd Manager</option>
                    <option>Quality Manager</option><option>Sales Manager</option><option>Purchase Manager</option>
                    <option>Logistic Manager</option><option>HR/HSE/GA Manager</option><option>Factory Manager</option>
                    <option>CFO</option><option>CEO</option><option></option>
                  </select>
                </td>                
              </tr>
              <tr>
                <td class="uploadTd">Sign 5</td>
                <td>
                  <select v-model="formInputs.sign5" :disabled="isSigned5">
                    <option>Production 1st Manager</option><option>Production 2nd Manager</option>
                    <option>Quality Manager</option><option>Sales Manager</option><option>Purchase Manager</option>
                    <option>Logistic Manager</option><option>HR/HSE/GA Manager</option><option>Factory Manager</option>
                    <option>CFO</option><option>CEO</option><option></option>
                  </select>
                </td>                
              </tr>
            </table>
            <br>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button><br>
            {{ saveStatus }}  
          </td>
        </tr>
      </table>
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref, computed  } from 'vue';  
  import axios from 'axios';     
  import { uploadFile, fileDel } from '../utils.js';  
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "documentreport";   
        const saveStatus = ref('');
        const fileSaveStatus = reactive({ file: '', imageone: '', imagetwo: '', });    
        const isProcessing = ref(false);   
        const selectedFiles = reactive({ file: null,  imageone: null, imagetwo: null, });             
        const uploadType = 'documentReport';   
        const itemInputs = reactive({
          item1: "", item2: "", item3: "", item4: "", item5: "", item6: "", item7: "", item8: "", item9: "", item10: "",
          cost1: "", cost2: "", cost3: "", cost4: "", cost5: "", cost6: "", cost7: "", cost8: "", cost9: "", cost10: "",
          qty1: "", qty2: "", qty3: "", qty4: "", qty5: "", qty6: "", qty7: "", qty8: "", qty9: "", qty10: "",
          unit1: "", unit2: "", unit3: "", unit4: "", unit5: "", unit6: "", unit7: "", unit8: "", unit9: "", unit10: "",
        });             
        const formInputs = reactive({
          id: "", year:"", month: "", day: "", 
          team: "",  type: "purchase", writer: "", title: "", contents: "", 
          purchaseitem: "", purchasecost: "", purchasequantity: "", purchaseunit: "",
          deliveryyear: "",  deliverymonth: "",  deliveryday: "",  fromcountry: "",  incotems: "",
          deliverycost: "", deliveryunit: "",  payyear: "",  paymonth: "",  payday: "", paymentcondition: "",
          sign1: "", sign2: "", sign3: "", sign4: "", sign5: "", signed1: "", signed2: "", signed3: "", signed4: "", signed5: "",
          remark: "", file: "", filepath: "", imageone: "", imageonepath: "", imagetwo: "", imagetwopath: "",
        });
        const totalCost = reactive({ withoutDeliveryPln: "", withDeliveryPln: "", withoutDeliveryEur: "", withDeliveryEur: ""  });

        const isSigned1 = computed(() => formInputs.signed1 === 'Signed');
        const isSigned2 = computed(() => formInputs.signed2 === 'Signed');
        const isSigned3 = computed(() => formInputs.signed3 === 'Signed');
        const isSigned4 = computed(() => formInputs.signed4 === 'Signed');
        const isSigned5 = computed(() => formInputs.signed5 === 'Signed');

        const managerCodes = {
          "Production 1st Manager": '34',
          "Production 2nd Manager": '35',
          "Quality Manager": '36',
          "Sales Manager": '37',
          "Purchase Manager": '38',
          "Planning Manager": '39',
          "Logistic Manager": '40',
          "HR Manager": '41',
          "Factory Manager": '42',
          "CFO": '43',
          "CEO": '44'
        };

        const managerBackCodes = {
          '34': "Production 1st Manager",
          '35': "Production 2nd Manager", 
          '36': "Quality Manager",
          '37': "Sales Manager",
          '38': "Purchase Manager",
          '39': "Planning Manager",
          '40': "Logistic Manager",
          '41': "HR Manager",
          '42': "Factory Manager",
          '43': "CFO",
          '44': "CEO"
        };

        const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

        const submitForm = async () => {

          isProcessing.value = true;
          saveStatus.value = 'Processing...'; 
          prepareDataForSubmission();

          const columnMapping = {            
            year: 'year',
            month: 'month',
            day: 'day',
            team: 'team',
            type: 'type',
            writer: 'writer',
            title: 'title',
            contents: 'contents',
            purchaseitem: 'purchaseitem',
            purchasecost: 'purchasecost',
            purchasequantity: 'purchasequantity',
            purchaseunit: 'purchaseunit', 
            deliveryyear: 'deliveryyear',
            deliverymonth: 'deliverymonth',
            deliveryday: 'deliveryday',
            fromcountry: 'fromcountry',
            incotems: 'incotems',
            deliverycost: 'deliverycost',
            deliveryunit: 'deliveryunit',
            payyear: 'payyear',
            paymonth: 'paymonth',
            payday: 'payday',
            paymentcondition: 'paymentcondition',
            sign1: 'sign1',  
            sign2: 'sign2', 
            sign3: 'sign3', 
            sign4: 'sign4',  
            sign5: 'sign5',   
            remark: 'remark',
            file: 'file',
            filepath: 'filepath',
            imageone: 'imageone',
            imageonepath: 'imageonepath',
            imagetwo: 'imagetwo',
            imagetwopath: 'imagetwopath'
          }

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: formInputs.year,
              month: formInputs.month,
              day: formInputs.day,
              team: formInputs.team,
              type: formInputs.type,
              writer: formInputs.writer,
              title: formInputs.title,
              contents: formInputs.contents,   
              purchaseitem: formInputs.purchaseitem,
              purchasecost: formInputs.purchasecost,
              purchasequantity: formInputs.purchasequantity,
              purchaseunit: formInputs.purchaseunit, 
              deliveryyear: formInputs.deliveryyear,
              deliverymonth: formInputs.deliverymonth,
              deliveryday: formInputs.deliveryday,
              fromcountry: formInputs.fromcountry,
              incotems: formInputs.incotems,
              deliverycost: formInputs.deliverycost,
              deliveryunit: formInputs.deliveryunit,
              payyear: formInputs.payyear,
              paymonth: formInputs.paymonth,
              payday: formInputs.payday,
              paymentcondition: formInputs.paymentcondition,       
              sign1: managerCodes[formInputs.sign1] || '',
              sign2: managerCodes[formInputs.sign2] || '',
              sign3: managerCodes[formInputs.sign3] || '',
              sign4: managerCodes[formInputs.sign4] || '',
              sign5: managerCodes[formInputs.sign5] || '',       
              remark: formInputs.remark,
              file: formInputs.file,
              filepath: formInputs.filepath,
              imageone: formInputs.imageone,
              imageonepath: formInputs.imageonepath,
              imagetwo: formInputs.imagetwo,
              imagetwopath: formInputs.imagetwopath   
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }
         
         await axios.put(`/api/materialput/${id}`, formData);         
         closeModal();
        };

        const closeModal = () => { emit("onClose"); }; 
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id;
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,
              formInputs.team = rowData.team,
              formInputs.type = rowData.type,
              formInputs.writer = rowData.writer,
              formInputs.title = rowData.title,
              formInputs.contents = rowData.contents,   
              formInputs.purchaseitem = rowData.purchaseitem,
              formInputs.purchasecost = rowData.purchasecost,
              formInputs.purchasequantity = rowData.purchasequantity,
              formInputs.purchaseunit = rowData.purchaseunit, 
              formInputs.deliveryyear = rowData.deliveryyear,
              formInputs.deliverymonth = rowData.deliverymonth,
              formInputs.deliveryday = rowData.deliveryday,
              formInputs.fromcountry = rowData.fromcountry,
              formInputs.incotems = rowData.incotems,
              formInputs.deliverycost = rowData.deliverycost,
              formInputs.deliveryunit = rowData.deliveryunit,
              formInputs.payyear = rowData.payyear,
              formInputs.paymonth = rowData.paymonth,
              formInputs.payday = rowData.payday,
              formInputs.paymentcondition = rowData.paymentcondition,           
              formInputs.sign1 = managerBackCodes[rowData.sign1] || '',
              formInputs.sign2 = managerBackCodes[rowData.sign2] || '',
              formInputs.sign3 = managerBackCodes[rowData.sign3] || '',
              formInputs.sign4 = managerBackCodes[rowData.sign4] || '',
              formInputs.sign5 = managerBackCodes[rowData.sign5] || '',       
              formInputs.signed1 = rowData.signed1,
              formInputs.signed2 = rowData.signed2,
              formInputs.signed3 = rowData.signed3,
              formInputs.signed4 = rowData.signed4,
              formInputs.signed5 = rowData.signed5,
              formInputs.remark = rowData.remark,
              formInputs.file = rowData.file,
              formInputs.filepath = rowData.filepath,
              formInputs.imageone = rowData.imageone,
              formInputs.imageonepath = rowData.imageonepath,
              formInputs.imagetwo = rowData.imagetwo,
              formInputs.imagetwopath = rowData.imagetwopath  
              
              updateItemInputs();

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );     

        const fileUpload = (type) => { if (formInputs[`${type}path`]) { alert("First delete file");return; }
            uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);
      };  
        const delFile = (type) => fileDel(type, formInputs, fileSaveStatus);  

        const totalCostWithoutDelivery = computed(() => {
          let totalPln = 0;
          let totalEur = 0;

          Object.keys(itemInputs).forEach(key => {
            if (key.startsWith('cost') && itemInputs[key]) {
              const index = key.slice(4); // Extract the number from the key
              const qtyKey = `qty${index}`;
              const unitKey = `unit${index}`;
              const cost = parseFloat(itemInputs[key]);
              const qty = parseFloat(itemInputs[qtyKey]);

              if (!isNaN(cost) && !isNaN(qty)) {
                if (itemInputs[unitKey] === 'PLN') {
                  totalPln += cost * qty;
                } else if (itemInputs[unitKey] === 'EUR') {
                  totalEur += cost * qty;
                }
              }
            }
          });

          return { totalPln, totalEur };
        });
      
        watch(totalCostWithoutDelivery, (newValues) => {
          totalCost.withoutDeliveryPln = newValues.totalPln;
          totalCost.withoutDeliveryEur = newValues.totalEur;
        }, { immediate: true });

        watch([totalCostWithoutDelivery, () => formInputs.deliverycost, () => formInputs.deliveryunit], ([totals, , unit]) => {
          const deliveryCost = parseFloat(formInputs.deliverycost);
          if (!isNaN(deliveryCost)) {       
            if (unit === 'PLN') {
              totalCost.withDeliveryPln = totals.totalPln + deliveryCost;
              totalCost.withDeliveryEur = totals.totalEur;  
            } else if (unit === 'EUR') {
              totalCost.withDeliveryEur = totals.totalEur + deliveryCost;
              totalCost.withDeliveryPln = totals.totalPln; 
            }
          } else {
            totalCost.withDeliveryPln = totals.totalPln;
            totalCost.withDeliveryEur = totals.totalEur;
          }
        }, { immediate: true });

        function updateItemInputs() {
          // formInputs에서 세미콜론으로 분리
          const items = formInputs.purchaseitem.split(';');
          const costs = formInputs.purchasecost.split(';');
          const quantities = formInputs.purchasequantity.split(';');
          const units = formInputs.purchaseunit.split(';');

          // itemInputs에 값 할당
          items.forEach((item, index) => {
            if (index < 10) { // 10개의 항목만 처리
              itemInputs[`item${index + 1}`] = item;
              itemInputs[`cost${index + 1}`] = costs[index] || '';
              itemInputs[`qty${index + 1}`] = quantities[index] || '';
              itemInputs[`unit${index + 1}`] = units[index] || '';
            }
          });
        }

        function prepareDataForSubmission() {
          // 각 항목별 배열 초기화
          const items = [];
          const costs = [];
          const quantities = [];
          const units = [];

          // itemInputs의 각 항목에 대한 데이터 수집
          for (let i = 1; i <= 10; i++) {
            items.push(itemInputs[`item${i}`]);
            costs.push(itemInputs[`cost${i}`]);
            quantities.push(itemInputs[`qty${i}`]);
            units.push(itemInputs[`unit${i}`]);
          }

          // formInputs에 세미콜론으로 구분된 문자열 저장
          formInputs.purchaseitem = items.join(';');
          formInputs.purchasecost = costs.join(';');
          formInputs.purchasequantity = quantities.join(';');
          formInputs.purchaseunit = units.join(';');
        }  

      return { dbTableName, submitForm, closeModal, saveStatus, isProcessing,
               formInputs, itemInputs, totalCost,
               isSigned1, isSigned2, isSigned3, isSigned4, isSigned5,
               selectedFiles, handleFileUpload, fileSaveStatus, fileUpload, delFile,              
      };
    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 1200px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px; margin-right: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 350px; } 
  .costInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .subDateInput { width: 50px; size: 8px; margin-left: 3px; margin-right: 3px; }  
  .titleInput { width: 663px; margin-left: 5px; } 
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }  
</style>