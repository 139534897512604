<template>
  <div class="container">
    <div class="search">
      <form @submit.prevent="sendDate()">
        <table class="searchTable">
          <tr>
            <td class="start">
              <input type="number" id="year" v-model="searchDate.year" />
              <input type="number" id="month" v-model="searchDate.month" />
              <input type="number" id="day" v-model="searchDate.day" />
              <select class="withouteop" v-model="formInputs.use">
                <option>Without EOP</option><option>All</option>
              </select>
            </td>
            <td class="searchTd">
              <button class="searchButton" type="submit" @click="callSearchData">Search</button>
            </td>
            <td class="searchTd">
              <button class="addButton" @click="showModal = true">Add Result</button>
            </td>
          </tr>
        </table>
      </form>
    </div>    
    <table>
      <tr>
        <th rowspan="2">Line Name</th>
        <th class="okhead" colspan="3">OK Quantity</th>
        <th class="differenthead" colspan="3">Different Quantity</th>
      </tr>      
      <tr>
        <th>Physical</th><th>WEB</th><th>SAP</th><th>SAP-WEB</th><th>Physical-WEB</th><th>SAP-Physical</th>
      </tr>
      <tr v-for="(target, index) in filteredTargets" :key="target.name">
        <td><b>{{ target.name }}</b></td>        
        <td><b>{{ rowData[index].physical_ok }}</b></td>
        <td><b>{{ rowData[index].web_ok }}</b></td>
        <td><b>{{ rowData[index].sap_ok }}</b></td>        
        <td :style="getCellStyle(rowData[index].diff_sap_web)"><b>{{ rowData[index].diff_sap_web }}</b></td>
        <td :style="getCellStyle(rowData[index].diff_physical_web)"><b>{{ rowData[index].diff_physical_web }}</b></td>
        <td :style="getCellStyle(rowData[index].diff_sap_physical)"><b>{{ rowData[index].diff_sap_physical }}</b></td>        
      </tr>
    </table>
    <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" />
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import Modal from './resultVerification_modal.vue';
import { createReportUtils, } from './resultVerificationUtils';
import { withoutExceptTargets, tableNames, withoutEOPLines } from '../lineData';

export default {
  components: { Modal },
  setup() {
    const showModal = ref(false); 
    const searchDate = ref({ year: '', month: '', day: '', });  
    const formInputs = reactive({ use: 'Without EOP', });  
    
    const { state, fetchAllData, fetchResultData, calculateRowData, sums } = createReportUtils(tableNames);   

    const filteredTargets = computed(() => {
      if (formInputs.use === 'Without EOP') {
        return withoutExceptTargets.filter(target => withoutEOPLines && withoutEOPLines.includes(target.name));
      }
      return withoutExceptTargets;
    });

    const rowData = computed(() => filteredTargets.value.map(target => calculateRowData(target.name, target, resultItem.value)));

    const sendDate = async () => {
      const date = { year: searchDate.value.year, month: searchDate.value.month, day: searchDate.value.day };
      return date;
    };   

    const callSearchData = async () => {    
      try {     
        const date = await sendDate();
        await fetchResultData(date);            
        await fetchAllData(date);
      }
      catch (error) {
        console.error("Report is currently disabled:", error.message);
      }
    };

    const getCellStyle = (value) => { return value !== 0 ? { backgroundColor: 'red', color: 'white' } : {}; };

    const resultItem = computed(() => {
      const items = {};
      state.resultData.forEach(d => {
        if (!items[d.line]) {
          items[d.line] = {};
        }
        items[d.line][d.type] = d.quantity;
      });
      return items;
    });

    return {
      state, withoutExceptTargets, rowData, sendDate, callSearchData, getCellStyle, formInputs,
      showModal, searchDate, sums, resultItem, filteredTargets
    };
  }
};
</script>

<style scoped>
   * { box-sizing: border-box; }

  .container { margin-top: 0px; padding-top: 0px; max-width: 95%; margin-left: auto; margin-right: auto; }
  .search { position: relative; background-color: #ffffff; padding: 0px; height: auto; width: 500px;}
  .start,.end { display: flex; flex-direction: row; border: 0px; }

  .start input,.end input { margin-right: 5px; }

  .separator { font-size: 24px; margin: 0 10px; border: 0px; }
  .searchTd { border: 0px; }

  input::placeholder { color: gray; }
  label { font-size: 14px; font-weight: 600; margin-right: 8px; }
  
  input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100px; }
  .withouteop {  padding: 8px; border: 1px solid #ccc;  border-radius: 4px; font-size: 14px; width: 150px;}

  .addButton {
    padding: 5px 10px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; width: 100px;
    font-size: 14px; font-weight: 600; margin-right: 20px; cursor: pointer; transition: background-color 0.3s;
  }

  .searchButton {
    padding: 5px 10px; background-color: #f1f1f1; color: black; border: none; border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-right: 20px; cursor: pointer; transition: background-color 0.3s;
  }

  table { width: auto; border-collapse: collapse; margin-bottom: 30px;}

  .comment { min-width: 300px; max-width: 600px; white-space: pre-wrap;  word-wrap: break-word; text-align: left; }

  th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ;
    border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px;
    font-size : 12px;  font-weight: 600;
  }

  td { border: 1px solid #ccc; text-align: center; padding: 8px; font-size: 12px; width: auto; }  

  tr:nth-child(even) { background-color: #f8f8f8; }

  tr:hover { background-color: #e6e6e6;}
  .ok { background-color: #94b7f8;  font-weight: bold;}

  .okhead {background-color: #8dc7fd; }
  .differenthead { background-color: #569e56; } 
</style>
