<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Schedule</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>
            <td>
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day (Start):</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="formInputs.startyear"  />
                  <input type="text" id="month" v-model="formInputs.startmonth" />
                  <input type="text" id="day" v-model="formInputs.startday" />                  
                </div> 
            </div>   
            <div class="form-group">
              <label for="period">Period:</label>
              <div>
                <input class="radio" type="radio" id="yes" value="yes" v-model="formInputs.period">
                <label for="yes">YES</label>
              </div> 
              <div>
                <input class="radio" type="radio" id="no" value="no" v-model="formInputs.period">
                <label for="no">NO</label>
              </div> 
            </div>
            <div class="form-group date-time-group" v-if="formInputs.period === 'yes'">
              <label>Year/Month/Day (End):</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="formInputs.endyear"  />
                  <input type="text" id="month" v-model="formInputs.endmonth" />
                  <input type="text" id="day" v-model="formInputs.endday" />                  
                </div> 
            </div>       
            <div class="form-group">
              <label for="writer">Writer:</label>
              <div><input type="text" id="writer" v-model="formInputs.writer"/></div>
            </div>
            <div class="form-group">
              <label for="factory">Type:</label>
              <div>
                <input class="radio" type="radio" id="type" value="Vacation" v-model="formInputs.type">
                <label for="vacation">Vacation</label>
                <input class="radio"  type="radio" id="type" value="Work Time" v-model="formInputs.type">
                <label for="worktime">Work Time</label>
                <input class="radio"  type="radio" id="type" value="Meeting" v-model="formInputs.type">
                <label for="meeting">Meeting</label>
                <input class="radio"  type="radio" id="type" value="ETC" v-model="formInputs.type">
                <label for="etc">ETC</label>
              </div>
            </div>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="contents">Contents:</label>
              <textarea id="contents" v-model="formInputs.contents" rows="4" cols="25"></textarea>              
            </div>  
          </td>
            </tr>
            </table>       
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        
        const dbTableName = "monthlyschedule"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);             

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          startyear: "", startmonth: "", startday: "", endyear: "", endmonth: "", endday: "",
          writer: "", type: "", contents: "", period: "no"       
        });
        // -끝-   
        const columnMapping = {
          startyear: 'year',
          startmonth: 'month',
          startday: 'day',
          writer: 'writer',
          type: 'type',
          contents: 'contents'
        };

        const submitForm = async () => {
          isProcessing.value = true;
          saveStatus.value = 'Processing...';

          if (formInputs.period === 'no') {
            await postFormData({
              year: formInputs.startyear,
              month: formInputs.startmonth,
              day: formInputs.startday,
              writer: formInputs.writer,
              type: formInputs.type,
              contents: formInputs.contents
            });
          } else {
            await postDateRange();
          }

          saveStatus.value = 'Completed';
          isProcessing.value = false;
          closeModal();
        };

        const postFormData = async (data) => {
          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: data
          };
          await axios.post('/api/post', formData);
        };

        const postDateRange = async () => {
          const startDate = new Date(formInputs.startyear, formInputs.startmonth - 1, formInputs.startday);
          const endDate = new Date(formInputs.endyear, formInputs.endmonth - 1, formInputs.endday);

          for (let dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
            await postFormData({
              year: dt.getFullYear(),
              month: dt.getMonth() + 1,
              day: dt.getDate(),
              writer: formInputs.writer,
              type: formInputs.type,
              contents: formInputs.contents
            });
          }
        };

        const closeModal = () => {
          emit("onClose");
        };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    

      return {  dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing };

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 

};
  
  </script>
    
  <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 800px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s; font-weight: bold;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }  
    .radio { margin-left: 10px; margin-right: 5px; }
    .disabled { background-color: #ccc; cursor: not-allowed; }
</style>