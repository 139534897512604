export function updateModel(project, model, modelOptions, formInputs) {
    if (project === 'EVO') {
        modelOptions.value = ['EVO 2P Front', 'EVO 2P Rear', 'EVO 3P Front', 'EVO 3P Rear'];

        if (model === 'EVO 2P Front') {
            setPeelTensileInputs(formInputs, 7, 6);
        } else if (model === 'EVO 2P Rear'){            
            setPeelTensileInputs(formInputs, 7, 6);
        } else if (model === 'EVO 3P Front'){            
            setPeelTensileInputs(formInputs, 5, 4);
        } else if (model === 'EVO 3P Rear'){            
            setPeelTensileInputs(formInputs, 5, 4);
        }
    } else if (project === 'EV2020') {
        modelOptions.value = ['40kW Front', '40kW Rear', '60kW Front', '60kW Rear'];

        if (model === '40kW Front') {
            setPeelTensileInputs(formInputs, 7, 7);
        } else if (model === '40kW Rear') {            
            setPeelTensileInputs(formInputs, 6, 6);
        } else if (model === '60kW Front') {            
            setPeelTensileInputs(formInputs, 5, 5);
        } else if (model === '60kW Rear') {            
            setPeelTensileInputs(formInputs, 4, 4);
        }
    } else {
        modelOptions.value = ['Please select a project'];
        setPeelTensileInputs(formInputs, 0, 0);
    }
}

function setPeelTensileInputs(formInputs, numPeels, numTensiles) {
    // peel 및 tensile 입력 창을 동적으로 생성합니다.
    formInputs.peels = Array.from({ length: numPeels }, () => ({ value: '' }));
    formInputs.tensiles = Array.from({ length: numTensiles }, () => ({ value: '' }));
}
