<template>
    <div class="container">        
      <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">                
              <div class="start">
                  <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                  <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                  <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
              </div>
              <div class="separator">~</div>
              <div class="end">
                  <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                  <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                  <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
              </div>
              <div class="searchTd">
                  <button type="submit" @click="callSearchData">Search</button>                        
              </div>
              <div class="searchTd">
                  <button class="excelButton" @click="prdownloadExcel">Excel Download</button>                                              
              </div>
            </div> 
        </form>
      </div>          

      <B>Inkatom 1st</B>
      <table>
        <tr><th v-for="(header, index) in inkatom1st_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.inkatom1st_modelSums['2P12S'].target }}</td>
          <td>{{ sums.inkatom1st_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.inkatom1st_modelSums['2P12S'].ok }}</td>         
          <td class="verification">{{ sums.inkatom1st_modelSums['2P12S'].verification }}</td>   
          <td class="ng">{{ sums.inkatom1st_modelSums['2P12S'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['2P12S'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom1st_modelSums['2P12S'].input === 0 ? 0 : (((sums.inkatom1st_modelSums['2P12S'].ng ) / (sums.inkatom1st_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom1st_modelSums['2P12S'].work_minute}}</td>                      
        </tr>
        <tr>      
          <td>3P8S</td>          
          <td>{{ sums.inkatom1st_modelSums['3P8S'].target }}</td>
          <td>{{ sums.inkatom1st_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.inkatom1st_modelSums['3P8S'].ok }}</td>   
          <td class="verification">{{ sums.inkatom1st_modelSums['3P8S'].verification }}</td>               
          <td class="ng">{{ sums.inkatom1st_modelSums['3P8S'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['3P8S'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom1st_modelSums['3P8S'].input === 0 ? 0 : (((sums.inkatom1st_modelSums['3P8S'].ng ) / (sums.inkatom1st_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom1st_modelSums['3P8S'].work_minute}}</td>                      
        </tr>
        <tr>      
          <td>EVO 2P</td>          
          <td>{{ sums.inkatom1st_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.inkatom1st_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.inkatom1st_modelSums['EVO_2P'].ok }}</td>         
          <td class="verification">{{ sums.inkatom1st_modelSums['EVO_2P'].verification }}</td>   
          <td class="ng">{{ sums.inkatom1st_modelSums['EVO_2P'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_2P'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom1st_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.inkatom1st_modelSums['EVO_2P'].ng ) / (sums.inkatom1st_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom1st_modelSums['EVO_2P'].work_minute}}</td>                      
        </tr>
        <tr>      
          <td>EVO 3P</td>          
          <td>{{ sums.inkatom1st_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.inkatom1st_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.inkatom1st_modelSums['EVO_3P'].ok }}</td>         
          <td class="verification">{{ sums.inkatom1st_modelSums['EVO_3P'].verification }}</td>    
          <td class="ng">{{ sums.inkatom1st_modelSums['EVO_3P'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_modelSums['EVO_3P'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom1st_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.inkatom1st_modelSums['EVO_3P'].ng ) / (sums.inkatom1st_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom1st_modelSums['EVO_3P'].work_minute}}</td>                      
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.inkatom1st_Sums.target }}</td>
          <td>{{ sums.inkatom1st_Sums.input }}</td>
          <td class="ok">{{ sums.inkatom1st_Sums.ok }}</td>         
          <td class="verification">{{ sums.inkatom1st_Sums.verification }}</td>         
          <td class="ng">{{ sums.inkatom1st_Sums.ng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_coverdamage }}</td>          
          <td class="ng_type">{{ sums.inkatom1st_Sums.ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom1st_Sums.input === 0 ? 0 : (((sums.inkatom1st_Sums.ng ) / (sums.inkatom1st_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom1st_Sums.work_minute}}</td>                           
        </tr>        
      </table>

      <B>Inkatom 2nd</B>
      <table>
        <tr><th v-for="(header, index) in inkatom2nd_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.inkatom2nd_modelSums['2P12S'].target }}</td>
          <td>{{ sums.inkatom2nd_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.inkatom2nd_modelSums['2P12S'].ok }}</td>         
          <td class="verification">{{ sums.inkatom2nd_modelSums['2P12S'].verification }}</td>         
          <td class="ng">{{ sums.inkatom2nd_modelSums['2P12S'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['2P12S'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom2nd_modelSums['2P12S'].input === 0 ? 0 : (((sums.inkatom2nd_modelSums['2P12S'].ng ) / (sums.inkatom2nd_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom2nd_modelSums['2P12S'].work_minute}}</td>                      
        </tr>
        <tr>      
          <td>3P8S</td>          
          <td>{{ sums.inkatom2nd_modelSums['3P8S'].target }}</td>
          <td>{{ sums.inkatom2nd_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.inkatom2nd_modelSums['3P8S'].ok }}</td>         
          <td class="verification">{{ sums.inkatom2nd_modelSums['3P8S'].verification }}</td>   
          <td class="ng">{{ sums.inkatom2nd_modelSums['3P8S'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['3P8S'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom2nd_modelSums['3P8S'].input === 0 ? 0 : (((sums.inkatom2nd_modelSums['3P8S'].ng ) / (sums.inkatom2nd_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom2nd_modelSums['3P8S'].work_minute}}</td>                      
        </tr>
        <tr>      
          <td>EVO 2P</td>          
          <td>{{ sums.inkatom2nd_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.inkatom2nd_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.inkatom2nd_modelSums['EVO_2P'].ok }}</td>         
          <td class="verification">{{ sums.inkatom2nd_modelSums['EVO_2P'].verification }}</td>         
          <td class="ng">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_2P'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom2nd_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.inkatom2nd_modelSums['EVO_2P'].ng ) / (sums.inkatom2nd_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom2nd_modelSums['EVO_2P'].work_minute}}</td>                      
        </tr>
        <tr>      
          <td>EVO 3P</td>          
          <td>{{ sums.inkatom2nd_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.inkatom2nd_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.inkatom2nd_modelSums['EVO_3P'].ok }}</td>         
          <td class="verification">{{ sums.inkatom2nd_modelSums['EVO_3P'].verification }}</td>  
          <td class="ng">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_3P'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom2nd_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.inkatom2nd_modelSums['EVO_3P'].ng ) / (sums.inkatom2nd_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom2nd_modelSums['EVO_3P'].work_minute}}</td>                      
        </tr>
        <tr>      
          <td>EVO MC25 3P</td>          
          <td>{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].target }}</td>
          <td>{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].input }}</td>
          <td class="ok">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ok }}</td>         
          <td class="verification">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].verification }}</td>  
          <td class="ng">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_coverdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].input === 0 ? 0 : (((sums.inkatom2nd_modelSums['EVO_MC25_3P'].ng ) / (sums.inkatom2nd_modelSums['EVO_MC25_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom2nd_modelSums['EVO_MC25_3P'].work_minute}}</td>                      
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.inkatom2nd_Sums.target }}</td>
          <td>{{ sums.inkatom2nd_Sums.input }}</td>
          <td class="ok">{{ sums.inkatom2nd_Sums.ok }}</td>         
          <td class="verification">{{ sums.inkatom2nd_Sums.verification }}</td>       
          <td class="ng">{{ sums.inkatom2nd_Sums.ng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_heatstacking }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_busbardamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_fpcbdamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_weldlineposition }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_weldingng }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_finalslot }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_framedamage }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_eolreal }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_coating }}</td>
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_coverdamage }}</td>          
          <td class="ng_type">{{ sums.inkatom2nd_Sums.ng_etc }}</td>          
          <td class="ng">{{ sums.inkatom2nd_Sums.input === 0 ? 0 : (((sums.inkatom2nd_Sums.ng ) / (sums.inkatom2nd_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.inkatom2nd_Sums.work_minute}}</td>                           
        </tr>        
      </table>

      <B>inkatom Flexible</B>
      <table>
        <tr><th v-for="(header, index) in inkatom_flex_po_headers" :key="index">{{ header }}</th></tr>   
        <tr>
          <td>Positive</td>          
          <td>{{ sums.inkatom_flex_po_Sums.target }}</td>
          <td>{{ sums.inkatom_flex_po_Sums.input }}</td>
          <td class="ok">{{ sums.inkatom_flex_po_Sums.ok }}</td>         
          <td class="ng">{{ [ sums.inkatom_flex_po_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_po_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_po_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_po_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_po_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.inkatom_flex_po_Sums.input === 0 ? 0 : (((sums.inkatom_flex_po_Sums.ng ) / (sums.inkatom_flex_po_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.inkatom_flex_po_Sums.fn_wa_klip + sums.inkatom_flex_po_Sums.fn_wa_orangetape + sums.inkatom_flex_po_Sums.fn_wa_blacktape + sums.inkatom_flex_po_Sums.fn_wa_siliconcap + sums.inkatom_flex_po_Sums.fn_wa_capplus + sums.inkatom_flex_po_Sums.fn_wa_bandcable + sums.inkatom_flex_po_Sums.fn_lack_klip + sums.inkatom_flex_po_Sums.fn_lack_orangetape + sums.inkatom_flex_po_Sums.fn_lack_blacktape + sums.inkatom_flex_po_Sums.fn_lack_siliconcap + sums.inkatom_flex_po_Sums.fn_lack_capplus + sums.inkatom_flex_po_Sums.fn_lack_bandcable }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_wa_siliconcap }}</td>          
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type"></td>       
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_wa_bandcable }}</td>     
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_lack_siliconcap }}</td>          
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_lack_capplus }}</td>          
          <td class="fn_type"></td>       
          <td class="fn_type">{{ sums.inkatom_flex_po_Sums.fn_lack_bandcable }}</td>             
          <td class="fn">{{ sums.inkatom_flex_po_Sums.input === 0 ? 0 : ((sums.inkatom_flex_po_Sums.fn_wa_klip + sums.inkatom_flex_po_Sums.fn_wa_orangetape + sums.inkatom_flex_po_Sums.fn_wa_blacktape + sums.inkatom_flex_po_Sums.fn_wa_siliconcap + sums.inkatom_flex_po_Sums.fn_wa_capplus + sums.inkatom_flex_po_Sums.fn_wa_bandcable + sums.inkatom_flex_po_Sums.fn_lack_klip + sums.inkatom_flex_po_Sums.fn_lack_orangetape + sums.inkatom_flex_po_Sums.fn_lack_blacktape + sums.inkatom_flex_po_Sums.fn_lack_siliconcap + sums.inkatom_flex_po_Sums.fn_lack_capplus + sums.inkatom_flex_po_Sums.fn_lack_bandcable)/sums.inkatom_flex_po_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.inkatom_flex_po_Sums.work_minute}}</td>                      
        </tr> 
        <tr>
          <td>6+7-</td>          
          <td>{{ sums.inkatom_flex_67_Sums.target }}</td>
          <td>{{ sums.inkatom_flex_67_Sums.input }}</td>
          <td class="ok">{{ sums.inkatom_flex_67_Sums.ok }}</td>         
          <td class="ng">{{ [ sums.inkatom_flex_67_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_67_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_67_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_67_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_67_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.inkatom_flex_67_Sums.input === 0 ? 0 : (((sums.inkatom_flex_67_Sums.ng ) / (sums.inkatom_flex_67_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.inkatom_flex_67_Sums.fn_wa_klip + sums.inkatom_flex_67_Sums.fn_wa_orangetape + sums.inkatom_flex_67_Sums.fn_wa_blacktape + sums.inkatom_flex_67_Sums.fn_wa_capplus + sums.inkatom_flex_67_Sums.fn_wa_capminus + sums.inkatom_flex_67_Sums.fn_lack_klip + sums.inkatom_flex_67_Sums.fn_lack_orangetape + sums.inkatom_flex_67_Sums.fn_lack_blacktape + sums.inkatom_flex_67_Sums.fn_lack_capplus + sums.inkatom_flex_67_Sums.fn_lack_capminus }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_wa_capminus }}</td>       
          <td class="fn_type"></td>     
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_lack_capplus }}</td>          
          <td class="fn_type">{{ sums.inkatom_flex_67_Sums.fn_lack_capminus }}</td>       
          <td class="fn_type"></td>       
          <td class="fn">{{ sums.inkatom_flex_67_Sums.input === 0 ? 0 : ((sums.inkatom_flex_67_Sums.fn_wa_klip + sums.inkatom_flex_67_Sums.fn_wa_orangetape + sums.inkatom_flex_67_Sums.fn_wa_blacktape + sums.inkatom_flex_67_Sums.fn_wa_capplus + sums.inkatom_flex_67_Sums.fn_wa_capminus + sums.inkatom_flex_67_Sums.fn_lack_klip + sums.inkatom_flex_67_Sums.fn_lack_orangetape + sums.inkatom_flex_67_Sums.fn_lack_blacktape + sums.inkatom_flex_67_Sums.fn_lack_capplus + sums.inkatom_flex_67_Sums.fn_lack_capminus)/sums.inkatom_flex_67_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.inkatom_flex_67_Sums.work_minute}}</td>                           
        </tr> 
        <tr>
          <td>Negative</td>          
          <td>{{ sums.inkatom_flex_ne_Sums.target }}</td>
          <td>{{ sums.inkatom_flex_ne_Sums.input }}</td>
          <td class="ok">{{ sums.inkatom_flex_ne_Sums.ok }}</td>         
          <td class="ng">{{ [ sums.inkatom_flex_ne_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_ne_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_ne_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_ne_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_ne_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.inkatom_flex_ne_Sums.input === 0 ? 0 : (((sums.inkatom_flex_ne_Sums.ng ) / (sums.inkatom_flex_ne_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.inkatom_flex_ne_Sums.fn_wa_klip + sums.inkatom_flex_ne_Sums.fn_wa_orangetape + sums.inkatom_flex_ne_Sums.fn_wa_blacktape + sums.inkatom_flex_ne_Sums.fn_wa_siliconcap + sums.inkatom_flex_ne_Sums.fn_wa_capminus + sums.inkatom_flex_ne_Sums.fn_lack_klip + sums.inkatom_flex_ne_Sums.fn_lack_orangetape + sums.inkatom_flex_ne_Sums.fn_lack_blacktape + sums.inkatom_flex_ne_Sums.fn_lack_siliconcap + sums.inkatom_flex_ne_Sums.fn_lack_capminus }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_wa_siliconcap }}</td>          
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_wa_capminus }}</td>       
          <td class="fn_type"></td>     
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_lack_siliconcap }}</td>          
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.inkatom_flex_ne_Sums.fn_lack_capminus }}</td>       
          <td class="fn_type"></td>       
          <td class="fn">{{ sums.inkatom_flex_ne_Sums.input === 0 ? 0 : ((sums.inkatom_flex_ne_Sums.fn_wa_klip + sums.inkatom_flex_ne_Sums.fn_wa_orangetape + sums.inkatom_flex_ne_Sums.fn_wa_blacktape + sums.inkatom_flex_ne_Sums.fn_wa_siliconcap + sums.inkatom_flex_ne_Sums.fn_wa_capminus + sums.inkatom_flex_ne_Sums.fn_lack_klip + sums.inkatom_flex_ne_Sums.fn_lack_orangetape + sums.inkatom_flex_ne_Sums.fn_lack_blacktape + sums.inkatom_flex_ne_Sums.fn_lack_siliconcap + sums.inkatom_flex_ne_Sums.fn_lack_capminus)/sums.inkatom_flex_ne_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.inkatom_flex_ne_Sums.work_minute}}</td>                            
        </tr>
        <tr>
        <td>4-5+</td>          
          <td>{{ sums.inkatom_flex_45_Sums.target }}</td>
          <td>{{ sums.inkatom_flex_45_Sums.input }}</td>
          <td class="ok">{{ sums.inkatom_flex_45_Sums.ok  }}</td>         
          <td class="ng">{{ [ sums.inkatom_flex_45_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_45_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_45_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_45_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.inkatom_flex_45_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.inkatom_flex_45_Sums.input === 0 ? 0 : (((sums.inkatom_flex_45_Sums.ng ) / (sums.inkatom_flex_45_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.inkatom_flex_45_Sums.fn_wa_klip + sums.inkatom_flex_45_Sums.fn_wa_orangetape + sums.inkatom_flex_45_Sums.fn_wa_blacktape + sums.inkatom_flex_45_Sums.fn_wa_capplus + sums.inkatom_flex_45_Sums.fn_wa_capminus + sums.inkatom_flex_45_Sums.fn_lack_klip + sums.inkatom_flex_45_Sums.fn_lack_orangetape + sums.inkatom_flex_45_Sums.fn_lack_blacktape + sums.inkatom_flex_45_Sums.fn_lack_capplus + sums.inkatom_flex_45_Sums.fn_lack_capminus }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_wa_capminus }}</td>       
          <td class="fn_type"></td>     
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type">{{ sums.inkatom_flex_45_Sums.fn_lack_capminus }}</td>         
          <td class="fn_type"></td>       
          <td class="fn">{{ sums.inkatom_flex_45_Sums.input === 0 ? 0 : ((sums.inkatom_flex_45_Sums.fn_wa_klip + sums.inkatom_flex_45_Sums.fn_wa_orangetape + sums.inkatom_flex_45_Sums.fn_wa_blacktape + sums.inkatom_flex_45_Sums.fn_wa_capplus + sums.inkatom_flex_45_Sums.fn_wa_capminus + sums.inkatom_flex_45_Sums.fn_lack_klip + sums.inkatom_flex_45_Sums.fn_lack_orangetape + sums.inkatom_flex_45_Sums.fn_lack_blacktape + sums.inkatom_flex_45_Sums.fn_lack_capplus + sums.inkatom_flex_45_Sums.fn_lack_capminus)/sums.inkatom_flex_45_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.inkatom_flex_45_Sums.work_minute}}</td>                      
        </tr>                  
      </table>   
                     
      <!--  Target 요소(tact time 등) 필요시 부르는 방법 -->
        <!--  callTarget('TR3 SMT').tacttime  ( )안에 설비 이름을 넣고, 점 뒤에 필요한것을 치면 된다. -->
    </div>
  </template>

<script>

import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

export default {
  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();

    //각 설비별 데이터 베이스 table 이름을 입력해야 됨
    const targetTableName = "target"; 
    const inkatom1st_dbTableName = 'inkatom1st';   
    const inkatom2nd_dbTableName = 'inkatom2nd';   
    const inkatom_flex_po_dbTableName = 'inkatom_flex_po';        
    const inkatom_flex_67_dbTableName = 'inkatom_flex_67';        
    const inkatom_flex_ne_dbTableName = 'inkatom_flex_ne';        
    const inkatom_flex_45_dbTableName = 'inkatom_flex_45';   

    //각 설비별 header를 입력해야 됨
    
    
    const inkatom1st_headers = ['Model', 'Target', 'Input', 'OK', 'Verification', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL Real', 'Coating', 'Cover Damage', 'ETC', 'NG Rate', 'Work time',];
    const inkatom2nd_headers = ['Model', 'Target', 'Input', 'OK', 'Verification', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL Real', 'Coating', 'Cover Damage', 'ETC', 'NG Rate', 'Work time',];    
    const inkatom_flex_po_headers = ['Model', 'Target', 'Input', 'OK', 'NG', 'Damaged Cap', 'Damaged Flex', 'Damaged Tape', "Visible Copper", "NG Rate", 'Fake Sum', 'Assembly KLIP', 'Assembly Orange Tape', 'Assembly Black Tape', 'Assembly Silicon Cap', 'Assembly CAP +', 'Assembly CAP -', 'Assembly Band Cable', 'Lack KLIP', 'Lack Orange Tape', 'Lack Black Tape', 'Lack Silicon Cap', 'Lack CAP +', 'Lack CAP -', 'Lack Band Cable', 'Fake NG Rate', 'Work time',];   
  
    //각 설비별 Data를 받아올 함수 명을 입력해야 됨
    const targetList = reactive({ data: [], })
    const inkatom1st_Data = reactive({ data: [] });
    const inkatom2nd_Data = reactive({ data: [] });   
    const inkatom_flex_po_TableData = reactive({ data: [] });    
    const inkatom_flex_67_TableData = reactive({ data: [] });    
    const inkatom_flex_ne_TableData = reactive({ data: [] });    
    const inkatom_flex_45_TableData = reactive({ data: [] }); 

    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value]; return {sendDateData};};     
    const searchData = async (tableName) => {
        const sendDateData = await sendDate();
        console.log('Requesting data with:', {
            tablename: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endtYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        });
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        };  

        targetList.data = [];
        inkatom1st_Data.data = [];
        inkatom2nd_Data.data = [];        
        inkatom_flex_po_TableData.data = [];
        inkatom_flex_67_TableData.data = [];
        inkatom_flex_ne_TableData.data = [];
        inkatom_flex_45_TableData.data = [];

      console.log('Requesting data with:', formData);
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => {          
          switch (tableName) {
            //각 설비별로 DB table 이름과 변수 이름을 입력해야 됨
            case 'inkatom1st': inkatom1st_Data.data = res.data; break;
            case 'inkatom2nd': inkatom2nd_Data.data = res.data; break; 
            case 'inkatom_flex_po': inkatom_flex_po_TableData.data = res.data; break;            
            case 'inkatom_flex_67': inkatom_flex_67_TableData.data = res.data; break;            
            case 'inkatom_flex_ne': inkatom_flex_ne_TableData.data = res.data; break;            
            case 'inkatom_flex_45': inkatom_flex_45_TableData.data = res.data; break;            
          }
        });
    };

    const targetSearchData = async () => { //여기서는 사용 안함
          axios.get("/api/targetList").then((res) => {
          console.log("Response from /api/target:", res.data);
          targetList.data = res.data
          })
          .catch((error) => {
            console.error("Error fetching target data:", error.message);
          });
      }

    const callTarget = (lineName) => {return targetItem.value[lineName] || {};}; //여기서는 사용 안함

    //각 설비별 Data를 호출하는 것을 입력해야 됨
    
    const tableNames = [ inkatom1st_dbTableName, inkatom2nd_dbTableName, inkatom_flex_po_dbTableName, inkatom_flex_67_dbTableName,
                          inkatom_flex_ne_dbTableName, inkatom_flex_45_dbTableName ];

    const callSearchData = async () => {
      targetSearchData();
      for (const tableName of tableNames) {
        try {
          await searchData(tableName);
        } catch (error) {
          console.error(`Error loading data from ${tableName}:`, error);      
        }
      }
      settingCall();
    };

    const calculateInputAndNgRate = (ok, ng) => { const input = ok + ng; return { input }; };

    //각 설비별로 item 이름과 변수 이름을 입력해 줘야 함
    const targetItem = computed(() => {const items = {}; targetList.data.forEach((d) => {items[d.line_name] = d;});return items;});
    const inkatom1st_item = computed(() => { return inkatom1st_Data.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});    
    const inkatom2nd_item = computed(() => { return inkatom2nd_Data.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});    
    const inkatom_flex_po_item = computed(() => { return inkatom_flex_po_TableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});    
    const inkatom_flex_67_item = computed(() => { return inkatom_flex_67_TableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});    
    const inkatom_flex_ne_item = computed(() => { return inkatom_flex_ne_TableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});    
    const inkatom_flex_45_item = computed(() => { return inkatom_flex_45_TableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    
    const sums = computed(() => {
        //각 설비별 "let 함수 이름"을 만들고 모델 이름과 가져올 Data를 초기화 해야 함        
        //Inkatom 1st
        let inkatom1st_Sums = { target: 0, input: 0, ok: 0, verification:0,  ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 };  
        let inkatom1st_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, verification:0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_2P": { target: 0, input: 0, ok: 0, verification:0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0},                             
            "3P8S": { target: 0, input: 0, ok: 0, verification:0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_3P": { target: 0, input: 0, ok: 0, verification:0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0},                             
        };        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of inkatom1st_item.value) {            
            inkatom1st_Sums.target += row.target;
            inkatom1st_Sums.input += row.input;
            inkatom1st_Sums.ok += row.ok;            
            inkatom1st_Sums.verification += row.verification;    
            inkatom1st_Sums.ng += row.ng;
            inkatom1st_Sums.ng_heatstacking += row.ng_heatstacking;
            inkatom1st_Sums.ng_busbardamage += row.ng_busbardamage;
            inkatom1st_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            inkatom1st_Sums.ng_weldlineposition += row.ng_weldlineposition;
            inkatom1st_Sums.ng_weldingng += row.ng_weldingng;
            inkatom1st_Sums.ng_finalslot += row.ng_finalslot;
            inkatom1st_Sums.ng_framedamage += row.ng_framedamage;
            inkatom1st_Sums.ng_eolreal += row.ng_eolreal;
            inkatom1st_Sums.ng_coating += row.ng_coating;
            inkatom1st_Sums.ng_coverdamage += row.ng_coverdamage;            
            inkatom1st_Sums.ng_etc += row.ng_etc;
            inkatom1st_Sums.work_minute += row.work_minute;
          }     

        for (const row of inkatom1st_item.value) {
            if (Object.prototype.hasOwnProperty.call(inkatom1st_modelSums, row.model)) {
                inkatom1st_modelSums[row.model].target += row.target;
                inkatom1st_modelSums[row.model].input += row.input;
                inkatom1st_modelSums[row.model].ok += row.ok;                
                inkatom1st_modelSums[row.model].verification += row.verification;         
                inkatom1st_modelSums[row.model].ng += row.ng;
                inkatom1st_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                inkatom1st_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                inkatom1st_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                inkatom1st_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                inkatom1st_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                inkatom1st_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                inkatom1st_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                inkatom1st_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                inkatom1st_modelSums[row.model].ng_coating += row.ng_coating;
                inkatom1st_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;                
                inkatom1st_modelSums[row.model].ng_etc += row.ng_etc;
                inkatom1st_modelSums[row.model].work_minute += row.work_minute;                            
            }
        }  

        //Inkatom 2nd
        let inkatom2nd_Sums = { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 };  
        let inkatom2nd_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_2P": { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0},                             
            "3P8S": { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_3P": { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0},                             
            "EVO_MC25_3P": { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, work_minute: 0},                             
        };        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of inkatom2nd_item.value) {            
            inkatom2nd_Sums.target += row.target;
            inkatom2nd_Sums.input += row.input;
            inkatom2nd_Sums.ok += row.ok;            
            inkatom2nd_Sums.verification += row.verification;       
            inkatom2nd_Sums.ng += row.ng;
            inkatom2nd_Sums.ng_heatstacking += row.ng_heatstacking;
            inkatom2nd_Sums.ng_busbardamage += row.ng_busbardamage;
            inkatom2nd_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            inkatom2nd_Sums.ng_weldlineposition += row.ng_weldlineposition;
            inkatom2nd_Sums.ng_weldingng += row.ng_weldingng;
            inkatom2nd_Sums.ng_finalslot += row.ng_finalslot;
            inkatom2nd_Sums.ng_framedamage += row.ng_framedamage;
            inkatom2nd_Sums.ng_eolreal += row.ng_eolreal;
            inkatom2nd_Sums.ng_coating += row.ng_coating;
            inkatom2nd_Sums.ng_coverdamage += row.ng_coverdamage;            
            inkatom2nd_Sums.ng_etc += row.ng_etc;
            inkatom2nd_Sums.work_minute += row.work_minute;
          }     

        for (const row of inkatom2nd_item.value) {
            if (Object.prototype.hasOwnProperty.call(inkatom2nd_modelSums, row.model)) {
                inkatom2nd_modelSums[row.model].target += row.target;
                inkatom2nd_modelSums[row.model].input += row.input;
                inkatom2nd_modelSums[row.model].ok += row.ok;                
                inkatom2nd_modelSums[row.model].verification += row.verification;
                inkatom2nd_modelSums[row.model].ng += row.ng;
                inkatom2nd_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                inkatom2nd_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                inkatom2nd_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                inkatom2nd_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                inkatom2nd_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                inkatom2nd_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                inkatom2nd_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                inkatom2nd_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                inkatom2nd_modelSums[row.model].ng_coating += row.ng_coating;
                inkatom2nd_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;                
                inkatom2nd_modelSums[row.model].ng_etc += row.ng_etc;
                inkatom2nd_modelSums[row.model].work_minute += row.work_minute;                            
            }
        }  

        //Inkatom Flexible Positive
        let inkatom_flex_po_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0, fn_wa_capplus: 0, fn_wa_bandcable: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_siliconcap: 0, fn_lack_capplus: 0, fn_lack_bandcable: 0, work_minute: 0};                  
        for (const row of inkatom_flex_po_item.value) {            
            inkatom_flex_po_Sums.target += row.target;
            inkatom_flex_po_Sums.input += row.input;
            inkatom_flex_po_Sums.ok += row.ok;
            inkatom_flex_po_Sums.ng += row.ng;
            inkatom_flex_po_Sums.ng_damagedcap += row.ng_damagedcap;
            inkatom_flex_po_Sums.ng_damagedflex += row.ng_damagedflex;            
            inkatom_flex_po_Sums.ng_damagedtape += row.ng_damagedtape;
            inkatom_flex_po_Sums.ng_visiblecopper += row.ng_visiblecopper;
            inkatom_flex_po_Sums.fn_wa_klip += row.fn_wa_klip;
            inkatom_flex_po_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            inkatom_flex_po_Sums.fn_wa_blacktape += row.fn_wa_blacktape;
            inkatom_flex_po_Sums.fn_wa_siliconcap += row.fn_wa_siliconcap;
            inkatom_flex_po_Sums.fn_wa_capplus += row.fn_wa_capplus;
            inkatom_flex_po_Sums.fn_wa_bandcable += row.fn_wa_bandcable;
            inkatom_flex_po_Sums.fn_lack_klip += row.fn_lack_klip;
            inkatom_flex_po_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            inkatom_flex_po_Sums.fn_lack_blacktape += row.fn_lack_blacktape;
            inkatom_flex_po_Sums.fn_lack_siliconcap += row.fn_lack_siliconcap;
            inkatom_flex_po_Sums.fn_lack_capplus += row.fn_lack_capplus;
            inkatom_flex_po_Sums.fn_lack_bandcable += row.fn_lack_bandcable;            
            inkatom_flex_po_Sums.work_minute += row.work_minute;
          }      

        //Inkatom Flexible 6+7-
        let inkatom_flex_67_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_capplus: 0, fn_wa_capminus: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_capplus: 0, fn_lack_capminus: 0, work_minute: 0};                  
        for (const row of inkatom_flex_67_item.value) {            
            inkatom_flex_67_Sums.target += row.target;
            inkatom_flex_67_Sums.input += row.input;
            inkatom_flex_67_Sums.ok += row.ok;
            inkatom_flex_67_Sums.ng += row.ng;
            inkatom_flex_67_Sums.ng_damagedcap += row.ng_damagedcap;
            inkatom_flex_67_Sums.ng_damagedflex += row.ng_damagedflex;            
            inkatom_flex_67_Sums.ng_damagedtape += row.ng_damagedtape;
            inkatom_flex_67_Sums.ng_visiblecopper += row.ng_visiblecopper;
            inkatom_flex_67_Sums.fn_wa_klip += row.fn_wa_klip;
            inkatom_flex_67_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            inkatom_flex_67_Sums.fn_wa_blacktape += row.fn_wa_blacktape;            
            inkatom_flex_67_Sums.fn_wa_capplus += row.fn_wa_capplus;
            inkatom_flex_67_Sums.fn_wa_capminus += row.fn_wa_capminus;
            inkatom_flex_67_Sums.fn_lack_klip += row.fn_lack_klip;
            inkatom_flex_67_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            inkatom_flex_67_Sums.fn_lack_blacktape += row.fn_lack_blacktape;            
            inkatom_flex_67_Sums.fn_lack_capplus += row.fn_lack_capplus;
            inkatom_flex_67_Sums.fn_lack_capminus += row.fn_lack_capminus;            
            inkatom_flex_67_Sums.work_minute += row.work_minute;
          }      

        //Inkatom Flexible Negative
        let inkatom_flex_ne_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0, fn_wa_capminus: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_siliconcap: 0, fn_lack_capminus: 0, work_minute: 0};                  
        for (const row of inkatom_flex_ne_item.value) {            
            inkatom_flex_ne_Sums.target += row.target;
            inkatom_flex_ne_Sums.input += row.input;
            inkatom_flex_ne_Sums.ok += row.ok;
            inkatom_flex_ne_Sums.ng += row.ng;
            inkatom_flex_ne_Sums.ng_damagedcap += row.ng_damagedcap;
            inkatom_flex_ne_Sums.ng_damagedflex += row.ng_damagedflex;            
            inkatom_flex_ne_Sums.ng_damagedtape += row.ng_damagedtape;
            inkatom_flex_ne_Sums.ng_visiblecopper += row.ng_visiblecopper;
            inkatom_flex_ne_Sums.fn_wa_klip += row.fn_wa_klip;
            inkatom_flex_ne_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            inkatom_flex_ne_Sums.fn_wa_blacktape += row.fn_wa_blacktape;            
            inkatom_flex_ne_Sums.fn_wa_siliconcap += row.fn_wa_siliconcap;
            inkatom_flex_ne_Sums.fn_wa_capminus += row.fn_wa_capminus;
            inkatom_flex_ne_Sums.fn_lack_klip += row.fn_lack_klip;
            inkatom_flex_ne_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            inkatom_flex_ne_Sums.fn_lack_blacktape += row.fn_lack_blacktape;            
            inkatom_flex_ne_Sums.fn_lack_siliconcap += row.fn_lack_siliconcap;
            inkatom_flex_ne_Sums.fn_lack_capminus += row.fn_lack_capminus;            
            inkatom_flex_ne_Sums.work_minute += row.work_minute;
          }      

        //Inkatom Flexible 4-5+
        let inkatom_flex_45_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_capplus: 0, fn_wa_capminus: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_capplus: 0, fn_lack_capminus: 0, work_minute: 0};                  
        for (const row of inkatom_flex_45_item.value) {            
            inkatom_flex_45_Sums.target += row.target;
            inkatom_flex_45_Sums.input += row.input;
            inkatom_flex_45_Sums.ok += row.ok;
            inkatom_flex_45_Sums.ng += row.ng;
            inkatom_flex_45_Sums.ng_damagedcap += row.ng_damagedcap;
            inkatom_flex_45_Sums.ng_damagedflex += row.ng_damagedflex;            
            inkatom_flex_45_Sums.ng_damagedtape += row.ng_damagedtape;
            inkatom_flex_45_Sums.ng_visiblecopper += row.ng_visiblecopper;
            inkatom_flex_45_Sums.fn_wa_klip += row.fn_wa_klip;
            inkatom_flex_45_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            inkatom_flex_45_Sums.fn_wa_blacktape += row.fn_wa_blacktape;            
            inkatom_flex_45_Sums.fn_wa_capplus += row.fn_wa_capplus;
            inkatom_flex_45_Sums.fn_wa_capminus += row.fn_wa_capminus;
            inkatom_flex_45_Sums.fn_lack_klip += row.fn_lack_klip;
            inkatom_flex_45_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            inkatom_flex_45_Sums.fn_lack_blacktape += row.fn_lack_blacktape;            
            inkatom_flex_45_Sums.fn_lack_capplus += row.fn_lack_capplus;
            inkatom_flex_45_Sums.fn_lack_capminus += row.fn_lack_capminus;            
            inkatom_flex_45_Sums.work_minute += row.work_minute;
          }    

        return { inkatom1st_modelSums, inkatom2nd_modelSums, inkatom1st_Sums, inkatom2nd_Sums,
                inkatom_flex_po_Sums, inkatom_flex_67_Sums, inkatom_flex_ne_Sums, inkatom_flex_45_Sums,  };
    });

    const settingData = reactive({ data: [], });        

    const settingCall = async () => {         
        const tableName ='setting';
        const formData = { tableName: tableName };
        axios.get('/api/targetList', { params: formData })
        .then((res) => { settingData.data = res.data; })
        .catch((error) => { console.error("Error download excel data:", error.message); });
    };

       //설비 추가시 엑셀 다운로드 부분 열어서 수정해야 됨
       const prdownloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          //각 Table 이름을 다 쳐줘야 한다. sheet로 나뉘어서 저장됨 
          //희한하게 header 부분은 본 vue 파일에 있는 각 설비의 headers 부분을 pr,dt,qw에 똑같이 넣으면 잘 나온다.         
          const inkatom1st_wsPr = XLSX.utils.json_to_sheet(inkatom1st_Data.data, { header: inkatom1st_headers.value });          
          const inkatom2nd_wsPr = XLSX.utils.json_to_sheet(inkatom2nd_Data.data, { header: inkatom2nd_headers.value });  
          const inkatomPo_wsPr = XLSX.utils.json_to_sheet(inkatom_flex_po_TableData.data, { header: inkatom_flex_po_headers.value });  
          const inkatomNe_wsPr = XLSX.utils.json_to_sheet(inkatom_flex_ne_TableData.data, { header: inkatom_flex_po_headers.value });  
          const inkatom45_wsPr = XLSX.utils.json_to_sheet(inkatom_flex_45_TableData.data, { header: inkatom_flex_po_headers.value });  
          const inkatom67_wsPr = XLSX.utils.json_to_sheet(inkatom_flex_67_TableData.data, { header: inkatom_flex_po_headers.value });  

          //각 sheet 이름을 다 쳐줘야 한다
          XLSX.utils.book_append_sheet(wb, inkatom1st_wsPr, "Inkatom 1st");
          XLSX.utils.book_append_sheet(wb, inkatomPo_wsPr, "Flex Positive");
          XLSX.utils.book_append_sheet(wb, inkatomNe_wsPr, "Flex Negative");
          XLSX.utils.book_append_sheet(wb, inkatom45_wsPr, "Flex 45");
          XLSX.utils.book_append_sheet(wb, inkatom67_wsPr, "Flex 67");
          XLSX.utils.book_append_sheet(wb, inkatom2nd_wsPr, "Inkatom 2nd");
          
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "Inkatom_data.xlsx");
        };

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay,
      targetItem, targetList, targetTableName, callTarget,
      searchData, callSearchData, sendDate, sums, prdownloadExcel, 
      //설비가 늘어나면 headers를 return해야 함
      inkatom1st_headers, inkatom2nd_headers, inkatom_flex_po_headers,
    };
  },
};

</script>

<style scoped>
   * {
    box-sizing: border-box;
  }

  .container {
    margin-top: 0px;
    padding-top: 0px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .search {
  position: relative;
  background-color: #ffffff; /* Set a background color for better visibility */  
  padding: 0px; /* Add some padding for better appearance */
  height: auto;
}
  .start,
  .end {
    display: flex;
    flex-direction: row;    
    border: 0px;
  }

  .start input,
  .end input {
    margin-right: 5px;
  }

  .separator {
    font-size: 24px;
    margin: 0 10px;
    border: 0px;
  }
  .searchTd {    
    border: 0px;
  }

  input::placeholder {
    color: gray;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100px;
  }

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
 .excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}
  .excelButton:hover,
  button:hover {
    background-color: #2980b9;
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  th,
  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 8px;
    font-size: 12px;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #e6e6e6;
  }
  .ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ok_type {
  background-color: #d7e4fc; /* Adjust the color as needed */
  font-weight: bold;
}
.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style> 