
import { reactive, computed, watchEffect } from 'vue';
import { addData } from './data.js';

const spareTableData = reactive({ data: [] });

watchEffect(() => { spareTableData.data = addData[6].data;});

const factory1Data = computed(() => {
  return spareTableData.data.filter(row => row.factory === '1');
});

const isNearPurchase1st = computed(() => {
  return factory1Data.value.some(row => {    
    if (row.purchase === "purchasing") return false;    
    return row.limitqty === row.qty;
  });
});

const needToPurchase1st = computed(() => {
  return factory1Data.value.some(row => {    
    if (row.purchase === "purchasing") return false;    
    return row.limitqty > row.qty;
  });  
});

const factory2Data = computed(() => {
  return spareTableData.data.filter(row => row.factory === '2');
});

const isNearPurchase2nd = computed(() => {
  return factory2Data.value.some(row => {    
    if (row.purchase === "purchasing") return false;    
    return row.limitqty === row.qty;
  });
});

const needToPurchase2nd = computed(() => {
  return factory2Data.value.some(row => {    
    if (row.purchase === "purchasing") return false;    
    return row.limitqty > row.qty;
  });  
});

export const productionSpare1stColor = computed(() => {
  if (needToPurchase1st.value) {
    return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // 빨간색
  } else if (isNearPurchase1st.value) {
    return { backgroundColor: 'rgba(255, 255, 0, 0.7)' }; // 노란색
  }
  return { backgroundColor: 'transparent' }; // Transparent color
});

export const productionSpare2ndColor = computed(() => {
  if (needToPurchase2nd.value) {
    return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // 빨간색
  } else if (isNearPurchase2nd.value) {
    return { backgroundColor: 'rgba(255, 255, 0, 0.7)' }; // 노란색
  }
  return { backgroundColor: 'transparent' }; // Transparent color
});