<template>
  <div class="materialList">      
    <div class="excelFile right-align">
        {{ fileName }}
        <label for="file-input" class="custom-file-upload">Choose file</label>
        <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
        <button @click="upExcel('materialList')">List Upload</button>
        <button class="custom-file-upload" @click="downExcel">Down Load</button>    
        <button class="delButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="delAll(mlTableName, {delallcode: 'Del All'} )">ALL DEL</button>   
    </div>      
    <div class="table-container">
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in Headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in item" :key="row.line_name">     
        <template v-for="(header, index) in Headers">  
          <td v-if="header === 'Product Code'" :key="index" @click="editCell(mlTableName, row.id, 'productcode', $event)">{{ row.productcode }}</td>
          <td v-if="header === 'Product Name'" :key="index" @click="editCell(mlTableName, row.id, 'productname', $event)">{{ row.productname }}</td>
          <td v-if="header === 'Material Code'" :key="index" @click="editCell(mlTableName, row.id, 'materialcode', $event)">{{ row.materialcode }}</td>
          <td v-if="header === 'Material Name'" :key="index" @click="editCell(mlTableName, row.id, 'materialname', $event)">{{ row.materialname }}</td>
          <td v-if="header === 'BOM'" :key="index" @click="editCell(mlTableName, row.id, 'bom', $event)">{{ row.bom }}</td>          
          <td v-if="header === 'BOM Unit'" :key="index" @click="editCell(mlTableName, row.id, 'bomunit', $event)">{{ row.bomunit }}</td>
          <td v-if="header === 'Copper Weight'" :key="index" @click="editCell(mlTableName, row.id, 'copperweight', $event)">{{ typeof row.copperweight === 'number' ? row.copperweight.toFixed(2) : row.copperweight }}</td>          
          <td v-if="header === 'Copper Type'" :key="index" @click="editCell(mlTableName, row.id, 'coppertype', $event)">{{ row.coppertype }}</td>
          <td v-if="header === 'Coating'" :key="index" @click="editCell(mlTableName, row.id, 'coating', $event)">{{ row.coating }}</td>
          <td v-if="header === 'ROW_NO'" :key="index"  @click="editCell(mlTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>          
          <td v-if="header === 'Remark'" :key="index"  @click="editCell(mlTableName, row.id, 'remark', $event)">{{ row.remark }}</td>          
        </template>   
      </tr>
    </tbody>     
    </table>
    <h3>ROW_NO Standard</h3>
    <table class="rownostandard">
      <tr>
        <th>Project</th><th>Finished/Semi/Individual</th><th>Product classification</th><th>Serial No</th>
      </tr>
      <tr>
        <td>2 digit<br>VW : 10<br>EV2020 : 11<br>Ford : 12<br>FCA : 13<br>BMW : 14<br>BT6 : 15</td>        
        <td>1 digit<br>Finished : 1<br>Semi : 2<br>Individual : 3</td>    
        <td>3 digit</td>
        <td>2 digit</td>      
      </tr>      
    </table>
    </div>    
    </div>
  
</template>

<script>
import { reactive, computed, onMounted } from 'vue';
import axios from "axios";
import { onFileChange, uploadExcel, deleteAll, downloadExcel, editCellValue,} from "../utils.js"; 

export default {       
  data() { return { excelFile: null, fileName: "", }; },  

  methods: {
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
    downExcel() { downloadExcel(this.item, "materialList.xlsx"); },      
    delAll(tableName, filters) { deleteAll(tableName, filters, this.callSearchData); },
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    },
    canClick() {            
      const currentUser = this.$route.meta.currentUser;            
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2;}
      else { return false;}
    },    
  },
  setup() {   
    const mlTableName = "materialList";            
    const Headers = ['Product Code', 'Product Name', 'Material Code', 'Material Name', 'BOM', 'BOM Unit',
                      'Copper Weight', 'Copper Type', 'Coating', 'ROW_NO', 'Remark'
    ];
    const materialList = reactive({ data: [], })

    onMounted(() => { searchData(); });   

    const callSearchData = () => { searchData(mlTableName);  }; 

    const searchData = async () => {         

        const formData = {
          tableName: mlTableName,
        };            

        axios.get('/api/targetList', { params: formData })
          .then((res) => {                
            materialList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
          .catch((error) => {
            console.error("Error fetching target data:", error.message);
          });
    };

    const item = computed(() => {return materialList.data.map(d => {return { ...d,};});});       

    return { item, materialList, editCellValue, Headers, mlTableName, callSearchData };        
  }
}
</script>

<style scoped>
.table-container {
  overflow-x: hidden;
  position: relative;
}
.table-header,
.table-body {
  overflow: hidden;
  width: 100%;  
}
.table-body {
  max-height: 350px; /* Adjust the height to your needs */
  overflow-y: auto;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  table-layout: fixed;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}
.table thead th {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f8f9fa;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* Custom table styles */
.target {
  font-family: Arial, Helvetica, sans-serif;
}

.target .table {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.target .table th,
.target .table td {
  font-size: 0.5rem; /* Adjust the font size here */
  text-align: center;
}

.target .table tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.target .table tr:hover {
  background-color: #e9ecef;
  cursor: pointer;
}
.target hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));
  margin-bottom: 1rem;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #347003;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;  
  margin-right : 10px;
  margin-left : 10px;
}

.custom-file-upload:hover {
  background-color: #009625;
}

.excelFile {
  display: flex;
  align-items: center;  
  justify-content: flex-end;
  width: 600px;
  padding: 0 10px;  
  margin-bottom: 10px;
}

.right-align {
  margin-left: auto;
}

button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

.rownostandard { width: 50%; max-width: 50%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed;}
.rownostandard th, .rownostandard td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #000000; text-align: center; }
.rownostandard thead th { position: sticky; top: 0; z-index: 100; background-color: #f8f9fa;
  font-weight: 600; text-transform: uppercase; letter-spacing: 1px; font-size: 0.9rem; vertical-align: bottom;
  border-bottom: 2px solid #000000;
}
.rownostandard tbody + tbody { border-top: 2px solid #000000; }

 .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }


</style>