<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Production Class</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="modalTable">
              <tr>
                <td>
            <div class="form-group">   
              <label class="modalLabel">Line:</label>    
              <select class="modalSelect" id="line" v-model="formInputs.line">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
              </select>   
            </div>
            <div class="form-group">
              <label class="modalLabel">Class1:</label>
              <input class="modalInput" type="text" id="class1" v-model="formInputs.class1" />
            </div>
            <div class="form-group">
              <label class="modalLabel">Class2:</label>
              <input class="modalInput" type="text" id="class2" v-model="formInputs.class2" />
            </div>
            <div class="form-group">
              <label class="modalLabel">Remark:</label>
              <input class="modalInput" type="text" id="remark" v-model="formInputs.remark" />
            </div>
            <div class="form-group">
              <label class="modalLabel">Row No:</label>
              <input class="modalInput" type="text" id="row_no" v-model="formInputs.row_no" />
            </div>
          </td>          
            </tr>
            </table>
            <button type="submit" class="saveButton" >Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
  import { fetchLine,} from "../../utils.js"; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "productionclass"      
        const uniqueLines = ref([]);   
        
        const formInputs = reactive({
          line: "", class1: "", class2: "", remark: "", row_no: "",
        });
        
        const submitForm = async () => {          

          const columnMapping = {
            line : 'line', class1: 'class1', class2 : 'class2', remark : 'remark', row_no : 'row_no',
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              line : formInputs.line,
              class1 : formInputs.class1,
              class2 : formInputs.class2,
              remark : formInputs.remark,
              row_no : formInputs.row_no,              
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => { emit("onClose");};

        const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
  
      return { dbTableName, formInputs, submitForm, closeModal, uniqueLines };

    },     
    props: { onSubmit: { type: Function, required: true, }, }, 
};
  
  </script>
    
    <style scoped>    
    * { margin: 0; padding: 0; box-sizing: border-box; }
      
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding: 20px 20px;
      display: flex; justify-content: flex-start; align-items: flex-start;
    }
    
    .modal-wrapper { width: 100%;  max-width: 500px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
    .modalTable { table-layout: fixed; width: 100%; }  
    .modalTable td { width: 20%; vertical-align: top; padding: 0 15px; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    .modalLabel { font-weight: bold; }
  
    .modalInput { width: 100%; font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
    .modalSelect { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    .saveButton {
      background-color: #4CAF50; color: white; padding: 5px 20px; border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px; display: inline-block; transition-duration: 0.4s;
    }  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  </style>