<template>
    <div class="container">            
      <div class="act">               
        <h class="pagetitle">Daily Inventory Check</h>      
        <form @submit.prevent="sendDate()">  
          <div class="searchDiv">                                     
            <input type="text" id="year" v-model="searchDate.year" placeholder="Year"/>          
            <input type="text" id="month" v-model="searchDate.month" placeholder="Month" />                       
            <input type="text" id="day" v-model="searchDate.day" placeholder="Day"/>  
            <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
            <button class="excelDownload" @click="downExcel">Excel Down Load</button>                       
            <button class="modalButton" @click="showModal = true">+ Add</button>
            <label for="file-input" class="custom-file-upload">Excel File</label>                
            <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
            <span class="fileName">{{ fileName }}</span>
            <button class="excelButton" @click="upExcel('stocksap')">SAP Upload</button>
            <button class="dailyButton" @click="upExcel('dailyinventory')">Daily Upload</button>
          </div>
        </form>  
      </div>                
      <P></P>     
      <div class="tables">
      <table id="stockTable" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>          
        </tr>          
          <tr v-for="row in dailyItem" :key="row.id">              
            <td>{{ row.line }}</td>
            <td>{{ row.materialcode }}</td>
            <td>{{ row.materialname }}</td>
            <td>{{ row.quantity }}</td>
            <td>{{ row.unit }}</td>
            <td>{{ row.location }}</td>                            
            <td><button class="excelDownload" type="submit" @click="openSendModal(row)">Modify</button></td> 
            <td><button class="delButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>   
          </tr>
        </table><br>

        <table id="stockTable" class="custom-table">
          <tr>          
            <th v-for="(header, index) in compareHeaders" :key="index" class="sticky-header">{{ header }}</th>          
          </tr>          
          <tr v-for="row in item" :key="row.materialcode">                          
            <td>{{ row.materialcode }}</td>
            <td>{{ row.materialname }}</td>
            <td>{{ row.unit }}</td>
            <td>{{ row.dailyquantity }}</td>            
            <td>{{ row.sapquantity }}</td>            
            <td>{{ row.difference }}</td>                                        
          </tr>
        </table>     
       </div>                             
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>   
      <MoModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                   
  </div>  
</template>

<script>

import { reactive, ref, computed, } from 'vue';

import Modal from "./dailyInventoryAdd.vue";
import MoModal from "./dailyInventoryModify.vue";
import { onFileChange, uploadExcel, deleteRow, downloadExcel,} from "../../utils.js"; 
import { stockSearchData } from "./dailyUtils"; 

export default {      
  components: { Modal, MoModal },    

  methods: {   
    canClick() {      
      const currentUser = this.$route.meta.currentUser;
      if (currentUser) {          
          return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 17 || currentUser.id === 18;
      } else {
          return false;
      }
    },
    openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },      
    downExcel() { downloadExcel(this.item, "Daily_Inventory.xlsx"); },
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
  },

  setup() {              
      const showModal = ref(false);    
      const showMoModal = ref(false);   
      const headers = ['Line', 'Material Code', 'Material Name', 'Quantity', 'Unit', 'Location Name', 'Modify', 'DEL'];         
      const compareHeaders = ['Material Code', 'Material Name', 'Unit', 'Daily QTY', 'SAP QTY', 'Daily - SAP'];         
      const searchDate = ref({ year: '', month: '', day: '',});
      const sendDate = async () => {
          const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day, ];
          return {sendDateData};             
      };   

      const dailyTableName = "dailyinventory";
      const sapTableName = "stocksap";
      const inventoryTableData = reactive({ data: [], });             
      const sapTableData = reactive({ data: [], });  

      const searchData = async () => {
        try {
          const { sendDateData } = await sendDate();
          const fetchedData = await stockSearchData(sendDateData);     
          
          inventoryTableData.data = fetchedData[dailyTableName].data;
          sapTableData.data = fetchedData[sapTableName].data;       

        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      };   
      
      const callSearchData = () => { searchData(); };

      const dailyItem = computed(() => { return inventoryTableData.data.map(d => {return { ...d };});});  
      const sapItem = computed(() => { return sapTableData.data.map(d => {return { ...d };});});  

      const dailyItem_sum = computed(() => {
        const result = dailyItem.value.reduce((acc, item) => {
          if (acc[item.materialcode]) { acc[item.materialcode].quantity += item.quantity; }
          else { acc[item.materialcode] = { ...item }; }
          return acc;
        }, {});
        return Object.values(result);
      });

      const sapItem_sum = computed(() => {
        const result = sapItem.value.reduce((acc, item) => {
          if (acc[item.materialcode]) { acc[item.materialcode].quantity += item.quantity; }
          else { acc[item.materialcode] = { ...item }; }
          return acc;
        }, {});
        return Object.values(result);
      });

       const getSapQuantity = (materialcode, sapItems) => {
        const getSapItem = sapItems.find(item => item.materialcode === materialcode);
        return getSapItem ? getSapItem.quantity : 0;
      };  

      const item = computed(() => {
        const dailyItems = dailyItem_sum.value;
        const sapItems = sapItem_sum.value;
        return dailyItems.map(dailyItem => {
          const sapQuantity = getSapQuantity(dailyItem.materialcode, sapItems);
          return {
            materialcode: dailyItem.materialcode,
            materialname: dailyItem.materialname,
            unit: dailyItem.unit,
            dailyquantity: dailyItem.quantity,
            sapquantity: sapQuantity,
            difference: dailyItem.quantity - sapQuantity
          };
        });
      });

      return { headers, compareHeaders, showModal, showMoModal, callSearchData, sendDate, searchData, searchDate, dailyItem, sapItem, item };        
    },
}
</script>

<style scoped>
  * { box-sizing: border-box; }
  .container { margin-top: 0px; padding-top: 10px; max-width: 98%; margin-left: auto; margin-right: auto;}  
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 0px; }
  .searchDiv { margin-top: 10px; margin-bottom: 10px;}  
  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px; border: 1px solid #ccc;
    border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; margin-right: 10px;
  }
  .searchButton {
    padding: 5px 5px; background-color: #3498db; color: #fff;
    border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer; transition: background-color 0.3s;
  }
 .searchButton:hover { background-color: #2980b9; }
 .excelDownload {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
    padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
  }
  .excelDownload :hover { background-color: #009625; }  
  .modalButton {
    padding: 5px 5px;  background-color: #f3ac27;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 50px; margin-right: 10px;
    cursor: pointer; transition: background-color 0.3s;
  }
  .custom-file-upload {
    background-color: #4b20af;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
    padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
  }
  .custom-file-upload:hover {  background-color: #4b22Cf; }
  .sticky-header {
    position: sticky; top: 0; background-color: white; z-index: 10;
    background-color: rgb(230, 230, 230) ; border : 1px solid black;        
    text-align : center; padding: 5px 10px; font-size : 15px; 
  }
  .tables { display: flex; flex-direction: column;  align-items: stretch; height: 700px;  overflow: auto;} 
  .custom-table { font-size: 10px; width: 80%; overflow-x: auto; white-space: nowrap; } 
  td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px;} 
  .delButton {
    padding: 4px 4px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  } 
  .excelButton {
    padding: 5px 5px; background-color: #10a802;
    color: #fff; border: none;  border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer;  transition: background-color 0.3s;
  }
  .dailyButton {
    padding: 5px 5px; background-color: #310086;
    color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer; transition: background-color 0.3s;
    margin-left : 20px; margin-right : 20px;
  }
  .dailyButton:hover { background-color: #685688; }
</style>