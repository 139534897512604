<template>
    <div class="container">        
      <div class="RequestLog">
        <form @submit.prevent="sendDate()" class="searchBar">
            <table>  <!--검색 및 엑셀 버튼 -->
                <tr>
                    <td class="start">
                        <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
                        <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
                        <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
                    </td>
                    <td class="separator">~</td>
                    <td class="end">
                        <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
                        <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
                        <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
                    </td>
                    <td class="searchTd">
                        <button type="submit" @click="callSearchData">Search</button>                        
                    </td>
                    <td class="searchTd">
                        <button class="excelButton" @click="Excel">Excel</button>                                              
                    </td>
                </tr>
            </table>      
        </form>
      </div>            
      <div class="tables">
          <table id="productionResult" class="custom-table">
          <tr>          
            <th v-for="(header, index) in rqHeaders" :key="index">{{ header }}</th>          
          </tr>          
          <tr v-for="row in item" :key="row.id" :class="rowColor(row)">
              <template v-for="(header, index) in rqHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Month'" :key="index" >{{ row.month  }}</td>    
                <td v-if="header === 'Day'" :key="index" >{{ row.day  }}</td>    
                <td v-if="header === 'Time'" :key="index" >{{ row.request_time  }}</td>                
                <td v-if="header === 'Factory'" :key="index" >{{ row.factory }}</td>                
                <td v-if="header === 'Material Code'" :key="index" >{{ row.materialcode  }}</td>                
                <td v-if="header === 'Material Name'" :key="index" >{{ row.materialname  }}</td>                
                <td v-if="header === 'Quantity'" :key="index" >{{ row.quantity }}</td>                
                <td v-if="header === 'Unit'" :key="index" >{{ row.unit }}</td>    
                <td v-if="header === 'Due Month'" :key="index" >{{ row.due_month  }}</td>    
                <td v-if="header === 'Due Day'" :key="index" >{{ row.due_day  }}</td>    
                <td v-if="header === 'Due Time'" :key="index" >{{ row.due_time  }}</td>                   
                <td v-if="header === 'Detail'" :key="index" class="detail-cell" >{{ row.detail }}</td>                                 
                <td v-if="header === 'Send Month'" :key="index" >{{ row.send_month  }}</td>    
                <td v-if="header === 'Send Day'" :key="index" >{{ row.send_day  }}</td>    
                <td v-if="header === 'Send Time'" :key="index" >{{ row.send_time  }}</td>    
                <td v-if="header === 'Send Quantity'" :key="index" >{{ row.send_quantity }}</td>    
                <td v-if="header === 'Send Unit'" :key="index" >{{ row.send_unit }}</td>    
                <td v-if="header === 'Send Detail'" :key="index" class="detail-cell" >{{ row.send_detail }}</td>                  
                <td v-if="header === 'Confirm'" :key="index">{{ row.confirm }}</td>                
                <td v-if="header === 'Confirm Month'" :key="index">{{ row.confirm_month }}</td>    
                <td v-if="header === 'Confirm Day'" :key="index">{{ row.confirm_day }}</td>    
                <td v-if="header === 'Confirm Time'" :key="index">{{ row.confirm_time }}</td>                    
              </template>              
          </tr>
    </table>    
    </div>
  </div>
  </template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

export default {

  methods: {
    rowColor(row) {
        const currentDate = new Date();
        const rowDate = new Date(currentDate.getFullYear(), row.due_month - 1, row.due_day, ...row.due_time.split(':'));
        const twentyFourHours = 24 * 60 * 60 * 1000;
        const fortyEightHours = 48 * 60 * 60 * 1000;

        const timeDifference = rowDate - currentDate;

        if (row.send_quantity < row.quantity) {
          if (timeDifference <= twentyFourHours) {
            return "pink";
          } else if (timeDifference <= fortyEightHours) {
            return "yellow";
          }
        } else if (row.send_quantity >= row.quantity && row.confirm !== 'Confirmed') {
          return "pending";
        }
        return "";
      },
  },
  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();
 
    const rqTableName = 'materialrequest';


    //각 설비별 header를 입력해야 됨
    const rqHeaders = ['Month','Day', 'Time', 'Factory', 'Material Code', 'Material Name', 'Quantity',
                           'Unit', 'Due Month', 'Due Day', 'Due Time', 'Detail', 'Send Month', 'Send Day', 'Send Time', "Send Quantity", 
                           "Send Unit", "Send Detail", "Confirm", "Confirm Month", "Confirm Day", "Confirm Time" ]; 
    
    //각 설비별 Data를 받아올 함수 명을 입력해야 됨
    const rqTableData = reactive({ data: [], });      

    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value]; return {sendDateData};};     
    const searchData = async (tableName) => {
        const sendDateData = await sendDate();
        console.log('Requesting data with:', {
            tablename: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endtYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        });
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        };  
      console.log('Requesting data with:', formData);
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => {          
          switch (tableName) {            
            case 'materialrequest': rqTableData.data = res.data; break;            
          }
        });
    };  
 
    const callSearchData = () => {    
      searchData(rqTableName); settingCall();
    };
   
 
    const item = computed(() => {return rqTableData.data.map(d => {return { ...d,};});});    

    const settingData = reactive({ data: [], });        

    const settingCall = async () => {         
        const tableName ='setting';
        const formData = { tableName: tableName };
        axios.get('/api/targetList', { params: formData })
        .then((res) => { settingData.data = res.data; })
        .catch((error) => { console.error("Error download excel data:", error.message); });
    };
   
  
       //설비 추가시 엑셀 다운로드 부분 열어서 수정해야 됨
    const Excel = () => {
      const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
      const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
      if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
      const wb = XLSX.utils.book_new();

      //각 Table 이름을 다 쳐줘야 한다. sheet로 나뉘어서 저장됨 
      //희한하게 header 부분은 본 vue 파일에 있는 각 설비의 headers 부분을 pr,dt,qw에 똑같이 넣으면 잘 나온다.         
      const request_wsPr = XLSX.utils.json_to_sheet(rqTableData.data, { header: rqHeaders.value });          

      //각 sheet 이름을 다 쳐줘야 한다
      XLSX.utils.book_append_sheet(wb, request_wsPr, "request Data");          

      // Write the workbook and create a Blob
      const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      // Save the file
      saveAs(blob, "Request Log.xlsx");
    };
    //설비 추가시 엑셀 다운로드 부분 열어서 수정해야 됨
     

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay,
      rqHeaders, rqTableName, item,
      searchData, callSearchData, sendDate, Excel, 
      //설비가 늘어나면 아래 변수들을 다 생성하여 return시켜야 함
    
    };
  },
};
</script>

<style scoped>
   * {
    box-sizing: border-box;
  }

  .container {
    margin-top: 0px;
    padding-top: 10px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .start,
  .end {
    display: flex;
    flex-direction: row;    
    border: 0px;
  }

  .start input,
  .end input {
    margin-right: 5px;
  }

  .separator {
    font-size: 24px;
    margin: 0 10px;
    border: 0px;
  }
  .searchTd {    
    border: 0px;
  }

  input::placeholder {
    color: gray;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100px;
  }

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
 .excelButton {
  padding: 5px 5px;
  background-color: #347003;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}
  .excelButton:hover{
      background-color: #009625;
  }

  button:hover {
    background-color: #2980b9;
  }

  .RequestLog{
    width: 500px;
  }
      .searchBar {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        margin-left: 20px;
      }
      .searchBar > *:not(:last-child) {
        margin-right: 5px;
      }

      .searchBar select, 
      .searchBar input
        {
        width : 100px;  
        margin-right: 5px;
        padding : 5px;
      }

      label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}



td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: rgb(230, 230, 230) ;
  border : 1px solid black;        
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
  font-weight: 600;
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}
.detail-cell {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;  
}

.yellow {
  background-color: yellow;
}

.pink {
  background-color: pink;
}

.pending {
  background-color: rgb(125, 138, 250);
}
  </style>