import axios from 'axios';
import { ref, computed, reactive } from 'vue';

export function setupPlan() { 
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const currentDate = ref(new Date());
  const currentDateAndWeek = computed(() => {
    const weekNumber = getWeekNumber(currentDate.value);
    return `Week ${weekNumber}`;
  });

  const weekDates = computed(() => {
    const weekStart = new Date(currentDate.value);
    weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(weekStart);
      date.setDate(weekStart.getDate() + i);
      dates.push(date);
    }
    return dates;
  });

  const prevWeek = () => {
    currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() - 7));
  };

  const nextWeek = () => {
    currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() + 7));
  };

  function getWeekNumber(date) {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((date - startOfYear) / 86400000) + startOfYear.getDay() + 1) / 7);
    return weekNumber;
  }

  const lineName = [
    "BMW 48V", "BMW 12V", 
    // "FCA 1", "FCA 2", "FCA FLEX PO" , "FCA FLEX NE", "FCA FLEX BE", "FCA FLEX 45", "FCA FLEX 67", "BT6", "TR2 BFA 2P",
    "Ford 1 Front", "Ford 1 Rear", "Ford 2 Front", "Ford 2 Rear", "Ford 3 Front", "Ford 3 Rear", 
    "TR2 SMT", "1st Semi Check", "TR2 Crimping", "TR2 FPCA", "TR2 BFA 3P",
    "TR3 SMT", "2nd Semi Check", "TR3 Crimping", "TR3 FPCA", "EVO 1", "EVO 2", "EVO 3", "EVO Hybrid",
    "Bending 1", "Bending 2", "Bending 3", "EV2020 4060kW Front", "EV2020 4060kW Rear", "EV2020 60kW Front", "EV2020 60kW Rear",
    "EV2020 Packing", "EV2020 FFC Packing"
  ];

  const db = reactive({});  
  const dayNight = ["Day", "Night"];
  lineName.forEach(line => {
    dayNight.forEach(shift => {
      db[`${line} ${shift}`] = [];
    });
  });
  
  const dbCol = ["model", "planquantity", "workorder", "leader", "technician", "operator", "qualityworker", "notice"];

  function createItem(db, itemName) {
    const items = reactive({ mon: { id: null, value: '' }, tue: { id: null, value: '' }, wed: { id: null, value: '' }, thu: { id: null, value: '' }, fri: { id: null, value: '' }, sat: { id: null, value: '' }, sun: { id: null, value: '' } });
    for (const item of db) {
      const dayKey = getDayKey(new Date(item.year, item.month - 1, item.day).getDay());
      if (dayKey) {
        items[dayKey].value = item[itemName];
        items[dayKey].id = item.id;
      }
    }
    return items;
  } 

  function getDayKey(dayIndex) {
    switch (dayIndex) {
      case 1: return 'mon';
      case 2: return 'tue';
      case 3: return 'wed';
      case 4: return 'thu';
      case 5: return 'fri';
      case 6: return 'sat';
      case 0: return 'sun';
      default: return null;
    }
  }  

  async function searchData(tableName) {
    const startWeek = weekDates.value[0];
    const endWeek = weekDates.value[weekDates.value.length - 1];
    const formData = {
      tableName: tableName,
      startYear: startWeek.getFullYear(),
      startMonth: startWeek.getMonth() + 1,
      startDay: startWeek.getDate(),
      endYear: endWeek.getFullYear(),
      endMonth: endWeek.getMonth() + 1,
      endDay: endWeek.getDate()
    };

    const res = await axios.get('/api/searchPeriod', { params: formData });
    const groupedData = res.data.reduce((acc, item) => {
      const key = `${item.line} ${item.shift}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    Object.keys(db).forEach(key => {
      db[key] = groupedData[key] ? groupedData[key].sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        if (a.month !== b.month) return a.month - b.month;
        return a.day - b.day;
      }) : [];
    });
  }

  const item = computed(() => {
    const result = []; Object.keys(db).forEach((key) => { result.push(...db[key]); });
    return result;
  });

  return {
    days,
    currentDateAndWeek,
    weekDates,
    prevWeek,
    nextWeek,
    db,   
    item,
    createItem,
    searchData,
    lineName,
    dayNight,
    dbCol    
  };
}
