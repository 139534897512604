export  const columnMapping = {
    year: 'year', month: 'month', day: 'day', line: 'line', time: 'time', 
    station: 'station', itemvalues: 'itemvalues', judgement: 'judgement', technician: 'technician',
    cycle: 'cycle', cycledate: 'cycledate', holiday: 'holiday',  remark: 'remark', 
};

export const sliceNumbers = {
    tr3smt: { modal_1: [0, 3], modal_2: [3, 6], modal_3: [6, 9], modal_4: [9, 12], modal_5: [12, 15], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    tr3crimping: { modal_1: [0, 4], modal_2: [4, 8], modal_3: [8, 12], modal_4: [12, 16], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    tr3fpca: { modal_1: [0, 3], modal_2: [3, 6], modal_3: [6, 9], modal_4: [9, 12], modal_5: [12, 15], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    evo1: { modal_1: [0, 5], modal_2: [5, 10], modal_3: [10, 15], modal_4: [15, 20], modal_5: [20, 25], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    evo2: { modal_1: [0, 5], modal_2: [5, 10], modal_3: [10, 15], modal_4: [15, 20], modal_5: [20, 25], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    evo3: { modal_1: [0, 5], modal_2: [5, 10], modal_3: [10, 15], modal_4: [15, 20], modal_5: [20, 25], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    evohybrid: { modal_1: [0, 4], modal_2: [4, 8], modal_3: [8, 12], modal_4: [12, 16], modal_5: [16, 19], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    ev202060kw: { modal_1: [0, 3], modal_2: [3, 7], modal_3: [7, 12], modal_4: [12, 17], modal_5: [17, 22], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    ev20204060kw: { modal_1: [0, 3], modal_2: [3, 7], modal_3: [7, 12], modal_4: [12, 17], modal_5: [17, 22], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},

    tr2smt: { modal_1: [0, 3], modal_2: [3, 6], modal_3: [6, 9], modal_4: [9, 12], modal_5: [12, 15], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    tr2crimping: { modal_1: [0, 4], modal_2: [4, 8], modal_3: [8, 12], modal_4: [12, 16], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    tr2fpca: { modal_1: [0, 3], modal_2: [3, 6], modal_3: [6, 9], modal_4: [9, 12], modal_5: [12, 15], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    bmw12v: { modal_1: [0, 3], modal_2: [3, 6], modal_3: [6, 9], modal_4: [9, 12], modal_5: [12, 14], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    bmw48v: { modal_1: [0, 4], modal_2: [4, 8], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},    
    fca2: { modal_1: [0, 2], modal_2: [2, 5], modal_3: [5, 7], modal_4: [7, 12], modal_5: [12, 15], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    ford2front: { modal_1: [0, 3], modal_2: [3, 6], modal_3: [6, 10], modal_4: [10, 14], modal_5: [14, 17], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    ford2rear: { modal_1: [0, 3], modal_2: [3, 6], modal_3: [6, 10], modal_4: [10, 15], modal_5: [15, 18], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    ford3front: { modal_1: [0, 16], modal_2: [], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    ford3rear: { modal_1: [0, 16], modal_2: [], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},    

    ahufactory2nd: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},    
    boilerfactory2nd: { modal_1: [0, 4], modal_2: [], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    compressorfactory2nd: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [2, 3], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    dustcollectorfactory2nd: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},    
    transformerfactory2nd: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},

    ahufactory1st: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [2, 3], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    boilerfactory1st: { modal_1: [0, 3], modal_2: [], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    compressorfactory1st: { modal_1: [0, 3], modal_2: [], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    dustcollectorfactory1st: { modal_1: [0, 4], modal_2: [], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
    transformerfactory1st: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [],},
};