import { reactive, ref, computed, watch, defineAsyncComponent, nextTick, } from 'vue';
import axios from "axios";
import { sliceNumbers } from '../lineName_check';

export function useForm(props) {       
    const modalSlices = ref([]);  
    const startValues = ref([]); 
    const filters = reactive({ cycle: "All", result: "All" }); 

    const updateModalSlices = () => {
        const lineKey = props.lineName.toLowerCase().replace(/\s+/g, '');
        modalSlices.value = Object.values(sliceNumbers[lineKey]);
        startValues.value = Array(modalSlices.value.length).fill(0);  
    };

    updateModalSlices();

    watch(() => props.lineName, () => { updateModalSlices(); });         

    const showModals = reactive(Array(modalSlices.value.length).fill(false));     

    const showModal = (index) => { showModals[index] = true; startValues.value[index] = 1; };
    const closeModal = (index) => { showModals[index] = false; callSearchData(); startValues.value[index] = 0;  };
    
    const headers = ['Date', 'Station Name', 'Technician', 'Time', 'Part Name', 'Item Name', 'Standard', 'Value', 'Unit', 'Result', 'Cycle', 'Cycledate', 'Remark' ];        
    const searchDate = ref({ startYear: '', startMonth: '', startDay: '', endYear: '', endMonth: '', endDay: '',});

    const sendDate = async () => {
        const sendDateData = [searchDate.value.startYear, searchDate.value.startMonth, searchDate.value.startDay, searchDate.value.endYear, searchDate.value.endMonth, searchDate.value.endDay];
        return {sendDateData};             
    };     

    const checkTableName = "maintenancechecksheet"; const checkTableData = reactive({ data: [], });     
    const targetTableName = "maintenancechecktarget"; const targetList = reactive({ data: [], })     
    
    const searchData = async (tableName) => {
        const sendDateData = await sendDate();
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
            line: props.lineName
        };  

        axios.get('/api/searchPeriod', { params: {...formData},})
        .then((res) => {            
            const filteredData = filterLatestData(res.data);
            checkTableData.data = filteredData;         
        });
    };
    
    const filterLatestData = (data) => {
        const dataMap = new Map();
        data.forEach(item => {
        const key = `${item.year}-${item.month}-${item.day}-${item.line}-${item.station}`;
        dataMap.set(key, item); 
        });
        return Array.from(dataMap.values()); 
    };

    const targetSearchData = async () => {   
        const formData = { tableName: targetTableName, line: props.lineName };        
        axios.get('/api/targetList', { params: formData })
        .then((res) => { 
          targetList.data = res.data;             
        })             
    };

    const callSearchData = async () => { 
        if ( !searchDate.value.startYear || !searchDate.value.startMonth || !searchDate.value.startDay) { return; }
        await searchData(checkTableName); 
        await targetSearchData();
    };  

    const combinedCheckSheetData = computed(() => {
        const combinedData = checkTableData.data.map(sheet => {
            const values = sheet.itemvalues.split(';');   
            const results = sheet.judgement.split(';');
            const cycle = sheet.cycle.split(';');
            const cycleDates = sheet.cycledate.split(';');
            const remarks = sheet.remark.split(';');         
            return values.map((value, index) => {
                const target = targetList.data.find(target => 
                target.line === sheet.line && target.station === sheet.station && target.station_row === index + 1);
    
                if (!target) { return null; }
    
                return {
                    ...sheet,
                    id: `${sheet.id}-${index}`, // 고유 key를 생성
                    station: sheet.station,
                    technician: sheet.technician,
                    time: sheet.time,
                    part: target.part,
                    itemName: target.item,
                    standard: target.standvalue || `${target.lowerlimit}~${target.upperlimit}`,
                    value,
                    unit: target.unit,
                    result :results[index],
                    cycle: cycle[index],
                    cycledate: cycleDates[index],
                    remark: remarks[index] || ''         
                };
                
            }).filter(item => item);
        }).flat();        
        return combinedData;
    });

    const filteredCheckSheetData = computed(() => {
        const filtered = combinedCheckSheetData.value.filter(item => {
            const cycleMatch = filters.cycle === 'All' || item.cycle === filters.cycle;
            const resultMatch = filters.result === 'All' || item.result === filters.result;
            return cycleMatch && resultMatch;
        });
        return filtered;
    });     

    const filteredModalSlices = computed(() => {
        const filtered = modalSlices.value.filter(slice => slice.length > 0);        
        return filtered;
    });

    const getModalComponent = () => {
      return defineAsyncComponent(() => import(`../checkMain/checkSheetModal.vue`));
    };   

    const handleModalButtonClick = async (index) => { showModal(index); await nextTick(); };

    return { callSearchData, sendDate, searchData, searchDate, checkTableName, targetSearchData, 
            headers, combinedCheckSheetData, showModal, closeModal, filteredModalSlices, showModals, getModalComponent,
            handleModalButtonClick, startValues, filters, filteredCheckSheetData
    };        
}