<template>
  <div class="checkTarget">      
    <hr>      
    <div class="horizontal-form">                               
      <button class="addButton" @click="showModal = true">+ Add</button>   
      <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
      <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
      <span class="fileName">{{ fileName }}</span>   
      <button class="excelUpload" @click="upExcel('assetclass')">Upload</button>
      <button class="excelDownload" @click="downExcel">Down Load</button>               
    </div> 
    <div class="table-container">      
      <table class="targetTable">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table class="targetTable"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line_name">          
          <td @click="editCell(targetTableName, row.id, 'classcode1', $event)">{{ row.classcode1 }}</td>
          <td @click="editCell(targetTableName, row.id, 'classdescription1', $event)">{{ row.classdescription1 }}</td>
          <td @click="editCell(targetTableName, row.id, 'classcode2', $event)">{{ row.classcode2 }}</td>
          <td @click="editCell(targetTableName, row.id, 'classdescription2', $event)">{{ row.classdescription2 }}</td>
          <td @click="editCell(targetTableName, row.id, 'classcode3', $event)">{{ row.classcode3 }}</td>
          <td @click="editCell(targetTableName, row.id, 'classdescription3', $event)">{{ row.classdescription3 }}</td>
          <td @click="editCell(targetTableName, row.id, 'remark', $event)">{{ row.remark }}</td>
          <td @click="editCell(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
          <td><button :class="['delButton', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(targetTableName, row.id)">DEL</button></td>        
      </tr>
    </tbody>     
    </table> 
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
</template>

<script>
import { reactive, computed, ref, onMounted } from 'vue';
import Modal from "./assetClassModal.vue";
import axios from "axios";
import { onFileChange, uploadExcel, deleteRow, downloadExcel, editCellValue, } from "../../utils.js"; 

export default {         
    components: { Modal, },    
    data() { return { excelFile: null, fileName: "", };},
    methods: {    
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.targetItem, "selectOption.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData);},     
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
           editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      },
      canDelClick() {
        const currentUser = this.$route.meta.currentUser;        
        if (currentUser) {
            return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;
        } else { return false;}
      },
    },
    setup() {   
        const targetTableName = "assetclass";            
        const target_headers = [ 'Class 1 Code', 'Class 1 Description', 'Class 2 Code', 'Class 2 Description', 'Class 3 Code', 'Class 3 Description', 'Remark', 'ROW NO', 'DEL'];
        const formInputs = reactive({ line: "" });       
        const targetList = reactive({ data: [], })
        const showModal = ref(false); 

        const searchData = async () => {      

          const formData = {
            tableName: targetTableName,
            line: formInputs.line,
          };

          axios.get('/api/targetList', { params: formData })
            .then((res) => {              
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const targetItem = computed(() => { return targetList.data.map(d => { return { ...d, };});});          
        
        const callSearchData = () => { searchData(targetTableName); };   

        onMounted(() => { searchData(); }); 

        return { targetItem, targetList, target_headers, targetTableName, showModal, callSearchData, formInputs };        
    }
  }
</script>

<style scoped>    
  .horizontal-form { margin: 10px 20px; display: flex;  align-items: center; justify-content: flex-end; margin-right: 200px;}   
  
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; margin-bottom: 10px; }     

  .table-container {  overflow-x: hidden;  position: relative; }
  
  .table-body { overflow: hidden; width: 100%; max-height: 350px;  overflow-y: auto; }  

  .targetTable { width: 100%; max-width: 100%; margin-bottom: 1rem;  border-collapse: collapse;  table-layout: fixed; }
  
  .targetTable td { padding: 0.75rem;  vertical-align: top; border-top: 1px solid #dee2e6; text-align: center; }

  .targetTable th {
    position: sticky; top: 0; z-index: 100; background-color: #f8f9fa; text-transform: uppercase;
    letter-spacing: 1px; text-align: center;
    font-size: 0.9rem; font-weight: 600; border-bottom: 2px solid #dee2e6; border-top: 1px solid #dee2e6;    
    padding: 0.75rem;  vertical-align: top; 
  }

  .addButton {
      padding: 5px 10px; background-color: #3498db; color: #fff; border: none; border-radius: 4px;
      font-size: 14px; font-weight: 600; margin-right: 30px; cursor: pointer; transition: background-color 0.3s;
  }

  .custom-file-upload {
      display: inline-block;  padding: 4px 12px;  cursor: pointer; padding: 5px 5px;
      background-color: #c49105;  color: #ffffff; margin-left: 100px;
      border-radius: 4px; font-size: 14px; font-weight: 600;  transition: background-color 0.3s;
  }
  .custom-file-upload:hover { background-color: #f3a359; }

  .excelUpload {
      background-color: #3498db;  color: white;
      border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
      padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px;     
  }
  .excelUpload:hover { background-color: #1e5173; }

  .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
    }
  .excelDownload :hover { background-color: #009625; }  
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
  }

  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }  
  
</style>