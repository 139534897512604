<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Document Report</h>        
      <form @submit.prevent="sendDate()" class="searchBar">
        <div class="horizontal-form">        
          <div class="start">
            <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
            <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
            <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
          </div>
          <div class="separator">~</div>
          <div class="end">
            <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
            <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
            <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
          </div>                  
          <div class="Team">
              <select id="team" v-model="team" placeholder="Team">
                <option>Production 1st</option><option>Production 2nd</option><option>Planning</option><option>Quality</option>
                <option>Sales</option><option>Purchase</option><option>HR/HSE/GA</option><option></option>
              </select>
          </div>  
          <div class="incharge">
              <input type="text" id="writer" v-model="writer" placeholder="Writer" />                        
          </div>
          <div class="incharge">
              <input type="text" id="title" v-model="title" placeholder="Title" />                        
          </div>       
          <div class="searchTd">
              <button type="submit" class="searchButton" @click="callSearchData">Search</button>                      
          </div>   
          <div class="searchTd">
              <button class="mobutton" @click="showModal = true">Make Report</button>                    
          </div>      
          <div class="searchTd">
              <button class="mobutton" @click="showPurchaseModal = true">Make Purchase Report</button>                    
          </div>                   
        </div>      
      </form>
    </div>      
    <hr>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>                
        </tr>    
        <tr v-for="row in item" :key="row.id" :class="row.rowClass">   
          <td>{{ row.date }}</td>       
          <td>{{ row.team }}</td>
          <td>{{ row.writer }}</td>          
          <td>{{ row.title }}</td>                    
          <td :class="{ approved: row.progress === 'Approved' }">{{ row.progress }}</td>          
          <td><button type="submit" @click="openViewModal(row)" class="viewButton">View</button></td>          
          <td class="fileaddress-cell">{{  getFileName(row.filepath) }}<br>
            <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
          </td>   
          <td>
            <template v-if="row.sign1 && row.sign1 !== '0'">
              <template v-if="row.signed1 === 'Signed'"> {{ getSignText(row.sign1) }}<br>{{row.signed1}}<br>
                <button class="cancelButton" :class="{ disabledButton: !signClick(row, 'sign1') }"
                        :disabled="!signClick(row, 'sign1')" v-if="getSignText(row.sign1)" @click="handleCancelSign(row, 'sign1')">
                        Cancel
                </button>  
              </template>
              <template v-else>            
                <button class="signButton" :class="{ disabledButton: !signClick(row, 'sign1') }"
                        :disabled="!signClick(row, 'sign1')" v-if="getSignText(row.sign1)" @click="handleSign(row, 'sign1')">
                  {{ getSignText(row.sign1) }}
                </button>
              </template>
            </template>
          </td>
          <td>
            <template v-if="row.sign2 && row.sign2 !== '0'">
              <template v-if="row.signed2 === 'Signed'"> {{ getSignText(row.sign2) }}<br>{{row.signed2}}<br>
                <button class="cancelButton" :class="{ disabledButton: !signClick(row, 'sign2') }"
                        :disabled="!signClick(row, 'sign2')" v-if="getSignText(row.sign2)" @click="handleCancelSign(row, 'sign2')">
                        Cancel
                </button>  
              </template>
              <template v-else>            
                <button class="signButton" :class="{ disabledButton: !signClick(row, 'sign2') }"
                        :disabled="!signClick(row, 'sign2')" v-if="getSignText(row.sign2)" @click="handleSign(row, 'sign2')">
                  {{ getSignText(row.sign2) }}
                </button>
              </template>
            </template>
          </td>
          <td>
            <template v-if="row.sign3 && row.sign3 !== '0'">
              <template v-if="row.signed3 === 'Signed'"> {{ getSignText(row.sign3) }}<br>{{row.signed3}}<br>
                <button class="cancelButton" :class="{ disabledButton: !signClick(row, 'sign3') }"
                        :disabled="!signClick(row, 'sign3')" v-if="getSignText(row.sign3)" @click="handleCancelSign(row, 'sign3')">
                        Cancel
                </button>  
              </template>
              <template v-else>            
                <button class="signButton" :class="{ disabledButton: !signClick(row, 'sign3') }"
                        :disabled="!signClick(row, 'sign3')" v-if="getSignText(row.sign2)" @click="handleSign(row, 'sign3')">
                  {{ getSignText(row.sign3) }}
                </button>
              </template>
            </template>            
          </td>
          <td>
            <template v-if="row.sign4 && row.sign4 !== '0'">
              <template v-if="row.signed4 === 'Signed'"> {{ getSignText(row.sign4) }}<br>{{row.signed4}}<br>
                <button class="cancelButton" :class="{ disabledButton: !signClick(row, 'sign4') }"
                        :disabled="!signClick(row, 'sign4')" v-if="getSignText(row.sign4)" @click="handleCancelSign(row, 'sign4')">
                        Cancel
                </button>           
              </template>
              <template v-else>            
                <button class="signButton" :class="{ disabledButton: !signClick(row, 'sign4') }"
                        :disabled="!signClick(row, 'sign4')" v-if="getSignText(row.sign4)" @click="handleSign(row, 'sign4')">
                  {{ getSignText(row.sign4) }}
                </button>
              </template>
            </template>
          </td>
          <td>
            <template v-if="row.sign5 && row.sign5 !== '0'">
              <template v-if="row.signed5 === 'Signed'"> {{ getSignText(row.sign5) }}<br>{{row.signed5}}<br>
                <button class="cancelButton" :class="{ disabledButton: !signClick(row, 'sign5') }"
                        :disabled="!signClick(row, 'sign5')" v-if="getSignText(row.sign5)" @click="handleCancelSign(row, 'sign5')">
                        Cancel
                </button>  
              </template>
              <template v-else>            
                <button class="signButton" :class="{ disabledButton: !signClick(row, 'sign5') }"
                        :disabled="!signClick(row, 'sign5')" v-if="getSignText(row.sign5)" @click="handleSign(row, 'sign5')">
                  {{ getSignText(row.sign5) }}
                </button>
              </template>
            </template>
          </td>
          <td class="remark-cell" >{{ row.remark }}</td>
          <td><button type="submit" @click="openSendModal(row)" class="mobutton">Modify</button></td>       
          <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="handleDeleteRow(dbTableName, row.id)">DEL</button></td>               
      </tr>
    </table>    
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>
    <purchaseModal  v-if="showPurchaseModal" @onClose="showPurchaseModal = false; callSearchData();"/> 
    <purchaseModifyModal  v-if="showPurchaseMoModal" :row-id="selectedRowId" @onClose="showPurchaseMoModal = false; callSearchData();"/>                 
    <normalViewModal  v-if="normalViewModal" :row-id="selectedRowId" @onClose="normalViewModal = false; callSearchData();"/>    
    <purchaseViewModal  v-if="purchaseViewModal" :row-id="selectedRowId" @onClose="purchaseViewModal = false; callSearchData();"/>    
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./normalReportModal.vue";
import modifyModal from "./normalReportMoModal.vue";
import purchaseModal from "./purchaseReportModal.vue";
import purchaseModifyModal from "./purchaseReportMoModal.vue";
import normalViewModal from "./normalView.vue";
import purchaseViewModal from "./purchaseView.vue";
import { deleteRow } from '../utils.js'; 

export default {
  data() {
    return {    
      columnMapping: {            
        year: 'year',
        month: 'month',
        day: 'day',
        team: 'team',
        type: 'type',
        writer: 'writer',
        title: 'title',
        contents: 'contents',
        purchaseitem: 'purchaseitem',
        purchasecost: 'purchasecost',
        purchasequantity: 'purchasequantity',
        purchaseunit: 'purchaseunit', 
        deliveryyear: 'deliveryyear',
        deliverymonth: 'deliverymonth',
        deliveryday: 'deliveryday',
        fromcountry: 'fromcountry',
        incotems: 'incotems',
        deliverycost: 'deliverycost',
        deliveryunit: 'deliveryunit',
        payyear: 'payyear',
        paymonth: 'paymonth',
        payday: 'payday',
        paymentcondition: 'paymentcondition',
        sign1: 'sign1',         
        signed1: 'signed1',
        sign2: 'sign2',         
        signed2: 'signed2',
        sign3: 'sign3',         
        signed3: 'signed3',
        sign4: 'sign4',         
        signed4: 'signed4',
        sign5: 'sign5',         
        signed5: 'signed5',
        remark: 'remark',
        file: 'file',
        filepath: 'filepath',
        imageone: 'imageone',
        imageonepath: 'imageonepath',
        imagetwo: 'imagetwo',
        imagetwopath: 'imagetwopath'
      }
    }
  }, 

  methods: {
    //openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; }, 
    openSendModal(row) { 
      this.selectedRowId = row.id;      
        if (row.type === 'normal') { this.showMoModal = true; this.showPurchaseMoModal = false; }
        else if (row.type === 'purchase') { this.showPurchaseMoModal = true; this.showMoModal = false; }
    }, 
    openViewModal(row) {
      this.selectedRowId = row.id;      
      if (row.type === 'normal') { this.normalViewModal = true; this.purchaseViewModal = false; }
      else if (row.type === 'purchase') { this.purchaseViewModal = true; this.normalViewModal = false; }
    },
    async handleSign(row, sign) { await this.signSend(row, sign); this.callSearchData(); },    
    async handleCancelSign(row, sign) { await this.cancelSend(row, sign); this.callSearchData(); },    
    canClick() {        
      const currentUser = this.$route.meta.currentUser;        
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2;}
      else { return false;}
    },
    signClick(row, signField) {
      const currentUser = this.$route.meta.currentUser;
      if (!currentUser) return false;
      const signIndex = parseInt(signField.substring(4));
      if (signIndex > 1) {         
          const prevSignedField = `signed${signIndex - 1}`;
          if (row[prevSignedField] !== "Signed") return false;
      }
      return currentUser.id === 1 || parseInt(row[signField]) === currentUser.id;
    },  
    downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }
        window.location.href = filepath;
    },     
    getFileName(filePath) {
      if (!filePath) return ''; const parts = filePath.split('-');
      if (parts.length > 1) { return parts.slice(1).join('-'); }
      return filePath;
    },
    handleDeleteRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    getSignText(signCode) {
      const signMapping = {
        '34': "Production 1st Manager",
        '35': "Production 2nd Manager",
        '36': "Quality Manager",
        '37': "Sales Manager",
        '38': "Purchase Manager",
        '39': "Planning Manager",
        '40': "Logistic Manager",
        '41': "HR Manager",
        '42': "Factory Manager",
        '43': "CFO",
        '44': "CEO"
      };
      return signMapping[signCode] || null;
    }, 

    async signSend(row, sign) {         
      if (this.signClick(row, sign)) {      

        const signIndex = parseInt(sign.substring(4)); 
        const signedField = `signed${signIndex}`; 
        const nextSignIndex = signIndex + 1; 
        const nextSignField = `sign${nextSignIndex}`; 
        const nextSignedField = `signed${nextSignIndex}`;           

        const formData = {
          id: row.id,
          tableName: 'documentreport',
          columnMapping: this.columnMapping,
          values: {    
            year: row.year,
            month: row.month,
            day: row.day,
            team: row.team,
            type: row.type,
            writer: row.writer,
            title: row.title,
            contents: row.contents,
            purchaseitem: row.purchaseitem,
            purchasecost: row.purchasecost,
            purchasequantity: row.purchasequantity,
            purchaseunit: row.purchaseunit,  
            deliveryyear: row.deliveryyear,
            deliverymonth: row.deliverymonth,
            deliveryday: row.deliveryday,
            fromcountry: row.fromcountry,
            incotems: row.incotems,
            deliverycost: row.deliverycost,
            deliveryunit: row.deliveryunit,
            payyear: row.payyear,
            paymonth: row.paymonth,
            payday: row.payday,
            paymentcondition: row.paymentcondition,               
            sign1: row.sign1,         
            signed1: row.signed1,
            sign2: row.sign2,         
            signed2: row.signed2,
            sign3: row.sign3,         
            signed3: row.signed3,
            sign4: row.sign4,         
            signed4: row.signed4,
            sign5: row.sign5,         
            signed5: row.signed5,        
            remark: row.remark,
            file: row.file,
            filepath: row.filepath,
            imageone: row.imageone,
            imageonepath: row.imageonepath,
            imagetwo: row.imagetwo,
            imagetwopath: row.imagetwopath,
            [signedField]: "Signed"  
          }
        };

        const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

        if (hasUndefinedValue) {
            console.error('Undefined value found in formData.values:', formData.values);
            return;
        }

        formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));       
        try {
            const response = await axios.put(`/api/materialput/${row.id}`, formData);
            console.log('Server response:', response);                 
            if (row[nextSignField] && row[nextSignedField] !== "Signed") { 
              const emailAddresses = {
                '34': 'ijeom@lscns.com', //엄익준
                '35': 'kwonys@lscns.com', //권영수
                '36': 'jihong@lscns.com', //홍주일
                '37': 'hckim2@lscns.com', //김현철
                '38': 'myjung@lscns.com', //정민영
                '39': 'ygsong@lspoland.com', //송영광
                '40': 'slawomir.zawislak@lspoland.com', //스와벡
                '41': 'm.son@lspoland.com', //손명원
                '42': 'dwseo@lscns.com',  //서대원
                '43': 'cmun2@lscns.com',  //문충호
                '44': 'mkjang@lscns.com'  //장문규
              };
              const emailData = {
                to: emailAddresses[row[nextSignField]], 
                subject: `WEB Report: ${row.title}`, 
                body: `The document for sign has arrived: ${row.title}
Web Address: "lsevpweb.ngrok.app"
Menu: Report - Documnet Report` 
              };           
              await axios.post('/api/send-email', emailData);
            } 
        } catch (error) {
            console.error('Error updating data:', error);
        }
      }
    },

    async cancelSend(row, sign) {         
      if (this.signClick(row, sign)) {      

        const signIndex = parseInt(sign.substring(4)); 
        const signedField = `signed${signIndex}`;            

        const formData = {
          id: row.id,
          tableName: 'documentreport',
          columnMapping: this.columnMapping,
          values: {    
            year: row.year,
            month: row.month,
            day: row.day,
            team: row.team,
            type: row.type,
            writer: row.writer,
            title: row.title,
            contents: row.contents,
            purchaseitem: row.purchaseitem,
            purchasecost: row.purchasecost,
            purchasequantity: row.purchasequantity,
            purchaseunit: row.purchaseunit, 
            deliveryyear: row.deliveryyear,
            deliverymonth: row.deliverymonth,
            deliveryday: row.deliveryday,
            fromcountry: row.fromcountry,
            incotems: row.incotems,
            deliverycost: row.deliverycost,
            deliveryunit: row.deliveryunit,
            payyear: row.payyear,
            paymonth: row.paymonth,
            payday: row.payday,
            paymentcondition: row.paymentcondition,                 
            sign1: row.sign1,         
            signed1: row.signed1,
            sign2: row.sign2,         
            signed2: row.signed2,
            sign3: row.sign3,         
            signed3: row.signed3,
            sign4: row.sign4,         
            signed4: row.signed4,
            sign5: row.sign5,         
            signed5: row.signed5,        
            remark: row.remark,
            file: row.file,
            filepath: row.filepath,
            imageone: row.imageone,
            imageonepath: row.imageonepath,
            imagetwo: row.imagetwo,
            imagetwopath: row.imagetwopath,
            [signedField]: "0"  
          }
        };

        const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

        if (hasUndefinedValue) {
            console.error('Undefined value found in formData.values:', formData.values);
            return;
        }

        formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));       
        try {
            const response = await axios.put(`/api/materialput/${row.id}`, formData);
            console.log('Server response:', response);  
        } catch (error) {
            console.error('Error updating data:', error);
        }
      }
    },
  },

  components: { Modal, modifyModal, purchaseModal, purchaseModifyModal, normalViewModal, purchaseViewModal },    

  setup() {   
    const team = ref(); const writer = ref(); const title = ref(); 
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();
    const dbTableName = 'documentreport';
    const showModal = ref(false);
    const showMoModal = ref(false);     
    const showPurchaseModal = ref(false);
    const showPurchaseMoModal = ref(false);     
    const normalViewModal  = ref(false);
    const purchaseViewModal  = ref(false);
    const headers = ['Date', 'Team', 'Writer', 'Title', 'Progress', 'View', 'File',
                      'Sign 1', 'Sign 2', 'Sign 3', 'Sign 4', 'Sign 5', 'Remark', 'Modify', 'DEL'
    ];
    const documentReportData = reactive({ data: [], })    
    
    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, team.value, writer.value, title.value]; return {sendDateData};};         
    
    const searchData = async () => {   
      
      const sendDateData = await sendDate();    

      const formData = {        
          tableName: dbTableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
          team: sendDateData.sendDateData[6],
          writer: sendDateData.sendDateData[7],
          title: sendDateData.sendDateData[8],          
      };  

      axios.get('/api/documentReport', { params: formData })
        .then((res) => {              
          documentReportData.data = res.data; 
        })
        .catch((error) => {
          console.error("Error fetching target data:", error.message);
      });
    };   
    
    const item = computed(() => {
      return documentReportData.data.map(item => {
        const date = `${item.day}.${item.month}.${item.year}`;
        const signFields = ['sign1', 'sign2', 'sign3', 'sign4', 'sign5'];
        const signedFields = ['signed1', 'signed2', 'signed3', 'signed4', 'signed5'];

        // 존재하는 서명이 모두 'Signed'인지 확인
        const allRequiredSigned = signFields.every((signField, index) => {
          const signedField = signedFields[index];
          // 서명 필드에 값이 있을 때만 검사
          return !item[signField] || (item[signField] && item[signedField] === "Signed");
        });

        const progress = allRequiredSigned ? "Approved" : "Wait Approval";
        return { ...item, date, progress };
      });
    });
    
    const callSearchData = () => { searchData(dbTableName); };   

    return { item, headers, dbTableName, callSearchData, sendDate,
             startYear, startMonth, startDay, endYear, endMonth, endDay, team, writer, title, 
             showModal, showMoModal, showPurchaseModal, showPurchaseMoModal, normalViewModal, purchaseViewModal
    };        
  }
}
</script>

<style scoped>
/* 기본 설정 */
.pagetitle { margin-left: 10px;}
.table-container { margin-left: 10px; margin-right: 10px;}
.table {
  width: 100%;  
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 그림자 효과 */
  background-color: #ffffff; /* 밝은 배경색 */
}
.table th {
    padding: 0.75rem;  
    text-align: center; /* Center align text */
    border: 1px solid #808080;
    background-color: #dad8d8; /* 밝은 배경색 */
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }
.table td {
    padding: 0.75rem;
    text-align: center; /* Center align text */
    border: 1px solid #808080;
  }

.table td.content-cell { max-width: 600px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.effect-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.fileaddress-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.remark-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
/* 색상 행 */
.yellowRow { background-color: #ffffcc; }
.redRow { background-color: #ffcccc; }

.mobutton {
    background-color: #4CAF50; color: rgb(255, 255, 255); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}
.viewButton {
    background-color: #6137fa; color: rgb(255, 255, 255); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}
.search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
.start input,.end input { margin-right: 5px; margin-left: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px; }
.searchTd { border: 0px; }
.searchButton {
    background-color: #bebebe; color: rgb(0, 0, 0); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}  
.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 20px; margin-left: 20px; 
}
.fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }
.searchBar select,.searchBar input{ width : 120px; margin-right: 5px; padding : 5px; } 
.signButton { padding: 10px 10px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
              font-size: 12px; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
              margin-right: 5px; cursor: pointer; transition: background-color 0.3s; font-weight: bold; 
}
.cancelButton { padding: 5px 5px; background-color: #d78968; color: #000000; border: none; border-radius: 4px;
              font-size: 10px; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
              margin-right: 5px; cursor: pointer; transition: background-color 0.3s; font-weight: bold; 
}
.delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
}
.disabledButton { background-color: gray; cursor: not-allowed; }
.approved { background-color: rgb(0, 92, 0); color: white; font-weight: bold;}
.horizontal-form { display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>