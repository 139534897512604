<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Q-WALL</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>                                                        
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option v-for="model in models" :key="model">{{ model }}</option>
              </select>               
            </div>              
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>
          </td>
          <td>   
            <div class="form-group">
              <label for="ng">NG:</label>
              <input type="number" id="ng" v-model="formInputs.ng" />
            </div>            
            <div class="form-group">
              <label for="ng_heatstacking">NG HEAT STACKING:</label>
              <input type="number" id="ng_heatstacking" v-model="formInputs.ng_heatstacking"  />
            </div>
            <div class="form-group">
              <label for="ng_busbardamage">NG BUSBAR DAMAGE:</label>
              <input type="number" id="ng_busbardamage" v-model="formInputs.ng_busbardamage" />
            </div>
            <div class="form-group">
              <label for="ng_fpcbdamage">NG FPCB DAMAGE:</label>
              <input type="number" id="ng_fpcbdamage" v-model="formInputs.ng_fpcbdamage"  />
            </div>
            <div class="form-group">
              <label for="ng_weldlineposition">NG WELD LINE POSITION:</label>
              <input type="number" id="ng_weldlineposition" v-model="formInputs.ng_weldlineposition" />
            </div>
            <div class="form-group">
              <label for="ng_weldingng">NG WELDING NG:</label>
              <input type="number" id="ng_weldingng" v-model="formInputs.ng_weldingng" />
            </div>
          </td>
          <td>   
            <div class="form-group">
              <label for="ng_finalslot">NG FINAL SLOT:</label>
              <input type="number" id="ng_finalslot" v-model="formInputs.ng_finalslot"  />
            </div>
            <div class="form-group">
              <label for="ng_framedamage">NG FRAME DAMAGE:</label>
              <input type="number" id="ng_framedamage" v-model="formInputs.ng_framedamage" />
            </div>
            <div class="form-group">
              <label for="ng_eolreal">NG EOL REAL:</label>
              <input type="number" id="ng_eolreal" v-model="formInputs.ng_eolreal" />
            </div>
            <div class="form-group">
              <label for="ng_eol2real">NG EOL 2 REAL:</label>
              <input type="number" id="ng_eol2real" v-model="formInputs.ng_eol2real" />
            </div>
            <div class="form-group">
              <label for="ng_coating">NG COATING:</label>
              <input type="number" id="ng_coating" v-model="formInputs.ng_coating" />
            </div>
            <div class="form-group">
              <label for="ng_coverhook">NG COVER HOOK:</label>
              <input type="number" id="ng_coverhook" v-model="formInputs.ng_coverhook" />
            </div>   
            <div class="form-group">
              <label for="ng_coverdamage">NG COVER DAMAGE:</label>
              <input type="number" id="ng_coverdamage" v-model="formInputs.ng_coverdamage" />
            </div>   
            <div class="form-group">
              <label for="ng_connector">NG CONNECTOR:</label>
              <input type="number" id="ng_connector" v-model="formInputs.ng_connector" />
            </div>         
            <div class="form-group">
              <label for="ng_etc">NG ETC:</label>
              <input type="number" id="ng_etc" v-model="formInputs.ng_etc" />
            </div>    
          </td>
          </tr>
          </table>                    
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>
import { useForm } from '../0_formStyle/modal/form_qw_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../0_formStyle/modal/style_qw_modal.css"></style>