<template>
    <div class="maxpowerdb">            
        <h class="pagetitle">Request Manpower</h>
        <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">               
                <div class="start">
                    <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                    <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                    <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
                </div>
                <div class="separator">~</div>
                <div class="end">
                    <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                    <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                    <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
                </div>
                <div class="searchTd">
                    <button type="submit" @click="callSearchData">Search</button>                        
                </div>
                <div class="searchTd">
                    <button class="button" @click="showModal = true">+ Request</button>                                                                   
                </div>
                <div class="searchTd">
                    <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
                </div>                    
                <div class="searchTd">
                    <button class="confirmButton" :class="{ disabledButton: !canConfirm() }"  type="submit"  @click="confirmAll" :disabled="!canConfirm()">ALL Confirm</button>
                </div>                
            </div>      
        </form>   
        </div>     
        <div class="table">
            <table>
                <tr>
                    <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                </tr>                    
                <tr v-for="row in item" :key="row.id">
                    <td>{{ row.factory }}</td>
                    <td>{{ row.year }}</td>
                    <td>{{ row.month }}</td>
                    <td>{{ row.day }}</td>
                    <td>{{ row.shift }}</td>
                    <td>{{ row.oscompany }}</td>
                    <td>{{ row.employeenumber }}</td>                    
                    <td>{{ row.name }}</td>
                    <td>{{ row.surname }}</td>
                    <td>{{ row.team }}</td>
                    <td>{{ row.line }}</td>
                    <td>{{ row.process }}</td>
                    <td>{{ row.position }}</td>
                    <td>{{ row.start_time }}</td>
                    <td>{{ row.end_time }}</td>
                    <td><button class="sendButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openEndModal(row)">End</button></td>
                    <td>{{ row.worktime }}</td>
                    <td>{{ row.remark }}</td>
                    <td><button class="confirmButton" :class="{ disabledButton: !canConfirm() }"  type="submit"  @click="handleClick(row)" :disabled="!canConfirm()">Confirm</button> </td>                   
                    <td><button class="sendButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>                   
                    <td><button :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>                    
                    <td>{{ row.confirm }}</td>
                </tr>
            </table>                        
        </div>
        <div class="countTable">
            <table>
                <tr><th>shift</th><th>Line</th><th>Process</th><th>Position</th><th>Count</th></tr>
                <tr v-for="row in countData" :key="row.shift + row.line + row.process + row.position">
                    <td>{{ row.shift }}</td><td>{{ row.line }}</td><td>{{ row.process }}</td><td>{{ row.position }}</td><td>{{ row.count }}</td>
                </tr>
            </table>
        </div>
        <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
        <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>             
        <endModal  v-if="showEndModal" :row-id="selectedRowId" @onClose="showEndModal = false; callSearchData();"/>             
    </div>
</template>

<script>
import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import { deleteRow, downloadExcel, setAttendance,} from "../../utils.js"; 

import Modal from "./weekendWorkModal.vue";
import modifyModal from "./weekendWorkModify.vue";
import endModal from "./weekendWorkendTimeModal.vue";

export default {  
    data() {
        return {    
          columnMapping: {            
            factory: "factory",
            year: 'year',
            month: 'month',
            day: 'day',
            shift: "shift",
            oscompany: "oscompany",
            employeenumber: "employeenumber",
            name : "name",
            surname : "surname",   
            team : "team",
            line : "line",
            process : "process",
            position : "position",
            start_time : "start_time",      
            end_time : "end_time",
            worktime : "worktime",
            remark : "remark",
            confirm: "confirm"     
          }
        }
    },    

    methods: {
        openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },
        openEndModal(row) { this.selectedRowId = row.id; this.showEndModal = true; },
        handleClick(row) { this.confirmSend(row); this.callSearchData(); },

        canClick(row) {            
            const currentUser = this.$route.meta.currentUser;            

            if (currentUser) {                
                if (row.confirm === "Confirmed") {
                    return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 14;
                } else {                    
                    return true;
                }
            } else {
                return false;
            }
        },

        canConfirm() {            
            const currentUser = this.$route.meta.currentUser;            
            if (currentUser) {
                return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 14;
            } else {
                return false;
            }
        },

        confirmAll() {
            if (this.canConfirm()) {
                this.item.forEach(row => {
                    this.confirmSend(row);
                });
                this.callSearchData();
            }
        },

        async confirmSend(row) {         
            if (this.canConfirm()) {
            console.log('confirmSend called with row:', row);
                const formData = {
                    id: row.id,
                    tableName: 'attendanceweekend',
                    columnMapping: this.columnMapping,
                    values: {
                        factory: row.factory,
                        year: row.year,
                        month: row.month,
                        day: row.day,
                        shift: row.shift,
                        oscompany : row.oscompany,
                        employeenumber : row.employeenumber,              
                        name : row.name,
                        surname : row.surname,
                        team : row.team,
                        line : row.line,
                        process : row.process,
                        position : row.position,
                        start_time : row.start_time,      
                        end_time : row.end_time,
                        worktime : row.worktime,
                        remark : row.remark,
                        confirm: 'Confirmed',           
                    }
                };

                const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

                if (hasUndefinedValue) {
                    console.error('Undefined value found in formData.values:', formData.values);
                    return;
                }

                formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
                console.log('formData:', formData);
                console.log('Mapping:', this.columnMapping);
                try {
                    const response = await axios.put(`/api/materialput/${row.id}`, formData);
                    console.log('Server response:', response);
                    console.log('Data updated successfully');          
                } catch (error) {
                    console.error('Error updating data:', error);
                }
            }
        },

        downExcel() { downloadExcel(this.item, "Attendance.xlsx"); },
        delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    },

    components: { Modal, modifyModal, endModal },        

    setup() {        
        const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();
        const dbTableName = 'attendanceweekend';
        const showModal = ref(false); 
        const showMoModal = ref(false);
        const showEndModal = ref(false);
        const headers = ['Factory', 'Team', 'Year', "Month", "Day", 'shift', 'OS Company',  'Employee Number',  
                        'First Name', 'Surname', 'Line', 'Process', 'Position', 'Start', 'End', 'End Button', 'Work Time', 'Remark',
                         'Confirm', 'Modify', 'DEL', 'Confirmed'];

        const dbData = reactive({ data: [], });
        
        const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value]; return {sendDateData};};     
        const searchData = async () => {
            const sendDateData = await sendDate();    
            const formData = {        
                tableName: dbTableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],
            };                        
            axios.get('/api/searchPeriod', { params: {...formData },})
                .then((res) => {
                    dbData.data = res.data.sort((a, b) => {
                        if (a.factory < b.factory) return -1; if (a.factory > b.factory) return 1;  
                        if (a.month < b.month) return -1; if (a.month > b.month) return 1;  
                        if (a.day < b.day) return -1; if (a.day > b.day) return 1;  
                        if (a.shift < b.shift) return -1; if (a.shift > b.shift) return 1;                        
                        if (a.line < b.line) return -1; if (a.line > b.line) return 1;
                        if (a.oscompany < b.oscompany) return -1; if (a.oscompany > b.oscompany) return 1;                        
                        if (a.name < b.name) return -1; if (a.name > b.name) return 1;

                        return 0;
                    });
                });

        }      

        const callSearchData = async () => {
            const attendanceEnabled = await setAttendance();                
            if (!attendanceEnabled) { return; }       
            searchData();
        };  

        const item = computed(() => {return dbData.data.map(d => {return { ...d,};});});    

        const countData = computed(() => {
            const counts = {};
            item.value.forEach(row => {
                const key = `${row.shift}|${row.line}|${row.process}|${row.position}`;
                if (counts[key]) {
                    counts[key] += 1;
                } else {
                    counts[key] = 1;
                }
            });

            let results = Object.entries(counts).map(([key, value]) => {
                const [shift, line, process, position] = key.split('|');
                return { shift: Number(shift), line, process, position, count: value };
            });

            results.sort((a, b) => {
                if (a.shift !== b.shift) return a.shift - b.shift;
                if (a.line !== b.line) return a.line.localeCompare(b.line);
                if (a.process !== b.process) return a.process.localeCompare(b.process);
                if (a.position !== b.position) return a.position.localeCompare(b.position);
                return 0;
            });

            return results;
        });  

        return { headers, showModal, item, callSearchData, deleteRow, dbTableName, showMoModal, endModal, showEndModal,
                startYear, startMonth, startDay, endYear, endMonth, endDay, sendDate, countData}        
    }}

</script>

<style scoped>
.request { 
  flex: 1; 
  margin-left: 10px;
  position: relative; /* Add this line */
  transform: scale(1);
  transform-origin: top left;
} 
 .button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;        
    transition: background-color 0.3s;        
  }
  .sendButton {
    padding: 5px 5px;
    background-color: #0d6d00;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 11px;   
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;        
    transition: background-color 0.3s;        
  }
  .confirmButton {
    padding: 5px 5px;
    background-color: #1e00ca;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 11px;   
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;        
    transition: background-color 0.3s;        
  }

  .disabledButton {
        background-color: gray;
        cursor: not-allowed;
      }

  label {
font-size: 14px;
font-weight: 600;
margin-right: 8px;
}


td {
border : 1px solid black;
text-align : center;
padding-left : 10px;
padding-right : 10px;
font-size : 12px; 
}

th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
    font-weight: 600;
  }
.table {
display: flex;
flex-direction: column;
align-items: stretch;
padding-left : 20px;
padding-right : 20px;
}

table {
width: 80%;
}
.detail-cell {
width: 200px;
min-width: 200px;
max-width: 200px;
white-space: pre-wrap;
word-wrap: break-word;
}

.pagetitle {
text-align: left;
font-size: 1.0rem;
font-weight: bold;
margin-left : 15px;  
}

.custom-file-upload {
display: inline-block;
padding: 6px 12px;
cursor: pointer;
background-color: #347003;
color: #ffffff;
border-radius: 4px;
font-size: 14px;
transition: background-color 0.3s;  
margin-right : 10px;
margin-left : 10px;
}

.custom-file-upload:hover {
background-color: #009625;
}

.excelFile {
display: flex;
align-items: center;  
justify-content: flex-end;
width: 600px;
padding: 0 10px;  
margin-bottom: 10px;
}

.right-align {
margin-left: auto;
}

button {
padding: 5px 5px;
background-color: #3498db;
color: #fff;
border: none;
border-radius: 4px;
font-size: 14px;
font-weight: 600;
margin-right: 3px;
cursor: pointer;
transition: background-color 0.3s;
}

.searchButton{
padding: 5px 5px;
background-color: #9c9c9c;
color: #000000;
border: none;
border-radius: 4px;
font-size: 14px;
font-weight: 600;
margin-right: 3px;
width: 100px;
cursor: pointer;
transition: background-color 0.3s;
}

.yellow {
background-color: yellow;
}

.pink {
background-color: pink;
}

.pending {
background-color: rgb(125, 138, 250);
}

.search {
position: relative;
background-color: #ffffff; /* Set a background color for better visibility */  
padding: 0px; /* Add some padding for better appearance */
height: auto;
}
.start,
.end {
  display: flex;
  flex-direction: row;    
  border: 0px;
}

.start input,
.end input {
  margin-right: 5px;
}

.separator {
  font-size: 24px;
  margin: 0 10px;
  border: 0px;
}
.searchTd {    
  border: 0px;
}

input::placeholder {
    color: gray;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100px;
  }

  .countTable {margin-left:20px; width: 50%; }
  .horizontal-form { margin-top: 10px; margin-bottom: 10px; margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   

</style>