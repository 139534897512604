import { reactive, ref } from 'vue';  
import axios from 'axios';   
import { getCurrentDate, makeFormData, getColumnMapping, } from "../../../utils.js"; 
  
export function useForm(props, emit) {   
    const dbTableName = "repeatdata"          
    const saveStatus = ref(''); 
    const isProcessing = ref(false);  
    
    const formInputs = reactive({ line_name: props.lineName, shift : "", supervisor : "", leader : "", technician : "", shiftname : "", operatorqty : "", workorder : "", });   
    const searchDate = reactive({ ...getCurrentDate(), }); 
    
    const submitForm = async () => {
      if (!searchDate.year || !searchDate.month || !searchDate.day) {
        alert("Please fill in all the fields: year, month, and day.");
        return;
      }

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';  

      const columnMapping = getColumnMapping(searchDate, formInputs);  

      const formData = makeFormData(searchDate, formInputs, dbTableName, columnMapping);  

      try {
        await axios.post('/api/post', formData);
        saveStatus.value = 'Completed'; 
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }         
      closeModal();
    };

    const closeModal = () => { emit("onClose"); };
  
    return { searchDate, dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing,  };
}