<template>
  <div class="controlPlan">        
    <form @submit.prevent="sendDate()">
      <div class="horizontal-form">                
        <div><b>Line</b></div>
        <div>            
          <select v-model="line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
          </select>                  
        </div>
        <div><b>Rev.No</b></div>
        <div>            
          <select v-model="revision">
            <option v-for="revision in uniqueRevision" :key="revision">{{ revision }}</option>
          </select>                  
        </div>
        <div>
            <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
        </div>
        <div>                        
            <button class="addButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showModal = true">+ Add PFMEA</button>
        </div>
        <div>                        
            <button class="addButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showLabelModal = true">+ Add PFMEA Label</button>
        </div>
        <div>
            <button class="historyButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showHistoryModal = true">History</button>
        </div>            
        <div>
            <button class="printButton" @click.prevent="printTable()">Print</button>    
        </div>   

      </div>      
    </form>
    <body>
    <div class="table-container" id="printArea">
      <table id="controlPlanTable">   
        <thead> 
        <tr>
          <td class="lsevpWidth1"></td>
          <td class="lsevpWidth2"></td>
          <td class="lsevpWidth3"></td>
          <td class="titleWidth1"></td>
          <td class="titleWidth2"></td>
          <td class="titleWidth3"></td>
          <td class="titleWidth4"></td>
          <td class="titleWidth5"></td>
          <td class="titleWidth6"></td>
          <td class="titleWidth7"></td>
          <td class="securityWidth1"></td>
          <td class="securityWidth2"></td>
          <td class="securityWidth3"></td>
          <td class="securityWidth4"></td>
          <td class="securityWidth5"></td>
          <td class="securityWidth6"></td>
          <td class="securityWidth7"></td>
          <td class="securityWidth8"></td>
          <td class="securityWidth9"></td>
          <td class="securityWidth10"></td>    
          <td class="no-printWidth"></td>
          <td class="no-printWidth"></td>  
        </tr>
        <tr>
          <td rowspan="2" colspan="3" class="lsevp">LSEV Poland</td>          
          <td rowspan="2" colspan="7"><b class="title">Process Failure Mode Effects Analysis</b><br>{{ displaySopStatus }}</td>

          <td rowspan="2" colspan="4" class="docNo">Doc. No:<br>{{item.length > 0 ? item[0].doc_no : ''}}</td>
          <td>Revision Date</td>    
          <td colspan="5">{{item.length > 0 ? item[0].revision_date : ''}}</td>                
          <td colspan="2" rowspan="3" class="no-print1"></td>          
        </tr>
          <td>Revision</td>
          <td colspan="5">{{item.length > 0 ? item[0].revision : ''}}</td>                  
        <tr>
          <td colspan="3" class="alignLeft">Part Number/Revision :<br>{{item.length > 0 ? item[0].partnumber : ''}}</td>
          <td colspan="7" class="alignLeft">Project Name :<br>{{item.length > 0 ? item[0].projectname : ''}}</td>
          <td colspan="4" class="alignLeft">Technical Approval of Customer/Date<br>(if required)<br>{{item.length > 0 ? item[0].technical : ''}}</td>
          <td colspan="6" class="alignLeft">Approver<br>{{item.length > 0 ? item[0].approver : ''}}</td>                    
        </tr>
        <tr>
          <td colspan="5" class="alignLeft">Part Name :<br>{{item.length > 0 ? item[0].partname : ''}}</td>
          <td colspan="5" class="alignLeft">MDT Members :<br>{{item.length > 0 ? item[0].mdt : ''}}</td>
          <td colspan="4" class="alignLeft">Quality Approval of Customer/Date<br>(if required)<br>{{item.length > 0 ? item[0].quality : ''}}</td>
          <td colspan="6" class="alignLeft">Review<br>{{item.length > 0 ? item[0].review : ''}}</td>          
          <td colspan="2" class="no-printLableModify">Label Modify</td>               
        </tr>        
        <tr>
          <td colspan="4" class="alignLeft">Supplier/Factory<br>{{item.length > 0 ? item[0].supplier : ''}}</td>
          <td colspan="1" class="alignLeft">Supplier Code<br>{{item.length > 0 ? item[0].suppliercode : ''}}</td>
          <td colspan="5" class="alignLeft">Supplier/Approved date<br>{{item.length > 0 ? item[0].supplierdate : ''}}</td>  
          <td colspan="4" class="alignLeft">Others<br>(if required)<br>{{item.length > 0 ? item[0].others : ''}}</td>
          <td colspan="6" class="alignLeft">Prepared by<br>{{item.length > 0 ? item[0].prepared : ''}}</td>                  
          <td colspan="2" class="no-print"><button type="submit" @click="openSendLabelModal(item[0])" class="moButton">Label Modify</button></td>          
        </tr>      
        <tr>
          <th rowspan="2">Process No.</th>
          <th rowspan="2">Function</th>
          <th rowspan="2">Requirements</th>          
          <th rowspan="2">Potential Failure<br>Mode</th>
          <th rowspan="2">Potential Effects of<br>Failure</th>
          <th rowspan="2">S<br>E<br>V</th>
          <th rowspan="2">C<br>L<br>A<br>S<br>S</th>
          <th rowspan="2">Potential<br>Caues/Mechanisms<br>of Failure</th>
          <th colspan="4">Current Process Control</th>
          <th rowspan="2">R<br>P<br>N</th>
          <th rowspan="2">Recommended<br>Actions</th>
          <th rowspan="2">Responsibility<br>and Target<br>Completion<br>Date</th>
          <th colspan="5">Action Results</th>
          <th rowspan="2" class="no-print">Modify</th>
          <th rowspan="2" class="no-print">Row_no</th>
        </tr>
        <tr>
          <th>O<br>C<br>C</th>
          <th>Prevention</th>
          <th>Dectection</th>
          <th>D<br>E<br>T</th>
          <th>Actions<br>Taken</th>
          <th>S<br>E<br>V</th>
          <th>O<br>C<br>C</th>
          <th>D<br>E<br>T</th>
          <th>R<br>P<br>N</th>          
        </tr>       
        </thead>
        <tbody>        
        <template v-for="group in groupedData" :key="group.process">
          <tr v-for="(item, index) in group.data" :key="item.id">
            <td v-if="index === 0" :rowspan="group.data.length">{{ group.process }}</td>
            <td v-if="index === 0" :rowspan="group.data.length">{{ group.process_name }}</td>            
            <td v-if="shouldShowRequirementsCell(group.data, index)" :rowspan="getRequirementsRowspan(group.data, index)" class="alignLeft">{{ item.requirements }}</td>
            <td v-if="shouldShowPotentialFailureCell(group.data, index)" :rowspan="getPotentialFailureRowspan(group.data, index)" class="alignLeft">{{ item.potential_failure }}</td>
            <td v-if="shouldShowPotentialEffectsCell(group.data, index)" :rowspan="getPotentialEffectsRowspan(group.data, index)" class="alignLeft">{{ item.potential_effects }}</td>
                        
            <td>{{ item.sev1 }}</td>
            <td class="alignCenter">{{ item.class1 === 'O' ? '◈' : item.class1 }}</td>
            <td class="alignLeft">{{ item.potential_caues }}</td>
            <td>{{ item.occ1 }}</td>
            <td class="alignLeft">{{ item.prevention }}</td>
            <td class="alignLeft">{{ item.detection }}</td>
            <td>{{ item.det1 }}</td>            
             <td :class="getRpnClass(item.rpn1)">{{ item.rpn1 }}</td>
            <td class="alignLeft">{{ item.recommended }}</td>
            <td class="alignLeft">{{ item.responsibility }}</td>
            <td class="alignLeft">{{ item.actions }}</td>
            <td>{{ item.sev2 }}</td>
            <td>{{ item.occ2 }}</td>
            <td>{{ item.det2 }}</td>
            <td :class="getRpnClass(item.rpn2)">{{ item.rpn2 }}</td>
            <td class="no-print"><button type="submit" @click="openSendModal(item)" class="moButton">Modify</button></td>            
            <td class="no-print" @click="editCell(dbTableName, item.id, 'row_no', $event)">{{ item.row_no }}</td>            
            
          </tr>
        </template>        
        </tbody>
      </table>
    </div> 
    </body>   
    <Modal v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    <ModalLabel v-if="showLabelModal" @onClose="showLabelModal = false; callSearchData();"/> 
    <ModalMoLabel v-if="showLabelMoModal" :row-id="selectedRowId" @onClose="showLabelMoModal = false; callSearchData();"/> 
    <historyModal v-if="showHistoryModal" :line="line" @onClose="showHistoryModal = false; "/> 
  </div>  
</template>

<script>
import { ref, reactive, computed, watch  } from 'vue';
import axios from 'axios';
import Modal from './pFmeaModal.vue';
import modifyModal from './pFmeaMoModal.vue';
import ModalLabel from './pFmeaLabelModal.vue';
import ModalMoLabel from './pFmeaLabelMoModal.vue';
import historyModal from './pFmeaHistoryModal.vue';
import { deleteRow, fetchData, editCellValue} from "../utils.js"; 

export default {       
  methods: {
    openSendModal(item) { this.selectedRowId = item.id; this.showMoModal = true; },        
    openSendLabelModal(item) { this.selectedRowId = item.id; this.showLabelMoModal = true; },      
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    canClick() {          
      const currentUser = this.$route.meta.currentUser;          
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 32 }
      else { return false;}
    },
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    }, 
    
    printTable() {
      const printContents = document.getElementById("printArea").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;                 
      window.print();
      document.body.innerHTML = originalContents;    
      window.location.reload();   
    }, 

    shouldShowRequirementsCell(data, index) { if (index === 0) return true; return data[index].requirements !== data[index - 1].requirements;},
    getRequirementsRowspan(data, index) { let rowspan = 1; 
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].requirements === data[index].requirements) { rowspan++; } else { break;  }
      }      
      return rowspan;
    },
    shouldShowPotentialFailureCell(data, index) { if (index === 0) return true; return data[index].potential_failure !== data[index - 1].potential_failure; },
    getPotentialFailureRowspan(data, index) { let rowspan = 1;
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].potential_failure === data[index].potential_failure) { rowspan++; } else { break; }
      }      
      return rowspan;
    },
    shouldShowPotentialEffectsCell(data, index) { if (index === 0) return true; return data[index].potential_effects !== data[index - 1].potential_effects; },
    getPotentialEffectsRowspan(data, index) { let rowspan = 1;
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].potential_effects === data[index].potential_effects) { rowspan++; } else { break; }
      }      
      return rowspan;
    },
    getRpnClass(value) {
      if (value === 0 || value === "" || value === null) { return ''; }
      else if (value >= 100) { return 'rpn-high'; }
      else if (value >= 40) { return 'rpn-medium'; }
      else { return 'rpn-low'; }
    }
  },

  components: { Modal, modifyModal, ModalLabel, ModalMoLabel, historyModal },     

  setup() {           
      const line = ref();
      const revision = ref();
      const dbTableName = 'pfmea';
      const labelTableName = 'pfmea_label';
      const showModal = ref(false);        
      const showMoModal = ref(false);      
      const showLabelModal = ref(false);   
      const showLabelMoModal = ref(false);                   
      const showHistoryModal = ref(false);                   
      const controlPlanData = reactive({ data: [], })  
      const labelData = reactive({ data: [], })  
      const uniqueLines = ref([]);     
      const uniqueRevision = ref([]);             

      const sendDate = async () => {const sendDateData = [line.value, revision.value,]; return { sendDateData };};    

      const searchData = async () => {            
        const sendDateData = await sendDate();   

        if (!sendDateData.sendDateData[0] || !sendDateData.sendDateData[1]) { alert('Need select Line And Rev.No'); return;}       
        const formData = {        
              tableName: dbTableName,                
              line: sendDateData.sendDateData[0],       
              revision: sendDateData.sendDateData[1],  
        };  

        axios.get('/api/controlPlan', { params: formData, })                      
              .then((res) => {
              controlPlanData.data = res.data.sort((a, b) => a.row_no - b.row_no);
              });          
      };        

      const searchLabelData = async () => {            
        const sendDateData = await sendDate();   

        if (!sendDateData.sendDateData[0] || !sendDateData.sendDateData[1]) {return;}       
        const formData = {        
              tableName: labelTableName,                
              line: sendDateData.sendDateData[0],       
              revision: sendDateData.sendDateData[1],  
        };  

        axios.get('/api/controlPlan', { params: formData, })                      
              .then((res) => { labelData.data = res.data});  
      };    

      const item = computed(() => { return labelData.data.map(d => { return { ...d, }; }); });

      const callSearchData = () => { searchData(); searchLabelData(); }; 

      const loadLines = async () => {
        uniqueLines.value = await fetchData('pfmea_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
      }; loadLines();  

      const loadStations = async (line) => { uniqueRevision.value = await fetchData('pfmea_label', 'revision', [{ column: 'line', value: line }]);};
      watch(() => line.value, (newLine) => { loadStations(newLine); });


      const groupedData = computed(() => {
        const naturalSort = (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });

        const groups = {};
        controlPlanData.data.forEach(d => {
          if (!groups[d.process]) {
            groups[d.process] = {              
              process: d.process,
              process_name: d.process_name,                        
              data: []
            };
          }
          groups[d.process].data.push(d);
        });          
        const sortedGroups = Object.values(groups).sort((a, b) => naturalSort(a.process, b.process));
        sortedGroups.forEach(group => {
          group.data.sort((a, b) => a.row_no - b.row_no);
        });
        return sortedGroups;
      }); 

      const displaySopStatus = computed(() => {
        if (item.value.length > 0) {
            const sop = item.value[0].sop;
            if (sop === 'Initial sample') {
                return '■ Initial sample □ Pre-production □ SOP';
            } else if (sop === 'Pre-production') {
                return '□ Initial sample ■ Pre-production □ SOP';
            } else if (sop === 'SOP') {
                return '□ Initial sample □ Pre-production ■ SOP';
            }
        }
        return '';
      });  

      return { item, revision, dbTableName, callSearchData, line, sendDate, uniqueLines, uniqueRevision, groupedData, displaySopStatus,
                showModal, showMoModal, showLabelModal, showLabelMoModal, showHistoryModal, 
      };        
    }
  }
</script>

<style scoped src="./pFmeaStyle.css"></style>