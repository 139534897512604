<template>
  <div class="todolist">  
    <div class="firstDiv">
      <h class="pagetitle">Asset List</h>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">                
          <div>
            <select class="classType" v-model="classdescription1">            
              <option v-for="class1 in uniqueClass1" :key="class1">{{ class1 }}</option>
              <option></option>
            </select>                
          </div>
          <div>
              <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
          </div>
          <div>                        
              <button class="addButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="showModal = true">+ New Asset</button>
          </div>
          <div>
              <button class="excelDownload" @click.prevent="downExcel">Down Load</button>     
          </div>            
        </div>      
      </form>  
    </div>
    <div class="table-container">
      <table class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>                
        </tr>              
        <tr v-for="row in item" :key="row.id" >                  
          <td>
            <img v-if="row.imagepath && row.imagepath !== '0'" :src="getImageUrl(row.imagepath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imagepath))">
            <template v-else></template>              
          </td>
          <td>{{ row.date }}</td>          
          <td>{{ row.assetnumber }}</td>
          <td>{{ row.name }}</td>
          <td>{{ row.contents }}</td>
          <td>{{ row.classdescription1 }}</td>
          <td>{{ row.classdescription2 }}</td>
          <td>{{ row.classdescription3 }}</td>         
          <td>{{ row.location }}</td>          
          <td>{{ row.incharge }}</td>                    
          <td>{{ row.remark }}</td>             
          <td><button class="moButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>          
          <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>        
        </tr>
      </table>    
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
  </div>  
</template>

<script>
import { ref, reactive, computed  } from 'vue';
import axios from 'axios';
import Modal from './assetModal.vue';
import modifyModal from './assetMoModal.vue';
import { deleteRow, downloadExcel, fetchData } from "../utils.js"; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      downExcel() { downloadExcel(this.item, "AssetList.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
      canClick() {          
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 }
        else { return false;}
      },   
      getImageUrl(filepath) { return filepath;},
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); }
    },

    components: { Modal, modifyModal, },     

    setup() {           
        const classdescription1 = ref();
        const dbTableName = 'assetlist';
        const showModal = ref(false);        
        const showMoModal = ref(false);      
        const headers = ['Image', 'Date', 'Asset Number', 'Name', 'Contents', 'Class1', 'Class2', 'Class3', 'Location',
                         'In Charge', 'Remark', 'Modify', 'DEL'];
        const assetData = reactive({ data: [], })  
        const uniqueClass1 = ref([]);         

        const sendDate = async () => {const sendDateData = [classdescription1.value,]; return { sendDateData };};    

        const searchData = async () => {            
          const sendDateData = await sendDate();          
          const formData = {        
                tableName: dbTableName,                
                classdescription1: sendDateData.sendDateData[0],       
          };  

          axios.get('/api/targetList', { params: formData, })                      
               .then((res) => { assetData.data = res.data.sort((a,b) => {
                if (a.assetnumber < b.assetnumber) return -1;
                if (a.assetnumber > b.assetnumber) return 1;
                return 0;
               });
          });  
        };        

        const item = computed(() => {
          return assetData.data.map(d => {            
            const formattedDate = `${d.year}-${d.month}-${d.day}`;
            return { ...d, date: formattedDate };
          });
        });

        const callSearchData = () => { searchData(dbTableName);  }; 

        const loadClass1 = async () => { uniqueClass1.value  = await fetchData('assetclass', 'classdescription1') };
        loadClass1();  

        return { item, headers, dbTableName, callSearchData, showModal, showMoModal, 
                 classdescription1, sendDate, uniqueClass1
        };        
    }
  }
</script>

<style scoped> 
  .yearInput { padding: 5px 5px; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 20px;}
  .horizontal-form { margin: 10px 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }
  .table-container { margin: 10px 20px; }  
  .disabledButton { background-color: gray; cursor: not-allowed; }  
  .table { display: table-row; flex-direction: column; align-items: stretch; padding: 0px 20px; width: 80%; }
  .table td { border : 1px solid black; text-align : center; padding: 0px 10px; font-size : 15px; }
  .table th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
      text-align : center; padding: 10px; font-size : 15px; font-weight: 600;
  }  
  .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
    }
  .excelDownload :hover { background-color: #009625; }  

  .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
  }
  .moButton { padding: 5px 5px; background-color: #347003; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }  
  .classType { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}      
</style>