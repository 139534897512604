<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add NG Contents</h3>          
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="dateTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" class="firstTDWidth">Occur Date</td>              
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Year</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Month</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Day</td>
              </tr>
              <tr>
                <td><input class="dateInput" type="number" v-model="formInputs.year" /></td>
                <td><input class="dateInput" type="number" v-model="formInputs.month" /></td>
                <td><input class="dateInput" type="number" v-model="formInputs.day" /></td>                
              </tr>
            </table><br>

            <table class="lineTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" class="firstTDWidth">NG Overview</td>              
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Project</td>                
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Line</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Posting</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Status</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">NG Type</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">SAP Storage</td>
              </tr>
              <tr>
                <td>
                  <select v-model="formInputs.project">
                    <option v-for="proj in projects" :key="proj">{{ proj }}</option><option></option>
                  </select>
                </td>
                <td>
                  <select v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                  </select>
                </td>
                <td>
                  <select v-model="formInputs.posting">
                    <option v-for="posting in postings" :key="posting">{{ posting }}</option><option></option>
                  </select>
                </td>
                <td>
                  <select v-model="formInputs.status">
                    <option v-for="status in statuses" :key="status">{{status }}</option><option></option>
                  </select>
                </td>
                <td>
                  <select v-model="formInputs.ngtype">
                    <option v-for="ngtype in ngTypes" :key="ngtype">{{ ngtype }}</option><option></option>
                  </select>
                </td>
                <td><input type="text" v-model="formInputs.sap"/></td>
              </tr>
            </table><br>

            <table class="itemTable"> 
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="3" class="firstTDWidth">Material<br>/ Quantity / Location</td>              
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Material Code</td> 
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Material Name</td> 
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Quantity</td> 
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Pallet Qty</td>                
              </tr>
              <tr>
                <td><input type="text" v-model="formInputs.materialcode"/></td>
                <td><input type="text" v-model="formInputs.materialname"/></td>
                <td><input type="number" class="dateInput" v-model="formInputs.quantity"/></td>
                <td><input type="number" class="dateInput" v-model="formInputs.pallet"/></td>
              </tr>
              <tr>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Location</td>
                <td colspan="3" style="text-align:left"><input type="text" class="locationInput" v-model="formInputs.location"/></td>
              </tr>
            </table><br>

            <table class="itemTable"> 
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" class="firstTDWidth">Contents<br>Countermeasure</td>              
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Contents</td>
                <td><textarea id="contents" v-model="formInputs.contents" rows="5" cols="100"></textarea></td>
              </tr>
              <tr>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Countermeasure</td>
                <td><textarea id="contents" v-model="formInputs.countermeasure" rows="5" cols="100"></textarea></td>
              </tr>
            </table><br>

            <table class="resolvedTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" class="firstTDWidth">Resolved</td>              
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Year</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Month</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Day</td>
                <td style="background-color: #eaeaea; border: 1; padding: 5px 5px;">Resolved Type</td>
              </tr>
              <tr>
                <td><input class="dateInput" type="number" v-model="formInputs.resolvedyear" /></td>
                <td><input class="dateInput" type="number" v-model="formInputs.resolvedmonth" /></td>
                <td><input class="dateInput" type="number" v-model="formInputs.resolvedday" /></td>   
                <td>
                  <select v-model="formInputs.resolved">
                    <option v-for="resolved in resolveds" :key="resolved">{{ resolved }}</option><option></option>
                  </select>
                </td>             
              </tr>
            </table><br>            
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { ref, watch} from 'vue';
import axios from 'axios';
import { getColumnMapping, makeFormData, fetchData, useFormInputs } from "../utils.js";
import { projectList, statusList, ngTypeList, resolvedList, postingList } from './data.js';

export default {
  setup(props, { emit }) {
    const dbTableName = "nglist";
    const isProcessing = ref(false);
    const saveStatus = ref('');
    const uniqueLines = ref([]);
    const projects = ref(projectList);
    const postings = ref(postingList);
    const statuses = ref(statusList);
    const ngTypes = ref(ngTypeList);
    const resolveds = ref(resolvedList);
          
    const { formInputs, dataForm } = useFormInputs()

    const submitForm = async () => {      

      isProcessing.value = true;
      saveStatus.value = 'Processing...';
      
      const columnMapping = getColumnMapping(undefined, formInputs);  
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping);    

      try {
        await axios.post('/api/post', formData);
        saveStatus.value = 'Completed'; 
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error';
      }      

      isProcessing.value = false;
      closeModal();
    };

    const closeModal = () => { emit("onClose"); };

    const loadLines = async () => {
      const lines = await fetchData('machinedata', 'line');
      const excludedLines = ["EV2020 60FR", "EV2020 60RR", "EV2020 4060FR", "EV2020 4060RR",
        "Common", "Leader", "Maintenance", "Packing", "Production Logistic", "Technician",
        "2nd Factory Compressors", "2nd Factory Boilers", "2nd Factory Dust collectors", "2nd Factory AHU",
        "2nd Factory Transformer", "1st Factory Compressor 1", "1st Factory Compressor 2", "1st Factory Boiler 1",
        "1st Factory Boiler 2", "1st Factory Dust collector 1", "1st Factory Dust collector 2", "1st Factory Dust collector 3",
        "1st Factory Dust collector 4", "1st Factory NEW AHU", "1st Factory OLD AHU", "1st Factory AHU SMT", "1st Factory Transformer",        
      ];
      uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
      uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines(); 

    const materialNames = async (materialcode) => {
      const materialNamesList = await fetchData('materiallist', 'materialname', [{ column: 'materialcode', value: materialcode }]);
      if (materialNamesList.length > 0) {
        formInputs.materialname = materialNamesList[materialNamesList.length - 1];
      } else { formInputs.materialname = ''; }
    };
    watch(() => formInputs.materialcode, (newCode) => {if (newCode) { materialNames(newCode); }}); 

    return {
      dbTableName, formInputs, submitForm, closeModal, isProcessing, uniqueLines, saveStatus, dataForm,
      projects, statuses, ngTypes, resolveds, postings,
    };
  },
};
</script>
      
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }     
    .modal-wrapper { width: 100%;  max-width: 900px; }  
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }  
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
    .modal-header h3 { font-size: 1.2rem; }
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
        
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }  

    .firstTDWidth {width: 120px; background-color: #A8D1FF; padding: 5px 5px;}
     
    .itemInput { margin-left: 5px; margin-right: 5px; width: 100px;}     
    .itemSelect { margin-left: 0px; margin-right: 0px; } 
    .dateInput { width: 80px; }          
    .locationInput { width: 500px; margin-left:10px; }          
    .contentsInput { margin-left: 5px; margin-right: 5px; width: 550px; } 
    .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
    .dateTable {width: 400px;}
    .dateTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px;}
    .dateTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    .resolvedTable {width: 500px;}
    .resolvedTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px;}
    .resolvedTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }    
    .lineTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px;}
    .lineTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
    .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
   
    .disabled { background-color: #ccc; cursor: not-allowed; }
    .addLineButton {margin-right: 20px; padding: 5px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }    
    .uniquecode {color: red;}
    .uniqueButton { padding: 5px 5px; background-color: #fad369; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
</style>