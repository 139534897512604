<!-- 다운타임은 dbtablename과 linename 바꾸고, 내역 테이블 붙히고, class1,2,3 확인해서 바꾸면 끝남  -->
<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Downtime</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable"> <!--Factoy, Line, Station, Type Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>                        
                <td>
                  <input class="dateInput" type="text" v-model="searchDate.year" />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Factory</td> 
                <td>
                  <select class="itemInput" v-model="formInputs.factory">
                    <option>1</option><option>2</option>
                  </select>              
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line</td> 
                <td>
                  <select id="line" v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                  </select>              
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Station</td> 
                <td>
                  <select id="station" v-model="formInputs.station">
                    <option v-for="station in uniqueStations" :key="station">{{ station }}</option>
                  </select>             
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Type</td> 
                <td>
                  <select id="type" v-model="formInputs.type">
                    <option>Repair</option><option>Failure</option><option>TPM</option><option>Patrol</option><option>Installation</option>
                    <option>Improvement</option><option>Other</option><option></option>
                  </select>               
                </td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--Time Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Start Time</td>                  
                <td>
                  <input class="timeInput" type="number" v-model="startHour" min="0" max="23" placeholder="Hour"/>                    
                  <input class="timeInput" type="number" v-model="startMinute" min="0" max="59" placeholder="Minute"/>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">End Time</td>                  
                <td>
                  <input class="timeInput" type="number" v-model="endHour" min="0" max="23" placeholder="Hour"/>                    
                  <input class="timeInput" type="number" v-model="endMinute" min="0" max="59" placeholder="Minute"/>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Time</td>                  
                <td>
                  <input type="number" v-model="formInputs.time" readonly class="readWindow" />                  
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Shift</td>                  
                <td>
                  <input type="number" v-model="formInputs.shift" readonly class="readWindow" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Name</td>                  
                <td>
                  <input class="itemInput" v-model="formInputs.name" />
                </td>
              </tr>
            </table><br>            

            <table class="itemTable"> <!--Class Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Class 1</td>                  
                <td>
                  <select id="class1" v-model="formInputs.class1">
                    <option v-for="class1 in uniqueClass1" :key="class1">{{ class1 }}</option>
                    <option>Other</option><option></option>
                  </select>                    
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Class 2</td>                  
                <td>
                  <select id="class2" v-model="formInputs.class2">
                    <option v-for="class2 in uniqueClass2" :key="class2">{{ class2 }}</option>
                    <option>Other</option><option></option>
                  </select>                    
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Class 3</td>                  
                <td>
                  <select id="class3" v-model="formInputs.class3">
                    <option v-for="class3 in uniqueClass3" :key="class3">{{ class3 }}</option>
                    <option>Other</option><option></option>
                  </select>                   
                </td>  
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Done</td>                  
                <td>
                  <select id="done" v-model="formInputs.done">
                    <option>Done</option><option>Not yet</option>
                  </select>                    
                </td>                            
              </tr>
            </table><br>

            <table class="itemTable"> <!--Contents Table-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Contents</td>
                <td style="border: 0"><textarea id="contents" v-model="formInputs.contents" rows="10" cols="120"></textarea></td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--Item Table-->
              <tr>
                <th>No.</th><th>Box Number<button class="addLineButton" @click.prevent="addItem" v-if="itemCount < 10">+</button></th><th>Spare Name</th><th>Quantity</th><th>Unit</th>
              </tr>        
              <tr v-if="itemCount >= 1">  
                <td style="font-weight: bold">1</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box1" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name1" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty1" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit1"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 2">  
                <td style="font-weight: bold">2</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box2" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name2" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty2" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit2"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 3">  
                <td style="font-weight: bold">3</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box3" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name3" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty3" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit3"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 4">  
                <td style="font-weight: bold">4</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box4" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name4" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty4" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit4"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 5">  
                <td style="font-weight: bold">5</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box5" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name5" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty5" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit5"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 6">  
                <td style="font-weight: bold">6</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box6" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name6" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty6" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit6"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 7">  
                <td style="font-weight: bold">7</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box7" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name7" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty7" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit7"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 8">  
                <td style="font-weight: bold">8</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box8" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name8" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty8" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit8"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 9">  
                <td style="font-weight: bold">9</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box9" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name9" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty9" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit9"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
              <tr v-if="itemCount >= 10">  
                <td style="font-weight: bold">10</td>
                <td><input class="nameInput" type="text" v-model="itemInputs.box10" /></td>
                <td><input class="costInput" type="text" v-model="itemInputs.name10" /></td>
                <td><input class="qtyInput" type="text" v-model="itemInputs.qty10" /></td>
                <td><select class="unitSelect" type="text" v-model="itemInputs.unit10"><option>EA</option><option>Gram</option><option>mm</option><option>Rol</option></select></td>
              </tr>
            </table><br>

            

           <p class="failurestandard">We select Failure only when the planned production has been stopped. Otherwise, if the failure does not stop the production, select Repair.</p>
           <p class="failurestandard">Failure wybieramy tylko w przypadku gdy nastąpiło zatrzymanie planowanej produkcji. W innym przypadku gdy awaria nie powoduje zatrzymania pordukcji wybieramy Repair</p><br>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}          
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref,  } from 'vue'; 
  import axios from 'axios';   
  import { getCurrentDate, fetchLine, fetchStation, fetchClass1, fetchClass2, fetchClass3 } from '../../utils.js';
   
  export default {                
    setup(props, { emit }) {
  
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const dbTableName = "maintenanceworklist"              
        const uniqueLines = ref([]);   
        const uniqueStations = ref([]);   
        const uniqueClass1 = ref([]);
        const uniqueClass2 = ref([]);
        const uniqueClass3 = ref([]);   
        const itemCount = ref(1);    
        const spareData = reactive({ data: [], }) 
         
        const formInputs = reactive({
          factory: "", shift: "", line: "", station: "", type: "", start_time: "", end_time: "", time: "",
          class1: "", class2: "", class3: "", spare: "", sparebox: "", spareqty: "", spareunit: "", contents: "", name: "", done: "",    
        }); 
        const itemInputs = reactive({
          box1: "", box2: "", box3: "", box4: "", box5: "", box6: "", box7: "", box8: "", box9: "", box10: "",
          name1: "", name2: "", name3: "", name4: "", name5: "", name6: "", name7: "", name8: "", name9: "", name10: "",
          qty1: "", qty2: "", qty3: "", qty4: "", qty5: "", qty6: "", qty7: "", qty8: "", qty9: "", qty10: "",
          unit1: "", unit2: "", unit3: "", unit4: "", unit5: "", unit6: "", unit7: "", unit8: "", unit9: "", unit10: "",
        });

        const calculateTimeDifference = (start_time, end_time) => {
          if (!start_time || !end_time) {
            return "";
          }
          const [startHour, startMinute] = start_time.split(":").map(Number);
          const [endHour, endMinute] = end_time.split(":").map(Number);

          const startDate = new Date();
          startDate.setHours(startHour, startMinute);

          const endDate = new Date();
          endDate.setHours(endHour, endMinute);

          let timeDifference = (endDate - startDate) / 1000 / 60;

          if (timeDifference < 0) {
            timeDifference += 24 * 60;
          }

          return timeDifference;
        };
     
        watch(
          () => [formInputs.start_time, formInputs.end_time],
          ([start_time, end_time]) => {            
            const startHour = start_time.split(":")[0];
            formInputs.shift = startHour >= 6 && startHour <= 17 ? 1 : 2;            
            formInputs.time = calculateTimeDifference(start_time, end_time);
          }
        );
  
        const startHour = computed({
          get: () => {
            const [hour] = formInputs.start_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.start_time.split(":");
            formInputs.start_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const startMinute = computed({
          get: () => {
            const [, minute] = formInputs.start_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.start_time.split(":");
            formInputs.start_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        }); 

        const endHour = computed({
          get: () => {
            const [hour] = formInputs.end_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.end_time.split(":");
            formInputs.end_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const endMinute = computed({
          get: () => {
            const [, minute] = formInputs.end_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.end_time.split(":");
            formInputs.end_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });  

        const searchDate = reactive({ ...getCurrentDate(), });        
    
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.line || !formInputs.type || !formInputs.time         
              || startHour.value > 23 || startMinute.value > 59 || endHour.value > 23 || endMinute.value > 59) {                           
            alert("Please fill and check year, month, day, line, type, start time, end time.");
            return;
          }

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';
          prepareDataForSubmission();          

          const columnMapping = {
            year: 'year', month: 'month' , day: 'day', factory: 'factory', shift: 'shift', type: 'type', line: 'line', station: 'station', start_time: 'start_time',
            end_time: 'end_time', time: 'time', class1 : 'class1', class2 : 'class2', class3 : 'class3',
            spare : 'spare', sparebox : 'sparebox', spareqty: 'spareqty', spareunit: 'spareunit',
            contents: 'contents', name: 'name', done: 'done' }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              factory: formInputs.factory,
              shift: formInputs.shift,
              type: formInputs.type,
              line: formInputs.line,
              station: formInputs.station,                            
              start_time: formInputs.start_time,
              end_time: formInputs.end_time,
              time: formInputs.time,
              class1: formInputs.class1,
              class2: formInputs.class2,
              class3: formInputs.class3,
              spare: formInputs.spare,     
              sparebox: formInputs.sparebox,     
              spareqty: formInputs.spareqty,     
              spareunit: formInputs.spareunit,     
              contents: formInputs.contents,     
              name: formInputs.name,   
              done: formInputs.done,     
            }
         };
         
          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error';
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };        

        const getSpareData = async () => {    
          const formData = { tableName: 'sparelist'};
          axios.get('/api/targetList', { params: formData })
            .then((res) => { spareData.data = res.data; })
            .catch((error) => { console.error("Error fetching target data:", error.message); });
        };

        getSpareData();       

        const getSpareName = (evpcode) => {
          const spare = spareData.data.find(s => s.evpcode === evpcode);
          return spare ? `${spare.name} ${spare.specification}` : '';
        };
        
        for (let i = 1; i <= 10; i++) {
          watch(
            () => itemInputs[`box${i}`],
            (newVal) => {
              if (newVal) {
                itemInputs[`name${i}`] = getSpareName(newVal);
              } else {
                itemInputs[`name${i}`] = '';
              }
            }
          );
        }

        function prepareDataForSubmission() {
          // 각 항목별 배열 초기화
          const boxs = [];
          const names = [];
          const quantities = [];
          const units = [];

          // itemInputs의 각 항목에 대한 데이터 수집
          for (let i = 1; i <= 10; i++) {
            boxs.push(itemInputs[`box${i}`]);
            names.push(itemInputs[`name${i}`]);
            quantities.push(itemInputs[`qty${i}`]);
            units.push(itemInputs[`unit${i}`]);
          }

          // formInputs에 세미콜론으로 구분된 문자열 저장
          formInputs.sparebox = boxs.join(';');
          formInputs.spare = names.join(';');
          formInputs.spareqty = quantities.join(';');
          formInputs.spareunit = units.join(';');
        }   

        const closeModal = () => {  emit("onClose");  };    

        const loadLines = async () => {
          const lines = await fetchLine();
          const excludedLines = ["EV2020 60FR", "EV2020 60RR", "EV2020 4060FR", "EV2020 4060RR"];
          uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
        }; loadLines();
        const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };
        watch(() => formInputs.line, (newLine) => { loadStations(newLine); });

        const loadClass1 = async () => { uniqueClass1.value = await fetchClass1(); }; loadClass1();

        const loadClass2 = async (class1) => { uniqueClass2.value = await fetchClass2(class1); }; 
        watch(() => formInputs.class1, (newClass) => { loadClass2(newClass); });

        const loadClass3 = async (class2) => { uniqueClass3.value = await fetchClass3(class2); }; 
        watch(() => formInputs.class2, (newClass) => { loadClass3(newClass); });

        function addItem() { if (itemCount.value < 10) { itemCount.value++; }}



 
      
          
        return { searchDate, dbTableName, formInputs, submitForm, closeModal, 
                  calculateTimeDifference, startHour, startMinute, endHour, endMinute,
                  uniqueLines, uniqueStations, uniqueClass1, uniqueClass2, uniqueClass3,
                  saveStatus, isProcessing, addItem, itemInputs, itemCount, spareData, getSpareName,                                
        }; 
    },     

    props: { onSubmit: { type: Function, required: true, }, }, 
  };
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 1200px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; width: 80px !important; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 100px; } 
  .costInput { margin-left: 5px; margin-right: 5px; width: 350px; } 
  .qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }  
  .subDateInput { width: 50px; size: 8px; margin-left: 3px; margin-right: 3px; }  
  .titleInput { width: 663px; margin-left: 5px; }  
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 10px; margin-right: 10px; padding: 0px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }
  .failurestandard {margin-left:10px; font-weight: bold};
</style>