<template>
  <div class="container">
    <form @submit.prevent="sendDate()">
      <div class="horizontal-form">                
        <div><b>Line</b></div>
        <div>            
          <select v-model="line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
          </select>                  
        </div>
        <div><b>Rev.No</b></div>
        <div>            
          <select v-model="revision">
            <option v-for="revision in uniqueRevision" :key="revision">{{ revision }}</option>
          </select>                  
        </div>
        <div><b>Page.No</b></div>
        <div>            
          <select v-model="page">
            <option v-for="page in uniquePage" :key="page">{{ page }}</option><option>ALL</option>
          </select>                  
        </div>
        <div>
            <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
        </div>
        <div>                        
            <button class="addButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showModal = true">+ Add Control Plan</button>
        </div>
        <div>                        
            <button class="addButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showLabelModal = true">+ Add Label</button>
        </div>
        <div>
            <button class="historyButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showHistoryModal = true">History</button>
        </div>            
        <div>
            <button class="printButton" @click.prevent="printTable">Print</button>    
        </div>  
        <div class="offset">Offset X:</div>
        <div><input class="offsetInput" v-model.number="offsetX" type="number" /></div>
        <div class="offset">Offset Y:</div>
        <div><input class="offsetInput" v-model.number="offsetY" type="number" /></div>
        <div class="offset">Scale X:</div>
        <div><input class="offsetInput" v-model.number="scaleX" type="number" step="0.01" /></div>
        <div class="offset">Scale Y:</div>
        <div><input class="offsetInput" v-model.number="scaleY" type="number" step="0.01" /></div>
        <div>            
          <select v-model="offset">
            <option v-for="offset in uniqueOffset" :key="offset">{{ offset }}</option>
          </select>                  
        </div>
        <div>
            <button type="submit" class="searchButton" @click="searchOffset()">Offset Search</button>
        </div>
        <div>                        
            <button class="offSetButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showOffSetModal = true">+ Add Offset</button>
        </div>
        <div>                        
            <button class="offSetButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="openSendOffsetModal(itemOffset[0])">Modify Offset</button>
        </div>
        <div>                        
            <button class="offSetButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="delRow(offsetTableName, itemOffset[0].id)">Delete Offset</button>
        </div>

      </div>      
    </form>     
    <div class="table-container" id="printArea">
      <table>
        <thead> 
        <tr class="widthTR">
          <td class="width100"></td>
          <td class="width100"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width400"></td>
          <td class="width150"></td>
          <td class="width120"></td>
          <td class="width150"></td>
          <td class="width120"></td>
          <td class="width100"></td>
          <td class="width100"></td>
          <td class="width100"></td>
        </tr>
        <tr>
          <td rowspan="2" colspan="2" class="lsevp">LSEV Poland</td>
          <td rowspan="2" colspan="8"><b class="pagetitle">Process Flow Chart</b><br>{{ displaySopStatus }}</td>          
          <td rowspan="2" colspan="2" class="alignLeft">Doc.No:<br>{{itemLable.length > 0 ? itemLable[0].doc_no : ''}}</td>          
          <td class="alignLeft">Revision date</td>
          <td class="alignLeft">{{itemLable.length > 0 ? itemLable[0].revision_date : ''}}</td>    
          <td colspan="3" rowspan="3" class="no-print"></td>                        
        </tr>
        <tr>            
          <td class="alignLeft">Revision</td>
          <td class="alignLeft">{{itemLable.length > 0 ? itemLable[0].revision : ''}}</td>          
        </tr>
        <tr>
          <td colspan="2" class="alignLeft">Part Number/Revision :<br>{{itemLable.length > 0 ? itemLable[0].partnumber : ''}}</td>
          <td colspan="8" class="alignLeft">Project Name :<br>{{itemLable.length > 0 ? itemLable[0].projectname : ''}}</td>          
          <td colspan="2" class="alignLeft">Technical Approval of Customer/Date<br>(if required)<br>{{itemLable.length > 0 ? itemLable[0].technical : ''}}</td>
          <td colspan="2" class="alignLeft">Approver<br>{{itemLable.length > 0 ? itemLable[0].approver : ''}}</td>
        </tr>        
        <tr>
          <td colspan="2" class="alignLeft">Part Name :<br>{{itemLable.length > 0 ? itemLable[0].partname : ''}}</td>
          <td colspan="8" class="alignLeft">MDT Members :<br>{{itemLable.length > 0 ? itemLable[0].mdt : ''}}</td>          
          <td colspan="2" class="alignLeft">Quality Approval of Customer/Date<br>(if required)<br>{{itemLable.length > 0 ? itemLable[0].quality : ''}}</td>
          <td colspan="2" class="alignLeft">Review<br>{{itemLable.length > 0 ? itemLable[0].review : ''}}</td>
          <td colspan="3" class="no-printLableModify">Label Modify</td>          
        </tr>        
        <tr>
          <td colspan="2" class="alignLeft">Supplier/Factory<br>{{itemLable.length > 0 ? itemLable[0].supplier : ''}}</td>
          <td colspan="7" class="alignLeft">Supplier Code<br>{{itemLable.length > 0 ? itemLable[0].suppliercode : ''}}</td>
          <td class="alignLeft">Supplier/Approved date<br>{{itemLable.length > 0 ? itemLable[0].supplierdate : ''}}</td>
          <td colspan="2" class="alignLeft">Others<br>(if required)<br>{{itemLable.length > 0 ? itemLable[0].others : ''}}</td>
          <td colspan="2" class="alignLeft">Prepared by<br>{{itemLable.length > 0 ? itemLable[0].prepared : ''}}</td>
          <td colspan="3" class="no-print"><button type="submit" @click="openSendLabelModal(itemLable[0])" class="moButton">Label Modify</button></td>
        </tr>   
        </thead>   
        <thead class="sticky-header"> 
        <tr>
          <th rowspan="4" colspan="2">Process No./Name</th><th colspan="7" class="symbolsWidth">Symbols</th><th rowspan="4">Operation Discription</th>
          <th rowspan="4" class="fontSize12">Significant Product<br>Characteristics<br>(Outputs)</th><th rowspan="4" class="fontSize12">Class<br>(KPC/QCI)</th>
          <th rowspan="4" class="fontSize12">Significant Product<br>Characteristics<br>(Intputs)</th><th rowspan="4" class="fontSize12">Class<br>(KCC/QCC)</th>          
          <th rowspan="4" class="no-print">Modify</th>
          <th rowspan="4" class="no-print">Row_no</th>
          <th rowspan="4" class="no-print">Unique Code</th>
        </tr>
        <tr>
          <th colspan="6" class="mainProcessWidth">Main Process</th><th class="symbolSubFont">Sub<br>Process</th>
        </tr>
        <tr>
          <th class="symbolThFont">Process</th><th class="symbolThFont">Move</th><th class="symbolThFont">Store</th><th class="symbolThFont">Inspection</th><th class="symbolThFont">Rework</th><th class="symbolThFont">Discard</th><th class="symbolThFont">Process</th>
        </tr>
        <tr>
          <th class="symbolThFont">●</th><th class="symbolThFont">⇒</th><th class="symbolThFont">▼</th><th class="symbolThFont">■</th><th class="symbolThFont">◇</th><th class="symbolThFont">◆</th><th class="symbolThFont">●</th>
        </tr>
        </thead>   
        <tbody>     
          <tr v-for="(item, rowIndex) in item" :key="item.id">
            <td v-if="shouldShowProcessCell(item, rowIndex)" :rowspan="getProcessRowspan(item, rowIndex)">{{ item.process }}</td>            
            <td v-if="shouldShowProcessNameCell(item, rowIndex)" :rowspan="getProcessNameRowspan(item, rowIndex)">{{ item.process_name }}</td>            

            <td class="symbolTd" v-for="col in dynamicCols" :key="col" :ref="`cell-${rowIndex}-${col}`">
              <div class="symbol">
                {{ getSymbol(rowIndex, col) }}
              </div>              
            </td>                         
            
            <td class="operationDiscription">{{item.operationdiscription}}</td>      
            <td class="outIn">{{item.significant_output}}</td>      
            <td class="fontSize12">{{item.class1}}</td>      
            <td class="outIn">{{item.significant_input}}</td>      
            <td class="fontSize12">{{item.class2}}</td>     
            <td class="no-print"><button type="submit" @click="openSendModal(item)" class="moButton">Modify</button></td>            
            <td class="no-print" @click="editCell(dbTableName, item.id, 'row_no', $event)">{{ item.row_no }}</td>           
            <td class="no-print">{{ item.uniquecode }}</td>
          </tr>          
        </tbody>
      </table>

      <svg class="connections">
        <g :transform="transform">
          <path v-for="(line, index) in lines" :key="index"
                :d="line.d"
                :stroke-dasharray="line.dash"
                stroke="blue"
                fill="none"
                marker-end="url(#arrow-down)"/>
        </g>
        <defs>
          <marker id="arrow-down" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
            <path d="M0,0 L10,5 L0,10 Z" fill="blue"/>
          </marker>
        </defs>
      </svg>
    </div>     
    <Modal v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/> 
    <ModalLabel v-if="showLabelModal" @onClose="showLabelModal = false; callSearchData();"/>
    <ModalMoLabel v-if="showLabelMoModal" :row-id="selectedRowId" @onClose="showLabelMoModal = false; callSearchData();"/> 
    <historyModal v-if="showHistoryModal" :line="line" @onClose="showHistoryModal = false; "/>
    <offSetModal v-if="showOffSetModal" @onClose="showOffSetModal = false; "/>
    <offSetMoModal v-if="showOffSetMoModal" :row-id="selectedRowId" @onClose="showOffSetMoModal = false; "/>
  </div>    
</template>

<script>
import { reactive, computed, ref, watch,} from 'vue';
import axios from "axios";
import Modal from './flowChartModal.vue';
import modifyModal from './flowChartMoModal.vue';
import ModalLabel from './flowChartLabelModal.vue';
import ModalMoLabel from './flowChartLabelMoModal.vue';
import historyModal from './flowChartHistoryModal.vue';
import offSetModal from './flowChartOffsetModal.vue';
import offSetMoModal from './flowChartOffsetMoModal.vue';


import { calculateLinePositions, updateSymbolsAndConnections, recalculateLinePositions } from './flowChartUtils';
import { fetchData, deleteRow, editCellValue } from "../utils.js"; 

export default {
  data() { return { rows: [], cols: [], symbols: [], connections: [], lines: [], 
    offsetX: 0, offsetY: 0, scaleX: 1, scaleY: 1,
    isPrinting: false,
    dbTableName: "flowchart", labelTableName: "flowchart_label", offsetTableName: "flowchart_offset", };
  },
  watch: {
    item(newVal) { this.$nextTick(() => { this.updateSymbolsAndConnections(newVal); }); },
    itemOffset(newVal) {if (newVal.length > 0) { this.offsetX = newVal[0].offsetX || 0; this.offsetY = newVal[0].offsetY || 0; this.scaleX = newVal[0].scaleX || 1; this.scaleY = newVal[0].scaleY || 1; }}
  },
  computed: {         
    dynamicCols() { return [1, 2, 3, 4, 5, 6, 7]; },  
    transform() {
      return this.isPrinting ? `translate(${this.offsetX}, ${this.offsetY}) scale(${this.scaleX}, ${this.scaleY})` : '';
    }


  },
  methods: {
    openSendModal(item) { this.selectedRowId = item.id; this.showMoModal = true; },        
    openSendLabelModal(item) { this.selectedRowId = item.id; this.showLabelMoModal = true; },      
    openSendOffsetModal(item) { this.selectedRowId = item.id; this.showOffSetMoModal = true; },      
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },  
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    },   
    getSymbol(rowIndex, col) {
      const symbol = this.symbols.find(symbol => parseInt(symbol.row) === parseInt(rowIndex) && parseInt(symbol.col) === parseInt(col));            
      return symbol ? symbol.type : '';
    },
    canClick() {          
      const currentUser = this.$route.meta.currentUser;          
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 32 }
      else { return false;}
    }, //test1
    
    printTable() {
      this.isPrinting = true; // 프린트 상태를 true로 설정
      this.$nextTick(() => {        
        this.recalculateLinesForPrint();
        setTimeout(() => {
          const printContents = document.getElementById("printArea").innerHTML;
          const originalContents = document.body.innerHTML;
          document.body.innerHTML = printContents;
          window.print();   
          document.body.innerHTML = originalContents;          
          this.restoreOriginalLinesAfterPrint();
          this.isPrinting = false; // 프린트 완료 후 false로 설정          
          window.location.reload(); 
        }, 500);         
      });
    },

    recalculateLinesForPrint() {
      this.lines = recalculateLinePositions(this.$refs, this.connections, this.symbols);
    },

    restoreOriginalLinesAfterPrint() {
      this.lines = calculateLinePositions(this.$refs, this.connections, this.symbols);
    },

  shouldShowProcessCell(item, index) {
    if (index === 0) return true;
    return this.item[index].process !== this.item[index - 1].process;
  },
  getProcessRowspan(item, index) {
    let rowspan = 1;
    for (let i = index + 1; i < this.item.length; i++) {
      if (this.item[i].process === this.item[index].process) {
        rowspan++;
      } else {
        break;
      }
    }
    return rowspan;
  },
  shouldShowProcessNameCell(item, index) {
    if (index === 0) return true;
    return this.item[index].process_name !== this.item[index - 1].process_name;
  },
  getProcessNameRowspan(item, index) {
    let rowspan = 1;
    for (let i = index + 1; i < this.item.length; i++) {
      if (this.item[i].process_name === this.item[index].process_name) {
        rowspan++;
      } else {
        break;
      }
    }
    return rowspan;
  },

    
  updateSymbolsAndConnections(items) {
    const symbolTypes = { "1": "●", "2": "⇒", "3": "▼", "4": "■", "5": "◇", "6": "◆", "7": "●",};
    const { symbols, connections, rows, cols } = updateSymbolsAndConnections(items, symbolTypes);
    this.symbols = symbols;
    this.connections = connections;
    this.rows = rows;
    this.cols = cols;      
  },  
  callSearchData() {
    this.searchData().then(() => {
      this.labelData().then(() => {
        this.$nextTick(() => {
          this.updateSymbolsAndConnections(this.item);
          this.$nextTick(() => { this.lines = calculateLinePositions(this.$refs, this.connections, this.symbols); });
        });
      });
    });
  },
  async searchData() {
    const sendDateData = await this.sendDate();
    if (!sendDateData.sendDateData[0] || !sendDateData.sendDateData[1]) {
      alert('Need select Line And Rev.No');
      return;
    }

    const formData = {
      tableName: this.dbTableName,
      line: sendDateData.sendDateData[0],
      revision: sendDateData.sendDateData[1],
      page: sendDateData.sendDateData[3],
    };

    return axios.get('/api/flowChart', { params: formData })
      .then((res) => {
        this.dbList.data = res.data.sort((a, b) => a.row_no - b.row_no);
      });
  },
  async labelData() {
    const sendDateData = await this.sendDate();
    if (!sendDateData.sendDateData[0] || !sendDateData.sendDateData[1]) {
      return;
    }

    const formData = {
      tableName: this.labelTableName,
      line: sendDateData.sendDateData[0],
      revision: sendDateData.sendDateData[1],
    };

    return axios.get('/api/flowChart', { params: formData })
      .then((res) => {
        this.labelList.data = res.data.sort((a, b) => a.row_no - b.row_no);
      });
  },  

  async searchOffset() {
    const sendDateData = await this.sendDate();
    if (!sendDateData.sendDateData[0] || !sendDateData.sendDateData[2]) {
      return;
    }

  const formData = {
      tableName: this.offsetTableName,
      line: sendDateData.sendDateData[0],
      employeenumber: sendDateData.sendDateData[2],
    };

    return axios.get('/api/targetList', { params: formData })
      .then((res) => {
        this.offsetList.data = res.data.sort((a, b) => a.row_no - b.row_no);
      });
    },      
  },
 
  mounted() { window.addEventListener('resize', this.updateLinePositions); },
  beforeUnmount() { window.removeEventListener('resize', this.updateLinePositions); },

  components: { Modal, modifyModal, ModalLabel, ModalMoLabel, historyModal, offSetModal, offSetMoModal }, 

  setup() {
    const line = ref();
    const revision = ref();    
    const offset = ref();    
    const page = ref('ALL');    
    const dbList = reactive({ data: [] });   
    const labelList = reactive({ data: [] });
    const offsetList = reactive({ data: [] });  
    const showModal = ref(false);        
    const showMoModal = ref(false);      
    const showLabelModal = ref(false);   
    const showLabelMoModal = ref(false);                   
    const showHistoryModal = ref(false);  
    const showOffSetModal = ref(false);      
    const showOffSetMoModal = ref(false);    
    const uniqueLines = ref([]);     
    const uniqueRevision = ref([]);    
    const uniqueOffset = ref([]);    
    const uniquePage = ref([]);    

    const displaySopStatus = computed(() => {
      if (itemLable.value.length > 0) {
          const sop = itemLable.value[0].sop;
          if (sop === 'Initial sample') {
              return '■ Initial sample □ Pre-production □ SOP';
          } else if (sop === 'Pre-production') {
              return '□ Initial sample ■ Pre-production □ SOP';
          } else if (sop === 'SOP') {
              return '□ Initial sample □ Pre-production ■ SOP';
          }
      }
      return '';
    });    

    const sendDate = async () => {const sendDateData = [line.value, revision.value, offset.value, page.value]; return { sendDateData };};    
   
    const loadLines = async () => {
        uniqueLines.value = await fetchData('flowchart_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
      }; loadLines();  

    const loadStations = async (line) => { uniqueRevision.value = await fetchData('flowchart_label', 'revision', [{ column: 'line', value: line }]);};
    watch(() => line.value, (newLine) => { loadStations(newLine); });

    const loadOffset = async (line) => { uniqueOffset.value = await fetchData('flowchart_offset', 'employeenumber', [{ column: 'line', value: line }]);};
    watch(() => line.value, (newLine) => { loadOffset(newLine); });

    const loadPage = async (line) => { uniquePage.value = await fetchData('flowchart', 'page', [{ column: 'line', value: line }]);};
    watch(() => line.value, (newLine) => { loadPage(newLine); });

    const item = computed(() => dbList.data.map(d => ({ ...d })));    
    const itemOffset = computed(() => offsetList.data.map(d => ({ ...d })));    
    const itemLable = computed(() => labelList.data.map(d => ({ ...d })));

    return { item, itemLable, itemOffset, dbList, labelList, offsetList, uniqueLines, uniqueRevision, line, revision, offset, page, uniqueOffset, uniquePage, sendDate, displaySopStatus,
             showModal, showMoModal, showLabelModal, showLabelMoModal, showHistoryModal, showOffSetModal, showOffSetMoModal     
    };
  },  
};
</script>

<style scoped src="./flowChartStyle.css"></style>
