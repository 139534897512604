<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Q-WALL</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>                                                         
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option v-for="model in models" :key="model">{{ model }}</option>
              </select>                 
            </div>              
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>
          </td>
          <td> 
            <div class="form-group">
              <label for="ng">NG:</label>
              <input type="number" id="ng" v-model="formInputs.ng" />
            </div>            
            <div class="form-group">
              <label for="ng_busbarassembly">NG BUSBAR ASSEMBLY:</label>
              <input type="number" id="ng_busbarassembly" v-model="formInputs.ng_busbarassembly"  />
            </div>
            <div class="form-group">
              <label for="ng_heatstacking">NG HEAT STACKING:</label>
              <input type="number" id="ng_heatstacking" v-model="formInputs.ng_heatstacking" />
            </div>
            <div class="form-group">
              <label for="ng_eol">NG EOL:</label>
              <input type="number" id="ng_eol" v-model="formInputs.ng_eol"  />
            </div>
          </td>
          <td> 
            <div class="form-group">
              <label for="ng_laserwelding">NG LASER WELDING:</label>
              <input type="number" id="ng_laserwelding" v-model="formInputs.ng_laserwelding" />
            </div>
            <div class="form-group">
              <label for="ng_slot">NG SLOT:</label>
              <input type="number" id="ng_slot" v-model="formInputs.ng_slot" />
            </div>
            <div class="form-group">
              <label for="ng_holepositionjig">NG HOLE POSITION JIG:</label>
              <input type="number" id="ng_holepositionjig" v-model="formInputs.ng_holepositionjig" />
            </div>
            <div class="form-group">
              <label for="ng_finalinspection">NG FINAL INSPECTION:</label>
              <input type="number" id="ng_finalinspection" v-model="formInputs.ng_finalinspection"  />
            </div>
            <div class="form-group">
              <label for="ng_others">NG OTHERS:</label>
              <input type="number" id="ng_others" v-model="formInputs.ng_others" />
            </div>
            <div class="form-group">
              <label for="ng_test">TEST:</label>
              <input type="number" id="ng_test" v-model="formInputs.ng_test" />
            </div>          
          </td>
          </tr>
          </table>   
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>
import { useForm } from '../0_formStyle/modal/form_qw_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../0_formStyle/modal/style_qw_modal.css"></style>