<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Utility Inspection Report</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>                        
                <td>
                  <input class="dateInput" type="text" v-model="searchDate.year" />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Done Date</td>                        
                <td>
                  <input class="dateInput" type="text" v-model="formInputs.finyear" />
                  <input class="dateInput" type="text" v-model="formInputs.finmonth" />
                  <input class="dateInput" type="text" v-model="formInputs.finday" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Type</td> 
                <td>
                  <select class="itemInput" v-model="formInputs.type">
                    <option>Line</option><option>Utility</option><option>Construction</option>
                  </select>              
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Writer</td>
                <td><input class="itemInput" type="text" v-model="formInputs.writer" /></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Report Number</td>
                <td><input class="itemInput" type="text" v-model="formInputs.reportnumber" /></td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--Title Table-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;">Title</td>
                <td style="border: 0"><input class="titleInput" type="text" v-model="formInputs.title" /></td>                  
              </tr>
            </table><br>

            <P class="evaluationTitle">Machine</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaCont">Did you use materials that meet the specifications?</td>                
                <td class="score">15</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc01">
                    <option>0</option><option>7.5</option><option>15</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is there any abnormal noise?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc02">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Has the machine been tested after installation preliminary (primary) inspection?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc03">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are the controls panels and buttons visible, recognizable and appropriately marked?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc04">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is the language used in the machines Local language, Korean and English, is it easy to change it?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc05">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is a surge protection device installed?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc06">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is there enough space for repairs by the maintenance staff during maintenance?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc07">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are the touch screen and setup program easy to operate?</td>                
                <td class="score">15</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc08">
                    <option>0</option><option>7.5</option><option>15</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is it comfortable for operators to work?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc09">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>                      
            </table><br>

            <P class="evaluationTitle">Product/Quality</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaCont">Does it meet the agreed upon productivity?</td>                
                <td class="score">30</td>
                <td class="score">
                  <select id="workmethod" v-model="score02.sc01">
                    <option>0</option><option>15</option><option>30</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are the quality standards met?</td>                
                <td class="score">25</td>
                <td class="score">
                  <select id="workmethod" v-model="score02.sc02">
                    <option>0</option><option>17.5</option><option>25</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is there any problem with the detection power of the inspection equipment?</td>                
                <td class="score">25</td>
                <td class="score">
                  <select id="workmethod" v-model="score02.sc03">
                    <option>0</option><option>17.5</option><option>25</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is the master jig provided? (documents included)</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score02.sc04">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>             
            </table><br>

            <P class="evaluationTitle">Pneumatic</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaCont">Does it match the pneumatic installation drawing?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score03.sc01">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are the pneumatic hoses numbered and labeled?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score03.sc02">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are there any air leaks?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score03.sc03">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is there no damage to the machine due to pressure loss or critical pressure drop?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score03.sc04">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Was it installed through the main air unit?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score03.sc05">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>             
            </table><br>

            <P class="evaluationTitle">Training and After Service</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaCont">Was there sufficient training for operators, technicians, maintenance personnel, and engineers?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score04.sc01">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Were spares provided?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score04.sc02">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Does the machine have a warranty?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score04.sc03">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is it possible to communicate with the manufacturer and local engineers?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score04.sc04">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Can local engineer resolve problems online or by telephone support?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score04.sc05">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are service and spare parts available in local?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score04.sc06">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>             
            </table><br>

            <P class="evaluationTitle">Safety</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaCont">Are devices installed for worker safety?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc01">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Does it stop immediately when the safety device operates?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc02">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Does Door Auto Lock work well?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc03">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">In case of an emergency stop, does the facility stop immediately?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc04">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Does the tower lamp work well (green/yellow/red)?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc05">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are dangerous places and obstacles properly marked and secured?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc06">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is the machine equipped with protective measures against hazards caused by gas, vapor, liquid or dust emissions?</td>                
                <td class="score">5</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc07">
                    <option>0</option><option>2.5</option><option>5</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Are the machine and its parts properly secured to ensure their stability?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc08">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is the workplace, including machine maintenance, properly lit?</td>                
                <td class="score">5</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc09">
                    <option>0</option><option>2.5</option><option>5</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is the machine protected against hazards electrocution of workers?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc10">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Is there protection against risk to workers due to pressure loss or critical pressure drop in the event of an emergency stop?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score05.sc11">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
             
            </table><br>

            <P class="evaluationTitle">Documentation</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaCont">Have you received electrical drawings?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc01">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Have you received the mechanical drawing?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc02">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Have you received the pneumatic drawings?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc03">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Have you received a lubrication drawing?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc04">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Have you received the Trouble shooting Guide?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc05">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Have you received the user manual?</td>                
                <td class="score">20</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc06">
                    <option>0</option><option>10</option><option>20</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Does the machine have local safety certification (e.g. European CE)?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc07">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>
              <tr>
                <td class="evaCont">Whether electrical measurements of the machine have been performed?</td>                
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score06.sc08">
                    <option>0</option><option>5</option><option>10</option>
                  </select>                  
                </td>
              </tr>             
            </table><br>

            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;">Remark</td>
                <td style="border: 0"><input class="titleInput" type="text" v-model="formInputs.remark" /></td>
                <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;">Total Score</td>
                <td style="border: 0; text-align: center; width: 100px;">{{ formInputs.sum_score }}</td>
                <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;" >result</td>
                <td style="border: 0; width: 100px;" :class="resultClass">{{ formInputs.result }}</td>
              </tr>
            </table><br>

            <table class="uploadTable">
              <tr>
                <td class="uploadTd">File<br>Upload</td>
                <td><input class="uploadInput" type="file" @change="handleFileUpload('file')($event)" /><br>
                    <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button><br>
                    {{ fileSaveStatus.file }}
                </td>              
                <td class="uploadTd">Image 1<br>Upload</td>
                <td><input class="uploadInput" type="file" @change="handleFileUpload('imageone')($event)" /><br>            
                    <button class="fileUploadButton" type="button" @click="fileUpload('imageone')">Image 1 Upload</button><br>
                    {{ fileSaveStatus.imageone }}   
                </td>              
                <td class="uploadTd">Image 2<br>Upload</td>
                <td><input class="uploadInput" type="file" @change="handleFileUpload('imagetwo')($event)" /><br>             
                    <button class="fileUploadButton" type="button" @click="fileUpload('imagetwo')">Image 2 Upload</button><br>
                    {{ fileSaveStatus.imagetwo }}                     
                </td>
              </tr>
              </table><br>                           
              
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch,  ref, computed } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, uploadFile} from "../../utils.js"; 
   
  export default {            
    setup(props, { emit }) {   
      const dbTableName = "constructioninspection";  
      const saveStatus = ref(''); 
      const selectedFiles = reactive({ file: null,  imageone: null, imagetwo: null, });     
      const fileSaveStatus = reactive({ file: '', imageone: '', imagetwo: '', });
      const uploadType = 'constructioninspection';  
      const isProcessing = ref(false);                    
      const formInputs = reactive({   
        finyear: "", finmonth: "", finday: "", type: "", title: "", writer: "", reportnumber:"", 
        score_1: "", score_2: "", score_3: "", score_4: "", score_5: "", score_6: "",
        result: "", remark: "", confirm: "",
        file: "", filepath: "", imageone: "", imageonepath: "", imagetwo: "", imagetwopath: "",
        sum_score: "",
      });
      const score01 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "", sc07: "", sc08: "", sc09: "",});
      const score02 = reactive({sc01: "", sc02: "", sc03: "", sc04: "" });
      const score03 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "" }); 
      const score04 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "" });
      const score05 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "", sc07: "", sc08: "", sc09: "", sc10: "", sc11: "", });
      const score06 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "", sc07: "", sc08: "" });

      const searchDate = reactive({ ...getCurrentDate(), });    

      const submitForm = async () => {         

        isProcessing.value = true; 
        saveStatus.value = 'Processing...';

        const columnMapping = {              
          year: 'year',
          month: 'month',
          day: 'day',
          finyear: 'finyear',
          finmonth: 'finmonth',
          finday: 'finday',
          type: 'type',
          title: 'title',
          writer: 'writer',
          reportnumber: 'reportnumber',
          score_1: 'score_1',
          score_2: 'score_2',
          score_3: 'score_3',
          score_4: 'score_4',
          score_5: 'score_5',
          score_6: 'score_6',
          sum_score: 'sum_score',
          result: 'result',
          remark: 'remark',            
          confirm : 'confirm',
          file: 'file',
          filepath: 'filepath',
          imageone: 'imageone',
          imageonepath: 'imageonepath',
          imagetwo: 'imagetwo',
          imagetwopath: 'imagetwopath'  
        }     

        const formData = {            
          tableName: dbTableName,
          columnMapping: columnMapping,
          values: {              
            year: searchDate.year,
            month: searchDate.month,
            day: searchDate.day,              
            finyear: formInputs.finyear,
            finmonth: formInputs.finmonth,
            finday: formInputs.finday,
            type: formInputs.type,
            title: formInputs.title,
            writer: formInputs.writer,
            reportnumber: formInputs.reportnumber,
            score_1: formInputs.score_1,
            score_2: formInputs.score_2,
            score_3: formInputs.score_3,
            score_4: formInputs.score_4,
            score_5: formInputs.score_5,
            score_6: formInputs.score_6,
            sum_score: formInputs.sum_score,
            result: formInputs.result,
            remark: formInputs.remark,            
            confirm: formInputs.confirm,
            file: formInputs.file,
            filepath: formInputs.filepath,
            imageone: formInputs.imageone,
            imageonepath: formInputs.imageonepath,
            imagetwo: formInputs.imagetwo,
            imagetwopath: formInputs.imagetwopath
          }
        };     

        try {
          await axios.post('/api/post', formData);  
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error'; 
        } finally {
          isProcessing.value = false; 
        }         
      
        closeModal();
      };
      const closeModal = () => { emit("onClose"); };        

      watch(
        () => [score01.sc01, score01.sc02, score01.sc03, score01.sc04, score01.sc05, score01.sc06, score01.sc07, score01.sc08, score01.sc09],
        () => {formInputs.score_1 = `${score01.sc01};${score01.sc02};${score01.sc03};${score01.sc04};${score01.sc05};${score01.sc06};${score01.sc07};${score01.sc08};${score01.sc09}`;}
      );
      watch(
        () => [score02.sc01, score02.sc02, score02.sc03, score02.sc04],
        () => {formInputs.score_2 = `${score02.sc01};${score02.sc02};${score02.sc03};${score02.sc04}`;}
      );
      watch(
        () => [score03.sc01, score03.sc02, score03.sc03, score03.sc04, score03.sc05],
        () => {formInputs.score_3 = `${score03.sc01};${score03.sc02};${score03.sc03};${score03.sc04};${score03.sc05}`;}
      );
      watch(
        () => [score04.sc01, score04.sc02, score04.sc03, score04.sc04, score04.sc05, score04.sc06],
        () => {formInputs.score_4 = `${score04.sc01};${score04.sc02};${score04.sc03};${score04.sc04};${score04.sc05};${score04.sc06}`;}
      );  
      watch(
        () => [score05.sc01, score05.sc02, score05.sc03, score05.sc04, score05.sc05, score05.sc06, score05.sc07, score05.sc08, score05.sc09, score05.sc10, score05.sc11],
        () => {formInputs.score_5 = `${score05.sc01};${score05.sc02};${score05.sc03};${score05.sc04};${score05.sc05};${score05.sc06};${score05.sc07};${score05.sc08};${score05.sc09};${score05.sc10};${score05.sc11}`;}
      );
      watch(
        () => [score06.sc01, score06.sc02, score06.sc03, score06.sc04, score06.sc05, score06.sc06, score06.sc07, score06.sc08],
        () => {formInputs.score_6 = `${score06.sc01};${score06.sc02};${score06.sc03};${score06.sc04};${score06.sc05};${score06.sc06};${score06.sc07};${score06.sc08}`;}
      );
      watch(
        () => [
            score01.sc01, score01.sc02, score01.sc03, score01.sc04, score01.sc05, score01.sc06, score01.sc07, score01.sc08, score01.sc09,
            score02.sc01, score02.sc02, score02.sc03, score02.sc04,
            score03.sc01, score03.sc02, score03.sc03, score03.sc04, score03.sc05,
            score04.sc01, score04.sc02, score04.sc03, score04.sc04, score04.sc05, score04.sc06,
            score05.sc01, score05.sc02, score05.sc03, score05.sc04, score05.sc05, score05.sc06, score05.sc07, score05.sc08, score05.sc09, score05.sc10, score05.sc11,
            score06.sc01, score06.sc02, score06.sc03, score06.sc04, score06.sc05, score06.sc06, score06.sc07, score06.sc08
        ],
        () => {

          const sum_score01 = parseInt(score01.sc01) + parseInt(score01.sc02) + parseInt(score01.sc03) + parseInt(score01.sc04) + parseInt(score01.sc05) + parseInt(score01.sc06) + parseInt(score01.sc07) + parseInt(score01.sc08) + parseInt(score01.sc09);
          const sum_score02 = parseInt(score02.sc01) + parseInt(score02.sc02) + parseInt(score02.sc03) + parseInt(score02.sc04);
          const sum_score03 = parseInt(score03.sc01) + parseInt(score03.sc02) + parseInt(score03.sc03) + parseInt(score03.sc04) + parseInt(score03.sc05);
          const sum_score04 = parseInt(score04.sc01) + parseInt(score04.sc02) + parseInt(score04.sc03) + parseInt(score04.sc04) + parseInt(score04.sc05) + parseInt(score04.sc06);
          const sum_score05 = parseInt(score05.sc01) + parseInt(score05.sc02) + parseInt(score05.sc03) + parseInt(score05.sc04) + parseInt(score05.sc05) + parseInt(score05.sc06) + parseInt(score05.sc07) + parseInt(score05.sc08) + parseInt(score05.sc09) + parseInt(score05.sc10) + parseInt(score05.sc11);
          const sum_score06 = parseInt(score06.sc01) + parseInt(score06.sc02) + parseInt(score06.sc03) + parseInt(score06.sc04) + parseInt(score06.sc05) + parseInt(score06.sc06) + parseInt(score06.sc07) + parseInt(score06.sc08);
          const totalScore = ((sum_score01 + sum_score02 + sum_score03 + sum_score04 + sum_score05 + sum_score06)/6).toFixed(0)
        
          if (totalScore >= 95) { formInputs.sum_score = totalScore; formInputs.result ='OK'; }
          else { formInputs.sum_score = totalScore; formInputs.result ='NG'; }
        }
      );

      const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};
      const fileUpload = (type) => uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);         

      const resultClass = computed(() => {
        switch (formInputs.result) {
          case 'OK': return 'result-ok';
          case 'NG': return 'result-ng';
          default: return 'result-neutral';
        }
      });

      return { dbTableName, formInputs, searchDate, submitForm, closeModal, saveStatus, isProcessing,
               handleFileUpload, fileUpload, selectedFiles, fileSaveStatus, resultClass,
               score01, score02, score03, score04, score05, score06,
      };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 1200px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 350px; } 
  .costInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .uploadInput { margin-left: 5px; margin-right: 5px; width: 200px; } 
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .subDateInput { width: 50px; size: 8px; margin-left: 3px; margin-right: 3px; }  
  .titleInput { width: 660px; margin-left: 10px; }  
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }  

  .evaluationTitle { font-weight: bold; }
  .evaluationTable { table-layout: fixed; width: 100%; border-collapse: collapse; }
  .evaluationTable td  { vertical-align: center; text-align: left; padding: 0 5px; border: 1px solid rgb(156, 156, 156); }
  .evaluationTable th { background-color:#eaeaea ; vertical-align: center; text-align: center; padding: 10px 15px; border: 1px solid rgb(156, 156, 156);}  
  .evaluationTable select, input { width: 70px; }

  .evaItem {width: 220px;}
  .evaCont {width: 500px;}  
  .score {width: 100px; text-align: center !important; }

  .gradeTable { table-layout: fixed; width: 100%; }
  .gradeTable td { width: 20%; vertical-align: top; padding: 0 15px; }
  .gradeTable input { width: 70px; }

  .grade {width: 220px;}
  .nextEva {width: 500px;}  
  .remark {width: 220px; }  
  .remarkInput {width: 220px !important; }  
  .result-ok { background-color: rgb(153, 239, 153); font-weight: bold; text-align: center; }
  .result-ng { background-color: rgb(239, 173, 173); font-weight: bold; text-align: center; }
  .result-neutral { background-color: transparent; }

</style>