<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Flow Chart</h3>
          <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
          {{ saveStatus }}
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="lineTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line</td>
                <td>
                  <select v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                  </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Revision No.<br>(00)</td>
                <td>
                  <input class="revisionInput" type="text" v-model="formInputs.revision" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Page No.<br></td>
                <td>
                  <input class="revisionInput" type="text" v-model="formInputs.page" />
                </td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--Class Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">                
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process No.</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.process" rows="3" cols="40"></textarea></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process Name</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.process_name" rows="3" cols="40"></textarea></td>
              </tr>
            </table><br>   
            
            <div v-for="(item, index) in items" :key="index">
              <b class="uniquecode" >Unique Code: Once specified as a unique value for this item, it cannot be changed, and it must not overlap in the same Line.</b>
              <table class="itemTable">
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #A8D1FF; padding: 5px 5px;">Unique Code</td>
                  <td colspan="3" style="text-align: left;">
                    <input class="itemInput" type="text" v-model="item.uniquecode" />
                    <button class="uniqueButton" @click.prevent="checkUniqueCode(item.uniquecode, item)">Check</button>                     
                  </td>
                  <td colspan="3" style="font-size: 12px; text-align:left">{{item.uniqueCodeAvailable}}</td>       
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td rowspan="4" style="background-color: #F9FDA2; padding: 5px 5px;">Symbol</td>                                    
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Process</td>
                  <td style="text-align: left;">
                    <select class="symbolInput" v-model="item.symbols_col">
                      <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option>
                    </select>
                  </td>                           
                  <td colspan="4" style="text-align:left" >
                    Process 1: Process, ● &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Process 5: Rework, ◇<br>
                    Process 2: Move, ⇒ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Process 6: Discard, ◆<br>
                    Process 3: Store, ▼ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Process 7: Sub Process, ●<br>
                    Process 4: Inspection, ■
                  </td>                  
                </tr>                
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Connect<br>Unique Code 1</td>               
                  <td style="text-align: left;"><input class="symbolInput" type="text" v-model="item.end_symbol1" /></td>                  
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Line Type 1</td>               
                  <td style="text-align: left;"><select class="symbolSelect" v-model="item.line_type1"><option>solid</option><option>dashed</option><option></option></select></td>  
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Line Direction 1</td>               
                  <td style="text-align: left;"><select class="symbolSelect" v-model="item.line_direction1"><option>left</option><option>right</option><option>down</option><option></option></select></td>                  
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Connect<br>Unique Code 2</td>               
                  <td style="text-align: left;"><input class="symbolInput" type="text" v-model="item.end_symbol2" /></td>                  
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Line Type 2</td>               
                  <td style="text-align: left;"><select class="symbolSelect" v-model="item.line_type2"><option>solid</option><option>dashed</option><option></option></select></td>  
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Line Direction 2</td>               
                  <td style="text-align: left;"><select class="symbolSelect" v-model="item.line_direction2"><option>left</option><option>right</option><option>down</option><option></option></select></td>                  
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Connect<br>Unique Code 3</td>               
                  <td style="text-align: left;"><input class="symbolInput" type="text" v-model="item.end_symbol3" /></td>                  
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Line Type 3</td>               
                  <td style="text-align: left;"><select class="symbolSelect" v-model="item.line_type3"><option>solid</option><option>dashed</option><option></option></select></td>  
                  <td style="background-color: #eaeaea; padding: 5px 5px;">Line Direction 3</td>               
                  <td style="text-align: left;"><select class="symbolSelect" v-model="item.line_direction3"><option>left</option><option>right</option><option>down</option><option></option></select></td>                  
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #F9FDA2; padding: 5px 5px;">Operation Discription</td>                  
                  <td colspan="7" style="text-align: left;"><textarea id="contents" v-model="item.operationdiscription" rows="3" cols="130"></textarea></td>                  
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td rowspan="2" style="background-color: #F9FDA2; padding: 5px 5px;">Significant<br>Class</td>
                  <td colspan="2" style="background-color: #eaeaea; padding: 5px 5px;">Significant Product<br>Characteristics<br>(Output)</td>
                  <td colspan="2" style="text-align: left;"><textarea id="contents" v-model="item.significant_output" rows="3" cols="70"></textarea></td>
                  <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Class<br> (KPC/QCI)</td>
                  <td colspan="2" style="text-align: left;"><select class="symbolSelect" v-model="item.class1"><option>O</option><option></option></select></td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td colspan="2" style="background-color: #eaeaea; padding: 5px 5px;">Significant Product<br>Characteristics<br>(Iutput)</td>
                  <td colspan="2" style="text-align: left;"><textarea id="contents" v-model="item.significant_input" rows="3" cols="70"></textarea></td>
                  <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Class<br> (KPC/QCI)</td>
                  <td colspan="2" style="text-align: left;"><select class="symbolSelect" v-model="item.class2"><option>O</option><option></option></select></td>
                </tr>
                <tr style="border: 1px solid rgb(156, 156, 156);">
                  <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Row No.</td>
                  <td colspan="2" style="text-align: left;"><input class="itemInput" type="text" v-model="item.row_no" /></td>                  
                </tr>                
              </table><br>
            </div>           
            
            <button class="addLineButton" @click.prevent="addItem">+</button>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { ref, reactive } from 'vue';
import axios from 'axios';
import { getColumnMapping, makeFormData, fetchData } from "../utils.js";

export default {
  setup(props, { emit }) {
    const dbTableName = "flowchart";
    const isProcessing = ref(false);
    const saveStatus = ref(''); 
    const uniqueLines = ref([]);
    const items = ref([{ uniquecode: '', uniqueCodeOk: false, uniqueCodeAvailable: '' }]);
    const formInputs = reactive({
      line: '',
      revision: '',      
      process: '',
      process_name: '',
    });

    const submitForm = async () => {      

      // Unique Code 중복 검사
      const uniqueCodes = items.value.map(item => item.uniquecode);
      const hasDuplicate = uniqueCodes.some((code, index) => uniqueCodes.indexOf(code) !== index);

      if (hasDuplicate) {
        alert("There is same Unique Code. Please Check it.");
        isProcessing.value = false;
        return;
      }

      const allUniqueCodesChecked = items.value.every(item => item.uniqueCodeOk);

      if (!allUniqueCodesChecked) {
        alert("Please check unique code.");
        isProcessing.value = false;
        return;
      }

      isProcessing.value = true;
      saveStatus.value = 'Processing...';

      for (const item of items.value) {

        const filteredItem = { ...item };
        delete filteredItem.uniqueCodeOk;
        delete filteredItem.uniqueCodeAvailable;  

        const columnMapping = getColumnMapping(undefined, { ...formInputs, ...filteredItem });
        const formData = makeFormData(undefined, { ...formInputs, ...filteredItem }, dbTableName, columnMapping);

        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        }
      }

      isProcessing.value = false;
      closeModal();
    };

    const addItem = () => { items.value.push({ uniquecode: '', uniqueCodeOk: false, uniqueCodeAvailable: '' }); }; 

    const checkUniqueCode = async (uniquecode, item) => {
      try {
        const response = await axios.get('/api/targetList', { params: { tableName: dbTableName, uniquecode } });
        if (response.data.length > 0) {          
          item.uniqueCodeOk = false;
          item.uniqueCodeAvailable = "Database have same Unique Code. Please change Unique Code."
        } else {          
          item.uniqueCodeOk = true;
          item.uniqueCodeAvailable = "Unique Code is available."
        }
      } catch (error) {
        console.error('Error during unique code check:', error);
      }
    };  

    const closeModal = () => { emit("onClose"); };

    const loadLines = async () => {
        uniqueLines.value = await fetchData('flowchart_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines();  

    return {
      dbTableName,
      formInputs,
      submitForm,
      closeModal,
      isProcessing,
      uniqueLines,
      items,
      addItem,
      saveStatus,
      checkUniqueCode
    };
  },
};
</script>
      
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }     
    .modal-wrapper { width: 100%;  max-width: 1100px; }  
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }  
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
    .modal-header h3 { font-size: 1.2rem; }
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
        
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }  
     
    .itemInput { margin-left: 5px; margin-right: 5px; } 
    .symbolInput { margin-left: 5px; margin-right: 5px; width: 100px;} 
    .symbolSelect { margin-left: 5px; margin-right: 5px; width: 100px;} 
    .revisionInput { width: 50px; size: 8px; margin-left: 10px; }          
    .contentsInput { margin-left: 5px; margin-right: 5px; width: 550px; } 
    .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
    .lineTable {width: 600px;}
    .lineTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; width: 100px !important;}
    .lineTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
    .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
   
    .disabled { background-color: #ccc; cursor: not-allowed; }
    .addLineButton {margin-right: 20px; padding: 5px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }    
    .uniquecode {color: red;}
    .uniqueButton { padding: 5px 5px; background-color: #fad369; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
</style>