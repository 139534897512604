import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import { targets, lineData } from '../../lineData';

export const createSearchReportUtils = (tableNames) => {
    const state = reactive({
        data: {},        
        startYear: ref(''),
        startMonth: ref(''),
        startDay: ref(''),
        endYear: ref(''),
        endMonth: ref(''),
        endDay: ref('')
    });

    tableNames.forEach(tableName => {
        state.data[tableName] = reactive({ data: [] });
    });
    
    const fetchData = async (tableGroup, dateRange) => {
        if (!dateRange || !dateRange.startYear || !dateRange.startMonth || !dateRange.startDay || !dateRange.endYear || !dateRange.endMonth || !dateRange.endDay) {
            throw new Error('Invalid date range provided');
        }

        const formData = {
            tableNames: tableGroup.join(','),
            startYear: dateRange.startYear,
            startMonth: dateRange.startMonth,
            startDay: dateRange.startDay,
            endYear: dateRange.endYear,
            endMonth: dateRange.endMonth,
            endDay: dateRange.endDay
        };

        try {
            const res = await axios.get('/api/searchPeriod', { params: formData });
            const responseData = res.data;

            responseData.forEach(tableData => {
                const { tableName, data } = tableData;
                state.data[tableName].data = data || [];                
            });
        } catch (error) {
            console.error("Error fetching table data:", error.message);
        }
    };

    const fetchAllData = async (dateRange) => {
        if (!dateRange) {
            throw new Error('Date range parameter is required for fetchAllData');
        }

        // 테이블 이름을 그룹으로 나누어 각 그룹별로 fetchData를 호출합니다.
        const tableGroups = [];
        for (let i = 0; i < tableNames.length; i += 12) {
            tableGroups.push(tableNames.slice(i, i + 12));
        }

        for (const tableGroup of tableGroups) {
            await fetchData(tableGroup, dateRange);
        }
    };

    const getDataByType = (type) => {
        const dataByType = {};
        Object.keys(state.data).forEach((tableName) => {
            if (tableName.endsWith(type)) {
                dataByType[tableName] = state.data[tableName].data;
            }
        });
        return dataByType;
    };  

    const calculateRowData = (lineConfig, data, tableName, isFakeNgIncluded) => {
        const result = {};
        const total = {
            target: 0, input: 0, ok: 0, ng: 0, work_minute: 0, time: 0,
            ok_a: 0, ok_b: 0, ok_c: 0, ok_d: 0, oktotal: 0,
            ng_3p: 0, ng_3p_2: 0, ng_3p_3: 0, ng_2p: 0, ngtotal: 0,
            fakeSum: 0,
            ok_busbarassembly: 0, ok_pcs: 0, ok_leaktest: 0, ok_harnessassembly: 0, ok_eol: 0,
        };
    
        lineConfig.ng_types.forEach(ng_type => {
            total[ng_type] = 0;
        });
    
        if (isFakeNgIncluded) {
            lineConfig.fn_types.forEach(fn_type => {
                total[fn_type] = 0;
            });
        }
    
        data.forEach(row => {
            const model = row.model;
            if (!result[model]) {
                result[model] = {
                    target: 0, input: 0, ok: 0, ng: 0, work_minute: 0, time: 0,
                    ok_a: 0, ok_b: 0, ok_c: 0, ok_d: 0, oktotal: 0,
                    ng_3p: 0, ng_3p_2: 0, ng_3p_3: 0, ng_2p: 0, ngtotal: 0,
                    fakeSum: 0,
                    ok_busbarassembly: 0, ok_pcs: 0, ok_leaktest: 0, ok_harnessassembly: 0, ok_eol: 0,
                };
    
                lineConfig.ng_types.forEach(ng_type => {
                    result[model][ng_type] = 0;
                });
    
                if (isFakeNgIncluded) {
                    lineConfig.fn_types.forEach(fn_type => {
                        result[model][fn_type] = 0;
                    });
                }
            }
    
            result[model].target += row.target || 0;
            result[model].ok += row.ok || 0;
            result[model].ng += row.ng || 0;
            result[model].work_minute += row.work_minute || 0;
            result[model].time += row.time || 0;
            result[model].ok_a += row.ok_a || 0;
            result[model].ok_b += row.ok_b || 0;
            result[model].ok_c += row.ok_c || 0;
            result[model].ok_d += row.ok_d || 0;
            result[model].ok_busbarassembly += row.ok_busbarassembly || 0;
            result[model].ok_pcs += row.ok_pcs || 0;
            result[model].ok_leaktest += row.ok_leaktest || 0;
            result[model].ok_harnessassembly += row.ok_harnessassembly || 0;
            result[model].ok_eol += row.ok_eol || 0;
            result[model].oktotal = result[model].ok_a + result[model].ok_b + result[model].ok_c + result[model].ok_d;
    
            if (tableName.toLowerCase().includes('thermistor')) {
                result[model].input = result[model].oktotal + result[model].ng;
            } else {
                result[model].input = result[model].ok + result[model].ng;
            }
    
            total.target += row.target || 0;
            total.ok += row.ok || 0;
            total.ng += row.ng || 0;
            total.work_minute += row.work_minute || 0;
            total.time += row.time || 0;
            total.ok_a += row.ok_a || 0;
            total.ok_b += row.ok_b || 0;
            total.ok_c += row.ok_c || 0;
            total.ok_d += row.ok_d || 0;
            total.ng_3p += row.ng_3p || 0;
            total.ng_3p_2 += row.ng_3p_2 || 0;
            total.ng_3p_3 += row.ng_3p_3 || 0;
            total.ng_2p += row.ng_2p || 0;
            total.ok_busbarassembly += row.ok_busbarassembly || 0;
            total.ok_pcs += row.ok_pcs || 0;
            total.ok_leaktest += row.ok_leaktest || 0;
            total.ok_harnessassembly += row.ok_harnessassembly || 0;
            total.ok_eol += row.ok_eol || 0;
            total.oktotal = total.ok_a + total.ok_b + total.ok_c + total.ok_d;
            total.ngtotal = total.ng_3p + total.ng_3p_2 + total.ng_3p_3 + total.ng_2p;
    
            if (tableName.toLowerCase().includes('thermistorpad')) {
                total.input = total.oktotal + total.ng;
            } else if (tableName.toLowerCase().includes('bending')) {
                total.input = total.oktotal + total.ngtotal;
            } else {
                total.input = total.ok + total.ng;
            }
    
            lineConfig.ng_types.forEach(ng_type => {
                result[model][ng_type] += row[ng_type] || 0;
                total[ng_type] += row[ng_type] || 0;
            });
    
            if (isFakeNgIncluded) {
                lineConfig.fn_types.forEach(fn_type => {
                    result[model][fn_type] += row[fn_type] || 0;
                    total[fn_type] += row[fn_type] || 0;
                });
                result[model].fakeSum = lineConfig.fn_types.reduce((sum, fn_type) => sum + result[model][fn_type], 0);                
            }
        });
    
        Object.keys(result).forEach(model => {
            result[model].ngRate = result[model].input === 0 ? 0 : ((result[model].ng / result[model].input) * 100).toFixed(1);
            if (isFakeNgIncluded) {
                result[model].fakeRate = result[model].input === 0 ? 0 : ((result[model].fakeSum / result[model].input) * 100).toFixed(1);
            }
        });
        total.ngRate = total.input === 0 ? 0 : ((total.ng / total.input) * 100).toFixed(1);
        if (isFakeNgIncluded) {
            total.fakeSum = lineConfig.fn_types.reduce((sum, fn_type) => sum + total[fn_type], 0);
            total.fakeRate = total.input === 0 ? 0 : ((total.fakeSum / total.input) * 100).toFixed(1);
        }
    
        result.total = total;
    
        return result;
    };
    
    const sums = computed(() => {
        const result = {};
        targets.forEach(target => {
            const tableNamePrefix = target.name.replace(/ /g, '').toLowerCase();
            const tableNamesFiltered = [target.pr, target.dt, target.qw].filter(Boolean);
            const isFakeNgIncluded = target.periodFakeNg === "1";
            tableNamesFiltered.forEach(tableName => {
                const data = state.data[tableName]?.data || [];
                if (!result[tableName]) {
                    result[tableName] = {};
                }
                const lineConfig = lineData[tableNamePrefix];
                if (lineConfig) {
                    const rowData = calculateRowData(lineConfig, data, tableName, isFakeNgIncluded);
                    result[tableName] = rowData;
                }
            });
        });
        return result;
    });
    

    return {
        state,
        fetchAllData,
        calculateRowData,
        getDataByType,
        sums
    };
};

export const settingValue = async () => {
    const settingData = reactive({ data: [], }); 
    const formData = { tableName: 'setting' };
    try {
        const res = await axios.get('/api/targetList', { params: formData });
        settingData.data = res.data;
    } catch (error) {
        console.error("Error fetching settings:", error.message);
    }
    return { settingData };
};
