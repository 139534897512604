<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle"><B>Construction/Equipment Inspection Report</B></h>        
      <form @submit.prevent="sendDate()" class="searchBar">
        <div class="horizontal-form">              
          <div class="start">
            <input type="text" id="startYear" v-model="year" placeholder="Year" />                 
          </div>   
          <div class="incharge">
              <input type="text" id="writer" v-model="writer" placeholder="Writer" />                        
          </div>
          <div class="incharge">
              <input type="text" id="title" v-model="title" placeholder="Title" />                        
          </div>       
          <div class="searchTd">
              <button type="submit" class="searchButton" @click="callSearchData">Search</button>                      
          </div>   
          <div class="searchTd">
              <button class="mobutton" @click="showLineModal = true">Line Inspection Report</button>
          </div> 
          <div class="searchTd">
              <button class="mobutton" @click="showUtilityModal = true">Utility Inspection Report</button>
          </div>  
          <div class="searchTd">
              <button class="mobutton" @click="showConstructionModal = true">Construction Inspection Report</button>
          </div>                                       
        </div>      
      </form>
    </div>      
    <hr>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>                
        </tr>    
        <tr v-for="row in item" :key="row.id" >   
          <td>{{ row.date }}</td>  
          <td>{{ row.type }}</td>       
          <td>{{ row.title }}</td>
          <td>{{ row.writer }}</td>          
          <td>{{ row.reportnumber }}</td>                    
          <td><button type="submit" @click="openViewModal(row)" class="viewButton">View</button></td>  
          <td>{{ row.sum_score }}</td>          
          <td>{{ row.result }}</td>
          <td>{{ row.doneDate }}</td>
          <td class="fileaddress-cell">{{  getFileName(row.filepath) }}<br>
            <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
          </td>        
          <td>{{ row.remark }}</td>
          <td><button type="submit" @click="openSendModal(row)" class="mobutton">Modify</button></td>   
          <td><button class="confirmButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="confirmClick(row)">Confirm</button></td>
          <td>{{ row.confirm }}</td>    
          <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(row)">DEL</button></td>               
      </tr>
    </table>    
    </div>    
    <lineModal  v-if="showLineModal" @onClose="showLineModal = false; callSearchData();"/> 
    <lineModifyModal  v-if="lineModifyModal" :row-id="selectedRowId" @onClose="lineModifyModal = false; callSearchData();"/>    
    <lineViewModal  v-if="lineViewModal" :row-id="selectedRowId" @onClose="lineViewModal = false; callSearchData();"/>    
    <utilityModal  v-if="showUtilityModal" @onClose="showUtilityModal = false; callSearchData();"/> 
    <constructionModal  v-if="showConstructionModal" @onClose="showConstructionModal = false; callSearchData();"/> 
    
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import lineModal from "./lineModal.vue";
import lineModifyModal from "./lineMoModal.vue";
import lineViewModal from "./lineView.vue";
import utilityModal from "./utilityModal.vue";
import constructionModal from "./constructionModal.vue";

import { deleteRow } from '../../utils.js'; 

export default {  
  data() {
    return {    
      columnMapping: {     
        year: 'year',
        month: 'month',
        day: 'day',
        finyear: 'finyear',
        finmonth: 'finmonth',
        finday: 'finday',
        type: 'type',
        title: "title",        
        writer: "writer",
        reportnumber: "reportnumber",
        score_1: 'score_1',
        score_2: 'score_2',
        score_3: 'score_3',
        score_4: 'score_4',
        score_5: 'score_5',
        score_6: 'score_6',
        sum_score: 'sum_score',
        result: 'result' ,
        remark : "remark",
        confirm: "confirm",   
        file: 'file',
        filepath: 'filepath',
        imageone: 'imageone',
        imageonepath: 'imageonepath',
        imagetwo: 'imagetwo',
        imagetwopath: 'imagetwopath'
      }
    }
  },    

  methods: {
    //openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },    
    openSendModal(row) {
      this.selectedRowId = row.id;
      if (row.type === 'Line') { this.lineModifyModal = true; } //this.utilityViewModal = false; this.constructionViewModal = false; }
      //else if (row.type === 'Utility') { this.lineViewModal = false; this.utilityViewModal = true; this.constructionViewModal = false; }
      //else if (row.type === 'Construction') { this.lineViewModal = false; this.utilityViewModal = false; this.constructionViewModal = true; }      
    },    
    openViewModal(row) {
      this.selectedRowId = row.id;      
      if (row.type === 'Line') { this.lineViewModal = true; }//this.utilityViewModal = false; this.constructionViewModal = false; }
      //else if (row.type === 'Utility') { this.lineViewModal = false; this.utilityViewModal = true; this.constructionViewModal = false; }
      //else if (row.type === 'Construction') { this.lineViewModal = false; this.utilityViewModal = false; this.constructionViewModal = true; }
    },    
    canClick() {        
      const currentUser = this.$route.meta.currentUser;        
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2;}
      else { return false;}
    },   
    downloadFile(filepath) {
      if (!filepath) { alert('No file available for download'); return; }
      window.location.href = filepath;
    },   
    getFileName(filePath) {
      if (!filePath) return ''; const parts = filePath.split('-');
      if (parts.length > 1) { return parts.slice(1).join('-'); }
      return filePath;
    },
    delRow(row) {
      if (row.filepath) { alert('First file delete and try again'); }
      else { deleteRow(this.dbTableName, row.id, this.callSearchData); }
    },
    confirmClick(row) { this.confirmSend(row); this.callSearchData(); },
   
    async confirmSend(row) {               
      const formData = {
        id: row.id,
        tableName: 'constructioninspection',
        columnMapping: this.columnMapping,
        values: {            
          year: row.year,
          month: row.month,
          day: row.day,        
          finyear: row.finyear,
          finmonth: row.finmonth,
          finday: row.finday,
          type: row.type,
          title: row.title,        
          writer: row.writer,
          reportnumber: row.reportnumber,
          score_1: row.score_1,
          score_2: row.score_2,
          score_3: row.score_3,
          score_4: row.score_4,
          score_5: row.score_5,
          score_6: row.score_6,
          sum_score: row.sum_score,            
          result: row.result,    
          remark: row.remark,
          confirm: "Confirmed", 
          file: row.file,
          filepath: row.filepath,
          imageone: row.imageone,
          imageonepath: row.imageonepath,
          imagetwo: row.imagetwo,
          imagetwopath: row.imagetwopath
        }
      };

      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

      if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
      }
      formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));      
      try {
          const response = await axios.put(`/api/materialput/${row.id}`, formData);
          console.log('Server response:', response);        
      } catch (error) {
          console.error('Error updating data:', error);
      }      
    },       
  }, 

  components: { lineModal, lineModifyModal, lineViewModal, utilityModal, constructionModal,  },    

  setup() {   
    const year = ref(); const writer = ref(); const title = ref(); 
    const dbTableName = 'constructioninspection';
    const showLineModal = ref(false);
    const showUtilityModal = ref(false);
    const showConstructionModal = ref(false);
    const lineViewModal = ref(false);
    const utilityViewModal = ref(false);
    const constructionViewModal = ref(false);
    const lineModifyModal = ref(false); 
    const headers = ['Date', 'Type', 'Title', 'Writer', 'Report Number', 'View', 'Score', 'Result', 'Done Date', 'File',
                     'Remark', 'Modify', 'Confirm', 'Confirmed', 'DEL'
    ];
    const constructionInspectionData = reactive({ data: [], })    
    
    const sendDate = async () => {const sendDateData = [year.value, writer.value, title.value]; return {sendDateData};};         
    
    const searchData = async () => {   
      
      const sendDateData = await sendDate();  
      const formData = {        
          tableName: dbTableName,
          year: sendDateData.sendDateData[0],        
          writer: sendDateData.sendDateData[1],
          title: sendDateData.sendDateData[2],          
      };  

      axios.get('/api/constructionInspection', { params: formData })
        .then((res) => {              
          constructionInspectionData.data = res.data; 
        })
        .catch((error) => {
          console.error("Error fetching target data:", error.message);
      });
    };   
    
    const item = computed(() => {
      return constructionInspectionData.data.map(item => {
        const date = `${item.day}.${item.month}.${item.year}`;
        const doneDate = `${item.finday}.${item.finmonth}.${item.finyear}`;
        return { ...item, date, doneDate };
      });
    });
    
    const callSearchData = () => { searchData(dbTableName); };   

    return { item, headers, dbTableName, callSearchData, sendDate,
             year, writer, title,              
             showLineModal, showUtilityModal, showConstructionModal,
             lineModifyModal,
             lineViewModal, utilityViewModal, constructionViewModal
    };        
  }
}
</script>

<style scoped>
/* 기본 설정 */
.pagetitle { margin-left: 10px;}
.table-container { margin-left: 10px; margin-right: 10px;}
.table {
  width: 100%;  
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 그림자 효과 */
  background-color: #ffffff; /* 밝은 배경색 */
}
.table th {
    padding: 0.75rem;  
    text-align: center; /* Center align text */
    border: 1px solid #808080;
    background-color: #dad8d8; /* 밝은 배경색 */
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }
.table td {
    padding: 0.75rem;
    text-align: center; /* Center align text */
    border: 1px solid #808080;
  }

.table td.content-cell { max-width: 600px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.effect-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.fileaddress-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.remark-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
/* 색상 행 */
.yellowRow { background-color: #ffffcc; }
.redRow { background-color: #ffcccc; }

.mobutton {
    background-color: #4CAF50; color: rgb(255, 255, 255); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}
.viewButton {
    background-color: #6137fa; color: rgb(255, 255, 255); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}
.search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
.start input,.end input { margin-right: 5px; margin-left: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px; }
.searchTd { border: 0px; }
.searchButton {
    background-color: #bebebe; color: rgb(0, 0, 0); padding: 10px 15px;
    margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
}  
.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 20px; margin-left: 20px; 
}
.fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }
.searchBar select,.searchBar input{ width : 120px; margin-right: 5px; padding : 5px; } 
.signButton { padding: 10px 10px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
              font-size: 12px; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
              margin-right: 5px; cursor: pointer; transition: background-color 0.3s; font-weight: bold; 
}
.cancelButton { padding: 5px 5px; background-color: #d78968; color: #000000; border: none; border-radius: 4px;
              font-size: 10px; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
              margin-right: 5px; cursor: pointer; transition: background-color 0.3s; font-weight: bold; 
}
.delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
}
.disabledButton { background-color: gray; cursor: not-allowed; }
.approved { background-color: rgb(0, 92, 0); color: white; font-weight: bold;}
.confirmButton { padding: 10px 15px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
                font-weight: 600; margin-top: 3px; margin-bottom: 3px; 
                cursor: pointer; transition: background-color 0.3s;        
}
.horizontal-form { margin-top: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>