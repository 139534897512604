<template>
    <div class="fcaflexpo">            
      <div class="act">               
         <h class="pagetitle">Inkatom Flexible Positive</h>         
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>   
        <button class="button" @click="showModal = true">+ Result</button>  
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>                
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCellValue(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Damaged Cap'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagedcap === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagedcap', $event)">{{ row.ng_damagedcap }}</td>
                <td v-if="header === 'Damaged Flex'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagedflex === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagedflex', $event)">{{ row.ng_damagedflex }}</td>
                <td v-if="header === 'Damaged Tape'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagedtape === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagedtape', $event)">{{ row.ng_damagedtape }}</td>                
                <td v-if="header === 'Visible Copper'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_visiblecopper === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_visiblecopper', $event)">{{ row.ng_visiblecopper }}</td>                
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> <!--need to make cal-->
                <td v-if="header === 'Assembly KLIP'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_wa_klip === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_wa_klip', $event)">{{ row.fn_wa_klip }}</td> 
                <td v-if="header === 'Assembly Orange Tape'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_wa_orangetape === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_wa_orangetape', $event)">{{ row.fn_wa_orangetape }}</td>
                <td v-if="header === 'Assembly Black Tape'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_wa_blacktape === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_wa_blacktape', $event)">{{ row.fn_wa_blacktape }}</td>
                <td v-if="header === 'Assembly Silicon Cap'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_wa_siliconcap === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_wa_siliconcap', $event)">{{ row.fn_wa_siliconcap }}</td>
                <td v-if="header === 'Assembly CAP +'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_wa_capplus === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_wa_capplus', $event)">{{ row.fn_wa_capplus }}</td>
                <td v-if="header === 'Assembly Band Cable'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_wa_bandcable === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_wa_bandcable', $event)">{{ row.fn_wa_bandcable }}</td>
                <td v-if="header === 'Lack KLIP'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lack_klip === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lack_klip', $event)">{{ row.fn_lack_klip }}</td>
                <td v-if="header === 'Lack Orange Tape'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lack_orangetape === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lack_orangetape', $event)">{{ row.fn_lack_orangetape }}</td>
                <td v-if="header === 'Lack Black Tape'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lack_blacktape === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lack_blacktape', $event)">{{ row.fn_lack_blacktape }}</td>
                <td v-if="header === 'Lack Silicon Cap'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lack_siliconcap === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lack_siliconcap', $event)">{{ row.fn_lack_siliconcap }}</td>
                <td v-if="header === 'Lack CAP +'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lack_capplus === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lack_capplus', $event)">{{ row.fn_lack_capplus }}</td>
                <td v-if="header === 'Lack Band Cable'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lack_bandcable === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lack_bandcable', $event)">{{ row.fn_lack_bandcable }}</td>                
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> <!--need to make cal-->
                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCellValue(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCellValue(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCellValue(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCellValue(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="3">Shift</th>
         <td>1 Subtotal</td>          
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_damagedcap }}</td>
          <td>{{ sums.shiftSums[1].ng_damagedflex }}</td>
          <td>{{ sums.shiftSums[1].ng_damagedtape }}</td>          
          <td>{{ sums.shiftSums[1].ng_visiblecopper }}</td>          
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ shift1FnTotal }}</td>
          <td>{{ sums.shiftSums[1].fn_wa_klip }}</td>
          <td>{{ sums.shiftSums[1].fn_wa_orangetape }}</td>
          <td>{{ sums.shiftSums[1].fn_wa_blacktape }}</td>
          <td>{{ sums.shiftSums[1].fn_wa_siliconcap }}</td>
          <td>{{ sums.shiftSums[1].fn_wa_capplus }}</td>
          <td>{{ sums.shiftSums[1].fn_wa_bandcable }}</td>
          <td>{{ sums.shiftSums[1].fn_lack_klip }}</td>
          <td>{{ sums.shiftSums[1].fn_lack_orangetape }}</td>
          <td>{{ sums.shiftSums[1].fn_lack_blacktape }}</td>
          <td>{{ sums.shiftSums[1].fn_lack_siliconcap }}</td>
          <td>{{ sums.shiftSums[1].fn_lack_capplus }}</td>
          <td>{{ sums.shiftSums[1].fn_lack_bandcable }}</td>          
          <td>{{ (( shift1FnTotal )/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2 Subtotal</td>          
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_damagedcap }}</td>
          <td>{{ sums.shiftSums[2].ng_damagedflex }}</td>
          <td>{{ sums.shiftSums[2].ng_damagedtape }}</td>          
          <td>{{ sums.shiftSums[2].ng_visiblecopper }}</td>          
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ shift2FnTotal }}</td>
          <td>{{ sums.shiftSums[2].fn_wa_klip }}</td>
          <td>{{ sums.shiftSums[2].fn_wa_orangetape }}</td>
          <td>{{ sums.shiftSums[2].fn_wa_blacktape }}</td>
          <td>{{ sums.shiftSums[2].fn_wa_siliconcap }}</td>
          <td>{{ sums.shiftSums[2].fn_wa_capplus }}</td>
          <td>{{ sums.shiftSums[2].fn_wa_bandcable }}</td>
          <td>{{ sums.shiftSums[2].fn_lack_klip }}</td>
          <td>{{ sums.shiftSums[2].fn_lack_orangetape }}</td>
          <td>{{ sums.shiftSums[2].fn_lack_blacktape }}</td>
          <td>{{ sums.shiftSums[2].fn_lack_siliconcap }}</td>
          <td>{{ sums.shiftSums[2].fn_lack_capplus }}</td>
          <td>{{ sums.shiftSums[2].fn_lack_bandcable }}</td>          
          <td>{{ (( shift2FnTotal )/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>3 Subtotal</td>          
          <td>{{ sums.shiftSums[3].target }}</td>
          <td>{{ sums.shiftSums[3].input }}</td>
          <td>{{ sums.shiftSums[3].ok }}</td>
          <td>{{ sums.shiftSums[3].ng }}</td>
          <td>{{ sums.shiftSums[3].ng_damagedcap }}</td>
          <td>{{ sums.shiftSums[3].ng_damagedflex }}</td>
          <td>{{ sums.shiftSums[3].ng_damagedtape }}</td>          
          <td>{{ sums.shiftSums[3].ng_visiblecopper }}</td>          
          <td>{{ ((sums.shiftSums[3].ng) / (sums.shiftSums[3].input) * 100).toFixed(1) }}%</td>
          <td>{{ shift3FnTotal }}</td>
          <td>{{ sums.shiftSums[3].fn_wa_klip }}</td>
          <td>{{ sums.shiftSums[3].fn_wa_orangetape }}</td>
          <td>{{ sums.shiftSums[3].fn_wa_blacktape }}</td>
          <td>{{ sums.shiftSums[3].fn_wa_siliconcap }}</td>
          <td>{{ sums.shiftSums[3].fn_wa_capplus }}</td>
          <td>{{ sums.shiftSums[3].fn_wa_bandcable }}</td>
          <td>{{ sums.shiftSums[3].fn_lack_klip }}</td>
          <td>{{ sums.shiftSums[3].fn_lack_orangetape }}</td>
          <td>{{ sums.shiftSums[3].fn_lack_blacktape }}</td>
          <td>{{ sums.shiftSums[3].fn_lack_siliconcap }}</td>
          <td>{{ sums.shiftSums[3].fn_lack_capplus }}</td>
          <td>{{ sums.shiftSums[3].fn_lack_bandcable }}</td>          
          <td>{{ (( shift3FnTotal )/(sums.shiftSums[3].input) * 100).toFixed(1) }}%</td>
        </tr>        
        <tr>
         <th colspan="2">Positive Total</th>         
          <td>{{ sums.prtotalSums.target }}</td>
          <td>{{ sums.prtotalSums.input  }}</td>
          <td class="ok" >{{ sums.prtotalSums.ok }}</td>
          <td class="ng" >{{ sums.prtotalSums.ng  }}</td>
          <td class="ng_type">{{ [ sums.prtotalSums ].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td> <!-- obj.ng_assembly 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.prtotalSums ].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td> 
          <td class="ng_type">{{ [ sums.prtotalSums ].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>           
          <td class="ng_type">{{ [ sums.prtotalSums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td> 
          <td class="ng">{{ ((sums.prtotalSums.ng ) / (sums.prtotalSums.input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ fnTotal }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_wa_blacktape }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_wa_siliconcap }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_wa_capplus }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_wa_bandcable }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lack_blacktape }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lack_siliconcap }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lack_capplus  }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lack_bandcable  }}</td>          
          <td class="fn">{{ ((fnTotal)/(sums.prtotalSums.input ) * 100).toFixed(1) }}%</td>
        </tr>         
      </table>     
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./inkatomPo_modal.vue";

export default {      
    components: { Modal, },    

    setup() {
        const lineName = "fca_flex_po"  //설비 변경시 필수 교체        
        const dbTableName = "inkatom_flex_po";  //searchData에 있는 값도 바꿔야 함       
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Target', 'Input', 'OK', 'NG', "Damaged Cap", "Damaged Flex",
                         "Damaged Tape", 'Visible Copper', 'NG Rate', 'Fake Sum', "Assembly KLIP",
                         "Assembly Orange Tape", "Assembly Black Tape", "Assembly Silicon Cap", 
                         "Assembly CAP +", "Assembly Band Cable", "Lack KLIP", "Lack Orange Tape", 
                         "Lack Black Tape", "Lack Silicon Cap", "Lack CAP +", "Lack Band Cable",                         
                         'Fake NG Rate', 'Remark', 'Work Order', 'Supervisor', 
                         'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, fn_wa_klip, fn_wa_orangetape, fn_wa_blacktape, fn_wa_siliconcap,
                                         fn_wa_capplus, fn_wa_bandcable, fn_lack_klip, fn_lack_orangetape, 
                                         fn_lack_blacktape, fn_lack_siliconcap, fn_lack_capplus, 
                                         fn_lack_bandcable, ) => {
          const input = ok + ng;
          const fakeng = fn_wa_klip + fn_wa_orangetape + fn_wa_blacktape + fn_wa_siliconcap +
                         fn_wa_capplus + fn_wa_bandcable + fn_lack_klip + fn_lack_orangetape + 
                         fn_lack_blacktape + fn_lack_siliconcap + fn_lack_capplus + fn_lack_bandcable;
          let ngRate = 0;
          let fn_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          return { input, ngRate, fakeng, fn_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(d.ok, d.ng, d.fn_wa_klip, d.fn_wa_orangetape, d.fn_wa_blacktape, d.fn_wa_siliconcap,
                                         d.fn_wa_capplus, d.fn_wa_bandcable, d.fn_lack_klip, d.fn_lack_orangetape, d.fn_lack_blacktape, d.fn_lack_siliconcap, d.fn_lack_capplus, 
                                         d.fn_lack_bandcable,);
            return { ...d, input, ngRate, fakeng, fn_ngRate, }; 
          });
        });                 

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
            console.log('Requesting data with:', {
              tablename: tableName,
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
            });

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };
            
            prTableData.data = [];

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'inkatom_flex_po':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName); settingCall();
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let prtotalSums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0,
                  ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0,
                  fn_wa_capplus: 0, fn_wa_bandcable: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0,
                  fn_lack_siliconcap: 0, fn_lack_capplus: 0, fn_lack_bandcable: 0, };
          let shiftSums = {
            1: { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0,
                  ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0,
                  fn_wa_capplus: 0, fn_wa_bandcable: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0,
                  fn_lack_siliconcap: 0, fn_lack_capplus: 0, fn_lack_bandcable: 0, }, 
          
            2: { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0,
                  ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0,
                  fn_wa_capplus: 0, fn_wa_bandcable: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0,
                  fn_lack_siliconcap: 0, fn_lack_capplus: 0, fn_lack_bandcable: 0, },

            3: { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0,
                  ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0,
                  fn_wa_capplus: 0, fn_wa_bandcable: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0,
                  fn_lack_siliconcap: 0, fn_lack_capplus: 0, fn_lack_bandcable: 0, },
          };
        

          for (const row of item.value) {
              prtotalSums.target += row.target;
              prtotalSums.input += row.input;
              prtotalSums.ok += row.ok;
              prtotalSums.ng += row.ng;
              prtotalSums.ng_damagedcap += row.ng_damagedcap;
              prtotalSums.ng_damagedflex += row.ng_damagedflex;
              prtotalSums.ng_damagedtape += row.ng_damagedtape;
              prtotalSums.ng_visiblecopper += row.ng_visiblecopper;
              prtotalSums.fn_wa_klip += row.fn_wa_klip;
              prtotalSums.fn_wa_orangetape += row.fn_wa_orangetape;
              prtotalSums.fn_wa_blacktape += row.fn_wa_blacktape;
              prtotalSums.fn_wa_siliconcap += row.fn_wa_siliconcap;
              prtotalSums.fn_wa_capplus += row.fn_wa_capplus;
              prtotalSums.fn_wa_bandcable += row.fn_wa_bandcable;
              prtotalSums.fn_lack_klip += row.fn_lack_klip;
              prtotalSums.fn_lack_orangetape += row.fn_lack_orangetape;
              prtotalSums.fn_lack_blacktape += row.fn_lack_blacktape;
              prtotalSums.fn_lack_siliconcap += row.fn_lack_siliconcap;
              prtotalSums.fn_lack_capplus += row.fn_lack_capplus;
              prtotalSums.fn_lack_bandcable += row.fn_lack_bandcable;     
          }

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].ok += row.ok;
              shiftSums[row.shift].ng += row.ng;
              shiftSums[row.shift].ng_damagedcap += row.ng_damagedcap;
              shiftSums[row.shift].ng_damagedflex += row.ng_damagedflex;
              shiftSums[row.shift].ng_damagedtape += row.ng_damagedtape;
              shiftSums[row.shift].ng_visiblecopper += row.ng_visiblecopper;
              shiftSums[row.shift].fn_wa_klip += row.fn_wa_klip;
              shiftSums[row.shift].fn_wa_orangetape += row.fn_wa_orangetape;
              shiftSums[row.shift].fn_wa_blacktape += row.fn_wa_blacktape;
              shiftSums[row.shift].fn_wa_siliconcap += row.fn_wa_siliconcap;
              shiftSums[row.shift].fn_wa_capplus += row.fn_wa_capplus;
              shiftSums[row.shift].fn_wa_bandcable += row.fn_wa_bandcable;
              shiftSums[row.shift].fn_lack_klip += row.fn_lack_klip;
              shiftSums[row.shift].fn_lack_orangetape += row.fn_lack_orangetape;
              shiftSums[row.shift].fn_lack_blacktape += row.fn_lack_blacktape;
              shiftSums[row.shift].fn_lack_siliconcap += row.fn_lack_siliconcap;
              shiftSums[row.shift].fn_lack_capplus += row.fn_lack_capplus;
              shiftSums[row.shift].fn_lack_bandcable += row.fn_lack_bandcable;                 
              }
          } 

          return { prtotalSums, shiftSums, };
        });

        const fnTotal = computed(() => {
          return (
            sums.value.prtotalSums.fn_wa_klip + sums.value.prtotalSums.fn_wa_orangetape +
            sums.value.prtotalSums.fn_wa_blacktape + sums.value.prtotalSums.fn_wa_siliconcap +
            sums.value.prtotalSums.fn_wa_capplus + sums.value.prtotalSums.fn_wa_bandcable + 
            sums.value.prtotalSums.fn_lack_klip + sums.value.prtotalSums.fn_lack_orangetape + 
            sums.value.prtotalSums.fn_lack_blacktape + sums.value.prtotalSums.fn_lack_siliconcap + 
            sums.value.prtotalSums.fn_lack_capplus + sums.value.prtotalSums.fn_lack_bandcable
          );
        });

        const shift1FnTotal = computed(() => {
          return (
            sums.value.shiftSums[1].fn_wa_klip + sums.value.shiftSums[1].fn_wa_orangetape +
            sums.value.shiftSums[1].fn_wa_blacktape + sums.value.shiftSums[1].fn_wa_siliconcap +
            sums.value.shiftSums[1].fn_wa_capplus + sums.value.shiftSums[1].fn_wa_bandcable + 
            sums.value.shiftSums[1].fn_lack_klip + sums.value.shiftSums[1].fn_lack_orangetape + 
            sums.value.shiftSums[1].fn_lack_blacktape + sums.value.shiftSums[1].fn_lack_siliconcap + 
            sums.value.shiftSums[1].fn_lack_capplus + sums.value.shiftSums[1].fn_lack_bandcable            
          );
        });

        const shift2FnTotal = computed(() => {
          return (
            sums.value.shiftSums[2].fn_wa_klip + sums.value.shiftSums[2].fn_wa_orangetape +
            sums.value.shiftSums[2].fn_wa_blacktape + sums.value.shiftSums[2].fn_wa_siliconcap +
            sums.value.shiftSums[2].fn_wa_capplus + sums.value.shiftSums[2].fn_wa_bandcable + 
            sums.value.shiftSums[2].fn_lack_klip + sums.value.shiftSums[2].fn_lack_orangetape + 
            sums.value.shiftSums[2].fn_lack_blacktape + sums.value.shiftSums[2].fn_lack_siliconcap + 
            sums.value.shiftSums[2].fn_lack_capplus + sums.value.shiftSums[2].fn_lack_bandcable      
          );
        });

        const shift3FnTotal = computed(() => {
          return (
            sums.value.shiftSums[3].fn_wa_klip + sums.value.shiftSums[3].fn_wa_orangetape +
            sums.value.shiftSums[3].fn_wa_blacktape + sums.value.shiftSums[3].fn_wa_siliconcap +
            sums.value.shiftSums[3].fn_wa_capplus + sums.value.shiftSums[3].fn_wa_bandcable + 
            sums.value.shiftSums[3].fn_lack_klip + sums.value.shiftSums[3].fn_lack_orangetape + 
            sums.value.shiftSums[3].fn_lack_blacktape + sums.value.shiftSums[3].fn_lack_siliconcap + 
            sums.value.shiftSums[3].fn_lack_capplus + sums.value.shiftSums[3].fn_lack_bandcable      
          );
        });


        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error download excel data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "PR Data");

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "data.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, 
                 item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 sums, callSearchData, downloadExcel,
                 sendDate, fnTotal, shift1FnTotal, shift2FnTotal,  shift3FnTotal,               
                 };        
    },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}

.modalButtonContainer {
  display: inline-flex;
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .qwallButton {
    padding: 5px 5px;
    background-color: #00eb91;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, .qwallButton:hover, 
.button:hover {
    background-color: #2980b9;
  }

  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input
  {
  width : 80px;  
  margin-right: 5px;
  padding : 5px;
 }

label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}

#dailyReport td {
  text-align: left;
}

.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
}

.model-Front {
  background-color: #a0eefc; /* Adjust the color as needed for 2P12S */
}

.model-Rear {
  background-color: #9887e4; /* Adjust the color as needed for 3P8S */
}

.ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.zero-value_ng {
  background-color: #fa7979; 
  color: #fa7979; 
}

.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.zero-value_ngtype {
  background-color: #fdb08d; 
  color: #fdb08d;
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.zero-value_fn {
  background-color: #ff72d5; 
  color: #ff72d5; 
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}

.zero-value_fntype {
  background-color: #ff9bcd; 
  color: #ff9bcd; 
}

.qwall {
  background-color: #66b179;  
}

.shift1 { background-color: rgb(174, 240, 147); }
.shift2 { background-color: rgba(248, 250, 145, 0.979); }


</style>