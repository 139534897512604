<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">To Do List</h>        
      <form @submit.prevent="sendData()" class="searchBar">
        <div class="horizontal-form">              
          <div class="Team">
              <select id="team" v-model="team" placeholder="Team">
                <option>Production 1st</option><option>Production 2nd</option><option>Planning</option><option>Quality</option>
                <option>Sales</option><option>Purchase</option><option>HR/HSE/GA</option><option></option>
              </select>
          </div>
          <div class="Project">
              <select id="project" v-model="project" placeholder="Project">
                <option>BMW</option><option>FCA</option><option>Ford</option><option>Renault</option><option>VW</option><option>ALL</option><option></option>
              </select>      
          </div>
          <div class="Type">
              <select id="type" v-model="type" placeholder="Type">
                <option>Productivity</option><option>Optimize manpower</option><option>NG Reduction</option><option>ETC</option><option></option>
              </select>
          </div>                  
          <div class="incharge">
              <input type="text" id="incharge" v-model="incharge" placeholder="In Charge" />                        
          </div>
          <div class="Progress">
              <select id="progress" v-model="progress" placeholder="Progress">
                <option>With out Done</option><option>Not Yet</option><option>On going</option><option>Done</option><option></option>
              </select>
          </div>
          <div class="searchTd">
              <button type="submit" @click="callSearchData">Search</button>                      
          </div>   
          <div class="searchTd">
              <button class="mobutton" @click="showModal = true">Write Task</button>                    
          </div>    
          <div class="searchTd">
              <button class="excelDownload" @click="downExcel">Down Load</button>                    
          </div>                    
        </div>      
      </form>
    </div>      
    <hr>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>                
        </tr>      
        <tr v-for="row in Item" :key="row.id" :class="row.rowClass">               
          <td>{{ row.date }}</td>       
          <td>{{ row.team }}</td>
          <td>{{ row.project }}</td>          
          <td>{{ row.type }}</td>
          <td>{{ row.line }}</td>
          <td class="content-cell">{{ row.contents }}</td>
          <td>                                    
            <img v-if="row.imageonepath && row.imageonepath !== '0'" :src="getImageUrl(row.imageonepath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imageonepath))">            
            <div v-else></div>            
          </td>
          <td>            
            <img v-if="row.imagetwopath && row.imagetwopath !== '0'" :src="getImageUrl(row.imagetwopath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imagetwopath))">            
            <div v-else></div>
          </td>
          <td><button type="submit" @click="openSendModal(row)" class="mobutton">Modify</button></td>
          <td>{{ row.incharge }}</td>
          <td>{{ row.progress }}</td>
          <td class="effect-cell" >{{ row.effect }}</td>
          <td>{{ row.duedate }}</td>
          <td>{{ row.dday }}</td>
          <td class="fileaddress-cell">{{  getFileName(row.filepath) }}<br>
            <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
          </td>
          <td class="remark-cell" >{{ row.remark }}</td>
          <td><button :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(row)">DEL</button></td>                  
      </tr>
    </table>        
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                 
    </div>    
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./toDoListModal.vue";
import modifyModal from "./toDoListMoModal.vue";
import { deleteRow, downloadExcel, } from '../utils.js'; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      canClick() {        
        const currentUser = this.$route.meta.currentUser;        
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2;}
        else { return false;}
      },  
      getImageUrl(filepath) { return filepath;},
      //getImageUrl(filepath) {return `http://localhost:3000/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `https://lsevpweb.ngrok.app/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `http://pl.lsevpweb.com/uploads/${filepath}`;},
      downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }
        window.location.href = filepath;
      },
      /*downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }        
        const downloadUrl = `/api/fileDownload?filepath=${encodeURIComponent(filepath)}`;
        window.location.href = downloadUrl;
      },*/      
      getFileName(filePath) {
        if (!filePath) return ''; const parts = filePath.split('-');
        if (parts.length > 1) { return parts.slice(1).join('-'); }
        return filePath;
      },
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); },
      delRow(row) {
        if (row.filepath || row.imageonepath || row.imagetwopath) {
          alert('First file delete and try again');
        } else {
          deleteRow(this.dbTableName, row.id, this.callSearchData);
        }
      },
      downExcel() { downloadExcel(this.Item, "toDoList.xlsx"); },
    },

    components: { Modal, modifyModal, },     

    setup() {   
        const team = ref(); const project = ref(); const type = ref(); const incharge = ref(); const progress = ref("With out Done");
        const dbTableName = 'todolist';
        const showModal = ref(false);
        const showMoModal = ref(false);      
        const headers = ['Date', 'Team', 'Project', 'Type', 'Line', 'Contents', 'Image 1', 'Image 2', 'Modify', 'In Charge', 'Progress', 'Effect', 'Due Date', 'D-Day', 'File', 'Remark', 'DEL'];
        const toDoListData = reactive({ data: [], })        
         
        const sendData = async () => {const sendDataData = [team.value, project.value, type.value, incharge.value, progress.value]; return {sendDataData};};     

        const searchData = async () => {  
          const sendDataData = await sendData();
          const formData = {        
              tableName: dbTableName,
              team: sendDataData.sendDataData[0],
              project: sendDataData.sendDataData[1],
              type: sendDataData.sendDataData[2],
              incharge: sendDataData.sendDataData[3],
              progress: sendDataData.sendDataData[4],                            
          };  

          console.log('Requesting data with:', formData);

          try {
            const response = await axios.get('/api/toDoList', { params: formData });
            const dataWithDday = response.data.map(item => {
              const dueDate = new Date(item.dueyear, item.duemonth - 1, item.dueday);
              const today = new Date();
              const timeDiff = dueDate - today;
              const dDay = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
              const date = `${item.day}.${item.month}.${item.year}`;
              const duedate = `${item.dueday}.${item.duemonth}.${item.dueyear}`;

              return { ...item, dday: dDay, date, duedate, };
            });

            toDoListData.data = dataWithDday.sort((a, b) => {
              if (a.year !== b.year) return a.year - b.year;
              if (a.month !== b.month) return a.month - b.month;
              return a.day - b.day;
            });
          } catch (error) {
            console.error("Error fetching target data:", error);
          }
        };

       const Item = computed(() => {
          return toDoListData.data.map(item => {            
            const isDone = item.progress === 'Done';
            const dDay = isDone ? "" : calculateDday(item);            
            const date = `${item.day}.${item.month}.${item.year}`;
            const duedate = `${item.dueday}.${item.duemonth}.${item.dueyear}`;            
            const rowClass = isDone ? '' : getClassByDday(dDay);
            return { ...item, dday: dDay, date, duedate, rowClass };
          });
        });

        function calculateDday(item) {
          const dueDate = new Date(item.dueyear, item.duemonth - 1, item.dueday);
          const today = new Date();
          const timeDiff = dueDate - today;
          return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        }

        function getClassByDday(dDay) {
          if (dDay < 0) return 'redRow';
          if (dDay < 4) return 'yellowRow';
          return '';
        } 

        const callSearchData = () => { searchData(dbTableName); };        
 

        return { Item, headers, dbTableName, callSearchData, showModal, showMoModal, sendData,
                 team, project, type, incharge, progress        
        };        
    }
  }
</script>

<style scoped>
/* 기본 설정 */
.pagetitle { margin-left: 10px;}
.table-container { margin-left: 10px; margin-right: 10px;}
.table {
  width: 100%;  
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 그림자 효과 */
  background-color: #ffffff; /* 밝은 배경색 */
}
.table th {
    padding: 0.75rem;  
    text-align: center; /* Center align text */
    border: 1px solid #808080;
    background-color: #dad8d8; /* 밝은 배경색 */
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }
.table td {
    padding: 0.75rem;
    text-align: center; /* Center align text */
    border: 1px solid #808080;
  }

.table td.content-cell { max-width: 600px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.effect-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.fileaddress-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.remark-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
/* 색상 행 */
.yellowRow { background-color: #ffffcc; }
.redRow { background-color: #ffcccc; }

/* 버튼 스타일 */
button {
  background-color: #b6b6b6; 
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  margin: 4px 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #696969;
} 

.mobutton {
    background-color: #4CAF50; 
    color: rgb(255, 255, 255);
    padding: 10px 15px;
    margin: 4px 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }

.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 20px; margin-left: 20px; 
}
.fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }
.searchBar select,.searchBar input{ width : 120px; margin-right: 5px; padding : 5px; } 
.yellowRow { background-color: rgb(238, 238, 184);}
.redRow {  background-color: rgb(252, 167, 167);}
.horizontal-form { margin-top: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
.excelDownload {
    background-color: #347003;  color: white;
    padding: 10px 15px; margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
  }
.excelDownload :hover { background-color: #009625; }  
</style>