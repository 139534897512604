<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">          
          <h3>Add Daily Inventory</h3>              
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
              <label>Year/Month/Day:</label>
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="searchDate.year"  />
                <input type="text" id="month" v-model="searchDate.month" />
                <input type="text" id="day" v-model="searchDate.day" />                  
              </div> 
            </div>   
             <div class="form-group">
              <label>Line:</label>
              <select id="line" v-model="formInputs.line">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
              </select>   
            </div>
            <div class="form-group">
              <label for="locationname">Location Name:</label>
              <select id="line" v-model="formInputs.location">
                <option v-for="location in locationNames" :key="location">{{ location }}</option>
              </select>                               
            </div>           
          </td>
          <td>
            <div class="form-group">
              <label for="materialcode">Material Code:</label>
              <input type="text" id="materialcode" v-model="formInputs.materialcode"/>
            </div>                
            <div class="form-group">
              <label for="materialname">Material Name:</label>
              <input type="text" id="materialname" v-model="formInputs.materialname" readonly class="readWindow" />
            </div>   
            <div class="form-group">
              <label for="quantity">Quantity:</label>
              <input type="text" id="quantity" v-model="formInputs.quantity"  />
            </div>  
            <div class="form-group">
              <label for="unit">Unit:</label>
              <select id="unit" v-model="formInputs.unit">            
                <option>EA</option><option>G</option><option>ROL</option><option>CM</option><option></option>
              </select>              
            </div>
          </td>
          </tr>
        </table>             
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                      
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, ref } from 'vue';    
  import axios from 'axios';  
  import { getCurrentDate, fetchmaterialname, fetchLine,} from "../../utils.js"; 

  export default {    
    setup(_, { emit }) {      
        const dbTableName = "dailyinventory" 
        const uniqueLines = ref([]);   
        const saveStatus = ref(''); 
        const isProcessing = ref(false);     
      
        const formInputs = reactive({        
          line: "",
          location: "",
          materialcode: "",
          materialname: "",        
          quantity: "",
          unit: "",                      
        });    

        const searchDate = reactive({ ...getCurrentDate(),});   
       
        const submitForm = async () => {

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = { 
            year: "year",
            month: "month",
            day: "day",     
            line: "line",   
            location: "location",
            materialcode: "materialcode",
            materialname: "materialname",      
            quantity: "quantity",
            unit: "unit",           
          }      

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,              
              line: formInputs.line,            
              location: formInputs.location,              
              materialcode: formInputs.materialcode,
              materialname: formInputs.materialname,            
              quantity: formInputs.quantity,
              unit: formInputs.unit,              
            }
         };
         
          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };

      const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();

      watch(() => formInputs.materialcode, () => { fetchmaterialname(formInputs);});

      const locationNames = ref([
        "F_Toyota #1 Loading",
        "F_Toyota #1 Bending",
        "F_Toyota #1 Unloading",
        "F_Frame Zone #1",
        "F_FPCB Zone #1",
        "F_Finish Product Zone #1",
        "L_Production Logistic",
      ]);

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, locationNames, saveStatus, isProcessing, uniqueLines };

    },
    props: { onSubmit: { type: Function,  required: true, }, },    
  };   
</script>
    
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 500px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; } 
  
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
    .disabled { background-color: #ccc; cursor: not-allowed; }
  </style>