<template>
  <div class="checkTarget">          
    <div class="parent">
      <table class="searchTable"> 
        <tr>
          <td class="searchTd">
            <input id="year" v-model="formInputs.year" />              
          </td>
          <td class="searchTd">
            <button class="searchButton" type="submit" @click="callSearchData">Search</button>                        
          </td>
          <td>      
            <div class="addbutton">        
              <button class="addButton" @click="showModal = true">+ Add</button>         
              <button class="excelDownload" @click="downExcel">Down Load</button>  
            </div>
          </td>
        </tr>
      </table>
    </div> 
    <table class="mainTable">
      <tr>          
        <th v-for="(header, index) in target_headers" :key="index" v-html="header" class="sticky-header"></th>          
      </tr>          
      <tr v-for="row in item" :key="row.id"> 
        <td>{{ row.line }}</td>
        <td>{{ row.grade }}</td>
        <td>{{ row.status }}</td>
        <td>{{ row.possible }}</td>
        <td>{{ row.trouble }}</td>
        <td>{{ row.delivery }}</td>
        <td>{{ row.reuse }}</td>
        <td>{{ row.goods }}</td>
        <td>{{ row.safety }}</td>
        <td>{{ row.environment }}</td>
        <td>{{ row.mtbf }}</td>
        <td>{{ row.meantime }}</td>
        <td>{{ row.mttr }}</td>
        <td>{{ row.expense }}</td>
        <td>{{ row.howold }}</td>
        <td>{{ row.remark }}</td>
        <td>{{ row.row_no }}</td>
        <td><button :class="['delButton', { disabledButton: !canClick() }]" :disabled="!canClick()" type="submit" @click="delRow(targetTableName, row.id)">DEL</button></td>                    
      </tr>            
    </table>
    <br>
    <h3>Preventive maintenance standards for each equipment class</h3>
      <table class="prestandard">
      <tr><th>Grade</th><th>Yearly</th><th>Half Yearly</th><th>Quarterly</th><th>Monthly</th><th>Weekly</th><th>Daily</th></tr>
      <tr>
        <td class="gragestandard">A</td>
        <td>-detailed inspection<br>-Replacement of parts<br>-Greece, Oil</td>
        <td></td>
        <td>-Full state check<br>-Replacement of major parts<br>-Greece, Oil<br></td>
        <td></td>
        <td>-Functional check<br>-Check the condition of major parts</td>
        <td>-Visual check<br>-Operation test<br></td>        
      </tr>
      <tr>
        <td class="gragestandard">B</td>
        <td>-detailed inspection<br>-Replacement of parts<br>-Greece, Oil</td>
        <td>-Full state check<br>-Replacement of major parts<br>-Greece, Oil<br></td>
        <td>-In-depth functional check<br>-Check of major parts replacement cycle</td>
        <td>-Functional check<br>-Check the condition of major parts</td>
        <td></td>
        <td>-Visual check<br>-Operation test<br></td>        
      </tr>
      <tr>
        <td class="gragestandard">C</td>
        <td>-detailed inspection<br>-Replacement of parts<br>-Greece, Oil</td>
        <td></td>
        <td>-Functional check<br>-Check the condition of major parts</td>
        <td></td>
        <td></td>
        <td>-Visual check<br>-Operation test<br></td>        
      </tr>
    </table>
    <br>
    <h3>ROW_NO Standard</h3>
    <table class="rownostandard">
      <tr><th>Year</th><th>Serial Number</th><th>Example</th></tr>
      <tr><td>4 digit</td><td>2 digit</td><td>202401</td></tr>      
    </table>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
  </div>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import Modal from "./machineGradeModal.vue";
import axios from "axios";
import { deleteRow,  downloadExcel, } from "../../utils.js"; 

export default {         
    components: { Modal, },        
    methods: {       
      downExcel() { downloadExcel(this.item, "machineGrade.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
      canClick() {    
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;}
        else { return false; }
      },
    },
    setup() {   
      const targetTableName = "machinegrade";            
      const target_headers = ['Line', 'Grade', 'Status of<br>Machine Run', 'Is Production psssible in<br>other machine?',
      'How about the Effect on<br>machine trouble?',
      'How abot the effect on<br>delivery?','Is the Re-Use<br>possible?',
      'How about the effect to<br>finished Goods?','How about the effect on<br>operator safety?',
      'How about the effect on<br>environment/safety?','MTBF','Mean Failure Time<br>Per Month',
      'MTTR','Expense Size','How old<br>the Machine?',      
      'Remark', 'Row No', 'DEL'
    ];
      const targetList = reactive({ data: [], })
      const formInputs = reactive({ year: "", })
      const showModal = ref(false);       

      const searchData = async () => {         

        const formData = {
          tableName: targetTableName,
          year: formInputs.year,
        };        

        axios.get('/api/targetList', { params: formData })
          .then((res) => {
            console.log("Response from /api/target:", res.data);
            targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
          })
          .catch((error) => {
            console.error("Error fetching target data:", error.message);
          });
        };

      const item = computed(() => { return targetList.data.map(d => { return { ...d, }; }); });     
      
      const callSearchData = () => { searchData(targetTableName); };            
          
      return { item, target_headers, targetTableName, showModal, formInputs, callSearchData,  };        
    }
  }
</script>

<style scoped>
  .parent { margin-left: 10px; display: flex; justify-content: space-between; }
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .searchTd { border: 0px !important;} 
  input { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; margin-bottom: 10px; } 
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; margin-bottom: 10px;
  }
  .addButton {
      padding: 5px 10px; background-color: #3498db; color: #fff; border: none; border-radius: 4px;
      font-size: 14px; font-weight: 600; margin-right: 30px; margin-bottom: 10px; cursor: pointer; transition: background-color 0.3s;
  }
  .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
  }  
  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }   

  .disabledButton { background-color: gray; cursor: not-allowed; }
  .sticky-header {
    position: sticky; top: 0; background-color: white; 
    z-index: 10; background-color: rgb(230, 230, 230) ;  border : 1px solid black;        
    text-align : center;  padding-left : 10px;  padding-right : 10px;  font-size : 12px; 
  }

  .mainTable { margin-left: 10px; font-size: 14px; width: 90%; overflow-x: auto; white-space: nowrap; border-collapse: collapse;}
  .mainTable th, .mainTable td { border: 1px solid #828282;  padding: 8px; text-align: center; }
  .rownostandard { margin-left: 10px; margin-top: 10px; border: 1px solid #ddd; background-color: #ffffff; padding: 10px;  width: 80% }
  .rownostandard th { border: 1px solid #ddd;  padding: 8px;  text-align: center; background-color: #bbbbbb;}
  .rownostandard td { border: 1px solid #ddd;  padding: 8px;  text-align: center}
  .prestandard { margin-left: 10px; margin-top: 10px; border: 1px solid #ddd; background-color: #ffffff; padding: 10px;  width: 80% }
  .prestandard th { border: 1px solid #ddd;  padding: 8px;  text-align: center; background-color: #bbbbbb;}
  .prestandard td { border: 1px solid #ddd;  padding: 8px;  text-align: left; vertical-align: top}
  .gragestandard { border: 1px solid #ddd;  padding: 8px;  text-align: center !important; vertical-align: middle !important;}
 
</style>