<template>
    <div class="ev20204060rr">
      <div class="act">
        <h class="pagetitle">EV2020 40/60kW Rear</h>       
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downExcel">Excel</button>
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>
        <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" >{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers "> <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index"  @click="editCell(dbTableName, row.id, header, $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-2P12S': row.model === '40kW Rear', 'model-3P8S': row.model === '60kW Rear'}" @click="editCell(dbTableName, row.id, header, $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCell(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok"  @click="editCell(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>                
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }"  @click="editCell(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Busbar Assembly'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_busbarassembly === 0 }"  @click="editCell(dbTableName, row.id, 'ng_busbarassembly', $event)">{{ row.ng_busbarassembly }}</td>
                <td v-if="header === 'Heat Stacking'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_heatstacking === 0 }"  @click="editCell(dbTableName, row.id, 'ng_heatstacking', $event)">{{ row.ng_heatstacking }}</td>
                <td v-if="header === 'EOL'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eol === 0 }"  @click="editCell(dbTableName, row.id, 'ng_eol', $event)">{{ row.ng_eol }}</td>
                <td v-if="header === 'Laser Welding'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_laserwelding === 0 }"  @click="editCell(dbTableName, row.id, 'ng_laserwelding', $event)">{{ row.ng_laserwelding }}</td>
                <td v-if="header === 'Slot'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_slot === 0 }"  @click="editCell(dbTableName, row.id, 'ng_slot', $event)">{{ row.ng_slot }}</td>
                <td v-if="header === 'Hole Position JIG'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_holepositionjig === 0 }"  @click="editCell(dbTableName, row.id, 'ng_holepositionjig', $event)">{{ row.ng_holepositionjig }}</td>
                <td v-if="header === 'Final Inspection'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_finalinspection === 0 }"  @click="editCell(dbTableName, row.id, 'ng_finalinspection', $event)">{{ row.ng_finalinspection }}</td>
                <td v-if="header === 'Others'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_others === 0 }"  @click="editCell(dbTableName, row.id, 'ng_others', $event)">{{ row.ng_others }}</td>                
                <td v-if="header === 'Welding Peel Test'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_weldingpeeltest === 0 }"  @click="editCell(dbTableName, row.id, 'ng_weldingpeeltest', $event)">{{ row.ng_weldingpeeltest }}</td>
                <td v-if="header === 'Test'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_test === 0 }"  @click="editCell(dbTableName, row.id, 'ng_test', $event)">{{ row.ng_test }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> 
                <td v-if="header === 'Heat Stacking Vision'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_heatstackingvision === 0 }"  @click="editCell(dbTableName, row.id, 'fn_heatstackingvision', $event)">{{ row.fn_heatstackingvision }}</td>                                                 
                <td v-if="header === 'Laser Vision'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_laservision === 0 }"  @click="editCell(dbTableName, row.id, 'fn_laservision', $event)">{{ row.fn_laservision }}</td>                                                 
                <td v-if="header === 'EOL Fake'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_eol === 0 }"  @click="editCell(dbTableName, row.id, 'fn_eol', $event)">{{ row.fn_eol }}</td>                                                 
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td>                
                <td v-if="header === 'Semi Hook Broken'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_hookbroken === 0 }" @click="editCell(dbTableName, row.id, 'semi_hookbroken', $event)">{{ row.semi_hookbroken }}</td>
                <td v-if="header === 'Semi FPCB Bending'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_fpcbending === 0 }" @click="editCell(dbTableName, row.id, 'semi_fpcbending', $event)">{{ row.semi_fpcbending }}</td>                
                <td v-if="header === 'Semi ETC'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_etc === 0 }" @click="editCell(dbTableName, row.id, 'semi_etc', $event)">{{ row.semi_etc }}</td>
                <td v-if="header === 'Work Order'" :key="index"  @click="editCell(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index"  @click="editCell(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index"  @click="editCell(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index"  @click="editCell(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index"  @click="editCell(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td>                 
                <td v-if="header === 'Operator Quantity'" :key="index"  @click="editCell(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCell(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index"  @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr> 
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>          
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_busbarassembly }}</td>
          <td>{{ sums.shiftSums[1].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[1].ng_eol }}</td>
          <td>{{ sums.shiftSums[1].ng_laserwelding }}</td>
          <td>{{ sums.shiftSums[1].ng_slot }}</td>
          <td>{{ sums.shiftSums[1].ng_holepositionjig }}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspection }}</td>    
          <td>{{ sums.shiftSums[1].ng_others }}</td>
          <td>{{ sums.shiftSums[1].ng_weldingpeeltest }}</td>          
          <td>{{ sums.shiftSums[1].ng_test }}</td>   
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].fakeng }}</td>            
          <td>{{ sums.shiftSums[1].fn_heatstackingvision }}</td>
          <td>{{ sums.shiftSums[1].fn_laservision }}</td>
          <td>{{ sums.shiftSums[1].fn_eol }}</td>
          <td>{{ ((sums.shiftSums[1].fakeng)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>          
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_busbarassembly }}</td>
          <td>{{ sums.shiftSums[2].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[2].ng_eol }}</td>
          <td>{{ sums.shiftSums[2].ng_laserwelding }}</td>
          <td>{{ sums.shiftSums[2].ng_slot }}</td>
          <td>{{ sums.shiftSums[2].ng_holepositionjig }}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspection }}</td>    
          <td>{{ sums.shiftSums[2].ng_others }}</td>
          <td>{{ sums.shiftSums[2].ng_weldingpeeltest }}</td>          
          <td>{{ sums.shiftSums[2].ng_test }}</td>  
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].fakeng }}</td>            
          <td>{{ sums.shiftSums[2].fn_heatstackingvision }}</td>
          <td>{{ sums.shiftSums[2].fn_laservision }}</td>
          <td>{{ sums.shiftSums[2].fn_eol }}</td>
          <td>{{ ((sums.shiftSums[2].fakeng)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr> 
         <th rowspan="2">Model</th>
         <td>40kW</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['40kW Rear'].target }}</td>
          <td>{{ sums.modelSums['40kW Rear'].input }}</td>
          <td>{{ sums.modelSums['40kW Rear'].ok - sums.qwmodelSums['40kW Rear'].ng }}</td>          
          <td>{{ sums.modelSums['40kW Rear'].ng + sums.qwmodelSums['40kW Rear'].ng }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td>{{ [ sums.modelSums['40kW Rear'],sums.qwmodelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>          
          <td>{{ ((sums.modelSums['40kW Rear'].ng + sums.qwmodelSums['40kW Rear'].ng) / (sums.modelSums['40kW Rear'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['40kW Rear'].fakeng }}</td>            
          <td>{{ sums.modelSums['40kW Rear'].fn_heatstackingvision }}</td>
          <td>{{ sums.modelSums['40kW Rear'].fn_laservision }}</td>
          <td>{{ sums.modelSums['40kW Rear'].fn_eol }}</td>
          <td>{{ ((sums.modelSums['40kW Rear'].fakeng)/(sums.modelSums['40kW Rear'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>60kW</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['60kW Rear'].target }}</td>
          <td>{{ sums.modelSums['60kW Rear'].input }}</td>
          <td>{{ sums.modelSums['60kW Rear'].ok - sums.qwmodelSums['60kW Rear'].ng }}</td>          
          <td>{{ sums.modelSums['60kW Rear'].ng + sums.qwmodelSums['60kW Rear'].ng }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td>{{ [ sums.modelSums['60kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>          
          <td>{{ ((sums.modelSums['60kW Rear'].ng + sums.qwmodelSums['60kW Rear'].ng) / (sums.modelSums['60kW Rear'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['60kW Rear'].fakeng }}</td>            
          <td>{{ sums.modelSums['60kW Rear'].fn_heatstackingvision }}</td>
          <td>{{ sums.modelSums['60kW Rear'].fn_laservision }}</td>
          <td>{{ sums.modelSums['60kW Rear'].fn_eol }}</td>
          <td>{{ ((sums.modelSums['60kW Rear'].fakeng)/(sums.modelSums['60kW Rear'].input) * 100).toFixed(1) }}%</td>
        </tr>               
        <tr>
         <th colspan="3">EV2020 40/60kW Rear Total</th>         
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok + sums.shiftSums[2].ok - sums.qwmodelSums['40kW Rear'].ng - sums.qwmodelSums['60kW Rear'].ng}}</td>                
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['40kW Rear'].ng + sums.qwmodelSums['60kW Rear'].ng }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>           
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>                             
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['40kW Rear'],sums.qwmodelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>                             
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['40kW Rear'].ng + sums.qwmodelSums['60kW Rear'].ng) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>         
          <td class="fn">{{ sums.shiftSums[1].fakeng + sums.shiftSums[2].fakeng }}</td>
          <td class="fn_type">{{ sums.shiftSums[1].fn_heatstackingvision + sums.shiftSums[2].fn_heatstackingvision }}</td>           
          <td class="fn_type">{{ sums.shiftSums[1].fn_laservision + sums.shiftSums[2].fn_laservision }}</td>           
          <td class="fn_type">{{ sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_eol }}</td>           
          <td class="fn">{{ (( sums.shiftSums[1].fakeng + sums.shiftSums[2].fakeng)/(sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
        <!-- Use header instead of qwheader -->
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCell(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index"></td>
            <td v-if="header === 'Input'" :key="index"></td>
            <td v-if="header === 'OK'" :key="index" @click="editCell(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>            
            <td v-if="header === 'NG'" :key="index" @click="editCell(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Busbar Assembly'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_busbarassembly === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_busbarassembly', $event)">{{ qw.ng_busbarassembly }}</td>
            <td v-if="header === 'Heat Stacking'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_heatstacking === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_heatstacking', $event)">{{ qw.ng_heatstacking }}</td>
            <td v-if="header === 'EOL'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_eol === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_eol', $event)">{{ qw.ng_eol }}</td>
            <td v-if="header === 'Laser Welding'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_laserwelding === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_laserwelding', $event)">{{ qw.ng_laserwelding }}</td>
            <td v-if="header === 'slot'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_slot === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_slot', $event)">{{ qw.ng_slot }}</td>
            <td v-if="header === 'Hole Position JIG'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_holepositionjig === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_holepositionjig', $event)">{{ qw.ng_holepositionjig }}</td>
            <td v-if="header === 'Final Inspection'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_finalinspection === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_finalinspection', $event)">{{ qw.ng_finalinspection }}</td>            
            <td v-if="header === 'Others'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_others === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_others', $event)">{{ qw.ng_others }}</td>            
            <td v-if="header === 'Welding Peel Test'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_weldingpeeltest === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_weldingpeeltest', $event)">{{ qw.ng_weldingpeeltest }}</td>            
            <td v-if="header === 'Test'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_test === 0 }"  @click="editCell(qwTableName, qw.id, 'ng_test', $event)">{{ qw.ng_test }}</td>            
          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCell(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" :line-name="lineName"/>
      <dtModal v-if="showdtModal" @onClose="showdtModal = false; callSearchData();" :line-name="lineName"/>         
      <drModal v-if="showdrModal" @onClose="showdrModal = false; callSearchData();" :line-name="lineName"/> 
      <qwModal v-if="showqwModal" @onClose="showqwModal = false; callSearchData();" :line-name="lineName"/> 
      <rdModal v-if="showrdModal" @onClose="showrdModal = false; callSearchData();" :line-name="lineName"/> 
  </div>
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import { downloadExcel, editCellValue} from "../../utils.js";
import { searchData, calculateSums } from "../productionUtils.js";
import { targets } from "../../lineData.js";

import Modal from "./ev2020_4060rr_prm.vue";
import qwModal from "./ev2020_4060rr_qwm.vue";
import dtModal from "../modal_downTime.vue";
import drModal from "../modal_dailyReport.vue";
import rdModal from "../modal_repeatData.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },

    methods: {
      downExcel () { downloadExcel([this.prTableData.data, this.dtTableData.data, this.drTableData.data, this.qwTableData.data], "Production Data.xlsx", [], ["PR Data", "DT Data", "DR Data", "QW Data"]);},
      editCell(tableName, id, header, event) {
          const currentValue = event.target.innerText;
          const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
          if (newValue !== null && newValue !== currentValue) {
              editCellValue(tableName, id, header, newValue, this.callSearchData);
          }
      },
    },        
    
    setup() {
        const lineName = "EV2020 4060RR";  // 설비 변경 시 필수 교체        
        const selectedLine = targets.find(target => target.name === lineName);
        if (!selectedLine) { throw new Error(`Line name "${lineName}" not found in targets`); }

        const dbTableName = selectedLine.pr;
        const dtTableName = selectedLine.dt;
        const drTableName = selectedLine.dr;
        const qwTableName = selectedLine.qw;
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly",
                         "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection',
                         'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Fake Sum', 'Heat Stacking Vision',
                         'Laser Vision', 'EOL Fake','Fake NG Rate', 'Semi Hook Broken', 'Semi FPCB Bending', 
                         'Semi ETC', 'Work Order', 'Supervisor', 'Leader', 'Technician', 'Shift Name', 
                         'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체

        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], });   

        //Repeat Data part
        const showrdModal = ref(false);  
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, heatstackingvision, laservision, fneol, ) => {
          const input = ok + ng;                    
          const fakeng = heatstackingvision + laservision + fneol;   

          let ngRate = 0;
          let fn_ngRate = 0;          
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }          
          return { input, ngRate, fakeng, fn_ngRate, };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = 
            calculateInputAndNgRate(d.ok, d.ng, d.fn_heatstackingvision, d.fn_laservision, d.fn_eol, );
            return { ...d, input, ngRate, fakeng, fn_ngRate }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const callSearchData = async () => {
          try {
            const { sendDateData } = await sendDate();
            const tableNames = [dbTableName, dtTableName, drTableName, qwTableName];
            const result = await searchData(tableNames, sendDateData);
            
            prTableData.data = result.prTableData;
            dtTableData.data = result.dtTableData;
            drTableData.data = result.drTableData;
            qwTableData.data = result.qwTableData;
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        };

        //데이터 더하기 위한 Template, shift, model 설정
        const sumsTemplate = {
          target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0,
          ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, 
          ng_weldingpeeltest: 0, ng_test: 0, fakeng: 0, fn_heatstackingvision: 0, fn_laservision: 0, fn_eol: 0,
        };

        const shiftKeys = [1, 2]; 
        const modelKeys = ["40kW Rear", "60kW Rear",];

        const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys, qwItem.value,));
        //끝

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped src="../0_formStyle/line/style_line_pr.css"></style>