import { reactive, ref, computed } from 'vue';
import axios from 'axios';

export const createReportUtils = (tableNames) => {
    const state = reactive({
        data: {},
        targetList: [],
        comments: [],
        settings: [],
        showModal: ref(false),
        searchDate: ref({ year: '', month: '', day: '' })
    });

    tableNames.forEach(tableName => {
        state.data[tableName] = reactive({ data: [] });
    });

    const fetchData = async (date) => {
        if (!date || !date.year || !date.month || !date.day) {
            throw new Error('Invalid date provided');
        }

        const formData = {
            tableNames: tableNames.join(','), 
            values: {
                year: date.year,
                month: date.month,
                day: date.day
            }
        };

        try {
            const res = await axios.get('/api/productionData', { params: formData });
            const responseData = res.data;

            responseData.forEach(tableData => {
                const { tableName, data } = tableData;
                state.data[tableName].data = data || [];  
            });
        } catch (error) {
            console.error("Error fetching table data:", error.message);
        }
    };

    const fetchAllData = async (date) => {
        if (!date) {
            throw new Error('Date parameter is required for fetchAllData');
        }
        await fetchData(date);
    };

    const fetchTargets = async () => {
        const formData = { tableName: 'target' };

        try {
            const res = await axios.get('/api/targetList', { params: formData });
            state.targetList = res.data;           
        } catch (error) {
            console.error("Error fetching target data:", error.message);
        }
    };

    const fetchComments = async (date) => {
        if (!date || !date.year || !date.month || !date.day) {
            throw new Error('Invalid date provided for fetching comments');
        }

        const formData = {
            tableName: 'dailyreportcomment',
            values: {
                year: date.year,
                month: date.month,
                day: date.day
            }
        };

        try {
            const res = await axios.get('/api/productionData', { params: formData });
            state.comments = res.data;          
        } catch (error) {
            console.error("Error fetching comments data:", error.message);
        }
    };

    const sums = computed(() => {
        const sumsObject = {};

        tableNames.forEach(tableName => {
            sumsObject[tableName] = {
                target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ok_qw: 0, ng_qw: 0
            };
        });

        tableNames.forEach(tableName => {
            state.data[tableName].data.forEach(row => {
                sumsObject[tableName].target += row.target || 0;
                sumsObject[tableName].ok += row.ok || 0;
                sumsObject[tableName].ng += row.ng || 0;
                sumsObject[tableName].work_minute += row.work_minute || 0;
                sumsObject[tableName].time += row.time || 0;
                sumsObject[tableName].ok_qw += row.ok_qw || 0;
                sumsObject[tableName].ng_qw += row.ng_qw || 0;
            });
        });        
        return sumsObject;
    });

    const calculateRowData = (targetName, targetConfig, targetItem) => {    
        const sumsObject = sums.value;
        const prData = sumsObject[targetConfig.pr] || {};
        const dtData = sumsObject[targetConfig.dt] || {};
        const qwData = sumsObject[targetConfig.qw] || {};

        const targetDetails = targetItem[targetName] || {};  

        const okTarget = prData.target || 0;
        const ok = prData.ok - (qwData.ng || 0);
        const achieve = ((ok / (prData.target || 1)) * 100).toFixed(1);
        const ngTarget = ((targetDetails.ngrate || 0) * 100).toFixed(1);
        const ng = prData.ng + (qwData.ng || 0);
        const ngRate = ((ng / (ok + ng || 1)) * 100).toFixed(1);
        const achieveNG = ((targetDetails.ngrate / (ngRate || 1)) * 10000).toFixed(1);
        const tactTimeTarget = targetDetails.real_tacttime || 0;
        const actualTactTime = (((prData.work_minute - (dtData.time || 0)) * 60) / (prData.ok || 1)).toFixed(1);
        const achieveTactTime = ((tactTimeTarget / (actualTactTime || 1)) * 100).toFixed(1);
        const downTimeTarget = (targetDetails.downtimerate || 0) * (prData.work_minute || 0);
        const downTime = dtData.time || 0;
        const achieveDownTime = ((downTimeTarget / (downTime || 1)) * 100).toFixed(1);
        const oeeAvailable = (((prData.work_minute - (dtData.time || 0)) / (prData.work_minute || 1)) * 100).toFixed(1);
        const oeeEfficiency = ((tactTimeTarget / (actualTactTime || 1)) * 100).toFixed(1);
        const oeeQuality = ((ok / ((prData.ok + prData.ng) || 1)) * 100).toFixed(1);
        const oee = ((oeeAvailable * oeeEfficiency * oeeQuality) / 10000).toFixed(1);
        const workMinute = prData.work_minute || 0;

        const comment = state.comments.find(c => c.line_name === targetName) || {};
        const problem = comment.problem;
        const countermeasure = comment.countermeasure;
        const commentID = comment.id;

        return {
            okTarget, ok, achieve, ngTarget, ng, ngRate, achieveNG, tactTimeTarget, actualTactTime,
            achieveTactTime, downTimeTarget, downTime, achieveDownTime, oee, oeeAvailable, oeeEfficiency,
            oeeQuality, workMinute, problem, countermeasure, commentID
        };
    };

    return {
        state,
        fetchAllData,
        fetchTargets,
        fetchComments,
        calculateRowData,
        sums
    };
};

export const settingValue = async () => {
    const settingData = reactive({ data: [] });
    const formData = { tableName: 'setting' };
    try {
        const res = await axios.get('/api/targetList', { params: formData });
        settingData.data = res.data;
    } catch (error) {
        console.error("Error fetching settings:", error.message);
    }
    return { settingData };
};

