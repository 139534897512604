<template>
  <div class="checkTarget">      
    <hr>
    <div class="parent">   
      <div class="horizontal-form">        
        <div class="searchTd">
          <select id="line" v-model="formInputs.line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
            <option></option>              
          </select>  
        </div>
        <div class="searchTd">
          <button class="searchButton" type="submit" @click="callSearchData">Search</button>                        
        </div>   
        <div class="searchTd">
          <div class="topButtons">        
            <button class="addButton" @click="showModal = true">+ Add</button>   
            <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
            <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
            <span class="fileName">{{ fileName }}</span>   
            <button class="excelUpload" @click="upExcel('productionclass')">Upload</button>
            <button class="excelDownload" @click="downExcel">Down Load</button>
            <button class="delButton" :class="{ disabledButton: !canDelClick() }" :disabled="!canDelClick()" @click="delAll(targetTableName, { line: formInputs.line })">ALL DEL</button>
          </div>
        </div>        
      </div>
    </div>
    <div class="table-container">      
      <table class="targetTable">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table class="targetTable"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line_name">          
          <td @click="editCell(targetTableName, row.id, 'line', $event)">{{ row.line }}</td>
          <td @click="editCell(targetTableName, row.id, 'class1', $event)">{{ row.class1 }}</td>
          <td @click="editCell(targetTableName, row.id, 'class2', $event)">{{ row.class2 }}</td>
          <td @click="editCell(targetTableName, row.id, 'remark', $event)">{{ row.remark }}</td>
          <td @click="editCell(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
          <td><button :class="['delButton', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(targetTableName, row.id)">DEL</button></td>        
      </tr>
    </tbody>     
    </table> 
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import Modal from "./productionClassModal.vue";
import axios from "axios";
import { onFileChange, uploadExcel, deleteRow, deleteAll, downloadExcel, editCellValue, fetchLine, } from "../../utils.js"; 

export default {         
    components: { Modal, },    
    data() { return { excelFile: null, fileName: "", };},
    methods: {    
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.targetItem, "selectOption.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData);},
      delAll(tableName, filters) { deleteAll(tableName, filters, this.callSearchData); },
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
           editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      },
      canDelClick() {
        const currentUser = this.$route.meta.currentUser;        
        if (currentUser) {
            return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;
        } else { return false;}
      },
    },
    setup() {   
        const targetTableName = "productionclass";            
        const target_headers = [ 'Line', 'Class1', 'Class2', 'Remark', 'ROW NO', 'DEL'];
        const formInputs = reactive({ line: "" });
        const uniqueLines = ref([]);
        const targetList = reactive({ data: [], })
        const showModal = ref(false); 

        const searchData = async () => {      

          const formData = {
            tableName: targetTableName,
            line: formInputs.line,
          };

          axios.get('/api/targetList', { params: formData })
            .then((res) => {              
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const targetItem = computed(() => { return targetList.data.map(d => { return { ...d, };});});    
        
        const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
        
        const callSearchData = () => { searchData(targetTableName); };   

        return { targetItem, targetList, target_headers, targetTableName, showModal, callSearchData, uniqueLines, formInputs };        
    }
  }
</script>

<style scoped>
  
  .parent { display: flex;  justify-content: space-between;  align-items: center; margin-left : 10px; }
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .searchTd { border: 0px !important;}   
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; margin-bottom: 10px; }   
  .topButtons { margin-left: auto;  }

  .table-container {  overflow-x: hidden;  position: relative; }
  
  .table-body { overflow: hidden; width: 100%; max-height: 350px;  overflow-y: auto; }  

  .targetTable { width: 100%; max-width: 100%; margin-bottom: 1rem;  border-collapse: collapse;  table-layout: fixed; }
  
  .targetTable td { padding: 0.75rem;  vertical-align: top; border-top: 1px solid #dee2e6; text-align: center; }

  .targetTable th {
    position: sticky; top: 0; z-index: 100; background-color: #f8f9fa; text-transform: uppercase;
    letter-spacing: 1px; text-align: center;
    font-size: 0.9rem; font-weight: 600; border-bottom: 2px solid #dee2e6; border-top: 1px solid #dee2e6;    
    padding: 0.75rem;  vertical-align: top; 
  }

  .addButton {
      padding: 5px 10px; background-color: #3498db; color: #fff; border: none; border-radius: 4px;
      font-size: 14px; font-weight: 600; margin-right: 30px; margin-bottom: 10px; cursor: pointer; transition: background-color 0.3s;
  }

  .custom-file-upload {
      display: inline-block;  padding: 4px 12px;  cursor: pointer; padding: 5px 5px;
      background-color: #c49105;  color: #ffffff; margin-left: 100px;
      border-radius: 4px; font-size: 14px; font-weight: 600;  transition: background-color 0.3s;
  }
  .custom-file-upload:hover { background-color: #f3a359; }

  .excelUpload {
      background-color: #3498db;  color: white;
      border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
      padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;    
  }
  .excelUpload:hover { background-color: #1e5173; }

  .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
    }
  .excelDownload :hover { background-color: #009625; }  
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; margin-bottom: 10px;
  }

  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }
  .horizontal-form { margin-top: 10px; margin-bottom: 10px; margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
  
</style>