<template>
  <div class="maxpowerdb">            
      <h class="pagetitle">ZYX EMPLOYEE</h>
      <div class="topButtons">
        <button class="addButton" @click="showModal = true">+ New Employee</button>      
        <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>      
        <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
        <span class="fileName">{{ fileName }}</span>        
        <button class="excelUpload" @click="upExcel('employeedata')">Upload</button>
        <button class="excelDownload" :class="{ disabledButton: !canDownLoad(row) }" :disabled="!canDownLoad(row)" @click="downExcel">Down Load</button>
      </div>
      <div class="normalTable">
          <table>
              <tr>
                  <th v-for="(header, index) in this.headers" :key="index">{{ header }}</th>
              </tr>                    
              <tr v-for="row in item" :key="row.id">                   
                  <td>{{ row.employeenumber  }}</td>    
                  <td>{{ row.oscompany }}</td>    
                  <td>{{ row.name }}</td>    
                  <td>{{ row.surname }}</td>    
                  <td>{{ row.factory }}</td> 
                  <td>{{ row.line }}</td>    
                  <td>{{ row.process }}</td>    
                  <td>{{ row.position }}</td>    
                  <td>{{ row.remark }}</td>            
                  <td><button class="modifyButton" type="submit" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="openSendModal(row)">Modify</button></td>                   
                  <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="delRow(dbTableName, row.id)">DEL</button></td>
              </tr>
          </table>                        
      </div>
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
      <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>             
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue';
import axios from "axios";
import { onFileChange, uploadExcel, deleteRow, downloadExcel } from "../../utils.js"; 

import Modal from "./newEmployee.vue";
import modifyModal from "./modifyEmployee.vue";

export default {  
data() { return { excelFile: null, fileName: "", }; }, 
methods: {
    openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
    downExcel() { downloadExcel(this.dbData.data, "Oscompany_DB.xlsx"); },
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    canClick(row) {
      const currentUser = this.$route.meta.currentUser;
      if (!currentUser) { return false; }
      switch (row.oscompany) {
          case "MAX POWER": return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 8;
          case "MEGA STAFF": return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 9;
          case "ZYX": return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 10;
          case "INKATOM": return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 19;
          default: return currentUser.id === 1;
      }
    },
    canDownLoad() {            
      const currentUser = this.$route.meta.currentUser;            
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 10;}
      else { return false; }
    },
},

components: { Modal, modifyModal },        

setup() {        
  const dbTableName = 'employeedata';
  const showModal = ref(false); 
  const showMoModal = ref(false);      

  const dbData = reactive({ data: [], });

  const headers = ['Employee Number', 'OS Company', 'First Name', 'Surname', 'Factory', 'Line', 'Process', 'Position', 'Remark', 'Modify', 'DEL'];

  const searchData = async (tableName) => {    
    const columnName = 'oscompany';    
    axios.get('/api/where', { params: { value: 'ZYX', table_name: tableName, column_name: columnName } })
      .then((res) => {dbData.data = res.data; });
  };

  const callSearchData = () => { searchData(dbTableName); };
  const item = computed(() => {return dbData.data.map(d => {return { ...d,};});});    
  
  onMounted(() => { searchData(dbTableName); });

  return { headers, dbData, showModal, item, callSearchData, dbTableName, showMoModal, }        
  
}}

</script>

<style scoped>
.topButtons { margin-left: auto;  }
.addButton { padding: 5px 5px;  background-color: #3498db; color: #fff;  border: none;  border-radius: 4px;
  font-size: 14px; font-weight: 600; margin-left: 20px; margin-top: 10px; margin-bottom: 10px; cursor: pointer;        
  transition: background-color 0.3s;        
}
.modifyButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none; border-radius: 4px;
  font-size: 11px;font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px; cursor: pointer;        
  transition: background-color 0.3s;        
}

.normalTable { width: 80%; display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; }

.normalTable td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }

.normalTable th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ;
    border : 1px solid black; text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
}

.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; }

.custom-file-upload {      
    background-color: #c49105;  color: #ffffff;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 5px 5px; transition: background-color 0.3s; margin-left : 30px; margin-bottom : 10px;    
}

.custom-file-upload:hover { background-color: #f3a359; }

.excelUpload {
    background-color: #3498db;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;    
}
.excelUpload:hover { background-color: #1e5173; }
.excelDownload {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;    
}
.excelDownload:hover { background-color: #009625; }

.delButton {
  padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
  font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
}
.disabledButton { background-color: gray; cursor: not-allowed; }

</style>