// periodReportUtils.js

import { reactive, ref, computed } from 'vue';
import axios from 'axios';

export const createPeriodReportUtils = (tableNames) => {
    const state = reactive({
        data: {},
        targetList: [],
        comments: [],
        settings: [],
        showModal: ref(false),
        startYear: ref(''),
        startMonth: ref(''),
        startDay: ref(''),
        endYear: ref(''),
        endMonth: ref(''),
        endDay: ref(''),
    });

    tableNames.forEach(tableName => {
        state.data[tableName] = reactive({ data: [] });
    });

    const fetchData = async (tableGroup, dateRange) => {
        if (!dateRange || !dateRange.startYear || !dateRange.startMonth || !dateRange.startDay || !dateRange.endYear || !dateRange.endMonth || !dateRange.endDay) {
            throw new Error('Invalid date range provided');
        }

        const formData = {
            tableNames: tableGroup.join(','),
            startYear: dateRange.startYear,
            startMonth: dateRange.startMonth,
            startDay: dateRange.startDay,
            endYear: dateRange.endYear,
            endMonth: dateRange.endMonth,
            endDay: dateRange.endDay
        };

        try {
            const res = await axios.get('/api/searchPeriod', { params: formData });
            const responseData = res.data;

            responseData.forEach(tableData => {
                const { tableName, data } = tableData;
                state.data[tableName].data = data || [];                
            });
        } catch (error) {
            console.error("Error fetching table data:", error.message);
        }
    };

    const fetchAllData = async (dateRange) => {
        if (!dateRange) {
            throw new Error('Date range parameter is required for fetchAllData');
        }

        // 테이블 이름을 그룹으로 나누어 각 그룹별로 fetchData를 호출합니다.
        const tableGroups = [];
        for (let i = 0; i < tableNames.length; i += 12) {
            tableGroups.push(tableNames.slice(i, i + 12));
        }

        for (const tableGroup of tableGroups) {
            await fetchData(tableGroup, dateRange);
        }
    };

    const fetchTargets = async () => {
        const formData = { tableName: 'target' };

        try {
            const res = await axios.get('/api/targetList', { params: formData });
            state.targetList = res.data;            
        } catch (error) {
            console.error("Error fetching target data:", error.message);
        }
    };

    const sums = computed(() => {
        const sumsObject = {};

        tableNames.forEach(tableName => {
            sumsObject[tableName] = {
                target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ok_qw: 0, ng_qw: 0
            };
        });

        tableNames.forEach(tableName => {
            state.data[tableName].data.forEach(row => {
                sumsObject[tableName].target += row.target || 0;
                sumsObject[tableName].ok += row.ok || 0;
                sumsObject[tableName].ng += row.ng || 0;
                sumsObject[tableName].work_minute += row.work_minute || 0;
                sumsObject[tableName].time += row.time || 0;
                sumsObject[tableName].ok_qw += row.ok_qw || 0;
                sumsObject[tableName].ng_qw += row.ng_qw || 0;
            });
        });
        
        return sumsObject;
    });

    const calculateRowData = (targetName, targetConfig, targetItem) => {
        const sumsObject = sums.value;
        const prData = sumsObject[targetConfig.pr] || {};
        const dtData = sumsObject[targetConfig.dt] || {};
        const qwData = sumsObject[targetConfig.qw] || {};

        const targetDetails = targetItem[targetName] || {};

        const okTarget = prData.target || 0;
        const ok = prData.ok - (qwData.ng || 0);
        const achieve = ((ok / (prData.target || 1)) * 100).toFixed(1);
        const ngTarget = ((targetDetails.ngrate || 0) * 100).toFixed(1);
        const ng = prData.ng + (qwData.ng || 0);
        const ngRate = ((ng / (ok + ng || 1)) * 100).toFixed(1);
        const achieveNG = ((targetDetails.ngrate / (ngRate || 1)) * 10000).toFixed(1);
        const tactTimeTarget = targetDetails.real_tacttime || 0;
        const actualTactTime = (((prData.work_minute - (dtData.time || 0)) * 60) / (prData.ok || 1)).toFixed(1);
        const achieveTactTime = ((tactTimeTarget / (actualTactTime || 1)) * 100).toFixed(1);
        const downTimeTarget = (targetDetails.downtimerate || 0) * (prData.work_minute || 0);
        const downTime = dtData.time || 0;
        const achieveDownTime = ((downTimeTarget / (downTime || 1)) * 100).toFixed(1);
        const oeeAvailable = (((prData.work_minute - (dtData.time || 0)) / (prData.work_minute || 1)) * 100).toFixed(1);
        const oeeEfficiency = ((tactTimeTarget / (actualTactTime || 1)) * 100).toFixed(1);
        const oeeQuality = ((ok / ((prData.ok + prData.ng) || 1)) * 100).toFixed(1);
        const oee = ((oeeAvailable * oeeEfficiency * oeeQuality) / 10000).toFixed(1);
        const workMinute = prData.work_minute || 0;
        const uph = (ok/(prData.work_minute/60)).toFixed(0);

        return {
            okTarget, ok, achieve, ngTarget, ng, ngRate, achieveNG, tactTimeTarget, actualTactTime,
            achieveTactTime, downTimeTarget, downTime, achieveDownTime, oee, oeeAvailable, oeeEfficiency,
            oeeQuality, workMinute, uph,
        };
    };

    return {
        state,
        fetchAllData,
        fetchTargets,
        calculateRowData,
        sums
    };
};

export const settingValue = async () => {
    const settingData = reactive({ data: [], }); 
    const formData = { tableName: 'setting' };
    try {
        const res = await axios.get('/api/targetList', { params: formData });
        settingData.data = res.data;
    } catch (error) {
        console.error("Error fetching settings:", error.message);
    }
    return { settingData };
};
