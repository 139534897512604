<template>
  <div class="setting">      
    <hr>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in Item" :key="row.id">     
        <template v-for="(header, index) in headers">  
          <td v-if="header === 'EXCEL DOWNLOAD'" :key="index" @click="editCellValue(tableName, row.id, 'exceldownload', $event)" >{{ row.exceldownload }}</td>
          <td v-if="header === 'DASHBOARD'" :key="index" @click="editCellValue(tableName, row.id, 'dashboard', $event)">{{ row.dashboard }}</td>          
          <td v-if="header === 'REPORT'" :key="index" @click="editCellValue(tableName, row.id, 'report', $event)">{{ row.report }}</td>          
          <td v-if="header === 'ATTENDANCE'" :key="index" @click="editCellValue(tableName, row.id, 'attendance', $event)">{{ row.attendance }}</td>          
          <td v-if="header === 'PLANNING'" :key="index" @click="editCellValue(tableName, row.id, 'planning', $event)">{{ row.planning }}</td>    
          <td v-if="header === 'SHIPPING'" :key="index" @click="editCellValue(tableName, row.id, 'shipping', $event)">{{ row.shipping }}</td>
        </template>
      </tr>
    </tbody>     
    </table>
    </div>    
    </div>
  
</template>

<script>
import { reactive, computed, onMounted } from 'vue';
import axios from "axios";

export default {         
    setup() {   
        const tableName = "setting";            
        const headers = ['EXCEL DOWNLOAD', 'DASHBOARD', 'REPORT', 'ATTENDANCE', 'PLANNING', 'SHIPPING'];
        const settingList = reactive({ data: [], })

        onMounted(() => { searchData(); });   

        const searchData = async () => {         

          const formData = { tableName: tableName, };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => {
              console.log("Response from /api/target:", res.data);
              settingList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const Item = computed(() => { return settingList.data.map(d => { return { ...d, }; }); });
             
        const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };      

        return { Item, editCellValue, headers, tableName,};        
    }
  }
</script>

<style scoped>
.table-container {
  overflow-x: hidden;
  position: relative;
}

.table-header,
.table-body {
  overflow: hidden;
  width: 100%;  
}

.table-body {
  max-height: 350px; /* Adjust the height to your needs */
  overflow-y: auto;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  table-layout: fixed;
}


.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f8f9fa;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* Custom table styles */
.target {
  font-family: Arial, Helvetica, sans-serif;
}

.target .table {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.target .table td {
  text-align: center;
}

.target .table tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.target .table tr:hover {
  background-color: #e9ecef;
  cursor: pointer;
}
.target hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));
  margin-bottom: 1rem;
}
</style>