import { reactive, watch, ref} from 'vue';  
import axios from 'axios';   
import { getCurrentDate, setupWatchers, fetchData, //fetchStation,
         makeFormData, getColumnMapping, getModelsForLine } from "../../../utils.js"; 
import { targets, lineData } from "../../../lineData.js";
  
export function useForm(props, emit) {        
    const selectedLine = targets.find(target => target.name === props.lineName);
    const models = ref(getModelsForLine(props.lineName, lineData));    

    const dbTableName = selectedLine.dt;        
    
    const saveStatus = ref(''); 
    const isProcessing = ref(false);         
    
    const uniqueClass1 = ref([]);
    const uniqueClass2 = ref([]);
    const uniqueStations = ref([]);        
    
    const formInputs = reactive({
      shift: "", model: "", start_time: "", end_time: "", time: "",
      class1: "", class2: "", class3: "", detail: "",
    });

    const { startHour, startMinute, endHour, endMinute } = setupWatchers(formInputs);
  
    const searchDate = reactive({ ...getCurrentDate(), });      

    const loadStations = async (line) => { uniqueStations.value = await fetchData('machinedata', 'station', [{ column: 'line', value: line }]);};
    loadStations(props.lineName);

    const loadClass1 = async (line) => { uniqueClass1.value  = await fetchData('productionclass', 'class1', [{ column: 'line', value: line }]); };
    loadClass1(props.lineName);

    const loadClass2 = async (line, class1) => { uniqueClass2.value  = await fetchData('productionclass', 'class2', [{ column: 'line', value: line }, { column: 'class1', value: class1 }]); };
    watch(() => formInputs.class1, (newClass) => { loadClass2(props.lineName, newClass); }); 
          
    const submitForm = async () => {
      if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
        alert("Please fill in all the fields: year, month, day and model.");
        return;
      }

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';

      const columnMapping = getColumnMapping(searchDate, formInputs);

      const formData = makeFormData(searchDate, formInputs, dbTableName, columnMapping);  

      try {      
        await axios.post('/api/post', formData);
        saveStatus.value = 'Completed';
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }         
      closeModal();
    };

    const closeModal = () => { emit("onClose"); };        
      
    return { searchDate, dbTableName, formInputs, submitForm, closeModal,
              startHour, startMinute, endHour, endMinute,
              uniqueStations, uniqueClass1, uniqueClass2,
              saveStatus, isProcessing, models
    };
}