<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Production Spare List</h>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">                
          <div class="searchTd">
            <input class="word" type="text" id="word" v-model="word" placeholder="Search word" />
          </div>
          <div class="searchTd">
            <input class="maker" type="text" id="maker" v-model="maker" placeholder="Maker" />
          </div>
          <div class="searchTd">                          
              <input class="radio" type="radio" id="1st" value="1" v-model="formInputs.factory">
              <label for="1st">1st Factory</label>
              <input class="radio"  type="radio" id="2nd" value="2" v-model="formInputs.factory">
              <label for="2nd">2nd Factory</label>
              <input class="radio"  type="radio" id="allfactory" value="allfactory" v-model="formInputs.factory">
              <label for="allfactory">ALL Factory</label>
          </div>
          <div> / </div>
          <div class="searchTd">                          
              <input class="radio" type="radio" id="all" value="all" v-model="formInputs.need">
              <label for="all">ALL</label>
              <input class="radio"  type="radio" id="need" value="need" v-model="formInputs.need">
              <label for="need">Need Purchase</label>   
              <input class="radio"  type="radio" id="purchasing" value="purchasing" v-model="formInputs.need">
              <label for="purchasing">Purchasing</label>                    
          </div>       
          <div class="searchTd">
              <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
          </div>
          <div class="searchTd">                        
              <button class="addButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="showModal = true">+ New Spare</button>
          </div>
          <div class="searchTd">
              <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
          </div>                  
        </div>                 
        </form>  
    </div>          
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>                
        </tr>              
        <tr v-for="row in filteredItems" :key="row.id" :class="{ 'red-row': row.qty < row.limitqty, 'yellow-row': row.qty === row.limitqty }">          
        <template v-for="(header, index) in headers">  
          <td v-if="header === 'Image'" :key="index" >
            <img v-if="row.imagepath && row.imagepath !== '0'" :src="getImageUrl(row.imagepath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imagepath))">
            <template v-else></template>              
          </td>
          <td v-if="header === 'Code'" :key="index" >{{ row.code }}</td>
          <td v-if="header === 'Name'" :key="index" >{{ row.name }}</td>
          <td v-if="header === 'Specification'" :key="index" >{{ row.specification }}</td>
          <td v-if="header === 'Maker'" :key="index">{{ row.maker }}</td>
          <td v-if="header === 'QTY'" :key="index" class="qty">{{ row.qty }}</td>          
          <td v-if="header === 'Modify'" :key="index" ><button class="moButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>          
          <td v-if="header === 'Factory'" :key="index" >{{ row.factory }}</td>
          <td v-if="header === 'Location'" :key="index" >{{ row.location }}</td>          
          <td v-if="header === 'Limit QTY'" :key="index" >{{ row.limitqty }}</td>          
          <td v-if="header === 'Unit Cost(PLN)'" :key="index" >{{ row.unitcostone }}</td>
          <td v-if="header === 'Unit Cost(EUR)'" :key="index" >{{ row.unitcosttwo }}</td>          
          <td v-if="header === 'Remark'" :key="index" >{{ row.remark }}</td>   
          <td v-if="header === 'Purchase'" :key="index" >{{ row.purchase }}</td>   
          <td v-if="header === 'DEL'" :key="index"><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>
        </template>   
      </tr>
    </table>
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    </div>  
</template>

<script>
import { onBeforeUnmount, ref, reactive, computed } from 'vue';
import axios from 'axios';
import { CancelToken } from 'axios';
import Modal from './spareModal.vue';
import modifyModal from './spareMoModal.vue';
import { deleteRow, downloadExcel } from "../../utils.js"; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      canClick() {          
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 32;}
        else { return false;}
      },  
      //getImageUrl(filepath) {return `http://localhost:3000/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `https://lsevpweb.ngrok.app/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `http://pl.lsevpweb.com/uploads/${filepath}`;},
      getImageUrl(filepath) { return filepath;},
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); },
      downExcel() { downloadExcel(this.Item, "productionSpare.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    },

    components: { Modal, modifyModal, },     

    setup() {           
        const word = ref(); const maker = ref();
        const dbTableName = 'productionspare';
        const showModal = ref(false);
        const formInputs = reactive({ factory: "allfactory", need: "all", });
        const showMoModal = ref(false);      
        const headers = ['Image', "Code", 'Name', 'Specification', 'Maker', 'QTY', 'Modify', 'Factory', 'Location',
                         'Limit QTY', 'Unit Cost(PLN)', 'Unit Cost(EUR)', 'Remark', 'Purchase', 'DEL'];
        const spareData = reactive({ data: [], })        

        const sendDate = async () => {const sendDateData = [word.value, maker.value, formInputs.factory === 'allfactory' ? '' : formInputs.factory,]; return { sendDateData };};    

        const searchData = async () => {            
          const sendDateData = await sendDate();          
          const formData = {        
                tableName: dbTableName,                
                word: sendDateData.sendDateData[0],
                maker: sendDateData.sendDateData[1],
                factory: sendDateData.sendDateData[2],
            };  

          console.log('Requesting data with:', formData);

          axios.get('/api/productionSpare', { params: formData, cancelToken: source.token  })                      
               .then((res) => { spareData.data = res.data.sort((a,b) => {
                if (a.code < b.code) return -1;
                if (a.code > b.code) return 1;
                return 0;
               });
          });  
        };        
 
        const Item = computed(() => { return spareData.data.map(d => { return { ...d, }; }); });

        const filteredItems = computed(() => {
          return Item.value.filter(row => {
            if (formInputs.need === "all") {
              return true; 
            } else if (formInputs.need === "need") {
              return row.qty <= row.limitqty;
            } else if (formInputs.need === "purchasing") {
              return row.purchase === "purchasing"; 
            }
          });
        });

        const callSearchData = () => { searchData(dbTableName); };           

        const source = CancelToken.source();
        onBeforeUnmount(() => { source.cancel('Component being unmounted');});

        return { Item, headers, dbTableName, callSearchData, showModal, showMoModal,
                 word, maker, sendDate, formInputs, filteredItems
        };        
    }
  }
</script>

<style scoped> 
  .table-container { margin-left : 10px; margin-right: 10px;}
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .disabledButton { background-color: gray; cursor: not-allowed; }
  label { font-size: 17px; font-weight: 600; margin-right: 8px; }
  .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
  .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px; }
  th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
      text-align : center; padding: 10px; font-size : 15px; font-weight: 600;
  }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                      border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
  }
  .custom-file-upload:hover { background-color: #009625; }

  .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
  }
  .moButton { padding: 5px 5px; background-color: #347003; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchTd { border: 0px !important;}    
  .word { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .maker { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .factory { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  
  input::placeholder { color: gray; }
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }   
  
  .radio { margin-left: 10px; margin-right: 5px; }
  .red-row { background-color: rgb(237, 117, 117);}
  .yellow-row { background-color: rgb(238, 249, 140);}
  .qty { background-color: rgb(247, 227, 124); font-weight: bold }
  .horizontal-form { margin-left: 10px; margin-top: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>