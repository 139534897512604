<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Master Sample</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>        
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>
                  <div class="form-group date-time-group">
                    <label>Make Year/Month/Day:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.year"  />
                      <input type="text" id="month" v-model="formInputs.month" />
                      <input type="text" id="day" v-model="formInputs.day" />                  
                    </div> 
                  </div>   
                  <div class="form-group">   
                    <label>Line Name:</label>    
                    <select v-model="formInputs.line">
                      <option v-for="line in uniqueLines" :key="line">{{ line }}</option>                                         
                      <option></option>
                    </select>   
                  </div>   
                  <div class="form-group">
                    <label >Part Name:</label>
                    <input type="text" v-model="formInputs.partname"/>
                  </div>
                  <div class="form-group">
                    <label >Part Number:</label>
                    <input type="text" v-model="formInputs.partnumber"/>
                  </div>
                  <div class="form-group">
                    <label >Process:</label>
                     <select v-model="formInputs.process">
                      <option v-for="station in uniqueStations" :key="station">{{ station }}</option>
                     </select>  
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label>Type:</label>
                    <select v-model="formInputs.type">
                      <option>OK</option><option>NG</option>
                    </select>
                  </div>
                     <div class="form-group">
                    <label>Check NG Type:</label>                    
                    <textarea id="contents" v-model="formInputs.checkngtype" rows="4" cols="25"></textarea>                     
                  </div>
                  <div class="form-group">
                    <label>Code:</label>
                    <input type="text" v-model="formInputs.code"/>
                  </div>
                  <div class="form-group date-time-group">
                    <label>Expire Year/Month/Day:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.expireyear"  />
                      <input type="text" id="month" v-model="formInputs.expiremonth" />
                      <input type="text" id="day" v-model="formInputs.expireday" />                  
                    </div> 
                  </div>
                  <div class="form-group">
                    <label for="type">Label File Upload:</label>
                  </div>
                  <div>           
                    <input type="file" @change="handleFileUpload('file')($event)" />              
                    <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button><br>
                    {{ fileSaveStatus.file }}   
                  </div>                   
                </td>                                      
              </tr>
            </table>
            <button type="submit" class="saveButton" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}           
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>  
  import { reactive, ref, watch} from 'vue';  
  import axios from 'axios';   
  import { fetchLine, fetchStation, uploadFile } from "../../utils.js"; 
   
  export default {           
    setup(props, { emit }) {            
      const dbTableName = "mastersample"  
      const selectedFiles = reactive({ file: null,  imageone: null, imagetwo: null, });     
      const saveStatus = ref('');
      const fileSaveStatus = reactive({ file: '', imageone: '', imagetwo: '', });
      const isProcessing = ref(false);      
      const uploadType = 'mastersample';    
      const uniqueLines = ref([]);   
      const uniqueStations = ref([]);    
          
      const formInputs = reactive({
        year: "", month: "", day: "", line: "", partname: "", partnumber: "", process: "", type: "",
        checkngtype: "", code: "", expireyear: "", expiremonth: "", expireday: "", file: "", filepath: "",
      });        

      const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};
      
      const submitForm = async () => { 

        isProcessing.value = true;
        saveStatus.value = 'Processing...'; 

        const columnMapping = {
          year: 'year', month: 'month', day: 'day', line: 'line', partname: 'partname', partnumber: 'partnumber',
          process: 'process', type: 'type', checkngtype: 'checkngtype', code: 'code',
          expireyear: 'expireyear', expiremonth: 'expiremonth', expireday: 'expireday',
          file: 'file', filepath: 'filepath',
        }

        const formData = {
          tableName: dbTableName,
          columnMapping: columnMapping,
          values: {
            year : formInputs.year,
            month : formInputs.month,
            day : formInputs.day,
            line: formInputs.line,
            partname: formInputs.partname,
            partnumber: formInputs.partnumber,
            process: formInputs.process,
            type: formInputs.type,
            checkngtype: formInputs.checkngtype,
            code: formInputs.code,
            expireyear: formInputs.expireyear,
            expiremonth: formInputs.expiremonth,
            expireday: formInputs.expireday,
            file: formInputs.file,
            filepath: formInputs.filepath,            
          }
        };
         
        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed';
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        } finally {
          isProcessing.value = false;
        }
        
        closeModal();
      };

      const closeModal = () => { emit("onClose"); };   

      const fileUpload = (type) => uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);              

      const loadLines = async () => {
        const lines = await fetchLine();
        const excludedLines = ["EV2020 60FR", "EV2020 60RR", "EV2020 4060FR", "EV2020 4060RR", "Packing", "Production Logistic", "Maintenance", "Leader", "Technician", "Common", ];
        uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
      }; loadLines();

      const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };
      watch(() => formInputs.line, (newLine) => { loadStations(newLine); });

    return { dbTableName, formInputs, submitForm, closeModal, uniqueLines, uniqueStations,
            saveStatus, isProcessing, selectedFiles, handleFileUpload, fileUpload, fileSaveStatus,
    };

  },     

  props: { onSubmit: { type: Function, required: true, }, }, 

};
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 700px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }  

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  .saveButton {
    background-color: #4CAF50; color: white; padding: 5px 20px; border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px; display: inline-block; transition-duration: 0.4s;
  }  

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
 
  input[readonly] { background-color: #f3f3f3; color: #5c5d5f; font-weight: bold; }
  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .disabled { background-color: #ccc; cursor: not-allowed; }
</style>