<template>
    <div class="dataDelete">            
      <div class="act">               
         <h class="pagetitle">Data Delete</h>         
      </div>    
      <form class="searchBar">     
        <select id="line" v-model="formInputs.lineName">
          <option>BMW 48V</option><option>BMW 12V</option><option>FCA 1</option><option>FCA 2</option>
          <option>FCA Flex BC</option><option>FCA Flex PO</option><option>FCA Flex 67</option>
          <option>FCA Flex NE</option><option>FCA Flex 45</option>
          <option>BT6</option><option>Ford 1 Front</option><option>Ford 1 Rear</option>
          <option>Ford 2 Front</option><option>Ford 2 Rear</option><option>Ford 3 Front</option><option>Ford 3 Rear</option>
          <option>TR2 SMT</option><option>TR2 Crimping</option><option>TR2 FPCA</option><option>TR2 BFA 3P</option>
          <option>TR3 SMT</option><option>TR3 Crimping</option><option>TR3 FPCA</option><option>EVO 1</option><option>EVO 2</option><option>EVO 3</option>
          <option>EVO Hybrid</option><option>Thermistor Tape</option><option>Thermistor Pad</option><option>Bending EVO</option>
          <option>EV2020 4060 Front</option><option>EV2020 4060 Rear</option>
          <option>EV2020 60 Front</option><option>EV2020 60 Rear</option>                
          <option>INKATOM 1st</option><option>INKATOM 2nd</option>    
        </select>
        <input id="year" v-model="formInputs.year"/>                      
        <input id="month" v-model="formInputs.month"/>   
        <input id="day" v-model="formInputs.day"/>           
        <button class="searchButton" type="submit"  @click.prevent="callSearchData">Search</button>          
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in prTableData.data" :key="row.id">
            <template v-for="(header, index) in headers">  
                <td v-if="header === 'DEL'" :key="index" class="delTd"><button class="del" @click="deleteRow(dbTableName, row.id)">DEL</button></td>
                <td v-if="header === 'Shift'" :key="index">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" >{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-Front': row.model === 'Front', 'model-Rear': row.model === 'Rear'}" @click="editCellValue(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index">{{ row.target }}</td>           
                <td v-if="header === 'OK'" :key="index" class="ok" >{{ row.ok }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }">{{ row.ng }}</td>         
                <td v-if="header === 'row_no'" :key="index" >{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
            <td v-if="header === 'DEL'" :key="index" class="delTd"><button class="del" @click="deleteRow(qwTableName, qw.id)">DEL</button></td>
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" >{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index" ></td>            
            <td v-if="header === 'OK'" :key="index" >{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" >{{ qw.ng }}</td> 
          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">
              <td v-if="drheader === 'DEL'" :key="index" class="delTd"><button class="del" @click="deleteRow(drTableName, dr.id)">DEL</button></td>
              <td v-if="drheader === 'Contents'" :key="index" >{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">
                <td v-if="dtheader === 'DEL'" :key="index" class="delTd"><button  class="del" @click="deleteRow(dtTableName, dt.id)">DEL</button></td>
                <td v-if="dtheader === 'Shift'" :key="index" >{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" >{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" >{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" >{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" >{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" >{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" >{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" >{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" >{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" >{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>           
      </div>          
  </div>
 </template>

<script>

import { watch, reactive, ref, computed, } from 'vue';
import axios from "axios";

export default {       

  setup() {
      const formInputs = ref({ lineName: "", year: '', month: '', day: '', });              
        
      const tableData = ref([      
          {lineName: 'BMW 48V', dbTableName: 'bmw48vpr', dtTableName: 'bmw48vdt', drTableName: 'bmw48vdr', qwTableName: 'bmw48vqw' },
          {lineName: 'BMW 12V', dbTableName: 'bmw12vpr', dtTableName: 'bmw12vdt', drTableName: 'bmw12vdr', qwTableName: 'bmw12vqw' },
          {lineName: 'FCA 1', dbTableName: 'fca1pr', dtTableName: 'fca1dt', drTableName: 'fca1dr', qwTableName: 'fca1qw' },
          {lineName: 'FCA 2', dbTableName: 'fca2pr', dtTableName: 'fca2dt', drTableName: 'fca2dr', qwTableName: 'fca2qw' },
          {lineName: 'FCA Flex BC', dbTableName: 'fca_flex_bcpr', dtTableName: 'fca_flex_bcdt', drTableName: 'fca_flex_bcdr', qwTableName: 'fca_flex_bcqw' },
          {lineName: 'FCA Flex PO', dbTableName: 'fca_flex_popr', dtTableName: 'fca_flex_podt', drTableName: 'fca_flex_podr', qwTableName: 'fca_flex_poqw' },
          {lineName: 'FCA Flex 67', dbTableName: 'fca_flex_67pr', dtTableName: 'fca_flex_67dt', drTableName: 'fca_flex_67dr', qwTableName: 'fca_flex_67qw' },
          {lineName: 'FCA Flex NE', dbTableName: 'fca_flex_nepr', dtTableName: 'fca_flex_nedt', drTableName: 'fca_flex_nedr', qwTableName: 'fca_flex_neqw' },
          {lineName: 'FCA Flex 45', dbTableName: 'fca_flex_45pr', dtTableName: 'fca_flex_45dt', drTableName: 'fca_flex_45dr', qwTableName: 'fca_flex_45qw' },
          {lineName: 'BT6', dbTableName: 'bt6pr', dtTableName: 'bt6dt', drTableName: 'bt6dr', qwTableName: 'bt6qw' },
          {lineName: 'Ford 1 Front', dbTableName: 'ford1frpr', dtTableName: 'ford1frdt', drTableName: 'ford1frdr', qwTableName: 'ford1frqw' },
          {lineName: 'Ford 1 Rear', dbTableName: 'ford1rrpr', dtTableName: 'ford1rrdt', drTableName: 'ford1rrdr', qwTableName: 'ford1rrqw' },
          {lineName: 'Ford 2 Front', dbTableName: 'ford2frpr', dtTableName: 'ford2frdt', drTableName: 'ford2frdr', qwTableName: 'ford2frqw' },
          {lineName: 'Ford 2 Rear', dbTableName: 'ford2rrpr', dtTableName: 'ford2rrdt', drTableName: 'ford2rrdr', qwTableName: 'ford2rrqw' },
          {lineName: 'Ford 3 Front', dbTableName: 'ford3frpr', dtTableName: 'ford3frdt', drTableName: 'ford3frdr', qwTableName: 'ford3frqw' },
          {lineName: 'Ford 3 Rear', dbTableName: 'ford3rrpr', dtTableName: 'ford3rrdt', drTableName: 'ford3rrdr', qwTableName: 'ford3rrqw' },
          {lineName: 'TR2 SMT', dbTableName: 'tr2smtpr', dtTableName: 'tr2smtdt', drTableName: 'tr2smtdr', qwTableName: 'tr2smtqw' },
          {lineName: 'TR2 Crimping', dbTableName: 'tr2crimpingpr', dtTableName: 'tr2crimpingdt', drTableName: 'tr2crimpingdr', qwTableName: 'tr2crimpingqw' },
          {lineName: 'TR2 FPCA', dbTableName: 'tr2fpcapr', dtTableName: 'tr2fpcadt', drTableName: 'tr2fpcadr', qwTableName: 'tr2fpcaqw' },          
          {lineName: 'TR2 BFA 3P', dbTableName: 'tr2bfa3ppr', dtTableName: 'tr2bfa3pdt', drTableName: 'tr2bfa3pdr', qwTableName: 'tr2bfa3pqw' },
          {lineName: 'TR3 SMT', dbTableName: 'tr3smtpr', dtTableName: 'tr3smtdt', drTableName: 'tr3smtdr', qwTableName: 'tr3smtqw' },
          {lineName: 'TR3 Crimping', dbTableName: 'tr3crimpingpr', dtTableName: 'tr3crimpingdt', drTableName: 'tr3crimpingdr', qwTableName: 'tr3crimpingqw' },
          {lineName: 'TR3 FPCA', dbTableName: 'tr3fpcapr', dtTableName: 'tr3fpcadt', drTableName: 'tr3fpcadr', qwTableName: 'tr3fpcaqw' },
          {lineName: 'EVO 1', dbTableName: 'tr3bfa2ppr', dtTableName: 'tr3bfa2pdt', drTableName: 'tr3bfa2pdr', qwTableName: 'tr3bfa2pqw' },
          {lineName: 'EVO 2', dbTableName: 'tr3bfa3ppr', dtTableName: 'tr3bfa3pdt', drTableName: 'tr3bfa3pdr', qwTableName: 'tr3bfa3pqw' },
          {lineName: 'EVO 3', dbTableName: 'tr2bfa2ppr', dtTableName: 'tr2bfa2pdt', drTableName: 'tr2bfa2pdr', qwTableName: 'tr2bfa2pqw' },
          {lineName: 'EVO Hybrid', dbTableName: 'evopr', dtTableName: 'evodt', drTableName: 'evodr', qwTableName: 'evoqw' },
          {lineName: 'Thermistor Tape', dbTableName: 'thermistortapepr', dtTableName: 'thermistortapedt', drTableName: 'thermistortapedr',},
          {lineName: 'Thermistor Pad', dbTableName: 'thermistorpadpr', dtTableName: 'thermistorpaddt', drTableName: 'thermistorpaddr',},
          {lineName: 'Bending EVO', dbTableName: 'bendingevopr', dtTableName: 'bendingevodt', drTableName: 'bendingevodr',},
          {lineName: 'EV2020 4060 Front', dbTableName: 'ev2020_4060frpr', dtTableName: 'ev2020_4060frdt', drTableName: 'ev2020_4060frdr', qwTableName: 'ev2020_4060frqw' },
          {lineName: 'EV2020 4060 Rear', dbTableName: 'ev2020_4060rrpr', dtTableName: 'ev2020_4060rrdt', drTableName: 'ev2020_4060rrdr', qwTableName: 'ev2020_4060rrqw' },
          {lineName: 'EV2020 60 Front', dbTableName: 'ev2020_60frpr', dtTableName: 'ev2020_60frdt', drTableName: 'ev2020_60frdr', qwTableName: 'ev2020_60frqw' },
          {lineName: 'EV2020 60 Rear', dbTableName: 'ev2020_60rrpr', dtTableName: 'ev2020_60rrdt', drTableName: 'ev2020_60rrdr', qwTableName: 'ev2020_60rrqw' },
          {lineName: 'INKATOM 1st', dbTableName: 'inkatom1st', },
          {lineName: 'INKATOM 2nd', dbTableName: 'inkatom2nd', },
       ]);  

      watch(() => formInputs.value.lineName, () => { });

      const selectedLine = computed(() => {
            return tableData.value.find(line => line.lineName === formInputs.value.lineName);
      });

      const dbTableName = ref('');
      const dtTableName = ref('');
      const drTableName = ref('');
      const qwTableName = ref('');

      watch(formInputs, () => {
            if (selectedLine.value) {
              dbTableName.value = selectedLine.value.dbTableName;
              dtTableName.value = selectedLine.value.dtTableName;
              drTableName.value = selectedLine.value.drTableName;
              qwTableName.value = selectedLine.value.qwTableName;
            }
      }, { deep: true }); 
        
      //Production result part    
      const headers = ['DEL', 'Shift', 'Time', 'Model', 'Target', 'OK', 'NG', 'row_no']; //설비 변경시 필수 교체
      const prTableData = reactive({ data: [], });     

      //Downtime part 
      const dtHeaders = ['DEL', 'Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
      const dtTableData = reactive({ data: [], });      

      //Daily repot part
      const drHeaders = ['DEL', 'Contents']; 
      const drTableData = reactive({ data: [], });      

      //QWALL part   
      const qwTableData = reactive({ data: [], });             

      const searchData = async (tableName) => {        

            const formData = {
              tableName: tableName,
              values: {                
                year: formInputs.value.year,
                month: formInputs.value.month,
                day: formInputs.value.day,
              }
            };
            console.log('tableName:', tableName);
            console.log('Requesting data with:', formData);
            axios.get('/api/productionData', { params: formData })
              .then((res) => {                
                switch (tableName) {
                  case dbTableName.value:
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); 
                    break;
                  case dtTableName.value:
                    dtTableData.data = res.data;
                    break;
                  case drTableName.value:
                    drTableData.data = res.data;
                    break;
                  case qwTableName.value:
                    qwTableData.data = res.data;
                    break;
                }
              });
      };
      const callSearchData = () => {
            searchData(dbTableName.value);
            searchData(dtTableName.value);
            searchData(drTableName.value);
            searchData(qwTableName.value);
      };  
      
      const deleteRow = async (tableName, rowId) => {
        // Show a confirmation window and store the user's choice
        const userConfirmed = confirm("Are you sure you want to delete this row?");

        // If the user clicks "OK", proceed with the deletion
        if (userConfirmed) {
          try {
            await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });
            callSearchData();
          } catch (error) {
            console.error("Error deleting row:", error);
          }
        }
      };

      return { headers, dtHeaders, dtTableData, tableData, dbTableName, qwTableName, 
               prTableData, searchData, dtTableName, drTableName,
               drHeaders, drTableData, qwTableData, callSearchData, deleteRow,                 
               formInputs,
      };        
  },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}

.modalButtonContainer {
  display: inline-flex;
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

.qwallButton {
  padding: 5px 5px;
  background-color: #00eb91;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}
.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, .qwallButton:hover, 
.button:hover {
    background-color: #2980b9;
  }

  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input
  {
  width : 80px;  
  margin-right: 5px;
  padding : 5px;
 }

label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.delTd {
  border : 1px solid black;
  text-align : center !important;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {   width: 80%; }

#dailyReport td {
  text-align: left;
}

.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
}

.del {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }

</style>