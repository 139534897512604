<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Flow Chart</h3>
          <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="handleSubmit" :disabled="isProcessing">Save</button>
          {{ saveStatus }}
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <table class="lineTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line</td>
                <td>
                  <select v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                  </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Revision No.<br>(00)</td>
                <td>
                  <input class="revisionInput" type="text" v-model="formInputs.revision" />
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Page No.<br></td>
                <td>
                  <input class="revisionInput" type="text" v-model="formInputs.page" />
                </td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--Class Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">                
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process No.</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.process" rows="3" cols="40"></textarea></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Process Name</td>
                <td style="text-align: left;"><textarea id="contents" v-model="formInputs.process_name" rows="3" cols="40"></textarea></td>
              </tr>
            </table><br> 
                        
            <b class="uniquecode" >Unique Code: Once specified as a unique value for this item, it cannot be changed, and it must not overlap in the same Line.</b>
            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #A8D1FF; padding: 5px 5px;">Unique Code</td>
                <td colspan="2" style="text-align: left;"><input class="readonly" type="text" v-model="formInputs.uniquecode" readonly /></td>
                <td style="background-color: #A8D1FF; padding: 5px 5px;">Delete Item<br></td>
                <td colspan="4" style="text-align: left;"><input class="itemInput" type="text" v-model="formInputs.delete_item" /><br>(Write down the Revision No. when deletion began.)</td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="4" style="background-color: #F9FDA2; padding: 5px 5px;">Symbol</td>                                    
                <td style="background-color: #eaeaea; padding: 5px 5px;">Process</td>
                <td style="text-align: left;">
                  <select class="symbolInput" v-model="formInputs.symbols_col">
                    <option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option>
                  </select>
                </td>                           
                <td colspan="4" style="text-align:left" >
                  Process 1: Process, ● &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Process 5: Rework, ◇<br>
                  Process 2: Move, ⇒ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Process 6: Discard, ◆<br>
                  Process 3: Store, ▼ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Process 7: Sub Process, ●<br>
                  Process 4: Inspection, ■
                </td>                  
              </tr>                
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; padding: 5px 5px;">Connect<br>Unique Code 1</td>               
                <td style="text-align: left;"><input class="symbolInput" type="text" v-model="formInputs.end_symbol1" /></td>                  
                <td style="background-color: #eaeaea; padding: 5px 5px;">Line Type 1</td>               
                <td style="text-align: left;"><select class="symbolSelect" v-model="formInputs.line_type1"><option>solid</option><option>dashed</option><option></option></select></td>  
                <td style="background-color: #eaeaea; padding: 5px 5px;">Line Direction 1</td>               
                <td style="text-align: left;"><select class="symbolSelect" v-model="formInputs.line_direction1"><option>left</option><option>right</option><option>down</option><option></option></select></td>                  
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; padding: 5px 5px;">Connect<br>Unique Code 2</td>               
                <td style="text-align: left;"><input class="symbolInput" type="text" v-model="formInputs.end_symbol2" /></td>                  
                <td style="background-color: #eaeaea; padding: 5px 5px;">Line Type 2</td>               
                <td style="text-align: left;"><select class="symbolSelect" v-model="formInputs.line_type2"><option>solid</option><option>dashed</option><option></option></select></td>  
                <td style="background-color: #eaeaea; padding: 5px 5px;">Line Direction 2</td>               
                <td style="text-align: left;"><select class="symbolSelect" v-model="formInputs.line_direction2"><option>left</option><option>right</option><option>down</option><option></option></select></td>                  
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; padding: 5px 5px;">Connect<br>Unique Code 3</td>               
                <td style="text-align: left;"><input class="symbolInput" type="text" v-model="formInputs.end_symbol3" /></td>                  
                <td style="background-color: #eaeaea; padding: 5px 5px;">Line Type 3</td>               
                <td style="text-align: left;"><select class="symbolSelect" v-model="formInputs.line_type3"><option>solid</option><option>dashed</option><option></option></select></td>  
                <td style="background-color: #eaeaea; padding: 5px 5px;">Line Direction 3</td>               
                <td style="text-align: left;"><select class="symbolSelect" v-model="formInputs.line_direction3"><option>left</option><option>right</option><option>down</option><option></option></select></td>                  
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #F9FDA2; padding: 5px 5px;">Operation Discription</td>                  
                <td colspan="7" style="text-align: left;"><textarea id="contents" v-model="formInputs.operationdiscription" rows="3" cols="130"></textarea></td>                  
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td rowspan="2" style="background-color: #F9FDA2; padding: 5px 5px;">Significant<br>Class</td>
                <td colspan="2" style="background-color: #eaeaea; padding: 5px 5px;">Significant Product<br>Characteristics<br>(Output)</td>
                <td colspan="2" style="text-align: left;"><textarea id="contents" v-model="formInputs.significant_output" rows="3" cols="70"></textarea></td>
                <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Class<br> (KPC/QCI)</td>
                <td colspan="2" style="text-align: left;"><select class="symbolSelect" v-model="formInputs.class1"><option>O</option><option></option></select></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td colspan="2" style="background-color: #eaeaea; padding: 5px 5px;">Significant Product<br>Characteristics<br>(Iutput)</td>
                <td colspan="2" style="text-align: left;"><textarea id="contents" v-model="formInputs.significant_input" rows="3" cols="70"></textarea></td>
                <td colspan="1" style="background-color: #eaeaea; padding: 5px 5px;">Class<br> (KPC/QCI)</td>
                <td colspan="2" style="text-align: left;"><select class="symbolSelect" v-model="formInputs.class2"><option>O</option><option></option></select></td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Row No.</td>
                <td colspan="2" style="text-align: left;"><input class="itemInput" type="text" v-model="formInputs.row_no" /></td>                  
              </tr>                
            </table><br>
            
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="handleSubmit" :disabled="isProcessing">Save</button>
            {{ saveStatus }}
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { ref, watch} from 'vue';
import axios from 'axios';
import { getColumnMapping, makeFormData, fetchData, useFormInputs,} from "../utils.js";

export default {
  setup(props, { emit }) {
    const dbTableName = "flowchart";
    const isProcessing = ref(false);
    const saveStatus = ref(''); 
    const modifyorNot = ref('');
    const uniqueLines = ref([]); 

    const { formInputs, dataForm } = useFormInputs({id: ""});

    const modiFySubmitForm = async () => {  

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';

      const columnMapping = getColumnMapping(undefined, formInputs, true);   
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping, true);
      const id = formInputs.id; 

      if (!id) { alert("ID is missing"); return; }
      
      try {      
        await axios.put(`/api/materialput/${id}`, formData);
        saveStatus.value = 'Completed';
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }         
      closeModal();
    };   

    const submitForm = async () => {  

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';

      delete formInputs.id; // id 항목 제거
      const columnMapping = getColumnMapping(undefined, formInputs);   
      delete columnMapping.id; // id 항목 제거
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping);      
      
      try {      
        await axios.put(`/api/post`, formData);
        saveStatus.value = 'Completed';
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }         
      closeModal();
    };    

    const handleSubmit = () => {
      if (modifyorNot.value === formInputs.revision) {
        modiFySubmitForm();
      } else {
        submitForm();
      }
    };

    const closeModal = () => { emit("onClose"); };

    const fetchSelectedRowData = async () => {
      try {
        const response = await axios.get("/api/materialrequestadd", {
          params: { tableName: dbTableName, id: props.rowId },
        });
        const rowData = response.data[0];

        formInputs.id = rowData.id;
        formInputs.line = rowData.line;
        formInputs.revision = rowData.revision;
        formInputs.page = rowData.page;
        formInputs.process = rowData.process;
        formInputs.process_name = rowData.process_name;
        formInputs.uniquecode = rowData.uniquecode;
        formInputs.delete_item = rowData.delete_item;
        formInputs.symbols_col = rowData.symbols_col;
        formInputs.operationdiscription = rowData.operationdiscription;
        formInputs.significant_output = rowData.significant_output;
        formInputs.class1 = rowData.class1;
        formInputs.significant_input = rowData.significant_input;
        formInputs.class2 = rowData.class2;
        formInputs.row_no = rowData.row_no;
        formInputs.end_symbol1 = rowData.end_symbol1;
        formInputs.line_type1 = rowData.line_type1;
        formInputs.line_direction1 = rowData.line_direction1;
        formInputs.end_symbol2 = rowData.end_symbol2;
        formInputs.line_type2 = rowData.line_type2;
        formInputs.line_direction2 = rowData.line_direction2;
        formInputs.end_symbol3 = rowData.end_symbol3;
        formInputs.line_type3 = rowData.line_type3;
        formInputs.line_direction3 = rowData.line_direction3;        
        modifyorNot.value = rowData.revision;

      } catch (error) {
        console.error("Error fetching row data:", error.message);
      }
    };
    watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } ); 

    const loadLines = async () => {
        uniqueLines.value = await fetchData('flowchart_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines();  

    return {
      dbTableName, formInputs, submitForm, closeModal, isProcessing, uniqueLines, saveStatus, dataForm, handleSubmit
    };
  },

  props: { rowId: { type: Number, required: true, }, },
};
</script>
      
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }     
    .modal-wrapper { width: 100%;  max-width: 1200px; }  
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }  
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
    .modal-header h3 { font-size: 1.2rem; }
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
        
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }  
    
    .readonly { margin-left: 5px; margin-right: 5px; background-color: #f0f0f0;} 
    .itemInput { margin-left: 5px; margin-right: 5px; } 
    .revisionInput { width: 50px; size: 8px; margin-left: 10px; }          
    .contentsInput { margin-left: 5px; margin-right: 5px; width: 550px; } 
    .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
    .lineTable {width: 600px;}
    .lineTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; width: 100px !important;}
    .lineTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
    .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
   
    .disabled { background-color: #ccc; cursor: not-allowed; }
    .addLineButton {margin-right: 20px; padding: 5px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }    
    .uniquecode {color: red;}
</style>