export  const columnMapping = {
    year: 'year', month: 'month', day: 'day', line: 'line', shift: 'shift', time: 'time', 
    model: 'model', process: 'process', itemvalues: 'itemvalues', judgement: 'judgement',
    technician: 'technician', remark: 'remark',    
};

export const sliceNumbers = {
    tr3smt: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [4, 9], modal_4: [9, 10], modal_5: [10, 12], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    tr3crimping: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [2, 3], modal_4: [3, 4], modal_5: [4, 5], modal_6: [5, 6], modal_7: [6, 7], modal_8: [7, 8], modal_9: [8, 9], modal_10: [],},
    tr3fpca: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [2, 4], modal_4: [4, 8], modal_5: [8, 13], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    evo1: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [4, 5], modal_4: [5, 6], modal_5: [6, 10], modal_6: [10, 15], modal_7: [15, 18], modal_8: [18, 19], modal_9: [19, 23], modal_10: [23, 27] },
    evo2: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [4, 5], modal_4: [5, 6], modal_5: [6, 10], modal_6: [10, 15], modal_7: [15, 18], modal_8: [18, 19], modal_9: [19, 23], modal_10: [23, 27] },
    evo3: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [4, 5], modal_4: [5, 6], modal_5: [6, 10], modal_6: [10, 15], modal_7: [15, 18], modal_8: [18, 19], modal_9: [19, 23], modal_10: [23, 27] },
    evohybrid: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [4, 5], modal_4: [5, 6], modal_5: [6, 12], modal_6: [12, 18], modal_7: [18, 19], modal_8: [19, 26], modal_9: [], modal_10: [] },
    ev202060kw: { modal_1: [0, 1], modal_2: [1, 8], modal_3: [8, 11], modal_4: [11, 13], modal_5: [13, 16], modal_6: [16, 18], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    ev20204060kw: { modal_1: [0, 1], modal_2: [1, 8], modal_3: [8, 11], modal_4: [11, 13], modal_5: [13, 16], modal_6: [16, 18], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },

    tr2smt: { modal_1: [0, 1], modal_2: [1, 4], modal_3: [4, 9], modal_4: [9, 10], modal_5: [10, 12], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    tr2crimping: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [2, 3], modal_4: [3, 4], modal_5: [4, 5], modal_6: [5, 6], modal_7: [6, 7], modal_8: [7, 8], modal_9: [8, 9], modal_10: [] },
    tr2fpca: { modal_1: [0, 1], modal_2: [1, 2], modal_3: [2, 4], modal_4: [4, 8], modal_5: [8, 13], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    bmw12v: { modal_1: [0, 3], modal_2: [3, 5], modal_3: [5, 8], modal_4: [8, 10], modal_5: [10, 11], modal_6: [11, 13], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    bmw48v: { modal_1: [0, 2], modal_2: [2, 3], modal_3: [3, 6], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    fcaflexbc: { modal_1: [0, 5], modal_2: [], modal_3: [], modal_4: [], modal_5: [], modal_6: [], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    fca2: { modal_1: [0, 2], modal_2: [2, 3], modal_3: [3, 4], modal_4: [4, 6], modal_5: [6, 8], modal_6: [8, 10], modal_7: [10, 12], modal_8: [], modal_9: [], modal_10: [] },
    ford2front: { modal_1: [0, 2], modal_2: [2, 5], modal_3: [5, 6], modal_4: [6, 9], modal_5: [9, 11], modal_6: [11, 14], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    ford2rear: { modal_1: [0, 2], modal_2: [2, 4], modal_3: [4, 6], modal_4: [6, 7], modal_5: [7, 10], modal_6: [10, 12], modal_7: [12, 15], modal_8: [], modal_9: [], modal_10: [] },
    ford3front: { modal_1: [0, 2], modal_2: [2, 5], modal_3: [5, 6], modal_4: [6, 9], modal_5: [9, 11], modal_6: [11, 14], modal_7: [], modal_8: [], modal_9: [], modal_10: [] },
    ford3rear: { modal_1: [0, 2], modal_2: [2, 4], modal_3: [4, 6], modal_4: [6, 7], modal_5: [7, 10], modal_6: [10, 12], modal_7: [12, 15], modal_8: [], modal_9: [], modal_10: [] },
};

export const lineData = {
    tr2smt: { models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P"] },
    tr2crimping: { models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P"] },
    tr2fpca: { models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P"] },
    tr2bfa3p: { models: ["3P8S", "3P_GEN.1", "EVO_3P"] },
    tr3smt: { models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P"] },
    tr3crimping: { models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P"] },
    tr3fpca: { models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P"] },
    evo1: { models: ["2P12S", "EVO_3P"] },
    evo2: { models: ["3P8S", "EVO_3P"] },
    evo3: { models: ["2P12S", "2P_GEN.1", "EVO_3P"] },
    evohybrid: { models: ["EVO_2P", "EVO_3P"] },
    ev202060kw: { models: ["60kW"] },    
    ev20204060kw: { models: ["40kW", "60kW"] },    
    ford1front: { models: ["Front"] },
    ford1rear: { models: ["Rear"] },
    ford2front: { models: ["Front"] },
    ford2rear: { models: ["Rear"] },
    ford3front: { models: ["Front"] },
    ford3rear: { models: ["Rear"] },
    fca1: { models: ["Front", "Rear", ] },
    fca2: { models: ["Front", "Rear", ] },
    bt6: { models: ["Front", "Rear", ] },
    bmw12v: { models: ["F40 12V", "HAF", ] },  
    bmw48v: { models: ["ICB", ] }, 
    fcaflexbc: { models: ["Busbar Carrier"] },  
    fcaflexpo: { models: ["Positive"] },
    fcaflex67: { models: ["6+7-"] },
    fcaflexne: { models: ["Negative"] }, 
    fcaflex45: { models: ["4-5+"] }, 
    thermistortape: { models: ["EVO_2P", "EVO_3P"] }, 
    thermistorpad: { models: ["EVO_2P", "EVO_3P"] }, 
    bendingevo: { models: ["EVO_2P", "EVO_3P"] }, 
};
