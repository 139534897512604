<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Check Item and Target</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>
            <div class="form-group">   
              <label for="line">Line Name:</label>    
              <select id="line" v-model="formInputs.line">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
              </select>    
            </div>
            <div class="form-group">
              <label for="process">Process:</label>
              <input type="text" id="process" v-model="formInputs.process" />
            </div>
            <div class="form-group">
              <label for="process_row">Process Row:</label>
              <input type="number" id="process_row" v-model="formInputs.process_row" />
            </div>
            <div class="form-group">
              <label for="model">Model:</label>                          
              <select id="model" v-model="formInputs.model">
                <option v-for="option in models" :key="option">{{ option }}</option>
              </select> 
            </div>  
          </td>
          <td>
            <div class="form-group">
              <label for="item">Item:</label>
              <input type="text" id="item" v-model="formInputs.item" />
            </div>
            <div class="form-group">
              <label for="lowerlimit">Lower Limit:</label>
              <input type="text" id="lowerlimit" v-model="formInputs.lowerlimit" />
            </div>
            <div class="form-group">
              <label for="standardvalue">Standard Value:</label>
              <input type="text" id="standardvalue" v-model="formInputs.standardvalue" />
            </div>
            <div class="form-group">
              <label for="upperlimit">Upper Limit:</label>
              <input type="text" id="upperlimit" v-model="formInputs.upperlimit" />
            </div>
            <div class="form-group">
              <label for="unit">Unit:</label>
              <input type="text" id="unit" v-model="formInputs.unit" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="updatedate">Update Date(yyyy/mm/dd):</label>
              <input type="text" id="updatedate" v-model="formInputs.updatedate" />
            </div>
            <div class="form-group">
              <label for="acceptance">Acceptance:</label>
              <input type="text" id="acceptance" v-model="formInputs.acceptance" />
            </div>
            <div class="form-group">
              <label for="remark">Remark:</label>
              <input type="text" id="remark" v-model="formInputs.remark" />
            </div>
            <div class="form-group">
              <label for="row_no">Row No:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" />
            </div>
            </td>
            </tr>
            </table>
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>

<script>
import { useForm } from '../0_formStyle/form_checkTarget_modal';

export default {
  props: { onSubmit: { type: Function, required: true, }, }, 
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../0_formStyle/style_check_target_modal.css"></style>