<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Purchase Request</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>
            <td>
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>     
            <div class="form-group">
              <label for="costtype">Cost Type:</label>
              <div>
                <select id="costtyoe" v-model="formInputs.costtype">
                  <option>Production</option><option>Repair Cost</option><option>Assets</option><option>ETC</option>
                </select>
              </div>
            </div>  
            <div class="form-group">
              <label for="type">Type:</label>
              <div>
                <select id="type" v-model="formInputs.type">
                  <option>Spare</option><option>Repair</option><option>Manufacture</option><option>Tool</option>
                  <option>Machine</option><option>Service</option><option>Chemical</option><option>Annual</option><option>Invest</option>
                </select>
              </div>
            </div>  
            <div class="form-group">
              <label for="supplier">Supplier:</label>
              <div>
                <input type="text" id="supplier" v-model="formInputs.supplier" />                  
              </div>
            </div>                
            <div class="form-group">
              <label for="itemname">Item Name:</label>
              <div>
                <input type="text" id="itemname" v-model="formInputs.itemname" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="specification">Specification:</label>
              <div>
                <input type="text" id="specification" v-model="formInputs.specification" />                  
              </div>
            </div>  
            </td>
            <td>
            <div class="form-group">
              <label for="quantity">Quantity:</label>
              <div>
                <input type="number" id="quantity" v-model="formInputs.quantity" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="unitcost1">Unit Cost(PLN):</label>
              <div>
                <input type="number" id="unitcost1" v-model="formInputs.unitcost1" />                  
                <input type="number" id="price1" v-model="formInputs.price1" readonly class="readWindow" />  
              </div>
            </div>   
            <div class="form-group">
              <label for="unitcost2">Unit Cost(EUR):</label>
              <div>
                <input type="number" id="unitcost2" v-model="formInputs.unitcost2" />
                <input type="number" id="price2" v-model="formInputs.price2" readonly class="readWindow" />  
              </div>
            </div> 
            <div class="form-group">
              <label for="requester">Requester:</label>
              <div>
                <input type="text" id="requester" v-model="formInputs.requester" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="offer">Offer:</label>
              <div>
                <input type="file" @change="handleFileUpload('offer')($event)" />             
                <button :class="{ 'disabled': isProcessing }" class="fileUploadButton" type="button" @click="fileUpload('offer')" :disabled="isProcessing">File Upload</button>
                {{ fileSaveStatus.offer }}              
              </div>  
            </div> 
            </td>
            <td>
              <div class="form-group">
              <label for="progress">Progress:</label>
              <div>
                <select id="progress" v-model="formInputs.progress">
                  <option>Request</option><option>Done</option><option>Cancel</option>
                </select>
              </div>
            </div>   
            <div class="form-group date-time-group">
                <label>Expect Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="expectyear" v-model="formInputs.expectyear"  />
                  <input type="text" id="expectmonth" v-model="formInputs.expectmonth" />
                  <input type="text" id="expectday" v-model="formInputs.expectday" />                  
                </div> 
            </div>  
            <div class="form-group">
              <label for="remark">Remark:</label>
              <div>
                <input type="text" id="remark" v-model="formInputs.remark" />
              </div>
            </div>               
          </td>
            </tr>
            </table>       
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive,watch, ref } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, uploadFile, } from '../utils.js';
   
  export default {                
    setup(props, { emit }) {   
        const dbTableName = "purchaserequest"    
        const saveStatus = ref(''); 
        const fileSaveStatus = reactive({ offer: '', });
        const isProcessing = ref(false);       
        const selectedFiles = reactive({ offer: null,  });     
        const uploadType = 'purchase';    

        const getCurrentTimeFormatted = () => {
          const now = new Date();
          const year = String(now.getFullYear()).slice(2); // 연도의 마지막 두 자리
          const month = String(now.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 1을 더해줌)
          const day = String(now.getDate()).padStart(2, '0'); // 일
          const hours = String(now.getHours()).padStart(2, '0'); // 시간
          const minutes = String(now.getMinutes()).padStart(2, '0'); // 분

          return `${year}${month}${day}-${hours}${minutes}`;
        };
       
        const formInputs = reactive({
          costtype: "", type: "", supplier: "", itemname: "", specification: "", quantity: "", unitcost1: "0",
          unitcost2: "0", price1: "", price2: "", requester: "", progress: "", offer: "",
          expectyear: "", expectmonth: "", expectday: "", remark: "", offerpath: "", requestnumber: getCurrentTimeFormatted()    
        });

        const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

        const searchDate = reactive({ ...getCurrentDate(),   });    

        watch(() => formInputs.quantity, (newQuantity) => {
          formInputs.price1 = newQuantity * formInputs.unitcost1;
          formInputs.price2 = newQuantity * formInputs.unitcost2;
        });

        watch(() => formInputs.unitcost1, (newUnitCost1) => {
          formInputs.price1 = formInputs.quantity * newUnitCost1;
        });

        watch(() => formInputs.unitcost2, (newUnitCost2) => {
          formInputs.price2 = formInputs.quantity * newUnitCost2;
        });

        const submitForm = async () => {
          if (formInputs.unitcost1 === null || formInputs.unitcost1 === undefined || formInputs.unitcost1 === '' ||
              formInputs.unitcost2 === null || formInputs.unitcost2 === undefined || formInputs.unitcost2 === '') { 
            alert("Please fill in all the fields: Unit Cost(PLN), Unit Cost(EUR) at least put in 0.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            costtype: 'costtype',
            type: 'type',
            supplier: 'supplier',
            itemname: 'itemname',
            specification: 'specification',
            quantity: 'quantity',
            unitcost1: 'unitcost1',
            unitcost2: 'unitcost2',            
            requester: 'requester',
            progress: 'progress',
            offer: 'offer',            
            expectyear: 'expectyear',
            expectmonth: 'expectmonth',
            expectday: 'expectday',            
            remark: 'remark', 
            offerpath: 'offerpath',
            requestnumber: 'requestnumber'  
          };

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              costtype: formInputs.costtype,
              type: formInputs.type,
              supplier: formInputs.supplier,
              itemname: formInputs.itemname,
              specification: formInputs.specification,
              quantity: formInputs.quantity,
              unitcost1: formInputs.unitcost1,
              unitcost2: formInputs.unitcost2,
              requester: formInputs.requester,
              progress: formInputs.progress,
              offer: formInputs.offer,             
              expectyear: formInputs.expectyear,
              expectmonth: formInputs.expectmonth,
              expectday: formInputs.expectday,              
              remark: formInputs.remark,
              offerpath: formInputs.offerpath,
              requestnumber: formInputs.requestnumber,
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으 로 데이터 전송 완료

            const emailData = {
              to: 'hikim@lspoland.com', // 받는 사람의 이메일 주소(여러명에게 보낼때는 이메일주소1,이메일주소2 이런식으로 콤마(,)로 구분)
              subject: '신규 구매 요청', // 이메일 제목
              body: `Item Name : ${formInputs.itemname} Spec : ${formInputs.specification} Requester : ${formInputs.requester}` // 이메일 내용
            };
            await axios.post('/api/send-email', emailData); // 이메일 발송 요청

          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };

        const fileUpload = (type) => uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing); 

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing, 
               handleFileUpload, fileSaveStatus, fileUpload, selectedFiles,
      };

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 

};
  
  </script>
    
  <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 800px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }

    .fileUploadButton {
      background-color: #c6c6c6; color: rgb(0, 0, 0);
      padding: 5px 5px; margin-top: 10px; margin-right: 10px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
      display: inline-block; transition-duration: 0.4s;
    }

    .fileUploadButton:hover { background-color: #5f5f5f; } 
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }  
    .radio { margin-left: 10px; margin-right: 5px; }
    .disabled { background-color: #ccc; cursor: not-allowed; }
</style>