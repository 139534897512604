<!-- 다운타임은 dbtablename과 linename 바꾸고, 내역 테이블 붙히고, materialcode,2,3 확인해서 바꾸면 끝남  -->
<template>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h3>Material Request</h3>
            <button class="close-button" @click="closeModal">X</button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
              <div class="form-group date-time-group">
                <label>Request Time:</label>
                <div class="date-time-inputs">
                  <input type="number" id="year" v-model="searchDate.year" readonly class="readWindow" placeholder="Year" />
                  <input type="number" id="month" v-model="searchDate.month" readonly class="readWindow" placeholder="Month" />
                  <input type="number" id="day" v-model="searchDate.day" readonly class="readWindow" placeholder="Day"  />
                  <input type="text" id="request_time" v-model="formInputs.request_time" readonly class="readWindow" />
                </div>
              </div>
              <div class="form-group date-time-group">
                <label>Due Time:</label>
                <div class="date-time-inputs">
                  <input type="number" id="due_year" v-model="formInputs.due_year" placeholder="Year" />
                  <input type="number" id="due_month" v-model="formInputs.due_month" placeholder="Month" />
                  <input type="number" id="due_day" v-model="formInputs.due_day" placeholder="Day"  />
                  <input type="text" id="due_time" v-model="formInputs.due_time" placeholder="hh:mm"/>
                </div>
              </div>
              <div class="form-group">
              <label for="factory">Factory:</label>
              <select id="factory" v-model="formInputs.factory">            
                <option>1</option><option>2</option><option>etc</option>
              </select>              
            </div>             
              <div class="form-group">
                <label for="materialcode">Material Code:</label>
                <input type="number" id="materialcode" v-model="formInputs.materialcode" />              
              </div>              
              <div class="form-group">
                <label for="materialname">Material Name:</label>
                <input type="text" id="materialname" v-model="formInputs.materialname" readonly class="readWindow" /> 
              </div>     
              <div class="form-group quantity-unit-group">
                <label>Quantity:</label>
                <div class="quantity-unit-inputs">
                  <input type="number" id="quantity" v-model="formInputs.quantity" placeholder="Quantity" />
                  <select id="unit" v-model="formInputs.unit">
                    <option value="" disabled selected hidden>Unit</option>
                    <option>ea</option>
                    <option>gram</option>
                    <option>liter</option>
                  </select>
                </div>
              </div> 
              <div class="form-group" style="display: flex; flex-direction: column;">
                <label for="detail">Detail:</label>
                <textarea id="detail" v-model="formInputs.detail" rows="4" cols="25"></textarea>
              </div>   
              <div class="form-group send-group">                
                <div class="send-inputs">
                  <input type="text" id="send_year" v-model="formInputs.send_year" readonly class="readWindow"/>  
                  <input type="text" id="send_month" v-model="formInputs.send_month" readonly class="readWindow"/>  
                  <input type="text" id="send_day" v-model="formInputs.send_day" readonly class="readWindow" />  
                  <input type="text" id="send_time" v-model="formInputs.send_time" readonly class="readWindow" />  
                  <input type="text" id="send_quantity" v-model="formInputs.send_quantity" readonly class="readWindow" />  
                  <input type="text" id="send_unit" v-model="formInputs.send_unit" readonly class="readWindow" />  
                  <input type="text" id="confirm" v-model="formInputs.confirm" readonly class="readWindow" />  
                </div>
              </div>        
              <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
              <button type="submit" id="save">Save</button>            
              </form>
           </div>        
        </div>      
      </div>
    </div>
</template>
    
<script>  
  import { reactive, watch,  } from 'vue';  
  import axios from 'axios';   
    
  export default {                
    setup(props, { emit }) {   
    
        const rqTableName = "materialrequest"                

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          request_time: "",     
          due_year: "",
          due_month: "",
          due_day: "",
          due_time: "",  
          factory: "",                             
          materialcode: "",            
          materialname: "",    
          quantity: "",
          unit: "",            
          detail: "", 
          send_year: "0",
          send_month: "0",
          send_day: "0",
          send_time: " ",
          send_quantity: "0",
          send_unit: " ",
          confirm: "not yet",
        });
        // -끝-              
  
      //현재 날짜로 기본 설정 -시작-
        const getCurrentDate = () => {
          let today = new Date();        
          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();  
          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        }; 
        const getCurrentTime = () => {
          let today = new Date();
          const adjustedHour = String(today.getHours()).padStart(2, '0');
          const adjustedMinute = String(today.getMinutes()).padStart(2, '0');
          return `${adjustedHour}:${adjustedMinute}`;
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });  

        formInputs.request_time = getCurrentTime();
      // -끝-      
    
        //input, select에 있는 id 이름과 database table 이름을 맞추는거, 
        //그래서 필히 id 이름이 database table 이름과 동일해야 함
        //시작 
        const getColumnMapping = () => {
          const inputs = document.querySelectorAll("input, select, textarea");
          const filteredInputs = Array.from(inputs).filter(input => input.id !== 'file-input');
          const mapping = {};

          filteredInputs.forEach(input => {
            let inputId = input.id;

            if (inputId === "request_hour" || inputId === "request_minute") {
              inputId = "request_time";
            } 
            if (!mapping[inputId]) {
              if (inputId === "request_time") {
                mapping[inputId] = "request_time";
              } else {
                mapping[inputId] = input.id;
              }
            }
          });  
          return mapping;
        };
        //끝 

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!formInputs.factory || !formInputs.materialcode || !formInputs.quantity || !formInputs.unit) {
            alert("Please fill in Factory, Material Code, Quantity, Unit .");
            return;
            }  
          const columnMapping = getColumnMapping();

          const formData = {
            tableName: rqTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,                
              request_time: formInputs.request_time,   
              due_year: formInputs.due_year,
              due_month: formInputs.due_month,
              due_day: formInputs.due_day,
              due_time: formInputs.due_time,                  
              factory: formInputs.factory,                             
              materialcode: formInputs.materialcode,            
              materialname: formInputs.materialname,    
              quantity: formInputs.quantity,
              unit: formInputs.unit, 
              detail: formInputs.detail,   
              send_year: formInputs.send_year,
              send_month: formInputs.send_month,
              send_day: formInputs.send_day,
              send_time: formInputs.send_time,
              send_quantity: formInputs.send_quantity,                
              send_unit: formInputs.send_unit,                
              confirm: formInputs.confirm,     
            }
          };           
          await axios.post('/api/post', formData);           
          closeModal();
        };  
        const closeModal = () => {
          emit("onClose");
        };
        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-  

        //자재코드에 따라 자재명 서버에서 불러오는거 -시작-
        const fetchmaterialname = () => {
          const materialTableName = 'materiallist';
          const columnName = 'materialcode';
          console.log("fetchmaterialname called with materialcode:", formInputs.materialcode);
          axios
            .get('/api/where', { params: { value: formInputs.materialcode, table_name: materialTableName, column_name: columnName } })
            .then((res) => {
              console.log('Response from /api/where:', res.data);       

              // Set the fetched materialname to formInputs.materialname
              if (res.data && res.data.length > 0) {
                formInputs.materialname = res.data[0].materialname;
              } else {
                formInputs.materialname = "";
              }
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };  
        watch(() => formInputs.materialcode, () => {
              fetchmaterialname();
              console.log("Updated materialname:", formInputs.materialname);
        });
        //자재코드에 따라 자재명 서버에서 불러오는거 -끝-
      
          return { searchDate, rqTableName, formInputs, submitForm, closeModal, 
                      };
                      //requestHour, requestMinute,
        },     

    props: { onSubmit: { type: Function, required: true, }, }, 
  };    
</script>
      
<style scoped>
  /* Reset */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper {
    width: 100%;
    max-width: 400px;
  }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .modal-header h3 {
    font-size: 1.5rem;
  }

  .close-button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
  }

  label {
    font-weight: bold;
  }

  input, textarea, select {
    font-size: 1rem;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input[type="number"] {
    width: 100%;
  }

  .readWindow[readonly] {
    background-color: #f0f0f0;
  }

  button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
  }

  button[type="submit"]:hover {
    background-color: #45a049;
  }

  .date-time-group {
  display: flex;
  align-items: left;
}

.date-time-inputs {
  display: flex;
  gap: 5px;
}

.date-time-inputs input {
  width: 85px;
  size: 8px;
}

.quantity-unit-group {
  display: flex;
  align-items: left;
}

.quantity-unit-inputs {
  display: flex;
  gap: 5px;
}

.quantity-unit-inputs input {
  width: 300px;
}
.quantity-unit-inputs select {
  width: 50;
}

.send-group {
  display: none;
}

</style>