<template>
  <div class="container">
    <div class="controls">
      <table class="searchTable">
        <tr>
          <td>
            <select v-model="year" class="dropdown">
              <option>2022</option><option>2023</option><option>2024</option><option>2025</option>
              <option>2026</option><option>2027</option><option>2028</option><option>2029</option>
              <option>2030</option><option>2031</option><option>2032</option><option>2033</option>              
            </select>
          </td>        
          <td>
            <select v-model="line" class="dropdown">
              <option>TR2 SMT</option><option>TR2 Crimping</option><option>TR2 FPCA</option><option>TR3 SMT</option>
              <option>TR3 Crimping</option><option>TR3 FPCA</option><option>TR2 BFA 3P</option><option>EVO 1</option>
              <option>EVO 2</option><option>EVO 3</option><option>EVO Hybrid</option><option>Ford 1 Front</option>
              <option>Ford 1 Rear</option><option>Ford 2 Front</option><option>Ford 2 Rear</option><option>Ford 3 Front</option>
              <option>Ford 3 Rear</option><option>EV2020 60kW Front</option><option>EV2020 60kW Rear</option>
              <option>EV2020 4060kW Front</option><option>EV2020 4060kW Rear</option>
              <option>BMW 48V</option><option>BMW 12V</option><option>BT6</option><option>FCA 1</option><option>FCA 2</option>
              <option>FCA FLEX BC</option><option>FCA FLEX PO</option><option>FCA FLEX NE</option>
              <option>FCA FLEX 45</option><option>FCA FLEX 67</option>
            </select>
          </td>
          <td>
            <button @click="search()" class="searchButton">Search</button>
          </td>
        </tr>
      </table> 
      <br>
    </div>
    <table class="mainTable">
      <tr>
        <th class="categoryHeader" colspan="2">Category</th>
        <th v-for="period in formattedMonths" :key="`header-${period}`">{{ period }}</th>
      </tr>
      <tr class="mainTr" v-for="category in categories" :key="`category-row-${category.name}`">
        <td class="categoryName">{{ category.title }}</td>
        <td class="categoryUnit">{{ category.unit }}</td>
        <td class="valueTd" v-for="period in formattedMonths" :key="`value-${category.name}-${period}`"
          :style="shouldApplyWhiteStyle(formattedResults[period][category.name]) ? { color: 'white' } : {}">
          {{ formattedResults && formattedResults[period] ? formattedResults[period][category.name] : 'N/A' }}
        </td>
      </tr>
    </table>
    <table>
      <tr>
        <td><div class="chart-container"><canvas ref="okChartRef"></canvas></div></td>
        <td><div class="chart-container"><canvas ref="ngChartRef"></canvas></div></td>
      </tr>
      <tr>
        <td><div class="chart-container"><canvas ref="workTimeChartRef"></canvas></div></td>
        <td><div class="chart-container"><canvas ref="oeeChartRef"></canvas></div></td>
      </tr>
      <tr>
        <td><div class="chart-container"><canvas ref="mttrChartRef"></canvas></div></td>  
        <td><div class="chart-container"><canvas ref="mtbfChartRef"></canvas></div></td>       
      </tr>
      <tr>
        <td><div class="chart-container"><canvas ref="failureRateChartRef"></canvas></div></td>         
      </tr>
    </table>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { Chart, registerables } from 'chart.js';
import { dataMonth, updateResultData, fetchDataForAddData, updateTactTargets } from './data.js';

export default {
  setup() {
    const okChartRef = ref(null);
    const ngChartRef = ref(null);
    const workTimeChartRef = ref(null);
    const oeeChartRef = ref(null);
    const mttrChartRef = ref(null);
    const mtbfChartRef = ref(null);
    const failureRateChartRef = ref(null);
    const okChartInstance = reactive({});
    const ngChartInstance = reactive({}); 
    const workTimeChartInstance = reactive({});
    const oeeChartInstance = reactive({});
    const mttrChartInstance = reactive({});
    const mtbfChartInstance = reactive({});
    const failureRateChartInstance = reactive({});
    const year = ref('');
    const line = ref('');
    const searchResult = reactive({});  

    Chart.register(...registerables);

    const categories = [
      { title: 'OK', name: 'ok', unit: 'EA' },
      { title: 'OK Achieve', name: 'okAchieve', unit: '%' },
      { title: 'NG', name: 'ng', unit: 'EA' },
      { title: 'NG Rate', name: 'ngRate', unit: '%' },
      { title: 'Down Time', name: 'DownTime', unit: 'Min' },
      { title: 'Down Time Rate', name: 'DownTimeRate', unit: '%' },
      { title: 'Working Time', name: 'WorkTime', unit: 'Min' },
      { title: 'OEE', name: 'oee', unit: '%' },
      { title: 'Available', name: 'oeeAvailable', unit: '%' },
      { title: 'Efficiency', name: 'oeeEfficiency', unit: '%' },
      { title: 'Quality', name: 'oeeQuality', unit: '%' },
      { title: 'MTTR', name: 'mttr', unit: 'Min' },
      { title: 'MTBF', name: 'mtbf', unit: 'Min' },
      { title: 'Failure Rate', name: 'failureRate', unit: '%' }
    ];

    const formattedMonths = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    async function loadData() {      
      await fetchDataForAddData();
      updateTactTargets();
      await updateResultData(year.value, line.value);      
    }

    function updateSearchResult() {
      const months = formattedMonths;
      months.forEach(month => {
        const monthData = dataMonth[month];
        const lineData = monthData.find(item => item.line === line.value);
        if (lineData) {
          searchResult[month] = {
            target: lineData.target,
            ok: lineData.ok,
            ng: lineData.ng,
            work_minute: lineData.work_minute,
            time: lineData.time,
            ngRate: lineData.ngRate,
            okAchieve: lineData.okAchieve,
            tacttime: lineData.tacttime,
            failureTime: lineData.failureTime,
            failureCount: lineData.failureCount,
          };
        }
      });
    }

    const formattedResults = computed(() => {
      const results = {};
      formattedMonths.forEach(month => {
        const data = searchResult[month];
        if (data) {
          results[month] = calculateValues(data);
        } else {
          results[month] = categories.reduce((acc, category) => {
            acc[category.name] = '0';
            return acc;
          }, {});
        }
      });
      return results;
    });

    function calculateValues(data) {
      const { ok = 0, okAchieve = 0, ng = 0, ngRate = 0, work_minute = 0, time = 0, tacttime = 0, failureTime = 0, failureCount = 0, } = data;

      const operatingTime = work_minute - time;
      const totalProductionTime = ((operatingTime * 60) / ok).toFixed(1);  
      const mttr = failureCount > 0 ? (failureTime / failureCount).toFixed(1) : 'N/A';
      const mtbf = failureCount > 0 ? (work_minute / failureCount).toFixed(1) : 'N/A';
      const failureRate = work_minute > 0 ? ((failureTime / work_minute) * 100).toFixed(1) : 'N/A';
      const oeeAvailable = work_minute > 0 ? ((operatingTime / work_minute) * 100).toFixed(1) : 'N/A';
      const oeeEfficiency = totalProductionTime > 0 ? ((tacttime / totalProductionTime) * 100).toFixed(1) : 'N/A';
      const oeeQuality = (ok + ng ) > 0 ? ((ok / (ok + ng)) * 100).toFixed(1) : 'N/A';

      return {
        ok,
        okAchieve,
        ng,
        ngRate,
        DownTime: time,
        DownTimeRate: work_minute === 0 ? 0 : (time / work_minute).toFixed(1),
        WorkTime: work_minute,        
        oeeAvailable,
        oeeEfficiency,
        oeeQuality,
        oee: ((oeeAvailable * oeeEfficiency * oeeQuality)/10000).toFixed(1),
        mttr,
        mtbf,
        failureRate,
      };
    }

    async function search() {
      await loadData();
      updateSearchResult();
      createOkChart();
      createNgChart();
      createWorkTimeChart();
      createOEEChart();
      createMTTRChart();
      createMTBFChart();
      createFailureRateChart();
    }

    function createOkChart() {
      if (okChartInstance.value) {
        okChartInstance.value.destroy();
      }
      okChartInstance.value = new Chart(okChartRef.value.getContext('2d'), {
        type: 'bar',  //그래프 유형   'line'  'bar'
        data: {
          labels: formattedMonths,
          datasets: [
            {
              label: 'OK (EA)',
              data: formattedMonths.map(month => formattedResults.value[month].ok),
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              barThickness: 10, // 막대의 두께 설정, 선형에서는 삭제
              yAxisID: 'y',
            },
            {
              label: 'OK Achieve (%)',
              data: formattedMonths.map(month => formattedResults.value[month].okAchieve),
              borderColor: 'rgba(255, 159, 64, 1)',
              backgroundColor: 'rgba(255, 159, 64, 0.2)',              
              barThickness: 10,
              yAxisID: 'y1'
            }
          ],
        },
        options: {
          scales: {
            y: {
                beginAtZero: true,
                type: 'linear',
                position: 'left',
                id: 'y',
                scaleLabel: {
                  display: true,
                  labelString: 'EA',
                  fontColor: 'black',  // 라벨 글자색 설정
                  fontSize: 16,       // 라벨 글자 크기 설정
                }
            },
            y1: {
                beginAtZero: true,
                type: 'linear',
                position: 'right',
                id: 'y1',
                scaleLabel: {
                  display: true,
                  labelString: '%',
                  fontColor: 'black',  // 라벨 글자색 설정
                  fontSize: 16,       // 라벨 글자 크기 설정
                },
                ticks: {
                  max: 100,
                  min: 0,
                }
            }
          }
        }
      });
    }

    function createNgChart() {
      if (ngChartInstance.value) {
        ngChartInstance.value.destroy();
      }
      ngChartInstance.value = new Chart(ngChartRef.value.getContext('2d'), {
        type: 'bar',  // 그래프 유형: 막대 그래프
        data: {
          labels: formattedMonths,
          datasets: [
            { label: 'NG (EA)', data: formattedMonths.map(month => formattedResults.value[month].ng), borderColor: 'rgba(255, 99, 132, 1)', backgroundColor: 'rgba(255, 99, 132, 0.2)', barThickness: 10, yAxisID: 'y',},
            { label: 'NG Rate (%)', data: formattedMonths.map(month => formattedResults.value[month].ngRate), borderColor: 'rgba(54, 162, 235, 1)', backgroundColor: 'rgba(54, 162, 235, 0.2)', barThickness: 10, yAxisID: 'y1',}
          ],
        },
        options: {
          scales: {
            y: { beginAtZero: true, type: 'linear', position: 'left', id: 'y',
                 scaleLabel: { display: true, labelString: 'EA' }
            },
            y1: { beginAtZero: true, type: 'linear', position: 'right', id: 'y1',
                  scaleLabel: { display: true, labelString: '%' },
                  ticks: { max: 100, min: 0, }
            }
          }
        }
      });
    }

    function createWorkTimeChart() {
      if (workTimeChartInstance.value) workTimeChartInstance.value.destroy();
      workTimeChartInstance.value = new Chart(workTimeChartRef.value.getContext('2d'), {
        type: 'bar',
        data: {
          labels: formattedMonths,
          datasets: [
            //{ label: 'Work Time (Min)', data: formattedMonths.map(month => formattedResults.value[month].WorkTime), backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)', barThickness: 10, yAxisID: 'y', },
            { label: 'Down Time (Min)', data: formattedMonths.map(month => formattedResults.value[month].DownTime), backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)', barThickness: 10, yAxisID: 'y', },
            { label: 'Down Time Rate (%)', data: formattedMonths.map(month => formattedResults.value[month].DownTimeRate), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', barThickness: 10, yAxisID: 'y1', }
          ]
        },
        options: {
          scales: {
            y: { beginAtZero: true, type: 'linear', position: 'left', id: 'y',
                 scaleLabel: { display: true, labelString: 'Min' }
            },
            y1: { beginAtZero: true, type: 'linear', position: 'right', id: 'y1',
                  scaleLabel: { display: true, labelString: '%' },
                  ticks: { max: 100, min: 0, }
            }
          }
        }
      });
    }

    function createOEEChart() {
      if (oeeChartInstance.value) oeeChartInstance.value.destroy();
      oeeChartInstance.value = new Chart(oeeChartRef.value.getContext('2d'), {
        type: 'bar',
        data: {
          labels: formattedMonths,
          datasets: [
            { label: 'OEE (%)', data: formattedMonths.map(month => formattedResults.value[month].oee), backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgba(54, 162, 235, 1)', barThickness: 10 },
            { label: 'Available (%)', data: formattedMonths.map(month => formattedResults.value[month].oeeAvailable), backgroundColor: 'rgba(255, 99, 132, 0.2)', borderColor: 'rgba(255, 99, 132, 1)', barThickness: 10 },
            { label: 'Efficiency (%)', data: formattedMonths.map(month => formattedResults.value[month].oeeEfficiency), backgroundColor: 'rgba(255, 159, 64, 0.2)', borderColor: 'rgba(255, 159, 64, 1)', barThickness: 10 },
            { label: 'Quality (%)', data: formattedMonths.map(month => formattedResults.value[month].oeeQuality), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', barThickness: 10 }
          ]
        },
        options: { scales: { 
                  y: { beginAtZero: true, type: 'linear', position: 'left', id: 'y',
                        scaleLabel: { display: true, labelString: '%' },
                        ticks: { max: 100, min: 0, }
                     }}}
      });
    }

    function createMTTRChart() {
      if (mttrChartInstance.value) mttrChartInstance.value.destroy();
      mttrChartInstance.value = new Chart(mttrChartRef.value.getContext('2d'), {
        type: 'bar',
        data: {
          labels: formattedMonths,
          datasets: [
            { label: 'MTTR (Min)', data: formattedMonths.map(month => formattedResults.value[month].mttr), backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)', barThickness: 10, yAxisID: 'y', },
  //          { label: 'MTBF (Min)', data: formattedMonths.map(month => formattedResults.value[month].mtbf), backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)', barThickness: 10, yAxisID: 'y', },
    //        { label: 'Failure Rate (%)', data: formattedMonths.map(month => formattedResults.value[month].failureRate), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', barThickness: 10, yAxisID: 'y1', }
          ]
        },
        options: {
          scales: {
            y: { beginAtZero: true, type: 'linear', position: 'left', id: 'y',
                 scaleLabel: { display: true, labelString: 'Min' }
            },
          }
        }
      });
    }

    function createMTBFChart() {
      if (mtbfChartInstance.value) mtbfChartInstance.value.destroy();
      mtbfChartInstance.value = new Chart(mtbfChartRef.value.getContext('2d'), {
        type: 'bar',
        data: {
          labels: formattedMonths,
          datasets: [
         //   { label: 'MTTR (Min)', data: formattedMonths.map(month => formattedResults.value[month].mttr), backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)', barThickness: 10, yAxisID: 'y', },
            { label: 'MTBF (Min)', data: formattedMonths.map(month => formattedResults.value[month].mtbf), backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)', barThickness: 10, yAxisID: 'y', },
         //   { label: 'Failure Rate (%)', data: formattedMonths.map(month => formattedResults.value[month].failureRate), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', barThickness: 10, yAxisID: 'y1', }
          ]
        },
        options: {
          scales: {
            y: { beginAtZero: true, type: 'linear', position: 'left', id: 'y',
                 scaleLabel: { display: true, labelString: 'Min' }
            },
          }
        }
      });
    }

    function createFailureRateChart() {
      if (failureRateChartInstance.value) failureRateChartInstance.value.destroy();
      failureRateChartInstance.value = new Chart(failureRateChartRef.value.getContext('2d'), {
        type: 'bar',
        data: {
          labels: formattedMonths,
          datasets: [
        //  { label: 'MTTR (Min)', data: formattedMonths.map(month => formattedResults.value[month].mttr), backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)', barThickness: 10, yAxisID: 'y', },
        //  { label: 'MTBF (Min)', data: formattedMonths.map(month => formattedResults.value[month].mtbf), backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)', barThickness: 10, yAxisID: 'y', },
            { label: 'Failure Rate (%)', data: formattedMonths.map(month => formattedResults.value[month].failureRate), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', barThickness: 10, yAxisID: 'y1', }
          ]
        },
        options: {
          scales: {
            y: { beginAtZero: true, type: 'linear', position: 'left', id: 'y',
                  scaleLabel: { display: true, labelString: '%' },
                  ticks: { max: 100, min: 0, }
            }
          }
        }
      });
    }

    onMounted(() => {
      if (okChartRef.value && ngChartRef.value && workTimeChartRef.value && oeeChartRef.value && mttrChartRef.value && mtbfChartRef.value && failureRateChartRef.value) {
        createOkChart(); createNgChart(); createWorkTimeChart(); createOEEChart(); createMTTRChart(); createMTBFChart(); createFailureRateChart();
      }
    });

    function shouldApplyWhiteStyle(value) {
      return parseFloat(value) === 0 || Number.isNaN(value) || !isFinite(value);
    }

    return {
      year, line, search, categories, formattedMonths, formattedResults, shouldApplyWhiteStyle,
      okChartRef, ngChartRef, workTimeChartRef, oeeChartRef, mttrChartRef, mtbfChartRef, failureRateChartRef
    };
  }
}
</script>

<style scoped>
  .container { margin: 20px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
  .controls, .searchTable, .mainTable { width: 80%; margin-bottom: 10px; }
  .dropdown { width: 100%; padding: 8px 10px; border-radius: 5px; border: 1px solid #ccc; background-color: white; }
  .searchButton { margin-left:10px; background-color: #d3d3d3; color: rgb(0, 0, 0); border: none; border-radius: 5px; padding: 7px 20px; cursor: pointer; font-weight: bold; }
  .searchButton:hover { background-color: #7d7d7d; }
  .mainTable { width: 100%; border-collapse: collapse; }
  .mainTable th { border: 1px solid #7d7d7d; padding: 8px; text-align: center; }
  .valueTd { border: 1px solid #7d7d7d; padding: 8px; text-align:right; padding-right: 8px; }
  .categoryHeader { background-color: #f2f2f2; color: black; }
  .categoryName, .categoryUnit { background-color: #f9f9f9; text-align: center; font-weight: bold; border: 1px solid #7d7d7d; }
  .chart-container { height:50vh; width:50vw; margin-top: 20px; margin-left: 10px; margin-right: 10px; background-color: #fff; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
</style>
