<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Scrap Measurement</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable"> <!--Factoy, Line, Station, Type Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date<br>(Year/Month/Day)</td>                        
                <td>
                  <input class="dateInput" type="text" v-model="searchDate.year" />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Pure Copper(g)</td> 
                <td>
                  <input class="itemInput" type="text" v-model="formInputs.purecopper">
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);"> 
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Coating Copper(g)</td> 
                <td>
                  <input class="itemInput" type="text" v-model="formInputs.coatingcopper">
                </td>                
              </tr>
            </table><br>                      
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}          
          </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, ref,  } from 'vue'; 
  import axios from 'axios';   
  import { getCurrentDate, } from '../../utils.js';
   
  export default {                
    setup(props, { emit }) {
  
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const dbTableName = "scrapmeasurement"                      
         
        const formInputs = reactive({ purecopper: null, coatingcopper: null });         

        const searchDate = reactive({ ...getCurrentDate(), });        
    
        const submitForm = async () => {        

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';          

          const columnMapping = {
            year: 'year', month: 'month' , day: 'day', purecopper: 'purecopper', coatingcopper: 'coatingcopper'
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              purecopper: formInputs.purecopper,
              coatingcopper: formInputs.coatingcopper,              
            }
         };
         
          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error';
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };    
        
        const closeModal = () => {  emit("onClose");  };  
          
        return { searchDate, dbTableName, formInputs, submitForm, closeModal,                   
                  saveStatus, isProcessing,                              
        }; 
    },     

    props: { onSubmit: { type: Function, required: true, }, }, 
  };
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 500px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; width: 80px !important; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }
  button[type="submit"]:hover { background-color: #45a049; }

  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }  
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
</style>