<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Work Time Paper</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <td>
                  <div class="form-group date-time-group">
                    <label>Year/Month:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.year"  />
                      <input type="text" id="month" v-model="formInputs.month" />                                   
                    </div> 
                  </div>                        
                  <div class="form-group">
                    <label for="oscompany">OS Company:</label>
                    <select class="modalSelect" id="oscompany" v-model="formInputs.oscompany">
                      <option v-for="oscompany in uniqueCompany" :key="oscompany">{{ oscompany }}</option><option></option>   
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="employeenumber">Employee Number:</label>
                    <input id="employeenumber" v-model="formInputs.employeenumber" />
                  </div>
                  <div class="time">
                    <label for="time">Work Time:</label>
                    <input type="number" id="time" v-model="formInputs.worktime" />              
                  </div>
                  </td>                                    
              </tr>
            </table>                     
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';  
  import { fetchCompany,} from "../../utils.js";  
   
  export default {      

    setup(props, { emit }) {   
        const dbTableName = "attendancepaper";   
        const uniqueCompany = ref([]);       
        const formInputs = reactive({   
          id: "",
          year: "",
          month: "",          
          oscompany: "",
          employeenumber: "",          
          worktime : "",          
        });

        const loadCompany = async () => { uniqueCompany.value = await fetchCompany(); }; loadCompany();

        const submitForm = async () => {
          if (!formInputs.employeenumber || !formInputs.oscompany ) {
            alert("Please fill in all the fields: Employee Number, OS Company.");
            return;
          }
          const columnMapping = {                                      
            year: 'year',
            month: 'month',                        
            oscompany: "oscompany",
            employeenumber: "employeenumber",            
            worktime : "worktime",             
          }     

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {                            
              year: formInputs.year,
              month: formInputs.month,              
              oscompany : formInputs.oscompany,
              employeenumber : formInputs.employeenumber,                            
              worktime : formInputs.worktime              
            }
         };

         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }
         
         await axios.put(`/api/materialput/${id}`, formData);              
         closeModal();
        };
        const closeModal = () => {  emit("onClose"); };  

        const fetchSelectedRowData = async () => {
            try {
              const selectedData = props.selectedData;
              
              formInputs.id = selectedData.id;
              formInputs.year = selectedData.year;
              formInputs.month = selectedData.month;
              formInputs.oscompany = selectedData.oscompany;
              formInputs.employeenumber = selectedData.employeenumber;
              formInputs.worktime = selectedData.worktime;

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
        };
        watch( () => props.selectedData, () => { fetchSelectedRowData(); }, { immediate: true } ); 
          

      return { dbTableName, formInputs, submitForm, closeModal, uniqueCompany };

    },         
    props: { selectedData: { type: Object, default: () => ({}) } },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 500px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 80px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>