<template>
  <div class="modal-mask">
  <div class="modal-wrapper">
  <div class="modal-container">
  <div class="modal-header">
    <h3>Take Over View</h3>
    <button class="close-button" @click="closeModal">X</button>
  </div>
    <div class="modal-body">
      <table class="signTable">
        <tr>
          <th>Date</th><th>Factory</th><th>Shift</th><th>Position</th><th>Writer</th><th>Line</th>
        </tr>
        <tr>
          <td>{{formInputs.day}}.{{formInputs.month}}.{{formInputs.year}}</td>
          <td>{{formInputs.factory}}</td>
          <td>{{formInputs.shift}}</td>
          <td>{{formInputs.position}}</td>
          <td>{{formInputs.writer}}</td>
          <td>{{formInputs.line}}</td>          
        </tr>
      </table>
      <table class="mainTable">        
        <tr><td class="itemTd">Contents</td><td class="contentsTd" v-html="convertNewLines(formInputs.contents)"></td></tr>
        <tr><td class="itemTd">Remark</td><td class="contentsTd">{{formInputs.remark}}</td></tr>
      </table>      
      <div> 
        <button class="printButton" @click="printDocument">Print</button>
      </div>
    </div>        
  </div>      
  </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch,  } from 'vue';  
  import axios from 'axios';     
   
  export default {   
    methods: {     
      printDocument() {
        let printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');       
        document.querySelectorAll('link[rel="stylesheet"]').forEach((link) => {
          printWindow.document.head.appendChild(link.cloneNode(true));
        });    
        document.querySelectorAll('style').forEach((style) => {
          printWindow.document.head.appendChild(style.cloneNode(true));
        });      
        const printContent = document.querySelector('.modal-container').innerHTML;
        printWindow.document.body.innerHTML = printContent;
        printWindow.document.close(); 
        printWindow.focus();
        setTimeout(() => { printWindow.print(); printWindow.close(); }, 1000);
      },
      convertNewLines(text) { return text.replace(/\n/g, '<br>'); }
    },         
    setup(props, { emit }) {   
        const dbTableName = "takeover";  
        const formInputs = reactive({ id: "", year: "", month: "", day: "", factory: "",
                                      shift: "", position: "", writer: "", line: "", contents: "", remark: "", confirm: ""
        });    
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id;
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,                       
              formInputs.factory = rowData.factory,
              formInputs.shift = rowData.shift,
              formInputs.position = rowData.position,
              formInputs.writer = rowData.writer,
              formInputs.line = rowData.line,
              formInputs.contents = rowData.contents,
              formInputs.remark = rowData.remark,
              formInputs.confirm = rowData.confirm

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } ); 

      const closeModal = () => { emit("onClose"); }; 

      return { dbTableName, formInputs,  closeModal, };
    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   
.modal-wrapper { width: 100%;  max-width: 1060px; }
.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}
.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
.modal-header h3 { font-size: 1.2rem; }
.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.signTable th {background-color: #d2d2d2; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.signTable td {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.imageTable th {background-color: #d2d2d2; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.imageTable td {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}

.mainTable {margin-top: 20px; margin-bottom: 20px;}
.itemTd {background-color: #d2d2d2; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.contentsTd {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px;
            border: 1px solid #7d7d7d; text-align: left; width: 950px;
}
.printButton {
  background-color: #347003;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-size: 15px; font-weight: bold;
  padding: 10px 10px; transition: background-color 0.3s; margin-top : 10px; 
}

</style>