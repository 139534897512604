<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Q-WALL</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>                                                            
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option v-for="model in models" :key="model">{{ model }}</option>
              </select>                
            </div>              
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>
            <div class="form-group">
              <label for="ng">NG:</label>
              <input type="number" id="ng" v-model="formInputs.ng" />
            </div>            
            <div class="form-group">
              <label for="ng_lasermarking">NG Laser Marking:</label>
              <input type="number" id="ng_lasermarking" v-model="formInputs.ng_lasermarking"  />
            </div>
            <div class="form-group">
              <label for="ng_middlestation">NG Middle Station:</label>
              <input type="number" id="ng_middlestation" v-model="formInputs.ng_middlestation" />
            </div>
            <div class="form-group">
              <label for="ng_aoi">NG AOI:</label>
              <input type="number" id="ng_aoi" v-model="formInputs.ng_aoi"  />
            </div>
            <div class="form-group">
              <label for="ng_coating">NG Coating:</label>
              <input type="number" id="ng_coating" v-model="formInputs.ng_coating" />
            </div>
            <div class="form-group">
              <label for="ng_eol">NG EOL:</label>
              <input type="number" id="ng_eol" v-model="formInputs.ng_eol" />
            </div>
            <div class="form-group">
              <label for="ng_final">NG Fianl:</label>
              <input type="number" id="ng_final" v-model="formInputs.ng_final"  />
            </div>                                       
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>
import { useForm } from '../0_formStyle/modal/form_qw_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../0_formStyle/modal/style_qw_modal.css"></style>