<template>
    <div class="stocklist">            
      <div class="act">               
         <h class="pagetitle">Stock List</h>                  
      </div>
      <table class="searchBar">
        <tr>
          <td class="searchBarTd">
          <form @submit.prevent="sendDate()">             
            <label>Search : </label>
            <input id="year" v-model="searchDate.year" placeholder="Target Year"/>          
            <input id="month" v-model="searchDate.month" placeholder="Target Month" />                 
            <input id="day" v-model="searchDate.day" placeholder="Target Day"/>       
            <select id="factory" v-model="searchDate.factory" placeholder="Factory">
              <option>1</option><option>2</option>
            </select>              
            <input class= "compare" id="compareyear" v-model="searchDate.compareYear" placeholder="Compare Year"/>          
            <input class= "compare" id="comparemonth" v-model="searchDate.compareMonth" placeholder="Compare Month" />                       
            <input class= "compare" id="compareday" v-model="searchDate.compareDay" placeholder="Compare Day"/>        
            <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
            <button class="excelButton" @click="downExcel">Excel</button>               
          </form>
          </td>
          </tr>
        <tr>
        <td class="searchBarTd">        
          <label>Save : </label>
          <input type="number" id="newyear" v-model="formInputs.newyear" />                          
          <input type="number" id="newmonth" v-model="formInputs.newmonth" /> 
          <input type="number" id="newday" v-model="formInputs.newday" />                            
          <button class="button" :class="{ 'disabled': isProcessing }" @click="saveData" :disabled="isProcessing">Save Data</button>          
          {{ saveStatus }}           
          <button class="modalButton" @click="showModal = true">+ Add</button>      
          <button class="button" @click="moshowModal = true">Movement</button>            
          <label for="file-input" class="custom-file-upload">Excel File</label>                
          <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
          <span class="fileName">{{ fileName }}</span>
          <button class="excelButton" @click="upExcel('stocksap')">SAP Upload</button>
          <button class="dailyButton" @click="upExcel('stockdaily')">Daily Stock Upload</button>         
          <button class="delButton" :class="{ disabledButton: !canAllDel() }" :disabled="!canAllDel()" @click="delAll">DEL ALL</button>          
        </td>
        </tr>
      </table>             
      <P></P>     
      <div class="tables">
      <table id="stockTable" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">
                <td v-if="header === 'Factory'" :key="index" @click="editCell(stockTableName, row.id, 'factory', $event)">{{ row.factory }}</td>
                <td v-if="header === 'Line'" :key="index" @click="editCell(stockTableName, row.id, 'line', $event)" >{{ row.line }}</td>
                <td v-if="header === 'Material Code'" :key="index" >{{ row.materialcode }}</td>
                <td v-if="header === 'Material Name'" :key="index" >{{ row.materialname }}</td>
                <td v-if="header === 'Unit'" :key="index" >{{ row.unit }}</td>
                <td v-if="header === 'Base Qty'" :key="index" >{{ row.baseqty }}</td>
                <td v-if="header === 'Product Qty'" :key="index">{{ row.prOKquantity.toFixed(2) }}</td>
                <td v-if="header === 'Scrap Qty'" :key="index">{{ row.prNGquantity.toFixed(2) }}</td>
                <td v-if="header === 'Movement'" :key="index">{{ row.movement.toFixed(2) }}</td>
                <td v-if="header === 'Calculation Qty'" :key="index" >{{ row.calqty.toFixed(2) }}</td>
                <td v-if="header === 'Post'" :key="index" @click="editCell(stockTableName, row.id, 'post', $event)">{{ row.post }}</td>
                <td v-if="header === 'Difference'" :key="index" @click="editCell(stockTableName, row.id, 'difference', $event)">{{ row.difference }}</td>
                <td v-if="header === 'Final Qty'" :key="index">{{ row.finalqty.toFixed(2) }}</td>
                <td v-if="header === 'Daily Stock'" :key="index" @click="editCell(stockTableName, row.id, 'dailystock', $event)">{{ row.dailystock }}</td>
                <td v-if="header === 'Upload Daily'" :key="index">{{ row.uploadDaily }}</td>
                <td v-if="header === 'Plan'" :key="index">{{ row.planQuantity.toFixed(2) }}</td>
                <td v-if="header === 'Need'" :key="index">{{ row.need }}</td>
                <td v-if="header === 'SAP'" :key="index">{{ row.sapquantity }}</td>     
                <td v-if="header === 'Compare'" :key="index">{{ row.cd }}</td>
                <td v-if="header === 'D-S'" :key="index">{{ row.dsd }}</td>                
                <td v-if="header === 'INC/DEC'" :key="index">{{ row.incdec }}</td>
                <!--<td v-if="header === 'R-S'" :key="index">{{ row.rsd }}</td>-->
                <!--<td v-if="header === 'R-W'" :key="index">{{ row.rwd }}</td>-->
                <td v-if="header === 'Remark'" :key="index" @click="editCell(stockTableName, row.id, 'remark', $event)">{{ row.remark }}</td>           
                <td v-if="header === 'DEL'" :key="index" class="del"><button class="delButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick(row)" type="submit" @click="delRow(stockTableName, row.id)">DEL</button></td>
                <td v-if="header === 'SAP Init'" :key="index" @click="editCell(stockTableName, row.id, 'sapinit', $event)">{{ row.sapinit }}</td>
            </template>
          </tr>
        </table>                  
       </div>                  
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>            
      <moModal  v-if="moshowModal" @onClose="moshowModal = false; callSearchData();"/>        
  </div>  
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import { onFileChange, uploadExcel, deleteRow, deleteAll, downloadExcel, editCellValue, productionSearchData } from "../utils.js"; 
import { stockSearchData } from "./stockUtils"; 

import Modal from "./stockAdd.vue";
import moModal from "./movement/stockMovement.vue";

export default {      
    components: { Modal, moModal },    
    data() {
      return {
        formInputs: { newyear: new Date().getFullYear(), newmonth: new Date().getMonth() + 1, newday: new Date().getDate(), }, 
        saveStatus: '', isProcessing: false, excelFile: null, fileName: "",
      };
    },   
    methods: {
      async saveData() {
        this.isProcessing = true;
        this.saveStatus = 'Receiving...';
        await this.$nextTick();

        const columnMapping = {
          year: "year", month: "month", day: "day", factory: "factory", line: "line",
          materialcode: "materialcode", materialname: "materialname", unit: "unit",
          baseqty: "baseqty",  sapinit: "sapinit", remark: "remark", model: "model", row_no: "row_no",
        };

        const updatedData = this.item.map((row) => { 
          return {
            year: this.formInputs.newyear,
            month: this.formInputs.newmonth,
            day: this.formInputs.newday,
            factory: row.factory,
            line: row.line,
            materialcode: row.materialcode,
            materialname: row.materialname,
            unit: row.unit,
            baseqty: row.finalqty,            
            sapinit: row.sapinit,
            remark: row.remark,
            model: row.model,      
            row_no: row.row_no, 
          };
        });

        try {
          await axios.post('/api/postAll', { tableName: 'stock', columnMapping, values: updatedData });
          this.saveStatus = 'Finish';
        } catch (error) {
          console.error('Error saving data:', error);
          this.saveStatus = 'Error';
        }

        await this.$nextTick();
        this.isProcessing = false;
      },

      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.item, "Stock.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); }, 
      //delAll(tableName, filters) {deleteAll(tableName, filters, this.callSearchData); },         
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
           editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      },   

      async delAll() {
        try {          
          await deleteAll(this.stockTableName, { year: this.searchDate.year, month: this.searchDate.month, day: this.searchDate.day });
          await deleteAll(this.sapTableName, { year: this.searchDate.year, month: this.searchDate.month, day: this.searchDate.day });
          await deleteAll(this.dailyTableName, { year: this.searchDate.year, month: this.searchDate.month, day: this.searchDate.day });
          alert("All specified data in all tables deleted successfully.");
        } catch (error) {
          console.error("Error deleting data across tables:", error);
          alert("An error occurred while deleting the data.");
        }
      },

     canClick() {        
        const currentUser = this.$route.meta.currentUser;        
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 4 || currentUser.id === 17 || currentUser.id === 18;}
        else { return false;}
     },
     canAllDel() {        
        const currentUser = this.$route.meta.currentUser;        
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 17;}
        else { return false;}
     },
  },

    setup() {              
        const showModal = ref(false); 
        const moshowModal = ref(false); 
        const headers = ['Factory', 'Line', 'Material Code', 'Material Name', 'Unit', 'Base Qty', 'Product Qty', 'Scrap Qty', 'Movement',
                         'Calculation Qty', 'Post', 'Difference', 'Final Qty', 'Daily Stock', 'Upload Daily', 'Plan', 'Need', 'SAP', 'Compare', 'D-S', 'INC/DEC', 'Remark', 'DEL', 'SAP Init' ];         
        const searchDate = ref({ factory : '', year: '', month: '', day: '', compareYear: '', compareMonth: '', compareDay: '',});
        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day, searchDate.value.factory, searchDate.value.compareYear, searchDate.value.compareMonth, searchDate.value.compareDay,];
            return {sendDateData};             
        };            
        //const materialListTableName = 'materiallist';       

        const stockTableName = "stock"; const stockTableData = reactive({ data: [], }); const compareTableData = reactive({ data: [], });   
        const sapTableName = "stocksap"; const sapTableData = reactive({ data: [], }); const compareSapTableData = reactive({ data: [], });      
        const dailyTableName = "stockdaily"; const dailyTableData = reactive({ data: [], });
        const scrapTableName = "scrapmateriallog"; const scrapTableData = reactive({ data: [], });        
        const scrapProductTableName = "scrapproductlog"; const scrapProductTableData = reactive({ data: [], });    
        const movementTableName = "stockmovement"; const fromTableData = reactive({ data: [], }); const toTableData = reactive({ data: [], });
        const planTableName = "weekplan"; const planTableData = reactive({ data: [], });                    

        const tr3smt_dbTableName = 'tr3smtpr'; const tr3smt_prTableData = reactive({ data: [] });    
        const tr3smt_qwTableName = 'tr3smtqw'; const tr3smt_qwTableData = reactive({ data: [] });   
        const tr3crimping_dbTableName = 'tr3crimpingpr'; const tr3crimping_prTableData = reactive({ data: [] });
        const tr3crimping_qwTableName = 'tr3crimpingqw'; const tr3crimping_qwTableData = reactive({ data: [] });            
        const tr3fpca_dbTableName = 'tr3fpcapr'; const tr3fpca_prTableData = reactive({ data: [] });
        const tr3fpca_qwTableName = 'tr3fpcaqw'; const tr3fpca_qwTableData = reactive({ data: [] });
        const tr3bfa2p_dbTableName = 'tr3bfa2ppr'; const tr3bfa2p_prTableData = reactive({ data: [] });
        const tr3bfa2p_qwTableName = 'tr3bfa2pqw'; const tr3bfa2p_qwTableData = reactive({ data: [] });
        const tr3bfa3p_dbTableName = 'tr3bfa3ppr'; const tr3bfa3p_prTableData = reactive({ data: [] });
        const tr3bfa3p_qwTableName = 'tr3bfa3pqw'; const tr3bfa3p_qwTableData = reactive({ data: [] });
        const evo_dbTableName = 'evopr'; const evo_prTableData = reactive({ data: [] });
        const evo_qwTableName = 'evoqw'; const evo_qwTableData = reactive({ data: [] });
        const ev2020_60fr_dbTableName = 'ev2020_60frpr'; const ev2020_60fr_prTableData = reactive({ data: [] }); 
        const ev2020_60fr_qwTableName = 'ev2020_60frqw'; const ev2020_60fr_qwTableData = reactive({ data: [] });
        const ev2020_60rr_dbTableName = 'ev2020_60rrpr'; const ev2020_60rr_prTableData = reactive({ data: [] });
        const ev2020_60rr_qwTableName = 'ev2020_60rrqw'; const ev2020_60rr_qwTableData = reactive({ data: [] });
        const ev2020_4060fr_dbTableName = 'ev2020_4060frpr'; const ev2020_4060fr_prTableData = reactive({ data: [] });   
        const ev2020_4060fr_qwTableName = 'ev2020_4060frqw'; const ev2020_4060fr_qwTableData = reactive({ data: [] });
        const ev2020_4060rr_dbTableName = 'ev2020_4060rrpr'; const ev2020_4060rr_prTableData = reactive({ data: [] });  
        const ev2020_4060rr_qwTableName = 'ev2020_4060rrqw'; const ev2020_4060rr_qwTableData = reactive({ data: [] });

        const tr2smt_dbTableName = 'tr2smtpr'; const tr2smt_prTableData = reactive({ data: [] });   
        const tr2smt_qwTableName = 'tr2smtqw'; const tr2smt_qwTableData = reactive({ data: [] });
        const tr2crimping_dbTableName = 'tr2crimpingpr'; const tr2crimping_prTableData = reactive({ data: [] }); 
        const tr2crimping_qwTableName = 'tr2crimpingqw'; const tr2crimping_qwTableData = reactive({ data: [] });
        const tr2fpca_dbTableName = 'tr2fpcapr'; const tr2fpca_prTableData = reactive({ data: [] });   
        const tr2fpca_qwTableName = 'tr2fpcaqw'; const tr2fpca_qwTableData = reactive({ data: [] });
        const tr2bfa2p_dbTableName = 'tr2bfa2ppr'; const tr2bfa2p_prTableData = reactive({ data: [] });     
        const tr2bfa2p_qwTableName = 'tr2bfa2pqw'; const tr2bfa2p_qwTableData = reactive({ data: [] });
        const tr2bfa3p_dbTableName = 'tr2bfa3ppr'; const tr2bfa3p_prTableData = reactive({ data: [] }); 
        const tr2bfa3p_qwTableName = 'tr2bfa3pqw'; const tr2bfa3p_qwTableData = reactive({ data: [] });  
        const ford1fr_dbTableName = 'ford1frpr'; const ford1fr_prTableData = reactive({ data: [] });       
        const ford1fr_qwTableName = 'ford1frqw'; const ford1fr_qwTableData = reactive({ data: [] });
        const ford1rr_dbTableName = 'ford1rrpr'; const ford1rr_prTableData = reactive({ data: [] });     
        const ford1rr_qwTableName = 'ford1rrqw'; const ford1rr_qwTableData = reactive({ data: [] });
        const ford2fr_dbTableName = 'ford2frpr'; const ford2fr_prTableData = reactive({ data: [] });    
        const ford2fr_qwTableName = 'ford2frqw'; const ford2fr_qwTableData = reactive({ data: [] });
        const ford2rr_dbTableName = 'ford2rrpr'; const ford2rr_prTableData = reactive({ data: [] });    
        const ford2rr_qwTableName = 'ford2rrqw'; const ford2rr_qwTableData = reactive({ data: [] });
        const ford3fr_dbTableName = 'ford3frpr'; const ford3fr_prTableData = reactive({ data: [] });   
        const ford3fr_qwTableName = 'ford3frqw'; const ford3fr_qwTableData = reactive({ data: [] });
        const ford3rr_dbTableName = 'ford3rrpr'; const ford3rr_prTableData = reactive({ data: [] });     
        const ford3rr_qwTableName = 'ford3rrqw'; const ford3rr_qwTableData = reactive({ data: [] });
        const fca1_dbTableName = 'fca1pr'; const fca1_prTableData = reactive({ data: [] });      
        const fca1_qwTableName = 'fca1qw'; const fca1_qwTableData = reactive({ data: [] });
        const fca2_dbTableName = 'fca2pr'; const fca2_prTableData = reactive({ data: [] });     
        const fca2_qwTableName = 'fca2qw'; const fca2_qwTableData = reactive({ data: [] });
        const bt6_dbTableName = 'bt6pr'; const bt6_prTableData = reactive({ data: [] });      
        const bt6_qwTableName = 'bt6qw'; const bt6_qwTableData = reactive({ data: [] });
        const bmw12v_dbTableName = 'bmw12vpr'; const bmw12v_prTableData = reactive({ data: [] });     
        const bmw12v_qwTableName = 'bmw12vqw'; const bmw12v_qwTableData = reactive({ data: [] });
        const bmw48v_dbTableName = 'bmw48vpr'; const bmw48v_prTableData = reactive({ data: [] });   
        const bmw48v_qwTableName = 'bmw48vqw'; const bmw48v_qwTableData = reactive({ data: [] });
        const fca_flex_bc_dbTableName = 'fca_flex_bcpr'; const fca_flex_bc_prTableData = reactive({ data: [] });        
        const fca_flex_bc_qwTableName = 'fca_flex_bcqw'; const fca_flex_bc_qwTableData = reactive({ data: [] });
        const fca_flex_67_dbTableName = 'fca_flex_67pr'; const fca_flex_67_prTableData = reactive({ data: [] });       
        const fca_flex_67_qwTableName = 'fca_flex_67qw'; const fca_flex_67_qwTableData = reactive({ data: [] });
        const fca_flex_ne_dbTableName = 'fca_flex_nepr'; const fca_flex_ne_prTableData = reactive({ data: [] });       
        const fca_flex_ne_qwTableName = 'fca_flex_neqw'; const fca_flex_ne_qwTableData = reactive({ data: [] });
        const fca_flex_45_dbTableName = 'fca_flex_45pr'; const fca_flex_45_prTableData = reactive({ data: [] });       
        const fca_flex_45_qwTableName = 'fca_flex_45qw'; const fca_flex_45_qwTableData = reactive({ data: [] });
        const fca_flex_po_dbTableName = 'fca_flex_popr'; const fca_flex_po_prTableData = reactive({ data: [] });
        const fca_flex_po_qwTableName = 'fca_flex_poqw'; const fca_flex_po_qwTableData = reactive({ data: [] });
          
        const mainSearchData = async () => {
          try {
            const { sendDateData } = await sendDate();
            const fetchedData = await stockSearchData(sendDateData);     
            
            stockTableData.data = fetchedData[stockTableName].data;
            sapTableData.data = fetchedData[sapTableName].data;
            dailyTableData.data = fetchedData[dailyTableName].data;
            scrapTableData.data = fetchedData[scrapTableName].data;
            scrapProductTableData.data = fetchedData[scrapProductTableName].data;
            planTableData.data = fetchedData[planTableName].data;  

          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        };   

        const compareSearchData = async (tableName) => {          
          const sendDateData = await sendDate();          
          const formData = {
            tableName: tableName,
            values: {                
              year: sendDateData.sendDateData[4],
              month: sendDateData.sendDateData[5],
              day: sendDateData.sendDateData[6],   
              factory: sendDateData.sendDateData[3],                                         
            }
          };
          console.log('Requesting data with:', formData);

          axios.get('/api/stock', { params: formData })
            .then((res) => {                         
                switch (tableName) {
                  case 'stock': compareTableData.data = res.data;  break;     
                  case 'stocksap': compareSapTableData.data = res.data;  break;                      
                }
            });
        };

        const fromSearchData = async (tableName) => {
          const sendDateData = await sendDate();
          const formData = {
            tableName: tableName,
            values: {                
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],                            
              fromlocation: sendDateData.sendDateData[3],    
            }
          };
          console.log('Requesting data with:', formData);

          axios.get('/api/stock', { params: formData })
            .then((res) => { fromTableData.data = res.data;});
        };

        const toSearchData = async (tableName) => {
          const sendDateData = await sendDate();
          const formData = {
            tableName: tableName,
            values: {                
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],                            
              tolocation: sendDateData.sendDateData[3],        
            }
          };
          console.log('Requesting data with:', formData);

          axios.get('/api/stock', { params: formData })
            .then((res) => { toTableData.data = res.data;});
        };   

      const searchData = async () => {
        try {
          const { sendDateData } = await sendDate();
          const fetchedData = await productionSearchData(sendDateData);

          tr3smt_prTableData.data = [];
          tr3smt_qwTableData.data = [];
          tr3crimping_prTableData.data = [];
          tr3crimping_qwTableData.data = [];
          tr3fpca_prTableData.data = [];
          tr3fpca_qwTableData.data = [];
          tr3bfa2p_prTableData.data = [];
          tr3bfa2p_qwTableData.data = [];
          tr3bfa3p_prTableData.data = [];
          tr3bfa3p_qwTableData.data = [];
          evo_prTableData.data = [];
          evo_qwTableData.data = [];
          ev2020_60fr_prTableData.data = [];
          ev2020_60fr_qwTableData.data = [];
          ev2020_60rr_prTableData.data = [];
          ev2020_60rr_qwTableData.data = [];
          ev2020_4060fr_prTableData.data = [];
          ev2020_4060fr_qwTableData.data = [];
          ev2020_4060rr_prTableData.data = [];
          ev2020_4060rr_qwTableData.data = [];
          tr2smt_prTableData.data = [];
          tr2smt_qwTableData.data = [];
          tr2crimping_prTableData.data = [];
          tr2crimping_qwTableData.data = [];
          tr2fpca_prTableData.data = [];
          tr2fpca_qwTableData.data = [];
          tr2bfa2p_prTableData.data = [];
          tr2bfa2p_qwTableData.data = [];
          tr2bfa3p_prTableData.data = [];
          tr2bfa3p_qwTableData.data = [];
          ford1fr_prTableData.data = [];
          ford1fr_qwTableData.data = [];
          ford1rr_prTableData.data = [];
          ford1rr_qwTableData.data = [];
          ford2fr_prTableData.data = [];
          ford2fr_qwTableData.data = [];
          ford2rr_prTableData.data = [];
          ford2rr_qwTableData.data = [];
          ford3fr_prTableData.data = [];
          ford3fr_qwTableData.data = [];
          ford3rr_prTableData.data = [];
          ford3rr_qwTableData.data = [];
          fca1_prTableData.data = [];
          fca1_qwTableData.data = [];
          fca2_prTableData.data = [];
          fca2_qwTableData.data = [];
          bt6_prTableData.data = [];
          bt6_qwTableData.data = [];
          bmw12v_prTableData.data = [];
          bmw12v_qwTableData.data = [];
          bmw48v_prTableData.data = [];
          bmw48v_qwTableData.data = [];
          fca_flex_bc_prTableData.data = [];
          fca_flex_bc_qwTableData.data = [];
          fca_flex_po_prTableData.data = [];
          fca_flex_po_qwTableData.data = [];
          fca_flex_67_prTableData.data = [];
          fca_flex_67_qwTableData.data = [];
          fca_flex_ne_prTableData.data = [];
          fca_flex_ne_qwTableData.data = [];
          fca_flex_45_prTableData.data = [];
          fca_flex_45_qwTableData.data = [];    
        
          tr2smt_prTableData.data = fetchedData[tr2smt_dbTableName].data;
          tr2smt_qwTableData.data = fetchedData[tr2smt_qwTableName].data;          
          tr2crimping_prTableData.data = fetchedData[tr2crimping_dbTableName].data;                              
          tr2crimping_qwTableData.data = fetchedData[tr2crimping_qwTableName].data;
          tr2fpca_prTableData.data = fetchedData[tr2fpca_dbTableName].data;    
          tr2fpca_qwTableData.data = fetchedData[tr2fpca_qwTableName].data;
          tr2bfa2p_prTableData.data = fetchedData[tr2bfa2p_dbTableName].data;    
          tr2bfa2p_qwTableData.data = fetchedData[tr2bfa2p_qwTableName].data;
          tr2bfa3p_prTableData.data = fetchedData[tr2bfa3p_dbTableName].data;    
          tr2bfa3p_qwTableData.data = fetchedData[tr2bfa3p_qwTableName].data;
          ford1fr_prTableData.data = fetchedData[ford1fr_dbTableName].data;    
          ford1fr_qwTableData.data = fetchedData[ford1fr_qwTableName].data;      
          ford1rr_prTableData.data = fetchedData[ford1rr_dbTableName].data;    
          ford1rr_qwTableData.data = fetchedData[ford1rr_qwTableName].data;    
          ford2fr_prTableData.data = fetchedData[ford2fr_dbTableName].data;    
          ford2fr_qwTableData.data = fetchedData[ford2fr_qwTableName].data;    
          ford2rr_prTableData.data = fetchedData[ford2rr_dbTableName].data;    
          ford2rr_qwTableData.data = fetchedData[ford2rr_qwTableName].data;    
          ford3fr_prTableData.data = fetchedData[ford3fr_dbTableName].data;    
          ford3fr_qwTableData.data = fetchedData[ford3fr_qwTableName].data;    
          ford3rr_prTableData.data = fetchedData[ford3rr_dbTableName].data;    
          ford3rr_qwTableData.data = fetchedData[ford3rr_qwTableName].data;  
          fca1_prTableData.data = fetchedData[fca1_dbTableName].data;    
          fca1_qwTableData.data = fetchedData[fca1_qwTableName].data;    
          fca2_prTableData.data = fetchedData[fca2_dbTableName].data;    
          fca2_qwTableData.data = fetchedData[fca2_qwTableName].data;   
          bt6_prTableData.data = fetchedData[bt6_dbTableName].data;    
          bt6_qwTableData.data = fetchedData[bt6_qwTableName].data;     
          bmw12v_prTableData.data = fetchedData[bmw12v_dbTableName].data;    
          bmw12v_qwTableData.data = fetchedData[bmw12v_qwTableName].data;    
          bmw48v_prTableData.data = fetchedData[bmw48v_dbTableName].data;    
          bmw48v_qwTableData.data = fetchedData[bmw48v_qwTableName].data;    
          fca_flex_bc_prTableData.data = fetchedData[fca_flex_bc_dbTableName].data;    
          fca_flex_bc_qwTableData.data = fetchedData[fca_flex_bc_qwTableName].data;    
          fca_flex_po_prTableData.data = fetchedData[fca_flex_po_dbTableName].data;    
          fca_flex_po_qwTableData.data = fetchedData[fca_flex_po_qwTableName].data;    
          fca_flex_67_prTableData.data = fetchedData[fca_flex_67_dbTableName].data;    
          fca_flex_67_qwTableData.data = fetchedData[fca_flex_67_qwTableName].data;    
          fca_flex_ne_prTableData.data = fetchedData[fca_flex_ne_dbTableName].data;    
          fca_flex_ne_qwTableData.data = fetchedData[fca_flex_ne_qwTableName].data;    
          fca_flex_45_prTableData.data = fetchedData[fca_flex_45_dbTableName].data;    
          fca_flex_45_qwTableData.data = fetchedData[fca_flex_45_qwTableName].data; 
      
          tr3smt_prTableData.data = fetchedData[tr3smt_dbTableName].data;  
          tr3smt_qwTableData.data = fetchedData[tr3smt_qwTableName].data;
          tr3crimping_prTableData.data = fetchedData[tr3crimping_dbTableName].data;    
          tr3crimping_qwTableData.data = fetchedData[tr3crimping_qwTableName].data;
          tr3fpca_prTableData.data = fetchedData[tr3fpca_dbTableName].data;
          tr3fpca_qwTableData.data = fetchedData[tr3fpca_qwTableName].data;
          tr3bfa2p_prTableData.data = fetchedData[tr3bfa2p_dbTableName].data;    
          tr3bfa2p_qwTableData.data = fetchedData[tr3bfa2p_qwTableName].data;
          tr3bfa3p_prTableData.data = fetchedData[tr3bfa3p_dbTableName].data;    
          tr3bfa3p_qwTableData.data = fetchedData[tr3bfa3p_qwTableName].data;   
          evo_prTableData.data = fetchedData[evo_dbTableName].data;    
          evo_qwTableData.data = fetchedData[evo_qwTableName].data;   
          ev2020_60fr_prTableData.data = fetchedData[ev2020_60fr_dbTableName].data;    
          ev2020_60fr_qwTableData.data = fetchedData[ev2020_60fr_qwTableName].data;   
          ev2020_60rr_prTableData.data = fetchedData[ev2020_60rr_dbTableName].data;    
          ev2020_60rr_qwTableData.data = fetchedData[ev2020_60rr_qwTableName].data;     
          ev2020_4060fr_prTableData.data = fetchedData[ev2020_4060fr_dbTableName].data;    
          ev2020_4060fr_qwTableData.data = fetchedData[ev2020_4060fr_qwTableName].data;   
          ev2020_4060rr_prTableData.data = fetchedData[ev2020_4060rr_dbTableName].data;    
          ev2020_4060rr_qwTableData.data = fetchedData[ev2020_4060rr_qwTableName].data;     
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      };

        const callSearchData = () => {  //Data need add
            mainSearchData();        
            compareSearchData(stockTableName);    
            compareSearchData(sapTableName);   
            fromSearchData(movementTableName);            
            toSearchData(movementTableName);    
            searchData();                 
        };            
        
        const item = computed(() => {
          const productionData = processProductionData();
          const planData = processPlanData();          
          return stockTableData.data.map(d => {
            const okValue = (productionData[d.materialcode]?.ok || 0) * -1;              //아래 getProductScrapQuantity는 스크랩을 했을때 완제품에 대한 수량을 마이너스 하기 위해 있는 것이다. 그런데 이기능을 사용하면 2번 스크랩 하는 결과가 된다.
            //const prNGquantity = (getScrapQuantity(d.materialcode, scrap_item_sum.value)+getProductScrapQuantity(d.materialcode, scrapProduct_item_sum.value)) * -1;            
            const prNGquantity = getScrapQuantity(d.materialcode, scrap_item_sum.value) * -1;            
            const fromquantity = getFromQuantity (d.materialcode, from_item_sum.value);            
            const toquantity = getToQuantity (d.materialcode, to_item_sum.value);   
            let sapquantity = getSapQuantity (d.materialcode, sap_item_sum.value) + d.sapinit;   
            let dailyquantity = getSapQuantity (d.materialcode, daily_item_sum.value);
            const compareDailyStock = getCompareQuantity (d.materialcode, compare_item_sum.value);   
            const compareSap = getCompareSapQuantity (d.materialcode, compare_sap_item_sum.value);   

            let sapquantityToFixed = sapquantity !== null ? sapquantity.toFixed(2) : null;
            let dailyquantityToFixed = dailyquantity !== null ? dailyquantity.toFixed(2) : null;
            const movement = toquantity - fromquantity             
            const calqty = d.baseqty + okValue + prNGquantity + movement;                        
            const finalqty = calqty + d.post+ d.difference;      
            const planQuantity = planData[d.materialcode]?.planquantity || 0;
            let need = finalqty - planQuantity;
                need = need < 0 ? Math.abs(need) : null;  
            let needToFixed = need !== null ? need.toFixed(2) : null;
            let dailyandsapdifference = d.dailystock + dailyquantity - sapquantity; 
            let dailyandsapdifferenceFixed = dailyandsapdifference !== null ? dailyandsapdifference.toFixed(2) : null;  
            let comparedifference = compareDailyStock - compareSap;
            let comparedifferenceFixed = comparedifference !== null ? comparedifference.toFixed(2) : null;  
            let incdec = dailyandsapdifferenceFixed - comparedifferenceFixed;
            let incdecFixed = incdec !== null ? incdec.toFixed(2) : null;            

            //let realandsapdifference = finalqty - sapquantity;     
            //let realandwebdifference = d.dailystock - d.baseqty - movement - d.difference;                 
            //let realandsapdifferenceToFixed = realandsapdifference !== null ? realandsapdifference.toFixed(2) : null;     
            //let realandwebdifferenceToFixed = realandwebdifference !== null ? realandwebdifference.toFixed(2) : null;     
            return { ...d, prOKquantity: okValue, prNGquantity: prNGquantity, sapquantity: sapquantityToFixed, movement: movement, 
                    calqty: calqty, finalqty: finalqty, planQuantity: planQuantity, need: needToFixed, dsd: dailyandsapdifferenceFixed,
                    cd: comparedifferenceFixed, incdec: incdecFixed, uploadDaily: dailyquantityToFixed
                    //rsd: realandsapdifferenceToFixed, rwd: realandwebdifferenceToFixed
                   };            
          });
        });
        
        const compare_item = computed(() => { return compareTableData.data.map(d => {return { ...d };});});      
        const sap_item = computed(() => { return sapTableData.data.map(d => {return { ...d };});});              
        const daily_item = computed(() => { return dailyTableData.data.map(d => {return { ...d };});});              
        const compare_sap_item = computed(() => { return compareSapTableData.data.map(d => {return { ...d };});});              
        const scrap_item = computed(() => { return scrapTableData.data.map(d => {return { ...d };});});    
        const scrapProduct_item = computed(() => { return scrapProductTableData.data.map(d => {return { ...d };});});    
        const from_item = computed(() => { return fromTableData.data.map(d => {return { ...d };});});   
        const to_item = computed(() => { return toTableData.data.map(d => {return { ...d };});});   
        const plan_item = computed(() => { return planTableData.data.map(d => {return { ...d };});});   
        const tr3smt_item = computed(() => { return tr3smt_prTableData.data.map(d => {return { ...d };});});    
        const tr3smt_qwItem = computed(() => {return tr3smt_qwTableData.data.map(d => {return { ...d,};});}); 
        const tr3crimping_item = computed(() => { return tr3crimping_prTableData.data.map(d => {return { ...d,};});});    
        const tr3crimping_qwItem = computed(() => {return tr3crimping_qwTableData.data.map(d => {return { ...d,};});});    
        const tr3fpca_item = computed(() => { return tr3fpca_prTableData.data.map(d => {return { ...d };});});    
        const tr3fpca_qwItem = computed(() => {return tr3fpca_qwTableData.data.map(d => {return { ...d,};});}); 
        const tr3bfa2p_item = computed(() => { return tr3bfa2p_prTableData.data.map(d => {return { ...d,};});});    
        const tr3bfa2p_qwItem = computed(() => {return tr3bfa2p_qwTableData.data.map(d => {return { ...d,};});});
        const tr3bfa3p_item = computed(() => { return tr3bfa3p_prTableData.data.map(d => {return { ...d,};});});    
        const tr3bfa3p_qwItem = computed(() => {return tr3bfa3p_qwTableData.data.map(d => {return { ...d,};});});    
        const evo_item = computed(() => { return evo_prTableData.data.map(d => {return { ...d,};});});    
        const evo_qwItem = computed(() => {return evo_qwTableData.data.map(d => {return { ...d,};});});    
        const ev2020_60fr_item = computed(() => { return ev2020_60fr_prTableData.data.map(d => {return { ...d,};});});    
        const ev2020_60fr_qwItem = computed(() => {return ev2020_60fr_qwTableData.data.map(d => {return { ...d,};});});    
        const ev2020_60rr_item = computed(() => { return ev2020_60rr_prTableData.data.map(d => {return { ...d,};});});    
        const ev2020_60rr_qwItem = computed(() => {return ev2020_60rr_qwTableData.data.map(d => {return { ...d,};});});    
        const ev2020_4060fr_item = computed(() => { return ev2020_4060fr_prTableData.data.map(d => {return { ...d,};});});    
        const ev2020_4060fr_qwItem = computed(() => {return ev2020_4060fr_qwTableData.data.map(d => {return { ...d,};});});    
        const ev2020_4060rr_item = computed(() => { return ev2020_4060rr_prTableData.data.map(d => {return { ...d,};});});    
        const ev2020_4060rr_qwItem = computed(() => {return ev2020_4060rr_qwTableData.data.map(d => {return { ...d,};});});    
        const tr2smt_item = computed(() => { return tr2smt_prTableData.data.map(d => {return { ...d };});});    
        const tr2smt_qwItem = computed(() => {return tr2smt_qwTableData.data.map(d => {return { ...d,};});});
        const tr2crimping_item = computed(() => { return tr2crimping_prTableData.data.map(d => {return { ...d,};});});    
        const tr2crimping_qwItem = computed(() => {return tr2crimping_qwTableData.data.map(d => {return { ...d,};});});    
        const tr2fpca_item = computed(() => { return tr2fpca_prTableData.data.map(d => {return { ...d,};});});    
        const tr2fpca_qwItem = computed(() => {return tr2fpca_qwTableData.data.map(d => {return { ...d,};});});
        const tr2bfa2p_item = computed(() => { return tr2bfa2p_prTableData.data.map(d => {return { ...d,};});});    
        const tr2bfa2p_qwItem = computed(() => {return tr2bfa2p_qwTableData.data.map(d => {return { ...d,};});});
        const tr2bfa3p_item = computed(() => { return tr2bfa3p_prTableData.data.map(d => {return { ...d,};});});    
        const tr2bfa3p_qwItem = computed(() => {return tr2bfa3p_qwTableData.data.map(d => {return { ...d,};});}); 
        const ford1fr_item = computed(() => { return ford1fr_prTableData.data.map(d => {return { ...d,};});});    
        const ford1rr_item = computed(() => { return ford1rr_prTableData.data.map(d => {return { ...d,};});});    
        const ford2fr_item = computed(() => { return ford2fr_prTableData.data.map(d => {return { ...d,};});});    
        const ford2rr_item = computed(() => { return ford2rr_prTableData.data.map(d => {return { ...d,};});});    
        const ford3fr_item = computed(() => { return ford3fr_prTableData.data.map(d => {return { ...d,};});});    
        const ford3rr_item = computed(() => { return ford3rr_prTableData.data.map(d => {return { ...d,};});});    
        const fca1_item = computed(() => { return fca1_prTableData.data.map(d => {return { ...d,};});});    
        const fca1_qwItem = computed(() => {return fca1_qwTableData.data.map(d => {return { ...d,};});});    
        const fca2_item = computed(() => { return fca2_prTableData.data.map(d => {return { ...d,};});});    
        const fca2_qwItem = computed(() => {return fca2_qwTableData.data.map(d => {return { ...d,};});});    
        const bt6_item = computed(() => { return bt6_prTableData.data.map(d => {return { ...d,};});});    
        const bt6_qwItem = computed(() => {return bt6_qwTableData.data.map(d => {return { ...d,};});});    
        const bmw12v_item = computed(() => { return bmw12v_prTableData.data.map(d => {return { ...d,};});});    
        const bmw12v_qwItem = computed(() => {return bmw12v_qwTableData.data.map(d => {return { ...d,};});});    
        const bmw48v_item = computed(() => { return bmw48v_prTableData.data.map(d => {return { ...d,};});});    
        const bmw48v_qwItem = computed(() => {return bmw48v_qwTableData.data.map(d => {return { ...d,};});}); 
        const fca_flex_bc_item = computed(() => { return fca_flex_bc_prTableData.data.map(d => {return { ...d,};});});    
        const fca_flex_bc_qwItem = computed(() => {return fca_flex_bc_qwTableData.data.map(d => {return { ...d,};});});  
        const fca_flex_po_item = computed(() => { return fca_flex_po_prTableData.data.map(d => {return { ...d,};});});    
        const fca_flex_po_qwItem = computed(() => {return fca_flex_po_qwTableData.data.map(d => {return { ...d,};});});  
        const fca_flex_67_item = computed(() => { return fca_flex_67_prTableData.data.map(d => {return { ...d,};});});    
        const fca_flex_67_qwItem = computed(() => {return fca_flex_67_qwTableData.data.map(d => {return { ...d,};});});  
        const fca_flex_ne_item = computed(() => { return fca_flex_ne_prTableData.data.map(d => {return { ...d,};});});    
        const fca_flex_ne_qwItem = computed(() => {return fca_flex_ne_qwTableData.data.map(d => {return { ...d,};});});  
        const fca_flex_45_item = computed(() => { return fca_flex_45_prTableData.data.map(d => {return { ...d,};});});    
        const fca_flex_45_qwItem = computed(() => {return fca_flex_45_qwTableData.data.map(d => {return { ...d,};});});  

        //데이터 더하기 -시작-  

        const calculateSums = (itemValues, modelSums) => {
          for (const row of itemValues) {            
            const model = row.model || "";
            if (Object.prototype.hasOwnProperty.call(modelSums, model)) {
              modelSums[model].ok += row.ok;
              modelSums[model].ng += row.ng;
            }
          }
          return modelSums;
        };

        const calculateQwSums = (qwItemValues, qwmodelSums) => {
          for (const row of qwItemValues) {
            // if model property is not present, default to an empty string
            const model = row.model || "";
            if (Object.prototype.hasOwnProperty.call(qwmodelSums, model)) {
              qwmodelSums[model].ng += row.ng;
            }
          }
          return qwmodelSums;
        };

        const sums = computed(() => {  //설비마다 모델들을 추가해야 함 
          let tr3smt_modelSums = { "2P12S": { ok: 0, ng: 0 }, "3P8S": { ok: 0, ng: 0 }, "EVO_2P": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr3smt_qwmodelSums = { "2P12S": { ng: 0 }, "3P8S": { ng: 0 }, "EVO_2P": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let tr3crimping_modelSums = { "2P12S": { ok: 0, ng: 0 }, "3P8S": { ok: 0, ng: 0 }, "EVO_2P": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr3crimping_qwmodelSums = { "2P12S": { ng: 0 }, "3P8S": { ng: 0 }, "EVO_2P": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let tr3fpca_modelSums = { "2P12S": { ok: 0, ng: 0 }, "3P8S": { ok: 0, ng: 0 }, "EVO_2P": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr3fpca_qwmodelSums = { "2P12S": { ng: 0 }, "3P8S": { ng: 0 }, "EVO_2P": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let tr3bfa2p_modelSums = { "2P12S": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr3bfa2p_qwmodelSums = { "2P12S": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let tr3bfa3p_modelSums = { "3P8S": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr3bfa3p_qwmodelSums = { "3P8S": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let evo_modelSums = { "EVO_2P": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let evo_qwmodelSums = { "EVO_2P": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let ev2020_60fr_modelSums = { "60kW Front": { ok: 0, ng: 0 }, };
          let ev2020_60fr_qwmodelSums = { "60kW Front": { ng: 0 },  };
          let ev2020_60rr_modelSums = { "60kW Rear": { ok: 0, ng: 0 }, };
          let ev2020_60rr_qwmodelSums = { "60kW Rear": { ng: 0 },  };
          let ev2020_4060fr_modelSums = { "40kW Front": { ok: 0, ng: 0 }, "60kW Front": { ok: 0, ng: 0 }, };
          let ev2020_4060fr_qwmodelSums = { "40kW Front": { ng: 0 }, "60kW Front": { ng: 0 },  };
          let ev2020_4060rr_modelSums = { "40kW Rear": { ok: 0, ng: 0 }, "60kW Rear": { ok: 0, ng: 0 }, };
          let ev2020_4060rr_qwmodelSums = { "40kW Rear": { ng: 0 }, "60kW Rear": { ng: 0 },  };

          let tr2smt_modelSums = { "2P12S": { ok: 0, ng: 0 }, "3P8S": { ok: 0, ng: 0 }, "EVO_2P": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr2smt_qwmodelSums = { "2P12S": { ng: 0 }, "3P8S": { ng: 0 }, "EVO_2P": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let tr2crimping_modelSums = { "2P12S": { ok: 0, ng: 0 }, "3P8S": { ok: 0, ng: 0 }, "EVO_2P": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr2crimping_qwmodelSums = { "2P12S": { ng: 0 }, "3P8S": { ng: 0 }, "EVO_2P": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let tr2fpca_modelSums = { "2P12S": { ok: 0, ng: 0 }, "3P8S": { ok: 0, ng: 0 }, "EVO_2P": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, };
          let tr2fpca_qwmodelSums = { "2P12S": { ng: 0 }, "3P8S": { ng: 0 }, "EVO_2P": { ng: 0 }, "EVO_3P": { ng: 0 }, };
          let tr2bfa2p_modelSums = { "2P12S": { ok: 0, ng: 0 }, "EVO_2P": { ok: 0, ng: 0 }, "2P_GEN.1": { ok: 0, ng: 0 }, };
          let tr2bfa2p_qwmodelSums = { "2P12S": { ng: 0 }, "EVO_2P": { ng: 0 }, "2P_GEN.1": { ng: 0 }, };
          let tr2bfa3p_modelSums = { "3P8S": { ok: 0, ng: 0 }, "EVO_3P": { ok: 0, ng: 0 }, "3P_GEN.1": { ok: 0, ng: 0 }, };
          let tr2bfa3p_qwmodelSums = { "3P8S": { ng: 0 }, "EVO_3P": { ng: 0 }, "3P_GEN.1": { ng: 0 }, };
          let ford1fr_modelSums = { "4P8S": { ok: 0, ng: 0}, "4P7S": { ok: 0, ng: 0}, "3P10S": { ok: 0, ng: 0}, "3P8S": { ok: 0, ng: 0}, };
          let ford1rr_modelSums = { "4P8S": { ok: 0, ng: 0}, "4P7S": { ok: 0, ng: 0}, "3P10S": { ok: 0, ng: 0}, "3P8S": { ok: 0, ng: 0}, };
          let ford2fr_modelSums = { "4P8S": { ok: 0, ng: 0}, "4P7S": { ok: 0, ng: 0}, "3P10S": { ok: 0, ng: 0}, "3P8S": { ok: 0, ng: 0}, };
          let ford2rr_modelSums = { "4P8S": { ok: 0, ng: 0}, "4P7S": { ok: 0, ng: 0}, "3P10S": { ok: 0, ng: 0}, "3P8S": { ok: 0, ng: 0}, };
          let ford3fr_modelSums = { "4P8S": { ok: 0, ng: 0}, "4P7S": { ok: 0, ng: 0}, "3P10S": { ok: 0, ng: 0}, "3P8S": { ok: 0, ng: 0}, };
          let ford3rr_modelSums = { "4P8S": { ok: 0, ng: 0}, "4P7S": { ok: 0, ng: 0}, "3P10S": { ok: 0, ng: 0}, "3P8S": { ok: 0, ng: 0}, };
          let fca1_modelSums = { "Front": { ok: 0, ng: 0}, "Rear": { ok: 0, ng: 0} };
          let fca1_qwmodelSums = { "Front": { ng: 0}, "Rear": { ng: 0} };
          let fca2_modelSums = { "Front": { ok: 0, ng: 0}, "Rear": { ok: 0, ng: 0} };
          let fca2_qwmodelSums = { "Front": { ng: 0}, "Rear": { ng: 0} };
          let bt6_modelSums = { "Front": { ok: 0, ng: 0}, "Rear": { ok: 0, ng: 0} };
          let bt6_qwmodelSums = { "Front": { ng: 0}, "Rear": { ng: 0} };
          let bmw12v_modelSums = { "F40 12V": { ok: 0, ng: 0}, "HAF": { ok: 0, ng: 0} };
          let bmw12v_qwmodelSums = { "F40 12V": { ng: 0}, "HAF": { ng: 0} };          
          let bmw48v_modelSums = { "ICB": { ok: 0, ng: 0},};
          let bmw48v_qwmodelSums = { "ICB": { ng: 0}, };
          let fca_flex_bc_modelSums = { "": { ok: 0, ng: 0 },  };
          let fca_flex_bc_qwmodelSums = { "": {  ng: 0 },  };
          let fca_flex_po_modelSums = { "": { ok: 0, ng: 0 },  };
          let fca_flex_po_qwmodelSums = { "": {  ng: 0 },  };
          let fca_flex_ne_modelSums = { "": { ok: 0, ng: 0 },  };
          let fca_flex_ne_qwmodelSums = { "": {  ng: 0 },  };
          let fca_flex_45_modelSums = { "": { ok: 0, ng: 0 },  };
          let fca_flex_45_qwmodelSums = { "": {  ng: 0 },  };
          let fca_flex_67_modelSums = { "": { ok: 0, ng: 0 },  };
          let fca_flex_67_qwmodelSums = { "": {  ng: 0 },  };

          tr3smt_modelSums = calculateSums(tr3smt_item.value, tr3smt_modelSums,);
          tr3smt_qwmodelSums = calculateQwSums(tr3smt_qwItem.value, tr3smt_qwmodelSums,);
          tr3crimping_modelSums = calculateSums(tr3crimping_item.value, tr3crimping_modelSums,);
          tr3crimping_qwmodelSums = calculateQwSums(tr3crimping_qwItem.value, tr3crimping_qwmodelSums,);
          tr3fpca_modelSums = calculateSums(tr3fpca_item.value, tr3fpca_modelSums,);
          tr3fpca_qwmodelSums = calculateQwSums(tr3fpca_qwItem.value, tr3fpca_qwmodelSums,);          
          tr3bfa2p_modelSums = calculateSums(tr3bfa2p_item.value, tr3bfa2p_modelSums,);
          tr3bfa2p_qwmodelSums = calculateQwSums(tr3bfa2p_qwItem.value, tr3bfa2p_qwmodelSums,);
          tr3bfa3p_modelSums = calculateSums(tr3bfa3p_item.value, tr3bfa3p_modelSums,);
          tr3bfa3p_qwmodelSums = calculateQwSums(tr3bfa3p_qwItem.value, tr3bfa3p_qwmodelSums,);
          evo_modelSums = calculateSums(evo_item.value, evo_modelSums,);
          evo_qwmodelSums = calculateQwSums(evo_qwItem.value, evo_qwmodelSums,);
          ev2020_60fr_modelSums = calculateSums(ev2020_60fr_item.value, ev2020_60fr_modelSums,);
          ev2020_60fr_qwmodelSums = calculateQwSums(ev2020_60fr_qwItem.value, ev2020_60fr_qwmodelSums,);
          ev2020_60rr_modelSums = calculateSums(ev2020_60rr_item.value, ev2020_60rr_modelSums,);
          ev2020_60rr_qwmodelSums = calculateQwSums(ev2020_60rr_qwItem.value, ev2020_60rr_qwmodelSums,);
          ev2020_4060fr_modelSums = calculateSums(ev2020_4060fr_item.value, ev2020_4060fr_modelSums,);
          ev2020_4060fr_qwmodelSums = calculateQwSums(ev2020_4060fr_qwItem.value, ev2020_4060fr_qwmodelSums,);
          ev2020_4060rr_modelSums = calculateSums(ev2020_4060rr_item.value, ev2020_4060rr_modelSums,);
          ev2020_4060rr_qwmodelSums = calculateQwSums(ev2020_4060rr_qwItem.value, ev2020_4060rr_qwmodelSums,);

          tr2smt_modelSums = calculateSums(tr2smt_item.value, tr2smt_modelSums,);
          tr2smt_qwmodelSums = calculateQwSums(tr2smt_qwItem.value, tr2smt_qwmodelSums,);
          tr2crimping_modelSums = calculateSums(tr2crimping_item.value, tr2crimping_modelSums,);
          tr2crimping_qwmodelSums = calculateQwSums(tr2crimping_qwItem.value, tr2crimping_qwmodelSums,);
          tr2fpca_modelSums = calculateSums(tr2fpca_item.value, tr2fpca_modelSums,);
          tr2fpca_qwmodelSums = calculateQwSums(tr2fpca_qwItem.value, tr2fpca_qwmodelSums,);          
          tr2bfa2p_modelSums = calculateSums(tr2bfa2p_item.value, tr2bfa2p_modelSums,);
          tr2bfa2p_qwmodelSums = calculateQwSums(tr2bfa2p_qwItem.value, tr2bfa2p_qwmodelSums,);
          tr2bfa3p_modelSums = calculateSums(tr2bfa3p_item.value, tr2bfa3p_modelSums,);
          tr2bfa3p_qwmodelSums = calculateQwSums(tr2bfa3p_qwItem.value, tr2bfa3p_qwmodelSums,);
          ford1fr_modelSums = calculateSums(ford1fr_item.value, ford1fr_modelSums,);
          ford1rr_modelSums = calculateSums(ford1rr_item.value, ford1rr_modelSums,);
          ford2fr_modelSums = calculateSums(ford2fr_item.value, ford2fr_modelSums,);
          ford2rr_modelSums = calculateSums(ford2rr_item.value, ford2rr_modelSums,);
          ford3fr_modelSums = calculateSums(ford3fr_item.value, ford3fr_modelSums,);
          ford3rr_modelSums = calculateSums(ford3rr_item.value, ford3rr_modelSums,);
          fca1_modelSums = calculateSums(fca1_item.value, fca1_modelSums,);
          fca1_qwmodelSums = calculateQwSums(fca1_qwItem.value, fca1_qwmodelSums,);
          fca2_modelSums = calculateSums(fca2_item.value, fca2_modelSums,);
          fca2_qwmodelSums = calculateQwSums(fca2_qwItem.value, fca2_qwmodelSums,);
          bt6_modelSums = calculateSums(bt6_item.value, bt6_modelSums,);
          bt6_qwmodelSums = calculateQwSums(bt6_qwItem.value, bt6_qwmodelSums,);
          bmw12v_modelSums = calculateSums(bmw12v_item.value, bmw12v_modelSums,);
          bmw12v_qwmodelSums = calculateQwSums(bmw12v_qwItem.value, bmw12v_qwmodelSums,);
          bmw48v_modelSums = calculateSums(bmw48v_item.value, bmw48v_modelSums,);
          bmw48v_qwmodelSums = calculateQwSums(bmw48v_qwItem.value, bmw48v_qwmodelSums,);
          fca_flex_bc_modelSums = calculateSums(fca_flex_bc_item.value, fca_flex_bc_modelSums,);
          fca_flex_bc_qwmodelSums = calculateQwSums(fca_flex_bc_qwItem.value, fca_flex_bc_qwmodelSums,);
          fca_flex_po_modelSums = calculateSums(fca_flex_po_item.value, fca_flex_po_modelSums,);
          fca_flex_po_qwmodelSums = calculateQwSums(fca_flex_po_qwItem.value, fca_flex_po_qwmodelSums,);
          fca_flex_ne_modelSums = calculateSums(fca_flex_ne_item.value, fca_flex_ne_modelSums,);
          fca_flex_ne_qwmodelSums = calculateQwSums(fca_flex_ne_qwItem.value, fca_flex_ne_qwmodelSums,);
          fca_flex_45_modelSums = calculateSums(fca_flex_45_item.value, fca_flex_45_modelSums,);
          fca_flex_45_qwmodelSums = calculateQwSums(fca_flex_45_qwItem.value, fca_flex_45_qwmodelSums,);
          fca_flex_67_modelSums = calculateSums(fca_flex_67_item.value, fca_flex_67_modelSums,);
          fca_flex_67_qwmodelSums = calculateQwSums(fca_flex_67_qwItem.value, fca_flex_67_qwmodelSums,);
          
          return { tr3smt_modelSums, tr3smt_qwmodelSums, tr3crimping_modelSums, tr3crimping_qwmodelSums,
                   tr3fpca_modelSums, tr3fpca_qwmodelSums, tr3bfa2p_modelSums, tr3bfa2p_qwmodelSums,
                   tr3bfa3p_modelSums, tr3bfa3p_qwmodelSums, evo_modelSums, evo_qwmodelSums,
                   ev2020_60fr_modelSums, ev2020_60fr_qwmodelSums, ev2020_60rr_modelSums, ev2020_60rr_qwmodelSums, 
                   ev2020_4060fr_modelSums, ev2020_4060fr_qwmodelSums, ev2020_4060rr_modelSums, ev2020_4060rr_qwmodelSums,            
                   tr2smt_modelSums, tr2smt_qwmodelSums, tr2crimping_modelSums, tr2crimping_qwmodelSums,
                   tr2fpca_modelSums, tr2fpca_qwmodelSums, tr2bfa2p_modelSums, tr2bfa2p_qwmodelSums,
                   tr2bfa3p_modelSums, tr2bfa3p_qwmodelSums,
                   ford1fr_modelSums, ford1rr_modelSums, ford2fr_modelSums, ford2rr_modelSums, ford3fr_modelSums, ford3rr_modelSums,
                   fca1_modelSums, fca1_qwmodelSums, fca2_modelSums, fca2_qwmodelSums,
                   bt6_modelSums, bt6_qwmodelSums, bmw12v_modelSums, bmw12v_qwmodelSums, bmw48v_modelSums, bmw48v_qwmodelSums,
                   fca_flex_po_modelSums, fca_flex_po_qwmodelSums, fca_flex_bc_modelSums, fca_flex_bc_qwmodelSums,
                   fca_flex_ne_modelSums, fca_flex_ne_qwmodelSums, fca_flex_45_modelSums, fca_flex_45_qwmodelSums,
                   fca_flex_67_modelSums, fca_flex_67_qwmodelSums,
          }
        });
        
        //데이터 더하기 -끝-    

        const data = ref([  //설비마다 제품별 코드들을 추가해야 함 완료 
          { sum_name: "tr3smt", "2P12S": "6202416", "3P8S": "6202413", "EVO_2P": "6202439", "EVO_3P": "6202437", },
          { sum_name: "tr3crimping", "2P12S": "6202415", "3P8S": "6202412", "EVO_2P": "6202438", "EVO_3P": "6202436", },
          { sum_name: "tr3fpca", "2P12S": "6202414", "3P8S": "6202411", "EVO_2P": "6202391", "EVO_3P": "6202392",},
          { sum_name: "tr3bfa2p", "2P12S": "2175902", "EVO_3P": "2189892"},
          { sum_name: "tr3bfa3p", "3P8S": "2175903", "EVO_3P": "2189892"},
          { sum_name: "evo", "EVO_2P": "2189891", "EVO_3P": "2189892"},
          { sum_name: "tr2smt", "2P12S": "6202416", "3P8S": "6202413", "EVO_2P": "6202439", "EVO_3P": "6202437", },
          { sum_name: "tr2crimping", "2P12S": "6202415", "3P8S": "6202412", "EVO_2P": "6202438", "EVO_3P": "6202436", },
          { sum_name: "tr2fpca", "2P12S": "6202414", "3P8S": "6202411", "EVO_2P": "6202391", "EVO_3P": "6202392",},
          { sum_name: "tr2bfa2p", "2P12S": "2175902", "EVO_2P": "2183515", "EVO_3P": "2189892", "2P_GEN.1": "2187932"},
          { sum_name: "tr2bfa3p", "3P8S": "2175903", "EVO_3P": "2189892", "3P_GEN.1": "2187933"},
          { sum_name: "fordfront", "4P8S": "2187237", "4P7S": "2187239", "3P10S": "2187241", "3P8S": "2187243"},
          { sum_name: "fordrear", "4P8S": "2187238", "4P7S": "2187240", "3P10S": "2187242", "3P8S": "2187244"},
          { sum_name: "ev2020", "60kW Front": "2183497", "60kW Rear": "2183498", "40kW Front": "2183495", "40kW Rear": "2183496"},
          { sum_name: "fca", "Front": "2182418", "Rear": "2182419", },
          { sum_name: "fcaflexpo", "": "2179721", },
          { sum_name: "fcaflexne", "": "2179722", },
          { sum_name: "fcaflex67", "": "2179723", },
          { sum_name: "fcaflex45", "": "2179724", },
          { sum_name: "fcaflexbc", "": "2182420", },
          { sum_name: "bt6", "Front": "2174220", "Rear": "2174221", },
          { sum_name: "bmw12v", "F40": "2179213", "HAF": "2174724", },
          { sum_name: "bmw48v", "ICB": "2184104", },
        ]);
        
        const bom = ref([
          { product_code: "2175902", "6202414": "1", "7640226": "1", "7644015": "2", "7640233": "2", "7640228": "1", "7640218": "1", "7640217": "1", "7640219": "1", "7640220": "1", "7640221": "9", "7640234": "2", "7640229": "1", },
          { product_code: "2175903", "6202411": "1", "7644015": "2", "7640233": "2", "7640234": "2", "7640227": "1", "7640230": "1", "7640223": "1", "7640222": "1", "7640224": "3", "7640225": "4", "7640231": "1", },
          { product_code: "2183515", "6202391": "1", "7651539": "1", "7651548": "1", "7655878": "2", "7651541": "1", "7651975": "1", "7654587": "2", "7651542": "1", "7651550": "1", "7651549": "1", "7651551": "1", "7651552": "1", "7651553": "9", "7651543": "1" },
/*EVO3P*/ { product_code: "2188903", "6202392": "1", "7659614": "1", "7657811": "2", "7644015": "2", "7651541": "1", "7651975": "1", "7659603": "2", "7659617": "1", "7651555": "1", "7651554": "1", "7651556": "3", "7651557": "4", "7659618": "1", "7657810": "2", },
/*EVO3P AD*/ { product_code: "2189892", "6202392": "1", "7659614": "1", "7657811": "2", "7644015": "2", "7651541": "1", "7651975": "1", "7659603": "2", "7659617": "1", "7651555": "1", "7651554": "1", "7651556": "3", "7651557": "4", "7659618": "1", "7657810": "2", },
/*EVO3P old*/ { product_code: "2183516", "6202392": "1", "7659614": "1", "7651548": "2", "7655878": "2", "7651541": "1", "7651975": "1", "7654587": "2", "7659617": "1", "7651555": "1", "7651554": "1", "7651556": "3", "7651557": "4", "7659618": "1", },
/*EVO2P*/ { product_code: "2189891", "6202391": "1", "7659614": "1", "7659615": "1", "7659616": "1", "7651550": "1", "7651549": "1", "7651551": "1", "7651552": "1", "7651553": "9", "7659603": "1", "7644015": "2", "7657811": "2", "7651541": "1", "7651975": "1", "7657810": "2", },
/*EVO MC25*/{ product_code: "2191390", "6202800": "1", "7659617": "1", "7659618": "1", "7659603": "2", "7651555": "1", "7651554": "1", "7651556": "3", "7651557": "4", "7662063": "1", "7662066": "1", "7657811": "2", "7657810": "2", "7651541": "1", "7651975": "1", },
/*EVO MC25*/{ product_code: "6202811", "7662067": "1", "7638650": "2", "7636914": "11", "7647255": "0.12",},          
/*EVO MC25*/{ product_code: "6202812", "6202811": "1", "7640241": "9",},
/*EVO MC25*/{ product_code: "6202800", "6202812": "1", "7640239": "1", "7640240": "1", "7634222": "0.2", "7634221": "0.015", "7662074": "0.395",},
          { product_code: "6202416", "7640236": "1", "7638650": "2", "7636914": "15", "7647255": "0.08",},
          { product_code: "6202415", "6202416": "1", "7640241": "13",},
          { product_code: "6202414", "6202415": "1", "7640238": "1", "7640240": "1", "7634222": "0.17", "7634221": "0.02", "7640244": "0.65",},
          { product_code: "6202413", "7640237": "1", "7638650": "2", "7636914": "11", "7647255": "0.08",},
          { product_code: "6202412", "6202413": "1", "7640241": "9",},
          { product_code: "6202411", "6202412": "1", "7640240": "1", "7634222": "0.17", "7634221": "0.02", "7640244": "0.65", "7640239": "1",},
          { product_code: "6202439", "7651561": "1", "7638650": "2", "7636914": "15", "7647255": "0.08",},
          { product_code: "6202438", "6202439": "1", "7640241": "13",},
          { product_code: "6202391", "6202438": "1", "7640238": "1", "7640240": "1", "7634222": "0.17", "7634221": "0.02", "7640244": "0.65", },
          { product_code: "6202437", "7651562": "1", "7638650": "2", "7636914": "11", "7647255": "0.08",},
          { product_code: "6202436", "6202437": "1", "7640241": "9",},
          { product_code: "6202392", "6202436": "1", "7640240": "1", "7634222": "0.17", "7634221": "0.02", "7640244": "0.65", "7640239": "1",},
          { product_code: "2187237", "7657768": "10", "7630711": "3", "7630712": "2", "7630703": "1", "7655313": "1", "7654582": "1", "7638040": "1", "7639513": "1", "7645502": "0.32", "7645142": "1.7", },
          { product_code: "2187238", "7657768": "8", "7630711": "4", "7630704": "1", "7655314": "1", "7654585": "1", "7638040": "1", "7645502": "0.3", "7645142": "1.2", "7639513": "1", },
          { product_code: "2187239", "7657768": "8", "7630711": "3", "7630712": "1", "7630705": "1", "7655315": "1", "7654582": "1", "7638040": "1", "7639513": "1", "7645502": "0.32", "7645142": "1.7", },
          { product_code: "2187240", "7657768": "8", "7630711": "3", "7630712": "1", "7630706": "1", "7655316": "1", "7654585": "1", "7638040": "1", "7639513": "1", "7645502": "0.3", "7645142": "1.2", },
          { product_code: "2187241", "7657768": "12", "7630714": "4", "7630713": "2", "7630707": "1", "7655317": "1", "7654582": "1", "7638040": "1", "7639513": "1", "7645502": "0.32", "7645142": "1.7", },
          { product_code: "2187242", "7657768": "10", "7630714": "5", "7630708": "1", "7655318": "1", "7654585": "1", "7638040": "1", "7639513": "1", "7645502": "0.3", "7645142": "1.2", },
          { product_code: "2187243", "7657768": "10", "7630714": "3", "7630713": "2", "7630709": "1", "7655319": "1", "7654582": "1", "7638040": "1", "7639513": "1", "7645502": "0.32", "7645142": "1.7", },
          { product_code: "2187244", "7657768": "8", "7630714": "4", "7630710": "1", "7655320": "1", "7654585": "1", "7638040": "1", "7639513": "1", "7645502": "0.3", "7645142": "1.2", },
          { product_code: "2183497", "7651622": "1", "7642426": "1", "7642427": "1", "7642425": "3", "7642428": "2", "7643804": "1", "7651624": "1", },
          { product_code: "2183498", "7651623": "1", "7642425": "4", "7642431": "1", "7647541": "0.001", "7643804": "1", "7651625": "1",},
          { product_code: "2183495", "7651616": "1", "7643809": "1", "7643810": "1", "7649436": "5", "7642428": "2", "7643804": "1", "7651619": "1", },
          { product_code: "2183496", "7651617": "1", "7649436": "6", "7642431": "1", "7647541": "0.001", "7643804": "1", "7651620": "1", },
          { product_code: "2182418", "7650223": "1", "7633728": "1", "7633729": "1", "7650222": "5", "7650220": "1", "7634221": "0.017", "7655680": "0.9", "7641444": "0.28", "7638615": "1", },
          { product_code: "2182419", "7633731": "1", "7650222": "6", "7650221": "1", "7634221": "0.017", "7655680": "0.6", "7641444": "0.22", "7638615": "1", },
          { product_code: "2179721", "7645462": "1", "7637224": "1", "7607230": "2", "7645458": "1", "7637228": "1", "7616538": "0.1", "7298688": "0.04", },
          { product_code: "2179722", "7645463": "1", "7607230": "3", "7637228": "1", "7645459": "1", "7298688": "0.04", "7645460": "1", },
          { product_code: "2179723", "7645465": "1", "7607230": "2", "7645459": "1", "7298688": "0.04", "7616538": "0.04", "764548": "1"},
          { product_code: "2179724", "7637713": "1", "7616538": "0.021", "7645459": "1", "7298688": "0.009", "7645458": "1", },
          { product_code: "2182420", "7638738": "1", "7650225": "1", "7650226": "1", "7650227": "1", "7650228": "1", "7650229": "1", "7650230": "1", "7638947": "3", "7638949": "3", "7650231": "1", "7638739": "1", "7650232": "1", "7638948": "2", "7642025": "1"},
          { product_code: "2174220", "7637845": "1", "7637843": "1", "7637836": "1", "7637838": "1", "7637840": "5", "7634221": "0.02", "7655680": "1.21", "7633724": "0.8", "7637847": "1", "7639497": "1", },
          { product_code: "2174221", "7637846": "1", "7637844": "1", "7637841": "6", "7634221": "0.02", "7655680": "1.08", "7633724": "0.7", "7637848": "1", "7639497": "1", },
          { product_code: "2179213", "7639221": "1", "7628908": "5", "7628909": "1", "7639504": "1", "7654556": "0.7", "7641444": "0.3", "7638614": "1", "7634221": "0.025", },
          { product_code: "2174724", "7638050": "1", "7638048": "1", "7638049": "1", "7628908": "5", "7634221": "0.025", "7654556": "0.7", "7638614": "1", "7641444": "1.2", },
          { product_code: "2184104", "7641801": "1", "7641803": "1", "7641804": "1", "7641920": "1", "7643716": "2", "7652319": "1", "7647713": "1", "7644751": "1", },
          { product_code: "2187932", "7658348": "1", "7644015": "2", "7657811": "2", "7640228": "1", "7640218": "1", "7640217": "1", "7640219": "1", "7640220": "1", "7640221": "9", "7640234": "2","7640229": "1","6202414": "1","7657810": "2", },
          { product_code: "2187933", "7658352": "1", "7644015": "2", "7657811": "2", "7640230": "1", "7640223": "1", "7640222": "1", "7640224": "3", "7640225": "4", "7640234": "2", "7640231": "1","6202411": "1", "7657810": "2", },
        ]);

      const plan_item_obj = computed(() => {
          const planItemObj = {};          
          for (const item of plan_item.value) {
              planItemObj[item.code] = { planquantity: item.planquantity };
          }          
          return planItemObj;
      });

      function planData() {
        const combinedData = Object.assign({}, plan_item_obj.value);
        const results = [];

        for (const code in combinedData) {
          const planquantity = plan_item_obj.value[code]?.planquantity || 0;
          const bomItem = bom.value.find(item => item.product_code === code);  
          
          if (!bomItem) continue;
          const materials = {};
          for (const material_code in bomItem) {
            if (material_code !== 'product_code') {
              materials[material_code] = {
                plan: planquantity * bomItem[material_code],
              };
            }
          }
          results.push({ code, materials });
        }
        return results;
      }

      function processPlanData() {
        const data = {};
        for (const result of planData()) {
          for (const [material_code, material] of Object.entries(result.materials)) {
            if (!data[material_code]) { data[material_code] = { planquantity: 0 }; }
            data[material_code].planquantity += material.plan;  // material.plan because the property in material object is 'plan'
          }
        }
        return data;
      }      

      const tr3smtpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3smt"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3smt_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr3smtqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3smt"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3smt_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr3crimpingpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3crimping"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3crimping_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr3crimpingqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3crimping"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3crimping_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });        
      const tr3fpcapr = computed(() => {
        if (sums.value && data.value) {            
          const mapping = data.value.find((item) => item.sum_name === "tr3fpca"); //설비마다 수정해야 됨           
          return transformSums(sums.value.tr3fpca_modelSums, mapping); //설비마다 수정해야 됨
        }
        return {};
      });    
      const tr3fpcaqw = computed(() => {
        if (sums.value && data.value) {            
          const mapping = data.value.find((item) => item.sum_name === "tr3fpca"); //설비마다 수정해야 됨           
          return transformSums(sums.value.tr3fpca_qwmodelSums, mapping); //설비마다 수정해야 됨
        }
        return {};
      });      
      const tr3bfa2ppr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3bfa2p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3bfa2p_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr3bfa2pqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3bfa2p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3bfa2p_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr3bfa3ppr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3bfa3p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3bfa3p_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr3bfa3pqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr3bfa3p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr3bfa3p_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const evopr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "evo"); //설비마다 수정해야 됨
          return transformSums(sums.value.evo_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const evoqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "evo"); //설비마다 수정해야 됨
          return transformSums(sums.value.evo_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_60frpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_60fr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_60frqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_60fr_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_60rrpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_60rr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_60rrqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_60rr_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_4060frpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_4060fr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_4060frqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_4060fr_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_4060rrpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_4060rr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ev2020_4060rrqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "ev2020"); //설비마다 수정해야 됨
          return transformSums(sums.value.ev2020_4060rr_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr2smtpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2smt"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2smt_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr2smtqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2smt"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2smt_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr2crimpingpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2crimping"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2crimping_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr2crimpingqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2crimping"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2crimping_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });        
      const tr2fpcapr = computed(() => {
        if (sums.value && data.value) {            
          const mapping = data.value.find((item) => item.sum_name === "tr2fpca"); //설비마다 수정해야 됨           
          return transformSums(sums.value.tr2fpca_modelSums, mapping); //설비마다 수정해야 됨
        }
        return {};
      });    
      const tr2fpcaqw = computed(() => {
        if (sums.value && data.value) {            
          const mapping = data.value.find((item) => item.sum_name === "tr2fpca"); //설비마다 수정해야 됨           
          return transformSums(sums.value.tr2fpca_qwmodelSums, mapping); //설비마다 수정해야 됨
        }
        return {};
      });      
      const tr2bfa2ppr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2bfa2p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2bfa2p_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr2bfa2pqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2bfa2p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2bfa2p_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr2bfa3ppr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2bfa3p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2bfa3p_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const tr2bfa3pqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "tr2bfa3p"); //설비마다 수정해야 됨
          return transformSums(sums.value.tr2bfa3p_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ford1frpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fordfront"); //설비마다 수정해야 됨
          return transformSums(sums.value.ford1fr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ford1rrpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fordrear"); //설비마다 수정해야 됨
          return transformSums(sums.value.ford1rr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ford2frpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fordfront"); //설비마다 수정해야 됨
          return transformSums(sums.value.ford2fr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ford2rrpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fordrear"); //설비마다 수정해야 됨
          return transformSums(sums.value.ford2rr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ford3frpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fordfront"); //설비마다 수정해야 됨
          return transformSums(sums.value.ford3fr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const ford3rrpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fordrear"); //설비마다 수정해야 됨
          return transformSums(sums.value.ford3rr_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fca1pr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fca"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca1_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fca1qw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fca"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca1_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fca2pr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fca"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca2_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fca2qw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fca"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca2_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const bt6pr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "bt6"); //설비마다 수정해야 됨
          return transformSums(sums.value.bt6_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const bt6qw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "bt6"); //설비마다 수정해야 됨
          return transformSums(sums.value.bt6_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const bmw12vpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "bmw12v"); //설비마다 수정해야 됨
          return transformSums(sums.value.bmw12v_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const bmw12vqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "bmw12v"); //설비마다 수정해야 됨
          return transformSums(sums.value.bmw12v_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const bmw48vpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "bmw48v"); //설비마다 수정해야 됨
          return transformSums(sums.value.bmw48v_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const bmw48vqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "bmw48v"); //설비마다 수정해야 됨
          return transformSums(sums.value.bmw48v_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflexbcpr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflexbc"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_bc_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflexbcqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflexbc"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_bc_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflexnepr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflexne"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_ne_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflexneqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflexne"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_ne_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflexpopr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflexpo"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_po_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflexpoqw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflexpo"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_po_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflex45pr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflex45"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_45_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflex45qw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflex45"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_45_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflex67pr = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflex67"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_67_modelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });
      const fcaflex67qw = computed(() => {          
        if (sums.value && data.value) {
          const mapping = data.value.find((item) => item.sum_name === "fcaflex67"); //설비마다 수정해야 됨
          return transformSums(sums.value.fca_flex_67_qwmodelSums, mapping); //설비마다 수정해야 됨
        }  
        return {};
      });

      function productionData() {  //설비마다 수정해야 됨
        const combinedData = Object.assign({}, 
                                           tr3smtpr.value, tr3crimpingpr.value, tr3fpcapr.value, tr3bfa2ppr.value, tr3bfa3ppr.value,
                                           tr3smtqw.value, tr3crimpingqw.value, tr3fpcaqw.value, tr3bfa2pqw.value, tr3bfa3pqw.value,
                                           evopr.value, ev2020_60frpr.value, ev2020_60rrpr.value, ev2020_4060frpr.value, ev2020_4060rrpr.value,                                      
                                           evoqw.value, ev2020_60frqw.value, ev2020_60rrqw.value, ev2020_4060frqw.value, ev2020_4060rrqw.value,                                      
                                           tr2smtpr.value, tr2crimpingpr.value, tr2fpcapr.value, tr2bfa2ppr.value, tr2bfa3ppr.value,
                                           tr2smtqw.value, tr2crimpingqw.value, tr2fpcaqw.value, tr2bfa2pqw.value, tr2bfa3pqw.value,
                                           ford1frpr.value, ford1rrpr.value, ford2frpr.value, ford2rrpr.value, ford3frpr.value, ford3rrpr.value,
                                           fca1pr.value, fca1qw.value, fca2pr.value, fca2qw.value, bt6pr.value, bt6qw.value,
                                           bmw12vpr.value, bmw12vqw.value, bmw48vpr.value, bmw48vqw.value,
                                           fcaflexbcpr.value, fcaflexbcqw.value, fcaflexpopr.value, fcaflexpoqw.value, fcaflexnepr.value, fcaflexneqw.value,
                                           fcaflex45pr.value, fcaflex45qw.value, fcaflex67pr.value, fcaflex67qw.value, );
        const results = [];

        for (const product_code in combinedData) {
          const tr3smtprOk = tr3smtpr.value[product_code]?.ok || 0;
          const tr3smtprNg = tr3smtpr.value[product_code]?.ng || 0;          
          const tr3smtqwNg = tr3smtqw.value[product_code]?.ng || 0;
          const tr3crimpingprOk = tr3crimpingpr.value[product_code]?.ok || 0;
          const tr3crimpingprNg = tr3crimpingpr.value[product_code]?.ng || 0;
          const tr3crimpingqwNg = tr3crimpingqw.value[product_code]?.ng || 0;
          const tr3fpcaprOk = tr3fpcapr.value[product_code]?.ok || 0;
          const tr3fpcaprNg = tr3fpcapr.value[product_code]?.ng || 0;
          const tr3fpcaqwNg = tr3fpcaqw.value[product_code]?.ng || 0;
          const tr3bfa2pprOk = tr3bfa2ppr.value[product_code]?.ok || 0;
          const tr3bfa2pprNg = tr3bfa2ppr.value[product_code]?.ng || 0;
          const tr3bfa2pqwNg = tr3bfa2pqw.value[product_code]?.ng || 0;
          const tr3bfa3pprOk = tr3bfa3ppr.value[product_code]?.ok || 0;
          const tr3bfa3pprNg = tr3bfa3ppr.value[product_code]?.ng || 0;
          const tr3bfa3pqwNg = tr3bfa3pqw.value[product_code]?.ng || 0;
          const evoprOk = evopr.value[product_code]?.ok || 0;
          const evoprNg = evopr.value[product_code]?.ng || 0;
          const evoqwNg = evoqw.value[product_code]?.ng || 0;
          const ev2020_60frprOk = ev2020_60frpr.value[product_code]?.ok || 0;
          const ev2020_60frprNg = ev2020_60frpr.value[product_code]?.ng || 0;
          const ev2020_60frqwNg = ev2020_60frqw.value[product_code]?.ng || 0;
          const ev2020_60rrprOk = ev2020_60rrpr.value[product_code]?.ok || 0;
          const ev2020_60rrprNg = ev2020_60rrpr.value[product_code]?.ng || 0;
          const ev2020_60rrqwNg = ev2020_60rrqw.value[product_code]?.ng || 0;
          const ev2020_4060frprOk = ev2020_4060frpr.value[product_code]?.ok || 0;
          const ev2020_4060frprNg = ev2020_4060frpr.value[product_code]?.ng || 0;
          const ev2020_4060frqwNg = ev2020_4060frqw.value[product_code]?.ng || 0;
          const ev2020_4060rrprOk = ev2020_4060rrpr.value[product_code]?.ok || 0;
          const ev2020_4060rrprNg = ev2020_4060rrpr.value[product_code]?.ng || 0;
          const ev2020_4060rrqwNg = ev2020_4060rrqw.value[product_code]?.ng || 0;
          const tr2smtprOk = tr2smtpr.value[product_code]?.ok || 0;
          const tr2smtprNg = tr2smtpr.value[product_code]?.ng || 0;          
          const tr2smtqwNg = tr2smtqw.value[product_code]?.ng || 0;
          const tr2crimpingprOk = tr2crimpingpr.value[product_code]?.ok || 0;
          const tr2crimpingprNg = tr2crimpingpr.value[product_code]?.ng || 0;
          const tr2crimpingqwNg = tr2crimpingqw.value[product_code]?.ng || 0;
          const tr2fpcaprOk = tr2fpcapr.value[product_code]?.ok || 0;
          const tr2fpcaprNg = tr2fpcapr.value[product_code]?.ng || 0;
          const tr2fpcaqwNg = tr2fpcaqw.value[product_code]?.ng || 0;
          const tr2bfa2pprOk = tr2bfa2ppr.value[product_code]?.ok || 0;
          const tr2bfa2pprNg = tr2bfa2ppr.value[product_code]?.ng || 0;
          const tr2bfa2pqwNg = tr2bfa2pqw.value[product_code]?.ng || 0;
          const tr2bfa3pprOk = tr2bfa3ppr.value[product_code]?.ok || 0;
          const tr2bfa3pprNg = tr2bfa3ppr.value[product_code]?.ng || 0;
          const tr2bfa3pqwNg = tr2bfa3pqw.value[product_code]?.ng || 0;
          const ford1frprOk = ford1frpr.value[product_code]?.ok || 0;
          const ford1rrprOk = ford1rrpr.value[product_code]?.ok || 0;
          const ford2frprOk = ford2frpr.value[product_code]?.ok || 0;
          const ford2rrprOk = ford2rrpr.value[product_code]?.ok || 0;
          const ford3frprOk = ford3frpr.value[product_code]?.ok || 0;
          const ford3rrprOk = ford3rrpr.value[product_code]?.ok || 0;
          const ford1frprNg = ford1frpr.value[product_code]?.ng || 0;
          const ford1rrprNg = ford1rrpr.value[product_code]?.ng || 0;
          const ford2frprNg = ford2frpr.value[product_code]?.ng || 0;
          const ford2rrprNg = ford2rrpr.value[product_code]?.ng || 0;
          const ford3frprNg = ford3frpr.value[product_code]?.ng || 0;
          const ford3rrprNg = ford3rrpr.value[product_code]?.ng || 0;
          const fca1prOk = fca1pr.value[product_code]?.ok || 0;
          const fca1prNg = fca1pr.value[product_code]?.ng || 0;
          const fca1qwNg = fca1qw.value[product_code]?.ng || 0;
          const fca2prOk = fca2pr.value[product_code]?.ok || 0;
          const fca2prNg = fca2pr.value[product_code]?.ng || 0;
          const fca2qwNg = fca2qw.value[product_code]?.ng || 0;
          const bt6prOk = bt6pr.value[product_code]?.ok || 0;
          const bt6prNg = bt6pr.value[product_code]?.ng || 0;
          const bt6qwNg = bt6qw.value[product_code]?.ng || 0;
          const bmw12vprOk = bmw12vpr.value[product_code]?.ok || 0;
          const bmw12vprNg = bmw12vpr.value[product_code]?.ng || 0;
          const bmw12vqwNg = bmw12vqw.value[product_code]?.ng || 0;
          const bmw48vprOk = bmw48vpr.value[product_code]?.ok || 0;
          const bmw48vprNg = bmw48vpr.value[product_code]?.ng || 0;
          const bmw48vqwNg = bmw48vqw.value[product_code]?.ng || 0;
          const fcaflexbcprOk = fcaflexbcpr.value[product_code]?.ok || 0;
          const fcaflexbcprNg = fcaflexbcpr.value[product_code]?.ng || 0;
          const fcaflexbcqwNg = fcaflexbcqw.value[product_code]?.ng || 0;
          const fcaflexpoprOk = fcaflexpopr.value[product_code]?.ok || 0;
          const fcaflexpoprNg = fcaflexpopr.value[product_code]?.ng || 0;
          const fcaflexpoqwNg = fcaflexpoqw.value[product_code]?.ng || 0;
          const fcaflexneprOk = fcaflexnepr.value[product_code]?.ok || 0;
          const fcaflexneprNg = fcaflexnepr.value[product_code]?.ng || 0;
          const fcaflexneqwNg = fcaflexneqw.value[product_code]?.ng || 0;
          const fcaflex45prOk = fcaflex45pr.value[product_code]?.ok || 0;
          const fcaflex45prNg = fcaflex45pr.value[product_code]?.ng || 0;
          const fcaflex45qwNg = fcaflex45qw.value[product_code]?.ng || 0;
          const fcaflex67prOk = fcaflex67pr.value[product_code]?.ok || 0;
          const fcaflex67prNg = fcaflex67pr.value[product_code]?.ng || 0;
          const fcaflex67qwNg = fcaflex67qw.value[product_code]?.ng || 0;

          const totalOk = tr3smtprOk + tr3crimpingprOk + tr3fpcaprOk + tr3bfa2pprOk + tr3bfa3pprOk + evoprOk + 
                          ev2020_60frprOk + ev2020_60rrprOk + ev2020_4060frprOk + ev2020_4060rrprOk + 
                          tr2smtprOk + tr2crimpingprOk + tr2fpcaprOk + tr2bfa2pprOk + tr2bfa3pprOk +
                          ford1frprOk + ford1rrprOk + ford2frprOk + ford2rrprOk + ford3frprOk + ford3rrprOk + 
                          fca1prOk + fca2prOk + bt6prOk + bmw12vprOk + bmw48vprOk + fcaflexbcprOk +
                          fcaflexpoprOk + fcaflexneprOk + fcaflex45prOk + fcaflex67prOk ;
          const totalNg = tr3smtprNg + tr3smtqwNg +tr3crimpingprNg + tr3crimpingqwNg + tr3fpcaprNg + tr3fpcaqwNg +
                          tr3bfa2pprNg + tr3bfa2pqwNg + tr3bfa3pprNg + tr3bfa3pqwNg + evoprNg + evoqwNg + 
                          ev2020_60frprNg + ev2020_60frqwNg + ev2020_60rrprNg + ev2020_60rrqwNg + 
                          ev2020_4060frprNg + ev2020_4060frqwNg + ev2020_4060rrprNg + ev2020_4060rrqwNg +       
                          tr2smtprNg + tr2crimpingprNg + tr2fpcaprNg + tr2bfa2pprNg + tr2bfa3pprNg +
                          ford1frprNg + ford1rrprNg + ford2frprNg + ford2rrprNg + ford3frprNg + ford3rrprNg + 
                          fca1prNg + fca2prNg + bt6prNg + bmw12vprNg + bmw48vprNg + fcaflexbcprNg +
                          fcaflexpoprNg + fcaflexneprNg + fcaflex45prNg + fcaflex67prNg + 
                          tr2smtqwNg + tr2crimpingqwNg + tr2fpcaqwNg + tr2bfa2pqwNg + tr2bfa3pqwNg +                          
                          fca1qwNg + fca2qwNg + bt6qwNg + bmw12vqwNg + bmw48vqwNg + fcaflexbcqwNg +
                          fcaflexpoqwNg + fcaflexneqwNg + fcaflex45qwNg + fcaflex67qwNg ;

          const bomItem = bom.value.find(item => item.product_code === product_code);
          if (!bomItem) continue;

          const materials = {};
          for (const material_code in bomItem) {
            if (material_code !== 'product_code') {
              materials[material_code] = {
                ok: totalOk * bomItem[material_code],
                ng: totalNg * bomItem[material_code]
              };
            }
          }
          results.push({ product_code, materials });
        }
        return results;
      }

      function processProductionData() {
          const data = {};
          for (const result of productionData()) {
              for (const [material_code, material] of Object.entries(result.materials)) {
                  if (!data[material_code]) { data[material_code] = { ok: 0, ng: 0, }; }
                  data[material_code].ok += material.ok;
                  data[material_code].ng += material.ng;
              }
          }
          return data; 
      }  

      function transformSums(sums, mapping) {
        if (!sums || !mapping) return {};    
          const transformed = Object.entries(sums).reduce((acc, [key, value]) => {
          const newKey = mapping[key];
          if (newKey) { acc[newKey] = value; }
          return acc;
        }, {});          
        return transformed;
      }    

      const compare_item_sum = computed(() => {
        const result = compare_item.value.reduce((acc, item) => {
          if (acc[item.materialcode]) {
            acc[item.materialcode].dailystock += item.dailystock;
          } else {
            acc[item.materialcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const compare_sap_item_sum = computed(() => {
        const result = compare_sap_item.value.reduce((acc, item) => {
          if (acc[item.materialcode]) {
            acc[item.materialcode].quantity += item.quantity;
          } else {
            acc[item.materialcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const sap_item_sum = computed(() => {
        const result = sap_item.value.reduce((acc, item) => {
          if (acc[item.materialcode]) {
            acc[item.materialcode].quantity += item.quantity;
          } else {
            acc[item.materialcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const daily_item_sum = computed(() => {
        const result = daily_item.value.reduce((acc, item) => {
          if (acc[item.materialcode]) {
            acc[item.materialcode].quantity += item.quantity;
          } else {
            acc[item.materialcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const scrap_item_sum = computed(() => {
        const result = scrap_item.value.reduce((acc, item) => {
          if (acc[item.materialcode]) {
            acc[item.materialcode].quantity += item.quantity;
          } else {
            acc[item.materialcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const scrapProduct_item_sum = computed(() => {
        const result = scrapProduct_item.value.reduce((acc, item) => {
          if (acc[item.productcode]) {
            acc[item.productcode].quantity += item.quantity;
          } else {
            acc[item.productcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const from_item_sum = computed(() => {
        const result = from_item.value.reduce((acc, item) => {
          if (acc[item.materialcode]) {
            acc[item.materialcode].quantity += item.quantity;
          } else {
            acc[item.materialcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const to_item_sum = computed(() => {
        const result = to_item.value.reduce((acc, item) => {
          if (acc[item.materialcode]) {
            acc[item.materialcode].quantity += item.quantity;
          } else {
            acc[item.materialcode] = { ...item };
          }
          return acc;
        }, {});

        return Object.values(result);
      });

      const getCompareQuantity = (materialcode, compareItems) => {
        const compareItem = compareItems.find(item => item.materialcode === materialcode);
        return compareItem ? compareItem.dailystock : 0;
      };

      const getCompareSapQuantity = (materialcode, compareSapItems) => {
        const compareSapItem = compareSapItems.find(item => item.materialcode === materialcode);
        return compareSapItem ? compareSapItem.quantity : 0;
      };

      const getSapQuantity = (materialcode, sapItems) => {
        const sapItem = sapItems.find(item => item.materialcode === materialcode);
        return sapItem ? sapItem.quantity : 0;
      };

      const getScrapQuantity = (materialcode, scrapItems) => {
        const scrapItem = scrapItems.find(item => item.materialcode === materialcode);
        return scrapItem ? scrapItem.quantity : 0;
      };

      const getProductScrapQuantity = (materialcode, scrapItems) => {
        const scrapItem = scrapItems.find(item => item.productcode === materialcode);
        return scrapItem ? scrapItem.quantity : 0;
      };

      const getFromQuantity = (materialcode, fromItems) => {
        const fromItem = fromItems.find(item => item.materialcode === materialcode);
        return fromItem ? fromItem.quantity : 0;
      };

      const getToQuantity = (materialcode, toItems) => {
        const toItem = toItems.find(item => item.materialcode === materialcode);
        return toItem ? toItem.quantity : 0;
      };   

      return { headers, showModal, moshowModal, callSearchData, sendDate,  
               searchData, searchDate, item, stockTableName, sapTableName, dailyTableName,                
               getScrapQuantity, scrap_item_sum, getProductScrapQuantity, scrapProduct_item_sum, 
    };
  },
}

</script>

<style scoped>
  .act { display: flex; padding: 10px 10px 5px 5px; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; } 

  .button {
    padding: 5px 5px;  background-color: #3498db;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-right: 3px;
    cursor: pointer; transition: background-color 0.3s;
  }

  .searchButton {
    padding: 5px 5px;  background-color: #94a5b1;
    color: #000000; border: none;
    border-radius: 4px; font-size: 14px; font-weight: 600;
    margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton {
    padding: 5px 5px; background-color: #10a802;
    color: #fff; border: none;
    border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer;
    transition: background-color 0.3s;
  }

  .modalButton {
    padding: 5px 5px;  background-color: #f3ac27;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 50px; margin-right: 10px;
    cursor: pointer; transition: background-color 0.3s;
  }

  .button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dailyButton {
    padding: 5px 5px; background-color: #310086;
    color: #fff; border: none;
    border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer;
    transition: background-color 0.3s;
    margin-left : 20px; margin-right : 20px;
  }

  .dailyButton:hover { background-color: #685688; }

  .excelButton:hover, .searchButton:hover, .qwallButton:hover, 
  .button:hover { background-color: #2980b9; }
    
  .searchBar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .searchBar > *:not(:last-child) { margin-right: 5px; }

  .searchBar select, 
  .searchBar input
    { width : 80px;  margin-right: 5px; padding : 5px; }

  .compare
    { background-color: rgb(192, 192, 192); width : 80px;  margin-right: 5px; padding : 5px; }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  .searchBarTd { border : 0px; }

  td {
    border : 1px solid black;
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px;     
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }

  .tables {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left : 20px;
    padding-right : 20px;
    height: 600px; /* Adjust this value according to your needs */
    overflow: auto;
  } 

  .custom-table {
    font-size: 10px;
    width: 80%;
    overflow-x: auto;
    white-space: nowrap;
  } 
  .custom-file-upload {
    display: inline-block;  padding: 6px 12px;
    cursor: pointer; background-color: #347003;
    color: #ffffff; border-radius: 4px;
    font-size: 14px;  transition: background-color 0.3s;
    margin-left: 20px;
  }
  .custom-file-upload:hover {  background-color: #009625; }

  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }

  .disabledButton { background-color: gray; cursor: not-allowed; }

</style>