
import { reactive, computed, watchEffect } from 'vue';
import { addData } from './data.js';

const calibrationTableData = reactive({ data: [] });

watchEffect(() => { calibrationTableData.data = addData[1].data;});

const hasExpired = computed(() => {
  return calibrationTableData.data.some(row => {
    if (row.manage !== "yes") return false;  // 'manage' 값이 'yes'가 아니면 false 반환

    const currentDate = new Date();
    const expireDate = new Date(row.ex_year, row.ex_month - 1, row.ex_day);
    return currentDate > expireDate; 
  });
});

const isNearExpiry = computed(() => {
  return calibrationTableData.data.some(row => {
    if (row.manage !== "yes") return false;  // 'manage' 값이 'yes'가 아니면 false 반환

    const currentDate = new Date();
    const expireDate = new Date(row.ex_year, row.ex_month - 1, row.ex_day);
    const diffTime = expireDate - currentDate;  
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));        
    return diffDays > 0 && diffDays <= 60; 
  });
});

export const calibrationtableColor = computed(() => {
  if (hasExpired.value) {
    return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // 빨간색
  } else if (isNearExpiry.value) {
    return { backgroundColor: 'rgba(255, 255, 0, 0.7)' }; // 노란색
  }
  return { backgroundColor: 'transparent' }; // Transparent color
});