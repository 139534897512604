<template>
  <div id="app">
    <h1>주식 예측 웹앱</h1>
    <div class="content-wrapper">
      <!-- 시가총액 섹션 (왼쪽) -->
      <div class="section market-cap-section">
        <div class="input-group">
          <h2>시가총액</h2>
          <button class="fetch-button" @click="getMarketCap">시가총액 가져오기</button>       
        </div>
        
        <div v-if="marketData">
          <h3>KOSPI 시가총액 상위 10개 종목</h3>
          <div class="table-container">
            <table class="market-table">
              <thead>
                <tr>
                  <th>종목명</th>
                  <th>종목코드</th>
                  <th>시가총액</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in marketData.kospi" :key="item.code">
                  <td>{{ item.name }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ formatNumber(item.marketCap) }} 억원</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>KOSDAQ 시가총액 상위 10개 종목</h3>
          <div class="table-container">
            <table class="market-table">
              <thead>
                <tr>
                  <th>종목명</th>
                  <th>종목코드</th>
                  <th>시가총액</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in marketData.kosdaq" :key="item.code">
                  <td>{{ item.name }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ formatNumber(item.marketCap) }} 억원</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- 주식 예측 섹션 (오른쪽) -->
      <div class="section prediction-section">
        <div class="input-group">        
          <h2>주식 예측</h2>
          <input class="input" v-model="stockName" placeholder="종목 이름을 입력하세요" />
          <button class="fetch-button" @click="getStockPrediction">주식 예측 가져오기</button>
        </div>
        
        <div class="stock-prediction">      
          <div v-if="stockImageUrl" class="image-container">
            <h3>주식 예측 결과:</h3>
            <img :src="stockImageUrl" alt="주식 예측 이미지" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'App',
  setup() {
    // MarketCap 관련 상태 및 함수
    const marketData = ref(null);

    const getMarketCap = async () => {
      try {
        const response = await fetch('https://openai.highbuff.com/?method=marketCap');
        const data = await response.json();
        marketData.value = data;
      } catch (error) {
        console.error('시가총액을 가져오는 중 오류 발생:', error);
      }
    };

    const formatNumber = (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    // StockPrediction 관련 상태 및 함수
    const stockName = ref('');
    const stockImageUrl = ref(null);

    const getStockPrediction = async () => {
      if (!stockName.value) {
        alert('종목 이름을 입력하세요.');
        return;
      }
      try {
        const response = await fetch(
          `https://openai.highbuff.com/?method=portfolioAI&name=${encodeURIComponent(stockName.value)}`
        );
        const imageUrl = await response.text();
        stockImageUrl.value = imageUrl.trim();
      } catch (error) {
        console.error('주식 예측을 가져오는 중 오류 발생:', error);
      }
    };

    return {
      // MarketCap 반환
      marketData,
      getMarketCap,
      formatNumber,
      // StockPrediction 반환
      stockName,
      stockImageUrl,
      getStockPrediction,
    };
  },
};
</script>

<style>
#app {
  margin: 20px;
}
.input-group {
  display: flex;
}
.fetch-button {
  padding: 5px 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}
.fetch-button:hover {
  background-color: #1e5173;
}
.market-cap {
  margin: 20px;
}
.table-container {
  overflow-x: auto;
  margin-top: 20px;
}
.market-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.market-table th,
.market-table td {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: center;
}
.market-table th {
  background-color: #f8f9fa;
  font-weight: 600;
}
h3 {
  margin-top: 20px;
}
.stock-prediction {
  margin: 20px;
}
.input-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.input {
  margin-left: 10px;
}
.image-container {
  margin-top: 20px;
}
.image-container img {
  max-width: 100%;
  height: auto;
}

.content-wrapper {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.section {
  flex: 1;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.market-cap-section {
  max-width: 50%;
}

.prediction-section {
  max-width: 50%;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }
  
  .section {
    max-width: 100%;
  }
}
</style>
