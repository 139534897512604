<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <table>
            <tr>
              <td><h3>Add Production Result</h3></td>
              <td><button type="submit" class="saveButton" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button></td>              
              <td>{{ saveStatus }}</td>          
            </tr>
          </table>  
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable"> 
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td class="grayTd">Date</td>                        
                <td>
                  <input class="dateInput" type="text" v-model="searchDate.year" />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />
                </td>
                <td class="grayTd">Shift</td>                  
                <td>
                  <input type="number" v-model="formInputs.shift" readonly class="readWindow" />
                </td>
                <td class="grayTd">Start Time</td>                  
                <td>
                  <select class="timeInput" v-model="formInputs.start_time">            
                    <option>01</option><option>02</option><option>03</option><option>04</option>
                    <option>05</option><option>06</option><option>07</option><option>08</option>
                    <option>09</option><option>10</option><option>11</option><option>12</option>
                    <option>13</option><option>14</option><option>15</option><option>16</option>
                    <option>17</option><option>18</option><option>19</option><option>20</option>
                    <option>21</option><option>22</option><option>23</option><option>24</option>
                  </select>                     
                </td>
                <td class="grayTd">End Time</td>                  
                <td>
                  <input  type="number" v-model="formInputs.end_time" min="1" max="24" readonly/>                                      
                </td>
                <td class="grayTd">Work Time(minute)</td>                  
                <td>
                  <select v-model="formInputs.work_minute">            
                    <option>5</option><option>15</option><option>30</option><option>45</option><option>60</option>    
                  </select>                     
                </td>
                <td class="grayTd">Target</td>                  
                <td>
                  <input class="readWindow" v-model="formInputs.target" readonly />                                      
                </td>
              </tr>
            </table><br>
          
              <table class="itemTable">
                <tr>
                  <th></th><th>EVO Hybrid 3P</th><th>EVO 2</th><th>EVO 3</th><th>EVO Hybrid 2P</th>
                </tr>
                <tr>
                  <td class="grayTd">Model</td>
                  <td><select class="itemInput" v-model="formInputs.model_a"><option>EVO</option><option>MC25</option></select></td>
                  <td><select class="itemInput" v-model="formInputs.model_b"><option>EVO</option><option>MC25</option></select></td>
                  <td><select class="itemInput" v-model="formInputs.model_d"><option>EVO</option><option>MC25</option></select></td>
                  <td><select class="itemInput" v-model="formInputs.model_c"><option>EVO</option></select></td>
                </tr>
                <tr>
                  <td class="grayTd" style="background-color: rgb(144, 204, 247) !important;">OK</td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ok_a" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ok_b" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ok_d" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ok_c" /></td>
                </tr>
                <tr>
                  <td class="grayTd" style="background-color :rgb(248, 143, 108) !important;">NG SUM</td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_2" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_3" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_2p" /></td>
                </tr>
                <tr>
                  <td class="grayTd" style="background-color :rgb(245, 174, 150) !important;">NG FPCA Damage</td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_fpcadamage" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_fpcadamage_2" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_fpcadamage_3" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_2p_fpcadamage" /></td>
                </tr>
                <tr>
                  <td class="grayTd" style="background-color :rgb(245, 174, 150) !important;">NG Coating</td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_coating" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_coating_2" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_coating_3" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_2p_coating" /></td>
                </tr>
                <tr>
                  <td class="grayTd" style="background-color :rgb(245, 174, 150) !important;">NG Connector<br>(Acrylic inside)</td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_connector" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_connector_2" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_3p_connector_3" /></td>
                  <td><input class="itemInput" type="number" v-model="formInputs.ng_2p_connector" /></td>
                </tr>
                <tr>
                  <td class="grayTd">FPCA Work Date</td>
                  <td>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcayear_a" placeholder="year" />
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcamonth_a" placeholder="month" />
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcaday_a" placeholder="day"/>
                  </td>
                  <td>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcayear_b" placeholder="year"/>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcamonth_b" placeholder="month"/>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcaday_b"  placeholder="day"/>
                  </td>
                  <td>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcayear_d" placeholder="year"/>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcamonth_d" placeholder="month"/>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcaday_d"  placeholder="day"/>
                  </td>
                  <td>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcayear_c" placeholder="year"/>
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcamonth_c" placeholder="month" />
                    <input class="dateInputFpca" type="text" v-model="formInputs.fpcaday_c"  placeholder="day"/>
                  </td>                  
                </tr>
                <tr>
                  <td class="grayTd">Leader</td>
                  <td><input class="itemInput" type="text" v-model="formInputs.leader" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.leader" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.leader" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.leader" /></td>                  
                </tr>
                <tr>
                  <td class="grayTd">Technician</td>
                  <td><input class="itemInput" type="text" v-model="formInputs.technician" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.technician_b" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.technician_c" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.technician" /></td>                  
                </tr>
                <tr>
                  <td class="grayTd">Operator Name</td>
                  <td><input class="itemInput" type="text" v-model="formInputs.operator_name_a" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.operator_name_b" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.operator_name_d" /></td>
                  <td><input class="itemInput" type="text" v-model="formInputs.operator_name_c" /></td>                  
                </tr>
                <tr>
                  <td class="grayTd" style="background-color :rgb(234, 246, 70) !important;">Verification</td>
                  <td><input class="itemInput" type="text" v-model="formInputs.verification" /></td>                
                  <td class="grayTd">Remark</td>
                  <td><input class="itemInput" type="text" v-model="formInputs.remark" /></td>                  
                  <td>Row_no: <input class="readWindow" type="text" v-model="formInputs.row_no" readonly /></td>
                </tr>       
              </table><br>
           
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" class="saveButton" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref } from 'vue';  
  import axios from 'axios';  

  export default {    
    setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "bendingevopr"
        const lineName = "Bending EVO"
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          shift: "",
          start_time: "",
          end_time: "",
          work_minute: "",          
          target: "", 
          model_a: "",
          model_b: "",
          model_c: "",
          model_d: "",       
          ok_a: "0",
          ok_b: "0",
          ok_c: "0",
          ok_d: "0",
          verification: "0",
          ng_3p: "0",
          ng_3p_fpcadamage: "0",
          ng_3p_coating: "0",
          ng_3p_connector: "0",
          ng_3p_2: "0",
          ng_3p_fpcadamage_2: "0",
          ng_3p_coating_2: "0",
          ng_3p_connector_2: "0",
          ng_3p_3: "0",
          ng_3p_fpcadamage_3: "0",
          ng_3p_coating_3: "0",
          ng_3p_connector_3: "0",
          ng_2p: "0",
          ng_2p_fpcadamage: "0",
          ng_2p_coating: "0",
          ng_2p_connector: "0",
          fpcayear_a: "",    
          fpcamonth_a: "",
          fpcaday_a: "",
          fpcayear_b: "",    
          fpcamonth_b: "",
          fpcaday_b: "",
          fpcayear_c: "",    
          fpcamonth_c: "",
          fpcaday_c: "",
          fpcayear_d: "",    
          fpcamonth_d: "",
          fpcaday_d: "",
          remark: "",    
          leader: "",  
          technician: "",        
          technician_b: "",    
          technician_c: "",    
          operator_name_a: "",
          operator_name_b: "",
          operator_name_c: "",
          operator_name_d: "",
          row_no: "",          
        });
        // -끝-

        //시작시간에 따라서 shift와 row_no 자동 입력 -시작-
        watch(() => formInputs.start_time, (newValue) => {
          const start_time_num = parseInt(newValue, 10);
          formInputs.shift = start_time_num >= 6 && start_time_num <= 17 ? 1 : 2;

          if (start_time_num >= 6 && start_time_num <= 24) {
            formInputs.row_no = start_time_num - 5;
          } else if (start_time_num >= 1 && start_time_num <= 5) {
            formInputs.row_no = start_time_num + 19;
          }
        });
        //끝

        //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -시작- 
        
        const targetProduct = reactive({ data: [], })

        const targetTableName = 'target';
        const columnName = 'line_name';
        axios
          .get('/api/where', { params: { value: 'Bending EVO', table_name: targetTableName, column_name: columnName } })
          .then((res) => {
            console.log('Response from /api/where:', res.data);
            targetProduct.data = res.data;
          })
          .catch((error) => {
            console.error('Error fetching target data:', error.message);
          });

        const productValue = computed(() => targetProduct.data[0]?.product || null);

        watch(
          [productValue, () => formInputs.work_minute, () => formInputs.operator_name_a, () => formInputs.operator_name_b, () => formInputs.operator_name_c, () => formInputs.operator_name_d],
          ([currentProductValue, currentWorkMinute, operatorNameA, operatorNameB, operatorNameC, operatorNameD]) => {
            let multiplier = 0;            
            if (operatorNameA) multiplier += 1;
            if (operatorNameB) multiplier += 1;
            if (operatorNameC) multiplier += 1;            
            if (operatorNameD) multiplier += 1;   
            multiplier = Math.max(multiplier, 1);
            if (currentProductValue && currentWorkMinute) {              
              formInputs.target = parseFloat((currentProductValue * currentWorkMinute * multiplier).toFixed(0));
            }
          }
        );    
                
       //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -끝- 
      
      //년.월.일을 지금 시작으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
         const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝
         
        const repeadtData = reactive({ data: [], })     
        const rdItem = computed(() => {return repeadtData.data.map(d => {return { ...d,};});});        
        watch(
            () => formInputs.shift,
            (newShift) => {
              if (newShift) {
                const repeatFormData = {
                  tableName: "repeatdata",
                  values: {
                    year: searchDate.year,
                    month: searchDate.month,
                    day: searchDate.day,
                    line_name: lineName,
                    shift: newShift,
                  },
                };
                axios
                  .get('/api/repeatData', { params: repeatFormData })
                  .then((res) => {
                    console.log('Response from /api/repeatData:', res.data);
                    repeadtData.data = res.data;
                  })
                  .catch((error) => {
                    console.error('Error fetching target data:', error.message);
                  });
              }
            }
          );

          watch(
            () => rdItem.value,
            (newRdItem) => {
              if (newRdItem && newRdItem.length > 0) {
                const lastItem = newRdItem[newRdItem.length - 1];
                formInputs.leader = lastItem.leader;
                formInputs.technician = lastItem.technician;
                formInputs.technician_b = lastItem.technician_b;
                formInputs.technician_c = lastItem.technician_c;
                formInputs.operator_name_a = lastItem.operator_name_a;
                formInputs.operator_name_b = lastItem.operator_name_b;
                formInputs.operator_name_c = lastItem.operator_name_c;
                formInputs.operator_name_d = lastItem.operator_name_d;
              }
            }
          );
       
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day ) {
            alert("Please fill in all the fields: year, month, day, ");
            return;
          }

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {
            year: 'year',            
            month: 'month',
            day: 'day',
            shift: 'shift',
            start_time: 'start_time',
            end_time: 'end_time',
            work_minute: 'work_minute',            
            target: 'target',
            model_a: 'model_a',
            model_b: 'model_b',
            model_c: 'model_c',
            model_d: 'model_d',
            ok_a: 'ok_a',
            ok_b: 'ok_b',
            ok_c: 'ok_c',
            ok_d: 'ok_d',
            verification: 'verification',
            ng_3p: 'ng_3p',
            ng_3p_fpcadamage: 'ng_3p_fpcadamage',
            ng_3p_coating: 'ng_3p_coating',
            ng_3p_connector: 'ng_3p_connector',
            ng_3p_2: 'ng_3p_2',
            ng_3p_fpcadamage_2: 'ng_3p_fpcadamage_2',
            ng_3p_coating_2: 'ng_3p_coating_2',
            ng_3p_connector_2: 'ng_3p_connector_2',
            ng_3p_3: 'ng_3p_3',
            ng_3p_fpcadamage_3: 'ng_3p_fpcadamage_3',
            ng_3p_coating_3: 'ng_3p_coating_3',
            ng_3p_connector_3: 'ng_3p_connector_3',            
            ng_2p: 'ng_2p',
            ng_2p_fpcadamage: 'ng_2p_fpcadamage',
            ng_2p_coating: 'ng_2p_coating',
            ng_2p_connector: 'ng_2p_connector',
            fpcayear_a: 'fpcayear_a',    
            fpcamonth_a: 'fpcamonth_a',
            fpcaday_a: 'fpcaday_a',
            fpcayear_b: 'fpcayear_b',    
            fpcamonth_b: 'fpcamonth_b',
            fpcaday_b: 'fpcaday_b',
            fpcayear_c: 'fpcayear_c',    
            fpcamonth_c: 'fpcamonth_c',
            fpcaday_c: 'fpcaday_c',     
            fpcayear_d: 'fpcayear_d',    
            fpcamonth_d: 'fpcamonth_d',
            fpcaday_d: 'fpcaday_d',            
            remark: 'remark',
            leader: 'leader',
            technician: 'technician',
            technician_b: 'technician_b',
            technician_c: 'technician_c',            
            operator_name_a: 'operator_name_a',
            operator_name_b: 'operator_name_b',
            operator_name_c: 'operator_name_c',
            operator_name_d: 'operator_name_d',
            row_no: 'row_no'
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              shift: formInputs.shift,
              start_time: formInputs.start_time,
              end_time: formInputs.end_time,
              work_minute: formInputs.work_minute,              
              target: formInputs.target,
              model_a: formInputs.model_a,
              model_b: formInputs.model_b,
              model_c: formInputs.model_c,
              model_d: formInputs.model_d,        
              ok_a: formInputs.ok_a,
              ok_b: formInputs.ok_b,
              ok_c: formInputs.ok_c,
              ok_d: formInputs.ok_d,
              verification: formInputs.verification,
              ng_3p: formInputs.ng_3p,
              ng_3p_fpcadamage: formInputs.ng_3p_fpcadamage,
              ng_3p_coating: formInputs.ng_3p_coating,
              ng_3p_connector: formInputs.ng_3p_connector,
              ng_3p_2: formInputs.ng_3p_2,
              ng_3p_fpcadamage_2: formInputs.ng_3p_fpcadamage_2,
              ng_3p_coating_2: formInputs.ng_3p_coating_2,
              ng_3p_connector_2: formInputs.ng_3p_connector_2,
              ng_3p_3: formInputs.ng_3p_3,
              ng_3p_fpcadamage_3: formInputs.ng_3p_fpcadamage_3,
              ng_3p_coating_3: formInputs.ng_3p_coating_3,
              ng_3p_connector_3: formInputs.ng_3p_connector_3,
              ng_2p: formInputs.ng_2p,
              ng_2p_fpcadamage: formInputs.ng_2p_fpcadamage,
              ng_2p_coating: formInputs.ng_2p_coating,
              ng_2p_connector: formInputs.ng_2p_connector,
              fpcayear_a: formInputs.fpcayear_a,    
              fpcamonth_a: formInputs.fpcamonth_a,
              fpcaday_a: formInputs.fpcaday_a,
              fpcayear_b: formInputs.fpcayear_b,    
              fpcamonth_b: formInputs.fpcamonth_b,
              fpcaday_b: formInputs.fpcaday_b,
              fpcayear_c: formInputs.fpcayear_c,    
              fpcamonth_c: formInputs.fpcamonth_c,
              fpcaday_c: formInputs.fpcaday_c,    
              fpcayear_d: formInputs.fpcayear_d,    
              fpcamonth_d: formInputs.fpcamonth_d,
              fpcaday_d: formInputs.fpcaday_d,    
              remark: formInputs.remark,              
              leader: formInputs.leader,
              technician: formInputs.technician,
              technician_b: formInputs.technician_b,
              technician_c: formInputs.technician_c,
              operator_name_a: formInputs.operator_name_a,
              operator_name_b: formInputs.operator_name_b,
              operator_name_c: formInputs.operator_name_c,
              operator_name_d: formInputs.operator_name_d,
              row_no: formInputs.row_no,    
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }

         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };

      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-  
      
      watch(
        () => formInputs.start_time,
        (newStartTime) => {
          const startTime = parseInt(newStartTime, 10);
          if (startTime < 24) {
            formInputs.end_time = (startTime + 1).toString().padStart(2, '0');
          } else {
            formInputs.end_time = '01';
          }
        }
      );

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, targetProduct, productValue,
               targetTableName, columnName, saveStatus, isProcessing };

    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      // currentDate: Object,
    },    
  };   
</script>

<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 1200px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; width: 80px !important; }

  .saveButton[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px; margin-left: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;

  }

  .saveButton[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .grayTd {background-color: #eaeaea !important; }
  .itemInput { width: 150px; margin-left: 5px; margin-right: 5px; text-align: center; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 100px; } 
  .costInput { margin-left: 5px; margin-right: 5px; width: 350px; } 
  .qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .dateInputFpca { width: 60px; size: 8px; margin-left: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }    
  .subDateInput { width: 50px; size: 8px; margin-left: 3px; margin-right: 3px; }  
  .titleInput { width: 663px; margin-left: 5px; }  
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 10px; margin-right: 10px; padding: 0px 10px; border: 0px; background-color: rgb(234, 246, 70); color:white; font-weight: bold; font-size: 15px; text-align: center }
  .failurestandard {margin-left:10px; font-weight: bold};  
  /*background-color: rgb(77, 69, 195);*/
</style>
