import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import { osCostSearchData, itemArray } from './osCostCal';
import osTarget, { osCostTargetSearchData } from './osCostTarget';
import exchangeRate, { exchangeRateSearch } from './exchangeRate';

export const createPeriodReportUtils = (tableNames) => {
  const state = reactive({
    data: {},
    targetList: [],
    osCost: [],
    settings: [],
    showModal: ref(false),
    year: ref(''),
    month: ref(''),
  });

  tableNames.forEach(tableName => {
    state.data[tableName] = reactive({ data: [] });
  });

  const fetchData = async (tableGroup, dateRange) => {
    if (!dateRange || !dateRange.year || !dateRange.month) {
      throw new Error('Invalid date range provided');
    }

    const formData = {
      tableNames: tableGroup.join(','),
      year: dateRange.year,
      month: dateRange.month,
    };

    try {
      const res = await axios.get('/api/yearMonth', { params: formData });
      const responseData = res.data;

      responseData.forEach(tableData => {
        const { tableName, data } = tableData;
        state.data[tableName].data = data || [];
      });      
    } catch (error) {
      console.error("Error fetching table data:", error.message);
    }
  };

  const fetchAllData = async (dateRange) => {
    if (!dateRange) {
      throw new Error('Date range parameter is required for fetchAllData');
    }

    const tableGroups = [];
    for (let i = 0; i < tableNames.length; i += 12) {
      tableGroups.push(tableNames.slice(i, i + 12));
    }

    for (const tableGroup of tableGroups) {
      await fetchData(tableGroup, dateRange);
    }
    await osCostSearchData(dateRange);
    await osCostTargetSearchData(dateRange);    
    await exchangeRateSearch(dateRange);    
  };

  const fetchTargets = async () => {
    const formData = { tableName: 'target' };

    try {
      const res = await axios.get('/api/targetList', { params: formData });
      state.targetList = res.data;
    } catch (error) {
      console.error("Error fetching target data:", error.message);
    }
  };

  const sums = computed(() => {
    const sumsObject = {};

    tableNames.forEach(tableName => {
      sumsObject[tableName] = {
        target: 0, ok: 0, ng: 0,
      };
    });

    tableNames.forEach(tableName => {
      state.data[tableName].data.forEach(row => {
        sumsObject[tableName].target += row.target || 0;
        sumsObject[tableName].ok += row.ok || 0;
        sumsObject[tableName].ng += row.ng || 0;
      });
    });

    return sumsObject;
  });

  const calculateRowData = (targetConfig) => {
    const sumsObject = sums.value;
    const prData = sumsObject[targetConfig.pr] || { target: 0, ok: 0, ng: 0 };
    const qwData = sumsObject[targetConfig.qw] || { ng: 0 };

    const okTarget = prData.target;
    const ok = prData.ok - qwData.ng;
    const achieve = okTarget > 0 ? ((ok / okTarget) * 100).toFixed(1) : '0.0';

    // osCostCal에서 가져온 itemArray와 매칭
    const itemArrayDetails = itemArray.value.find(item => item.line === targetConfig.name) || { hour: 0, cost: 0 };

    // osTarget에서 cost_pln 값 매칭
    const targetDetails = osTarget.data.find(item => item.line === targetConfig.name) || { cost_pln: 0, target_qty: 0, plan_qty: 0 };

    return {
      okTarget, achieve,
      ok: Number(ok).toFixed(0),
      hour: Number(itemArrayDetails.hour).toFixed(0),
      cost: Number(itemArrayDetails.cost).toFixed(0),
      cost_pln: Number(targetDetails.cost_pln).toFixed(2),
      target_qty: Number(targetDetails.target_qty).toFixed(0),
      plan_qty: Number(targetDetails.plan_qty).toFixed(0)
    };
  };

  return {
    state,
    fetchAllData,
    fetchTargets,
    calculateRowData,
    sums,
    exchangeRate
  };
};

export const settingValue = async () => {
  const settingData = reactive({ data: [] });
  const formData = { tableName: 'setting' };
  try {
    const res = await axios.get('/api/targetList', { params: formData });
    settingData.data = res.data;
  } catch (error) {
    console.error("Error fetching settings:", error.message);
  }
  return { settingData };
};

export const withoutEOPLines = [
  'TR2 SMT', 'TR2 Crimping', 'TR2 FPCA', 'TR2 BFA 3P',
  'TR3 SMT', 'TR3 Crimping', 'TR3 FPCA', 'EVO Hybrid', 'EVO 1', 'EVO 2', 'EVO 3',
  'EV2020 60FR', 'EV2020 60RR', 'EV2020 4060FR', 'EV2020 4060RR', 'EV2020 60kW', 'EV2020 4060kW',
  'Ford 2 Front', 'Ford 2 Rear', 'Ford 3 Front', 'Ford 3 Rear',
  'BMW12V', 'BMW48V', 'Inkatom', 'Common 1' , 'Common 2'
];

export const withEOPLines = [
  'TR2 SMT', 'TR2 Crimping', 'TR2 FPCA', 'TR2 BFA 3P',
  'TR3 SMT', 'TR3 Crimping', 'TR3 FPCA', 'EVO Hybrid', 'EVO 1', 'EVO 2', 'EVO 3',
  'EV2020 60FR', 'EV2020 60RR', 'EV2020 4060FR', 'EV2020 4060RR', 'EV2020 60kW', 'EV2020 4060kW',
  'Ford 1 Front', 'Ford 1 Rear', 'Ford 2 Front', 'Ford 2 Rear', 'Ford 3 Front', 'Ford 3 Rear',
  'FCA 1', 'FCA 2', 'BT6', 'FCA FLEX BC', 'FCA FLEX PO', 'FCA FLEX 67', 'FCA FLEX NE', 'FCA FLEX 45',
  'BMW12V', 'BMW48V', 'Inkatom', 'Common 1' , 'Common 2'
];