<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Equipment Ledger</h>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">          
            <div class="searchTd">
              <select id="line" v-model="formInputs.factory">                
                <option>1</option><option>2</option><option></option>
              </select>  
            </div>        
            <div class="searchTd">
              <select id="line" v-model="formInputs.line">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
                <option></option>              
              </select>  
            </div>               
            <div class="searchTd">
                <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
            </div>
            <div class="searchTd">                        
                <button class="addButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="showModal = true">+ New Ledger</button>
            </div>
            <div class="searchTd">
                <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
            </div>             
        </div>      
      </form>  
    </div>          
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr>           
          <th>Factory</th><th>Line</th><th>Station</th><th>Part</th><th>Image</th><th>Maker</th><th>Grade</th><th>Size(W/D/H)</th><th>Weight</th>
          <th @click="toggleElectricColumns">Voltage 1 ▶</th><th v-if="elecVisible">Phase 1</th><th v-if="elecVisible">Voltage 2</th>
          <th v-if="elecVisible">Phase 2</th><th v-if="elecVisible">Frequency</th>
          <th>Air Pressure</th><th  @click="toggleOperColumns">Install Date ▶</th><th v-if="operVisible">Price</th><th v-if="operVisible">Tact Time</th>
          <th v-if="operVisible">Number of Operater</th><th>Contents</th><th>History</th><th>Code</th><th>File</th><th>Modify</th><th>DEL</th>
        </tr>              
        <tr v-for="row in Item" :key="row.id" >                    
          <td>{{ row.factory }}</td>
          <td>{{ row.line }}</td>
          <td>{{ row.station }}</td>
          <td>{{ row.part }}</td>
          <td>
            <img v-if="row.imagepath && row.imagepath !== '0'" :src="getImageUrl(row.imagepath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imagepath))">
            <template v-else></template>              
          </td>            
          <td>{{ row.maker }}</td>
          <td>{{ row.grade }}</td>                      
          <td>{{ row.size }}</td>
          <td>{{ row.weight }}</td>                      
          <td>{{ row.voltageone }}</td>          
          <td v-if="elecVisible">{{ row.phaseone }}</td>
          <td v-if="elecVisible">{{ row.voltagetwo }}</td>          
          <td v-if="elecVisible">{{ row.phasetwo }}</td>
          <td v-if="elecVisible">{{ row.frequency }}</td>          
          <td>{{ row.airpressure }}</td>          
          <td>{{ row.installdate }}</td>          
          <td v-if="operVisible">{{ row.price }}</td>
          <td v-if="operVisible">{{ row.tacttime }}</td>
          <td v-if="operVisible">{{ row.operator }}</td>   
          <td class="contents" v-html="enterLine(row.contents)"></td>
          <td class="history">{{ row.history }}</td>  
          <td>{{ row.code }}</td>             
          <td>{{ getFileName(row.file) }}<br>
            <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
          </td>   
          <td><button class="moButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>          
          <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(row)">DEL</button></td>          
        </tr>
    </table>
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    </div>  
</template>

<script>
import { reactive, computed, ref, watch } from 'vue';
import axios from "axios";
import Modal from "./ledgerModal.vue";
import modifyModal from "./ledgerMoModal.vue";
import { deleteRow, downloadExcel, fetchData } from "../../utils.js"; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      canClick() {          
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 ||  currentUser.id === 3 ||currentUser.id === 32;}
        else { return false;}
      },  
      //getImageUrl(filepath) {return `http://localhost:3000/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `https://lsevpweb.ngrok.app/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `http://pl.lsevpweb.com/uploads/${filepath}`;},
      getImageUrl(filepath) { return filepath;},
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); },
      downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }
        window.location.href = filepath;
      }, 
      getFileName(filePath) {
        if (!filePath) return ''; const parts = filePath.split('-');
        if (parts.length > 1) { return parts.slice(1).join('-'); }
        return filePath;
      },
      downExcel() { downloadExcel(this.Item, "EquipmentLedger.xlsx"); },
      delRow(row) {
        if (row.filepath) { alert('First file delete and try again'); }
        else { deleteRow(this.dbTableName, row.id, this.callSearchData); }
      },
      enterLine(text) {if (!text) return '';  return text.replace(/\n/g, '<br>'); }
    },

    components: { Modal, modifyModal, },     

    setup() {                   
        const dbTableName = 'equipmentledger';
        const showModal = ref(false);
        const formInputs = reactive({ line: "" });
        const uniqueLines = ref([]);   
        const showMoModal = ref(false);      
        const headers = ['Factory', 'Line', 'Station', 'Part', 'Image', 'Maker', 'Grade', 'Size(W/D/H)', 'Weight', 'Voltage 1', 'Phase 1',
                         'Voltage 2', 'Phase 2', 'Frequency', 'Air Pressure', 'Install Date', 'Price', 'Tact Time',
                         'Number of Operater', 'Contents', 'History', 'Code', 'File', 'Modify', 'DEL'];
        const spareData = reactive({ data: [], })   
        
        const sendDate = async () => {const sendDateData = [formInputs.factory, formInputs.line]; return { sendDateData };};    

        const searchData = async () => {   
          const sendDateData = await sendDate();         
          const formData = {        
                tableName: dbTableName,                
                factory: sendDateData.sendDateData[0],
                line: sendDateData.sendDateData[1],
            };  

          console.log('Requesting data with:', formData);

          axios.get('/api/equipmentLedger', { params: formData })                      
               .then((res) => { spareData.data = res.data
          });  
        };
 
        const Item = computed(() => { return spareData.data.map(d => { return { ...d, }; }); });  

        const callSearchData = () => { searchData(); };       

        const loadLine = async (factory) => { uniqueLines.value  = await fetchData('equipmentledger', 'line', [{ column: 'factory', value: factory }]); };
        watch(() => formInputs.factory, (newFactory) => { loadLine(newFactory); });
       

        const elecVisible = ref(false);        
        const toggleElectricColumns = () => { elecVisible.value = !elecVisible.value; };
        const operVisible = ref(false);        
        const toggleOperColumns = () => { operVisible.value = !operVisible.value; };

        return { Item, deleteRow, headers, dbTableName, callSearchData, showModal, showMoModal, 
                 downloadExcel, formInputs, uniqueLines, sendDate,
                 toggleElectricColumns, elecVisible, toggleOperColumns, operVisible
        };        
    }
  }
</script>

<style scoped> 
  .table-container { margin-left : 10px; margin-right: 10px;}
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .disabledButton { background-color: gray; cursor: not-allowed; }
  label { font-size: 17px; font-weight: 600; margin-right: 8px; }
  .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
  .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px; word-wrap: break-word; white-space: normal; }
  th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
      text-align : center; padding: 10px; font-size : 15px; font-weight: 600;
  }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                      border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
  }
  .custom-file-upload:hover { background-color: #009625; }

  .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
  }
  .moButton { padding: 5px 5px; background-color: #347003; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchTd { border: 0px !important;}    
  .word { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .maker { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .factory { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  
  input::placeholder { color: gray; }
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }   
  
  .radio { margin-left: 10px; margin-right: 5px; }
  .red-row { background-color: rgb(237, 117, 117);}
  .yellow-row { background-color: rgb(238, 249, 140);}
  .history {width: 200px;}
  .contents {text-align: left !important;}
  .horizontal-form { margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>