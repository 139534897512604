<template>
    <div class="stocklist">            
      <div class="act">               
         <h class="pagetitle">Tray Stock</h>                  
      </div>
      <table class="searchBar">
        <tr>
          <td class="searchBarTd">
          <form @submit.prevent="sendDate()">             
            <label>Search : </label>
            <input id="year" v-model="searchDate.year" placeholder="Year"/>          
            <input id="month" v-model="searchDate.month" placeholder="Month" />                       
            <input id="day" v-model="searchDate.day" placeholder="Day"/>                                        
            <button class="searchButton" type="submit"  @click="callSearchData">Search</button>                         
          </form>
          </td>
          </tr>
        <tr>
        <td class="searchBarTd">     
          <button class="modalButton" @click="showModal = true">+ Add</button>                
        </td>
        </tr>
      </table>                  
      <P></P>     
      <div class="tables">
        
      <table id="stockTable" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" class="header">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">                
                <td v-if="header === 'Factory'" :key="index" >{{ row.factory }}</td>                  
                <td v-if="header === 'Line'" :key="index" >{{ row.line }}</td>
                <td v-if="header === 'Tray Type'" :key="index" >{{ row.type }}</td>                
                <td v-if="header === 'Quantity'" :key="index" >{{ row.quantity }}</td>                                             
                <td v-if="header === 'Remark'" :key="index" >{{ row.remark }}</td>      
                <td v-if="header === 'DEL'" :key="index" class="del"><button @click="deleteRow(stockTableName, row.id)">DEL</button></td>          
            </template>
          </tr>
        </table>    
        <br><br>
       
        <table id="stockTable" class="custom-table">
          <tr><th colspan="4">1st Factory</th></tr>           
          <tr><th>Line</th><th>Tray Quantity</th><th>Plan</th><th>Result</th></tr>          
            <tr><td>BMW 48V</td><td>{{ bmw48TrayAll }}</td><td>{{ bmw48Plan }}</td><td :style="getCellStyle(bmw48Result)">{{ bmw48Result }}</td></tr>
            <tr><td>BMW 12V</td><td>{{ bmw12TrayAll }}</td><td>{{ bmw12Plan }}</td><td :style="getCellStyle(bmw12Result)">{{ bmw12Result }}</td></tr>
            <tr><td>Ford</td><td>{{ fordTrayAll }}</td><td>{{ fordPlan }}</td><td :style="getCellStyle(fordResult)">{{ fordResult }}</td></tr>
            <tr><td>FPCA</td><td>{{ fpcaTray1 }}</td><td>{{ tr2FpcaPlan }}</td><td :style="getCellStyle(fpcaResult1)">{{ fpcaResult1 }}</td></tr>   

          <tr><th colspan="4">2nd Factory</th></tr>          
          <tr><th>Line</th><th>Tray Quantity</th><th>Plan</th><th>Result</th></tr>          
            <tr><td>FPCA</td><td>{{ fpcaTray2 }}</td><td>{{ tr3FpcaPlan }}</td><td :style="getCellStyle(fpcaResult2)">{{ fpcaResult2 }}</td></tr>
            <tr><td>EVO</td><td>{{ evoTrayAll }}</td><td>{{ evoPlan }}</td><td :style="getCellStyle(evoResult)">{{ evoResult }}</td></tr>
            <tr><td>EV2020</td><td>{{ ev2020TrayAll }}</td><td>{{ ev2020Plan }}</td><td :style="getCellStyle(ev2020Result)">{{ ev2020Result }}</td></tr>

          <tr><th colspan="4">Total</th></tr>          
          <tr><th>Line</th><th>Tray Quantity</th><th>Plan</th><th>Result</th></tr>  
             <tr><td>BMW 48V</td><td>{{ bmw48TrayAll }}</td><td>{{ bmw48Plan }}</td><td :style="getCellStyle(bmw48Result)">{{ bmw48Result }}</td></tr>        
            <tr><td>Ford</td><td>{{ fordTrayAll }}</td><td>{{ fordPlan }}</td><td :style="getCellStyle(fordResult)">{{ fordResult }}</td></tr>
            <tr><td>FPCA</td><td>{{ fpcaTrayAll }}</td><td>{{ fpcaPlan }}</td><td :style="getCellStyle(fpcaResult)">{{ fpcaResult }}</td></tr>
            <tr><td>EVO</td><td>{{ evoTrayAll }}</td><td>{{ evoPlan }}</td><td :style="getCellStyle(evoResult)">{{ evoResult }}</td></tr>
            <tr><td>EV2020</td><td>{{ ev2020TrayAll }}</td><td>{{ ev2020Plan }}</td><td :style="getCellStyle(ev2020Result)">{{ ev2020Result }}</td></tr>
        </table>

        <table id="stockTable" class="custom-table">
          <tr><th colspan="3">Tray Quantity</th></tr>
          <tr><th>1st</th><th>2nd</th><th>Total</th></tr>
          <tr><td :style="getCellStyle(result1st)">{{ result1st }}</td><td :style="getCellStyle(result2nd)">{{ result2nd }}</td><td :style="getCellStyle(totalResult)">{{ totalResult }}</td></tr>
        </table>
        <br><br>
       </div>                        
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>                   
  </div>    
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";

import Modal from "./trayAdd.vue";

export default {      
    components: { Modal  },    

    setup() {              
        const showModal = ref(false);         
        const headers = ['Factory', 'Line', 'Tray Type', 'Quantity', 'Remark', 'DEL' ];         
        const resultHeaders = ['Factory', 'Line', 'Plan', 'Difference', 'Result' ];
        const searchDate = ref({ year: '', month: '', day: '',});
        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day];
            return {sendDateData};             
        };            

        const stockTableName = "stocktray"; const stockTableData = reactive({ data: [], });        
        const planTableName = "weekplan"; const planTableData = reactive({ data: [], });                            
        
        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
          const formData = {
            tableName: tableName,
            values: {                
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],                                            
            }
          };
          console.log('Requesting data with:', formData);

          axios.get('/api/productionData', { params: formData })
            .then((res) => {                         
                switch (tableName) {
                  case 'stocktray': stockTableData.data = res.data;  break;                      
                  case 'weekplan': planTableData.data = res.data; console.log('weekplan:', res.data); break;    
                }
            });
        };  

        const callSearchData = () => { searchData(stockTableName); searchData(planTableName); };       

        const plan_item = computed(() => { return planTableData.data.map(d => {return { ...d };});});     
        const item = computed(() => { return stockTableData.data.map(d => {return { ...d };});});   
       
        
        //공장구분없이 설비별로 Tray 합계 계산 -시작-
        const sumByLine = computed(() => {
            return item.value.reduce((accumulator, currentItem) => {
                if (accumulator[currentItem.line]) {
                    accumulator[currentItem.line] += currentItem.quantity;
                } else {
                    accumulator[currentItem.line] = currentItem.quantity;
                }
                return accumulator;
            }, {});
        }); 

        const bmw48TrayAll = computed(() => sumByLine.value["BMW 48V"] || 0);
        const bmw12TrayAll = computed(() => sumByLine.value["BMW 12V"] || 0);
        const fpcaTrayAll = computed(() => sumByLine.value["FPCA"] || 0);
        const fordTrayAll = computed(() => sumByLine.value["Ford"] || 0);
        const evoTrayAll = computed(() => sumByLine.value["EVO"] || 0);
        const ev2020TrayAll = computed(() => sumByLine.value["EV2020"] || 0);

        //공장구분없이 설비별로 Tray 합계 계산 -끝-

        //공장별 설비별로 Tray 합계 계산 -시작-
        const sumByLineFactory = computed(() => {
            return item.value.reduce((accumulator, currentItem) => {
                const factoryKey = `factory${currentItem.factory}`;
                if (!accumulator[factoryKey]) { accumulator[factoryKey] = {}; }                
                if (accumulator[factoryKey][currentItem.line]) {
                    accumulator[factoryKey][currentItem.line] += currentItem.quantity;
                } else {
                    accumulator[factoryKey][currentItem.line] = currentItem.quantity;
                }                
                return accumulator;
            }, {});
        });

        const fpcaTray1 = computed(() => {if (sumByLineFactory.value.factory1 && sumByLineFactory.value.factory1.FPCA) {return sumByLineFactory.value.factory1.FPCA;} return 0;});        
        const fpcaTray2 = computed(() => {if (sumByLineFactory.value.factory2 && sumByLineFactory.value.factory2.FPCA) {return sumByLineFactory.value.factory2.FPCA;} return 0;});  
        //공장별 설비별로 Tray 합계 계산 -끝-
                
        //설비별로 Plan 합계 계산 -시작-
        const sumByPlanLine = computed(() => {
            const groupedData = {};       
            for (const item of plan_item.value) {
                if (!groupedData[item.line]) {
                    groupedData[item.line] = {};
                }
                groupedData[item.line][item.shift] = item.planquantity;
            }      
            const result = {};
            for (const line in groupedData) {
                const dayPlan = groupedData[line]["Day"] || 0;
                const nightPlan = groupedData[line]["Night"] || 0;
                result[line] = dayPlan + nightPlan;
            }
            return result;
        });

        const bmw48Plan = computed(() => sumByPlanLine.value["BMW 48V"] || 0);

        const bmw12Plan = computed(() => sumByPlanLine.value["BMW 12V"] || 0);        

        const fordPlan = computed(() => {
            const ford1front= sumByPlanLine.value["Ford 1 Front"] || 0;
            const ford1rear= sumByPlanLine.value["Ford 1 Rear"] || 0;
            const ford2front= sumByPlanLine.value["Ford 2 Front"] || 0;
            const ford2rear= sumByPlanLine.value["Ford 2 Rear"] || 0;
            const ford3front= sumByPlanLine.value["Ford 3 Front"] || 0;
            const ford3rear= sumByPlanLine.value["Ford 3 Rear"] || 0;
            
            return ford1front + ford1rear + ford2front + ford2rear + ford3front + ford3rear;
        });        

        const tr2FpcaPlan = computed(() => sumByPlanLine.value["TR2 FPCA"] || 0);
        const tr3FpcaPlan = computed(() => sumByPlanLine.value["TR3 FPCA"] || 0);

        const fpcaPlan = computed(() => {
            const tr2fpca= sumByPlanLine.value["TR2 FPCA"] || 0;
            const tr3fpca= sumByPlanLine.value["TR3 FPCA"] || 0;           
            return tr2fpca + tr3fpca;
        });

        const evoPlan = computed(() => {
            const evo1 = sumByPlanLine.value["EVO 1"] || 0;
            const evo2 = sumByPlanLine.value["EVO 2"] || 0;
            const evoHybrid = sumByPlanLine.value["EVO Hybrid"] || 0;
            return evo1 + evo2 + evoHybrid;
        });

        const ev2020Plan = computed(() => {
          const ev20204060front= sumByPlanLine.value["EV2020 4060kW Front"] || 0;
            const ev20204060rear= sumByPlanLine.value["EV2020 4060kW Rear"] || 0;  
            const ev202060front= sumByPlanLine.value["EV2020 60kW Front"] || 0;
            const ev202060rear= sumByPlanLine.value["EV2020 60kW Rear"] || 0;                
            return ev20204060front + ev20204060rear + ev202060front + ev202060rear;
        });
        //설비별로 Plan 합계 계산 -끝-

        //과부족 Result -시작-
        const bmw48Result = computed(() => {
          if ((bmw48Plan.value && bmw48TrayAll.value === 0) ) { return "Need to Count"; }
          const difference = (bmw48TrayAll.value*6) - bmw48Plan.value;  //1박스, 트레이당 들어가는 제품수를 곱해야 함
          return difference < 0 ? "Not Enough" : "Enough";
        });
        const bmw12Result = computed(() => {
          if ((bmw12Plan.value && bmw12TrayAll.value === 0) ) { return "Need to Count"; }
          const difference = (bmw12TrayAll.value*8) - bmw12Plan.value;  //1박스, 트레이당 들어가는 제품수를 곱해야 함
          return difference < 0 ? "Not Enough" : "Enough";
        });
        const fordResult = computed(() => {
          if ((fordPlan.value && fordTrayAll.value === 0) ) { return "Need to Count"; }
          const difference = (fordTrayAll.value*18) - fordPlan.value;  //1박스, 트레이당 들어가는 제품수를 곱해야 함
          return difference < 0 ? "Not Enough" : "Enough";
        });
        const fpcaResult1 = computed(() => {
          if ((tr2FpcaPlan.value && fpcaTray1.value === 0) ) { return "Need to Count"; }
          const difference = (fpcaTray1.value*2) - tr2FpcaPlan.value;
          return difference < 0 ? "Not Enough" : "Enough";
        });
        const fpcaResult2 = computed(() => {
          if ((tr2FpcaPlan.value && fpcaTray2.value === 0) ) { return "Need to Count"; }
          const difference = (fpcaTray2.value*2) - tr3FpcaPlan.value;
          return difference < 0 ? "Not Enough" : "Enough";
        });
        const fpcaResult = computed(() => {
          if ((fpcaPlan.value && fpcaTrayAll.value === 0) ) { return "Need to Count"; }
          const difference = (fpcaTrayAll.value*2) - fpcaPlan.value;
          return difference < 0 ? "Not Enough" : "Enough";
        });
        const evoResult = computed(() => {
          if ((evoPlan.value && evoTrayAll.value === 0) ) { return "Need to Count"; }
          const difference = (evoTrayAll.value*8) - evoPlan.value;
          return difference < 0 ? "Not Enough" : "Enough";
        });
        const ev2020Result = computed(() => {
          if ((ev2020Plan.value && ev2020TrayAll.value === 0) ) { return "Need to Count"; }
          const difference = (ev2020TrayAll.value*16) - ev2020Plan.value;
          return difference < 0 ? "Not Enough" : "Enough";
        });
        //과부족 Result -끝 -

        //종합결과 -시작-
        const result1st = computed(() => {
            if ((bmw48Plan.value && bmw48TrayAll.value === 0) || (bmw12Plan.value && bmw12TrayAll.value === 0) || (fordPlan.value && fordTrayAll.value === 0) || (tr2FpcaPlan.value && fpcaTray1.value === 0)) { return "Need to Count"; }
            const results = [bmw48Result.value, bmw12Result.value, fordResult.value, fpcaResult1.value];
            return results.some(result => result === "Not Enough") ? "Not Enough" : "Enough";
        });
        const result2nd = computed(() => {
            if ((tr3FpcaPlan.value && fpcaTray2.value === 0) || (evoPlan.value && evoTrayAll.value === 0) || (ev2020Plan.value && ev2020TrayAll.value === 0)) { return "Need to Count"; }
            const results = [fpcaResult2.value, evoResult.value, ev2020Result.value];
            return results.some(result => result === "Not Enough") ? "Not Enough" : "Enough";
        });
        const totalResult = computed(() => {
            if ((bmw48Plan.value && bmw48TrayAll.value === 0) || (bmw12Plan.value && bmw12TrayAll.value === 0) || (fordPlan.value && fordTrayAll.value === 0) || (tr2FpcaPlan.value && fpcaTray1.value === 0) ||
                (tr3FpcaPlan.value && fpcaTray2.value === 0) || (evoPlan.value && evoTrayAll.value === 0) || (ev2020Plan.value && ev2020TrayAll.value === 0)) { return "Need to Count"; }
            const results = [bmw48Result.value, bmw12Result.value, fordResult.value, fpcaResult.value, evoResult.value, ev2020Result.value];
            return results.some(result => result === "Not Enough") ? "Not Enough" : "Enough";
        });

        //종합결과 -끝-
                

        const getCellStyle = (result) => {
          if (result === 'Not Enough') {
            return {'background-color': 'red'};
          } else if (result === 'Need to Count') {
            return {'background-color': 'yellow'};
          }
          return {};
        };

        const deleteRow = async (tableName, rowId) => {
        // Show a confirmation window and store the user's choice
        const userConfirmed = confirm("Are you sure you want to delete this row?");

        // If the user clicks "OK", proceed with the deletion
        if (userConfirmed) {
          try {
            await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });
            callSearchData();
          } catch (error) {
            console.error("Error deleting row:", error);
          }
        }
      };

      return { headers, resultHeaders, showModal, callSearchData, sendDate, deleteRow,
                searchData, searchDate, stockTableName, plan_item, item, bmw48TrayAll, bmw12TrayAll, fpcaTrayAll, fpcaTray1, fpcaTray2, fordTrayAll, evoTrayAll, ev2020TrayAll,
                bmw48Plan, bmw12Plan, fordPlan, tr2FpcaPlan, tr3FpcaPlan, fpcaPlan, evoPlan, ev2020Plan,
                bmw48Result, bmw12Result, fordResult, fpcaResult1, fpcaResult2, fpcaResult, evoResult, ev2020Result, getCellStyle, totalResult, result1st, result2nd
             };        
    },
}
</script>

<style scoped>
  .act { display: flex; padding: 10px 10px 5px 5px; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; } 

  .button {
    padding: 5px 5px;  background-color: #3498db;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-right: 3px;
    cursor: pointer; transition: background-color 0.3s;
  }

  .searchButton {
    padding: 5px 5px;  background-color: #94a5b1;
    color: #000000; border: none;
    border-radius: 4px; font-size: 14px; font-weight: 600;
    margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton {
    padding: 5px 5px; background-color: #10a802;
    color: #fff; border: none;
    border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer;
    transition: background-color 0.3s;
  }

  .modalButton {
    padding: 5px 5px;  background-color: #f3ac27;
    color: #000000;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 50px; margin-right: 10px;
    cursor: pointer; transition: background-color 0.3s;
  }

  .button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

  .excelButton:hover, .searchButton:hover, .qwallButton:hover, 
  .button:hover { background-color: #2980b9; }
    
  .searchBar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .searchBar > *:not(:last-child) { margin-right: 5px; }

  .searchBar select, 
  .searchBar input
    { width : 80px;  margin-right: 5px; padding : 5px; }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  .searchBarTd { border : 0px; }

  .custom-file-upload {
    display: inline-block;  padding: 6px 12px;
    cursor: pointer; background-color: #347003;
    color: #ffffff; border-radius: 4px;
    font-size: 14px;  transition: background-color 0.3s;
    margin-left: 20px;
  }
  .custom-file-upload:hover {  background-color: #009625; }

  .custom-table { width: 80%; border-collapse: collapse; margin-bottom: 20px; margin-left: 20px; }      
  .custom-table th { border: 1px solid #ddd; padding: 2px;  text-align: center; font-weight: bold; font-size: 15px; background-color: #5586f0; color: white;}
  .custom-table td { border: 1px solid #ddd; padding: 2px;  text-align: center; font-weight: bold; font-size: 12px;} 
  .custom-table tr:nth-child(even) {  background-color: #f2f2f2;}
  .tables { display: flex; flex-wrap: wrap; justify-content: space-between; } 

</style>