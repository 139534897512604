<template>
  <div class="container">            
    <h class="pagetitle">Peel Tensile Inspection</h>        
    <form @submit.prevent="sendDate()" class="searchBar">
      <div class="horizontal-form"> 
        <div>
          <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
          <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
          <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
        </div>   
        <div>~</div> 
        <div>
          <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
          <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
          <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
        </div>
        <div>
          <select id="factory" v-model="project">
            <option>EVO</option><option>EV2020</option>
          </select>                        
        </div>
        <div>
          <select id="station" v-model="model">
           <option v-for="option in modelOptions" :key="option">{{ option }}</option>
          </select>                       
        </div>
        <div>
          <select id="factory" v-model="shift">
            <option>A</option><option>B</option><option>C</option><option></option>
          </select>                        
        </div>        
        <div>
          <select id="type" v-model="result">
            <option>OK</option><option>NG</option><option></option>                 
          </select>                        
        </div>
        <div>
          <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
        </div>
        <div>
          <button class="addButton" @click="showModal = true">Write Test Result</button>   
        </div>  
        <div>
          <button class="excelButton" @click="downExcel">Excel</button>                                              
        </div>                    
      </div>      
    </form>   
    <!--    
    <div class="horizontal-form"> 
      <div class="minMaxInput">MIN : <input type="number" v-model="minValue" /></div>     
      <div class="minMaxInput">MAX : <input type="number" v-model="maxValue" /></div>     
    </div>-->
    <div class="tables">
      <table class="custom-table">
      <tr>
        <th>Date</th><th>Shift</th><th>Project</th><th>Model</th><th>Operator</th><th>Leader</th><th>Result</th>
        <th>Peel Barcode</th>        
        <th v-for="(peel, index) in formInputs.peels" :key="'peel-' + index" class="peelTest">Peel {{ index + 1 }}</th>
        <th>Tensile Barcode</th>
        <th v-for="(tensile, index) in formInputs.tensiles" :key="'tensile-' + index" class="tensileTest">Tensile {{ index + 1 }}</th>
        <th>Remark</th><th>Modify</th><th>DEL</th>
      </tr>          
      <tr v-for="row in item" :key="row.id">
        <td>{{ row.date }}</td>                            
        <td>{{ row.shift }}</td>
        <td>{{ row.project }}</td>
        <td>{{ row.model }}</td>            
        <td>{{ row.operator }}</td>
        <td>{{ row.leader }}</td>
        <td :class="{'ok-background': row.result === 'OK', 'ng-background': row.result === 'NG'}">{{ row.result }}</td>
        <td>{{ row.barcode_peel }}</td>      
        <td v-for="(peel, index) in formInputs.peels" :key="'peel-' + index">{{ row['peel' + (index + 1)] }}</td>
        <td>{{ row.barcode_tensile }}</td>      
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensile-' + index">{{ row['tensile' + (index + 1)] }}</td> 
        <td>{{ row.remark }}</td>
        <td><button type="submit" @click="openSendModal(row)" class="moButton">Modify</button></td>
        <td><button :class="['del', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>                                        
      </tr>
    </table>    
  </div>  
  
  <div class="chartDiv">
    <table class="chartTable">
      <template v-for="(peelGroup, groupIndex) in peeledGroups" :key="'peelGroup-' + groupIndex">
        <tr>
          <!-- th 생성 -->
          <th v-for="peel in peelGroup" :key="'peelHeader-' + peel">Peel {{ peel }}</th>
        </tr>
        <tr>
          <!-- td 생성 -->
          <td v-for="peel in peelGroup" :key="'peelCanvas-' + peel">
            <canvas :id="'peelChart' + peel" class="chart"></canvas>
          </td>
        </tr>
      </template>
    </table>
    <br>

    <table class="chartTable">
      <template v-for="(tensileGroup, groupIndex) in tensileGroups" :key="'tensileGroup-' + groupIndex">
        <tr>
          <!-- th 생성 -->
          <th v-for="tensile in tensileGroup" :key="'tensileHeader-' + tensile">Tensile {{ tensile }}</th>
        </tr>
        <tr>
          <!-- td 생성 -->
          <td v-for="tensile in tensileGroup" :key="'tensileCanvas-' + tensile">
            <canvas :id="'tensileChart' + tensile" class="chart"></canvas>
          </td>
        </tr>
      </template>
    </table>
  </div>

   <!-- 통계 테이블 추가 -->
  <div class="statsDiv">
    <table class="custom-table">
      <tr>
        <th>Category</th>
        <th v-for="(peel, index) in formInputs.peels" :key="'peelHeader-' + index">Peel {{ index + 1 }}</th>
        <th v-for="(tensile, index) in formInputs.tensiles" :key="'tensileHeader-' + index">Tensile {{ index + 1 }}</th>
      </tr>
      <tr>
        <th>Average</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelAvg-' + index">{{ calculateAverage(peelData[index]) }}</td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileAvg-' + index">{{ calculateAverage(tensileData[index]) }}</td>
      </tr>
      <tr>
        <th>Min</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelMin-' + index">{{ calculateMin(peelData[index]) }}</td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileMin-' + index">{{ calculateMin(tensileData[index]) }}</td>
      </tr>
      <tr>
        <th>Max</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelMax-' + index">{{ calculateMax(peelData[index]) }}</td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileMax-' + index">{{ calculateMax(tensileData[index]) }}</td>
      </tr>
      <tr>
        <th>Range</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelRange-' + index">
          {{ calculateRange(minValue, maxValue) }}
        </td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileRange-' + index">
          {{ calculateRange(minValue, maxValue) }}
        </td>
      </tr>
      <tr>
        <th>Spread(S)</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelSpread-' + index">{{ calculateSpread(peelData[index]) }}</td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileSpread-' + index">{{ calculateSpread(tensileData[index]) }}</td>
      </tr>
      <tr>
        <th>Cp</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelCp-' + index">
          {{ calculateCp(calculateSpread(peelData[index]), minValue, maxValue) }}
        </td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileCp-' + index">
          {{ calculateCp(calculateSpread(tensileData[index]), minValue, maxValue) }}
        </td>
      </tr>
      <tr>
        <th>Cpk_max</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelCpk-' + index">
          {{ calculateCpkMax(calculateSpread(peelData[index]), minValue, maxValue, calculateAverage(peelData[index])) }}
        </td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileCpk-' + index">
          {{ calculateCpkMax(calculateSpread(tensileData[index]), minValue, maxValue, calculateAverage(tensileData[index])) }}
        </td>
      </tr>
      <tr>
        <th>Cpk_min</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelCpk-' + index">
          {{ calculateCpk(calculateSpread(peelData[index]), minValue, maxValue, calculateAverage(peelData[index])) }}
        </td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileCpk-' + index">
          {{ calculateCpk(calculateSpread(tensileData[index]), minValue, maxValue, calculateAverage(tensileData[index])) }}
        </td>
      </tr>
      <tr>
        <th>Capability</th>
        <td v-for="(peel, index) in formInputs.peels" :key="'peelCapability-' + index">
          {{ calculateCapability(calculateCp(calculateSpread(peelData[index]), minValue, maxValue), calculateCpk(calculateSpread(peelData[index]), minValue, maxValue, calculateAverage(peelData[index]), calculateMin(peelData[index]), calculateMax(peelData[index]))) }}
        </td>
        <td v-for="(tensile, index) in formInputs.tensiles" :key="'tensileCapability-' + index">
          {{ calculateCapability(calculateCp(calculateSpread(tensileData[index]), minValue, maxValue), calculateCpk(calculateSpread(tensileData[index]), minValue, maxValue, calculateAverage(tensileData[index]), calculateMin(tensileData[index]), calculateMax(tensileData[index]))) }}
        </td>
      </tr>
    </table>
  </div>
  
  <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
  <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>
</div>
</template>

<script>
import { reactive, ref, computed, watch, onMounted} from 'vue';
import Modal from "./spcPeelTensileModal.vue";
import modifyModal from "./spcPeelTensileMoModal.vue";
import { Chart, registerables, LinearScale, LineController, LineElement, PointElement, CategoryScale } from 'chart.js';
import { updateModel } from './model';  
import axios from 'axios';
import { deleteRow, downloadExcel, } from "../utils.js"; 

Chart.register(LinearScale, LineController, LineElement, PointElement, CategoryScale);

export default {
  components: { Modal, modifyModal },    

  methods: {  
    openSendModal(row) {this.selectedRowId = row.id; this.showMoModal = true;},    
    downExcel() { downloadExcel(this.item, "Peel Tensile.xlsx"); },
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    canDelClick() {    
      const currentUser = this.$route.meta.currentUser;      
      if (currentUser) {            
          return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3  ;
      } else {
          return false;
      }
    },    
  },

  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const project = ref(); const shift = ref(); const result = ref(); const model = ref(); 
    const minValue = ref(5); const maxValue = ref(40); 

    const dbTableName = 'qaspcpeeltensile';  
    const headers = ['Date', 'Factory', 'Shift', 'Type', 'Time', 'Line/Station', 'Class', 'Spare', 'Contents', 'Name', 'Done', 'Modify', 'DEL', ];    
    const tableData = reactive({ data: [], });  
    const modelOptions = ref([]);
    const showModal = ref(false);         
    const showMoModal = ref(false);     

    Chart.register(...registerables);

    const formInputs = reactive({     
      peels: Array.from({ length: 12 }, () => ({ value: '' })),
      tensiles: Array.from({ length: 12 }, () => ({ value: '' })),    
    });

    const peelData = computed(() =>
      formInputs.peels.map((_, index) => tableData.data.map(row => parseFloat(row[`peel${index + 1}`])))
    );

    const tensileData = computed(() =>
      formInputs.tensiles.map((_, index) => tableData.data.map(row => parseFloat(row[`tensile${index + 1}`])))
    );

    const calculateAverage = data => (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2);
    const calculateMin = data => Math.min(...data).toFixed(2);
    const calculateMax = data => Math.max(...data).toFixed(2);
    const calculateRange = (minValue, maxValue) => (maxValue - minValue).toFixed(2);
    const calculateSpread = data => {
      const mean = calculateAverage(data);
      const variance = data.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / data.length;
      return Math.sqrt(variance).toFixed(2);
    };
    
    const calculateCp = (spread, minValue, maxValue) => {
      const range = calculateRange(minValue, maxValue);      
      return parseFloat((range / (6 * spread)).toFixed(2));
    };

    const calculateCpk = (spread, minValue, maxValue, average) => {     
      const cpkLower = (average - minValue) / (3 * spread);
      const cpkUpper = (maxValue - average) / (3 * spread);  
      return Math.min(cpkLower, cpkUpper).toFixed(2);            
    };

    const calculateCpkMax = (spread, minValue, maxValue, average) => {     
      const cpkLower = (average - minValue) / (3 * spread);
      const cpkUpper = (maxValue - average) / (3 * spread);  
      return Math.max(cpkLower, cpkUpper).toFixed(2);            
    };

    const calculateCapability = (cp, cpk) => (cp >= 1.32 && cpk >= 1.32 ? 'OK' : 'NOK');  
    
    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, project.value, shift.value, result.value, model.value ]; return {sendDateData};};    

    const searchData = async (tableName) => {
      if (!project.value || !model.value) {
        alert("You have to select Project and Model");
        return;
      }

      const sendDateData = await sendDate();
      const formData = {        
          tableName: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
          project: sendDateData.sendDateData[6],
          shift: sendDateData.sendDateData[7],
          result: sendDateData.sendDateData[8],
          model: sendDateData.sendDateData[9],
      };    
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { 
          tableData.data = res.data.sort((a, b) => {          
            if (a.project !== b.project) return a.project - b.project;
            if (a.model !== b.model) return a.model - b.model;
            if (a.year !== b.year) return a.year - b.year;
            if (a.month !== b.month) return a.month - b.month;
            if (a.day !== b.day) return a.day - b.day;
            if (a.shift !== b.shift) return a.shift - b.shift;          
          });
        });
    };  

    const callSearchData = () => { searchData(dbTableName); };  

    const item = computed(() => {
      return tableData.data.map(d => ({
        date: `${d.year}-${d.month}-${d.day}`, 
        ...d  
      }));
    });

    const createCharts = () => {
      const createChart = (chartId, label, data, width, height) => {        
        const canvas = document.getElementById(chartId);
        if (!canvas) return;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        if (canvas.chartInstance) {
          canvas.chartInstance.destroy();
        }

        const numericData = data.map(value => Number(value));    
        
        const chartInstance = new Chart(ctx, {
          type: 'line',
          data: {
            labels: item.value.map(row => row.date),
            datasets: [
              {
                label: label,
                data: numericData,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                pointRadius: 5,
              }
            ]
          },
          options: {
            responsive: false,
            maintainAspectRatio: false,
            scales: {
              y: {
                min: 0,
                max: 40,
              },
              x: {
                display: false,
              },
            },
            plugins: {
              tooltip: {
                enabled: true,                                
                intersect: false,
                callbacks: {
                  label: function(tooltipItem) {              
                    return `Value: ${tooltipItem.raw}`;
                  }
                },    
              },
            },            
          },          
        });
        canvas.chartInstance = chartInstance;
      };

      formInputs.peels.forEach((peel, index) => {
        createChart(`peelChart${index + 1}`, `Peel ${index + 1}`, item.value.map(row => row[`peel${index + 1}`]), 600, 200);
      });

      formInputs.tensiles.forEach((tensile, index) => {
        createChart(`tensileChart${index + 1}`, `Tensile ${index + 1}`, item.value.map(row => row[`tensile${index + 1}`]), 600, 200);
      });
    };

    watch(() => [project.value, model.value], () => {
      updateModel(project.value, model.value, modelOptions, formInputs);
      createCharts();
    });
    watch(item, (newValue) => {
      if (newValue.length > 0) {
        createCharts();
      }
    });

    onMounted(() => { createCharts(); });

    const groupItems = (items, groupSize) => {
      return items.reduce((acc, item, index) => {
        const groupIndex = Math.floor(index / groupSize);
        if (!acc[groupIndex]) {
          acc[groupIndex] = [];
        }
        acc[groupIndex].push(index + 1); // item의 index를 사용하여 그룹화합니다.
        return acc;
      }, []);
    };

    const peeledGroups = computed(() => groupItems(formInputs.peels, 3));
    const tensileGroups = computed(() => groupItems(formInputs.tensiles, 3));

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay, shift, result, project, model,
      headers, dbTableName, item, showModal, showMoModal, modelOptions, formInputs,
      searchData,  sendDate, callSearchData, peeledGroups, tensileGroups,      
      minValue, maxValue,
      peelData,
      tensileData,
      calculateAverage,
      calculateMin,
      calculateMax,
      calculateSpread,
      calculateCp,
      calculateCpk,
      calculateCpkMax,
      calculateCapability,
      calculateRange,
    };
  },
};
</script>

<style scoped>
 * { box-sizing: border-box;}

.container { margin-top: 0px; padding-top: 10px; max-width: 100%; margin-left: auto; margin-right: auto;}
.start,.end { display: flex; flex-direction: row; border: 0px;}
.start input,.end input { margin-right: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px;}
.searchTd { border: 0px;}
.minMaxInput {margin: 10px; font-weight: bold}

input::placeholder { color: gray;}

label { font-size: 14px; font-weight: 600; margin-right: 8px;}

select,input[type="number"],input[type="text"] {
  padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
  font-size: 14px; width: 100%; max-width: 100px;
}

.del {
  padding: 4px 4px;
  background-color: #bfbebe;
  color: #000000; border: 10px; border-color: #000000;
  font-size: 10px; font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}

.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-left: 10px; margin-top: 10px;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }

.searchBar select,.searchBar input{ width : 100px; margin-right: 5px; padding : 5px; }

label { font-size: 14px; font-weight: 600; margin-right: 8px; }

td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 14px; }

th { 
  position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ; 
  border : 1px solid black; text-align : center; padding : 10px 10px; font-size : 14px; 
}
.tables {
  display: flex; flex-direction: column; align-items: stretch;
  padding-left : 10px; padding-right : 10px;
}

.custom-table { width: 100%; }
.chartDiv { margin: 20px 10px; }
.charttable { width: 100%; }
.statsDiv { margin: 20px 10px; }

.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 10px; }
.disabledButton { background-color: gray !important; cursor: not-allowed !important; }
.confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.contents { width: 500px !important; text-align: left;}
.ok-background { background-color: rgb(0, 158, 0); font-weight: bold; color: white; padding: 0px 0px; } 
.ng-background { background-color: rgb(196, 3, 3); font-weight: bold; color: white; padding: 0px 0px; } 
.peelTest {background-color: rgb(145, 188, 252);}
.tensileTest {background-color: rgb(242, 255, 187);}

.searchButton {
    background-color: #929292;  color: rgb(0, 0, 0);
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px;
    width: 100px; height: 30px; font-size: 15px
}
.searchButton:hover { background-color: #a4a4a4; }
.excelButton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; 
    width: 100px; height: 30px; font-size: 15px
}
.excelButton:hover { background-color: #009625; }
.addButton {
    background-color: #007396;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px;
    width: 150px; height: 30px; font-size: 15px 
}
.addButton:hover { background-color:  #3498db; }
.moButton {
    padding: 5px 5px; background-color: #0d4d01; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
.chart { margin: 10px;  position: relative; z-index: 1000;  pointer-events: auto;  }

</style>