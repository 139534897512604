<template>
  <div class="container">            
    <h class="pagetitle">{{ titleName }} History</h>        
    <form @submit.prevent="sendDate()" class="searchBar">
      <div class="horizontal-form"> 
        <div>
          <select id="line" v-model="line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
            <option>ALL</option>              
          </select>  
        </div>
        <div>
          <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
          <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
          <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
        </div>   
        <div>~</div> 
        <div>
          <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
          <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
          <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
        </div>        
        <div>
          <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
        </div>
        <div>
          <button class="excelButton" @click="downExcel">Excel</button>                                              
        </div>
        <div>
          <button class="addButton" @click="showModal = true">+ Add</button>   
        </div>              
      </div>      
    </form>            
    <div class="tables">
        <table id="productionResult" class="custom-table">
        <tr>          
          <th>Date</th><th>Contents</th><th>Modify</th><th>DEL</th>
        </tr>             
        <tr v-for="row in item" :key="row.id">            
          <td class="date">{{ row.day }}-{{ row.month }}-{{ row.year }}</td>                  
          <td class="contents">{{ row.contents }}</td>                
          <td class="delTd"><button type="submit" @click="openSendModal(row)" class="moButton">Modify</button></td>    
          <td class="delTd"><button :class="['del', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>                                        
        </tr>
  </table>    
  </div>
  <Modal  v-if="showModal" :type="type" @onClose="showModal = false; callSearchData();"/>    
  <modifyModal  v-if="showMoModal" :type="type" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>
</div>
</template>

<script>
import { reactive, ref, computed} from 'vue';
import Modal from "./checkSheetHistoryModal.vue";
import modifyModal from "./checkSheetHistoryMoModal.vue";
import axios from 'axios';
import { deleteRow, downloadExcel, fetchData } from "../../utils.js"; 


export default {
  components: { Modal, modifyModal, },    

methods: {  
  openSendModal(row) {this.selectedRowId = row.id; this.showMoModal = true;},    
  downExcel() { downloadExcel(this.item, "Check Sheet History.xlsx"); },
  delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
  canDelClick() {    
    const currentUser = this.$route.meta.currentUser;
    console.log('currentUser', currentUser)
    if (currentUser) {            
        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3  ;
    } else {
        return false;
    }
  },  
},

props: { type: { type: String, required: true }, headName: { type: String, required: true }}, 
setup(props) {
  const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const line = ref();

  const dbTableName = 'checksheethistory';    
  const tableData = reactive({ data: [], });  
  const showModal = ref(false);     
  const showMoModal = ref(false);    
  const uniqueLines = ref([]);    
  const titleName = computed(() => props.headName);

  const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, line.value ]; return {sendDateData};};     
  const searchData = async (tableName) => {
    if (!line.value) {return}
    const sendDateData = await sendDate();
    const formData = {        
        tableName: tableName,
        startYear: sendDateData.sendDateData[0],
        startMonth: sendDateData.sendDateData[1],
        startDay: sendDateData.sendDateData[2],
        endYear: sendDateData.sendDateData[3],
        endMonth: sendDateData.sendDateData[4],
        endDay: sendDateData.sendDateData[5],
        line: sendDateData.sendDateData[6], 
        type: props.type
    };    
    axios.get('/api/searchPeriod', { params: formData })
      .then((res) => { 
        tableData.data = res.data.sort((a, b) => {          
          if (a.year !== b.year) return a.year - b.year;
          if (a.month !== b.month) return a.month - b.month;
          if (a.day !== b.day) return a.day - b.day;          
        });
      });
  };

  const callSearchData = () => { searchData(dbTableName); };  

  const item = computed(() => { return tableData.data.map(d => { return { ...d, }; }); });

  const loadLines = async () => {
    uniqueLines.value = await fetchData('checksheettarget', 'line');
    uniqueLines.value.sort((a, b) => a.localeCompare(b));
  };
  loadLines();

  return {
    startYear, startMonth, startDay, endYear, endMonth, endDay, line, titleName,
    dbTableName, item, showModal, showMoModal,
    searchData, callSearchData, sendDate, uniqueLines
  };
},
};
</script>

<style scoped>
 * { box-sizing: border-box;}

.container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: auto; margin-right: auto;}
.start,.end { display: flex; flex-direction: row; border: 0px;}
.start input,.end input { margin-right: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px;}
.searchTd { border: 0px;}

input::placeholder { color: gray;}

select,input[type="number"],input[type="text"] {
  padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
  font-size: 14px; width: 100%; max-width: 100px;
}

.del {
  padding: 5px 5px;
  background-color: #bfbebe;
  color: #000000; border: 10px; border-color: #000000;
  font-size: 14px; font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}

.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-left: 10px; margin-top: 10px;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }

.searchBar select,.searchBar input{ width : 100px; margin-right: 5px; padding : 5px; }


.date {width: 100px;}
.delTd {width: 50px;}
.contents {width: 1000px;}
td { border : 1px solid black; text-align : center; padding : 10px 10px; font-size : 14px; }

th { 
  position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ; height: 30px;
  border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 14px; 
}
.tables {
  display: flex; flex-direction: column; align-items: stretch;
  padding-left : 10px; padding-right : 10px;
}

table { width: 80%; }
.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 10px; }
.disabledButton { background-color: gray !important; cursor: not-allowed !important; }
.confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.contents { width: 500px !important; text-align: left;}
.gray-background { background-color: gray;} 

.searchButton, .excelButton, .addButton { width: 100px; height: 30px !important; font-size: 15px}
.searchButton {
    background-color: #929292;  color: rgb(0, 0, 0);
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px;
}
.searchButton:hover { background-color: #a4a4a4; }
.excelButton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; 
}
.excelButton:hover { background-color: #009625; }
.addButton {
    background-color: #007396;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; 
}
.addButton:hover { background-color:  #3498db; }
.moButton {
    padding: 5px 5px; background-color: #0d4d01; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   

</style>