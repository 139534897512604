import { ref, watch } from 'vue';  
import axios from 'axios';   
import { getColumnMapping, makeFormData, useFormInputs, fetchData, } from '../../../utils.js'
//import { getColumnMapping, makeFormData, useFormInputs, fetchLine, fetchStation} from '../../../utils.js'
  
export function useForm(props, emit) {       
    const dbTableName = "maintenancechecktarget"    
    const uniqueLines = ref([]);   
    const uniqueStations = ref([]);     

    const { formInputs, dataForm } = useFormInputs();     
                    
    const submitForm = async () => {
      if (!formInputs.line || !formInputs.station_row || !formInputs.row_no ) {
        alert("Please fill in all the fields: line, and model.");
        return;
      }

      const columnMapping = getColumnMapping(undefined, formInputs);  
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
      
      await axios.post('/api/post', formData);
      
      closeModal();
    };

    const closeModal = () => { emit("onClose"); alert("You have to register changes to the check sheet history.");};

    const loadLines = async () => {
      const lines = await fetchData('machinedata', 'line');
      const excludedLines = ["EV2020 60FR", "EV2020 60RR", "EV2020 4060FR", "EV2020 4060RR",
        "Common", "Leader", "Maintenance", "Packing", "Production Logistic", "Technician"      
      ];
      uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
      uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines();  

    const loadStations = async (line) => { uniqueStations.value = await fetchData('machinedata', 'station', [{ column: 'line', value: line }]);};
    loadStations(props.lineName); 

    //const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
    //const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };
    watch(() => formInputs.line, (newLine) => { loadStations(newLine); });

    return { dbTableName, formInputs, submitForm, closeModal, uniqueLines, uniqueStations, dataForm };
}