<template>
  <div class="container">        
    <div class="RequestLog">
      <h class="pagetitle">In-Out Log</h>        
      <form @submit.prevent="sendDate()" class="searchBar">
        <table>  <!--검색 및 엑셀 버튼 -->
            <tr>
              <td class="start">
                  <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
                  <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
                  <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
              </td>
              <td class="separator">~</td>
              <td class="end">
                  <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
                  <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
                  <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
              </td>    
              <td class="factory">
                    <select id="factoryselect" v-model="factory" placeholder="Factory">
                      <option>1</option><option>2</option>
                    </select>                    
              </td>          
              <td class="searchTd">
                  <button type="submit" @click="callSearchData">Search</button>                        
              </td>
              <td class="searchTd">
                  <button class="excelButton" @click="Excel">Excel</button>                                              
              </td>
            </tr>
        </table>      
      </form>
    </div>            
    <div class="tables">
      <b>Material In-Out</b>
      <table class="materialLog">
        <tr>          
          <th>Material Code</th><th>Material Name</th><th>In Qty</th><th>Out Qty</th><th>Difference</th><th>Unit</th>
        </tr>          
        <tr v-for="row in item" :key="row.id">          
          <td class="list">{{ row.materialcode }}</td>
          <td class="list">{{ row.materialname }}</td>
          <td class="list">{{ row.tototalQuantity }}</td>
          <td class="list">{{ row.fromtotalQuantity }}</td>
          <td class="list">{{ row.different }}</td>
          <td class="list">{{ row.unit }}</td>            
        </tr>
      </table>
  </div>
</div>
</template>

<script>
import { reactive, ref, computed} from 'vue';
import axios from "axios";

export default {

 setup(){
  const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const factory = ref();
  const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, factory.value]; return {sendDateData};};     
  const moTableName = 'stockmovement';    
  
  const fromTableData = reactive({ data: [], });
  const toTableData = reactive({ data: [], });

  const fromSearchData = async (tableName) => {
    // 첫 번째 단계: fromSearchData 실행
    const sendDateData = await sendDate();
    const fromSearchDataForm = {
      tableName: tableName,
      startYear: sendDateData.sendDateData[0],
      startMonth: sendDateData.sendDateData[1],
      startDay: sendDateData.sendDateData[2],
      endYear: sendDateData.sendDateData[3],
      endMonth: sendDateData.sendDateData[4],
      endDay: sendDateData.sendDateData[5],                 
      fromlocation: sendDateData.sendDateData[6],    
    };

    console.log('Requesting data from:', fromSearchDataForm);
    await axios.get('/api/searchPeriod', { params: fromSearchDataForm })
      .then((res) => { fromTableData.data = res.data; });

    // 모든 materialCode의 quantity 합산
    const materialQuantitySum = {};
    fromTableData.data.forEach(item => {
      materialQuantitySum[item.materialcode] = (materialQuantitySum[item.materialcode] || 0) + item.quantity;
    });

    // 두 번째 단계: materialCode의 BOM 구하기 및 계산
    const inRangeMaterialCodes = new Set(
      Object.keys(materialQuantitySum).filter(code => 
        parseInt(code) >= 2100000 && parseInt(code) <= 2299999)
    );

    const productRequests = Array.from(inRangeMaterialCodes).map(materialCode => {
      return axios.get('/api/where', { 
        params: { value: materialCode, table_name: 'materiallist', column_name: 'productcode' }
      });
    });

    const productResponses = await Promise.all(productRequests);
    const bomData = productResponses.flatMap(res => res.data);

    // 총 수량 계산 및 범위 외 materialCode 처리
    const calculatedData = bomData.map(bomItem => {
      const productQuantity = materialQuantitySum[bomItem.productcode] || 0;
      const bomQuantity = bomItem.bom ? bomItem.bom : 0;

      return {
        ...bomItem,
        totalQuantity: productQuantity * bomQuantity
      };
    });

    // 범위 외 materialCode 중 BOM에 없는 항목 추가
    fromTableData.data.forEach(item => {
      if (!inRangeMaterialCodes.has(item.materialcode.toString())) {
        calculatedData.push({
          ...item,
          totalQuantity: item.quantity
        });
      }
    });

    // 계산된 데이터 저장
    fromTableData.data = calculatedData;

    // 같은 materialcode의 totalQuantity 합산 및 필요한 정보만 저장
    const aggregatedData = fromTableData.data.reduce((acc, item) => {
      const existingItem = acc.find(accItem => accItem.materialcode === item.materialcode);
      if (existingItem) {
        existingItem.totalQuantity += item.totalQuantity;
      } else {
        acc.push({
          materialcode: item.materialcode,
          materialname: item.materialname,
          totalQuantity: item.totalQuantity,
          unit: item.bomunit || item.unit // bomunit이 없으면 unit 사용
        });
      }
      return acc;
    }, []);

    // 합산된 데이터로 fromTableData.data 업데이트
    fromTableData.data = aggregatedData;
  };

  const toSearchData = async (tableName) => {
    // 첫 번째 단계: toSearchData 실행
    const sendDateData = await sendDate();
    const toSearchDataForm = {
      tableName: tableName,
      startYear: sendDateData.sendDateData[0],
      startMonth: sendDateData.sendDateData[1],
      startDay: sendDateData.sendDateData[2],
      endYear: sendDateData.sendDateData[3],
      endMonth: sendDateData.sendDateData[4],
      endDay: sendDateData.sendDateData[5],                 
      tolocation: sendDateData.sendDateData[6],            
    };

    console.log('Requesting data from:', toSearchDataForm);
    await axios.get('/api/searchPeriod', { params: toSearchDataForm })
      .then((res) => { toTableData.data = res.data; });

    // 모든 materialCode의 quantity 합산
    const materialQuantitySum = {};
    toTableData.data.forEach(item => {
      materialQuantitySum[item.materialcode] = (materialQuantitySum[item.materialcode] || 0) + item.quantity;
    });

    // 두 번째 단계: materialCode의 BOM 구하기 및 계산
    const inRangeMaterialCodes = new Set(
      Object.keys(materialQuantitySum).filter(code => 
        parseInt(code) >= 2100000 && parseInt(code) <= 2299999)
    );

    const productRequests = Array.from(inRangeMaterialCodes).map(materialCode => {
      return axios.get('/api/where', { 
        params: { value: materialCode, table_name: 'materiallist', column_name: 'productcode' }
      });
    });

    const productResponses = await Promise.all(productRequests);
    const bomData = productResponses.flatMap(res => res.data);

    // 총 수량 계산 및 범위 외 materialCode 처리
    const calculatedData = bomData.map(bomItem => {
      const productQuantity = materialQuantitySum[bomItem.productcode] || 0;
      const bomQuantity = bomItem.bom ? bomItem.bom : 0;

      return {
        ...bomItem,
        totalQuantity: productQuantity * bomQuantity
      };
    });

    // 범위 외 materialCode 중 BOM에 없는 항목 추가
    toTableData.data.forEach(item => {
      if (!inRangeMaterialCodes.has(item.materialcode.toString())) {
        calculatedData.push({
          ...item,
          totalQuantity: item.quantity
        });
      }
    });

    // 계산된 데이터 저장
    toTableData.data = calculatedData;

    // 같은 materialcode의 totalQuantity 합산 및 필요한 정보만 저장
    const aggregatedData = toTableData.data.reduce((acc, item) => {
      const existingItem = acc.find(accItem => accItem.materialcode === item.materialcode);
      if (existingItem) {
        existingItem.totalQuantity += item.totalQuantity;
      } else {
        acc.push({
          materialcode: item.materialcode,
          materialname: item.materialname,
          totalQuantity: item.totalQuantity,
          unit: item.bomunit || item.unit // bomunit이 없으면 unit 사용
        });
      }
      return acc;
    }, []);

    // 합산된 데이터로 toTableData.data 업데이트
    toTableData.data = aggregatedData;
  };

  const mergeFromAndToData = () => {
    const mergedData = {};

    // fromTableData 데이터 처리
    fromTableData.data.forEach(item => {
      const code = item.materialcode;
      if (!mergedData[code]) {
        mergedData[code] = {
          materialcode: item.materialcode,
          materialname: item.materialname,
          fromtotalQuantity: item.totalQuantity,
          tototalQuantity: 0, // 초기값 0
          unit: item.unit
        };
      } else {
        mergedData[code].fromtotalQuantity += item.totalQuantity;
      }
    });

    // toTableData 데이터 처리
    toTableData.data.forEach(item => {
      const code = item.materialcode;
      if (!mergedData[code]) {
        mergedData[code] = {
          materialcode: item.materialcode,
          materialname: item.materialname,
          fromtotalQuantity: 0, // 초기값 0
          tototalQuantity: item.totalQuantity,
          unit: item.unit
        };
      } else {
        mergedData[code].tototalQuantity += item.totalQuantity;
      }
    });

    // different 계산 및 추가
    Object.values(mergedData).forEach(item => {
      item.different = item.tototalQuantity - item.fromtotalQuantity;
    });

    return Object.values(mergedData); // 객체를 배열로 변환
  };

  // Vue.js의 computed 속성을 사용하여 결합된 데이터 생성
  const item = computed(() => {
    return mergeFromAndToData();
  });

  const callSearchData = () => { fromSearchData(moTableName); toSearchData(moTableName); };   
  
  return {startYear, startMonth, startDay, endYear, endMonth, endDay, factory, item, callSearchData, sendDate };
  }
}
 
</script>
  
<style scoped>
   * { box-sizing: border-box; }
  .container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: auto; margin-right: auto;}
  .start,.end { display: flex; flex-direction: row; border: 0px;}
  .factory {  border: 0px;}
  .start input,.end input, .factory select { margin-right: 5px; }
  .separator { font-size: 24px; margin: 0 10px; border: 0px; }
  .searchTd { border: 0px; }

  input::placeholder { color: gray; }

  label { font-size: 14px; font-weight: 600; margin-right: 8px; }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px; border: 1px solid #ccc;
    border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px;
  }

  button {
    padding: 5px 5px; background-color: #3498db; color: #fff;
    border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer; transition: background-color 0.3s;
  }
 .excelButton {
  padding: 5px 5px; background-color: #347003; color: #fff;
  border: none; border-radius: 4px; font-size: 15px; font-weight: 600;
  cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton:hover{ background-color: #009625; }
  button:hover { background-color: #2980b9; }
  .del {
    padding: 4px 4px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 10px; font-weight: 600; cursor: pointer; transition: background-color 0.3s;
  }
  .RequestLog{ width: 700px; }
  .searchBar {
    display: flex; flex-wrap: nowrap; justify-content: flex-start;
    align-items: center; margin-bottom: 20px; margin-left: 20px;
  }
  .searchBar > *:not(:last-child) { margin-right: 5px; }
  .searchBar select,.searchBar input { width : 100px; margin-right: 5px; padding : 5px; }
  label { font-size: 14px; font-weight: 600; margin-right: 8px; }

  td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }

  th {
      background-color: rgb(230, 230, 230) ;
      border : 1px solid black; text-align : center;
      padding-left : 10px; padding-right : 10px; font-size : 12px; 
  }
  .tables { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; }

  table { width: 80%; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .disabledButton { background-color: gray !important; cursor: not-allowed !important; }
  .confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
  }
  .gray-background { background-color: gray;}  
</style>