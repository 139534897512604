<template>
  <div class="checkTarget">      
    <hr>
    <div class="parent">
      <div class="horizontal-form">        
        <div class="searchTd">
          <select id="line" v-model="formInputs.line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
            <option>ALL</option>              
          </select>  
        </div>
        <div class="searchTd">
          <button class="searchButton" type="submit" @click="callSearchData">Search</button>                        
        </div>
        <div class="searchTd">
          <div class="addbutton">        
            <button class="addButton" @click="showModal = true">+ Add</button>   
            <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
            <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
            <span class="fileName">{{ fileName }}</span>   
            <button class="excelbutton" @click="upExcel('checksheettarget')">Upload</button>
            <button class="excelbutton" @click="downExcel">Down Load</button>  
            <button class="delButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="delAll(targetTableName, { line: formInputs.line })">ALL DEL</button>
          </div>
        </div>        
      </div>
    </div>
    <div class="table-container">      
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line_name">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Line name'" :key="index" >{{ row.line }}</td>
          <td v-if="header === 'Process Row'" :key="index" @click="editCell(targetTableName, row.id, 'process_row', $event)">{{ row.process_row }}</td>
          <td v-if="header === 'Process'" :key="index" @click="editCell(targetTableName, row.id, 'process', $event)">{{ row.process }}</td>
          <td v-if="header === 'Model'" :key="index" @click="editCell(targetTableName, row.id, 'model', $event)">{{ row.model }}</td>
          <td v-if="header === 'Item'" :key="index" @click="editCell(targetTableName, row.id, 'item', $event)">{{ row.item }}</td>
          <td v-if="header === 'Lower Limit'" :key="index" @click="editCell(targetTableName, row.id, 'lowerlimit', $event)">{{ row.lowerlimit }}</td>
          <td v-if="header === 'Standard Value'" :key="index" @click="editCell(targetTableName, row.id, 'standardvalue', $event)">{{ row.standardvalue }}</td>
          <td v-if="header === 'Upper Limit'" :key="index" @click="editCell(targetTableName, row.id, 'upperlimit', $event)">{{ row.upperlimit }}</td>
          <td v-if="header === 'Unit'" :key="index" @click="editCell(targetTableName, row.id, 'unit', $event)">{{ row.unit }}</td>
          <td v-if="header === 'Update Date'" :key="index" @click="editCell(targetTableName, row.id, 'updatedate', $event)">{{ row.updatedate }}</td>
          <td v-if="header === 'Acceptance Criteria'" :key="index" @click="editCell(targetTableName, row.id, 'acceptance', $event)">{{ row.acceptance }}</td>          
          <td v-if="header === 'Remark'" :key="index" @click="editCell(targetTableName, row.id, 'remark', $event)">{{ row.remark }}</td>
          <td v-if="header === 'Row No'" :key="index" @click="editCell(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
          <td v-if="header === 'Except'" :key="index" @click="editCell(targetTableName, row.id, 'except_row', $event)">{{ row.except_row }}</td>                    
        </template>   
      </tr>
    </tbody>     
    </table> <br>
    <h3>ROW_NO Standard</h3>
    <table class="rownostandard">
      <tr>
        <th>Project</th><th>Line</th><th>Model</th><th>Process</th><th>Serial No</th>
      </tr>
      <tr>
        <td>1 digit</td><td>2 digit</td><td>1 digit</td><td>2 digit</td><td>2 digit</td>
      </tr>
      <tr>
        <td colspan="5">Example : 10110101 (VW: 1 / TR3 SMT: 01 / EVO_2P: 1 / Master Sample: 01 / Serial No: 01)</td>                
      </tr>
    </table>

    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData(); "/>    
    </div>
</template>

<script>
import Modal from "./checkTargetModal.vue";
import { onFileChange, uploadExcel, downloadExcel, editCellValue, deleteAll} from "../../utils.js"; 
import { useForm } from '../0_formStyle/form_checkTarget';

export default {
  components: { Modal, },    
  data() { return { excelFile: null, fileName: "", }; }, 

  methods: {
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
    downExcel() { downloadExcel(this.targetItem, "CheckTarget.xlsx"); },
    delAll(tableName, filters) { deleteAll(tableName, filters, this.callSearchData); },
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
          alert("You have to register changes to the check sheet history.");
      }
    },  
    canClick() {            
      const currentUser = this.$route.meta.currentUser;            
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;}
      else { return false;}
    }, 
  },
  
  setup() {
    return useForm();
  }
};
</script>

<style scoped src="../0_formStyle/style_check_target.css"></style>