<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Daily Report</h3>          
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            
            <div class="form-group">
              <label for="id">ID(Use when chaning data):</label>
              <input type="number" id="id" v-model="formInputs.id" />                
            </div>            
            <div class="form-group date-time-group">
                <label>Comment Date:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div>
              </div>
            <div class="form-group">
              <label for="line_name">Line Name:</label>
              <select id="line" v-model="formInputs.line_name">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option>Inkatom</option><option></option>        
              </select>
            </div>                                  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="problem">Problem:</label>
              <textarea id="problem" v-model="formInputs.problem" rows="4" cols="25"></textarea>
            </div>                 
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="countermeasure">Countermeasure:</label>
              <textarea id="countermeasure" v-model="formInputs.countermeasure" rows="4" cols="25"></textarea>
            </div>   
            <button type="button" id="save" @click="submitForm" >Save</button>
            <button type="button" id="modify" @click="modifyForm" >Modify</button>
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>   
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, fetchData} from "../../utils.js"; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "dailyreportcomment"                  
        const uniqueLines = ref([]); 
        const formInputs = reactive({
          id: "",
          line_name: "",          
          problem: "",
          countermeasure: "",
        });       
      
        const searchDate = reactive({ ...getCurrentDate(), });

        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day) {
            alert("Please fill in all the fields: year, month, and day.");
            return;
          }         

          const columnMapping = {    
            year: 'year',
            month: 'month',
            day: 'day',
            line_name: 'line_name',
            problem: 'problem',              
            countermeasure: 'countermeasure' 
          }   

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              line_name: formInputs.line_name,
              problem: formInputs.problem,              
              countermeasure: formInputs.countermeasure,              
            }
         };         
          await axios.post('/api/post', formData);         
          closeModal();
        };

        const modifyForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day) {
            alert("Please fill in all the fields: year, month, and day.");
            return;
          }

          const columnMapping = {    
            year: 'year',
            month: 'month',
            day: 'day',
            line_name: 'line_name',
            problem: 'problem',              
            countermeasure: 'countermeasure' 
          }   
          
          const formData = {
            id: formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {              
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,              
              line_name: formInputs.line_name,
              problem: formInputs.problem,              
              countermeasure: formInputs.countermeasure,              
            }
         }; 
         const id = formInputs.id;
              if (!id) {
                alert("ID is missing");
                return;
              }              
          await axios.put(`/api/materialput/${id}`, formData);          
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };      

       const loadLines = async () => {
          const lines = await fetchData('machinedata', 'line');
          const excludedLines = ["EV2020 60kW", "EV2020 4060kW", 
            "Common", "Leader", "Maintenance", "Packing", "Production Logistic", "Technician",
            "2nd Factory Compressors", "2nd Factory Boilers", "2nd Factory Dust collectors", "2nd Factory AHU",
            "2nd Factory Transformer", "1st Factory Compressor 1", "1st Factory Compressor 2", "1st Factory Boiler 1",
            "1st Factory Boiler 2", "1st Factory Dust collector 1", "1st Factory Dust collector 2", "1st Factory Dust collector 3",
            "1st Factory Dust collector 4", "1st Factory NEW AHU", "1st Factory OLD AHU", "1st Factory AHU SMT", "1st Factory Transformer",            
          ];
          uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
          uniqueLines.value.sort((a, b) => a.localeCompare(b));
        }; loadLines();
  
      return { searchDate, dbTableName, formInputs, submitForm, closeModal, modifyForm, uniqueLines };

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 


};
  
</script>
    
<style scoped>
    
    /* Reset */
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper {
      width: 100%;
      max-width: 400px;
    }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 20px;
      max-height: 80vh;
      overflow-y: auto;
    }
    
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
  
    .modal-header h3 {
      font-size: 1.5rem;
    }
  
    .close-button {
      background-color: transparent;
      border: none;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
    }
  
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 15px;
    }
  
    label {
      font-weight: bold;
    }
  
    input, textarea, select {
      font-size: 1rem;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  
    input[type="number"] {
      width: 100%;
    }
  
    .time-inputs {
      display: flex;
      gap: 5px;
    }
  
    .time-inputs input[type="number"] {
      width: 50px;
    }
  
    .readWindow[readonly] {
      background-color: #f0f0f0;
    }
  
    button[type="button"] {
      background-color: #4CAF50;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      transition-duration: 0.4s;
    }
  
    button[type="button"]:hover {
      background-color: #45a049;
    } 
    .date-time-group {
  display: flex;
  align-items: left;
}

.date-time-inputs {
  display: flex;
  gap: 5px;
}

.date-time-inputs input {
  width: 85px;
  size: 8px;
}
</style>