<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Asset Class</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="modalTable">
              <tr>
                <td>
                  <div class="form-group">
                    <label class="modalLabel">Class 1 Code:</label>
                    <input class="modalInput" type="text" id="class1" v-model="formInputs.classcode1" />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label class="modalLabel">Class 1 Description:</label>
                    <input class="modalInput" type="text" id="class1" v-model="formInputs.classdescription1" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label class="modalLabel">Class 2 Code:</label>
                    <input class="modalInput" type="text" id="class2" v-model="formInputs.classcode2" />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label class="modalLabel">Class 2 Description:</label>
                    <input class="modalInput" type="text" id="class2" v-model="formInputs.classdescription2" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label class="modalLabel">Class 3 Code:</label>
                    <input class="modalInput" type="text" id="class3" v-model="formInputs.classcode3" />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label class="modalLabel">Class 3 Description:</label>
                    <input class="modalInput" type="text" id="class3" v-model="formInputs.classdescription3" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label class="modalLabel">Remark:</label>
                    <input class="modalInput" type="text" id="remark" v-model="formInputs.remark" />
                  </div>
                  <div class="form-group">
                    <label class="modalLabel">Row No:</label>
                    <input class="modalInput" type="text" id="row_no" v-model="formInputs.row_no" />
                  </div>
                </td>          
              </tr>
            </table>
            <button type="submit" class="saveButton" >Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>     
  import axios from 'axios';   
  import { getColumnMapping, makeFormData, useFormInputs,} from "../../utils.js"; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "assetclass"  
        const { formInputs, dataForm } = useFormInputs();

        const submitForm = async () => {    
          const columnMapping = getColumnMapping(undefined, formInputs);  
          const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
          await axios.post('/api/post', formData);
          closeModal();
        };

        const closeModal = () => { emit("onClose");};        
  
      return { dbTableName, formInputs, submitForm, closeModal, dataForm};

    },     
    props: { onSubmit: { type: Function, required: true, }, }, 
};
  
</script>
    
<style scoped>    
  * { margin: 0; padding: 0; box-sizing: border-box; }
    
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding: 20px 20px;
    display: flex; justify-content: flex-start; align-items: flex-start;
  }
  
  .modal-wrapper { width: 100%;  max-width: 500px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }
  .modalTable { table-layout: fixed; width: 100%; }  
  .modalTable td { width: 20%; vertical-align: top; padding: 0 15px; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  .modalLabel { font-weight: bold; }

  .modalInput { width: 100%; font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  .modalSelect { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; }

  .saveButton {
    background-color: #4CAF50; color: white; padding: 5px 20px; border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px; display: inline-block; transition-duration: 0.4s;
  }  
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
</style>