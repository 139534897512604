import { reactive, ref, computed } from 'vue';
import axios from 'axios';

export const createReportUtils = (tableNames) => {
    const state = reactive({
        data: {},   
        resultData: [],
        showModal: ref(false),
        searchDate: ref({ year: '', month: '', day: '' })
    });

    tableNames.forEach(tableName => {
        state.data[tableName] = reactive({ data: [] });
    });

    /*

    const fetchData = async (tableName, date) => {
        if (!date || !date.year || !date.month || !date.day) {
            throw new Error('Invalid date provided');
        }

        const formData = {
            tableName,
            values: {
                year: date.year,
                month: date.month,
                day: date.day
            }
        };

        try {
            const res = await axios.get('/api/productionData', { params: formData });
            state.data[tableName].data = res.data;            
        } catch (error) {
            console.error(`Error fetching data for table ${tableName}:`, error.message);
        }
    };
    */

    const fetchData = async (date) => {
        if (!date || !date.year || !date.month || !date.day) {
            throw new Error('Invalid date provided');
        }

        const formData = {
            tableNames: tableNames.join(','), 
            values: {
                year: date.year,
                month: date.month,
                day: date.day
            }
        };

        try {
            const res = await axios.get('/api/productionData', { params: formData });
            const responseData = res.data;

            responseData.forEach(tableData => {
                const { tableName, data } = tableData;
                state.data[tableName].data = data || [];  
            });
        } catch (error) {
            console.error("Error fetching table data:", error.message);
        }
    };

    const fetchAllData = async (date) => {
        if (!date) {
            throw new Error('Date parameter is required for fetchAllData');
        }
        await fetchData(date);
    };


    const fetchResultData = async (date) => {
        if (!date || !date.year || !date.month || !date.day) {
            throw new Error('Invalid date provided');
        }

        const formData = {
            tableName: 'resultverification',
            values: {
                year: date.year,
                month: date.month,
                day: date.day
            }
        };

        try {
            const res = await axios.get('/api/productionData', { params: formData });            
            const groupedData = {};

            res.data.forEach(item => {
                const key = `${item.year}-${item.month}-${item.day}-${item.shift}-${item.line}-${item.type}`;
                if (!groupedData[key] || item.id > groupedData[key].id) {
                    groupedData[key] = item;
                }
            });            

            const aggregatedData = {};

            Object.values(groupedData).forEach(item => {
                const key = `${item.line}-${item.type}`;
                if (!aggregatedData[key]) {
                    aggregatedData[key] = { ...item, quantity: 0 };
                }
                aggregatedData[key].quantity += item.quantity;
            });

            state.resultData = Object.values(aggregatedData);
           
        } catch (error) {
            console.error(`Error fetching data for table ${'resultverification'}:`, error.message);
        }
    };

    const sums = computed(() => {
        const sumsObject = {};

        tableNames.forEach(tableName => {
            sumsObject[tableName] = {
                target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ok_qw: 0, ng_qw: 0, quantity: 0
            };
        });

        tableNames.forEach(tableName => {
            state.data[tableName].data.forEach(row => {
                sumsObject[tableName].target += row.target || 0;
                sumsObject[tableName].ok += row.ok || 0;
                sumsObject[tableName].ng += row.ng || 0;
                sumsObject[tableName].work_minute += row.work_minute || 0;
                sumsObject[tableName].time += row.time || 0;
                sumsObject[tableName].ok_qw += row.ok_qw || 0;
                sumsObject[tableName].ng_qw += row.ng_qw || 0;
                sumsObject[tableName].quantity += row.quantity || 0;
            });
        });       
        return sumsObject;
    });

    const calculateRowData = (targetName, targetConfig, resultItem) => {
        const sumsObject = sums.value;
        const prData = sumsObject[targetConfig.pr] || {};        
        const qwData = sumsObject[targetConfig.qw] || {};

        const resultDetails = resultItem[targetName] || {};
        
        const physical_ok = resultDetails['Physical'] || 0;
        const web_ok = prData.ok - qwData.ng || 0;
        const sap_ok = resultDetails['SAP'] || 0;
        const diff_sap_web = sap_ok - web_ok;
        const diff_physical_web = physical_ok - web_ok;
        const diff_sap_physical = sap_ok - physical_ok;

        return {
            physical_ok, web_ok, sap_ok, diff_sap_web, diff_physical_web, diff_sap_physical
        };
    };

    return {
        state,
        fetchAllData,  
        calculateRowData,
        fetchResultData,
        sums
    };
};
