<template>
  <div class="modal-mask">
  <div class="modal-wrapper">
  <div class="modal-container">
  <div class="modal-header">
    <h3>Document Report View</h3>
    <button class="close-button" @click="closeModal">X</button>
  </div>
    <div class="modal-body">
      <table class="signTable">
        <tr>
          <th>Date</th><th>Writer</th><th>Sign 1</th><th>Sign 2</th><th>Sign 3</th><th>Sign 4</th><th>Sign 5</th>
        </tr>
        <tr>
          <td>{{formInputs.day}}.{{formInputs.month}}.{{formInputs.year}}</td>
          <td>{{formInputs.writer}}</td>
          <td>{{formInputs.sign1}}<br>{{formInputs.signed1}}</td>
          <td>{{formInputs.sign2}}<br>{{formInputs.signed2}}</td>
          <td>{{formInputs.sign3}}<br>{{formInputs.signed3}}</td>
          <td>{{formInputs.sign4}}<br>{{formInputs.signed4}}</td>
          <td>{{formInputs.sign5}}<br>{{formInputs.signed5}}</td>
        </tr>
      </table>
      <table class="mainTable">
        <tr><td class="itemTd">Title</td><td class="contentsTd">{{formInputs.title}}</td></tr>        
        <tr><td class="itemTd">Contents</td><td class="contentsTd" v-html="convertNewLines(formInputs.contents)"></td></tr>
        <tr><td class="itemTd">Remark</td><td class="contentsTd">{{formInputs.remark}}</td></tr>
      </table>
      <table class="imageTable">
        <tr>
          <th>Image 1</th><th>Image 2</th>
        </tr>
        <tr>
          <td>
            <img v-if="formInputs.imageonepath && formInputs.imageonepath !== '0'" :src="getImageUrl(formInputs.imageonepath)" alt="Image" style="width: 500px; height: auto;" @click="openImageInPopup(getImageUrl(formInputs.imageonepath))">
            <template v-else></template><br>
          </td>
          <td>
            <img v-if="formInputs.imagetwopath && formInputs.imagetwopath !== '0'" :src="getImageUrl(formInputs.imagetwopath)" alt="Image" style="width: 500px; height: auto;" @click="openImageInPopup(getImageUrl(formInputs.imagetwopath))">
            <template v-else></template>      
          </td>
        </tr>
      </table>
      <div> 
        <button class="printButton" @click="printDocument">Print</button>
      </div>
    </div>        
  </div>      
  </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch,  } from 'vue';  
  import axios from 'axios';     
   
  export default {   
    methods: {
      //getImageUrl(filepath) {return `http://localhost:3000/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `https://lsevpweb.ngrok.app/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `http://pl.lsevpweb.com/uploads/${filepath}`;},
      getImageUrl(filepath) { return filepath;},
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); },      
      printDocument() {
        let printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');       
        document.querySelectorAll('link[rel="stylesheet"]').forEach((link) => {
          printWindow.document.head.appendChild(link.cloneNode(true));
        });    
        document.querySelectorAll('style').forEach((style) => {
          printWindow.document.head.appendChild(style.cloneNode(true));
        });      
        const printContent = document.querySelector('.modal-container').innerHTML;
        printWindow.document.body.innerHTML = printContent;
        printWindow.document.close(); 
        printWindow.focus();
        setTimeout(() => { printWindow.print(); printWindow.close(); }, 1000);
      },
      convertNewLines(text) { return text.replace(/\n/g, '<br>'); }
    },         
    setup(props, { emit }) {   
        const dbTableName = "documentreport";  
        const formInputs = reactive({
          id: "", year:"", month: "", day: "", 
          team: "",  type: "normal", writer: "", title: "", contents: "", 
          purchaseitem: "", purchasecost: "", purchasequantity: "", purchaseunit: "",
          sign1: "", sign2: "", sign3: "", sign4: "", sign5: "",
          signed1: "", signed2: "", signed3: "", signed4: "", signed5: "",
          remark: "", file: "", filepath: "", imageone: "", imageonepath: "", imagetwo: "", imagetwopath: "",
        });      

        const managerBackCodes = {
          '34': "Production 1st Manager",
          '35': "Production 2nd Manager", 
          '36': "Quality Manager",
          '37': "Sales Manager",
          '38': "Purchase Manager",
          '39': "Planning Manager",
          '40': "Logistic Manager",
          '41': "HR Manager",
          '42': "Factory Manager",
          '43': "CFO",
          '44': "CEO"
        };     
        
        const signedZero = { '0': "", 'Signed': "Signed"};   
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id;
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,
              formInputs.team = rowData.team,
              formInputs.type = rowData.type,
              formInputs.writer = rowData.writer,
              formInputs.title = rowData.title,
              formInputs.contents = rowData.contents,   
              formInputs.purchaseitem = rowData.purchaseitem,
              formInputs.purchasecost = rowData.purchasecost,
              formInputs.purchasequantity = rowData.purchasequantity,
              formInputs.purchaseunit = rowData.purchaseunit,
              formInputs.deliveryyear = rowData.deliveryyear,
              formInputs.deliverymonth = rowData.deliverymonth,
              formInputs.deliveryday = rowData.deliveryday,
              formInputs.fromcountry = rowData.fromcountry,
              formInputs.incotems = rowData.incotems,
              formInputs.deliverycost = rowData.deliverycost,
              formInputs.deliveryunit = rowData.deliveryunit,
              formInputs.payyear = rowData.payyear,
              formInputs.paymonth = rowData.paymonth,
              formInputs.payday = rowData.payday,
              formInputs. paymentcondition = rowData.paymentcondition,   
              formInputs.sign1 = managerBackCodes[rowData.sign1] || '',
              formInputs.sign2 = managerBackCodes[rowData.sign2] || '',
              formInputs.sign3 = managerBackCodes[rowData.sign3] || '',
              formInputs.sign4 = managerBackCodes[rowData.sign4] || '',
              formInputs.sign5 = managerBackCodes[rowData.sign5] || '',       
              formInputs.signed1 = signedZero[rowData.signed1] || '',
              formInputs.signed2 = signedZero[rowData.signed2] || '',
              formInputs.signed3 = signedZero[rowData.signed3] || '',
              formInputs.signed4 = signedZero[rowData.signed4] || '',
              formInputs.signed5 = signedZero[rowData.signed5] || '',
              formInputs.remark = rowData.remark,
              formInputs.file = rowData.file,
              formInputs.filepath = rowData.filepath,
              formInputs.imageone = rowData.imageone,
              formInputs.imageonepath = rowData.imageonepath,
              formInputs.imagetwo = rowData.imagetwo,
              formInputs.imagetwopath = rowData.imagetwopath     

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } ); 

      const closeModal = () => { emit("onClose"); }; 

      return { dbTableName, formInputs,  closeModal, };
    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   
.modal-wrapper { width: 100%;  max-width: 1060px; }
.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}
.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
.modal-header h3 { font-size: 1.2rem; }
.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.signTable th {background-color: #d2d2d2; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.signTable td {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.imageTable th {background-color: #d2d2d2; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.imageTable td {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}

.mainTable {margin-top: 20px; margin-bottom: 20px;}
.itemTd {background-color: #d2d2d2; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.contentsTd {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px;
            border: 1px solid #7d7d7d; text-align: left; width: 950px;
}
.printButton {
  background-color: #347003;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-size: 15px; font-weight: bold;
  padding: 10px 10px; transition: background-color 0.3s; margin-top : 10px; 
}

</style>