import { ref, watch } from 'vue';  
import axios from 'axios';   
import { getModelsForLine, getColumnMapping, makeFormData, useFormInputs, fetchData} from '../../utils.js'
import { lineData } from "../lineName_check.js";
  
export function useForm(props, emit) {        
    const dbTableName = "checksheettarget"   
    const uniqueLines = ref([]);           
    
    const { formInputs, dataForm } = useFormInputs();     

    const models = ref([]);  

    const updateModels = () => {
      if (formInputs.line === "") { models.value = []; return;}
      models.value = getModelsForLine(formInputs.line, lineData);        
    };

    watch(() => formInputs.line, updateModels);
    
    const submitForm = async () => {
      if (!formInputs.line || !formInputs.model ) {
        alert("Please fill in all the fields: line, and model.");
        return;
      }

      const columnMapping = getColumnMapping(undefined, formInputs);  
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
      
      await axios.post('/api/post', formData);
      
      closeModal();
    };

    const closeModal = () => { emit("onClose"); alert("You have to register changes to the check sheet history.");};

    const loadLines = async () => {
      const lines = await fetchData('machinedata', 'line');
      const excludedLines = ["EV2020 60FR", "EV2020 60RR", "EV2020 4060FR", "EV2020 4060RR",
        "Common", "Leader", "Maintenance", "Packing", "Production Logistic", "Technician"      
      ];
      uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
      uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines(); 

  return { dbTableName, formInputs, submitForm, closeModal, updateModels, models, dataForm, uniqueLines};
}
