<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Revision History ({{ line }})</h3>    
          <button class="close-button" @click="closeModal">X</button>      
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <th>Revision</th><th>Revision Date</th><th>Contents</th>
              </tr>              
              <tr v-for="row in item" :key="row.id">
                <td class="revisionTd">{{row.revision}}</td>
                <td class="revisionDateTd">{{row.revision_date}}</td>
                <td class="historyTd">{{row.history}}</td>
              </tr>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { reactive, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
  props: { line: { type: String, required: true } },
  setup(props, { emit }) {
    const dbTableName = "flowchart_label";    
    const historyData = reactive({ data: [], })       

    const sendDate = async () => { const sendDateData = [props.line]; return { sendDateData }; };

    const searchData = async () => {    

      if (!props.line){ return; }
      
      const sendDateData = await sendDate();   
      
      const formData = {        
            tableName: dbTableName,                
            line: sendDateData.sendDateData[0],                     
      };  

      axios.get('/api/targetList', { params: formData, })                      
            .then((res) => { historyData.data = res.data});  
    };    

    const item = computed(() => { return historyData.data.map(d => { return { ...d, }; }); });

    onMounted(() => { searchData(); });

    const closeModal = () => { emit("onClose"); };

    return { dbTableName, closeModal, item };
  },
};
</script>
      
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }     
    .modal-wrapper { width: 100%;  max-width: 1000px; }  
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }  
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
    .modal-header h3 { font-size: 1.2rem; }
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
        
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }  

    tr { text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
    th { text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    td { font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px;}
    .revisionTd { text-align: center; width: 20px; }
    .historyTd { text-align: left; white-space: pre-wrap; }    
    .revisionDateTd { text-align: center; width: 100px; }
</style>