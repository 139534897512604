<template>
  <div class="container">        
    <div class="RequestLog">
      <h class="pagetitle">Stock List</h>        
      <!--<form @submit.prevent="sendDate()" class="searchBar">-->
      <form @submit.prevent="callSearchData" class="searchBar">
        <div class="searchDiv">          
          <input type="text" id="startYear" v-model="year" placeholder="Year" />
          <input type="text" id="startMonth" v-model="month" placeholder="Month" />
          <input type="text" id="startDay" v-model="day" placeholder="Day" />          
          <button class="searchButton" type="submit" @click="callSearchData">Search</button>                                  
          <button class="excelDownload" @click="downExcel">Excel</button>             
        </div>   
      </form>
    </div>            
    <div class="tables">
      <b>Factory Hall</b>
      <table>
        <tr>          
          <th>Material Code</th><th>Material Name</th><th>Before Qty</th><th>In Qty</th><th>Before + In</th>
          <th>Out Qty</th><th>Before + In - Out</th>
          <th>Daily Qty</th><th>Difference</th><th>Unit</th>
        </tr>          
        <tr v-for="row in factoryItems" :key="row.id">          
          <td class="list">{{ row.materialcode }}</td>
          <td class="list">{{ row.materialname }}</td>          
          <td class="list">{{ row.beforeDailytotalQuantity }}</td>
          <td class="list">{{ row.tototalQuantity }}</td>
          <td class="list">{{ row.beforePlusIn }}</td>          
          <td class="list">{{ row.fromtotalQuantity }}</td>
          <td class="list">{{ row.inMinusOut }}</td>
          <td class="list">{{ row.dailytotalQuantity }}</td>
          <td class="list">{{ row.different }}</td>
          <td class="list">{{ row.unit }}</td>            
        </tr>
      </table><br>
      <b>Production Logistic</b>
      <table>
        <tr>          
          <th>Material Code</th><th>Material Name</th><th>Before Qty</th><th>In Qty</th><th>Before + In</th>
          <th>Out Qty</th><th>Before + In - Out</th>
          <th>Daily Qty</th><th>Difference</th><th>Unit</th>
        </tr>          
        <tr v-for="row in warehouseItems" :key="row.materialcode">          
          <td class="list">{{ row.materialcode }}</td>
          <td class="list">{{ row.materialname }}</td>          
          <td class="list">{{ row.beforeDailytotalQuantity }}</td>
          <td class="list">{{ row.tototalQuantity }}</td>
          <td class="list">{{ row.beforePlusIn }}</td>          
          <td class="list">{{ row.fromtotalQuantity }}</td>
          <td class="list">{{ row.inMinusOut }}</td>
          <td class="list">{{ row.dailytotalQuantity }}</td>
          <td class="list">{{ row.different }}</td>
          <td class="list">{{ row.unit }}</td>            
        </tr>
      </table>
    </div>    
  </div>
</template>

<script>
import { ref, computed} from 'vue';
import { downloadExcel} from "../../utils.js";
import { setupDataSearch } from './stockUtils.js';

export default { 
  methods: {       
    downExcel () { downloadExcel([this.factoryItems, this.warehouseItems], "Stock List.xlsx", [], ["Factory Hall", "Warehouse"]);}    
  },

  setup(){
    const year = ref(); 
    const month = ref(); 
    const day = ref();
    const sendDate = async () => {
      const sendDateData = [year.value, month.value, day.value]; 
      return {sendDateData};
    };     
    // Factory Hall 설정
    const factorySetup = setupDataSearch(sendDate, "2", "2", "Production Hall", "1");
    const { fromSearchData: factoryFromSearchData, toSearchData: factoryToSearchData, dailySearchData: factoryDailySearchData, beforeDailySearchData: factoryBeforeDailySearchData, mergeData: mergeFactoryData } = factorySetup;

    // Warehouse 설정
    const warehouseSetup = setupDataSearch(sendDate, "1", "1", "L_Production Logistic", "1");
    const { fromSearchData: warehouseFromSearchData, toSearchData: warehouseToSearchData, dailySearchData: warehouseDailySearchData, beforeDailySearchData: warehouseBeforeDailySearchData, mergeData: mergeWarehouseData } = warehouseSetup;

    const callSearchData = async () => {    
      await factoryFromSearchData('stockmovement');   
      await factoryToSearchData('stockmovement');     
      await factoryDailySearchData('dailyinventory');     
      await factoryBeforeDailySearchData('dailyinventory');    
      await warehouseFromSearchData('stockmovement');     
      await warehouseToSearchData('stockmovement');      
      await warehouseDailySearchData('dailyinventory');      
      await warehouseBeforeDailySearchData('dailyinventory');      
    };

    const factoryItems = computed(() => mergeFactoryData());
    const warehouseItems = computed(() => mergeWarehouseData());

    return {
      year, month, day, callSearchData, factoryItems, warehouseItems
    };
  }
};
</script>
  
<style scoped>
   * { box-sizing: border-box; }
  .container { margin-top: 0px; padding-top: 10px; max-width: 98%; margin-left: auto; margin-right: auto;}  
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold;}
  .searchDiv { margin-top: 10px; margin-bottom: 10px;}  
  .label { font-size: 14px; font-weight: 600; margin-right: 8px; }
  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px; border: 1px solid #ccc;
    border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; margin-right: 10px;
  }
  .searchButton {
    padding: 5px 5px; background-color: #3498db; color: #fff;
    border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer; transition: background-color 0.3s;
  }
 .searchButton:hover { background-color: #2980b9; }
 .excelDownload {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
    padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
  }
  .excelDownload :hover { background-color: #009625; }  
  .tables { display: flex; flex-direction: column; align-items: stretch; }
  table { width: 80%; }
  td { border : 1px solid black; text-align : center; padding: 5px 5px; font-size : 12px; }
  th { background-color: rgb(230, 230, 230); border : 1px solid black; text-align : center; padding: 10px 10px; font-size : 12px;} 
</style>