<template>
    <div class="stocklist">            
      <div class="act">               
         <h class="pagetitle">Not Normal Stock List</h>
      </div>
      <table class="searchBar">
        <tr>
          <td class="searchBarTd">
          <form @submit.prevent="sendDate()">
            <label>Search : </label>
            <select id="team" v-model="searchDate.team" placeholder="team">
              <option>1st_Production</option><option>2nd_Production</option><option>QA</option><option>ALL</option>
            </select>
            <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
            <button class="excelButton" @click="downloadExcel">Excel</button>
          </form>
          </td>
          </tr>
        <tr>        
          <td class="searchBarTd"><button class="modalButton" @click="showModal = true">+ Add</button></td>
        </tr>
      </table>                  
      <P></P>     
      <div class="tables">
      <table id="stockTable" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">
                <td v-if="header === 'Team'" :key="index" @click="editCellValue(stockTableName, row.id, 'team', $event)">{{ row.team }}</td>
                <td v-if="header === 'Location'" :key="index" @click="editCellValue(stockTableName, row.id, 'location', $event)" >{{ row.location }}</td>
                <td v-if="header === 'Material Code'" :key="index" @click="editCellValue(stockTableName, row.id, 'materialcode', $event)" >{{ row.materialcode }}</td>
                <td v-if="header === 'Material Name'" :key="index" @click="editCellValue(stockTableName, row.id, 'materialname', $event)" >{{ row.materialname }}</td>
                <td v-if="header === 'Unit'" :key="index" @click="editCellValue(stockTableName, row.id, 'unit', $event)" >{{ row.unit }}</td>
                <td v-if="header === 'Quantity'" :key="index" @click="editCellValue(stockTableName, row.id, 'quantity', $event)" >{{ row.quantity }}</td>
                <td v-if="header === 'Contents'" :key="index" @click="editCellValue(stockTableName, row.id, 'contents', $event)" >{{ row.contents }}</td>
                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(stockTableName, row.id, 'remark', $event)" >{{ row.remark }}</td>
                <td v-if="header === 'Comming Date (Day/Month/Year)'" :key="index" >{{ row.commingdate }}</td>                
                <td v-if="header === 'DEL'" :key="index" class="del"><button :class="{ disabledButton: !canClick() }" :disabled="!canClick(row)" type="submit" @click="deleteRow(stockTableName, row.id)">DEL</button></td>
                
            </template>
          </tr>
        </table>                     
       </div>                  
      <!--<Modal v-if="showModal" @onClose="showModal = false"/>-->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>
  </div>  
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./stockAddnotnormal.vue";

export default {      
    components: { Modal, },            
    methods: {
      canClick() {
        const currentUser = this.$route.meta.currentUser;
        if (currentUser) {
            return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 17 || currentUser.id === 18;
          } else { return false;
        }
      },
    },

    setup() {              
        const showModal = ref(false);         
        const headers = ['Team', 'Location', 'Material Code', 'Material Name', 'Unit', 'Quantity', 'Contents', 'Remark', 'Comming Date (Day/Month/Year)', 'DEL', ];         
        const searchDate = ref({ team : ''});
        const sendDate = async () => { const sendDateData = [searchDate.value.team,]; return {sendDateData};};             

        const stockTableName = "stocknotnormal"; const stockTableData = reactive({ data: [], });    
        
        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
          const formData = {
            tableName: tableName,
            values: { team: sendDateData.sendDateData[0], }
          };
          console.log('Requesting data with:', formData);

          axios.get('/api/stocknotnormal', { params: formData })
            .then((res) => {                         
                switch (tableName) {  
                  case 'stocknotnormal': stockTableData.data = res.data;  break;                      
                }
            });
        };             

        const callSearchData = () => { searchData(stockTableName); settingCall(); };                
      
        const item = computed(() => {
            return stockTableData.data.map(d => {
                const commingdate = `${d.day}.${d.month}.${d.year}`;
                return { ...d, commingdate: commingdate };            
            });
        });

      const editCellValue = async (tableName, id, header, event) => {
          const currentValue = event.target.innerText;
          const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

          if (newValue !== null && newValue !== currentValue) {
            // Update the value in the database and update the tableData
            try {
              // Replace input value with the desired value
              await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

              // Refresh the data by calling searchData()
              searchData(tableName);
            } catch (error) {
              console.error("Error updating cell value:", error);
            }
          }
      };  

      const deleteRow = async (tableName, rowId) => {
        // Show a confirmation window and store the user's choice
        const userConfirmed = confirm("Are you sure you want to delete this row?");

        // If the user clicks "OK", proceed with the deletion
        if (userConfirmed) {
          try {
            await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });
            callSearchData();
          } catch (error) {
            console.error("Error deleting row:", error);
          }
        }
      };

      const settingData = reactive({ data: [], });        

      const settingCall = async () => {         
          const tableName ='setting';
          const formData = { tableName: tableName };
          axios.get('/api/targetList', { params: formData })
          .then((res) => { settingData.data = res.data; })
          .catch((error) => { console.error("Error download excel data:", error.message); });
      };

      const downloadExcel = () => {
        const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
        const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
        if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
        const wb = XLSX.utils.book_new();

        // Create worksheets for each table
        const wsPr = XLSX.utils.json_to_sheet(item.value, { header: headers.value });          

        // Append worksheets to the workbook with custom sheet names
        XLSX.utils.book_append_sheet(wb, wsPr, "Not Normal Stock");          

        // Write the workbook and create a Blob
        const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
        const blob = new Blob([wbout], { type: "application/octet-stream" });

        // Save the file
        saveAs(blob, "Not Normal Stock.xlsx");
      };

      return { headers, showModal, callSearchData, downloadExcel, sendDate, editCellValue, 
               searchData, searchDate, item, stockTableName, deleteRow
             };        
    },
}
</script>

<style scoped>
  .act { display: flex; padding: 10px 10px 5px 5px; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; } 

  .button {
    padding: 5px 5px;  background-color: #3498db;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-right: 3px;
    cursor: pointer; transition: background-color 0.3s;
  }

  .searchButton {
    padding: 5px 5px;  background-color: #94a5b1;
    color: #000000; border: none;
    border-radius: 4px; font-size: 14px; font-weight: 600;
    margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton {
    padding: 5px 5px; background-color: #10a802;
    color: #fff; border: none;
    border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer;
    transition: background-color 0.3s;
  }

  .modalButton {
    padding: 5px 5px;  background-color: #f3ac27;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 50px; margin-right: 10px;
    cursor: pointer; transition: background-color 0.3s;
  }

  .button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

  .excelButton:hover, .searchButton:hover, .qwallButton:hover, 
  .button:hover { background-color: #2980b9; }
    
  .searchBar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .searchBar > *:not(:last-child) { margin-right: 5px; }

  .searchBar select, 
  .searchBar input
    { width : 200px;  margin-right: 5px; padding : 5px; }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  .searchBarTd { border : 0px; }

  td {
    border : 1px solid black;
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px;     
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }

  .tables {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left : 20px;
    padding-right : 20px;
    height: 400px; /* Adjust this value according to your needs */
    overflow: auto;
  } 

  .custom-table {
    font-size: 10px;
    width: 80%;
    overflow-x: auto;
    white-space: nowrap;
  } 
  .custom-file-upload {
    display: inline-block;  padding: 6px 12px;
    cursor: pointer; background-color: #347003;
    color: #ffffff; border-radius: 4px;
    font-size: 14px;  transition: background-color 0.3s;
    margin-left: 20px;
  }
  .custom-file-upload:hover {  background-color: #009625; }

</style>