import { reactive } from 'vue';
import axios from 'axios';

const exchangeRate = reactive({ data: {} });

export const exchangeRateSearch = async (dateRange) => {
  if (!dateRange || !dateRange.year || !dateRange.month) {
    throw new Error('Invalid date range provided');
  }

  const formData = {
    tableName: "exchangerate",
    year: dateRange.year,
    month: dateRange.month,
  };

  try {
    const res = await axios.get('/api/yearMonth', { params: formData });
    if (res.data && Array.isArray(res.data.data)) {
      const uniqueData = {};

      res.data.data.forEach(item => {
        const key = `${item.year}-${item.month}`;

        if (!uniqueData[key]) {
          uniqueData[key] = { ...item };
        } else {
          // Update each field only if it exists in the current item
          uniqueData[key].plntousd = item.plntousd || uniqueData[key].plntousd;
          uniqueData[key].eurtousd = item.eurtousd || uniqueData[key].eurtousd;
          uniqueData[key].plntokrw = item.plntokrw || uniqueData[key].plntokrw;
          uniqueData[key].eurtokrw = item.eurtokrw || uniqueData[key].eurtokrw;
        }
      });

      exchangeRate.data = Object.values(uniqueData)[0] || {};
    } else {
      exchangeRate.data = {};
      console.error("Unexpected response format:", res.data);
    }
  } catch (error) {
    exchangeRate.data = {};
    console.error("Error fetching exchange rate data:", error.message);
  }
};

export default exchangeRate;
