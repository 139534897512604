<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Annual Target Date Calculation</h>        
      <form @submit.prevent="callSearchData()">
        <div class="horizontal-form">        
          <div>
            <select class="searchTda" id="line" v-model="line">
              <option v-for="line in uniqueLines" :key="line">{{ line }}</option>       
            </select>   
          </div>                  
          <div class="start">
              <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
              <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
              <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
          </div>
          <div class="separator">~</div>
          <div class="end">
              <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
              <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
              <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
          </div>
          <div class="searchTd">
              <button type="submit">Search</button>                        
          </div>      
          <div class="searchTd">
              <button class="custom-file-upload" @click.prevent="downExcel">Download</button>     
          </div>                          
        </div>      
      </form>  
    </div>      
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th>Station</th><th>Part</th><th>Item</th><th>Cycle</th><th>Cycle Date</th>
        </tr>      
        <tr v-for="row in Item" :key="row.id" >                         
          <td>{{ row.station }}</td>
          <td>{{ row.part }}</td>
          <td>{{ row.item }}</td>
          <td>{{ row.cycle }}</td>
          <td>{{ row.cycleDates }}</td>
      </tr>
    </table>
    </div>     
    </div>  
</template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from "axios";
import { downloadExcel } from "../../utils.js"; 

export default {   
    methods: {
      downExcel() { downloadExcel(this.Item, "AnnualCalculation.xlsx"); },
    },    

    setup() {   
        const dbTableName = 'maintenancechecktarget';   
        const startYear = ref(); const startMonth = ref(); const startDay = ref();
        const endYear = ref(); const endMonth = ref(); const endDay = ref();
        const line = ref('');
        const calculationData = reactive({ data: [] });        
        const uniqueLines = ref([]);          
        const weekDays = { "sun": 0, "mon": 1, "tue": 2, "wed": 3, "thu": 4, "fri": 5, "sat": 6 };

        const searchData = async () => {
          const formData = {
            tableName: dbTableName,
            line: line.value,
            cycle: "1 day"
          };
          try {
            const response = await axios.get('/api/annualcalculation', { params: formData });
            calculationData.data = response.data.sort((a, b) => a.row_no - b.row_no);           
          } catch (error) {
            console.error("Error fetching target data:", error.message);
          }
        };        

        const Item = computed(() => {
          return calculationData.data.map(item => {            
            const cycleDates = calculateCycleDates(item, startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, weekDays);
            return { ...item, cycleDates };
          });
        });

        const calculateCycleDates = (item, startYear, startMonth, startDay, endYear, endMonth, endDay, weekDays) => {
            const startDate = new Date(Date.UTC(startYear, startMonth - 1, startDay));
            const endDate = new Date(Date.UTC(endYear, endMonth - 1, endDay));            
            let initialCycleDate = new Date(Date.UTC(item.cycleyear, item.cyclemonth - 1, item.cycleday));           
            let cycleDate = adjustCycleDateToStart(initialCycleDate, item, weekDays, startDate, endDate);

            const dates = [];         
            while (cycleDate && cycleDate <= endDate) {
                if (cycleDate >= startDate) {
                    dates.push(cycleDate.toISOString().split('T')[0]);
                }
                cycleDate = getNextCycleDate(cycleDate, item, weekDays, endDate);
            }
            return dates.join('; ');
        };

        function getNextCycleDate(cycleDate, item, weekDays, endDate) {
          let newDate = new Date(cycleDate.getTime());          
          const endDateObj = new Date(endDate);

          if (item.cycle === '12 month') { item.cycle = '1 year'; }

          switch (item.cycle) {
            case '1 day':
              newDate.setUTCDate(newDate.getUTCDate() + 1);
              break;
            case '1 week':
            case '2 week':
            case '3 week':
              {
                const weekIncrement = item.cycle === '2 week' ? 14 : item.cycle === '3 week' ? 21 : 7;
                newDate.setUTCDate(newDate.getUTCDate() + weekIncrement);
                adjustDateForWeekDay(newDate, weekDays[item.week]);
              }
              break;
            case '1 month':
            case '2 month':
            case '3 month':
            case '6 month':
              {
                const monthIncrement = item.cycle === '2 month' ? 2 : item.cycle === '3 month' ? 3 : item.cycle === '6 month' ? 6 : 1;
                newDate.setUTCMonth(newDate.getUTCMonth() + monthIncrement);
                adjustDateForMonthCycle(newDate, weekDays[item.week], item.cycleday);
              }
              break;   
            case '1 year':
              {
                newDate.setUTCFullYear(newDate.getUTCFullYear() + 1);
                adjustDateForYearCycle(newDate, weekDays[item.week], item.cycleday);
              }
              break;
          }
       
          if (newDate > endDateObj) {            
            return null;
          }

          return newDate;
        }
      
        function adjustCycleDateToStart(cycleDate, item, weekDays, startDate, endDate) {
            let newDate = new Date(cycleDate);      
            while (newDate < startDate) {             
                let nextCycleDate = getNextCycleDate(newDate, item, weekDays, endDate);
                if (!nextCycleDate) {                  
                    return null; 
                }
                newDate = nextCycleDate;
            }           
            return newDate;
        }
      
        function adjustDateForWeekDay(date, weekDay) {        
          while (date.getUTCDay() !== weekDay) { date.setUTCDate(date.getUTCDate() + 1); }
        }
    
        function adjustDateForMonthCycle(date, weekDay, cycleDay) {         
          date.setUTCDate(1);         
          adjustDateForWeekDay(date, weekDay);        
          while (date.getUTCDate() < cycleDay) { date.setUTCDate(date.getUTCDate() + 7 ); }      
        } 

        function adjustDateForYearCycle(date, weekDay, cycleDay ) {      
          date.setUTCDate(1);  
          adjustDateForWeekDay(date, weekDay);   
          while (date.getUTCDate() < cycleDay) { date.setUTCDate(date.getUTCDate() + 7); }   
          if (date.getUTCDate() > cycleDay) { date.setUTCDate(date.getUTCDate() - 7); }
        }        
   
        const callSearchData = () => { searchData(); };

        const fetchLine = () => {
          const tableName = 'maintenancechecktarget';                   
          axios.get('/api/where2', { params: { table_name: tableName } })
            .then((res) => {
              if (res.data && res.data.length > 0) {
                const lines = res.data.map(item => item.line);
                uniqueLines.value = [...new Set(lines)].sort((a, b) => a.localeCompare(b));                
              } 
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };  
        
        fetchLine(); 

        return { Item, startYear, startMonth, startDay, endYear, endMonth, endDay, line, uniqueLines, callSearchData, };        
    }
};
</script>


<style scoped> 
  .table-container { margin-left : 10px; margin-right: 10px;}
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .disabledButton { background-color: gray; cursor: not-allowed; }
  label { font-size: 14px; font-weight: 600; margin-right: 8px; }
  .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
  .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
  th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
      text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
  }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                      border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
  }
  .custom-file-upload:hover { background-color: #009625; }

  button { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .del { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 11px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
  .start,.end { display: flex; flex-direction: row; border: 0px; }
  .start input,.end input { margin-right: 5px;}    
  .separator { font-size: 24px; margin: 0 10px; border: 0px; }z
  .searchTd { border: 0px !important; }
  .searchTda { width: 350px; }
  input::placeholder { color: gray; }
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }
  input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }    
  .horizontal-form { margin-top: 10px; margin-bottom: 10px; margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>