<template>
    <div class="checksheet">            
      <div class="act">               
         <h class="pagetitle">{{ lineName }} Maintenance Check Sheet</h>                  
      </div>
      <div class="horizontal-form">
        <form @submit.prevent="sendDate()" class="form-container">
          <div class="start">
            <input type="number" id="startYear" v-model="searchDate.startYear" placeholder="Year" />
            <input type="number" id="startMonth" v-model="searchDate.startMonth" placeholder="Month" />
            <input type="number" id="startDay" v-model="searchDate.startDay" placeholder="Day" />
          </div>
          <div class="separator">~</div>
          <div class="end">
            <input type="number" id="endYear" v-model="searchDate.endYear" placeholder="Year" />
            <input type="number" id="endMonth" v-model="searchDate.endMonth" placeholder="Month" />     
            <input type="number" id="endDay" v-model="searchDate.endDay" placeholder="Day" />
          </div>   
          <div class="end">
            <select v-model="filters.cycle">
              <option>All</option>
              <option>1 day</option><option>1 week</option><option>2 week</option>
              <option>1 month</option><option>3 month</option><option>6 month</option>
              <option>1 year</option>
            </select>
          </div>
          <div class="end">
            <select v-model="filters.result">
              <option>All</option>
              <option>ok</option><option>Pass</option><option>need check</option>
              <option>Except</option>                     
            </select>
          </div>
          <button class="searchButton" type="submit"  @click="callSearchData">Search</button>         
          <div class="modal-buttons">       
          <button v-for="(slice, index) in filteredModalSlices" :key="index" class="modalButton" @click="handleModalButtonClick(index)">
              Check Sheet {{ index + 1 }}
          </button>
          </div>
        </form>     
      </div>

      <div class="table-container">
      <table class="tables">
        <tr>
          <th v-for="(headers, index) in headers" :key="index" class="sticky-header">{{ headers }}</th>             
        </tr>
        <tr v-for="item in filteredCheckSheetData" :key="item.id">
          <td>{{ item.day }}.{{ item.month }}.{{ item.year }}</td>
          <td>{{ item.station }}</td>
          <td>{{ item.technician }}</td>
          <td>{{ item.time }}</td>
          <td>{{ item.part }}</td>
          <td>{{ item.itemName }}</td>
          <td>{{ item.standard }}</td>
          <td>{{ item.value }}</td>
          <td>{{ item.unit }}</td>
          <td :class="{ 'ok': item.result === 'ok', 'ng': item.result === 'ng', 'pass': item.result === 'pass', 'need-check': item.result === 'need check' , 'except': item.result === 'Except' }">{{ item.result }}</td>
          <td>{{ item.cycle }}</td>
          <td>{{ item.cycledate }}</td>
          <td>{{ item.remark }}</td>
        </tr>
      </table>  
      </div>

      <component v-for="(slice, index) in filteredModalSlices"
       :is="getModalComponent(index)" 
        v-show="showModals[index]"
       :key="'modal' + (index + 1)" @onClose="closeModal(index)"
       :sliceNumbers="slice" :lineName="lineName" :index="index"
       :start="startValues[index]"
       />
  </div>    
</template>

<script>
import { useForm } from '../0_formStyle/form_checkMain';

export default {
  props: { lineName: { type: String, required: true }, },   
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>

<style scoped src="../0_formStyle/style_checkMain.css"></style>