<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Attendance</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <td>
                  <div class="form-group date-time-group">
                    <label>Year/Month/Day:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="searchDate.year"  />
                      <input type="text" id="month" v-model="searchDate.month" />
                      <input type="text" id="day" v-model="searchDate.day" />                  
                    </div> 
                  </div>      
                  <div class="form-group">
                    <label for="shift">Shift:</label>
                    <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
                  </div>
                  <div class="form-group">
                    <label for="start_hour">Start time</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="start_hour" v-model="startHour" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="start_minute" v-model="startMinute" min="0" max="59" />
                    </div>
                  </div>            
                  <div class="form-group">
                    <label for="end_hour">End time</label>
                    <div class="time-inputs">
                        <label for="end_hour">H:</label>
                        <input type="number" id="end_hour" v-model="endHour" min="0" max="23" />
                        <label for="end_minute">M:</label>
                        <input type="number" id="end_minute" v-model="endMinute" min="0" max="59" />
                    </div>
                  </div>  
                  <div class="time">
                    <label for="time">Time:</label>
                    <input type="number" id="time" v-model="formInputs.worktime" readonly class="readWindow" />              
                  </div>
                  </td>
                  <td>
                    <div class="form-group">
                    <label for="employeenumber">Employee Number:</label>
                    <input id="employeenumber" v-model="formInputs.employeenumber" ref="employeeNumberInput" />                    
                  </div>            
                  <div class="form-group">
                    <label class="modalLabel" for="oscompany">OS Company:</label>
                    <select class="modalSelect" id="oscompany" v-model="formInputs.oscompany">
                      <option v-for="oscompany in uniqueCompany" :key="oscompany">{{ oscompany }}</option><option></option>   
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" id="name" v-model="formInputs.name" />
                  </div>
                  <div class="form-group">
                    <label for="surname">Surname:</label>
                    <input type="text" id="surname" v-model="formInputs.surname" />
                  </div>
                </td>                               
                <td>
                  <div class="form-group">
                    <label for="factory">Factory:</label>
                    <select id="factory" v-model="formInputs.factory" @change="updateFactoryOptions()">
                      <option>1</option><option>2</option>
                    </select>
                  </div>   
                  <div class="form-group">
                    <label for="team">Team:</label>
                    <select id="team" v-model="formInputs.team">    
                      <option>Production 1st</option><option>Production 2nd</option><option>QA</option><option>Warehouse</option><option>Inkatom</option>
                    </Select>
                  </div>   
                  <div class="form-group">
                    <label for="line">Line:</label>
                    <select class="modalSelect" id="line" v-model="formInputs.line">
                      <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
                    </select>                   
                  </div>
                  <div class="form-group">
                    <label for="process">Process:</label>
                    <select class="modalSelect" id="process" v-model="formInputs.process">
                      <option v-for="process in uniqueProcess" :key="process">{{ process }}</option><option></option>        
                    </select>  
                  </div>  
                  <div class="form-group">
                    <label for="position">Position:</label>
                    <select class="modalSelect" id="position" v-model="formInputs.position">
                      <option v-for="position in uniquePosition" :key="position">{{ position }}</option><option></option> 
                    </select>
                  </div> 
                  <div class="form-group">
                    <label for="remark">Remark:</label>
                    <input type="text" id="remark" v-model="formInputs.remark" />
                  </div> 
                </td>
              </tr>
            </table>                     
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}          
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { onMounted, reactive, ref, computed, watch } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, fetchLine, fetchProcess, fetchPosition, fetchCompany} from "../../utils.js"; 
   
  export default {                
    setup(props, { emit }) {   
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const employeeNumberInput = ref(null);
        const dbTableName = "attendanceweekend"   
        const uniqueLines = ref([]);     
        const uniqueProcess = ref([]);   
        const uniquePosition = ref([]);
        const uniqueCompany = ref([]);                      
        const formInputs = reactive({    
          factory: "",
          shift: "",
          oscompany: "",
          employeenumber: "",
          name : "",
          surname : "",
          team : "",
          line : "",
          process : "",
          position : "",
          start_time : "",      
          end_time : "",
          worktime : "",
          remark : "",
          confirm: "not yet"
        });

        const calculateTimeDifference = (start_time, end_time) => {
          if (!start_time || !end_time) {
            return "";
          }
          const [startHour, startMinute] = start_time.split(":").map(Number);
          const [endHour, endMinute] = end_time.split(":").map(Number);

          const startDate = new Date();
          startDate.setHours(startHour, startMinute);

          const endDate = new Date();
          endDate.setHours(endHour, endMinute);

          let timeDifference = (endDate - startDate) / 1000 / 60;

          if (timeDifference < 0) {
            timeDifference += 24 * 60;
          }

          timeDifference = Math.floor(timeDifference);

          return timeDifference;
        };
        
        watch(
          () => [formInputs.start_time, formInputs.end_time],
          ([start_time, end_time]) => {        
            const startHour = start_time.split(":")[0];
            formInputs.shift = startHour >= 6 && startHour <= 17 ? 1 : 2;       
            formInputs.worktime = calculateTimeDifference(start_time, end_time);
          }
        );
       
        const startHour = computed({
          get: () => {
            const [hour] = formInputs.start_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.start_time.split(":");
            formInputs.start_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const startMinute = computed({
          get: () => {
            const [, minute] = formInputs.start_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.start_time.split(":");
            formInputs.start_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });

        const endHour = computed({
          get: () => {
            const [hour] = formInputs.end_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.end_time.split(":");
            formInputs.end_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const endMinute = computed({
          get: () => {
            const [, minute] = formInputs.end_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.end_time.split(":");
            formInputs.end_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        }); 

        const searchDate = reactive({ ...getCurrentDate(), });    

        const setDefaultTimes = () => {
            const currentHour = new Date().getHours(); 

            if (currentHour >= 6 && currentHour < 18) { 
                startHour.value = 6;
                startMinute.value = 0;
                endHour.value = 18;
                endMinute.value = 0;
            } else {
                startHour.value = 18;
                startMinute.value = 0;
                endHour.value = 6;
                endMinute.value = 0;
            }
        };    
        
        const loadLines = async () => {
          const lines = await fetchLine();
          const excludedLines = [ "2nd Factory Compressors", "2nd Factory Boilers", "2nd Factory Dust collectors", "2nd Factory AHU", "2nd Factory Transformer",
                                  "1st Factory Compressor 1", "1st Factory Compressor 2", "1st Factory Boiler 1", "1st Factory Boiler 2", "1st Factory Dust collector 1",
                                  "1st Factory Dust collector 2", "1st Factory Dust collector 3", "1st Factory Dust collector 4", "1st Factory NEW AHU",
                                  "1st Factory OLD AHU", "1st Factory AHU SMT", "1st Factory Transformer",
                                  "Packing", "Production Logistic", "Maintenance", "Leader", "Technician"
          ];
          uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
        }; loadLines();
        const loadProcess = async () => { uniqueProcess.value = await fetchProcess(); }; loadProcess();
        const loadPosition = async () => { uniquePosition.value = await fetchPosition(); }; loadPosition();
        const loadCompany = async () => { uniqueCompany.value = await fetchCompany(); }; loadCompany();
  
        const fetchname = () => {
            const employeeTableName = 'employeedata';
            const columnName = 'employeenumber';
            console.log("fetchmaterialname called with materialcode:", formInputs.employeenumber);
            axios
              .get('/api/where', { params: { value: formInputs.employeenumber, table_name: employeeTableName, column_name: columnName } })
              .then((res) => {
                console.log('Response from /api/where:', res.data);       

                // Set the fetched materialname to formInputs.materialname
                if (res.data && res.data.length > 0) {
                  formInputs.oscompany = res.data[0].oscompany;
                  formInputs.factory = res.data[0].factory;
                  formInputs.line = res.data[0].line;
                  formInputs.process = res.data[0].process;
                  formInputs.position = res.data[0].position;
                  formInputs.name = res.data[0].name;
                  formInputs.surname = res.data[0].surname;
                } else {
                  formInputs.name = "";
                  formInputs.surname = "";
                }
              })
              .catch((error) => {
                console.error('Error fetching target data:', error.message);
              });
          };  
          watch(() => formInputs.employeenumber, () => {
                fetchname();                
          });   

        const resetFormInputs = () => {
              Object.keys(formInputs).forEach(key => {
                  formInputs[key] = "";
              });              
              formInputs.confirm = "not yet";
        };

        const submitForm = async () => {
          if (!formInputs.employeenumber || !formInputs.oscompany ) {
            alert("Please fill in all the fields: Employee Number, OS Company.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {            
            factory: "factory",
            year: 'year',
            month: 'month',
            day: 'day',
            shift: "shift",
            oscompany: "oscompany",
            employeenumber: "employeenumber",
            name : "name",
            surname : "surname",          
            team : "team",
            line : "line",
            process : "process",
            position : "position",
            start_time : "start_time",      
            end_time : "end_time",
            worktime : "worktime",
            remark : "remark",
            confirm: "confirm"     
          }     

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {              
              factory: formInputs.factory,
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              shift: formInputs.shift,
              oscompany : formInputs.oscompany,
              employeenumber : formInputs.employeenumber,              
              name : formInputs.name,
              surname : formInputs.surname,
              team : formInputs.team,
              line : formInputs.line,
              process : formInputs.process,
              position : formInputs.position,
              start_time : formInputs.start_time,      
              end_time : formInputs.end_time,
              worktime : formInputs.worktime,
              remark : formInputs.remark,
              confirm: formInputs.confirm
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
          
          resetFormInputs();
          closeModal();
        };

        const closeModal = () => { resetFormInputs(); emit("onClose"); };     

        onMounted(() => { setDefaultTimes(); employeeNumberInput.value.focus(); });
  
      return { dbTableName, formInputs, submitForm, closeModal, searchDate,
              calculateTimeDifference, startHour, startMinute, endHour, endMinute,
              employeeNumberInput, saveStatus, isProcessing,
              uniqueLines, uniqueProcess, uniquePosition, uniqueCompany
      };

    },     

    props: { onSubmit: {type: Function, required: true, }, }, 
};
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 1000px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 100px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
  .disabled { background-color: #ccc; cursor: not-allowed; }
</style>