<template>
  <div class="calibrationtarget">      
    <hr>      
    <div class="addbutton">    
      <button class="modalbutton" @click="showModal = true">+ Add</button>  
      <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
      <input id="file-input" type="file" @change="onFileChange" accept=".xlsx, .xls" style="display: none" />
      <span class="fileName">{{ fileName }}</span>   
      <button class="excelbutton" @click="uploadExcel('calibrationdate')">Upload</button>
      <button class="excelbutton" @click="downloadExcel">Down Load</button>  
    </div>    
    <div class="table-container">
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Line'" :key="index"  @click="editCellValue(dbTableName, row.id, 'line', $event)">{{ row.line }}</td>
          <td v-if="header === 'Station'" :key="index" @click="editCellValue(dbTableName, row.id, 'station', $event)">{{ row.station }}</td>
          <td v-if="header === 'Type'" :key="index" @click="editCellValue(dbTableName, row.id, 'type', $event)">{{ row.type }}</td>
          <td v-if="header === 'Name'" :key="index" @click="editCellValue(dbTableName, row.id, 'name', $event)">{{ row.name }}</td>          
          <td v-if="header === 'Measurement Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
          <td v-if="header === 'Issued Year'" :key="index" @click="editCellValue(dbTableName, row.id, 'is_year', $event)">{{ row.is_year }}</td>
          <td v-if="header === 'Issued Month'" :key="index" @click="editCellValue(dbTableName, row.id, 'is_month', $event)">{{ row.is_month }}</td>
          <td v-if="header === 'Issued Day'" :key="index" @click="editCellValue(dbTableName, row.id, 'is_day', $event)">{{ row.is_day }}</td>
          <td :style="aS(row)" v-if="header === 'Expire Year'" :key="index" @click="editCellValue(dbTableName, row.id, 'ex_year', $event)">{{ row.ex_year }}</td>
          <td :style="aS(row)" v-if="header === 'Expire Month'" :key="index" @click="editCellValue(dbTableName, row.id, 'ex_month', $event)">{{ row.ex_month }}</td>
          <td :style="aS(row)" v-if="header === 'Expire Day'" :key="index" @click="editCellValue(dbTableName, row.id, 'ex_day', $event)">{{ row.ex_day }}</td>
          <td v-if="header === 'Registraion Number'" :key="index" @click="editCellValue(dbTableName, row.id, 'registraion', $event)">{{ row.registraion }}</td>               
          <td v-if="header === 'Manage'" :key="index" @click="editCellValue(dbTableName, row.id, 'manage', $event)">{{ row.manage }}</td>               
          <td v-if="header === 'Remark'" :key="index" @click="editCellValue(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td>
          <td v-if="header === 'Row_no'" :key="index" @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>     
        </template>   
      </tr>
    </tbody>     
    </table>
    </div> 
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
  
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import axios from "axios";
import Modal from "./calibrationModal.vue";
import readXlsxFile from 'read-excel-file';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

export default {         
    components: { Modal },  
    data() {        
        return {
        excelFile: null,
        fileName: "",
        };
    },
    methods: {
        onFileChange(event) {
        this.excelFile = event.target.files ? event.target.files[0] : null;
        this.fileName = this.excelFile ? this.excelFile.name : "";
        },
        async uploadExcel(tableName) {
        if (!this.excelFile) {
            alert('Please select an Excel file.');
            return;
        }

        try {
            const rows = await readXlsxFile(this.excelFile);
            const columns = rows[0];
            const data = rows.slice(1);

            console.log('column :', columns.join(', '));
            data.forEach((row, index) => {
            console.log(`value row${index + 1} :`, row.join(', '));
            });

            console.log('Sending data to API:', { data: rows, tableName: tableName });
            const response = await axios.post('/api/upload-excel', {
            data: rows,
            tableName: tableName,
            });

            console.log(response);
            alert('File uploaded successfully.');
        } catch (error) {
            console.error(error);
            alert('Error uploading the file.');
        }
        },
    },
    setup() {   
        const dbTableName = "calibrationdate";            
        const target_headers = ['Line', 'Station', 'Type', 'Measurement Target', 'Issued Year', 'Issued Month', 'Issued Day', 'Expire Year', 'Expire Month', 'Expire Day', 'Registraion Number', 'Manage', 'Remark', 'Row_no'];
        const targetList = reactive({ data: [], })
        const showModal = ref(false);

        onMounted(() => { searchData(); settingCall(); });          

        const searchData = async () => {         

          const formData = {
            tableName: dbTableName,
          };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => {
              console.log("Response from /api/target:", res.data);
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const targetItem = computed(() => { return targetList.data.map(d => { return { ...d, };});});

        const aS = (row) => {
          if (row.manage === 'no') {
            return { backgroundColor: 'transparent' };
          } else if (row.manage !== 'yes') {
            return { backgroundColor: 'purple' }; // 보라색
          }

          const currentDate = new Date();
          const expireDate = new Date(row.ex_year, row.ex_month - 1, row.ex_day);
          const diffTime = expireDate - currentDate;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays <= 0) {
            return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // 빨간색
          } else if (diffDays <= 60) {
            return { backgroundColor: 'rgba(255, 255, 0, 0.3)' }; // 노란색
          } else {
            return { backgroundColor: 'transparent' }; // Transparent color
          }
        };       
                     
        const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
        }; 

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error download excel data:", error.message); });
        };
        
        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(targetList.data, { header: target_headers.value });
          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "calibration");


          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "calibratoin.xlsx");
        };

        const callSearchData = () => { searchData(dbTableName); };    

        return { callSearchData, showModal, targetItem, targetList, editCellValue, aS, target_headers, dbTableName, downloadExcel};        
    }
  }
</script>

<style scoped>
.table-container { overflow-x: hidden; position: relative; }

.table-header,
.table-body { overflow: hidden; width: 100%;}

.table-body { max-height: 350px; overflow-y: auto;}

.table { width: 100%; max-width: 100%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed;}

.table th,
.table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; text-align: center;}

.table thead th { 
  position: sticky; top: 0; z-index: 100; background-color: #f8f9fa;
  font-weight: 600; text-transform: uppercase; letter-spacing: 1px;
  font-size: 0.9rem; vertical-align: bottom; border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody { border-top: 2px solid #dee2e6;}

.table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075);}

.target { font-family: Arial, Helvetica, sans-serif;}

.target .table { border: 1px solid #dee2e6; border-radius: 0.25rem;
  overflow: hidden; box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.target .table td { text-align: center;}

.target .table tr:nth-child(odd) { background-color: #f8f9fa;}

.target .table tr:hover { background-color: #e9ecef; cursor: pointer;}
.target hr { border: 0; height: 1px; margin-bottom: 1rem;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));  
}

.modalbutton {
  background-color: #2076f7;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-weight: bold;
  padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 20px; margin-bottom : 10px;
}
.modalbutton:hover { background-color: #6780ec; }

.button {
      padding: 5px 10px;
      background-color: #3498db;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 14px;   
      font-weight: 600;
      margin-right: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

  .parent { display: flex; justify-content: space-between; }
  .addbutton { margin-left: auto;  }

  .custom-file-upload {
    display: inline-block;  padding: 4px 12px;  cursor: pointer;
    background-color: #c49105;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }

  .custom-file-upload:hover { background-color: #f3a359; }

  .excelbutton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .excelbutton:hover { background-color: #009625; }
</style>