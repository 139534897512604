<template>
  <div class="modal-mask">
  <div class="modal-wrapper">
  <div class="modal-container">
  <div class="modal-header">
    <h3>Line Inspection View</h3>
    <button class="close-button" @click="closeModal">X</button>
  </div>
    <div class="modal-body">
      <table class="signTable">
        <tr>
          <th>Date</th><th>Done Date</th><th>Writer</th><th>Report Number</th><th>Confirm</th>
        </tr>
        <tr>
          <td>{{formInputs.day}}.{{formInputs.month}}.{{formInputs.year}}</td>
          <td>{{formInputs.finday}}.{{formInputs.finmonth}}.{{formInputs.finyear}}</td>
          <td>{{formInputs.writer}}</td>
          <td>{{formInputs.reportnumber}}</td>
          <td>{{formInputs.confirm}}</td>          
        </tr>
      </table><br>
      
      <table class="itemTable"> <!--Title Table-->
        <tr style="border: 1px solid rgb(156, 156, 156);">
          <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;">Title</td>
          <td style="border: 0; text-align: left;" class="contentsTd">{{formInputs.title}}</td>                  
        </tr>
      </table><br>

      <P class="evaluationTitle">Machine</P>
      <table class="evaluationTable">              
        <tr>
          <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
        </tr>
        <tr>
          <td class="evaCont">Did you use materials that meet the specifications?</td>                
          <td class="score">15</td>
          <td class="score">{{score01.sc01}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is there any abnormal noise?</td>                
          <td class="score">10</td>
          <td class="score">{{score01.sc02}}</td>
        </tr>
        <tr>
          <td class="evaCont">Has the machine been tested after installation preliminary (primary) inspection?</td>                
          <td class="score">10</td>
          <td class="score">{{score01.sc03}}</td>
        </tr>
        <tr>
          <td class="evaCont">Are the controls panels and buttons visible, recognizable and appropriately marked?</td>                
          <td class="score">10</td>
          <td class="score">{{score01.sc04}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is the language used in the machines Local language, Korean and English, is it easy to change it?</td>                
          <td class="score">10</td>
          <td class="score">{{score01.sc05}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is a surge protection device installed?</td>                
          <td class="score">10</td>
          <td class="score">{{score01.sc06}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is there enough space for repairs by the maintenance staff during maintenance?</td>                
          <td class="score">10</td>
          <td class="score">{{score01.sc07}}</td>
        </tr>
        <tr>
          <td class="evaCont">Are the touch screen and setup program easy to operate?</td>                
          <td class="score">15</td>
          <td class="score">{{score01.sc08}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is it comfortable for operators to work?</td>                
          <td class="score">10</td>
          <td class="score">{{score01.sc09}}</td>
        </tr>                      
      </table><br>

      <P class="evaluationTitle">Product/Quality</P>
      <table class="evaluationTable">              
        <tr>
          <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
        </tr>
        <tr>
          <td class="evaCont">Does it meet the agreed upon productivity?</td>                
          <td class="score">30</td>
          <td class="score">{{score02.sc01}}</td>
        </tr>
        <tr>
          <td class="evaCont">Are the quality standards met?</td>                
          <td class="score">25</td>
          <td class="score">{{score02.sc02}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is there any problem with the detection power of the inspection equipment?</td>                
          <td class="score">25</td>
          <td class="score">{{score02.sc03}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is the master jig provided? (documents included)</td>                
          <td class="score">20</td>
          <td class="score">{{score02.sc04}}</td>
        </tr>             
      </table><br>

      <P class="evaluationTitle">Pneumatic</P>
      <table class="evaluationTable">              
        <tr>
          <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
        </tr>
        <tr>
          <td class="evaCont">Does it match the pneumatic installation drawing?</td>                
          <td class="score">20</td>
          <td class="score">{{score03.sc01}}</td>
        </tr>
        <tr>
          <td class="evaCont">Are the pneumatic hoses numbered and labeled?</td>                
          <td class="score">20</td>
          <td class="score">{{score03.sc02}}</td>
        </tr>
        <tr>
          <td class="evaCont">Are there any air leaks?</td>                
          <td class="score">20</td>
          <td class="score">{{score03.sc03}}</td>
        </tr>
        <tr>
          <td class="evaCont">Is there no damage to the machine due to pressure loss or critical pressure drop?</td>                
          <td class="score">20</td>
          <td class="score">{{score03.sc04}}</td>            
        </tr>
        <tr>
          <td class="evaCont">Was it installed through the main air unit?</td>
          <td class="score">20</td>
          <td class="score">{{score03.sc05}}</td>  
        </tr>             
      </table><br>

      <P class="evaluationTitle">Training and After Service</P>
      <table class="evaluationTable">              
        <tr>
          <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
        </tr>
        <tr>
          <td class="evaCont">Was there sufficient training for operators, technicians, maintenance personnel, and engineers?</td>                
          <td class="score">20</td>
          <td class="score">{{score04.sc01}}</td>  
        </tr>
        <tr>
          <td class="evaCont">Were spares provided?</td>                
          <td class="score">10</td>
          <td class="score">{{score04.sc02}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Does the machine have a warranty?</td>                
          <td class="score">10</td>
          <td class="score">{{score04.sc03}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Is it possible to communicate with the manufacturer and local engineers?</td>                
          <td class="score">20</td>
          <td class="score">{{score04.sc04}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Can local engineer resolve problems online or by telephone support?</td>                
          <td class="score">20</td>
          <td class="score">{{score04.sc05}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Are service and spare parts available in local?</td>                
          <td class="score">20</td>
          <td class="score">{{score04.sc06}}</td> 
        </tr>             
      </table><br>

      <P class="evaluationTitle">Safety</P>
      <table class="evaluationTable">              
        <tr>
          <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
        </tr>
        <tr>
          <td class="evaCont">Are devices installed for worker safety?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc01}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Does it stop immediately when the safety device operates?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc02}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Does Door Auto Lock work well?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc03}}</td> 
        </tr>
        <tr>
          <td class="evaCont">In case of an emergency stop, does the facility stop immediately?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc04}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Does the tower lamp work well (green/yellow/red)?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc05}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Are dangerous places and obstacles properly marked and secured?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc06}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Is the machine equipped with protective measures against hazards caused by gas, vapor, liquid or dust emissions?</td>                
          <td class="score">5</td>
          <td class="score">{{score05.sc07}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Are the machine and its parts properly secured to ensure their stability?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc08}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Is the workplace, including machine maintenance, properly lit?</td>                
          <td class="score">5</td>
          <td class="score">{{score05.sc09}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Is the machine protected against hazards electrocution of workers?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc10}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Is there protection against risk to workers due to pressure loss or critical pressure drop in the event of an emergency stop?</td>                
          <td class="score">10</td>
          <td class="score">{{score05.sc11}}</td> 
        </tr>
        
      </table><br>

      <P class="evaluationTitle">Documentation</P>
      <table class="evaluationTable">              
        <tr>
          <th class="evaCont">Inspection Item</th><th class="score">Max Score</th><th class="score">Score</th>
        </tr>
        <tr>
          <td class="evaCont">Have you received electrical drawings?</td>                
          <td class="score">10</td>
          <td class="score">{{score06.sc01}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Have you received the mechanical drawing?</td>                
          <td class="score">10</td>
          <td class="score">{{score06.sc02}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Have you received the pneumatic drawings?</td>                
          <td class="score">10</td>
          <td class="score">{{score06.sc03}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Have you received a lubrication drawing?</td>                
          <td class="score">10</td>
          <td class="score">{{score06.sc04}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Have you received the Trouble shooting Guide?</td>                
          <td class="score">20</td>
          <td class="score">{{score06.sc05}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Have you received the user manual?</td>                
          <td class="score">20</td>
          <td class="score">{{score06.sc06}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Does the machine have local safety certification (e.g. European CE)?</td>                
          <td class="score">10</td>
          <td class="score">{{score06.sc07}}</td> 
        </tr>
        <tr>
          <td class="evaCont">Whether electrical measurements of the machine have been performed?</td>                
          <td class="score">10</td>
          <td class="score">{{score06.sc08}}</td> 
        </tr>             
      </table><br>

      <table class="itemTable">
        <tr style="border: 1px solid rgb(156, 156, 156);">
          <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;">Remark</td>
          <td style="border: 0">{{ formInputs.remark }}</td>
          <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;">Total Score</td>
          <td style="border: 0; text-align: center; width: 100px;">{{ formInputs.sum_score }}</td>
          <td style="background-color: #eaeaea; border: 0; padding: 0px 19px;" >result</td>
          <td style="border: 0; width: 100px;" :class="resultClass">{{ formInputs.result }}</td>
        </tr>
      </table><br>
      
      <table class="imageTable">
        <tr>
          <th>Image 1</th><th>Image 2</th>
        </tr>
        <tr>
          <td>
            <img v-if="formInputs.imageonepath && formInputs.imageonepath !== '0'" :src="getImageUrl(formInputs.imageonepath)" alt="Image" style="width: 500px; height: auto;" @click="openImageInPopup(getImageUrl(formInputs.imageonepath))">
            <template v-else></template><br>
          </td>
          <td>
            <img v-if="formInputs.imagetwopath && formInputs.imagetwopath !== '0'" :src="getImageUrl(formInputs.imagetwopath)" alt="Image" style="width: 500px; height: auto;" @click="openImageInPopup(getImageUrl(formInputs.imagetwopath))">
            <template v-else></template>      
          </td>
        </tr>
      </table>
      <div> 
        <button class="printButton" @click="printDocument">Print</button>
      </div>
    </div>        
  </div>      
  </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, computed } from 'vue';  
  import axios from 'axios';     
   
  export default {   
    methods: {
      //getImageUrl(filepath) {return `http://localhost:3000/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `https://lsevpweb.ngrok.app/uploads/${filepath}`;},
      getImageUrl(filepath) { return filepath;},
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); },
      printDocument() {
        let printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');       
        document.querySelectorAll('link[rel="stylesheet"]').forEach((link) => {
          printWindow.document.head.appendChild(link.cloneNode(true));
        });    
        document.querySelectorAll('style').forEach((style) => {
          printWindow.document.head.appendChild(style.cloneNode(true));
        });      
        const printContent = document.querySelector('.modal-container').innerHTML;
        printWindow.document.body.innerHTML = printContent;
        printWindow.document.close(); 
        printWindow.focus();
        setTimeout(() => { printWindow.print(); printWindow.close(); }, 1000);
      },
      convertNewLines(text) { return text.replace(/\n/g, '<br>'); }
    },         
    setup(props, { emit }) {   
      const dbTableName = "constructioninspection";  
      const formInputs = reactive({   
        id: "", year:"", month: "", day: "", finyear: "", finmonth: "", finday: "", type: "", title: "", writer: "", reportnumber:"", 
        score_1: "", score_2: "", score_3: "", score_4: "", score_5: "", score_6: "",
        result: "", remark: "", confirm: "",
        file: "", filepath: "", imageone: "", imageonepath: "", imagetwo: "", imagetwopath: "",
        sum_score: "",
      });

      const score01 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "", sc07: "", sc08: "", sc09: "",});
      const score02 = reactive({sc01: "", sc02: "", sc03: "", sc04: "" });
      const score03 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "" }); 
      const score04 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "" });
      const score05 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "", sc07: "", sc08: "", sc09: "", sc10: "", sc11: "", });
      const score06 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", sc06: "", sc07: "", sc08: "" });      
        
      const parseAndAssignScores = (scoreString, scoreObject) => {
        const scores = scoreString.split(';');
        Object.keys(scoreObject).forEach((key, index) => {
          scoreObject[key] = scores[index] || "";
        });
      };
      
      const fetchSelectedRowData = async () => {
        try {
          const response = await axios.get("/api/materialrequestadd", {
            params: { tableName: dbTableName, id: props.rowId },
          });
          const rowData = response.data[0];

          formInputs.id = rowData.id;
          formInputs.year = rowData.year,
          formInputs.month = rowData.month,
          formInputs.day = rowData.day,
          formInputs.finyear = rowData.finyear,
          formInputs.finmonth = rowData.finmonth,
          formInputs.finday = rowData.finday,
          formInputs.type = rowData.type,
          formInputs.title = rowData.title,
          formInputs.writer = rowData.writer,
          formInputs.reportnumber = rowData.reportnumber,           
          formInputs.sum_score = rowData.sum_score,   
          formInputs.result = rowData.result,   
          formInputs.remark = rowData.remark,            
          formInputs.confirm = rowData.confirm,
          formInputs.file = rowData.file,
          formInputs.filepath = rowData.filepath,
          formInputs.imageone = rowData.imageone,
          formInputs.imageonepath = rowData.imageonepath,
          formInputs.imagetwo = rowData.imagetwo,
          formInputs.imagetwopath = rowData.imagetwopath     

          parseAndAssignScores(rowData.score_1, score01);
          parseAndAssignScores(rowData.score_2, score02);
          parseAndAssignScores(rowData.score_3, score03);
          parseAndAssignScores(rowData.score_4, score04);
          parseAndAssignScores(rowData.score_5, score05);
          parseAndAssignScores(rowData.score_6, score06);

        } catch (error) {
          console.error("Error fetching row data:", error.message);
        }
      };
      watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );    

      const closeModal = () => { emit("onClose"); }; 

      const resultClass = computed(() => {
        switch (formInputs.result) {
          case 'OK': return 'result-ok';
          case 'NG': return 'result-ng';
          default: return 'result-neutral';
        }
      });

      return { dbTableName, formInputs, closeModal,resultClass,
               score01, score02, score03, score04, score05, score06, 
      };
    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   
.modal-wrapper { width: 100%;  max-width: 1060px; }
.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}
.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
.modal-header h3 { font-size: 1.2rem; }
.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.signTable th {background-color: #eaeaea; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.signTable td {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.imageTable th {background-color: #eaeaea; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.imageTable td {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}

.mainTable {margin-top: 20px; margin-bottom: 20px;}
.itemTd {background-color: #d2d2d2; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px; border: 1px solid #7d7d7d; text-align: center;}
.contentsTd {background-color: #ffffff; color: black; font-size: 14px; font-weight: bold; padding: 5px 5px;
            border: 1px solid #7d7d7d; text-align: left; width: 950px;
}
.printButton {
  background-color: #347003;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-size: 15px; font-weight: bold;
  padding: 10px 10px; transition: background-color 0.3s; margin-top : 10px; 
}
.itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
.itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
.evaluationTitle { font-weight: bold; }
.evaluationTable { table-layout: fixed; width: 100%; border-collapse: collapse; }
.evaluationTable td  { vertical-align: center; text-align: left; padding: 0 5px; border: 1px solid rgb(156, 156, 156); }
.evaluationTable th { background-color:#eaeaea ; vertical-align: center; text-align: center; padding: 10px 15px; border: 1px solid rgb(156, 156, 156);}  
.evaluationTable select, input { width: 70px; }

.evaItem {width: 220px;}
.evaCont {width: 500px;}  
.score {width: 100px; text-align: center !important; }

.gradeTable { table-layout: fixed; width: 100%; }
.gradeTable td { width: 20%; vertical-align: top; padding: 0 15px; }
.gradeTable input { width: 70px; }

.grade {width: 220px;}
.nextEva {width: 500px;}  
.remark {width: 220px; }  
.remarkInput {width: 220px !important; }  
.result-ok { background-color: rgb(153, 239, 153); font-weight: bold; text-align: center; }
.result-ng { background-color: rgb(239, 173, 173); font-weight: bold; text-align: center; }
.result-neutral { background-color: transparent; }

</style>