<template>
  <div class="checksheet">
    <div class="act">
      <h class="pagetitle">{{ lineName }} Check Sheet</h>
    </div>
    <div class="horizontal-form">      
      <form @submit.prevent="sendDate()" class="form-container">
         <div class="start">
            <input type="number" id="startYear" v-model="searchDate.startYear" placeholder="Year" />
            <input type="number" id="startMonth" v-model="searchDate.startMonth" placeholder="Month" />
            <input type="number" id="startDay" v-model="searchDate.startDay" placeholder="Day" />
          </div>
          <div class="separator">~</div>
          <div class="end">
            <input type="number" id="endYear" v-model="searchDate.endYear" placeholder="Year" />
            <input type="number" id="endMonth" v-model="searchDate.endMonth" placeholder="Month" />     
            <input type="number" id="endDay" v-model="searchDate.endDay" placeholder="Day" />
          </div>  
          <div class="end">
            <select v-model="filters.result">
              <option>All</option>
              <option>ok</option><option>pass</option><option>need check</option>
              <option>Except</option>                     
            </select>
          </div>
          <button class="searchButton" type="submit" @click="callSearchData">Search</button>
          <button class="excelButton" @click="downExcel">Down Load</button>                
          <div class="modal-buttons">  
            <button v-for="(slice, index) in filteredModalSlices" :key="index" class="modalButton" @click="handleModalButtonClick(index)">
              Check Sheet {{ index + 1 }}
            </button>
          </div>      
      </form>      
    </div>

    <div class="table-container">
      <table class="tables">
        <tr>
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>
        </tr>
        <tr v-for="item in filteredCheckSheetData" :key="item.id">
          <td>{{ item.process }}</td>
          <td>{{ item.shift }}</td>
          <td>{{ item.technician }}</td>
          <td>{{ item.time }}</td>
          <td>{{ item.model }}</td>
          <td>{{ item.item }}</td>
          <td>{{ item.standard }}</td>
          <td>{{ item.value }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ item.acceptance }}</td>
          <td :class="{ 'ok': item.result === 'ok', 'ng': item.result === 'ng', 'pass': item.result === 'pass', 'need-check': item.result === 'need check', 'except': item.result === 'Except' }">{{ item.result }}</td>
          <td>{{ item.remark }}</td>
        </tr>
      </table>
    </div>

    <component
      v-for="(slice, index) in filteredModalSlices"
      :is="getModalComponent(index)"
      v-show="showModals[index]"
      :key="'modal' + (index + 1)"      
      @onClose="closeModal(index)"
      :sliceNumbers="slice"
      :lineName="lineName"
      :index="index"
      :start="startValues[index]"      
    />
  </div>
</template>

<script>
import { useForm } from '../0_formStyle/form_checkSheet_main';
import { downloadExcel } from "../../utils.js"; 

export default {
  props: { lineName: { type: String, required: true }},

  methods: {   
    downExcel() { downloadExcel(this.filteredCheckSheetData, "checkSheet.xlsx"); },
  },

  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>

<style scoped src="../0_formStyle/style_check_main.css"></style>
