import { reactive, ref, computed, } from 'vue';
import { searchData, calculateSums, getHeaders, getSumsTemplate, getShiftKeys, getModelKeys,
         calculateInputAndNgRate, getInputAndNgItemKeys } from "../../productionUtils.js";
import { targets } from "../../../lineData.js";

export function useForm(lineName) {        
  const selectedLine = targets.find(target => target.name === lineName);        
  if (!selectedLine) { throw new Error(`Line name "${lineName}" not found in targets`); }

  const dbTableName = selectedLine.pr;
  const dtTableName = selectedLine.dt;
  const drTableName = selectedLine.dr;
  const qwTableName = selectedLine.qw;

  //Production result part
  const showModal = ref(false); 
  const headers = getHeaders(lineName);         
  const prTableData = reactive({ data: [], });     

  //Downtime part
  const showdtModal = ref(false);  
  const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
  const dtTableData = reactive({ data: [], });      

  //Daily repot part
  const showdrModal = ref(false);    
  const drHeaders = ['Contents']; 
  const drTableData = reactive({ data: [], });      

  //QWALL part
  const showqwModal = ref(false);   
  const qwTableData = reactive({ data: [], });   

  //Repeat Data part
  const showrdModal = ref(false);      
  
  const searchDate = ref({ year: '', month: '', day: '', });

  const sendDate = async () => {
    const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
    return {sendDateData};   
  };    

  const item = computed(() => {
    const inputAndNgItemKeys = getInputAndNgItemKeys(lineName);

    return prTableData.data.map(d => {
      const items = inputAndNgItemKeys.reduce((obj, key) => {
        obj[key] = isNaN(d[key]) ? 0 : d[key];
        return obj;
      }, {});

      const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(items);

      return { 
        ...d, 
        input, 
        ngRate: isNaN(ngRate) ? 'NaN%' : `${ngRate}%`, 
        fakeng, 
        fn_ngRate: isNaN(fn_ngRate) ? 'NaN%' : `${fn_ngRate}%` 
      };
    });
  });

  const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

  const callSearchData = async () => {
    try {
      const { sendDateData } = await sendDate();
      const tableNames = [dbTableName, dtTableName, drTableName, qwTableName];
      const result = await searchData(tableNames, sendDateData);
      
      prTableData.data = result.prTableData;
      dtTableData.data = result.dtTableData;
      drTableData.data = result.drTableData;
      qwTableData.data = result.qwTableData;
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };        
  
  //데이터 더하기 위한 Template, shift, model 설정 (lineData.js에서 조정)
  const sumsTemplate = getSumsTemplate(lineName);
  const shiftKeys = getShiftKeys(lineName); 
  const modelKeys = getModelKeys(lineName);
  
  const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys, qwItem.value,));
  //끝
  
  return { headers, showModal, dtHeaders, dtTableData, 
            showdtModal, item, dbTableName, prTableData, searchData, searchDate, lineName, 
            showdrModal, drHeaders, drTableData, sums, callSearchData, 
            showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
            showrdModal,
            };        
}
