<template>
  <div class="failureTarget">      
    <hr>
    <div class="parent">
      <div class="addbutton">        
        <button class="button" @click="showModal = true">+ Add</button>    
      </div>
    </div>
    <div class="table-container">      
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Year'" :key="index" @click="editCellValue(targetTableName, row.id, 'year', $event)">{{ row.year }}</td>
          <td v-if="header === 'Line'" :key="index" @click="editCellValue(targetTableName, row.id, 'line', $event)">{{ row.line }}</td>
          <td v-if="header === 'MTTR'" :key="index" @click="editCellValue(targetTableName, row.id, 'mttr', $event)">{{ row.mttr }}</td>
          <td v-if="header === 'MTBF'" :key="index" @click="editCellValue(targetTableName, row.id, 'mtbf', $event)">{{ row.mtbf }}</td>
          <td v-if="header === 'Failure Rate'" :key="index" @click="editCellValue(targetTableName, row.id, 'failurerate', $event)">{{ row.failurerate }}</td>
          <td v-if="header === 'Row No'" :key="index" @click="editCellValue(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
        </template>   
      </tr>
    </tbody>     
    </table> <br>
    <h3>ROW_NO Standard</h3>
    <table class="rownostandard">
      <tr>
        <th>Year</th><th>Project</th><th>Line</th>
      </tr>
      <tr>
        <td>4 digit</td><td>1 digit</td><td>2 digit</td>
      </tr>
      <tr>
        <td colspan="3">Example : 101 (VW: 1 / TR3 SMT: 01)</td>                
      </tr>
    </table>
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import Modal from "./targetModal.vue";
import axios from "axios";

export default {         
    components: { Modal, },   
    setup() {   
        const targetTableName = "failuretarget";            
        const target_headers = ['Year', 'Line', 'MTTR', 'MTBF', 'Failure Rate', 'Row No'];
        const targetList = reactive({ data: [], })
        const showModal = ref(false); 

        onMounted(() => { searchData(); });   

        const searchData = async () => {         

          const formData = {
            tableName: targetTableName,
          };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => {
              console.log("Response from /api/target:", res.data);
              targetList.data = res.data.sort((a, b) => a.row - b.row); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const targetItem = computed(() => {
          return targetList.data.map(d => {
            return { ...d, };
          });
        });     
        
        const callSearchData = () => { searchData(targetTableName); };
             
        const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {              
              try {                
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });                
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
        };  

        return { targetItem, targetList, editCellValue, target_headers, targetTableName, showModal, callSearchData};        
    }
  }
</script>

<style scoped>
  .table-container {
    overflow-x: hidden;
    position: relative;
  }

  .table-header,
  .table-body {
    overflow: hidden;
    width: 100%;  
  }

  .table-body {
    max-height: 350px; 
    overflow-y: auto;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
  }

  .table thead th {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f8f9fa;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  .table tbody + tbody { border-top: 2px solid #dee2e6; }
  .table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075); }
  .target { font-family: Arial, Helvetica, sans-serif; }

  .target .table {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .target .table td { text-align: center; }
  .target .table tr:nth-child(odd) { background-color: #f8f9fa; }

  .target .table tr:hover {
    background-color: #e9ecef;
    cursor: pointer;
  }
  .target hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));
    margin-bottom: 1rem;
  }

  .button {
      padding: 5px 10px;
      background-color: #3498db;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 14px;   
      font-weight: 600;
      margin-right: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

  .parent { display: flex; justify-content: space-between; }
  .addbutton { margin-left: auto;  }

  .custom-file-upload {
    display: inline-block;  padding: 4px 12px;  cursor: pointer;
    background-color: #c49105;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }

  .custom-file-upload:hover { background-color: #f3a359; }

  .excelbutton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .excelbutton:hover { background-color: #009625; }

  .rownostandard {
    width: 50%;
    max-width: 50%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .rownostandard th,
  .rownostandard td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #000000;
    text-align: center;
  }

  .rownostandard thead th {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f8f9fa;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    vertical-align: bottom;
    border-bottom: 2px solid #000000;
  }

  .rownostandard tbody + tbody { border-top: 2px solid #000000; }
</style>