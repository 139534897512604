import { reactive, computed, watchEffect } from 'vue';
import { addData } from './data.js';

const trayTableData = reactive({ data: [] });
const planTableData = reactive({ data: [] });

watchEffect(() => { trayTableData.data = addData[3].data;});
watchEffect(() => { planTableData.data = addData[4].data;});

const tray_item = computed(() => { return trayTableData.data.map(d => {return { ...d };});});     
const plan_item = computed(() => { return planTableData.data.map(d => {return { ...d };});});     

const sumByLine = computed(() => {return tray_item.value.reduce((accumulator, currentItem) => {if (accumulator[currentItem.line]) {accumulator[currentItem.line] += currentItem.quantity;} else {accumulator[currentItem.line] = currentItem.quantity;} return accumulator; }, {});}); 
const sumByLineFactory = computed(() => {return tray_item.value.reduce((accumulator, currentItem) => {const factoryKey = `factory${currentItem.factory}`;if (!accumulator[factoryKey]) { accumulator[factoryKey] = {}; }if (accumulator[factoryKey][currentItem.line]) {accumulator[factoryKey][currentItem.line] += currentItem.quantity;} else {accumulator[factoryKey][currentItem.line] = currentItem.quantity;}return accumulator;}, {});});
const sumByPlanLine = computed(() => {
  const groupedData = {};            
  for (const item of plan_item.value) {if (!groupedData[item.line]) {groupedData[item.line] = {}; }groupedData[item.line][item.shift] = item.planquantity;}            
  const result = {};
  for (const line in groupedData) {const dayPlan = groupedData[line]["Day"] || 0; const nightPlan = groupedData[line]["Night"] || 0; result[line] = dayPlan + nightPlan; }
  return result;
});

const bmw48TrayAll = computed(() => sumByLine.value["BMW 48V"] || 0);    
const fordTrayAll = computed(() => sumByLine.value["Ford"] || 0);
const evoTrayAll = computed(() => sumByLine.value["EVO"] || 0);
const ev2020TrayAll = computed(() => sumByLine.value["EV2020"] || 0);

const fpcaTray1 = computed(() => {if (sumByLineFactory.value.factory1 && sumByLineFactory.value.factory1.FPCA) {return sumByLineFactory.value.factory1.FPCA;} return 0;});        
const fpcaTray2 = computed(() => {if (sumByLineFactory.value.factory2 && sumByLineFactory.value.factory2.FPCA) {return sumByLineFactory.value.factory2.FPCA;} return 0;});  

const bmw48Plan = computed(() => sumByPlanLine.value["BMW 48V"] || 0);
const fordPlan = computed(() => {
  const ford1front= sumByPlanLine.value["Ford 1 Front"] || 0;
  const ford1rear= sumByPlanLine.value["Ford 1 Rear"] || 0;
  const ford2front= sumByPlanLine.value["Ford 2 Front"] || 0;
  const ford2rear= sumByPlanLine.value["Ford 2 Rear"] || 0;
  const ford3front= sumByPlanLine.value["Ford 3 Front"] || 0;
  const ford3rear= sumByPlanLine.value["Ford 3 Rear"] || 0;            
  return ford1front + ford1rear + ford2front + ford2rear + ford3front + ford3rear;
});   
const tr2FpcaPlan = computed(() => sumByPlanLine.value["TR2 FPCA"] || 0);
const tr3FpcaPlan = computed(() => sumByPlanLine.value["TR3 FPCA"] || 0);    
const evoPlan = computed(() => {
  const evo1 = sumByPlanLine.value["EVO 1"] || 0;
  const evo2 = sumByPlanLine.value["EVO 2"] || 0;
  const evoHybrid = sumByPlanLine.value["EVO Hybrid"] || 0;
  return evo1 + evo2 + evoHybrid;
});
const ev2020Plan = computed(() => {
  const ev20204060front= sumByPlanLine.value["EV2020 4060kW Front"] || 0;
  const ev20204060rear= sumByPlanLine.value["EV2020 4060kW Rear"] || 0;  
  const ev202060front= sumByPlanLine.value["EV2020 60kW Front"] || 0;
  const ev202060rear= sumByPlanLine.value["EV2020 60kW Rear"] || 0;                
  return ev20204060front + ev20204060rear + ev202060front + ev202060rear;
});

const bmw48Result = computed(() => { const difference = (bmw48TrayAll.value*6) - bmw48Plan.value; return difference < 0 ? "Not Enough" : "Enough"; });
const fordResult = computed(() => { const difference = (fordTrayAll.value*18) - fordPlan.value; return difference < 0 ? "Not Enough" : "Enough"; });    
const fpcaResult1 = computed(() => { const difference = (fpcaTray1.value*2) - tr2FpcaPlan.value; return difference < 0 ? "Not Enough" : "Enough";});
const fpcaResult2 = computed(() => { const difference = (fpcaTray2.value*2) - tr3FpcaPlan.value; return difference < 0 ? "Not Enough" : "Enough"; });    
const evoResult = computed(() => { const difference = (evoTrayAll.value*8) - evoPlan.value; return difference < 0 ? "Not Enough" : "Enough"; });
const ev2020Result = computed(() => { const difference = (ev2020TrayAll.value*16) - ev2020Plan.value; return difference < 0 ? "Not Enough" : "Enough"; });              
    
export const trayResult1st = computed(() => {
        if ((bmw48Plan.value && bmw48TrayAll.value === 0) || (fordPlan.value && fordTrayAll.value === 0) || (tr2FpcaPlan.value && fpcaTray1.value === 0)) { return "Need to Count"; }
        const results = [bmw48Result.value, fordResult.value, fpcaResult1.value];
        return results.some(result => result === "Not Enough") ? "Not Enough" : "Enough";
});
export const trayResult2nd = computed(() => {
    if ((tr3FpcaPlan.value && fpcaTray2.value === 0) || (evoPlan.value && evoTrayAll.value === 0) || (ev2020Plan.value && ev2020TrayAll.value === 0)) { return "Need to Count"; }
    const results = [fpcaResult2.value, evoResult.value, ev2020Result.value];
    return results.some(result => result === "Not Enough") ? "Not Enough" : "Enough";
});

export const trayCellStyle1st = computed(() => {
  const result = trayResult1st.value;
  if (result === 'Not Enough') {
    return {'background-color': 'rgba(255, 0, 0, 0.7)'}; // 붉은색
  } else if (result === 'Need to Count') {
    return {'background-color': 'rgba(255, 255, 0, 0.7)'}; // 노란색
  }
  return {}; // 기본 색상
});

export const trayCellStyle2nd = computed(() => {
  const result = trayResult2nd.value;
  if (result === 'Not Enough') {
    return {'background-color': 'rgba(255, 0, 0, 0.7)'}; // 붉은색
  } else if (result === 'Need to Count') {
    return {'background-color': 'rgba(255, 255, 0, 0.7)'}; // 노란색
  }
  return {}; // 기본 색상
});
  