<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <table>
            <tr>
              <td><h3>Add Production Result</h3></td>
              <td><button type="submit" class="saveButton" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button></td>              
              <td>{{ saveStatus }}</td>          
            </tr>
          </table>  
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable"> 
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td class="grayTd">Date</td>                        
                <td>
                  <input class="dateInput" type="text" v-model="searchDate.year" />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />
                </td>
                <td class="grayTd">Shift</td>                  
                <td>
                  <input type="number" v-model="formInputs.shift" readonly class="readWindow" />
                </td>
                <td class="grayTd">Start Time</td>                  
                <td>
                  <select class="timeInput" v-model="formInputs.start_time">            
                    <option>01</option><option>02</option><option>03</option><option>04</option>
                    <option>05</option><option>06</option><option>07</option><option>08</option>
                    <option>09</option><option>10</option><option>11</option><option>12</option>
                    <option>13</option><option>14</option><option>15</option><option>16</option>
                    <option>17</option><option>18</option><option>19</option><option>20</option>
                    <option>21</option><option>22</option><option>23</option><option>24</option>
                  </select>                     
                </td>
                <td class="grayTd">End Time</td>                  
                <td>
                  <input  type="number" class="readWindow" v-model="formInputs.end_time" min="1" max="24" readonly/>                                      
                </td>
                <td class="grayTd">Work Time(minute)</td>                  
                <td>
                  <select v-model="formInputs.work_minute">            
                    <option>5</option><option>15</option><option>30</option><option>45</option><option>60</option>    
                  </select>                     
                </td>                
              </tr>
            </table><br>
          
            <table class="itemTable">
              <tr>
                <th></th><th>EVO 2P</th><th>EVO 3P</th><th>EV2020 FR/RR</th><th>EV2020 FFC</th>
              </tr>               
              <tr>
                <td class="grayTd" style="background-color: rgb(144, 204, 247) !important;">Packing QTY</td>
                <td><input class="itemInput" type="number" v-model="formInputs.evo_2p" /></td>
                <td><input class="itemInput" type="number" v-model="formInputs.evo_3p" /></td>
                <td><input class="itemInput" type="number" v-model="formInputs.ev2020frrr" /></td>
                <td><input class="itemInput" type="number" v-model="formInputs.ev2020ffc" /></td>
              </tr>
                <tr>
                <th></th><th>BMW 48V</th><th>BMW 12V</th><th>Ford Front</th><th>Ford Rear</th>
              </tr>  
              <tr>
                <td class="grayTd" style="background-color: rgb(144, 204, 247) !important;">Packing QTY</td>
                <td><input class="itemInput" type="number" v-model="formInputs.bmw48v" /></td>
                <td><input class="itemInput" type="number" v-model="formInputs.bmw12v" /></td>
                <td><input class="itemInput" type="number" v-model="formInputs.ford_front" /></td>
                <td><input class="itemInput" type="number" v-model="formInputs.ford_rear" /></td>
              </tr> 
            </table>
            <br>

            <table class="itemTable">
              <tr>
                <td class="grayTd">Supervisor</td>
                <td><input class="itemInput" type="text" v-model="formInputs.supervisor" /></td>    
                <td class="grayTd">Leader</td>
                <td><input class="itemInput" type="text" v-model="formInputs.leader" /></td>                                   
              </tr>   
              <tr>
                <td class="grayTd">Shift Name</td>
                <td><input class="itemInput" type="text" v-model="formInputs.shift_name" /></td>  
                <td class="grayTd">Operator QTY</td>
                <td><input class="itemInput" type="text" v-model="formInputs.operator_qty" /></td>   
              </tr>  
              <tr>
                <td class="grayTd">Work Order</td>
                <td><input class="itemInput" type="text" v-model="formInputs.workorder" /></td>   
                 <td class="grayTd">Remark</td>
                <td><input class="itemInput" type="text" v-model="formInputs.remark" /></td>                                                
              </tr>
              <tr>
                <td>Row_no: <input class="readWindow" type="text" v-model="formInputs.row_no" readonly /></td>   
              </tr>
            </table><br>
           
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" class="saveButton" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>  
  import { reactive, watch, computed, ref } from 'vue';  
  import axios from 'axios';  
  import { getCurrentDate, getColumnMapping , makeFormData, useFormInputs} from "../../utils.js"; 

  export default {    
    setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "packingpr"
        const lineName = "Packing"
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   

        const { formInputs, dataForm } = useFormInputs();  

        //시작시간에 따라서 shift와 row_no 자동 입력 -시작-
        watch(() => formInputs.start_time, (newValue) => {
          const start_time_num = parseInt(newValue, 10);
          formInputs.shift = start_time_num >= 6 && start_time_num <= 17 ? 1 : 2;

          if (start_time_num >= 6 && start_time_num <= 24) {
            formInputs.row_no = start_time_num - 5;
          } else if (start_time_num >= 1 && start_time_num <= 5) {
            formInputs.row_no = start_time_num + 19;
          }
        });
        //끝     
      
        const searchDate = reactive({ ...getCurrentDate(), });   

        //반복 입력되는 부분 불러오기 -시작- 
  
        const repeadtData = reactive({ data: [], })     
        const rdItem = computed(() => {return repeadtData.data.map(d => {return { ...d,};});});        
        watch(
            () => formInputs.shift,
            (newShift) => {
              if (newShift) {
                const repeatFormData = {
                  tableName: "repeatdata",
                  values: {
                    year: searchDate.year,
                    month: searchDate.month,
                    day: searchDate.day,
                    line_name: lineName,
                    shift: newShift,
                  },
                };
                axios
                  .get('/api/repeatData', { params: repeatFormData })
                  .then((res) => {                    
                    repeadtData.data = res.data;
                  })
                  .catch((error) => {
                    console.error('Error fetching target data:', error.message);
                  });
              }
            }
          );

          watch(
            () => rdItem.value,
            (newRdItem) => {
              if (newRdItem && newRdItem.length > 0) {
                const lastItem = newRdItem[newRdItem.length - 1];
                formInputs.supervisor = lastItem.supervisor;
                formInputs.leader = lastItem.leader;
                formInputs.shift_name = lastItem.shiftname;
                formInputs.operator_qty = lastItem.operatorqty;
                formInputs.workorder = lastItem.workorder;
              }
            }
          );

        //반복 입력되는 부분 불러오기 -끝-         

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day ) {
            alert("Please fill in all the fields: year, month, day.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = getColumnMapping(searchDate, formInputs);  
          const formData = makeFormData(searchDate, formInputs, dbTableName, columnMapping); 
         
          try {
              await axios.post('/api/post', formData);
              saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
            } catch (error) {
              console.error('Error during submission:', error);
              saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
            } finally {
              isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };

      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-  
      
      watch(
        () => formInputs.start_time,
        (newStartTime) => {
          const startTime = parseInt(newStartTime, 10);
          if (startTime < 24) {
            formInputs.end_time = (startTime + 1).toString().padStart(2, '0');
          } else {
            formInputs.end_time = '01';
          }
        }
      );

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, 
               saveStatus, isProcessing, dataForm };

    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      // currentDate: Object,
    },    
  };   
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 1200px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; width: 80px !important; }

  .saveButton[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px; margin-left: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;

  }

  .saveButton[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .grayTd {background-color: #eaeaea !important; }
  .itemInput { width: 150px; margin-left: 5px; margin-right: 5px; text-align: center; } 
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 100px; } 
  .costInput { margin-left: 5px; margin-right: 5px; width: 350px; } 
  .qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .dateInputFpca { width: 60px; size: 8px; margin-left: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }    
  .subDateInput { width: 50px; size: 8px; margin-left: 3px; margin-right: 3px; }  
  .titleInput { width: 663px; margin-left: 5px; }  
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 10px; margin-right: 10px; padding: 0px 10px; border: 0px; background-color: rgb(234, 246, 70); color:white; font-weight: bold; font-size: 15px; text-align: center }
  .failurestandard {margin-left:10px; font-weight: bold};  
  /*background-color: rgb(77, 69, 195);*/
</style>