import { reactive, ref, } from 'vue';  
import axios from 'axios';   
import { getCurrentDate, makeFormData, getColumnMapping, useFormInputs} from "../../../utils.js"; 
import { targets } from "../../../lineData.js";
  
export function useForm(props, emit) {   
    const selectedLine = targets.find(target => target.name === props.lineName);        
    if (!selectedLine) { throw new Error(`Line name "${props.lineName}" not found in targets`); }

    const dbTableName = selectedLine.dr;
    
    const saveStatus = ref(''); 
    const isProcessing = ref(false);     

    const { formInputs, dataForm } = useFormInputs();   
        
    const searchDate = reactive({ ...getCurrentDate(), });  
         
    const submitForm = async () => {
      if (!searchDate.year || !searchDate.month || !searchDate.day) {
        alert("Please fill in all the fields: year, month, and day.");
        return;
      }

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';

      const columnMapping = getColumnMapping(searchDate, formInputs);
      const formData = makeFormData(searchDate, formInputs, dbTableName, columnMapping);  

      try {      
        await axios.post('/api/post', formData);
        saveStatus.value = 'Completed';
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }          
      closeModal();
    };

    const closeModal = () => { emit("onClose"); };

    return { searchDate, dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing, dataForm};
  }