import axios from 'axios';
import { reactive } from 'vue';

export const setupDataSearch = (sendDate, fromlocation, tolocation, location, before) => {
  const fromTableData = reactive({ data: [] });
  const toTableData = reactive({ data: [] });
  const dailyTableData = reactive({ data: [] });
  const beforeDailyTableData = reactive({ data: [] });

  const searchData = async (tableName, params, tableData, url = '/api/stock') => {
    await axios.get(url, { params: { tableName, ...params } })
      .then((res) => { tableData.data = res.data; });

    // 모든 materialCode의 quantity 합산
    const materialQuantitySum = {};
    tableData.data.forEach(item => {
      materialQuantitySum[item.materialcode] = (materialQuantitySum[item.materialcode] || 0) + item.quantity;
    });

    // 두 번째 단계: materialCode의 BOM 구하기 및 계산
    const inRangeMaterialCodes = new Set(
      Object.keys(materialQuantitySum).filter(code =>
        parseInt(code) >= 2100000 && parseInt(code) <= 2299999)
    );

    const productRequests = Array.from(inRangeMaterialCodes).map(materialCode => {
      return axios.get('/api/where', {
        params: { value: materialCode, table_name: 'materiallist', column_name: 'productcode' }
      });
    });

    const productResponses = await Promise.all(productRequests);
    const bomData = productResponses.flatMap(res => res.data);

    // 총 수량 계산 및 범위 외 materialCode 처리
    const calculatedData = bomData.map(bomItem => {
      const productQuantity = materialQuantitySum[bomItem.productcode] || 0;
      const bomQuantity = bomItem.bom ? bomItem.bom : 0;

      return {
        ...bomItem,
        totalQuantity: productQuantity * bomQuantity
      };
    });

    // 범위 외 materialCode 중 BOM에 없는 항목 추가
    tableData.data.forEach(item => {
      if (!inRangeMaterialCodes.has(item.materialcode.toString())) {
        calculatedData.push({
          ...item,
          totalQuantity: item.quantity
        });
      }
    });

    // 계산된 데이터 저장
    tableData.data = calculatedData;

    // 같은 materialcode의 totalQuantity 합산 및 필요한 정보만 저장
    const aggregatedData = tableData.data.reduce((acc, item) => {
      const existingItem = acc.find(accItem => accItem.materialcode === item.materialcode);
      if (existingItem) {
        existingItem.totalQuantity += item.totalQuantity;
      } else {
        acc.push({
          materialcode: item.materialcode,
          materialname: item.materialname,
          totalQuantity: item.totalQuantity,
          unit: item.bomunit || item.unit // bomunit이 없으면 unit 사용
        });
      }
      return acc;
    }, []);

    // 합산된 데이터로 tableData.data 업데이트
    tableData.data = aggregatedData;
  };

  const fromSearchData = async (tableName) => {
    const sendDateData = await sendDate();
    const fromSearchDataForm = {
      values: {
        year: sendDateData.sendDateData[0],
        month: sendDateData.sendDateData[1],
        day: sendDateData.sendDateData[2],
        fromlocation: fromlocation
      }
    };

    await searchData(tableName, fromSearchDataForm, fromTableData);
  };

  const toSearchData = async (tableName) => {
    const sendDateData = await sendDate();
    const toSearchDataForm = {
      values: {
        year: sendDateData.sendDateData[0],
        month: sendDateData.sendDateData[1],
        day: sendDateData.sendDateData[2],
        tolocation: tolocation
      }
    };

    await searchData(tableName, toSearchDataForm, toTableData);
  };

  const dailySearchData = async (tableName) => {
    const sendDateData = await sendDate();
    const dailySearchDataForm = {
      values: {
        year: sendDateData.sendDateData[0],
        month: sendDateData.sendDateData[1],
        day: sendDateData.sendDateData[2],
        location: location
      }
    };

    await searchData(tableName, dailySearchDataForm, dailyTableData);
  };

  const beforeDailySearchData = async (tableName) => {
    const sendDateData = await sendDate();
    const beforeDailySearchDataForm = {
      values: {
        year: sendDateData.sendDateData[0],
        month: sendDateData.sendDateData[1],
        day: sendDateData.sendDateData[2],
        location: location,
        before: before
      }
    };

    await searchData(tableName, beforeDailySearchDataForm, beforeDailyTableData,);
  };

  const mergeData = () => {
    const mergedData = {};

    const mergeTable = (tableData, key) => {
      tableData.data.forEach(item => {
        const code = item.materialcode;
        if (!mergedData[code]) {
          mergedData[code] = {
            materialcode: item.materialcode,
            materialname: item.materialname,
            fromtotalQuantity: 0,
            tototalQuantity: 0,
            dailytotalQuantity: 0,
            beforeDailytotalQuantity: 0,
            unit: item.unit
          };
          mergedData[code][key] = item.totalQuantity;
        } else {
          mergedData[code][key] += item.totalQuantity;
        }
      });
    };

    mergeTable(fromTableData, 'fromtotalQuantity');
    mergeTable(toTableData, 'tototalQuantity');
    mergeTable(dailyTableData, 'dailytotalQuantity');
    mergeTable(beforeDailyTableData, 'beforeDailytotalQuantity');

    Object.values(mergedData).forEach(item => {
      item.beforePlusIn = item.beforeDailytotalQuantity + item.tototalQuantity;
      item.inMinusOut = item.beforePlusIn - item.fromtotalQuantity;
      item.different = item.dailytotalQuantity - item.inMinusOut;
    });

    return Object.values(mergedData);
  };

  return {
    fromTableData,
    toTableData,
    dailyTableData,
    beforeDailyTableData,
    fromSearchData,
    toSearchData,
    dailySearchData,
    beforeDailySearchData,
    mergeData
  };
};
