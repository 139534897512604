<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Maintenance Technician Evaluation</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="informationTable">
              <tr>
                <td>
                <div class="form-group date-time-group">
                  <label>Date(Year/Month/Day):</label>
                  <div class="date-time-inputs">
                    <input type="text" id="year" v-model="searchDate.year"  />
                    <input type="text" id="month" v-model="searchDate.month" />
                    <input type="text" id="day" v-model="searchDate.day" />                  
                  </div> 
                </div>
                </td>
                <td>
                <div class="form-group">
                  <label for="employeenumber">Employee Number</label>
                  <input type="text" id="employeenumber" v-model="formInputs.employeenumber" />
                </div> 
                </td>
                <td>
                <div class="form-group">
                  <label for="line">Line:</label>
                  <select id="line" v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
                    <option>Packing</option><option>Production Logistic</option><option>Maintenance</option><option></option>          
                  </select>              
                </div>
                </td>     
                <td>
                <div class="form-group">
                  <label for="station">Station:</label>
                  <select id="station" v-model="formInputs.station">
                    <option v-for="station in uniqueStations" :key="station">{{ station }}</option>
                    <option>Leader</option><option>Technician</option><option>Packing</option><option>Production Logistic</option><option>Maintenance</option>
                    <option></option>
                  </select>              
                </div>
                </td>
                </tr>
                <tr>
                <td>
                <div class="form-group date-time-group">
                  <label>Work Start:</label>
                  <div class="date-time-inputs">
                    <input type="text" id="year" v-model="formInputs.startyear"  />
                    <input type="text" id="month" v-model="formInputs.startmonth" />
                    <input type="text" id="day" v-model="formInputs.startday" />                  
                  </div> 
                </div>  
                </td>
                <td>
                <div class="form-group">
                  <label for="oscompany">OS Company:</label>
                  <select id="oscompany" v-model="formInputs.oscompany" >
                    <option>MAX POWER</option><option>MEGA STAFF</option><option>ZYX</option><option>Inkatom</option>
                    <option></option>
                  </select>
                </div>
                </td>
                <td>
                <div class="form-group date-time-group">
                  <label>Name/Surname:</label>
                  <div class="name-inputs">
                    <input type="text" id="name" v-model="formInputs.name" />
                    <input type="text" id="surname" v-model="formInputs.surname" />                    
                  </div> 
                </div>                  
                </td>
                <td>
                <div class="form-group">
                  <label for="position">Position:</label>
                  <input type="text" id="position" v-model="formInputs.position" />
                </div>    
              </td>
              </tr>
            </table><br>

            <P class="evaluationTitle">Work Method</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaItem">Evaluation Item</th><th class="evaCont">Check Point</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaItem">Equipment</td>
                <td class="evaCont">Do you understand work equipment and utility equipment?</td>
                <td class="score">15</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc01">
                    <option>15</option><option>14</option><option>13</option><option>12</option><option>11</option>
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="evaItem">Mechanic</td> 
                <td class="evaCont">Do you have mechanical knowledge?</td>
                <td class="score">10</td>                
                <td class="score">
                  <select id="workmethod" v-model="score01.sc02">
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="evaItem">Electric</td>
                <td class="evaCont">Do you have electrical knowledge?</td>
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc03">   
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>                 
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="evaItem">Pneumatic</td>
                <td class="evaCont">Do you have pneumatic knowledge?</td>
                <td class="score">10</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc04">  
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>                  
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>  
              <tr>
                <td class="evaItem">Responsiveness</td>
                <td class="evaCont">Do you respond quickly?</td>
                <td class="score">15</td>
                <td class="score">
                  <select id="workmethod" v-model="score01.sc05">  
                    <option>15</option><option>14</option><option>13</option><option>12</option><option>11</option>
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>                  
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>              
            </table><br>

            <P class="evaluationTitle">Work Attitude</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaItem">Evaluation Item</th><th class="evaCont">Check Point</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaItem">Safety Education</td>
                <td class="evaCont">Have you completed safety training?</td>
                <td class="score">5</td>
                <td class="score">
                  <select id="workattitude" v-model="score02.sc01">                    
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="evaItem">Fill out the form</td>
                <td class="evaCont">Are you implementing the report?</td>
                <td class="score">5</td>
                <td class="score">
                  <select id="workattitude" v-model="score02.sc02">                    
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="evaItem">Responsibility</td>
                <td class="evaCont">Are you fulfilling your duties?</td>
                <td class="score">10</td>
                <td class="score">
                  <select id="workattitude" v-model="score02.sc03">        
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>            
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="evaItem">Cooperation</td>
                <td class="evaCont">Do you work by helping each other?</td>
                <td class="score">5</td>
                <td class="score">
                  <select id="workattitude" v-model="score02.sc04">                    
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>   
              <tr>
                <td class="evaItem">Compliance with work</td>
                <td class="evaCont">Did you comply with the work attire?</td>
                <td class="score">5</td>
                <td class="score">
                  <select id="workattitude" v-model="score02.sc05">                    
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>                 
            </table><br>

            <P class="evaluationTitle">3C5S</P>
            <table class="evaluationTable">              
              <tr>
                <th class="evaItem">Evaluation Item</th><th class="evaCont">Check Point</th><th class="score">Max Score</th><th class="score">Score</th>
              </tr>
              <tr>
                <td class="evaItem">Job Cleaning Status</td>
                <td class="evaCont">Are the workspaces kept clean?</td>
                <td class="score">5</td>
                <td class="score">
                  <select id="threefive" v-model="score03.sc01">
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="evaItem">Organized</td>
                <td class="evaCont">Are the tools necessary for the work properly positioned?</td>
                <td class="score">5</td>
                <td class="score">
                  <select id="threefive" v-model="score03.sc02">
                    <option>10</option><option>9</option><option>8</option><option>7</option><option>6</option>
                    <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option>
                  </select>
                </td>
              </tr>       
            </table><br>

            <table class="gradeTable">
              <tr>
                <td class="grade">              
                <div class="form-group">
                  <label for="grade">Grade</label>
                  <input type="text" id="grade" v-model="formInputs.grade" readonly />
                </div>  
                </td>                
                <td class="nextEva">
                <div class="form-group date-time-group">
                  <label>Next Evaluation Date(Year/Month/Day):</label>
                  <div class="date-time-inputs">
                    <input type="text" id="year" v-model="formInputs.nextyear"  />
                    <input type="text" id="month" v-model="formInputs.nextmonth" />
                    <input type="text" id="day" v-model="formInputs.nextday" />                  
                  </div> 
                </div>  
                </td>                
                <td class="remark">
                <div class="form-group">
                  <label for="remark">Remark</label>
                  <input class="remarkInput" type="text" id="remark" v-model="formInputs.remark" />
                </div>      
              </td>
            </tr>
            </table>            
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch,  ref } from 'vue';  
  import axios from 'axios';  
  import { getCurrentDate, fetchLine, fetchStation, } from "../../utils.js";  
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "evaluation";   
        const uniqueLines = ref([]);   
        const uniqueStations = ref([]);   
        const saveStatus = ref(''); 
        const isProcessing = ref(false);                    
        const formInputs = reactive({   
          year: "", month: "", day: "", employeenumber: "", type: "normal", line: "", station: "",
          startyear: "", startmonth: "", startday: "", oscompany: "",  name: "", surname: "", position: "",
          workmethod: "", quality: "", workattitude: "", threefive: "", grade: "", confirm: "Not Yet",
          nextyear: "", nextmonth: "", nextday: "", remark: ""
        });
        const score01 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", });
        const score02 = reactive({sc01: "", sc02: "", sc03: "", sc04: "", sc05: "", });
        const score03 = reactive({sc01: "", sc02: "", });   

        const searchDate = reactive({ ...getCurrentDate(), });    

        const submitForm = async () => {
          if (!formInputs.employeenumber || !formInputs.oscompany ) {
            alert("Please fill in all the fields: Employee Number, OS Company.");
            return;
          }

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {              
            year: 'year',
            month: 'month',
            day: 'day',
            type: 'type',
            line: 'line',
            station: 'station',
            startyear: 'startyear',
            startmonth: 'startmonth',
            startday: 'startday',
            oscompany: 'oscompany',
            employeenumber: 'employeenumber',
            name : 'name',
            surname : 'surname',
            position : 'position',
            workmethod : 'workmethod',
            quality : 'quality',
            workattitude : 'workattitude',
            threefive : 'threefive',
            grade : 'grade',
            confirm : 'confirm',
            nextyear : 'nextyear',
            nextmonth : 'nextmonth',
            nextday : 'nextday',            
            remark : 'remark',               
          }     

          const formData = {            
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {              
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              type: formInputs.type,
              line: formInputs.line,
              station: formInputs.station,
              startyear: formInputs.startyear,
              startmonth: formInputs.startmonth,
              startday: formInputs.startday,
              oscompany: formInputs.oscompany,
              employeenumber: formInputs.employeenumber,
              name : formInputs.name,
              surname : formInputs.surname,
              position : formInputs.position,
              workmethod : formInputs.workmethod,
              quality : formInputs.quality,
              workattitude : formInputs.workattitude,
              threefive : formInputs.threefive,
              grade : formInputs.grade,
              confirm : formInputs.confirm,
              nextyear : formInputs.nextyear,
              nextmonth : formInputs.nextmonth,
              nextday : formInputs.nextday,
              remark : formInputs.remark,         
            }
         };     

         try {
            await axios.post('/api/post', formData);  
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }         
        
         closeModal();
        };
        const closeModal = () => { emit("onClose"); };   
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];
              
              formInputs.line = rowData.line;
              formInputs.station = rowData.station;
              formInputs.startyear = rowData.startyear;
              formInputs.startmonth = rowData.startmonth;
              formInputs.startday = rowData.startday;
              formInputs.oscompany = rowData.oscompany;
              formInputs.employeenumber = rowData.employeenumber;
              formInputs.name = rowData.name;
              formInputs.surname = rowData.surname;
              formInputs.position = rowData.position;              
              formInputs.remark = rowData.remark;

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

      const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
      const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };
  
      watch(() => formInputs.line, (newLine) => { loadStations(newLine); });
     
      const fetchname = () => {
        const employeeTableName = 'lineworkerlist';
        const columnName = 'employeenumber';        
        axios
          .get('/api/where', { params: { value: formInputs.employeenumber, table_name: employeeTableName, column_name: columnName } })
          .then((res) => {
            const filteredData = res.data.filter(data => 
              data.line === formInputs.line && data.station === formInputs.station);

            if (filteredData.length > 0) {
              const employeeData = filteredData[0];
                formInputs.oscompany = employeeData.oscompany;                                  
                formInputs.name = employeeData.name;
                formInputs.surname = employeeData.surname;       
                formInputs.startyear = employeeData.startyear;
                formInputs.startmonth = employeeData.startmonth;
                formInputs.startday = employeeData.startday;
            } else {
                formInputs.name = "";
                formInputs.surname = "";
                formInputs.oscompany = "";                
                formInputs.startyear = "";
                formInputs.startmonth = "";
                formInputs.startday = "";
            }
          })
          .catch((error) => {
            console.error('Error fetching target data:', error.message);
          });
      };
      watch([() => formInputs.employeenumber, () => formInputs.line, () => formInputs.station], () => { fetchname(); });
        
      const calculateNextEvaluationDate = () => {
        const currentDate = new Date(searchDate.year, searchDate.month - 1, searchDate.day);
        currentDate.setFullYear(currentDate.getFullYear() + 1);

        formInputs.nextyear = currentDate.getFullYear();
        formInputs.nextmonth = currentDate.getMonth() + 1;
        formInputs.nextday = currentDate.getDate();
      };

      calculateNextEvaluationDate();

      watch(() => [searchDate.year, searchDate.month, searchDate.day], () => {
        const currentDate = new Date(searchDate.year, searchDate.month - 1, searchDate.day);
        currentDate.setFullYear(currentDate.getFullYear() + 1);

        formInputs.nextyear = currentDate.getFullYear();
        formInputs.nextmonth = currentDate.getMonth() + 1;
        formInputs.nextday = currentDate.getDate();
      });   

      watch(
        () => [score01.sc01, score01.sc02, score01.sc03, score01.sc04, score01.sc05],
        () => {formInputs.workmethod = `${score01.sc01};${score01.sc02};${score01.sc03};${score01.sc04};${score01.sc05}`;}
      );
      watch(
        () => [score02.sc01, score02.sc02, score02.sc03, score02.sc04, score02.sc05],
        () => {formInputs.workattitude = `${score02.sc01};${score02.sc02};${score02.sc03};${score02.sc04};${score02.sc05}`;}
      );
      watch(
        () => [score03.sc01, score03.sc02],
        () => {formInputs.threefive = `${score03.sc01};${score03.sc02}`;}
      );      
      watch(
        () => [
          score01.sc01, score01.sc02, score01.sc03, score01.sc04, score01.sc05,
          score02.sc01, score02.sc02, score02.sc03, score02.sc04, score02.sc05,
          score03.sc01, score03.sc02, 
        ],
        () => {
          const totalScore = 
            parseInt(score01.sc01) + parseInt(score01.sc02) + parseInt(score01.sc03) + parseInt(score01.sc04) + parseInt(score01.sc05) + 
            parseInt(score02.sc01) + parseInt(score02.sc02) + parseInt(score02.sc03) + parseInt(score02.sc04) + parseInt(score02.sc05) + 
            parseInt(score03.sc01) + parseInt(score03.sc02);

          if (totalScore >= 90) { formInputs.grade = 'E'; }
          else if (totalScore >= 70 && totalScore < 90) { formInputs.grade = 'N'; }
          else { formInputs.grade = 'T'; }
        }
      );

      return { dbTableName, formInputs, searchDate, submitForm, closeModal, uniqueLines, uniqueStations, saveStatus, isProcessing,
               score01, score02, score03, 
      };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }  
  .modal-wrapper { width: 100%;  max-width: 1000px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  label { font-weight: bold; }
  .evaluationTitle { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  input[type="number"] { width: 100%;}
  .readWindow[readonly] { background-color: #f0f0f0; }
  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }
  button[type="submit"]:hover { background-color: #45a049; }

  .informationTable { table-layout: fixed; width: 100%; }
  .informationTable td { width: 20%; vertical-align: top; padding: 0 15px; }

  .evaluationTable { table-layout: fixed; width: 100%; border-collapse: collapse; }
  .evaluationTable td  { vertical-align: center; text-align: center; padding: 0 5px; border: 1px solid black; }
  .evaluationTable th { background-color:rgb(215, 215, 215) ; vertical-align: center; text-align: center; padding: 0 15px; border: 1px solid black;}
  .evaluationTable select, input { width: 70px; }

  .evaItem {width: 220px;}
  .evaCont {width: 500px;}  
  .score {width: 100px; }

  .gradeTable { table-layout: fixed; width: 100%; }
  .gradeTable td { width: 20%; vertical-align: top; padding: 0 15px; }
  .gradeTable input { width: 70px; }

  .grade {width: 220px;}
  .nextEva {width: 500px;}  
  .remark {width: 220px; }  
  .remarkInput {width: 220px !important; }  
  

  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 80px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }
  .name-inputs { display: flex; gap: 5px;}
  .name-inputs input { width: 100px; size: 8px; }

  .radio { margin-left: 10px; margin-right: 5px; }
</style>