<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Repeat Data</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <td>
                  <div class="form-group date-time-group">
                      <label>Year/Month/Day:</label>
                      <div class="date-time-inputs">
                        <input type="text" id="year" v-model="searchDate.year"  />
                        <input type="text" id="month" v-model="searchDate.month" />
                        <input type="text" id="day" v-model="searchDate.day" />                  
                      </div> 
                  </div>                                         
                  <div class="form-group">
                    <label for="shift">Shift:</label>
                    <select id="shift" v-model="formInputs.shift">
                      <option>1</option><option>2</option>
                    </select>
                  </div>  
                  <div class="form-group">
                    <label for="workorder">Work Order:</label>
                    <input type="text" id="workorder" v-model="formInputs.workorder" />
                  </div>  
                </td>                               
                <td>
                  <div class="form-group">
                    <label for="supervisor">Supervisor:</label>
                    <input type="text" id="supervisor" v-model="formInputs.supervisor" />
                  </div>
                  <div class="form-group">
                    <label for="leader">Leader:</label>
                    <input type="text" id="leader" v-model="formInputs.leader" />
                  </div>
                  <div class="form-group">
                    <label for="technician">Technician:</label>
                    <input type="text" id="technician" v-model="formInputs.technician" />
                  </div>
                  <div class="form-group">
                    <label for="shiftname">Shift Name:</label>
                    <input type="text" id="shiftname" v-model="formInputs.shiftname" />
                  </div>
                  <div class="form-group">
                    <label for="operator_qty">Operator Quantity:</label>
                    <input type="number" id="operatorqty" v-model="formInputs.operatorqty" />
                  </div>  
                </td>
              </tr>
            </table>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                                        
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>
import { useForm } from './0_formStyle/modal/form_rd_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
<style scoped src="./0_formStyle/modal/style_rd_modal.css"></style>