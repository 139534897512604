<template>
  <div class="arduinotest">          
      <div class="horizontal-form">    
        <div>
          <input v-model="year" placeholder="Year"/>
        </div>
        <div>
          <input v-model="month" placeholder="Month"/>
        </div>          
        <div class="searchTd">
          <button class="searchButton" @click="callSearchData()">Search</button>                            
        </div>   
        <div class="searchTd">
          <button class="addButton" @click="showModal = true">+ Add</button>                            
        </div>   
      </div>   

    <div class="table-body">
      <table id="targetTable" class="table">       
      <tr>          
        <th v-for="(header, index) in headers" :key="index" >{{ header }}</th>          
      </tr>          
        <tr v-for="row in item" :key="row.id">          
          <td>{{ row.year }}</td>
          <td>{{ row.month }}</td>
          <td @click="editCell(targetTableName, row.id, 'plntousd', $event)">{{ row.plntousd }}</td>
          <td @click="editCell(targetTableName, row.id, 'eurtousd', $event)">{{ row.eurtousd }}</td>
          <td @click="editCell(targetTableName, row.id, 'plntokrw', $event)">{{ row.plntokrw }}</td>
          <td @click="editCell(targetTableName, row.id, 'eurtokrw', $event)">{{ row.eurtokrw }}</td>                 
      </tr>    
    </table>
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData()"/>                                     
  </div>    
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import { editCellValue } from "../../utils.js"; 

import Modal from "./exchangeRateModal.vue";

export default {         
  components: { Modal,}, 

  methods: {
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    },
  },     

  setup() {   
      const targetTableName = "exchangerate";            
      const headers = ['Year', 'Month', 'PLN -> USD', 'EUR -> USD', 'PLN -> KRW', 'EUR -> KRW',];
      const showModal = ref(false);
      const year = ref();
      const month = ref();
      const targetList = reactive({ data: [], })      

      const callSearchData = async () => { searchData(); };

      const searchData = async () => {         

        const formData = {
          tableName: targetTableName,
          year: year.value,          
          month: month.value,
        };

        axios.get('/api/yearMonth', { params: formData })
          .then((res) => {
            const data = res.data.data;
            const latestData = {};

            data.forEach(item => {
             const key = `${item.year}-${item.month}`;
              if (!latestData[key]) {
                latestData[key] = { ...item };
              } else {
                // Update each field only if it exists in the current item
                latestData[key].plntousd = item.plntousd || latestData[key].plntousd;
                latestData[key].eurtousd = item.eurtousd || latestData[key].eurtousd;
                latestData[key].plntokrw = item.plntokrw || latestData[key].plntokrw;
                latestData[key].eurtokrw = item.eurtokrw || latestData[key].eurtokrw;
              }
            });

            targetList.data = Object.values(latestData);
          })
          .catch((error) => {
            console.error("Error fetching target data:", error.message);
          });
        };

      const item = computed(() => { return targetList.data.map(d => { return { ...d, }; }); });        

      return { item, targetList, headers, targetTableName, showModal, callSearchData, year, month};        
  }
}
</script>

<style scoped>
.table-container {
  overflow-x: hidden;
  position: relative;
}
input { padding: 5px 10px; margin-left: 10px; width: 150px;}
.table-header,
.table-body {
  overflow: hidden;
  width: 100%;  
}

.table-body {
  max-height: 350px; /* Adjust the height to your needs */
  overflow-y: auto;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  table-layout: fixed;
}


.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f8f9fa;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* Custom table styles */
.target {
  font-family: Arial, Helvetica, sans-serif;
}

.target .table {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.target .table td {
  text-align: center;
}

.target .table tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.target .table tr:hover {
  background-color: #e9ecef;
  cursor: pointer;
}
.target hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));
  margin-bottom: 1rem;
}
.addButton { margin-left: 20px; padding: 5px 20px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
.searchButton {
  padding: 5px 10px; background-color: #94a5b1; color: #000000; border: none;
  border-radius: 4px; font-size: 14px; font-weight: 600; margin-right: 3px;
  cursor: pointer; transition: background-color 0.3s;
}
</style>