<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">NG List</h>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">                  
          <div>            
            <table class="searchTable">
              <tr>
                <th>Start Year</th><th>Start Month</th><th>Start Day</th><th>End Year</th><th>End Month</th><th>End Day</th>
                <th>Project</th><th>Status</th><th>NG Type</th><th>Resolved</th><th>Word</th>
              </tr>
              <tr>
              <td><input class="dateInput" type="text" id="startYear" v-model="startYear" placeholder="Year" /></td>
              <td><input class="dateInput" type="text" id="startMonth" v-model="startMonth" placeholder="Month" /></td>
              <td><input class="dateInput" type="text" id="startDay" v-model="startDay" placeholder="Day" /></td>
              <td><input class="dateInput" type="text" id="endYear" v-model="endYear" placeholder="Year" /></td>
              <td><input class="dateInput" type="text" id="endMonth" v-model="endMonth" placeholder="Month" /></td>   
              <td><input class="dateInput" type="text" id="endDay" v-model="endDay" placeholder="Day" /></td>
                <td>
                  <select v-model="project">
                    <option v-for="proj in projects" :key="proj">{{ proj }}</option><option></option>
                  </select>
                </td>
                <td>
                  <select v-model="status">
                    <option v-for="status in statuses" :key="status">{{status }}</option><option></option>
                  </select>
                </td>
                <td>
                  <select v-model="ngtype">
                    <option v-for="ngtype in ngTypes" :key="ngtype">{{ ngtype }}</option><option></option>
                  </select>                
                </td>
                <td>
                  <select v-model="resolved">
                    <option v-for="resolved in resolveds" :key="resolved">{{ resolved }}</option><option></option>
                  </select>                
                </td>
                <td><input class="searchInput" type="text" id="word" v-model="word" placeholder="Search word" /></td>
              </tr>
            </table>
          </div>
          <div>
            <table class="buttonTable">
              <tr>
                <th colspan="3"></th>
              </tr>
              <tr>
                <td><button type="submit" @click="callSearchData">Search</button></td>
                <td><button class="button" @click="showModal = true">+ Add NG Contents</button></td>
                <td><button class="custom-file-upload" @click.prevent="downExcel">Down Load</button></td>
              </tr>
            </table>
          </div>                                  
        </div>      
      </form>  
    </div>      
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th>Occur Date</th><th>Project</th><th>Line</th><th>Status</th><th>Posting</th><th>NG Type</th><th>Code</th><th>Name</th><th>Qty</th><th>Pallet Qty</th><th>Contents</th>
          <th>Countermeasure</th><th>Location</th><th>Resolved Date</th><th>Resolved</th><th>SAP Storage</th><th>Modify</th><th>DEL</th>
        </tr>      
        <tr v-for="row in Item" :key="row.id" >               
          <td>{{ row.occurdate }}</td>       
          <td>{{ row.project }}</td>
          <td>{{ row.line }}</td>
          <td>{{ row.status }}</td>
          <td>{{ row.posting }}</td>
          <td>{{ row.ngtype }}</td>
          <td>{{ row.materialcode }}</td>
          <td>{{ row.materialname }}</td>
          <td>{{ row.quantity }}</td>
          <td>{{ row.pallet }}</td>
          <td>{{ row.contents }}</td>
          <td>{{ row.countermeasure }}</td>
          <td>{{ row.location }}</td>          
          <td>{{ row.resolveddate }}</td>
          <td>{{ row.resolved }}</td>
          <td>{{ row.sap }}</td>
          <td><button type="submit" @click="openSendModal(row)" class="modifyButton">Modify</button></td>
          <td><button class="del" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(row)">DEL</button></td>        
      </tr>
    </table>
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./ngModal.vue";
import modifyModal from "./ngMoModal.vue";
import { deleteRow, downloadExcel,} from "../utils.js"; 
import { projectList, statusList, ngTypeList, resolvedList } from './data.js';

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      downExcel() { downloadExcel(this.Item, "NG List.xlsx"); },
      delRow(row) {
        if (row.filepath) { alert('First file delete and try again'); }
        else { deleteRow(this.dbTableName, row.id, this.callSearchData); }
      },
      canClick() {          
        const currentUser = this.$route.meta.currentUser;
        console.log('currentUser', currentUser)
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3; } 
        else { return false; }
      }, 
    },

    components: { Modal, modifyModal, },     

    setup() {   
        const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();
        const word = ref(); const project = ref(); const status = ref(); const ngtype = ref(); const resolved = ref();
        const dbTableName = 'nglist';
        const showModal = ref(false);
        const showMoModal = ref(false);     
        const ngListData = reactive({ data: [], })    
        const projects = ref(projectList); 
        const statuses = ref(statusList); 
        const ngTypes = ref(ngTypeList); 
        const resolveds = ref(resolvedList); 
         
        const sendDate = async () => {const sendDateData = [
              startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value,
              word.value, project.value, status.value, ngtype.value, resolved.value,
        ]; return {sendDateData};};     

        const searchData = async () => {  
          if (!startYear.value){return}
          const sendDateData = await sendDate();
          const formData = {        
                tableName: dbTableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],                
                word: sendDateData.sendDateData[6],
                project: sendDateData.sendDateData[7],
                status: sendDateData.sendDateData[8],
                ngtype: sendDateData.sendDateData[9],
                resolved: sendDateData.sendDateData[10],
            };  

          console.log('Requesting data with:', formData);

          axios.get('/api/ngList', { params: formData })                      
            .then((res) => {
              const updatedData = res.data.map((row) => {
                // 여기에서 계산하고 새로운 속성을 추가
                const newRow = {
                  ...row,       
                  occurdate: `${row.year}.${row.month}.${row.day}`,           
                  resolveddate: `${row.resolvedyear}.${row.resolvedmonth}.${row.resolvedday}`,
                };
                return newRow;
              });

              // 정렬된 데이터를 ngListData.data에 저장
              ngListData.data = updatedData.sort((a, b) => {
              if (a.year < b.year) return -1; if (a.year > b.year) return 1;    
              if (a.month < b.month) return -1; if (a.month > b.month) return 1;  
              if (a.day < b.day) return -1; if (a.day > b.day) return 1;  
              if (a.project < b.project) return -1; if (a.project > b.project) return 1;
              if (a.status < b.status) return -1; if (a.status > b.status) return 1;
              if (a.ngtype < b.ngtype) return -1; if (a.ngtype > b.ngtype) return 1;
              if (a.resolved < b.resolved) return -1; if (a.resolved > b.resolved) return 1;

              return 0;
          });
      });
        }
        const Item = computed(() => { return ngListData.data.map(d => { return { ...d, }; });  });

        const callSearchData = () => { searchData(dbTableName); };        

        return { Item, deleteRow, dbTableName, callSearchData, showModal, showMoModal, projects, statuses, ngTypes, resolveds, 
                 startYear, startMonth, startDay, endYear, endMonth, endDay, word, project, status, ngtype, resolved,
                 sendDate, downloadExcel,  
        };        
    }
  }
</script>

<style scoped> 
    .table-container { margin-left : 10px; margin-right: 10px;}    
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .searchTable th{ background-color: #fffd9d; border : 1px solid black; text-align : center; font-size : 12px; font-weight: 600; }
    .searchTable td{ border : 1px solid black; text-align : center; font-size : 12px; }
    .buttonTable th{ background-color: white; border : 0px solid white; text-align : center; font-size : 12px; font-weight: 600; height: 40px; }
    .buttonTable td{ border : 0px solid black; text-align : center; font-size : 12px; }
    .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload:hover { background-color: #009625; }

    button { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .del { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 11px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
    .start,.end { display: flex; flex-direction: row; border: 0px; }
    .start input,.end input { margin-right: 5px;}    
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }z
    .searchTd { border: 0px !important; }
    .searchTda { border: 0px !important; width: 500px; }
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100px; }
    .searchInput { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100px;  }    
    .dateInput { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 80px;  }    
    .horizontal-form { margin-top: 10px; margin-left: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
    .modifyButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none; border-radius: 4px;
      font-size: 11px;font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px; cursor: pointer;        
      transition: background-color 0.3s;        
    }
</style>