<template>
  <div class="cleantarget">      
    <hr>      
    <div class="addbutton">    
      <select class="line-name-input" id="line" v-model="lineName">
        <option v-for="line in uniqueLines" :key="line">{{ line }}</option>          
      </select> 
      <button type="submit" class="searchButton" @click="callSearchData">Search</button>    
      <button class="modalbutton" @click="showModal = true">+ Add</button>  
      <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
      <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
      <span class="fileName">{{ fileName }}</span>   
      <button class="excelbutton" @click="upExcel(this.dbTableName)">Upload</button>
      <button class="excelbutton" @click="downExcel">Down Load</button>  
    </div>    
    <div class="table-container">
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line_name">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Line'" :key="index"  @click="editCell(this.dbTableName, row.id, 'line', $event)">{{ row.line }}</td>
          <td v-if="header === 'Station'" :key="index" @click="editCell(this.dbTableName, row.id, 'station', $event)">{{ row.station }}</td>
          <td v-if="header === 'Part'" :key="index" @click="editCell(this.dbTableName, row.id, 'part', $event)">{{ row.part }}</td>
          <td v-if="header === 'Cycle'" :key="index" @click="editCell(this.dbTableName, row.id, 'cycle', $event)">{{ row.cycle }}</td>
          <td v-if="header === 'Week'" :key="index" @click="editCell(this.dbTableName, row.id, 'week', $event)">{{ row.week }}</td>
          <td v-if="header === 'Row'" :key="index" @click="editCell(this.dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                    
          <td v-if="header === 'DEL'" :key="index"><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(this.dbTableName, row.id)">DEL</button></td>
        </template>   
      </tr>
    </tbody>     
    </table>
    </div> 
    <Modal  v-if="showModal" :dbTableName="dbTableName" @onClose="showModal = false; callSearchData();"/>    
    </div>
  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./cleanTargetModal.vue";
import { onFileChange, uploadExcel, downloadExcel, deleteRow, editCellValue, fetchData } from "../utils.js"; 

export default {         
    components: { Modal },  
    data() { return { excelFile: null, fileName: "", };},
    methods: {
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.targetItem, "Cleannig Target.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
      canClick() {          
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 }
        else { return false;}
      }, 
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
           editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      },
    },

    props: { dbTableName: { type: String, required: true } }, 
    setup(props) {                    
        const target_headers = ['Line', 'Station', 'Part', 'Cycle', 'Week', 'Row', 'DEL'];
        const targetList = reactive({ data: [], })
        const showModal = ref(false);
        const uniqueLines = ref();
        const lineName = ref();                 

        const searchData = async () => {    
          if (!lineName.value) {return}

          const formData = {
            tableName: props.dbTableName,
            line: lineName.value
          };

          axios.get('/api/targetList', { params: formData })
            .then((res) => {            
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const targetItem = computed(() => { return targetList.data.map(d => { return { ...d, };});});

        const callSearchData = () => { searchData(); };    

        const loadLines = async () => {
          uniqueLines.value = await fetchData(props.dbTableName, 'line');
          uniqueLines.value.sort((a, b) => a.localeCompare(b));
        }; loadLines(); 

        return { callSearchData, showModal, targetItem, targetList, target_headers,
                  uniqueLines, lineName, 
        };        
    }
  }
</script>

<style scoped>
.table-container { overflow-x: hidden; position: relative; }

.table-header,
.table-body { overflow: hidden; width: 100%;}

.table-body { max-height: 350px; overflow-y: auto;}

.table { width: 100%; max-width: 100%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed;}

.table th,
.table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; text-align: center;}

.table thead th { 
  position: sticky; top: 0; z-index: 100; background-color: #f8f9fa;
  font-weight: 600; text-transform: uppercase; letter-spacing: 1px;
  font-size: 0.9rem; vertical-align: bottom; border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody { border-top: 2px solid #dee2e6;}

.table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075);}

.target { font-family: Arial, Helvetica, sans-serif;}

.target .table { border: 1px solid #dee2e6; border-radius: 0.25rem;
  overflow: hidden; box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.target .table td { text-align: center;}

.target .table tr:nth-child(odd) { background-color: #f8f9fa;}

.target .table tr:hover { background-color: #e9ecef; cursor: pointer;}
.target hr { border: 0; height: 1px; margin-bottom: 1rem;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));  
}

.modalbutton {
  background-color: #2076f7;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-weight: bold;
  padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 20px; margin-bottom : 10px;
}
.modalbutton:hover { background-color: #6780ec; }

.button {
      padding: 5px 10px;
      background-color: #3498db;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 14px;   
      font-weight: 600;
      margin-right: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

  .parent { display: flex; justify-content: space-between; }
  .addbutton { margin-left: auto; margin-top: 10px; }

  .custom-file-upload {
    display: inline-block;  padding: 4px 12px;  cursor: pointer;
    background-color: #c49105;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }

  .custom-file-upload:hover { background-color: #f3a359; }

  .excelbutton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .excelbutton:hover { background-color: #009625; }
  .line-name-input{ width : 300px; margin-right: 20px; padding : 5px; margin-left : 20px; margin-bottom: 10px; }
  .searchButton { background-color: #bababa; color: rgb(0, 0, 0); border: none;
    border-radius: 4px; cursor: pointer; font-weight: bold; padding: 0.1rem 0.5rem;
    transition: background-color 0.3s;
  }
  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }
  .disabledButton { background-color: gray; cursor: not-allowed; }
</style>