<template>
  <div class="container">        
    <div class="RequestLog">
      <h class="pagetitle">QR Marking Laser Parameter</h>        
      <form @submit.prevent="sendDate()" class="searchBar">
          <table> 
              <tr>
                  <td class="start">
                      <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
                      <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
                      <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
                  </td>
                  <td class="separator">~</td>
                  <td class="end">
                      <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
                      <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
                      <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
                  </td>
                  <td class="searchTd">
                      <select v-model="line">                        
                        <option>EVO 1</option><option>EVO 2</option><option>EVO 3</option>
                        <option>EVO Hybrid</option><option>EV2020 60kW</option><option>EV2020 4060kW</option>
                        <option></option>
                      </select>                        
                  </td> 
                  <td class="searchTd">
                      <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
                  </td> 
                  <td class="searchTd">
                     <button class="addButton" @click="showModal = true">+ Add</button>   
                  </td>                  
                  <td class="searchTd">
                      <button class="excelButton" @click="downExcel">Excel</button>                                              
                  </td>
                  
              </tr>
          </table>      
      </form>
    </div>            
    <div class="tables">
        <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
        <tr v-for="row in item" :key="row.id">            
          <td>{{ row.year  }}</td>    
          <td>{{ row.month  }}</td>    
          <td>{{ row.day  }}</td>                  
          <td>{{ row.time  }}</td>                
          <td>{{ row.line }}</td>          
          <td>{{ row.station }}</td>                    
          <td>{{ row.power }}</td>    
          <td>{{ row.frequency }}</td>          
          <td>{{ row.technician }}</td>    
          <td>{{ row.remark }}</td>              
          <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="delRow(dbTableName, row.id)">DEL</button></td>
        </tr>
  </table>    
  </div>
  <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>      
</div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import Modal from "./qrLaserModal.vue";
import axios from 'axios';
import { deleteRow, downloadExcel, } from "../../../utils.js"; 

export default {
  components: { Modal, },   

  methods: {  
    downExcel() { downloadExcel(this.item, "QR Laser Parameter.xlsx"); },
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },    
    canClick() {            
      const currentUser = this.$route.meta.currentUser;            
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2; }
      else { return false; }
    },
  }, 

  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref();
    const endYear = ref(); const endMonth = ref(); const endDay = ref();
    const line = ref();

    const dbTableName = 'parabfaqrlaser';  
    const headers = ['Year', 'Month','Day', 'Time', 'Line', 'Station', 'Power(W or %)', 'Frequency(Hz)', 'Technician', 'Remark', 'DEL' ];
    const tableData = reactive({ data: [], });  
    const showModal = ref(false);   

    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, line.value]; return {sendDateData};};     
    const searchData = async (tableName) => {
        const sendDateData = await sendDate();  
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
            line: sendDateData.sendDateData[6],
        };        
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { tableData.data = res.data; });
    };  

    const callSearchData = () => { searchData(dbTableName);  };
    const item = computed(() => {return tableData.data.map(d => {return { ...d,};});});   

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay, line,
      headers, dbTableName, item, showModal,
      searchData, callSearchData, sendDate,    
    };
  },
};
</script>

<style scoped>
  * { box-sizing: border-box;}

  .container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: 10px; margin-right: auto;}
  .start,.end { display: flex; flex-direction: row; border: 0px;}
  .start input,.end input { margin-right: 5px;}
  .separator { font-size: 24px; margin: 0 10px; border: 0px;}
  .searchTd { border: 0px;}

  input::placeholder { color: gray;}

  label { font-size: 14px; font-weight: 600; margin-right: 8px;}

  select,input[type="number"],input[type="text"] {
    padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
    font-size: 14px; width: 100%; max-width: 100px;
  }

  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }

  .RequestLog{ width: 500px;}
  .searchBar {
    display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
    margin-bottom: 20px; margin-left: 20px;
  }
  .searchBar > *:not(:last-child) {  margin-right: 5px; }

  .searchBar select,.searchBar input{ width : 100px; margin-right: 5px; padding : 5px; }

  label { font-size: 14px; font-weight: 600; margin-right: 8px; }

  td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }

  th { 
    position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ;
    border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; 
  }
  .tables {
    display: flex; flex-direction: column; align-items: stretch;
    padding-left : 20px; padding-right : 20px;
  }

  table { width: 100%; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; }
  .disabledButton { background-color: gray !important; cursor: not-allowed !important; }
  .confirmButton {
      padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
      font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
      margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
  }
  .gray-background { background-color: gray;} 

  .searchButton, .excelButton, .addButton { width: 100px; height: 30px !important; font-size: 15px}
  .searchButton {
      background-color: #929292;  color: rgb(0, 0, 0);
      border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
      padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .searchButton:hover { background-color: #a4a4a4; }
  .excelButton {
      background-color: #347003;  color: white;
      border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
      padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .excelButton:hover { background-color: #009625; }
  .addButton {
      background-color: #007396;  color: white;
      border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
      padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .addButton:hover { background-color:  #3498db; }

</style>