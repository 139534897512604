<template>
  <div class="container">
    <div class="search">
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">     
          <div>            
            <input type="number" v-model="year" placeholder="Year" />
            <input type="number" v-model="month" placeholder="Month" />            
          </div>
          <div>
              <button class="searchButton" type="submit" @click="callSearchData">Search</button>
          </div>
          <div>
            <select class="exchange" v-model="currency">
              <option value="PLN">PLN</option>
              <option value="USD">USD</option>
            </select>
          </div>
          <div>
            <select class="withouteop" v-model="formInputs.use">
              <option>Without EOP</option><option>All</option>
            </select>
          </div>
          <div>
            <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
            <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
            <span class="fileName">{{ fileName }}</span>   
            <button class="listuploadbutton" @click.prevent="upExcel('oscostrolling')">Upload</button>
          </div>
        </div>
      </form>
    </div>   
    <b>Exchange Rate(PLN -> USD) : {{exchangeRate.data.plntousd}}</b>
    <br>
    <table>
      <tr>
        <th colspan="1"></th>
        <th colspan="6" class="okhead">Quantity</th>        
        <th colspan="7" class="downtimehead">Cost</th>   
      </tr>
      <tr><th v-for="(header, index) in headers" :key="index">{{ header }}</th></tr>
      <tr v-for="(target, index) in filteredTargets" :key="target.name">
        <td><b>{{ target.name }}</b></td>
        <td>{{ formatNumber(rowData[index].target_qty) }}</td>
        <td class="okhead">{{ rowData[index].ok }}</td>
        <td><b>{{ ((rowData[index].ok / rowData[index].target_qty)*100).toFixed(1) }}%</b></td>
        <td>{{ formatNumber(rowData[index].plan_qty) }}</td>
        <td>{{ formatNumber(Number(rowData[index].ok) + Number(rowData[index].plan_qty)) }}</td> <!--Expect_qty -->
        <td>{{ (((Number(rowData[index].ok) + Number(rowData[index].plan_qty))/rowData[index].target_qty)*100).toFixed(1) }}%</td> <!--Expect_Rate -->
        <!--<td>{{ rowData[index].hour }}</td>-->
        <td>{{ convertCurrency(rowData[index].cost_pln).toFixed(2) }}</td>    <!--Unit Cost -->  
        <td>{{ formatNumber((convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)).toFixed(0))  }}</td> <!--Target Cost -->          
        <td class="downtimehead">{{ formatNumber(convertCurrency(rowData[index].cost).toFixed(0)) }}</td>
        <td>{{ ((convertCurrency(rowData[index].cost)/(convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)))*100).toFixed(1) }}%</td> <!-- cost achieve -->
        <td>{{ ((((convertCurrency(rowData[index].cost) / (convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)))*100) / ((rowData[index].ok / rowData[index].target_qty)*100))*100).toFixed(1) }}%</td> <!-- over cost rate -->
        <td class="okhead">{{ formatNumber(((convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)) * ((Number(rowData[index].ok) + Number(rowData[index].plan_qty))/rowData[index].target_qty) * (((convertCurrency(rowData[index].cost) / (convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)))*100) / ((rowData[index].ok / rowData[index].target_qty)*100))).toFixed(0)) }}</td>
        <td :style="aS((((convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)) * ((Number(rowData[index].ok) + Number(rowData[index].plan_qty))/rowData[index].target_qty) * (((convertCurrency(rowData[index].cost) / (convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)))*100) / ((rowData[index].ok / rowData[index].target_qty)*100))) / (convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty))))"><b>{{ ((((convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)) * ((Number(rowData[index].ok) + Number(rowData[index].plan_qty))/rowData[index].target_qty) * (((convertCurrency(rowData[index].cost) / (convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)))*100) / ((rowData[index].ok / rowData[index].target_qty)*100))) / (convertCurrency(rowData[index].cost_pln) * Number(rowData[index].target_qty)))*100).toFixed(1) }}%</b></td>
      </tr>
       <tr>
        <td><b>Sum</b></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ formatNumber(totalTargetCost) }}</td>
        <td class="downtimehead">{{ formatNumber(totalCurrentCost) }}</td>
        <td>{{ ((totalCurrentCost / totalTargetCost)*100).toFixed(1) }}%</td>
        <td></td>
        <td class="okhead">{{ formatNumber(totalExpectCost) }}</td>
        <td :style="aS((totalExpectCost / totalTargetCost))"><b>{{ ((totalExpectCost / totalTargetCost)*100).toFixed(1) }}%</b></td>
      </tr>
    </table>        
  </div>
</template>


<script>
import { computed, reactive, ref } from 'vue';
import { createPeriodReportUtils, settingValue, withoutEOPLines, withEOPLines  } from './osCostUtils';
import { withoutExceptTargets, tableNames } from '../../lineData';
import { onFileChange, uploadExcel, } from "../../utils.js"; 

export default {
  methods: {    
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },      
  },
  data() { return { fileName: "", }; },
  setup() {
    const year = ref('');
    const month = ref('');
    const formInputs = reactive({ use: 'Without EOP' });
    const currency = ref('PLN');
    const { state, fetchAllData, fetchTargets, calculateRowData, sums, exchangeRate } = createPeriodReportUtils(tableNames);

    const headers = computed(() => {
      return ['Line', 'Target_qty', 'OK', 'Achieve', 'Plan_qty', 'Expect_qty', 'Expect_Rate', `Unit Cost(${currency.value})`, `Target Cost(${currency.value})`, `Current Cost(${currency.value})`, 'Achieve', 'Over Cost Rate', `Expect Cost(${currency.value})`, 'Expect_Rate',  ];
    });

    const filteredTargets = computed(() => {
      let targets = withoutExceptTargets;
      if (formInputs.use === 'Without EOP') {
        targets = targets.filter(target => withoutEOPLines.includes(target.name));
      } else if (formInputs.use === 'All') {
        targets = targets.filter(target => withEOPLines.includes(target.name));
      }
      return targets.concat([{ name: 'Common 1' }, { name: 'Common 2' }, { name: 'Inkatom' }]); // Add Common 1 and Common 2
    });

    const rowData = computed(() => {
      return filteredTargets.value.map(target => calculateRowData(target));
    });

    const convertCurrency = (value) => {
      if (currency.value === 'USD') {
        return Number(value / exchangeRate.data.plntousd);
      }
      return Number(value);
    };

    const totalTargetCost = computed(() => {
      return parseFloat(rowData.value.reduce((sum, row) => sum + convertCurrency(row.cost_pln) * Number(row.target_qty), 0)).toFixed(0);
    });

    const totalCurrentCost = computed(() => {
      return parseFloat(rowData.value.reduce((sum, row) => sum + convertCurrency(row.cost), 0)).toFixed(0);
    });

    const totalExpectCost = computed(() => {
      return parseFloat(rowData.value.reduce((sum, row) => {
        const targetQty = Number(row.target_qty);
        const ok = Number(row.ok);
        const planQty = Number(row.plan_qty);
        const costPln = convertCurrency(row.cost_pln);
        const cost = convertCurrency(row.cost);

        if (targetQty === 0 || costPln === 0) {
          return sum; // 0으로 나누는 경우를 피하기 위해 sum에 더하지 않음
        }

        const exceptCost = (costPln * targetQty) * ((ok + planQty) / targetQty) * ((cost / (costPln * targetQty)) * 100 / ((ok / targetQty) * 100));
        return sum + exceptCost;
      }, 0)).toFixed(0);
    });

    const sendDate = async () => {
      const dateRange = {
        year: year.value,
        month: month.value,
      };
      return dateRange;
    };

    const settingData = reactive({ data: [] });
    (async () => {
      try {
        const { settingData: setting } = await settingValue();
        settingData.data = setting.data;
      } catch (error) {
        console.error("Error initializing settings:", error.message);
      }
    })();

    const callSearchData = async () => {
      try {
        const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));
        const isReportEnabled = reportSetting && reportSetting.report === 1;
        if (!isReportEnabled) {
          alert('Report is currently disabled.');
          return;
        }

        const dateRange = await sendDate();

        await fetchTargets();
        await fetchAllData(dateRange);

        // Debugging: Console log the state data to ensure it's populated correctly        
      } catch (error) {
        console.error("Report is currently disabled:", error.message);
      }
    };

    const aS = (value) => {
      if (isNaN(value)) {
        return { backgroundColor: 'transparent' }; // Transparent color
      } else if (value > 1.05) {
        return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // Light red
      } else if (value > 1.00) {
        return { backgroundColor: 'rgba(255, 255, 0, 0.3)' }; // Light yellow
      } else {
        return { backgroundColor: 'rgba(144, 238, 144, 0.3)' }; // Light green
      }
    };

    const targetItem = computed(() => {
      const items = {};
      state.targetList.forEach(d => {
        items[d.line_name] = d;
      });
      return items;
    });

    const formatNumber = (number) => {
      return Number(number).toLocaleString();
    };

    return {
      headers, state, rowData, sendDate, callSearchData, aS, filteredTargets, formInputs,
      year, month, sums, targetItem, exchangeRate, currency, convertCurrency, formatNumber,
      withoutExceptTargets, totalTargetCost, totalCurrentCost, totalExpectCost,
    };
  }
};
</script>




<style scoped>
   * {
    box-sizing: border-box;
  }

  .container {
    margin-top: 0px;
    padding-top: 0px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .search {
  position: relative;
  background-color: #ffffff; /* Set a background color for better visibility */  
  padding: 0px; /* Add some padding for better appearance */
  height: auto;
  width: 800px;
}
  .start,
  .end {
    display: flex;
    flex-direction: row;    
    border: 0px;
  }

  .start input,
  .end input {
    margin-right: 5px;
  }

  .separator {
    font-size: 24px;
    margin: 0 10px;
    border: 0px;
  }
  .searchTd {    
    border: 0px;
  }

  input::placeholder {
    color: gray;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100px;   
    margin-right: 10px; 
  }
  .withouteop {  padding: 8px; border: 1px solid #ccc;  border-radius: 4px; font-size: 14px; width: 150px;}
  .exchange {  padding: 8px; border: 1px solid #ccc;  border-radius: 4px; font-size: 14px; width: 80px; margin-left: 30px;}

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 8px;
    font-size: 12px;    
  }

th {
    position: sticky;
    top: 0;    
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;        
    padding: 5px 10px;    
    font-size : 12px; 
    font-weight: 600;    
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #e6e6e6;
  }
  .ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}
.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.okhead {
  background-color: #8dc7fd; font-weight:bold; /* Adjust the color as needed */
}
.nghead {
  background-color: #fdb08d; /* Adjust the color as needed */
} 
.tacttimehead {
  background-color: #fac069; font-weight:bold; /* Adjust the color as needed */
} 
.downtimehead {
  background-color: #569e56; font-weight:bold; /* Adjust the color as needed */
} 
.oeehead {
  background-color: #bbbd4a; /* Adjust the color as needed */
} 
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
.custom-file-upload {
  display: inline-block;  padding: 4px 12px;  cursor: pointer;
  background-color: #c49105;  color: #ffffff; margin-left: 10px;
  border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
}
.custom-file-upload:hover { background-color: #f3a359; }
.listuploadbutton {
  padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px;
  font-size: 14px; font-weight: 600; margin-left: 10px; cursor: pointer; transition: background-color 0.3s;        
}
.searchButton {
  padding: 5px 10px; background-color: #94a5b1; color: #000000; border: none;
  border-radius: 4px; font-size: 14px; font-weight: 600; margin-right: 3px;
  cursor: pointer; transition: background-color 0.3s;
}
</style>