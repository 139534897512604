<template>
  <div class="checkTarget">      
    <hr>
    <div class="parent">
      <div class="horizontal-form">
        <div>
          <select id="line" v-model="formInputs.line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
            <option>ALL</option>
          </select>  
        </div>
        <div>          
          <button class="searchButton" type="submit" @click="callSearchData">Search</button>                        
        </div>
        <div>            
          <button class="addButton" @click="showModal = true">+ Add</button>   
          <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
          <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
          <span class="fileName">{{ fileName }}</span>   
          <button class="upButton" @click="upExcel('maintenancechecktarget')">Upload</button>
          <button class="excelbutton" @click="downExcel">Down Load</button>                
          <button class="delButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="delAll(targetTableName, { line: formInputs.line })">ALL DEL</button>            
        </div>        
      </div>
    </div>
    <div class="table-container">      
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line_name">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Line name'" :key="index" >{{ row.line }}</td>
          <td v-if="header === 'Station Row'" :key="index" @click="editCell(targetTableName, row.id, 'station_row', $event)">{{ row.station_row }}</td>
          <td v-if="header === 'Station'" :key="index" @click="editCell(targetTableName, row.id, 'station', $event)">{{ row.station }}</td>
          <td v-if="header === 'Part'" :key="index" @click="editCell(targetTableName, row.id, 'part', $event)">{{ row.part }}</td>          
          <td v-if="header === 'Item'" :key="index" @click="editCell(targetTableName, row.id, 'item', $event)">{{ row.item }}</td>          
          <td v-if="header === 'Lower Limit'" :key="index" @click="editCell(targetTableName, row.id, 'lowerlimit', $event)">{{ row.lowerlimit }}</td>
          <td v-if="header === 'Standard Value'" :key="index" @click="editCell(targetTableName, row.id, 'standvalue', $event)">{{ row.standvalue }}</td>
          <td v-if="header === 'Upper Limit'" :key="index" @click="editCell(targetTableName, row.id, 'upperlimit', $event)">{{ row.upperlimit }}</td>
          <td v-if="header === 'Unit'" :key="index" @click="editCell(targetTableName, row.id, 'unit', $event)">{{ row.unit }}</td>
          <td v-if="header === 'Update Date'" :key="index" @click="editCell(targetTableName, row.id, 'updatedate', $event)">{{ row.updatedate }}</td>
          <td v-if="header === 'Acceptance Criteria'" :key="index" @click="editCell(targetTableName, row.id, 'acceptance', $event)">{{ row.acceptance }}</td>          
          <td class="instruction" v-if="header === 'Instruction'" :key="index" @click="editCell(targetTableName, row.id, 'instruction', $event)">{{ row.instruction }}</td>
          <td v-if="header === 'Cycle'" :key="index" @click="editCell(targetTableName, row.id, 'cycle', $event)">{{ row.cycle }}</td>
          <td v-if="header === 'Week'" :key="index" @click="editCell(targetTableName, row.id, 'week', $event)">{{ row.week }}</td>
          <td v-if="header === 'Cycle Year'" :key="index" @click="editCell(targetTableName, row.id, 'cycleyear', $event)">{{ row.cycleyear }}</td>
          <td v-if="header === 'Cycle Month'" :key="index" @click="editCell(targetTableName, row.id, 'cyclemonth', $event)">{{ row.cyclemonth }}</td>
          <td v-if="header === 'Cycle Day'" :key="index" @click="editCell(targetTableName, row.id, 'cycleday', $event)">{{ row.cycleday }}</td>
          <td v-if="header === 'Remark'" :key="index" @click="editCell(targetTableName, row.id, 'remark', $event)">{{ row.remark }}</td>  
          <td v-if="header === 'Row'" :key="index" @click="editCell(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
          <td v-if="header === 'Except'" :key="index" @click="editCell(targetTableName, row.id, 'except_row', $event)">{{ row.except_row }}</td>
        </template>   
      </tr>
    </tbody>     
    </table>
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
</template>

<script>
import Modal from "./mainTargetModal.vue";
import { onFileChange, uploadExcel, downloadExcel, editCellValue, deleteAll,} from "../../../utils.js"; 
import { useForm } from '../0_formStyle/form_checkTarget';

export default {         
  components: { Modal, }, 
  data() { return { excelFile: null, fileName: "", }; }, 

  methods: {
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
    downExcel() { downloadExcel(this.targetItem, "MaintenanceCheckTarget.xlsx"); },
    delAll(tableName, filters) { deleteAll(tableName, filters, this.callSearchData); },
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
        editCellValue(tableName, id, header, newValue, this.callSearchData);
        alert("You have to register changes to the check sheet history.");
      }
    },   
    canClick() {            
      const currentUser = this.$route.meta.currentUser;            
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;}
      else { return false;}
    }, 
  },   
  
  setup() {
    return useForm();
  }
}
</script>

<style scoped src="../0_formStyle/style_checkTarget.css"></style>

