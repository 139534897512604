import { reactive, computed } from 'vue';
import axios from 'axios';

const dbData = reactive({ data: [], });                

export const osCostSearchData = async (dateRange) => {
  if (!dateRange || !dateRange.year || !dateRange.month) {
    throw new Error('Invalid date range provided');
  }

  const formData = {
    tableName: "attendance",
    year: dateRange.year,
    month: dateRange.month,
  };

  try {
    const res = await axios.get('/api/yearMonth', { params: formData });
    if (res.data && Array.isArray(res.data.data)) {
      dbData.data = res.data.data;
    } else {
      dbData.data = [];
      console.error("Unexpected response format:", res.data);
    }
  } catch (error) {
    dbData.data = [];
    console.error("Error fetching target data:", error.message);
  }
};

const item = computed(() => {
  const workHourSum = {};
  const costSum = {};
  
  dbData.data.forEach(row => {
    const key = `${row.year}|${row.month}|${row.factory}|${row.line}`;
    if (!workHourSum[key]) {
      workHourSum[key] = 0;
      costSum[key] = 0;
    }
    workHourSum[key] += Number(row.worktime) / 60;
    costSum[key] += (Number(row.worktime) / 60) * Number(row.timecost);
  });       
  
  function sumByKeyIncludes(lines) {
      let hourSum = 0;
      let costSumValue = 0; 
      Object.keys(workHourSum).forEach(key => {
          const keyParts = key.split('|');
          const keyLine = keyParts[keyParts.length - 1]; // line 부분 추출
          lines.forEach(line => {
              if (keyLine === line) {
                  hourSum += workHourSum[key];                            
                  costSumValue += costSum[key]; 
              }
          });
      });
      return { hour: parseFloat(hourSum.toFixed(0)), cost: parseFloat(costSumValue.toFixed(0)) };
  }

  function sumForCommonLine(lines, factory, processes) {
      let hourSum = 0;
      let costSum = 0;

      dbData.data.forEach(row => {                    
          if (lines.includes(row.line) && row.factory === factory && processes.includes(row.process)) {
              hourSum += Number(row.worktime) / 60;
              costSum += Number(row.worktime) / 60 * Number(row.timecost);
          }
      });

      return { hour: hourSum.toFixed(0), cost: costSum.toFixed(0) };
  }  
  
  const bmw12v = sumByKeyIncludes(["BMW12V"]);
  const bmw48v = sumByKeyIncludes(["BMW48V"]);  
  const fca2 = sumByKeyIncludes(["FCA 2"]);
  const fcaBc = sumByKeyIncludes(["FCA FLEX BC"]);
  const fcaNe = sumByKeyIncludes(["FCA FLEX NE"]);
  const fcaPo = sumByKeyIncludes(["FCA FLEX PO"]);
  const fca67 = sumByKeyIncludes(["FCA FLEX 67"]);
  const fca45 = sumByKeyIncludes(["FCA FLEX 45"]);
  const ford1front = sumByKeyIncludes(["Ford 1 Front"]);
  const ford1rear = sumByKeyIncludes(["Ford 1 Rear"]);
  const ford2front = sumByKeyIncludes(["Ford 2 Front"]);
  const ford2rear = sumByKeyIncludes(["Ford 2 Rear"]);
  const ford3front = sumByKeyIncludes(["Ford 3 Front"]);
  const ford3rear = sumByKeyIncludes(["Ford 3 Rear"]);  
  const bt6 = sumByKeyIncludes(["BT6"]);  
  const ev2020_60 = sumByKeyIncludes(["EV2020 60kW", "EV2020 60FR", "EV2020 60RR", "EV2020 60"]);
  const ev2020_4060 = sumByKeyIncludes(["EV2020 4060kW", "EV2020 4060FR", "EV2020 4060RR", "EV2020 4060",]);  
  const tr2smt = sumByKeyIncludes(["TR2 SMT"]);                    
  const tr2crimping = sumByKeyIncludes(["TR2 Crimping"]);                    
  const tr2fpca = sumByKeyIncludes(["TR2 FPCA"]);                    
  const tr3smt = sumByKeyIncludes(["TR3 SMT"]);                    
  const tr3crimping = sumByKeyIncludes(["TR3 Crimping"]);                    
  const tr3fpca = sumByKeyIncludes(["TR3 FPCA"]);                    
  const evoHybrid = sumByKeyIncludes(["EVO Hybrid"]);                           
  const evo1 = sumByKeyIncludes(["EVO 1"]);                           
  const evo2 = sumByKeyIncludes(["EVO 2"]);
  const evo3 = sumByKeyIncludes(["EVO 3"]);

  const evoInkatom = sumByKeyIncludes(["VW EVO"]);

  const common1 = sumForCommonLine(["Common", "Production Logistic", "Technician"], 1, ["SAP", "Office", "Maintenance", "Production Logistics", "Scrap", "Packing", "Common", "Laboratory", "LQC", "IQC", "OQC", "Dispatch", "Logistics" ]);
  const common2 = sumForCommonLine(["Common", "Production Logistic", "Technician"], 2, ["SAP", "Office", "Maintenance", "Production Logistics", "Scrap", "Packing", "Common", "Laboratory", "LQC", "IQC", "OQC", "Dispatch", "Logistics" ]);
  
  return {    
    bmw12vHour: bmw12v.hour, bmw12vCost: bmw12v.cost,
    bmw48vHour: bmw48v.hour, bmw48vCost: bmw48v.cost,

    fca2Hour: fca2.hour, fca2Cost: fca2.cost,
    fcaBcHour: fcaBc.hour, fcaBcCost: fcaBc.cost,
    fcaNeHour: fcaNe.hour, fcaNeCost: fcaNe.cost,
    fcaPoHour: fcaPo.hour, fcaPoCost: fcaPo.cost,
    fca67Hour: fca67.hour, fca67Cost: fca67.cost,
    fca45Hour: fca45.hour, fca45Cost: fca45.cost,
    
    ford1frontHour: ford1front.hour, ford1frontCost: ford1front.cost,
    ford1rearHour: ford1rear.hour, ford1rearCost: ford1rear.cost,
    ford2frontHour: ford2front.hour, ford2frontCost: ford2front.cost,
    ford2rearHour: ford2rear.hour, ford2rearCost: ford2rear.cost,
    ford3frontHour: ford3front.hour, ford3frontCost: ford3front.cost,
    ford3rearHour: ford3rear.hour, ford3rearCost: ford3rear.cost,

    bt6Hour: bt6.hour, bt6Cost: bt6.cost,
    
    ev202060Hour: ev2020_60.hour, ev202060Cost: ev2020_60.cost,
    ev20204060Hour: ev2020_4060.hour, ev20204060Cost: ev2020_4060.cost,

    tr2smtHour: tr2smt.hour, tr2smtCost: tr2smt.cost,
    tr2crimpingHour: tr2crimping.hour, tr2crimpingCost: tr2crimping.cost,
    tr2fpcaHour: tr2fpca.hour, tr2fpcaCost: tr2fpca.cost,
    tr3smtHour: tr3smt.hour, tr3smtCost: tr3smt.cost,
    tr3crimpingHour: tr3crimping.hour, tr3crimpingCost: tr3crimping.cost,
    tr3fpcaHour: tr3fpca.hour, tr3fpcaCost: tr3fpca.cost,
    
    evoHybridHour: evoHybrid.hour , evoHybridCost: evoHybrid.cost,
    evo1Hour: evo1.hour, evo1Cost: evo1.cost,
    evo2Hour: evo2.hour, evo2Cost: evo2.cost,
    evo3Hour: evo3.hour, evo3Cost: evo3.cost,

    evoInkatomHour: evoInkatom.hour, evoInkatomCost: evoInkatom.cost,

    common1Hour: common1.hour, common1Cost: common1.cost, 
    common2Hour: common2.hour, common2Cost: common2.cost, 
  };
              
 /* return {                      
      //bmw12vHour: bmw12v.hour + (common1.hour/7), bmw12vCost: bmw12v.cost + (common1.cost/7),      
      bmw12vHour: bmw12v.hour, bmw12vCost: bmw12v.cost,      
      bmw48vHour: bmw48v.hour + (common1.hour/7), bmw48vCost: bmw48v.cost + (common1.cost/7),      

      fca2Hour: fca2.hour, fca2Cost: fca2.cost,      
      fcaBcHour: fcaBc.hour, fcaBcCost: fcaBc.cost,
      fcaNeHour: fcaNe.hour, fcaNeCost: fcaNe.cost,
      fcaPoHour: fcaPo.hour, fcaPoCost: fcaPo.cost,
      fca67Hour: fca67.hour, fca67Cost: fca67.cost,
      fca45Hour: fca45.hour, fca45Cost: fca45.cost,
      
      ford1frontHour: ford1front.hour, ford1frontCost: ford1front.cost,
      ford1rearHour: ford1rear.hour, ford1rearCost: ford1rear.cost,
      ford2frontHour: ford2front.hour, ford2frontCost: ford2front.cost,
      ford2rearHour: ford2rear.hour, ford2rearCost: ford2rear.cost,
      ford3frontHour: ford3front.hour + (common1.hour/7), ford3frontCost: ford3front.cost + (common1.cost/7),
      ford3rearHour: ford3rear.hour + (common1.hour/7), ford3rearCost: ford3rear.cost + (common1.cost/7),

      bt6Hour: bt6.hour, bt6Cost: bt6.cost,
      
      ev202060Hour: ev2020_60.hour + (common2.hour/8), ev202060Cost: ev2020_60.cost + (common2.cost/8),
      ev20204060Hour: ev2020_4060.hour + (common2.hour/8), ev20204060Cost: ev2020_4060.cost + (common2.cost/8),

      tr2smtHour: tr2smt.hour + (common1.hour/7), tr2smtCost: tr2smt.cost + (common1.cost/7),
      tr2crimpingHour: tr2crimping.hour + (common1.hour/7), tr2crimpingCost: tr2crimping.cost + (common1.cost/7),
      tr2fpcaHour: tr2fpca.hour + (common1.hour/7), tr2fpcaCost: tr2fpca.cost + (common1.cost/7),
      tr3smtHour: tr3smt.hour + (common2.hour/8), tr3smtCost: tr3smt.cost + (common2.cost/8),
      tr3crimpingHour: tr3crimping.hour + (common2.hour/8), tr3crimpingCost: tr3crimping.cost + (common2.cost/8),
      tr3fpcaHour: tr3fpca.hour + (common2.hour/8), tr3fpcaCost: tr3fpca.cost + (common2.cost/8),
      
      evoHybridHour: evoHybrid.hour + (evoInkatom.hour/3) + (common2.hour/8) , evoHybridCost: evoHybrid.cost + (evoInkatom.cost/3) + (common2.cost/8),
      evo1Hour: evo1.hour + (evoInkatom.hour/3) + (common2.hour/8), evo1Cost: evo1.cost + (evoInkatom.cost/3) + (common2.cost/8),
      evo2Hour: evo2.hour + (evoInkatom.hour/3) + (common2.hour/8), evo2Cost: evo2.cost + (evoInkatom.cost/3) + (common2.cost/8),
      evo3Hour: evo3.hour, evo3Cost: evo3.cost,  
  };*/
});     

export const itemArray = computed(() => {
  const items = item.value;   
  return [      
      { line: "BMW12V", hour: items.bmw12vHour, cost: items.bmw12vCost },
      { line: "BMW48V", hour: items.bmw48vHour, cost: items.bmw48vCost },
      { line: "FCA 2", hour: items.fca2Hour, cost: items.fca2Cost},
      { line: "FCA FLEX BC" , hour: items.fcaBcHour, cost: items.fcaBcCost },
      { line: "FCA FLEX PO" , hour: items.fcaPoHour, cost: items.fcaPoCost },
      { line: "FCA FLEX 67" , hour: items.fca67Hour, cost: items.fca67Cost },
      { line: "FCA FLEX NE" , hour: items.fcaNeHour, cost: items.fcaNeCost },
      { line: "FCA FLEX 45" , hour: items.fca45Hour, cost: items.fca45Cost },
      
      { line: "Ford 1 Front" , hour: items.ford1frontHour, cost: items.ford1frontCost },
      { line: "Ford 1 Rear" , hour: items.ford1rearHour, cost: items.ford1rearCost },
      { line: "Ford 2 Front" , hour: items.ford2frontHour, cost: items.ford2frontCost },
      { line: "Ford 2 Rear" , hour: items.ford2rearHour, cost: items.ford2rearCost },
      { line: "Ford 3 Front" , hour: items.ford3frontHour, cost: items.ford3frontCost },
      { line: "Ford 3 Rear" , hour: items.ford3rearHour, cost: items.ford3rearCost },

      { line: "BT6" , hour: items.bt6Hour, cost: items.bt6Cost },
      
      { line: "EV2020 60FR" , hour: items.ev202060Hour/2, cost: items.ev202060Cost/2 },
      { line: "EV2020 60RR" , hour: items.ev202060Hour/2, cost: items.ev202060Cost/2 },
      { line: "EV2020 4060FR" , hour: items.ev20204060Hour/2, cost: items.ev20204060Cost/2 },
      { line: "EV2020 4060RR" , hour: items.ev20204060Hour/2, cost: items.ev20204060Cost/2 },

      { line: "TR2 SMT" , hour: items.tr2smtHour, cost: items.tr2smtCost },
      { line: "TR2 Crimping" , hour: items.tr2crimpingHour, cost: items.tr2crimpingCost },
      { line: "TR2 FPCA" , hour: items.tr2fpcaHour, cost: items.tr2fpcaCost },

      { line: "TR3 SMT" , hour: items.tr3smtHour, cost: items.tr3smtCost },
      { line: "TR3 Crimping" , hour: items.tr3crimpingHour, cost: items.tr3crimpingCost },
      { line: "TR3 FPCA" , hour: items.tr3fpcaHour, cost: items.tr3fpcaCost },

      { line: "EVO Hybrid" , hour: items.evoHybridHour, cost: items.evoHybridCost },
      { line: "EVO 1" , hour: items.evo1Hour, cost: items.evo1Cost },
      { line: "EVO 2" , hour: items.evo2Hour, cost: items.evo2Cost },
      { line: "EVO 3" , hour: items.evo3Hour, cost: items.evo3Cost },

      { line: "Inkatom" , hour: items.evoInkatomHour, cost: items.evoInkatomCost },

      { line: "Common 1" , hour: items.common1Hour, cost: items.common1Cost },
      { line: "Common 2" , hour: items.common2Hour, cost: items.common2Cost },
  ];
});  