<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Asset</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr> 
                <td>     
                  <div class="form-group">
                    <label for="type">Image Upload:</label>                  
                    <div>           
                      <input type="file" @change="handleFileUpload('image')($event)" />
                      <button class="fileUploadButton" type="button" @click="fileUpload('image')">Image Upload</button>
                      {{ fileSaveStatus.image }}               
                    </div><br>
                  </div>  
                </td>
              </tr>
            </table>
            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>
                <td>                    
                  <input class="dateInput" type="text" v-model="searchDate.year"  />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />                  
                  </td>                    
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Name</td>
                <td>
                  <input class="itemInput" type="text" v-model="formInputs.name" />                  
                </td>   
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Asset Number</td>
                <td>
                  <input type="text" id="assetnumber" class="readWindow" v-model="formInputs.assetnumber" readonly />                  
                </td>  
              </tr>
            </table><br>    

            <table class="itemTable"> <!--Class Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Factory</td>                  
                <td>
                  <select class="factoryInput" v-model="formInputs.factory">                    
                    <option>LS1</option><option>LS2</option>
                  </select>                    
                </td>  
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Class 1</td>                  
                <td>
                  <select class="classInput" v-model="formInputs.classdescription1">
                    <option v-for="class1 in uniqueClass1" :key="class1">{{ class1 }}</option>                    
                  </select>                    
                </td>           
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Class 2</td>                  
                <td>
                  <select class="classInput" v-model="formInputs.classdescription2">
                    <option v-for="class2 in uniqueClass2" :key="class2">{{ class2 }}</option>
                  </select>                    
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Class 3</td>                  
                <td>
                  <select class="classInput" v-model="formInputs.classdescription3">
                    <option v-for="class3 in uniqueClass3" :key="class3">{{ class3 }}</option>                    
                  </select>                   
                </td>                    
              </tr>
            </table><br>   

            <table class="itemTable"> <!--Contents Table-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 0px 5px;">Contents</td>
                <td style="border: 0"><textarea id="contents" v-model="formInputs.contents" rows="10" cols="140"></textarea></td>
              </tr>
            </table><br>

            <table class="itemTable"> <!--information Talbe-->
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Detail Location</td>                  
                <td>
                  <input class="locationInput" type="text" id="location" v-model="formInputs.location" />                    
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">In charge</td>                  
                <td>
                  <input type="text" id="incharge" v-model="formInputs.incharge" />                   
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Remark</td>                  
                <td>
                  <input type="text" id="remark" v-model="formInputs.remark" />                
                </td>                    
              </tr>
            </table><br>            
                
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>    
import { reactive, ref, watch, computed, onMounted} from 'vue';  
import axios from 'axios';  
import { getCurrentDate, uploadFile, getColumnMapping, makeFormData, useFormInputs, fetchData } from "../utils.js";  
  
export default {                
  setup(props, { emit }) {   
      const dbTableName = "assetlist"
      const saveStatus = ref(''); 
      const isProcessing = ref(false);         
      const selectedFiles = reactive({ image: null, });     
      const fileSaveStatus = reactive({ image: '', });    
      const uploadType = 'assetList';    
      const uniqueClass1 = ref([]);  
      const uniqueClass2 = ref([]);  
      const uniqueClass3 = ref([]);   
      const assetList = reactive({ data: [], })      

      const searchDate = reactive({ ...getCurrentDate(), });

      const { formInputs, dataForm } = useFormInputs({ image: "", imagepath: "", serialnumber: ""});          

      const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

      const submitForm = async () => {     
        isProcessing.value = true; 
        saveStatus.value = 'Processing...';

        const columnMapping = getColumnMapping(searchDate, formInputs);  
        const formData = makeFormData(searchDate, formInputs, dbTableName, columnMapping); 
        
        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error'; 
        } finally {
          isProcessing.value = false; 
        }
        
        closeModal();
      };     

      const searchData = async () => {
        const formData = { tableName: 'assetlist' };
        try {
          const res = await axios.get('/api/targetList', { params: formData });
          assetList.data = res.data;
        } catch (error) {
          console.error("Error fetching target data:", error.message);
        }
      };

      onMounted(() => { searchData() });  

      const closeModal = () => { emit("onClose"); };
      
      const fileUpload = (type) => uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);              

      const loadClass1 = async () => { uniqueClass1.value = await fetchData('assetclass', 'classdescription1'); }; loadClass1();
      const loadClass2 = async () => { uniqueClass2.value = await fetchData('assetclass', 'classdescription2'); }; loadClass2();
      const loadClass3 = async () => { uniqueClass3.value = await fetchData('assetclass', 'classdescription3'); }; loadClass3();

      const loadCode1 = async (classdescription1) => { formInputs.classcode1 = await fetchData('assetclass', 'classcode1', [{ column: 'classdescription1', value: classdescription1 }]); };      
      watch(() => formInputs.classdescription1, (newClass) => { loadCode1(newClass); }); 
      const loadCode2 = async (classdescription2) => { formInputs.classcode2 = await fetchData('assetclass', 'classcode2', [{ column: 'classdescription2', value: classdescription2 }]); };
      watch(() => formInputs.classdescription2, (newClass) => { loadCode2(newClass); }); 
      const loadCode3 = async (classdescription3) => { formInputs.classcode3 = await fetchData('assetclass', 'classcode3', [{ column: 'classdescription3', value: classdescription3 }]); };
      watch(() => formInputs.classdescription3, (newClass) => { loadCode3(newClass); }); 

      const generateSerialNumber = () => {
        const base = `${formInputs.factory || ''}-${formInputs.classcode1 || ''}-${formInputs.classcode2 || ''}-${formInputs.classcode3 || ''}`;
        const existingItems = assetList.data.filter(item => item.assetnumber.startsWith(base));
        const serialNumbers = existingItems.map(item => parseInt(item.assetnumber.split('-').pop(), 10)).sort((a, b) => a - b);
        let newSerialNumber = 1;
        if (serialNumbers.length > 0) {
          newSerialNumber = serialNumbers[serialNumbers.length - 1] + 1;
        }
        return String(newSerialNumber).padStart(3, '0');
      };

      watch([() => formInputs.factory, () => formInputs.classcode1, () => formInputs.classcode2, () => formInputs.classcode3], () => {
        formInputs.serialnumber = generateSerialNumber();
      }, { immediate: true });

      const assetNumber = computed(() => {
        return `${formInputs.factory || ''}-${formInputs.classcode1 || ''}-${formInputs.classcode2 || ''}-${formInputs.classcode3 || ''}-${formInputs.serialnumber || ''}`;
      });
      watch(assetNumber, (newAssetNumber) => { formInputs.assetnumber = newAssetNumber; });

    return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing,
             handleFileUpload, fileUpload, fileSaveStatus, selectedFiles, searchDate, dataForm,
             uniqueClass1, uniqueClass2, uniqueClass3, assetNumber,
    };
  },     

  props: {  onSubmit: { type: Function,  required: true, },}, 
};

</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 1000px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px; margin-left: 10px; margin-right: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;    
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .classInput { margin-left: 5px; margin-right: 5px; width: 200px; } 
  .factoryInput { margin-left: 5px; margin-right: 5px; width: 70px; } 
  .locationInput { margin-left: 5px; margin-right: 5px; width: 350px; }       
  .dateInput { width: 50px; size: 8px; margin-left: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }      
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 10px; margin-right: 10px; padding: 0px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }
  .failurestandard {margin-left:10px; font-weight: bold};  
</style>