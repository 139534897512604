<template>
  <div class="container">
    <div class="search">
      <form @submit.prevent="sendDate()">
        <table class="searchTable">
          <tr>
            <td class="start">
              <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
              <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
              <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
            </td>
            <td class="separator">~</td>
            <td class="end">
              <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
              <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />
              <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
              <select class="withouteop" v-model="formInputs.use">
                <option>Without EOP</option><option>All</option>
              </select>
            </td>
            <td class="searchTd">
              <button type="submit" @click="callSearchData">Search</button>
            </td>
          </tr>
        </table>
      </form>
    </div>
    <table>
      <tr>
        <th rowspan="1"></th>
        <th colspan="3">Data</th>
        <th colspan="3" class="okhead">MTTR</th>
        <th colspan="3" class="tacttimehead">MTBF</th>
        <th colspan="3" class="downtimehead">Failure Rate</th>
      </tr>
      <tr>
        <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
      </tr>
      <tr v-for="(target, index) in filteredTargets" :key="target.name">
        <td><b>{{ target.name }}</b></td>
        <td>{{ rowData[index].workMinute }}</td>
        <td>{{ rowData[index].failureTime }}</td>
        <td>{{ rowData[index].failureCount }}</td>
        <td>{{ rowData[index].mttrTarget }}</td>
        <td class="okhead"><b>{{ rowData[index]?.actualMttr || 0 }}</b></td>
        <td :style="aS(rowData[index]?.achieveMttr / 100, rowData[index]?.workMinute)">
          <b>{{ rowData[index]?.achieveMttr === "No Failure" ? "No Failure" : rowData[index]?.achieveMttr + "%" }}</b>
        </td>
        <td>{{ rowData[index]?.mtbfTarget || 0 }}</td>
        <td class="tacttimehead"><b>{{ rowData[index]?.actualMtbf || 0 }}</b></td>
        <td :style="aS(rowData[index]?.achievMmtbf / 100, rowData[index]?.workMinute)">
          <b>{{ rowData[index]?.achievMmtbf === "No Failure" ? "No Failure" : rowData[index]?.achievMmtbf + "%" }}</b>
        </td>
        <td>{{ rowData[index]?.failureRateTarget || 0 }}</td>
        <td class="downtimehead"><b>{{ rowData[index]?.actualFailureRate || 0 }}</b></td>        
        <td :style="aS(rowData[index]?.achieveFailureRate / 100, rowData[index]?.workMinute)">
          <b>{{ rowData[index]?.achieveFailureRate || 0 }}%</b>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { createPeriodReportUtils, settingValue } from './periodReportUtils';
import { withoutFailureExceptTargets, tableNames, withoutEOPLines } from '../../lineData';

export default {
  setup() {
    const startYear = ref();
    const startMonth = ref();
    const startDay = ref();
    const endYear = ref();
    const endMonth = ref();
    const endDay = ref();
    const formInputs = reactive({ use: 'Without EOP' });
    const { state, fetchAllData, fetchTargets, fetchWorkList, calculateRowData, sums } = createPeriodReportUtils(tableNames);

    const headers = ['Line', 'Running Time', 'Failure Time', 'Count of Failure', 'Target', 'Actual', 'Achieve', 'Target', 'Actual', 'Achieve', 'Target', 'Actual', 'Achieve'];

    const filteredTargets = computed(() => {
      if (formInputs.use === 'Without EOP') {
        return withoutFailureExceptTargets.filter(target => withoutEOPLines && withoutEOPLines.includes(target.name));
      }
      return withoutFailureExceptTargets;
    });

    const rowData = computed(() => {
      return filteredTargets.value.map(target => calculateRowData(target.name));
    });

    const sendDate = async () => {
      const dateRange = {
        startYear: startYear.value,
        startMonth: startMonth.value,
        startDay: startDay.value,
        endYear: endYear.value,
        endMonth: endMonth.value,
        endDay: endDay.value
      };
      return dateRange;
    };

    const settingData = reactive({ data: [] });
    (async () => {
      try {
        const { settingData: setting } = await settingValue();
        settingData.data = setting.data;
      } catch (error) {
        console.error("Error initializing settings:", error.message);
      }
    })();

    const callSearchData = async () => {
      try {
        const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));
        const isReportEnabled = reportSetting && reportSetting.report === 1;
        if (!isReportEnabled) {
          alert('Report is currently disabled.');
          return;
        }

        const dateRange = await sendDate();
        
        await fetchTargets(dateRange);
        await fetchWorkList(dateRange);
        await fetchAllData(dateRange);
      } catch (error) {
        console.error("Report is currently disabled:", error.message);
      }
    };

    const aS = (value, workMinute) => {
      if (isNaN(value) || workMinute === 0) {
        return { backgroundColor: 'transparent' };
      } else if (value < 0.8499) {
        return { backgroundColor: 'rgba(255, 0, 0, 0.7)' };
      } else if (value < 0.9499) {
        return { backgroundColor: 'rgba(255, 255, 0, 0.3)' };
      } else {
        return { backgroundColor: 'rgba(144, 238, 144, 0.3)' };
      }
    };


    const targetItem = computed(() => {
      const items = {};
      state.targetList.forEach(d => {
        items[d.line] = d;
      });
      return items;
    });

    return {
      headers, rowData, sendDate, callSearchData, aS, filteredTargets, formInputs,
      startYear, startMonth, startDay, endYear, endMonth, endDay, sums, targetItem,
    };
  }
};
</script>

<style scoped>
 * {
  box-sizing: border-box;
}

.container {
  margin-top: 0px;
  padding-top: 0px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.search {
position: relative;
background-color: #ffffff; /* Set a background color for better visibility */  
padding: 0px; /* Add some padding for better appearance */
height: auto;
width: 800px;
}
.start,
.end {
  display: flex;
  flex-direction: row;    
  border: 0px;
}

.start input,
.end input {
  margin-right: 5px;
}

.separator {
  font-size: 24px;
  margin: 0 10px;
  border: 0px;
}
.searchTd {    
  border: 0px;
}

input::placeholder {
  color: gray;
}
label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

select,
input[type="number"],
input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100px;
}

button {
  padding: 5px 5px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

table {
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 8px;
  font-size: 12px;    
}

th {
  position: sticky;
  top: 0;    
  z-index: 10;
  background-color: rgb(230, 230, 230) ;
  border : 1px solid black;        
  text-align : center;        
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}

tr:hover {
  background-color: #e6e6e6;
}
.ok {
background-color: #94b7f8; /* Adjust the color as needed */
font-weight: bold;
}
.ng {
background-color: #fa7979; /* Adjust the color as needed */
font-weight: bold;
}
.ng_type {
background-color: #fdb08d; /* Adjust the color as needed */
}

.okhead {
background-color: #8dc7fd; /* Adjust the color as needed */
}
.nghead {
background-color: #fdb08d; /* Adjust the color as needed */
} 
.tacttimehead {
background-color: #fac069; /* Adjust the color as needed */
} 
.downtimehead {
background-color: #569e56; /* Adjust the color as needed */
} 
.oeehead {
background-color: #bbbd4a; /* Adjust the color as needed */
} 
</style>