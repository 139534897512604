<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Line Worker</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
                  <div class="form-group">
              <label for="employeenumber">Employee Number:</label>
              <input type="text" id="employeenumber" v-model="formInputs.employeenumber" />
            </div>
                  <div class="form-group">
                <label for="line">Line:</label>
                <select id="line" v-model="formInputs.line">
                  <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
                  <option></option>          
                </select>              
              </div>
              <div class="form-group">
                <label for="station">Station:</label>
                <select id="station" v-model="formInputs.station">
                  <option v-for="station in uniqueStations" :key="station">{{ station }}</option>                 
                  <option></option>
                </select>              
              </div>
              <div class="form-group date-time-group">
                <label>Work Start Date(Year/Month/Day):</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="formInputs.startyear"  />
                  <input type="text" id="month" v-model="formInputs.startmonth" />
                  <input type="text" id="day" v-model="formInputs.startday" />                  
                </div> 
              </div>  
              <div class="form-group">
                <label for="oscompany">Company:</label>
                <select id="oscompany" v-model="formInputs.oscompany" >
                   <option>MAX POWER</option><option>MEGA STAFF</option><option>ZYX</option><option>Inkatom</option>
                   <option>LSEVP</option><option></option>
                </select>
              </div>
              <div class="form-group">
                <label for="position">Position:</label>
                <select id="position" v-model="formInputs.position">
                  <option>Leader</option><option>Technician</option>
                  <option>Senior Operator</option><option>Junior Operator</option>
                  <option>Senior Inspector</option><option>Inspector</option>
                  <option>Logistics</option><option>Forklift</option><option>Truck</option>
                  <option>Maintenance</option>
                </select>
              </div>
              <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" id="name" v-model="formInputs.name" />
            </div>
            <div class="form-group">
              <label for="surname">Surname:</label>
              <input type="text" id="surname" v-model="formInputs.surname" />
            </div>
            </td>
            </tr>
            </table>
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}        
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch,  ref } from 'vue';  
  import axios from 'axios';   
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "lineworkerlist";   
        const uniqueLines = ref([]);   
        const uniqueStations = ref([]);   
        const saveStatus = ref(''); 
        const isProcessing = ref(false);                   
        const formInputs = reactive({   
          id: "", line: "", station: "", oscompany: "", employeenumber: "", position: "", name : "", surname : "",             
          startyear : "", startmonth : "", startday : "",
        });

        const submitForm = async () => {
          if (!formInputs.employeenumber || !formInputs.oscompany ) {
            alert("Please fill in all the fields: Employee Number, OS Company.");
            return;
          }

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {              
            line: "line",
            station: "station",
            startyear : "startyear",
            startmonth : "startmonth",
            startday : "startday",
            oscompany: "oscompany",
            employeenumber: "employeenumber",
            position: 'position',
            name : "name",
            surname : "surname",               
          }     

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {              
              line: formInputs.line,
              station: formInputs.station,              
              startyear: formInputs.startyear,
              startmonth: formInputs.startmonth,
              startday: formInputs.startday,
              oscompany : formInputs.oscompany,
              employeenumber : formInputs.employeenumber, 
              position: formInputs.position,             
              name : formInputs.name,
              surname : formInputs.surname,              
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData); 
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }

         closeModal();
        };
        const closeModal = () => {
          emit("onClose");
        };   
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id;
              formInputs.line = rowData.line;
              formInputs.station = rowData.station,              
              formInputs.startyear = rowData.startyear,   
              formInputs.startmonth = rowData.startmonth,   
              formInputs.startday = rowData.startday,   
              formInputs.oscompany = rowData.oscompany,
              formInputs.employeenumber = rowData.employeenumber,              
              formInputs.name = rowData.name,
              formInputs.surname = rowData.surname           

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

          const fetchLine = () => {
          const tableName = 'machinedata';         
          console.log("fetchmaterialname called with materialcode:", formInputs.line);
          axios
            .get('/api/where2', { params: { table_name: tableName } })
            .then((res) => {
              console.log('Response from /api/targetList:', res.data);       

              // Set the fetched materialname to formInputs.materialname
              if (res.data && res.data.length > 0) {
                const lines = res.data.map(item => item.line);
                uniqueLines.value = [...new Set(lines)];  // 중복 값을 제거                
              } 
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };  
        const fetchStation = () => {
          const tableName = 'machinedata';         
          console.log("fetchmaterialname called with materialcode:", formInputs.line);
          axios
            .get('/api/where2', { params: { conditions: [
                {column: 'line', value: formInputs.line}],   
                table_name: tableName } })
            .then((res) => {
              console.log('Response from /api/where:', res.data);       

              // Set the fetched materialname to formInputs.materialname
              if (res.data && res.data.length > 0) {
                const stations = res.data.map(item => item.station);
                uniqueStations.value = [...new Set(stations)];  // 중복 값을 제거하여 uniqueStations에 const할당
                
              } else {
                formInputs.station = "";
              }
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };  

      fetchLine();
      watch(() => formInputs.line, () => {fetchStation(); });

      //직원 번호에 따라 이름 서버에서 불러오는거 -시작-
      const fetchname = () => {
            const employeeTableName = 'employeedata';
            const columnName = 'employeenumber';
            console.log("fetchmaterialname called with materialcode:", formInputs.employeenumber);
            axios
              .get('/api/where', { params: { value: formInputs.employeenumber, table_name: employeeTableName, column_name: columnName } })
              .then((res) => {
                console.log('Response from /api/where:', res.data);       

                // Set the fetched materialname to formInputs.materialname
                if (res.data && res.data.length > 0) {
                  formInputs.oscompany = res.data[0].oscompany;                
                  formInputs.position = res.data[0].position;
                  formInputs.name = res.data[0].name;
                  formInputs.surname = res.data[0].surname;
                } else {
                  formInputs.name = "";
                  formInputs.surname = "";
                }
              })
              .catch((error) => {
                console.error('Error fetching target data:', error.message);
              });
          };  
          watch(() => formInputs.employeenumber, () => {
                fetchname();                
          });
          //직원 번호에 따라 이름 서버에서 불러오는거 -끝-
          

      return { dbTableName, formInputs, submitForm, closeModal, uniqueLines, uniqueStations, saveStatus, isProcessing          
            };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 500px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 80px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>