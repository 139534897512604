<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Machine Grade</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>        
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>
                  <div class="form-group">
                    <label for="year">Year(yyyy):</label>
                    <input type="number" id="year" v-model="formInputs.year" />
                  </div>
                  <div class="form-group">   
                    <label for="line">Line Name:</label>    
                    <select id="line" v-model="formInputs.line">
                      <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                      <option>TR2 BFA 2P</option>
                      <option>TR2 BFA 3P</option>
                      <option>TR3 BFA 2P</option>
                      <option>TR3 BFA 3P</option>                      
                      <option></option>
                    </select>   
                  </div>   
                  <div class="form-group">
                    <label for="grade">Grade:</label>
                    <input type="text" id="grade" v-model="formInputs.grade" readonly/>
                  </div>
                  <div class="form-group">
                    <label for="rowno">Row No</label>
                    <input id="rowno" v-model="formInputs.row_no" />
                  </div>
                  <div class="form-group">
                    <label for="remark">Remark</label>
                    <input id="remark" v-model="formInputs.remark" />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label for="status">Status of Machine Run:</label>
                    <select id="status" v-model="formInputs.status">
                      <option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>
                    4: Over 60%/Month<br>
                    3: 50~60%/Month<br>
                    2: 40~50%/Month<br>
                    1: Less 40%/Month
                  </div>
                  <div class="form-group">
                    <label for="possible">Is Production psssible in other machine?</label>
                    <select id="possible" v-model="formInputs.possible">
                      <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>
                    5: Production Down / No By-Pass Machine<br>
                    4: Have replacable machine but need lot of expense<br>
                    3: Production is impossible by other operation<br>
                    2: Have Back up line<br>
                    1: Production is possible in other operation
                  </div>
                  <div class="form-group">
                    <label for="trouble">How about the Effect on machine trouble?</label>
                    <select id="trouble" v-model="formInputs.trouble">
                      <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>
                    5: Effec to down of whole plant<br>
                    4: Effec to down of devision<br>
                    3: Effec to specified project<br>
                    2: Effec to specified line<br>
                    1: Effec to specified machine
                  </div>
                  <div class="form-group">
                    <label for="delivery">How abot the effect on delivery?</label>
                    <select id="delivery" v-model="formInputs.delivery">
                      <option>5</option><option>3</option><option>1</option><option></option>
                    </select>
                    5: Effec to delivery to customer in case of over 2 hours down<br>                 
                    3: Effec to delivery to customer in case of less 2 hours down<br>            
                    1: No problem
                  </div>
                  <div class="form-group">
                    <label for="reuse">Is the Re-Use possible?</label>
                    <select id="reuse" v-model="formInputs.reuse">
                      <option>4</option><option>3</option><option>1</option><option></option>
                    </select>
                    4: Impossible of re-use, too close to final operation<br>                    
                    3: Possible the re-use, but need to lots of expense<br>                    
                    1: possible the re-use with resonable expense
                  </div>
                  <div class="form-group">
                    <label for="goods">How about the effect to finished Goods?</label>
                    <select id="goods" v-model="formInputs.goods">
                      <option>5</option><option>3</option><option>1</option><option></option>
                    </select>
                    5: Several impacy<br>                    
                    3: Light impact<br>                    
                    1: No impact to finished Goods
                  </div>
                  <div class="form-group">
                    <label for="safety">How about the effect on operator safety?</label>
                    <select id="safety" v-model="formInputs.safety">
                      <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>
                    5: Occur of Machine damage/operator's safety<br>
                    4: Unsatisfy to related regulation<br>
                    3: Damage to neighbor machine and effect abnormal operation<br>
                    2: Just down of related operation<br>
                    1: No specified inpact
                  </div>
                  <div class="form-group">
                    <label for="environment">How about the effect on environment/safety?</label>
                    <select id="environment" v-model="formInputs.environment">
                      <option>5</option><option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>
                    5: Occur of Machine damage/operator's safety<br>
                    4: Unsatisfy to related regulation<br>
                    3: Damage to neighbor machine and effect abnormal operation<br>
                    2: Just down of related operation<br>
                    1: No specified inpact
                  </div>
                </td> 
                <td>
                  <div class="form-group">
                    <label for="mtbf">MTBF</label>
                    <select id="mtbf" v-model="formInputs.mtbf">
                      <option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>
                    4: Over 10 times/month<br>
                    3: 7 ~ 10 times/month<br>
                    2: 3 ~ 6 times/month<br>
                    1: 0 ~ 2 times/month                    
                  </div>
                  <div class="form-group">
                    <label for="meantime">Mean Failure Time Per Month</label>
                    <select id="meantime" v-model="formInputs.meantime">
                      <option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>                    
                    4: Average Failure time Over 3 hour<br>
                    3: Average Failure time 1.5 ~ 3 hour<br>
                    2: Average Failure time 0.5 ~ 1.4 hour<br>
                    1: Average Failure time Less 0.5 hour
                  </div>
                  <div class="form-group">
                    <label for="mttr">MTTR</label>
                    <select id="mttr" v-model="formInputs.mttr">
                      <option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>                    
                    4: Over 10 hours/month<br>
                    3: 5 ~ 10 hours/month<br>
                    2: 2 ~ 5 hours/month<br>
                    1: Less 2 hours/month
                  </div>
                  <div class="form-group">
                    <label for="expense">Expense Size</label>
                    <select id="expense" v-model="formInputs.expense">
                      <option>4</option><option>3</option><option>2</option><option>1</option><option></option>
                    </select>                    
                    4: Over 3400 PLN/month<br>
                    3: 1800 3400 PLN/month<br>
                    2: 1000 ~ 1799 PLN/month<br>
                    1: Less 1000 PLN/month
                  </div>
                  <div class="form-group">
                    <label for="howold">How old the Machine?</label>
                    <select id="howold" v-model="formInputs.howold">
                      <option>4</option><option>2</option><option>1</option><option></option>
                    </select>
                    4: Over 10 years<br>                                        
                    2: 5 ~ 10 years<br>
                    1: Less 5 years
                  </div>      
                </td>         
              </tr>
            </table>
            <button type="submit" class="saveButton" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>  
  import { reactive, ref, computed, watch} from 'vue';  
  import axios from 'axios';   
  import { fetchLine, } from "../../utils.js"; 
   
  export default {           
    setup(props, { emit }) {            
      const dbTableName = "machinegrade"      
      const uniqueLines = ref([]);   
          
      const formInputs = reactive({
        year: "", line: "", grade: "", status: "", possible: "", trouble: "", delivery: "",  reuse: "", goods: "", 
        safety: "", environment: "", mtbf: "", meantime: "", mttr: "", expense: "", howold: "",        
        remark: "", row_no: "", 
      });

      const score = computed(() => {
        const machineRelatedInputs = [formInputs.mtbf, formInputs.meantime, formInputs.mttr, formInputs.expense, formInputs.howold];
        const otherInputs = [formInputs.status, formInputs.possible, formInputs.trouble, formInputs.delivery, formInputs.reuse, formInputs.goods, formInputs.safety, formInputs.environment];
        
        // 기계 관련 입력 중 하나라도 누락된 경우 다른 점수만 계산
        const isAnyMachineRelatedInputMissing = machineRelatedInputs.some(input => input === '' || input === null);
        
        let totalScore;
        if (isAnyMachineRelatedInputMissing) {
          totalScore = otherInputs.reduce((total, current) => total + (parseInt(current, 10) || 0), 0);
          if (totalScore > 26) return "A";
          else if (totalScore >= 19 && totalScore <= 25) return "B";
          else return "C";
        } else {
          totalScore = [...machineRelatedInputs, ...otherInputs].reduce((total, current) => total + (parseInt(current, 10) || 0), 0);
          if (totalScore >= 40) return "A";
          else if (totalScore >= 30 && totalScore < 40) return "B";
          else return "C";
        }
      });

   
      watch(score, (newGrade) => { formInputs.grade = newGrade; });
      
      const submitForm = async () => { 

        const columnMapping = {
          year: 'year', line: 'line', grade: 'grade', status: 'status', possible: 'possible', trouble: 'trouble',
          delivery: 'delivery', reuse: 'reuse', goods: 'goods', safety: 'safety', environment: 'environment',
          mtbf: 'mtbf', meantime: 'meantime', mttr: 'mttr', expense: 'expense', howold: 'howold',
          remark: 'remark', row_no: 'row_no'
        }

        const formData = {
          tableName: dbTableName,
          columnMapping: columnMapping,
          values: {
            year : formInputs.year,
            line: formInputs.line,
            grade: formInputs.grade,
            status: formInputs.status,
            possible: formInputs.possible,
            trouble: formInputs.trouble,
            delivery: formInputs.delivery,
            reuse: formInputs.reuse,
            goods: formInputs.goods,
            safety: formInputs.safety,
            environment: formInputs.environment,
            mtbf: formInputs.mtbf,
            meantime: formInputs.meantime,
            mttr: formInputs.mttr,
            expense: formInputs.expense,
            howold: formInputs.howold,            
            remark: formInputs.remark,
            row_no : formInputs.row_no,              
          }
        };
         
        await axios.post('/api/post', formData);
        
        closeModal();
      };

      const closeModal = () => { emit("onClose"); };   

      const loadLines = async () => {
        const lines = await fetchLine();
        const excludedLines = ["Packing", "Production Logistic", "Maintenance", "Leader", "Technician", "Common", ];
        uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
      }; loadLines();

    return { dbTableName, formInputs, submitForm, closeModal, uniqueLines };

  },     

  props: { onSubmit: { type: Function, required: true, }, }, 

};
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 1000px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  .saveButton {
    background-color: #4CAF50; color: white; padding: 5px 20px; border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px; display: inline-block; transition-duration: 0.4s;
  }  

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  input[readonly] { background-color: #f3f3f3; color: #5c5d5f; font-weight: bold; }
</style>