import { reactive, computed } from 'vue';
import { calculateSums } from "../production/productionUtils.js";
import { getCurrentDate } from './utils.js';
import axios from 'axios';

const dbTableName = "packingpr"
const prTableData = reactive({ data: [], });
const currentDate = getCurrentDate();

export const packingQty = async  () => {    
    const formData = {
        tableName: dbTableName,
        values: {
            year: currentDate.year,
            month: currentDate.month,
            day: currentDate.day,
        }
    };

    try {              
        const response = await axios.get('/api/productionData', { params: formData });       
        prTableData.data = response.data;            
    } catch (error) {
        console.error("Error fetching data:", error.message);
    }
};

const item = computed(() => { return prTableData.data.map(d => {return { ...d };});}); 

const sumsTemplate = {
    evo_2p: 0, evo_3p: 0, ev2020frrr: 0, ev2020ffc: 0, bmw48v: 0, bmw12v: 0,
    ford_front: 0, ford_rear: 0
};

const shiftKeys = []; 
const modelKeys = [];

//const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys));

const sums = computed(() => {
    const result = calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys);   
    return result;
});

export const packingSums = key => {
    const value = sums.value.prtotalSums[key];    
    return value;
};
  