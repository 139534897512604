<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">LPA</h>        
      <form @submit.prevent="callSearchData()" class="searchBar">
        <div class="horizontal-form">  
          <div>
            <select id="team" v-model="team" placeholder="Team">
              <option>Production 1st</option><option>Production 2nd</option><option>Quality</option><option>Logistic</option>
              <option>HR/HSE/GA</option><option></option>
            </select>
          </div>
          <div class="start">
            <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
            <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
            <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
          </div>
          <div class="separator">~</div>
          <div class="end">
            <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
            <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />
            <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
          </div>
          <div>
            <input type="text" id="incharge" v-model="incharge" placeholder="In Charge" />
          </div>
          <div>
            <select id="progress" v-model="progress" placeholder="Progress">
              <option>With out Done</option><option>Not Yet</option><option>On going</option><option>Done</option><option></option>
            </select>
          </div>
          <div>
            <button class="searchButton" type="submit" >Search</button>                      
            <button class="addButton" @click="showModal = true">Add LPA</button>
            <button class="excelDownload" @click="downExcel">Down Load</button>                       
          </div>
        </div>      
      </form>
    </div>      
    <hr>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th>Fcatory</th><th @click="toggleDateColumns">Date ▶</th><th v-if="dateVisible">Month</th><th v-if="dateVisible">Week</th>
          <th>Auditor</th><th>Area</th><th>Area Leader</th><th>Description of the problem</th><th>Reoccurance</th>
          <th>Photos of<br>the problem</th><th>Containment actions</th><th>Root cause</th>
          <th>Corrective Action</th><th>Evidance of<br>corrective action</th><th>Category</th><th>In charge</th>
          <th>Due date</th><th>Progress</th><th>Modify</th><th>DEL</th>
        </tr>      
        <tr v-for="row in item" :key="row.id" :class="row.rowClass">               
          <td>{{ row.factory }}</td>       
          <td>{{ row.date }}</td>          
          <td v-if="dateVisible">{{ row.month }}</td>
          <td v-if="dateVisible">{{ row.week }}</td>           
          <td>{{ row.auditor }}</td>
          <td>{{ row.area }}</td>
          <td>{{ row.arealeader }}</td>
          <td class="content-cell">{{ row.problem }}</td>
          <td>{{ row.reoccurance }}</td>
          <td>            
            <img v-if="row.imageonepath && row.imageonepath !== '0'" :src="getImageUrl(row.imageonepath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imageonepath))">
            <template v-else></template>
          </td>
          <td class="content-cell">{{ row.containment }}</td>
          <td class="content-cell">{{ row.rootcause }}</td>
          <td class="content-cell">{{ row.corrective }}</td>          
          <td>            
            <img v-if="row.imagetwopath && row.imagetwopath !== '0'" :src="getImageUrl(row.imagetwopath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imagetwopath))">
            <template v-else></template>
          </td>          
          <td>{{ row.category }}</td>
          <td>{{ row.incharge }}</td>          
          <td>{{ row.duedate }}</td>          
          <td>{{ row.progress }}</td>          
          <td><button type="submit" @click="openSendModal(row)" class="moButton">Modify</button></td>                    
          <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="handleDeleteRow(dbTableName, row.id)">DEL</button></td>
      </tr>
    </table>   
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                 
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./lpaModal.vue";
import modifyModal from "./lpaMoModal.vue";
import { deleteRow, downloadExcel, } from '../utils.js'; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      canClick() {        
        const currentUser = this.$route.meta.currentUser;        
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2;}
        else { return false;}
      },        
      getImageUrl(filepath) { return filepath;},             
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); },
      handleDeleteRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
      downExcel() { downloadExcel(this.item, "LPA.xlsx"); },
    },

    components: { Modal, modifyModal, },     

    setup() {   
        const startYear = ref(); const startMonth = ref(); const startDay = ref();
        const endYear = ref(); const endMonth = ref(); const endDay = ref();
        const team = ref(); const incharge = ref(); const progress = ref("With out Done");        

        const dbTableName = 'lpa';
        const showModal = ref(false);
        const showMoModal = ref(false);                    
        const lpaData = reactive({ data: [], })  

        const searchData = async () => {    
          const formData = {        
              tableName: dbTableName,  
              startYear: startYear.value,
              startMonth: startMonth.value,
              startDay: startDay.value,
              endYear: endYear.value,
              endMonth: endMonth.value,
              endDay: endDay.value,              
              team: team.value,              
              incharge: incharge.value,
              progress: progress.value,
          };  

          try {
            const response = await axios.get('/api/lpa', { params: formData });
            const dataWithDday = response.data.map(item => {
              const dueDate = new Date(item.dueyear, item.duemonth - 1, item.dueday);
              const today = new Date();
              const timeDiff = dueDate - today;
              const dDay = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
              const date = `${item.day}.${item.month}.${item.year}`;
              const duedate = `${item.dueday}.${item.duemonth}.${item.dueyear}`;

              return { ...item, dday: dDay, date, duedate, };
            });

            lpaData.data = dataWithDday.sort((a, b) => {
              if (a.year !== b.year) return a.year - b.year;
              if (a.month !== b.month) return a.month - b.month;
              return a.day - b.day;
            });
          } catch (error) {
            console.error("Error fetching target data:", error);
          }
        };

       const item = computed(() => {
          return lpaData.data.map(item => {            
            const isDone = item.progress === 'Done';
            const dDay = isDone ? "" : calculateDday(item);            
            const date = `${item.day}.${item.month}.${item.year}`;
            const duedate = `${item.dueday}.${item.duemonth}.${item.dueyear}`;            
            const rowClass = isDone ? '' : getClassByDday(dDay);
            return { ...item, dday: dDay, date, duedate, rowClass };
          });
        });

        function calculateDday(item) {
          const dueDate = new Date(item.dueyear, item.duemonth - 1, item.dueday);
          const today = new Date();
          const timeDiff = dueDate - today;
          return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        }

        function getClassByDday(dDay) {
          if (dDay < 0) return 'redRow';
          if (dDay < 4) return 'yellowRow';
          return '';
        } 

        const callSearchData = () => { searchData(dbTableName); };     

        const dateVisible = ref(false);                    
        const toggleDateColumns = () => { dateVisible.value = !dateVisible.value; };        

        return { item, dbTableName, callSearchData, showModal, showMoModal, 
                 startYear, startMonth, startDay, endYear, endMonth, endDay, team, incharge, progress, 
                 toggleDateColumns, dateVisible,
        };        
    }
  }
</script>

<style scoped>
/* 기본 설정 */
.pagetitle { margin-left: 10px; font-size: 20px; font-weight: bold;}

.table-container { margin-left: 10px; margin-right: 10px;}
.table {
  width: 100%;  
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 그림자 효과 */
  background-color: #ffffff; /* 밝은 배경색 */
  margin-top: 10px;
}
.table th {
    padding: 0.75rem;  
    text-align: center; /* Center align text */
    border: 1px solid #808080;
    background-color: #dad8d8; /* 밝은 배경색 */
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }
.table td {
    padding: 0.75rem;
    text-align: center; /* Center align text */
    border: 1px solid #808080;
  }

.table td.content-cell { max-width: 600px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.effect-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.fileaddress-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
.table td.remark-cell { max-width: 200px; text-align: left; white-space: pre-wrap; overflow-wrap: break-word; }
/* 색상 행 */
.yellowRow { background-color: #ffffcc; }
.redRow { background-color: #ffcccc; }

/* 버튼 스타일 */
.searchButton {
  background-color: #b6b6b6; 
  color: rgb(0, 0, 0);
  padding: 5px 15px;
  margin: 4px 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.searchButton:hover { background-color: #696969;} 

.moButton {
    padding: 8px 5px; background-color: #0d4d01; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}

.delButton {
    padding: 8px 5px; background-color: #b6b6b6; color: black; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}

.addButton {
    background-color: #007396;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    transition: background-color 0.3s; margin-left : 10px; padding: 5px 15px;
}
.addButton:hover { background-color:  #3498db; }

.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 0px; margin-left: 10px; 
}
.fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }
.searchBar select,.searchBar input{ width : 120px; margin-right: 5px; padding : 5px; } 
.yellowRow { background-color: rgb(238, 238, 184);}
.redRow {  background-color: rgb(252, 167, 167);}
.excelDownload {
    background-color: #347003;  color: white;
    padding: 5px 15px; margin: 4px 2px; border: none; border-radius: 4px; cursor: pointer; font-weight: bold;
  }
.excelDownload :hover { background-color: #009625; }  
.horizontal-form { display: flex; flex-wrap: wrap; gap: 10px; align-items: center; margin-top: 10px; margin-bottom: 10px;}  
</style>