<template>
  <div class="modal-mask">
    <div class="modal-wrapper"> 
    <div class="modal-container">     
      <div class="modal-header">
        <h3>Add Item</h3>
        <button class="close-button" @click="closeModal">X</button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitForm">
          <table>
            <tr>
              <td>
                <div class="form-group">
                  <label for="value1">IP Address</label>
                  <input type="text" v-model="formInputs.ipaddress" />
                </div>
                <div class="form-group">
                  <label for="value1">Value 1</label>
                  <input type="text" v-model="formInputs.value1" />
                </div>
                <div class="form-group">
                  <label for="value2">Value 2:</label>
                  <input type="text" v-model="formInputs.value2" />
                </div>
                <div class="form-group">
                  <label for="value3">Value 3:</label>
                  <input type="text" v-model="formInputs.value3" />
                </div>         
                <div class="form-group">
                  <label for="option1">Option 1:</label>
                  <input type="text" v-model="formInputs.option1" />
                </div>              
                <div class="form-group">
                  <label for="option2">Option 2:</label>
                  <input type="text" v-model="formInputs.option2" />
                </div>              
              </td>
            </tr>
          </table>
          <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
          {{ saveStatus }}        
        </form>
      </div>                
      </div>
    </div>
  </div>
</template>
  
  <script>    
  import { ref,} from 'vue';  
  import axios from 'axios';  
  import { getColumnMapping, makeFormData, useFormInputs } from "../utils.js"; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "arduinodata"
        const saveStatus = ref(''); 
        const isProcessing = ref(false);         
        
        const { formInputs, dataForm } = useFormInputs();

        const submitForm = async () => {         

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = getColumnMapping(undefined, formInputs);  
          const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
         
         try {
            await axios.post('/api/post', formData); 
            saveStatus.value = 'Completed'; 
          } catch (error) {          
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };     
  
      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing, dataForm };

    },     

    props: { onSubmit: { type: Function, required: true, },      
     
  }, 


};
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex; justify-content: center; align-items: center;
    }
    
    .modal-wrapper { width: 100%;  max-width: 500px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
  </style>