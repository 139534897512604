<template>
    <div class="stocklist">            
      <div class="act">               
         <h class="pagetitle">Monthly Inventory Check</h>                  
      </div>
      <table class="searchBar">
        <tr>
          <td class="searchBarTd">
          <form @submit.prevent="sendDate()">             
            <label>Search : </label>
            <input id="year" v-model="searchDate.year" placeholder="Year"/>          
            <input id="month" v-model="searchDate.month" placeholder="Month" />                       
            <input id="day" v-model="searchDate.day" placeholder="Day"/>              
            <select id="factory" v-model="searchDate.factory" placeholder="Factory">
              <option>1</option><option>2</option>
            </select>              
            <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
            <button class="excelButton" @click="downExcel">Excel</button>               
          </form>
          </td>
          </tr>
        <tr>
        <td class="searchBarTd">                  
          <button class="modalButton" @click="showModal = true">+ Add</button>                
        </td>
        </tr>
      </table>                  
      <P></P>     
      <div class="tables">
      <table id="stockTable" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" class="sticky-header">{{ header }}</th>          
        </tr>          
          <tr v-for="row in inventory_item" :key="row.id">
            <template v-for="(header, index) in headers">
                <td v-if="header === 'Factory'" :key="index">{{ row.factory }}</td>
                <td v-if="header === 'Area'" :key="index">{{ row.area }}</td>
                <td v-if="header === 'Serial No'" :key="index">{{ row.serialno }}</td>
                <td v-if="header === 'Location Code'" :key="index">{{ row.locationcode }}</td>
                <td v-if="header === 'Location Name'" :key="index">{{ row.locationname }}</td>
                <td v-if="header === 'Material Code'" :key="index" >{{ row.materialcode }}</td>
                <td v-if="header === 'Material Name'" :key="index" >{{ row.materialname }}</td>
                <td v-if="header === 'Batch'" :key="index" >{{ row.batch }}</td>
                <td v-if="header === 'Quantity'" :key="index" >{{ row.quantity }}</td>
                <td v-if="header === 'Unit'" :key="index" >{{ row.unit }}</td>
                <td v-if="header === 'State'" :key="index" >{{ row.state }}</td>                
                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(inventoryTableName, row.id, 'remark', $event)">{{ row.remark }}</td>                
                <td v-if="header === 'Modify'" :key="index" ><button class="excelButton" type="submit" @click="openSendModal(row)">Modify</button></td> 
                <td v-if="header === 'DEL'" :key="index" class="del"><button class="delButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick(row)" type="submit" @click="delRow(inventoryTableName, row.id)">DEL</button></td>                
            </template>
          </tr>
        </table>    
        <br><br>
        <table class="locationTable">
          <tr><td class="locationHead">Location Code</td><td class="locationHead">Location Name</td></tr>
          <tr><td>1</td><td>TR3 SMT (Cleaning Room)</td></tr>
          <tr><td>2</td><td>TR3 Crimping</td></tr>
          <tr><td>3</td><td>TR3 FPCA</td></tr>
          <tr><td>4</td><td>TR3 FPCA Cabinet</td></tr>
          <tr><td>5</td><td>EV2020 4060kW</td></tr>
          <tr><td>6</td><td>EV2020 60kW</td></tr>
          <tr><td>7</td><td>EVO 1</td></tr>
          <tr><td>8</td><td>EVO 2</td></tr>
          <tr><td>9</td><td>EVO Hybrid</td></tr>
          <tr><td>10</td><td>Production Warehouse</td></tr>
          <tr><td>11</td><td>Finished Product in Warehouse</td></tr>
          <tr><td>12</td><td>Disassemble Room</td></tr>
          <tr><td>13</td><td>Scrap Room</td></tr>
          <tr><td>14</td><td>Near Red Cage</td></tr>   
        </table>
       </div>                        
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>   
      <MoModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                   
  </div>  
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";

import Modal from "./inventoryAdd.vue";
import MoModal from "./inventoryModify.vue";
import { deleteRow, downloadExcel,} from "../../utils.js"; 

export default {      
  components: { Modal, MoModal },    

  methods: {   
    canClick() {      
      const currentUser = this.$route.meta.currentUser;
      if (currentUser) {          
          return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 17 || currentUser.id === 18;
      } else {
          return false;
      }
    },
    openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },
    downExcel() { downloadExcel(this.inventory_item, "Inventory_Check.xlsx"); },
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
  },

  setup() {              
      const showModal = ref(false);    
      const showMoModal = ref(false);   
      const headers = ['Factory', 'Area', 'Serial No', 'Location Code', 'Location Name', 'Material Code', 'Material Name', 'Batch', 'Quantity', 'Unit', 'State', 'Remark', 'Modify', 'DEL'];         
      const searchDate = ref({ factory : '', year: '', month: '', day: '',});
      const sendDate = async () => {
          const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day, searchDate.value.factory,];
          return {sendDateData};             
      };   

      const inventoryTableName = "stockinventory"; const inventoryTableData = reactive({ data: [], });               
      
      
      const searchData = async (tableName) => {
        const sendDateData = await sendDate();
        const formData = {
          tableName: tableName,
          values: {                
            year: sendDateData.sendDateData[0],
            month: sendDateData.sendDateData[1],
            day: sendDateData.sendDateData[2],                            
            factory: sendDateData.sendDateData[3],        
          }
        };
        console.log('Requesting data with:', formData);

        axios.get('/api/stock', { params: formData })
          .then((res) => {                         
              switch (tableName) {
                case 'stockinventory': inventoryTableData.data = res.data;  break;                      
              }
          });
      };
      
      const callSearchData = () => { searchData(inventoryTableName); };     

      const inventory_item = computed(() => { return inventoryTableData.data.map(d => {return { ...d };});});  

      return { headers, showModal, showMoModal, callSearchData, sendDate, searchData, searchDate, inventory_item, inventoryTableName, };        
    },
}
</script>

<style scoped>
  .act { display: flex; padding: 10px 10px 5px 5px; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; } 
  .searchButton {
    padding: 5px 5px;  background-color: #94a5b1;
    color: #000000; border: none;
    border-radius: 4px; font-size: 14px; font-weight: 600;
    margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton {
    padding: 5px 5px; background-color: #10a802;
    color: #fff; border: none;
    border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer;
    transition: background-color 0.3s;
  }

  .modalButton {
    padding: 5px 5px;  background-color: #f3ac27;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 50px; margin-right: 10px;
    cursor: pointer; transition: background-color 0.3s;
  }
    
  .searchBar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .searchBar > *:not(:last-child) { margin-right: 5px; }

  .searchBar select, 
  .searchBar input
    { width : 80px;  margin-right: 5px; padding : 5px; }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  .searchBarTd { border : 0px; }

  td {
    border : 1px solid black;
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 15px;     
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 15px; 
  }

  .tables {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left : 20px;
    padding-right : 20px;
    height: 700px; /* Adjust this value according to your needs */
    overflow: auto;
  } 

  .custom-table {
    font-size: 10px;
    width: 80%;
    overflow-x: auto;
    white-space: nowrap;  } 

  .locationTable {
    font-size: 10px;
    width: 30%;
    overflow-x: auto;
    white-space: nowrap;
  }   
  .locationHead {    
    background-color: white; /* Or the background color of your header */    
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 15px; 
    font-weight: 600;
  }
 .delButton {
    padding: 4px 4px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }

</style>