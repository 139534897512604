<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Flow Chart Offset</h3>          
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">

            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">   
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line</td>
                <td>
                  <select v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option>TR2 Semi</option><option>TR3 Semi</option>
                  </select>
                </td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Name</td>
                <td style="text-align: left"><input type="text" v-model="formInputs.employeenumber" /></td>
              </tr>
            </table>
            <br>
            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">   
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line Offset X</td>
                <td style="text-align: left;"><input class="offsetInput2" type="text" v-model="formInputs.offsetX"/></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line Offset Y</td>
                <td style="text-align: left;"><input class="offsetInput2" type="text" v-model="formInputs.offsetY"/></td>                
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line Scale X</td>
                <td style="text-align: left;"><input class="offsetInput2" type="text" v-model="formInputs.scaleX"/></td>
                <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line Scale Y</td>
                <td style="text-align: left;"><input class="offsetInput2" type="text" v-model="formInputs.scaleY"/></td>                
              </tr>              
            </table><br>

            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref,} from 'vue';
import axios from 'axios';
import { getColumnMapping, makeFormData, fetchData, useFormInputs } from "../utils.js";

export default {
  props: { rowId: { type: Number, required: true } },
  setup(props, { emit }) {
    const dbTableName = "flowchart_offset";    
    const isProcessing = ref(false);
    const saveStatus = ref('');    
    const uniqueLines = ref([]);

    const { formInputs, dataForm } = useFormInputs({ id: "" });   

    const submitForm = async () => {
      isProcessing.value = true;
      saveStatus.value = 'Processing...';
      
      const columnMapping = getColumnMapping(undefined, formInputs);
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping);

      try {
        await axios.post('/api/post', formData);
        saveStatus.value = 'Completed';
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error';
      } finally {
        isProcessing.value = false;
      }
      closeModal();
    };

    const closeModal = () => { emit("onClose"); };
   
    const loadLines = async () => {
        uniqueLines.value = await fetchData('flowchart_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
      }; loadLines();      

    return {
      dbTableName,
      formInputs,
      submitForm,
      closeModal,
      isProcessing,
      uniqueLines,
      saveStatus,
      dataForm
    };
  }
};
</script>
      
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }     
    .modal-wrapper { width: 100%;  max-width: 1000px; }  
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }  
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
    .modal-header h3 { font-size: 1.2rem; }
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
        
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }  
     
    .itemInput { margin-left: 5px; margin-right: 5px; } 
    .projectInput { margin-left: 5px; margin-right: 5px; width: 500px; } 
    .supplierInput { margin-left: 5px; margin-right: 5px; width: 210px; } 
    .offsetInput2 { margin-left: 5px; margin-right: 5px; width: 80px; }     
    .revisionInput { width: 50px; size: 8px; margin-left: 10px; }          
    .contentsInput { margin-left: 5px; margin-right: 5px; width: 550px; } 
    .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
    .lineTable {width: 800px;}
    .lineTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; width: 100px !important;}
    .lineTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
    .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
    .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
   
    .disabled { background-color: #ccc; cursor: not-allowed; }
    .addLineButton {margin-right: 20px; padding: 5px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }    
    .uniquecode {color: red;}
</style>