<template>
  <div class="barcodePrint">
    <h3>Barcode Read</h3>
    <div>
      <div class="horizontal-form">        
        <div>
          <label>From :</label>
          <select v-model="fromLocation">
            <option>1</option><option>1-Corridor</option><option>2</option>
            <option>Ware House</option><option>IQC</option><option>Test</option><option>Sample</option><option></option>
          </select>
        </div>
        <div>
          <label>To :</label>
          <select v-model="toLocation">
            <option>1</option><option>1-Corridor</option><option>2</option>
            <option>Ware House</option><option>IQC</option><option>Test</option><option>Sample</option><option></option>
          </select>
        </div>        
      </div>
      <br>
      <table>
        <tr>
          <th>Barcode</th>
          <th>Code</th>
          <th>Name</th>
          <th>Batch</th>
          <th>Quantity</th>
          <th>Unit</th>
        </tr>
        <tr v-for="(item, index) in items" :key="index">
          <td class="inputTd">
            <input
              v-model="item.barcode"
              @input="() => decodeBarcode(item, index)"
              @keyup.enter="() => moveToNextField(index)"
              :ref="setBarcodeRef(index)"
            />
          </td>
       <!--   <td class="inputTd"><input v-model="item.barcode" @input="decodeBarcode(item, index)" /></td>-->
          <td class="inputTd">{{ item.materialcode }}</td>
          <td class="inputTd">{{ item.materialname }}</td>
          <td class="inputTd">{{ item.batch }}</td>
          <td class="inputTd">{{ item.quantity }}</td>
          <td class="inputTd">{{ item.unit }}</td>
        </tr>
      </table>            
    </div>
    <br>
    <div>
      <button type="submit" class="saveButton" @click.prevent="submitAll" :disabled="isProcessing">Save</button>
      {{ saveStatus }}
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick } from 'vue';
import axios from 'axios';
import { getCurrentDate } from "../../utils.js";

export default {
  setup() {
    const fromLocation = ref('');
    const toLocation = ref('');
    const isProcessing = ref(false);
    const saveStatus = ref('');
    const currentDate = getCurrentDate();

    const items = reactive(Array.from({ length: 10 }, () => ({
      barcode: '',
      materialcode: '',
      materialname: '',
      batch: '',
      quantity: '',
      unit: ''
    })));

    const barcodeRefs = reactive({});

    onMounted(() => {
      nextTick(() => {
        if (barcodeRefs[0]) {
          barcodeRefs[0].focus(); // 첫 번째 바코드 입력 필드에 포커스
        }
      });
    });

    function decodeBarcode(item, index) {
      const parts = item.barcode.split('-');
      if (parts.length === 4) {
        const [materialcode, batch, quantity, unit] = parts;
        items[index] = { ...items[index], materialcode, batch, quantity, unit };
        fetchMaterialName(index);
      }
    }    

    async function fetchMaterialName(index) {
      const code = items[index].materialcode;
      if (code) {
        try {
          const response = await axios.get(`/api/where`, { params: { value: code, table_name: 'materiallist', column_name: 'materialcode' } });
          items[index].materialname = response.data.length > 0 ? response.data[0].materialname : 'No Data';
        } catch (error) {
          console.error('Error fetching material name:', error);
          items[index].materialname = '';
        }
      }
    }

    function setBarcodeRef(index) {
      return el => {
        barcodeRefs[index] = el;
      };
    }

    const moveToNextField = (index) => {
      const nextIndex = index + 1;    
      if (barcodeRefs[nextIndex]) {
        barcodeRefs[nextIndex].focus();
      }
    };

    async function submitAll() {
      isProcessing.value = true;
      saveStatus.value = 'Processing...';

      const columnMapping = { 
        year: "year",
        month: "month",
        day: "day",
        fromlocation: "fromlocation",
        tolocation: "tolocation",
        materialcode: "materialcode",
        materialname: "materialname",
        unit: "unit",
        quantity: "quantity",
        batch: "batch",
        confirm: "confirm",
        sap: "sap",
        remark: "remark"
      }         

      try {
        const formData = items.map(item => ({
          year: currentDate.year,
          month: currentDate.month,
          day: currentDate.day,
          fromlocation: fromLocation.value,
          tolocation: toLocation.value,
          materialcode: item.materialcode,
          materialname: item.materialname,
          unit: item.unit,
          quantity: item.quantity,   
          batch: item.batch || "No Batch", // 'No Batch'가 기본값입니다.
          confirm: 'Not yet',
          sap: 'Not yet',
          remark: ''
        }));   

        await axios.post('/api/postAll', { tableName: 'stockmovement', columnMapping: columnMapping, values: formData });
        saveStatus.value = 'Completed';
        items.forEach(item => {
          for (let key in item) {
            item[key] = '';
          }
        });
      } catch (error) {
        console.error('Error:', error);
        saveStatus.value = 'Error';
      } finally {
        isProcessing.value = false;
      }
    }

    return {
      fromLocation,
      toLocation,
      items,
      isProcessing,
      saveStatus,
      decodeBarcode,
      submitAll,
      setBarcodeRef,
      moveToNextField,
      barcodeRefs
    };
  }
};
</script>

<style scoped>
  .barcodePrint {margin-left:20px;}
  label {margin-left: 10px; font-weight: bold;}
  table {width : 1200px;}
  th { border: 1px solid rgb(122, 122, 122); background-color: rgb(214, 214, 214); text-align: center; font-size: 15px; padding: 5px 10px; font-weight: bold;}
  .inputTd { border: 1px  solid rgb(122, 122, 122); padding: 5px 10px; text-align: center}
  .svgDiv { margin-left:200px; }
  .svgTr { border: 1px  solid rgb(122, 122, 122); text-align: center;}
  input { width: 400px; text-align: center; font-size: 30px; font-weight: bold;}
  select { width: 150px; text-align: left; font-size: 20px; font-weight: bold; margin-left:10px}
  .saveButton {
    background-color: #08b10a; color: rgb(0, 0, 0); padding: 30px 300px; border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 30px; font-weight: bold; display: inline-block; transition-duration: 0.4s;  
  }  
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .horizontal-form { margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>
