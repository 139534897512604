<template>
  <div class="failureTarget">      
    <hr>
    <div class="parent">
      <table class="searchTable"> 
        <tr>   
          <td class="searchTd">
            <div class="addbutton">        
              <button class="addButton" @click="showModal = true">+ Add</button>   
              <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                              
              <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
              <span class="fileName">{{ fileName }}</span>   
              <button class="excelUpload" @click="upExcel('failureclass')">Upload</button>
              <button class="excelDownload" @click="downExcel">Down Load</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    
    <div class="table-container">      
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line">     
        <template v-for="(header, index) in target_headers">            
          <td v-if="header === 'Class1'" :key="index" @click="editCell(targetTableName, row.id, 'class1', $event)">{{ row.class1 }}</td>
          <td v-if="header === 'Class2'" :key="index" @click="editCell(targetTableName, row.id, 'class2', $event)">{{ row.class2 }}</td>
          <td v-if="header === 'Class3'" :key="index" @click="editCell(targetTableName, row.id, 'class3', $event)">{{ row.class3 }}</td>
          <td v-if="header === 'Row'" :key="index" @click="editCell(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>   
          <td v-if="header === 'DEL'" :key="index"><button class="delButton" @click="delRow(targetTableName, row.id)">DEL</button></td>                 
        </template>   
      </tr>
    </tbody>     
    </table> <br>
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
</template>

<script>
import { reactive, computed, ref, onMounted } from 'vue';
import Modal from "./classMo.vue";
import axios from "axios";
import { onFileChange, uploadExcel, downloadExcel, editCellValue, deleteRow,} from "../../utils.js"; 

export default {         
    components: { Modal, },    
    data() { return { excelFile: null, fileName: "", }; }, 

    methods: {
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.targetItem, "FailureClass.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },  
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
           editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      }, 
    },

    setup() {   
        const targetTableName = "failureclass";            
        const target_headers = ['Class1', 'Class2', 'Class3', 'Row', 'DEL'];        
        const targetList = reactive({ data: [], })
        const showModal = ref(false); 

        const searchData = async () => {   
          const formData = {
            tableName: targetTableName,            
          };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => {
              console.log("Response from /api/target:", res.data);
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => { console.error("Error fetching target data:", error.message); });
          };

        const targetItem = computed(() => { return targetList.data.map(d => {return { ...d, }; });
        });     
        
        const callSearchData = () => { searchData(); };   

        onMounted(() => { searchData(); });   

        return { targetItem, targetList, target_headers, targetTableName, showModal, callSearchData, };        
    }
  }
</script>

<style scoped>
  .parent { display: flex; justify-content: space-between; }
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .searchTd { border: 0px !important;}   
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; margin-bottom: 10px; }   
  .table-container { overflow-x: hidden; position: relative;}
  .table-header,.table-body { overflow: hidden; width: 100%; }
  .table-body { max-height: 350px; overflow-y: auto;}
  .table {width: 100%; max-width: 100%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed; }
  .table th,.table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; text-align: center; }
  .table thead th { position: sticky; top: 0; z-index: 100; background-color: #f8f9fa; font-weight: 600;
                    text-transform: uppercase; letter-spacing: 1px; font-size: 0.9rem; vertical-align: bottom;
                    border-bottom: 2px solid #dee2e6;
  }
  .table tbody + tbody { border-top: 2px solid #dee2e6; }
  .table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075); }
  .addButton { padding: 5px 10px; background-color: #3498db; color: #fff; border: none;
               border-radius: 4px; font-size: 14px; font-weight: 600; margin-right: 30px; margin-left: 30px;
               margin-bottom: 10px; cursor: pointer; transition: background-color 0.3s;
  }
  .custom-file-upload {
    display: inline-block;  padding: 4px 12px;  cursor: pointer;
    background-color: #c49105;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }
  .custom-file-upload:hover { background-color: #f3a359; }
  .excelUpload {
      background-color: #3498db;  color: white;
      border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
      padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;    
  }
  .excelUpload:hover { background-color: #1e5173; }

  .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
    }
  .excelDownload :hover { background-color: #009625; }  
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; margin-bottom: 10px;
  }
  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }
  .rownostandard { width: 50%; max-width: 50%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed;}
  .rownostandard th, .rownostandard td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #000000; text-align: center; }
  .rownostandard thead th { position: sticky; top: 0; z-index: 100; background-color: #f8f9fa;
    font-weight: 600; text-transform: uppercase; letter-spacing: 1px; font-size: 0.9rem; vertical-align: bottom;
    border-bottom: 2px solid #000000;
  }
  .rownostandard tbody + tbody { border-top: 2px solid #000000; }
  .disabledButton { background-color: gray; cursor: not-allowed; }
</style>