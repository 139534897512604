<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <table>
            <tr>
              <td><h3>Add Production Result</h3></td>
              <td><button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button></td>              
              <td>{{ saveStatus }}</td>     
            </tr>
          </table>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
          <table>
            <tr>            
              <td>
              <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
              </div>                                   
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>            
            <div class="form-group">
              <label for="start_time">Start Time:</label>              
              <select id="start_time" v-model="formInputs.start_time">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>      
            </div>
            <div class="form-group">
              <label for="end_time">End Time:</label>
              <select id="end_time" v-model="formInputs.end_time" readonly class="readWindow">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>
            </div>
            <div class="form-group">
              <label for="work_minute">Work Time(minute):</label>
              <select id="work_minute" v-model="formInputs.work_minute">            
                <option>5</option><option>15</option><option>30</option><option>45</option><option>60</option>    
              </select>                            
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option>4P8S</option><option>4P7S</option><option>3P10S</option><option>3P8S</option>
              </select>              
            </div>
            <div class="form-group">
              <label for="target">Target:</label>
              <input type="number" id="target" v-model="formInputs.target" readonly class="readWindow" />
            </div>                
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>
            </td>
            <td>
            <div class="form-group">
              <label for="ng">NG:</label>
              <input class="ngmaintype" type="number" id="ng" v-model="formInputs.ng" />
            </div>
            <div class="form-group ng-dropdown-container"> <!--Laser Marking NG dropdown-->
              <label for="ng_lasermarkingsum">Laser Marking NG:</label><br>
              <input class="ngmaintype" type="number" id="ng_lasermarkingsum" v-model="formInputs.ng_lasermarkingsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('lasermarking')">▼</button>
              <div v-if="dropdownsVisible.lasermarking" class="ng-dropdown-menu">
                <div>
                  <label for="ng_lasermarkingheatstaking">Heat Staking:</label>
                  <input type="number" id="ng_lasermarkingheatstaking" v-model="formInputs.ng_lasermarkingheatstaking" />
                </div>
                <div>
                  <label for="ng_lasermarkingbusbar">Busbar:</label>
                  <input type="number" id="ng_lasermarkingbusbar" v-model="formInputs.ng_lasermarkingbusbar" />
                </div>
                <div>
                  <label for="ng_lasermarkingassembly">Assembly:</label>
                  <input type="number" id="ng_lasermarkingassembly" v-model="formInputs.ng_lasermarkingassembly" />
                </div>
                <div>
                  <label for="ng_lasermarkingqrcode">QR-CODE:</label>
                  <input type="number" id="ng_lasermarkingqrcode" v-model="formInputs.ng_lasermarkingqrcode" />
                </div>                
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Middle Insp.-->
              <label for="ng_middleinspsum">Middle Inspction NG:</label><br>
              <input class="ngmaintype" type="number" id="ng_middleinspsum" v-model="formInputs.ng_middleinspsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('middleinsp')">▼</button>
              <div v-if="dropdownsVisible.middleinsp" class="ng-dropdown-menu">
                <div>
                  <label for="ng_middleinsprivetheight">Rivet Height:</label>
                  <input type="number" id="ng_middleinsprivetheight" v-model="formInputs.ng_middleinsprivetheight" />
                </div>
                <div>
                  <label for="ng_middleinspdamagedrivet">Damaged Rivet:</label>
                  <input type="number" id="ng_middleinspdamagedrivet" v-model="formInputs.ng_middleinspdamagedrivet" />
                </div>
                <div>
                  <label for="ng_middleinspassembly">Assembly:</label>
                  <input type="number" id="ng_middleinspassembly" v-model="formInputs.ng_middleinspassembly" />
                </div>
                <div>
                  <label for="ng_middleinspqrcode">QR-CODE:</label>
                  <input type="number" id="ng_middleinspqrcode" v-model="formInputs.ng_middleinspqrcode" />
                </div>                
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--AOI NG.-->
              <label for="ng_aoingsum">AOI NG:</label><br>
              <input class="ngmaintype" type="number" id="ng_aoingsum" v-model="formInputs.ng_aoingsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('aoing')">▼</button>
              <div v-if="dropdownsVisible.aoing" class="ng-dropdown-menu">
                <div>
                  <label for="ng_aoingsoldering">Soldering:</label>
                  <input type="number" id="ng_aoingsoldering" v-model="formInputs.ng_aoingsoldering" />
                </div>
                <div>
                  <label for="ng_aoingheatstaking">Heat Staking:</label>
                  <input type="number" id="ng_aoingheatstaking" v-model="formInputs.ng_aoingheatstaking" />
                </div>
                <div>
                  <label for="ng_aoingriveting">Riveting:</label>
                  <input type="number" id="ng_aoingriveting" v-model="formInputs.ng_aoingriveting" />
                </div>
                <div>
                  <label for="ng_aoingqrcode">QR-CODE:</label>
                  <input type="number" id="ng_aoingqrcode" v-model="formInputs.ng_aoingqrcode" />
                </div>                
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Coating NG.-->
              <label for="ng_coatingngsum">Coating NG:</label><br>
              <input class="ngmaintype" type="number" id="ng_coatingngsum" v-model="formInputs.ng_coatingngsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('coatingng')">▼</button>
              <div v-if="dropdownsVisible.coatingng" class="ng-dropdown-menu">
                <div>
                  <label for="ng_coatingnginsufficientcoating">Insufficient Coating:</label>
                  <input type="number" id="ng_coatingnginsufficientcoating" v-model="formInputs.ng_coatingnginsufficientcoating" />
                </div>
                <div>
                  <label for="ng_coatingngcoatingheight">Coating Height:</label>
                  <input type="number" id="ng_coatingngcoatingheight" v-model="formInputs.ng_coatingngcoatingheight" />
                </div>
                <div>
                  <label for="ng_coatingngexcessivecoating">Excessive Coating:</label>
                  <input type="number" id="ng_coatingngexcessivecoating" v-model="formInputs.ng_coatingngexcessivecoating" />
                </div>
                <div>
                  <label for="ng_coatingngqrcode">QR-CODE:</label>
                  <input type="number" id="ng_coatingngqrcode" v-model="formInputs.ng_coatingngqrcode" />
                </div>                
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--EOL NG.-->
              <label for="ng_eolngsum">EOL NG:</label><br>
              <input class="ngmaintype" type="number" id="ng_eolngsum" v-model="formInputs.ng_eolngsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('eolng')">▼</button>
              <div v-if="dropdownsVisible.eolng" class="ng-dropdown-menu">
                <div>
                  <label for="ng_eolngopencircuit">Open Circuit:</label>
                  <input type="number" id="ng_eolngopencircuit" v-model="formInputs.ng_eolngopencircuit" />
                </div>
                <div>
                  <label for="ng_eolngoverresistance">Over Resistance:</label>
                  <input type="number" id="ng_eolngoverresistance" v-model="formInputs.ng_eolngoverresistance" />
                </div>
                <div>
                  <label for="ng_eolng2pconnectors">2P Connectors:</label>
                  <input type="number" id="ng_eolng2pconnectors" v-model="formInputs.ng_eolng2pconnectors" />
                </div>
                <div>
                  <label for="ng_eolngqrcode">QR-CODE:</label>
                  <input type="number" id="ng_eolngqrcode" v-model="formInputs.ng_eolngqrcode" />
                </div>                
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--LQC NG.-->
              <label for="ng_lqcngsum">LQC NG:</label><br>
              <input class="ngmaintype" type="number" id="ng_lqcngsum" v-model="formInputs.ng_lqcngsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('lqcng')">▼</button>
              <div v-if="dropdownsVisible.lqcng" class="ng-dropdown-menu">
                <div>
                  <label for="ng_lqcngbusbar">Busbar:</label>
                  <input type="number" id="ng_lqcngbusbar" v-model="formInputs.ng_lqcngbusbar" />
                </div>
                <div>
                  <label for="ng_lqcngcarrier">Carrier:</label>
                  <input type="number" id="ng_lqcngcarrier" v-model="formInputs.ng_lqcngcarrier" />
                </div>
                <div>
                  <label for="ng_lqcngbubblesincoating">Bubbles in coating:</label>
                  <input type="number" id="ng_lqcngbubblesincoating" v-model="formInputs.ng_lqcngbubblesincoating" />
                </div>
                <div>
                  <label for="ng_lqcngfpcb">FPCB:</label>
                  <input type="number" id="ng_lqcngfpcb" v-model="formInputs.ng_lqcngfpcb" />
                </div>                
                <div>
                  <label for="ng_lqcngconnector">Connector:</label>
                  <input type="number" id="ng_lqcngconnector" v-model="formInputs.ng_lqcngconnector" />
                </div>
                <div>
                  <label for="ng_lqcngdamagedrivets">Damaged Rivets:</label>
                  <input type="number" id="ng_lqcngdamagedrivets" v-model="formInputs.ng_lqcngdamagedrivets" />
                </div>
                <div>
                  <label for="ng_lqcngbottompins">Bottom Pins:</label>
                  <input type="number" id="ng_lqcngbottompins" v-model="formInputs.ng_lqcngbottompins" />
                </div>
                <div>
                  <label for="ng_lqcngqrcode">QR-CODE:</label>
                  <input type="number" id="ng_lqcngqrcode" v-model="formInputs.ng_lqcngqrcode" />
                </div>
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Final NG.-->
              <label for="ng_finalinspngsum">Final NG:</label><br>
              <input class="ngmaintype" type="number" id="ng_finalinspngsum" v-model="formInputs.ng_finalinspngsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('finalng')">▼</button>
              <div v-if="dropdownsVisible.finalng" class="ng-dropdown-menu">
                <div>
                  <label for="ng_finalinspngbusbar">Busbar:</label>
                  <input type="number" id="ng_finalinspngbusbar" v-model="formInputs.ng_finalinspngbusbar" />
                </div>
                <div>
                  <label for="ng_finalinspngcarrier">Carrier:</label>
                  <input type="number" id="ng_finalinspngcarrier" v-model="formInputs.ng_finalinspngcarrier" />
                </div>
                <div>
                  <label for="ng_finalinspngbubblesincoating">Bubbles in coating:</label>
                  <input type="number" id="ng_finalinspngbubblesincoating" v-model="formInputs.ng_finalinspngbubblesincoating" />
                </div>
                <div>
                  <label for="ng_finalinspngfpcb">FPCB:</label>
                  <input type="number" id="ng_finalinspngfpcb" v-model="formInputs.ng_finalinspngfpcb" />
                </div>                
                <div>
                  <label for="ng_finalinspngconnector">Connector:</label>
                  <input type="number" id="ng_finalinspngconnector" v-model="formInputs.ng_finalinspngconnector" />
                </div>
                <div>
                  <label for="ng_finalinspngdamagedrivets">Damaged Rivets:</label>
                  <input type="number" id="ng_finalinspngdamagedrivets" v-model="formInputs.ng_finalinspngdamagedrivets" />
                </div>
                <div>
                  <label for="ng_finalinspngbottompins">Bottom Pins:</label>
                  <input type="number" id="ng_finalinspngbottompins" v-model="formInputs.ng_finalinspngbottompins" />
                </div>
                <div>
                  <label for="ng_finalinspngqrcode">QR-CODE:</label>
                  <input type="number" id="ng_finalinspngqrcode" v-model="formInputs.ng_finalinspngqrcode" />
                </div>
              </div>
            </div>
            </td>
            <td>
              <div class="form-group ng-dropdown-container"> <!--Fake Lasermarking NG.-->
              <label for="fn_lasermarkingsum">Fake NG Laser Marking:</label><br>
              <input class="ngmaintype" type="number" id="fn_lasermarkingsum" v-model="formInputs.fn_lasermarkingsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fnlasermarking')">▼</button>
              <div v-if="dropdownsVisible.fnlasermarking" class="ng-dropdown-menu">
                <div>
                  <label for="fn_lasermarkingheatstaking">Heat Staking:</label>
                  <input type="number" id="fn_lasermarkingheatstaking" v-model="formInputs.fn_lasermarkingheatstaking" />
                </div>
                <div>
                  <label for="fn_lasermarkingqrcode">QR-CODE:</label>
                  <input type="number" id="fn_lasermarkingqrcode" v-model="formInputs.fn_lasermarkingqrcode" />
                </div>                      
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Fake Middle Insp NG.-->
              <label for="fn_middleinspsum">Fake NG Middle Insp:</label><br>
              <input class="ngmaintype" type="number" id="fn_middleinspsum" v-model="formInputs.fn_middleinspsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fnmiddleinsp')">▼</button>
              <div v-if="dropdownsVisible.fnmiddleinsp" class="ng-dropdown-menu">
                <div>
                  <label for="fn_middleinsprivetheight">Rivet Height:</label>
                  <input type="number" id="fn_middleinsprivetheight" v-model="formInputs.fn_middleinsprivetheight" />
                </div>
                <div>
                  <label for="fn_middleinspqrcode">QR-CODE:</label>
                  <input type="number" id="fn_middleinspqrcode" v-model="formInputs.fn_middleinspqrcode" />
                </div>                      
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Fake AOI-->
              <label for="fn_aoingsum">Fake NG AOI:</label><br>
              <input class="ngmaintype" type="number" id="fn_aoingsum" v-model="formInputs.fn_aoingsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fnaoing')">▼</button>
              <div v-if="dropdownsVisible.fnaoing" class="ng-dropdown-menu">
                <div>
                  <label for="fn_aoingsoldering">Soldering:</label>
                  <input type="number" id="fn_aoingsoldering" v-model="formInputs.fn_aoingsoldering" />
                </div>
                <div>
                  <label for="fn_aoingheatstaking">Heat Staking:</label>
                  <input type="number" id="fn_aoingheatstaking" v-model="formInputs.fn_aoingheatstaking" />
                </div>                      
                <div>
                  <label for="fn_aoingriveting">Riveting:</label>
                  <input type="number" id="fn_aoingriveting" v-model="formInputs.fn_aoingriveting" />
                </div>                      
                <div>
                  <label for="fn_aoingqrcode">QR-CODE:</label>
                  <input type="number" id="fn_aoingqrcode" v-model="formInputs.fn_aoingqrcode" />
                </div>                      
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Fake Coating-->
              <label for="fn_coatingsum">Fake NG Coating:</label><br>
              <input class="ngmaintype" type="number" id="fn_coatingsum" v-model="formInputs.fn_coatingsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fncoating')">▼</button>
              <div v-if="dropdownsVisible.fncoating" class="ng-dropdown-menu">
                <div>
                  <label for="fn_coatinginsufficientcoating">Insufficient Coating:</label>
                  <input type="number" id="fn_coatinginsufficientcoating" v-model="formInputs.fn_coatinginsufficientcoating" />
                </div>
                <div>
                  <label for="fn_coatingbubbles">Bubbles:</label>
                  <input type="number" id="fn_coatingbubbles" v-model="formInputs.fn_coatingbubbles" />
                </div>                      
                <div>
                  <label for="fn_coatingexcessivecoating">Excessive Coating:</label>
                  <input type="number" id="fn_coatingexcessivecoating" v-model="formInputs.fn_coatingexcessivecoating" />
                </div>                      
                <div>
                  <label for="fn_coatingqrcode">QR-CODE:</label>
                  <input type="number" id="fn_coatingqrcode" v-model="formInputs.fn_coatingqrcode" />
                </div>                      
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Fake EOL-->
              <label for="fn_eolsum">Fake NG EOL:</label><br>
              <input class="ngmaintype" type="number" id="fn_eolsum" v-model="formInputs.fn_eolsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fneol')">▼</button>
              <div v-if="dropdownsVisible.fneol" class="ng-dropdown-menu">
                <div>
                  <label for="fn_eolopencircuit">Open Circuit:</label>
                  <input type="number" id="fn_eolopencircuit" v-model="formInputs.fn_eolopencircuit" />
                </div>
                <div>
                  <label for="fn_eoloverresistance">Over Resistance:</label>
                  <input type="number" id="fn_eoloverresistance" v-model="formInputs.fn_eoloverresistance" />
                </div>                      
                <div>
                  <label for="fn_eol2pconnectors">2P Connectors:</label>
                  <input type="number" id="fn_eol2pconnectors" v-model="formInputs.fn_eol2pconnectors" />
                </div>                      
                <div>
                  <label for="fn_eolqrcode">QR-CODE:</label>
                  <input type="number" id="fn_eolqrcode" v-model="formInputs.fn_eolqrcode" />
                </div>                      
              </div>
            </div>
            <div class="form-group ng-dropdown-container"> <!--Fake LQC-->
              <label for="fn_lqcsum">Fake NG LQC:</label><br>
              <input class="ngmaintype" type="number" id="fn_lqcsum" v-model="formInputs.fn_lqcsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fnlqc')">▼</button>
              <div v-if="dropdownsVisible.fnlqc" class="ng-dropdown-menu">
                <div>
                  <label for="fn_lqcbusbars">Busbars:</label>
                  <input type="number" id="fn_lqcbusbars" v-model="formInputs.fn_lqcbusbars" />
                </div>                
                <div>
                  <label for="fn_lqcqrcode">QR-CODE:</label>
                  <input type="number" id="fn_lqcqrcode" v-model="formInputs.fn_lqcqrcode" />
                </div>                      
              </div>
            </div>          
            <div class="form-group ng-dropdown-container"> <!--Fake Final-->
              <label for="fn_finalinspsum">Fake NG Final:</label><br>
              <input class="ngmaintype" type="number" id="fn_finalinspsum" v-model="formInputs.fn_finalinspsum" />
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fnfinal')">▼</button>
              <div v-if="dropdownsVisible.fnfinal" class="ng-dropdown-menu">
                <div>
                  <label for="fn_finalinspbusbar">Busbars:</label>
                  <input type="number" id="fn_finalinspbusbar" v-model="formInputs.fn_finalinspbusbar" />
                </div>                
                <div>
                  <label for="fn_finalinspqrcode">QR-CODE:</label>
                  <input type="number" id="fn_finalinspqrcode" v-model="formInputs.fn_finalinspqrcode" />
                </div>                      
              </div>
            </div>  
            <div class="form-group ng-dropdown-container"> <!--Components NG-->
              <label for="nc_sum">Components NG:</label><br>     
              <input class="ngmaintype" type="number" id="nc_sum" v-model="formInputs.nc_sum" />         
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('componentng')">▼</button>
              <div v-if="dropdownsVisible.componentng" class="ng-dropdown-menu">
                <div>
                  <label for="nc_carrier">Carrier:</label>
                  <input type="number" id="nc_carrier" v-model="formInputs.nc_carrier" />
                </div>                
                <div>
                  <label for="nc_busbar">Busbar:</label>
                  <input type="number" id="nc_busbar" v-model="formInputs.nc_busbar" />
                </div>                      
                <div>
                  <label for="nc_connector">Connector:</label>
                  <input type="number" id="nc_connector" v-model="formInputs.nc_connector" />
                </div>
                <div>
                  <label for="nc_fpcb">FPCB:</label>
                  <input type="number" id="nc_fpcb" v-model="formInputs.nc_fpcb" />
                </div>
              </div>
            </div>          
            </td>
            <td>
            <div class="form-group">
              <label for="remark">Remark:</label>
              <input type="text" id="remark" v-model="formInputs.remark"  />
            </div>
            <div class="form-group">
              <label for="workorder">Work Order:</label>
              <input type="text" id="workorder" v-model="formInputs.workorder"  />
            </div>
            <div class="form-group">
              <label for="supervisor">Supervisor:</label>
              <input type="text" id="supervisor" v-model="formInputs.supervisor" />
            </div>
            <div class="form-group">
              <label for="leader">Leader:</label>
              <input type="text" id="leader" v-model="formInputs.leader" />
            </div>
            <div class="form-group">
              <label for="technician">Technician:</label>
              <input type="text" id="technician" v-model="formInputs.technician" />
            </div>
            <div class="form-group">
              <label for="shift_name">Shift Name:</label>
              <input type="text" id="shift_name" v-model="formInputs.shift_name" />
            </div>
            <div class="form-group">
              <label for="operator_qty">Operator Quantity:</label>
              <input type="number" id="operator_qty" v-model="formInputs.operator_qty" />
            </div>
            <div class="form-group">
              <label for="row_no">Row_no:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" readonly class="readWindow" />
            </div>    
            </td>
            </tr>
            </table>            
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                      
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref } from 'vue';  
  import axios from 'axios';  

  export default {    
    data() {
      return { //드랍다운 박스 별로 여기에 값을 넣어줘야 한다.
        dropdownsVisible: {
        lasermarking: false, middleinsp: false, aoing: false, coatingng: false, eolng: false, lqcng: false,
        finalng: false, fnlasermarking: false, fnmiddleinsp: false, fnaoing: false, fncoating: false,
        fneol: false, fnlqc: false, fnfinal: false, componentng: false,        
        },
      };
    },
    methods: {
      toggleDropdown(dropdown) {
        for (const key in this.dropdownsVisible) {
          this.dropdownsVisible[key] = key === dropdown ? !this.dropdownsVisible[key] : false;
        }
     },
     selectOption(option) {
        console.log("Selected option:", option);
        this.dropdownVisible = false;
      },
    },
    setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "ford1frpr"
        const lineName = "Ford 1 Front"
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          shift: "",
          start_time: "",
          end_time: "",
          work_minute: "",
          model: "",
          target: "",        
          ok: "",
          ng: "0",
          ng_lasermarkingsum: "0",
          ng_lasermarkingheatstaking: "0",
          ng_lasermarkingbusbar: "0",
          ng_lasermarkingassembly: "0",
          ng_lasermarkingqrcode: "0",
          ng_middleinspsum: "0",
          ng_middleinsprivetheight: "0",
          ng_middleinspdamagedrivet: "0",
          ng_middleinspassembly: "0",
          ng_middleinspqrcode: "0",
          ng_aoingsum: "0",
          ng_aoingsoldering: "0",
          ng_aoingheatstaking: "0",
          ng_aoingriveting: "0",
          ng_aoingqrcode: "0",
          ng_coatingngsum: "0",
          ng_coatingnginsufficientcoating: "0",
          ng_coatingngcoatingheight: "0",
          ng_coatingngexcessivecoating: "0",
          ng_coatingngqrcode: "0",
          ng_eolngsum: "0",
          ng_eolngopencircuit: "0",
          ng_eolngoverresistance: "0",
          ng_eolng2pconnectors: "0",
          ng_eolngqrcode: "0",
          ng_lqcngsum: "0",
          ng_lqcngbusbar: "0",
          ng_lqcngcarrier: "0",
          ng_lqcngbubblesincoating: "0",
          ng_lqcngfpcb: "0",
          ng_lqcngconnector: "0",
          ng_lqcngdamagedrivets: "0",
          ng_lqcngbottompins: "0",
          ng_lqcngqrcode: "0",
          ng_finalinspngsum: "0",
          ng_finalinspngbusbar: "0",
          ng_finalinspngcarrier: "0",
          ng_finalinspngbubblesincoating: "0",
          ng_finalinspngfpcb: "0",
          ng_finalinspngconnector: "0",
          ng_finalinspngdamagedrivets: "0",
          ng_finalinspngbottompins: "0",
          ng_finalinspngqrcode: "0",
          fn_lasermarkingsum: "0",
          fn_lasermarkingheatstaking: "0",
          fn_lasermarkingqrcode: "0",
          fn_middleinspsum: "0",
          fn_middleinsprivetheight: "0",
          fn_middleinspqrcode: "0",
          fn_aoingsum: "0",
          fn_aoingsoldering: "0",
          fn_aoingheatstaking: "0",
          fn_aoingriveting: "0",
          fn_aoingqrcode: "0",
          fn_coatingsum: "0",  
          fn_coatinginsufficientcoating: "0",
          fn_coatingbubbles: "0",
          fn_coatingexcessivecoating: "0",
          fn_coatingqrcode: "0",
          fn_eolsum: "0",
          fn_eolopencircuit: "0",
          fn_eoloverresistance: "0",
          fn_eol2pconnectors: "0",
          fn_eolqrcode: "0",
          fn_lqcsum: "0",
          fn_lqcbusbars: "0",
          fn_lqcqrcode: "0",
          fn_finalinspsum: "0",
          fn_finalinspbusbar: "0",
          fn_finalinspqrcode: "0",
          nc_sum: "0",
          nc_carrier: "0",
          nc_busbar: "0",
          nc_connector: "0",
          nc_fpcb: "0",
          remark: "",
          workorder: "",
          supervisor: "",
          leader: "",
          technician: "",
          shift_name: "",
          operator_qty: "",
          row_no: "",          
        });
        // -끝-

        //시작시간에 따라서 shift와 row_no 자동 입력 -시작-
        watch(() => formInputs.start_time, (newValue) => {
          const start_time_num = parseInt(newValue, 10);
          formInputs.shift = start_time_num >= 6 && start_time_num <= 17 ? 1 : 2;

          if (start_time_num >= 6 && start_time_num <= 24) {
            formInputs.row_no = start_time_num - 5;
          } else if (start_time_num >= 1 && start_time_num <= 5) {
            formInputs.row_no = start_time_num + 19;
          }
        });
        //끝

        //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -시작- 
        
        const targetProduct = reactive({ data: [], })

        const targetTableName = 'target';
        const columnName = 'line_name';
        axios
          .get('/api/where', { params: { value: 'Ford 1 Front', table_name: targetTableName, column_name: columnName } })
          .then((res) => {
            console.log('Response from /api/where:', res.data);
            targetProduct.data = res.data;
          })
          .catch((error) => {
            console.error('Error fetching target data:', error.message);
          });

        const productValue = computed(() => targetProduct.data[0]?.product || null);

        watch(
          [productValue, () => formInputs.work_minute],
          ([currentProductValue, currentWorkMinute]) => {
            if (currentProductValue && currentWorkMinute) {
              formInputs.target = parseFloat((currentProductValue * currentWorkMinute).toFixed(0));
            }
          }
        );        
                
       //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -끝- 
      
      //년.월.일을 지금 시작으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
         const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝

        //반복 입력되는 부분 불러오기 -시작- 

        const repeadtData = reactive({ data: [], })     
        const rdItem = computed(() => {return repeadtData.data.map(d => {return { ...d,};});});        
        watch(
            () => formInputs.shift,
            (newShift) => {
              if (newShift) {
                const repeatFormData = {
                  tableName: "repeatdata",
                  values: {
                    year: searchDate.year,
                    month: searchDate.month,
                    day: searchDate.day,
                    line_name: lineName,
                    shift: newShift,
                  },
                };
                axios
                  .get('/api/repeatData', { params: repeatFormData })
                  .then((res) => {
                    console.log('Response from /api/repeatData:', res.data);
                    repeadtData.data = res.data;
                  })
                  .catch((error) => {
                    console.error('Error fetching target data:', error.message);
                  });
              }
            }
          );

        watch(
          () => rdItem.value,
          (newRdItem) => {
            if (newRdItem && newRdItem.length > 0) {
              const lastItem = newRdItem[newRdItem.length - 1];
              formInputs.supervisor = lastItem.supervisor;
              formInputs.leader = lastItem.leader;
              formInputs.technician = lastItem.technician;
              formInputs.shift_name = lastItem.shiftname;
              formInputs.operator_qty = lastItem.operatorqty;
              formInputs.workorder = lastItem.workorder;
            }
          }
        );
        
       //반복 입력되는 부분 불러오기 -끝-      

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
            alert("Please fill in all the fields: year, month, day and model.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            shift: 'shift',
            start_time: 'start_time',
            end_time: 'end_time',
            work_minute: 'work_minute',
            model: 'model',
            target: 'target',
            ok: 'ok',
            ng: 'ng',
            ng_lasermarkingsum: 'ng_lasermarkingsum',
            ng_lasermarkingheatstaking: 'ng_lasermarkingheatstaking',
            ng_lasermarkingbusbar: 'ng_lasermarkingbusbar',
            ng_lasermarkingassembly: 'ng_lasermarkingassembly',
            ng_lasermarkingqrcode: 'ng_lasermarkingqrcode',
            ng_middleinspsum: 'ng_middleinspsum',
            ng_middleinsprivetheight: 'ng_middleinsprivetheight',
            ng_middleinspdamagedrivet: 'ng_middleinspdamagedrivet',
            ng_middleinspassembly: 'ng_middleinspassembly',
            ng_middleinspqrcode: 'ng_middleinspqrcode',
            ng_aoingsum: 'ng_aoingsum',
            ng_aoingsoldering: 'ng_aoingsoldering',
            ng_aoingheatstaking: 'ng_aoingheatstaking',
            ng_aoingriveting: 'ng_aoingriveting',
            ng_aoingqrcode: 'ng_aoingqrcode',
            ng_coatingngsum: 'ng_coatingngsum',
            ng_coatingnginsufficientcoating: 'ng_coatingnginsufficientcoating',
            ng_coatingngcoatingheight: 'ng_coatingngcoatingheight',
            ng_coatingngexcessivecoating: 'ng_coatingngexcessivecoating',
            ng_coatingngqrcode: 'ng_coatingngqrcode',
            ng_eolngsum: 'ng_eolngsum',
            ng_eolngopencircuit: 'ng_eolngopencircuit',
            ng_eolngoverresistance: 'ng_eolngoverresistance',
            ng_eolng2pconnectors: 'ng_eolng2pconnectors',
            ng_eolngqrcode: 'ng_eolngqrcode',
            ng_lqcngsum: 'ng_lqcngsum',
            ng_lqcngbusbar: 'ng_lqcngbusbar',
            ng_lqcngcarrier: 'ng_lqcngcarrier',
            ng_lqcngbubblesincoating: 'ng_lqcngbubblesincoating',
            ng_lqcngfpcb: 'ng_lqcngfpcb',
            ng_lqcngconnector: 'ng_lqcngconnector',
            ng_lqcngdamagedrivets: 'ng_lqcngdamagedrivets',
            ng_lqcngbottompins: 'ng_lqcngbottompins',
            ng_lqcngqrcode: 'ng_lqcngqrcode',
            ng_finalinspngsum: 'ng_finalinspngsum',
            ng_finalinspngbusbar: 'ng_finalinspngbusbar',
            ng_finalinspngcarrier: 'ng_finalinspngcarrier',
            ng_finalinspngbubblesincoating: 'ng_finalinspngbubblesincoating',
            ng_finalinspngfpcb: 'ng_finalinspngfpcb',
            ng_finalinspngconnector: 'ng_finalinspngconnector',
            ng_finalinspngdamagedrivets: 'ng_finalinspngdamagedrivets',
            ng_finalinspngbottompins: 'ng_finalinspngbottompins',
            ng_finalinspngqrcode: 'ng_finalinspngqrcode',
            fn_lasermarkingsum: 'fn_lasermarkingsum',
            fn_lasermarkingheatstaking: 'fn_lasermarkingheatstaking',
            fn_lasermarkingqrcode: 'fn_lasermarkingqrcode',
            fn_middleinspsum: 'fn_middleinspsum',
            fn_middleinsprivetheight: 'fn_middleinsprivetheight',
            fn_middleinspqrcode: 'fn_middleinspqrcode',
            fn_aoingsum: 'fn_aoingsum',
            fn_aoingsoldering: 'fn_aoingsoldering',
            fn_aoingheatstaking: 'fn_aoingheatstaking',
            fn_aoingriveting: 'fn_aoingriveting',
            fn_aoingqrcode: 'fn_aoingqrcode',
            fn_coatingsum: 'fn_coatingsum',
            fn_coatinginsufficientcoating: 'fn_coatinginsufficientcoating',
            fn_coatingbubbles: 'fn_coatingbubbles',
            fn_coatingexcessivecoating: 'fn_coatingexcessivecoating',
            fn_coatingqrcode: 'fn_coatingqrcode',
            fn_eolsum: 'fn_eolsum',
            fn_eolopencircuit: 'fn_eolopencircuit',
            fn_eoloverresistance: 'fn_eoloverresistance',
            fn_eol2pconnectors: 'fn_eol2pconnectors',
            fn_eolqrcode: 'fn_eolqrcode',
            fn_lqcsum: 'fn_lqcsum',
            fn_lqcbusbars: 'fn_lqcbusbars',
            fn_lqcqrcode: 'fn_lqcqrcode',
            fn_finalinspsum: 'fn_finalinspsum',
            fn_finalinspbusbar: 'fn_finalinspbusbar',
            fn_finalinspqrcode: 'fn_finalinspqrcode',
            nc_sum: 'nc_sum',
            nc_carrier: 'nc_carrier',
            nc_busbar: 'nc_busbar',
            nc_connector: 'nc_connector',
            nc_fpcb: 'nc_fpcb',
            remark: 'remark',
            workorder: 'workorder',
            supervisor: 'supervisor',
            leader: 'leader',
            technician: 'technician',
            shift_name: 'shift_name',
            operator_qty: 'operator_qty',
            row_no: 'row_no',            
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              shift: formInputs.shift,
              start_time: formInputs.start_time,
              end_time: formInputs.end_time,
              work_minute: formInputs.work_minute,
              model: formInputs.model,
              target: formInputs.target,        
              ok: formInputs.ok,
              ng: formInputs.ng,
              ng_lasermarkingsum: formInputs.ng_lasermarkingsum,
              ng_lasermarkingheatstaking: formInputs.ng_lasermarkingheatstaking,
              ng_lasermarkingbusbar: formInputs.ng_lasermarkingbusbar,
              ng_lasermarkingassembly: formInputs.ng_lasermarkingassembly,
              ng_lasermarkingqrcode: formInputs.ng_lasermarkingqrcode,
              ng_middleinspsum: formInputs.ng_middleinspsum,
              ng_middleinsprivetheight: formInputs.ng_middleinsprivetheight,
              ng_middleinspdamagedrivet: formInputs.ng_middleinspdamagedrivet,
              ng_middleinspassembly: formInputs.ng_middleinspassembly,
              ng_middleinspqrcode: formInputs.ng_middleinspqrcode,
              ng_aoingsum: formInputs.ng_aoingsum,
              ng_aoingsoldering: formInputs.ng_aoingsoldering,
              ng_aoingheatstaking: formInputs.ng_aoingheatstaking,
              ng_aoingriveting: formInputs.ng_aoingriveting,
              ng_aoingqrcode: formInputs.ng_aoingqrcode,
              ng_coatingngsum: formInputs.ng_coatingngsum,
              ng_coatingnginsufficientcoating: formInputs.ng_coatingnginsufficientcoating,
              ng_coatingngcoatingheight: formInputs.ng_coatingngcoatingheight,
              ng_coatingngexcessivecoating: formInputs.ng_coatingngexcessivecoating,
              ng_coatingngqrcode: formInputs.ng_coatingngqrcode,
              ng_eolngsum: formInputs.ng_eolngsum,
              ng_eolngopencircuit: formInputs.ng_eolngopencircuit,
              ng_eolngoverresistance: formInputs.ng_eolngoverresistance,
              ng_eolng2pconnectors: formInputs.ng_eolng2pconnectors,
              ng_eolngqrcode: formInputs.ng_eolngqrcode,
              ng_lqcngsum: formInputs.ng_lqcngsum,
              ng_lqcngbusbar: formInputs.ng_lqcngbusbar,
              ng_lqcngcarrier: formInputs.ng_lqcngcarrier,
              ng_lqcngbubblesincoating: formInputs.ng_lqcngbubblesincoating,
              ng_lqcngfpcb: formInputs.ng_lqcngfpcb,
              ng_lqcngconnector: formInputs.ng_lqcngconnector,
              ng_lqcngdamagedrivets: formInputs.ng_lqcngdamagedrivets,
              ng_lqcngbottompins: formInputs.ng_lqcngbottompins,
              ng_lqcngqrcode: formInputs.ng_lqcngqrcode,
              ng_finalinspngsum: formInputs.ng_finalinspngsum,
              ng_finalinspngbusbar: formInputs.ng_finalinspngbusbar,
              ng_finalinspngcarrier: formInputs.ng_finalinspngcarrier,
              ng_finalinspngbubblesincoating: formInputs.ng_finalinspngbubblesincoating,
              ng_finalinspngfpcb: formInputs.ng_finalinspngfpcb,
              ng_finalinspngconnector: formInputs.ng_finalinspngconnector,
              ng_finalinspngdamagedrivets: formInputs.ng_finalinspngdamagedrivets,
              ng_finalinspngbottompins: formInputs.ng_finalinspngbottompins,
              ng_finalinspngqrcode: formInputs.ng_finalinspngqrcode,
              fn_lasermarkingsum: formInputs.fn_lasermarkingsum,
              fn_lasermarkingheatstaking: formInputs.fn_lasermarkingheatstaking,
              fn_lasermarkingqrcode: formInputs.fn_lasermarkingqrcode,
              fn_middleinspsum: formInputs.fn_middleinspsum,
              fn_middleinsprivetheight: formInputs.fn_middleinsprivetheight,
              fn_middleinspqrcode: formInputs.fn_middleinspqrcode,
              fn_aoingsum: formInputs.fn_aoingsum,
              fn_aoingsoldering: formInputs.fn_aoingsoldering,
              fn_aoingheatstaking: formInputs.fn_aoingheatstaking,
              fn_aoingriveting: formInputs.fn_aoingriveting,
              fn_aoingqrcode: formInputs.fn_aoingqrcode,
              fn_coatingsum: formInputs.fn_coatingsum,
              fn_coatinginsufficientcoating: formInputs.fn_coatinginsufficientcoating,
              fn_coatingbubbles: formInputs.fn_coatingbubbles,
              fn_coatingexcessivecoating: formInputs.fn_coatingexcessivecoating,
              fn_coatingqrcode: formInputs.fn_coatingqrcode,
              fn_eolsum: formInputs.fn_eolsum,
              fn_eolopencircuit: formInputs.fn_eolopencircuit,
              fn_eoloverresistance: formInputs.fn_eoloverresistance,
              fn_eol2pconnectors: formInputs.fn_eol2pconnectors,
              fn_eolqrcode: formInputs.fn_eolqrcode,
              fn_lqcsum: formInputs.fn_lqcsum ,
              fn_lqcbusbars: formInputs.fn_lqcbusbars,
              fn_lqcqrcode: formInputs.fn_lqcqrcode,
              fn_finalinspsum: formInputs.fn_finalinspsum,
              fn_finalinspbusbar: formInputs.fn_finalinspbusbar,
              fn_finalinspqrcode: formInputs.fn_finalinspqrcode,
              nc_sum: formInputs.nc_sum,
              nc_carrier: formInputs.nc_carrier,
              nc_busbar: formInputs.nc_busbar,
              nc_connector: formInputs.nc_connector,
              nc_fpcb: formInputs.nc_fpcb,
              remark: formInputs.remark,
              workorder: formInputs.workorder,
              supervisor: formInputs.supervisor,
              leader: formInputs.leader,
              technician: formInputs.technician,
              shift_name: formInputs.shift_name,
              operator_qty: formInputs.operator_qty,
              row_no: formInputs.row_no,    
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };

      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-       

      watch(
        () => formInputs.start_time,
        (newStartTime) => {
          const startTime = parseInt(newStartTime, 10);
          if (startTime < 24) {
            formInputs.end_time = (startTime + 1).toString().padStart(2, '0');
          } else {
            formInputs.end_time = '01';
          }
        }
      );     

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, targetProduct, productValue,
               targetTableName, columnName, saveStatus, isProcessing };

    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      // currentDate: Object,
    },    
  };   
  </script>
    
  <style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 1000px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 50px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
  .disabled { background-color: #ccc; cursor: not-allowed; }
</style>