<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Check Item and Target</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>
                  <div class="form-group">
                <label for="line">Line:</label>
                <select id="line" v-model="formInputs.line">
                  <option v-for="line in uniqueLines" :key="line">{{ line }}</option>                  
                </select>              
              </div>
              <div class="form-group">
                <label for="station">Station:</label>
                <select id="station" v-model="formInputs.station">
                  <option v-for="station in uniqueStations" :key="station">{{ station }}</option>
                </select>              
              </div>
              <div class="form-group">
              <label for="stationrow">Station Row:</label>
              <input type="number" id="stationrow" v-model="formInputs.station_row" />
            </div>
              <div class="form-group">
                <label for="part">Part:</label>
                <input type="text" id="part" v-model="formInputs.part" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="item">Item:</label>
              <input type="text" id="item" v-model="formInputs.item" />
            </div>
            <div class="form-group">
              <label for="lowerlimit">Lower Limit:</label>
              <input type="text" id="lowerlimit" v-model="formInputs.lowerlimit" />
            </div>
            <div class="form-group">
              <label for="standvalue">Standard Value:</label>
              <input type="text" id="standvalue" v-model="formInputs.standvalue" />
            </div>
            <div class="form-group">
              <label for="upperlimit">Upper Limit:</label>
              <input type="text" id="upperlimit" v-model="formInputs.upperlimit" />
            </div>
            <div class="form-group">
              <label for="unit">Unit:</label>
              <input type="text" id="unit" v-model="formInputs.unit" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="cycle">Cycle:</label>
              <input type="text" id="cycle" v-model="formInputs.cycle" />
            </div>
            <div class="form-group">
              <label for="week">Week:</label>
              <input type="text" id="week" v-model="formInputs.week" />
            </div>
            <div class="form-group date-time-group">
              <label>Cycle Year/Month/Day:</label>
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="formInputs.cycleyear"  />
                <input type="text" id="month" v-model="formInputs.cyclemonth" />
                <input type="text" id="day" v-model="formInputs.cycleday" />                  
              </div> 
            </div>   
          </td>
          <td>
            <div class="form-group">
              <label for="updatedate">Update Date(yyyy/mm/dd):</label>
              <input type="text" id="updatedate" v-model="formInputs.updatedate" />
            </div>
            <div class="form-group">
              <label for="acceptance">Acceptance:</label>
              <input type="text" id="acceptance" v-model="formInputs.acceptance" />
            </div>
            <div class="form-group">
              <label for="instruction">Instruction:</label>
              <input type="text" id="instruction" v-model="formInputs.instruction" />
            </div>
            <div class="form-group">
              <label for="remark">Remark:</label>
              <input type="text" id="remark" v-model="formInputs.remark" />
            </div>
            <div class="form-group">
              <label for="row_no">Row No:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" />
            </div>
            </td>
            </tr>
            </table>
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>

<script>
import { useForm } from '../0_formStyle/form_checkTarget_modal';

export default {
  props: { onSubmit: { type: Function, required: true, }, },   
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>

<style scoped src="../0_formStyle/style_checkTarget_modal.css"></style>
    
    