<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Q-WALL</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>                                                                      
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>
          </td>
          <td>   
            <div class="form-group">
              <label for="ng">NG:</label>
              <input type="number" id="ng" v-model="formInputs.ng" />
            </div>
            <div class="form-group">
              <label for="ng_damagedcap">NG Damaged Cap:</label>
              <input type="number" id="ng_damagedcap" v-model="formInputs.ng_damagedcap"  />
            </div>
            <div class="form-group">
              <label for="ng_damagedflex">NG Damaged Flex:</label>
              <input type="number" id="ng_damagedflex" v-model="formInputs.ng_damagedflex" />
            </div>            
            <div class="form-group">
              <label for="ng_damagedtape">NG Damaged Tape:</label>
              <input type="number" id="ng_damagedtape" v-model="formInputs.ng_damagedtape" />
            </div>
            <div class="form-group">
              <label for="ng_visiblecopper">NG Visible Copper:</label>
              <input type="number" id="ng_visiblecopper" v-model="formInputs.ng_visiblecopper"  />
            </div>       
          </td>
          </tr>
          </table>    
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>
import { useForm } from '../../0_formStyle/modal/form_qw_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../../0_formStyle/modal/style_qw_modal.css"></style>