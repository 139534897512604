<template>
  <div class="controlPlan">        
    <form @submit.prevent="sendDate()">
      <div class="horizontal-form">                
        <div><b>Line</b></div>
        <div>            
          <select v-model="line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
          </select>                  
        </div>
        <div><b>Rev.No</b></div>
        <div>            
          <select v-model="revision">
            <option v-for="revision in uniqueRevision" :key="revision">{{ revision }}</option>
          </select>                  
        </div>
        <div>
            <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
        </div>
        <div>                        
            <button class="addButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showModal = true">+ Add Control Plan</button>
        </div>
        <div>                        
            <button class="addButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showLabelModal = true">+ Add Control Plan Label</button>
        </div>
        <div>
            <button class="historyButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showHistoryModal = true">History</button>
        </div>            
        <div>
            <button class="printButton" @click.prevent="printTable()">Print</button>    
        </div>   

      </div>      
    </form>
    <body>
    <div class="table-container" id="printArea">
      <table id="controlPlanTable">   
        <thead> 
        <tr class="widthTR">
          <td class="width80"></td>
          <td class="width90"></td>
          <td class="width90"></td>
          <td class="width50"></td>
          <td class="width100"></td>
          <td class="width100"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width300"></td>
          <td class="width100"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width50"></td>
          <td class="width100"></td>
          <td class="width100"></td>
          <td class="width100"></td>
          <td class="width100"></td>
        </tr>
        <tr>
          <td rowspan="2" colspan="2" class="lsevp">LSEV Poland</td>
          <td rowspan="2" colspan="7"><b class="title">Control Plan</b><br>{{ displaySopStatus }}</td>
          <td rowspan="2" colspan="6" class="alignLeft">Doc. No:<br>{{item.length > 0 ? item[0].doc_no : ''}}</td>          
          <td colspan="2" class="alignLeft">Revision date</td>
          <td colspan="2">{{item.length > 0 ? item[0].revision_date : ''}}</td>    
          <td class="no-print" colspan="2" rowspan="3"></td>                
        </tr>
        <tr>                    
          <td colspan="2" class="alignLeft">Revision</td>
          <td colspan="2">{{item.length > 0 ? item[0].revision : ''}}</td>       
        </tr>
        <tr>
          <td colspan="2" class="alignLeft">Part Number/Revision :<br>{{item.length > 0 ? item[0].partnumber : ''}}</td>
          <td colspan="7" class="alignLeft">Project Name :<br>{{item.length > 0 ? item[0].projectname : ''}}</td>
          <td colspan="6" class="alignLeft">Technical Approval of Customer<br>/Date(if required)<br>{{item.length > 0 ? item[0].technical : ''}}</td>
          <td colspan="4" class="alignLeft">Approver<br>{{item.length > 0 ? item[0].approver : ''}}</td>
        </tr>
        <tr>
          <td colspan="2" class="alignLeft">Part Name :<br>{{item.length > 0 ? item[0].partname : ''}}</td>
          <td colspan="7" class="alignLeft">MDT Members :<br>{{item.length > 0 ? item[0].mdt : ''}}</td>
          <td colspan="6" class="alignLeft">Quality Approval of Customer<br>/Date(if required)<br>{{item.length > 0 ? item[0].quality : ''}}</td>
          <td colspan="4" class="alignLeft">Review<br>{{item.length > 0 ? item[0].review : ''}}</td>
          <td colspan="2" class="no-printLableModify">Label Modify</td>                   
        </tr>        
        <tr>
          <td colspan="2" class="alignLeft">Supplier/Factory<br>{{item.length > 0 ? item[0].supplier : ''}}</td>
          <td colspan="2" class="alignLeft">Supplier Code<br>{{item.length > 0 ? item[0].suppliercode : ''}}</td>
          <td colspan="5" class="alignLeft">Supplier/Approved date<br>{{item.length > 0 ? item[0].supplierdate : ''}}</td>
          <td colspan="6" class="alignLeft">Others(if required)<br>{{item.length > 0 ? item[0].others : ''}}</td>
          <td colspan="4" class="alignLeft">Prepared by<br>{{item.length > 0 ? item[0].prepared : ''}}</td>
          <td colspan="2" class="no-print"><button type="submit" @click="openSendLabelModal(item[0])" class="moButton">Label Modify</button></td>          
        </tr> 
       <!-- </thead>   
        <thead class="sticky-header">   -->
        <tr>
          <th rowspan="3">Parts/<br>Process No.</th>
          <th rowspan="3">Process Name/<br>Operation Description</th>
          <th rowspan="3">Machine/<br>Device/<br>JIG/Tool</th>
          <th colspan="3">Characteristics</th>
          <th rowspan="3">CTP</th>
          <th rowspan="3">CTQ</th>
          <th colspan="10">Methods</th>
          <th rowspan="3">Reaction<br>Plan</th>
          <th rowspan="3" class="no-print">Modify</th>
          <th rowspan="3" class="no-print">Row_no</th>
        </tr>
        <tr>
          <th rowspan="2">No.</th>
          <th rowspan="2">Product</th>
          <th rowspan="2">Process</th>
          <th rowspan="2">Product/Process/<br>Specification/Tolerance</th>
          <th rowspan="2">Evaluation/<br>Measurement<br>Technique</th>
          <th colspan="2">Sampling</th>
          <th colspan="5">Department</th>
          <th rowspan="2">Control<br>Method</th>
        </tr>
        <tr>
          <th>Size</th>
          <th>Freq</th>
          <th>P/P</th>
          <th>P</th>
          <th>T</th>
          <th>TPM</th>
          <th>Q</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="group in groupedData" :key="group.process">
          <tr v-for="(item, index) in group.data" :key="item.id">
            <td v-if="index === 0" :rowspan="group.data.length">{{ group.parts }}<br>{{ group.process }}</td>
            <td v-if="index === 0" :rowspan="group.data.length">{{ group.process_name }}</td>            
            <td v-if="shouldShowMachineCell(group.data, index)" :rowspan="getMachineRowspan(group.data, index)">{{ item.machine }}</td>
            <td v-else-if="!shouldShowMachineCell(group.data, index)" class="rowSpanCell"></td>
            <td>{{ index + 1 }}</td>            
            <td>{{ item.char_product }}</td>
            <td>{{ item.char_process }}</td>
            <td>{{ item.ctp }}</td>
            <td>{{ item.ctq }}</td>
            <td class="contentsTD">{{ item.contents }}</td>
            <td>{{ item.evaluation }}</td>
            <td>{{ item.sampling_size }}</td>
            <td>{{ item.sampling_freq }}</td>
            <td>{{ item.department === 'P/P' || item.department2 === 'P/P' ? 'O' : '' }}</td>
            <td>{{ item.department === 'P' || item.department2 === 'P' ? 'O' : '' }}</td>
            <td>{{ item.department === 'T' || item.department2 === 'T' ? 'O' : '' }}</td>
            <td>{{ item.department === 'TPM' || item.department2 === 'TPM' ? 'O' : '' }}</td>
            <td>{{ item.department === 'Q' || item.department2 === 'Q' ? 'O' : '' }}</td>
            
            <td v-if="shouldShowControlmethodCell(group.data, index)" :rowspan="getControlmethodRowspan(group.data, index)">{{ item.controlmethod }}</td>
            <td v-else-if="!shouldShowControlmethodCell(group.data, index)" class="rowSpanCell"></td>
            
            <td v-if="shouldShowReactionCell(group.data, index)" :rowspan="getReactionRowspan(group.data, index)">{{ item.reaction }}</td>
            <td v-else-if="!shouldShowReactionCell(group.data, index)" class="rowSpanCell"></td>
            <td class="no-print"><button type="submit" @click="openSendModal(item)" class="moButton">Modify</button></td>            
            <td class="no-print" @click="editCell(dbTableName, item.id, 'row_no', $event)">{{ item.row_no }}</td>            
          </tr>
        </template>
        </tbody>
      </table>
    </div> 
    </body>   
    <Modal v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    <ModalLabel v-if="showLabelModal" @onClose="showLabelModal = false; callSearchData();"/> 
    <ModalMoLabel v-if="showLabelMoModal" :row-id="selectedRowId" @onClose="showLabelMoModal = false; callSearchData();"/> 
    <historyModal v-if="showHistoryModal" :line="line" @onClose="showHistoryModal = false; "/> 
  </div>  
</template>

<script>
import { ref, reactive, computed, watch  } from 'vue';
import axios from 'axios';
import Modal from './controlPlanModal.vue';
import modifyModal from './controlPlanMoModal.vue';
import ModalLabel from './controlPlanLabelModal.vue';
import ModalMoLabel from './controlPlanLabelMoModal.vue';
import historyModal from './controlPlanHistoryModal.vue';
import { deleteRow, fetchData, editCellValue} from "../utils.js"; 

export default {       
  methods: {
    openSendModal(item) { this.selectedRowId = item.id; this.showMoModal = true; },        
    openSendLabelModal(item) { this.selectedRowId = item.id; this.showLabelMoModal = true; },      
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    canClick() {          
      const currentUser = this.$route.meta.currentUser;          
      if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 32 }
      else { return false;}
    },
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    }, 
  
    printTable() {
      const printContents = document.getElementById("printArea").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;                 
      window.print();
      document.body.innerHTML = originalContents;      
      window.location.reload(); 
    }, //test1

    shouldShowMachineCell(data, index) { if (index === 0) return true; return data[index].machine !== data[index - 1].machine;},
    getMachineRowspan(data, index) { let rowspan = 1; 
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].machine === data[index].machine) { rowspan++; } else { break;  }
      }      
      return rowspan;
    },
    shouldShowReactionCell(data, index) { if (index === 0) return true; return data[index].reaction !== data[index - 1].reaction; },
    getReactionRowspan(data, index) { let rowspan = 1;
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].reaction === data[index].reaction) { rowspan++; } else { break; }
      }      
      return rowspan;
    },
    shouldShowControlmethodCell(data, index) { if (index === 0) return true; return data[index].controlmethod !== data[index - 1].controlmethod; },
    getControlmethodRowspan(data, index) { let rowspan = 1;
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].controlmethod === data[index].controlmethod) { rowspan++; } else { break; }
      }      
      return rowspan;
    },
  },

  components: { Modal, modifyModal, ModalLabel, ModalMoLabel, historyModal },     

  setup() {           
      const line = ref();
      const revision = ref();
      const dbTableName = 'controlplan';
      const labelTableName = 'controlplan_label';
      const showModal = ref(false);        
      const showMoModal = ref(false);      
      const showLabelModal = ref(false);   
      const showLabelMoModal = ref(false);                   
      const showHistoryModal = ref(false);                   
      const controlPlanData = reactive({ data: [], })  
      const labelData = reactive({ data: [], })  
      const uniqueLines = ref([]);     
      const uniqueRevision = ref([]);             

      const sendDate = async () => {const sendDateData = [line.value, revision.value,]; return { sendDateData };};    

      const searchData = async () => {            
        const sendDateData = await sendDate();   

        if (!sendDateData.sendDateData[0] || !sendDateData.sendDateData[1]) { alert('Need select Line And Rev.No'); return;}       
        const formData = {        
              tableName: dbTableName,                
              line: sendDateData.sendDateData[0],       
              revision: sendDateData.sendDateData[1],  
        };  

        axios.get('/api/controlPlan', { params: formData, })                      
              .then((res) => {
              controlPlanData.data = res.data.sort((a, b) => a.row_no - b.row_no);
              });          
      };        

      const searchLabelData = async () => {            
        const sendDateData = await sendDate();   

        if (!sendDateData.sendDateData[0] || !sendDateData.sendDateData[1]) {return;}       
        const formData = {        
              tableName: labelTableName,                
              line: sendDateData.sendDateData[0],       
              revision: sendDateData.sendDateData[1],  
        };  

        axios.get('/api/controlPlan', { params: formData, })                      
              .then((res) => { labelData.data = res.data});  
      };    

      const item = computed(() => { return labelData.data.map(d => { return { ...d, }; }); });

      const callSearchData = () => { searchData(); searchLabelData(); }; 

      const loadLines = async () => {
        uniqueLines.value = await fetchData('controlplan_label', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
      }; loadLines();  

      const loadStations = async (line) => { uniqueRevision.value = await fetchData('controlPlan_label', 'revision', [{ column: 'line', value: line }]);};
      watch(() => line.value, (newLine) => { loadStations(newLine); });


      const displaySopStatus = computed(() => {
        if (item.value.length > 0) {
            const sop = item.value[0].sop;
            if (sop === 'Initial sample') {
                return '■ Initial sample □ Pre-production □ SOP';
            } else if (sop === 'Pre-production') {
                return '□ Initial sample ■ Pre-production □ SOP';
            } else if (sop === 'SOP') {
                return '□ Initial sample □ Pre-production ■ SOP';
            }
        }
        return '';
      });    


      const groupedData = computed(() => {
        const naturalSort = (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });

        const groups = {};
        controlPlanData.data.forEach(d => {
          if (!groups[d.process]) {
            groups[d.process] = {
              parts: d.parts,
              process: d.process,
              process_name: d.process_name,                        
              data: []
            };
          }
          groups[d.process].data.push(d);
        });          
        const sortedGroups = Object.values(groups).sort((a, b) => naturalSort(a.process, b.process));
        sortedGroups.forEach(group => {
          group.data.sort((a, b) => a.row_no - b.row_no);
        });
        return sortedGroups;
      }); 

      return { item, revision, dbTableName, callSearchData, line, sendDate, uniqueLines, uniqueRevision, displaySopStatus, groupedData, 
                showModal, showMoModal, showLabelModal, showLabelMoModal, showHistoryModal, 
      };        
    }
  }
</script>

<style scoped src="./controlPlanStyle.css"></style>