export function calculateLinePositions(refs, connections, symbols, offsetX = 0, offsetY = 0, scaleX = 1, scaleY = 1) {
  const lines = [];
  if (!refs || Object.keys(refs).length === 0) return lines;

  const cells = Object.values(refs).flat();
  if (cells.length === 0) return lines;

  const tableRect = cells[0].parentElement.parentElement.parentElement.getBoundingClientRect();

  connections.forEach(connection => {
    const startSymbol = symbols.find(symbol => symbol.id === connection.startSymbol);
    const endSymbol = symbols.find(symbol => symbol.id === connection.endSymbol);

    if (startSymbol && endSymbol) {
      const startCellRef = refs[`cell-${startSymbol.row}-${startSymbol.col}`];
      const endCellRef = refs[`cell-${endSymbol.row}-${endSymbol.col}`];

      if (!startCellRef || !startCellRef[0] || !endCellRef || !endCellRef[0]) return;

      const startCell = startCellRef[0];
      const endCell = endCellRef[0];

      const startRect = startCell.getBoundingClientRect();
      const endRect = endCell.getBoundingClientRect();

      const startX = (startRect.left + startRect.width / 2 - tableRect.left + offsetX) * scaleX;
      const startY = (startRect.top + startRect.height / 2 - tableRect.top + offsetY) * scaleY;
      const endX = (endRect.left + endRect.width / 2 - tableRect.left + offsetX) * scaleX;
      const endY = (endRect.top + endRect.height / 2 - tableRect.top + offsetY) * scaleY;

      let pathData;

      if (connection.lineDirection === 'right' || connection.lineDirection === 'left') {
        const midX = (startX + endX) / 2;
        pathData = `
          M ${startX} ${startY}
          L ${midX} ${startY}
          L ${midX} ${endY}
          L ${endX} ${endY}
        `;
      } else if (connection.lineDirection === 'down') {
        pathData = `
          M ${startX} ${startY}
          L ${startX} ${endY}
          L ${endX} ${endY}
        `;
      }

      lines.push({
        d: pathData,
        dash: connection.lineType === 'dashed' ? '5, 5' : '0'
      });
    }
  });

  return lines;
}

export function recalculateLinePositions(refs, connections, symbols, offsetX = 0, offsetY = 0, scaleX = 1, scaleY = 1) {
  return calculateLinePositions(refs, connections, symbols, offsetX, offsetY, scaleX, scaleY);
}






/*export function calculateLinePositions(refs, connections, symbols,) {
  const lines = [];
  if (!refs || Object.keys(refs).length === 0) return lines;

  const cells = Object.values(refs).flat();
  if (cells.length === 0) return lines;

  const tableRect = cells[0].parentElement.parentElement.parentElement.getBoundingClientRect();

  connections.forEach(connection => {
    const startSymbol = symbols.find(symbol => symbol.id === connection.startSymbol);
    const endSymbol = symbols.find(symbol => symbol.id === connection.endSymbol);

    if (startSymbol && endSymbol) {
      const startCellRef = refs[`cell-${startSymbol.row}-${startSymbol.col}`];
      const endCellRef = refs[`cell-${endSymbol.row}-${endSymbol.col}`];

      if (!startCellRef || !startCellRef[0] || !endCellRef || !endCellRef[0]) return;

      const startCell = startCellRef[0];
      const endCell = endCellRef[0];

      const startRect = startCell.getBoundingClientRect();
      const endRect = endCell.getBoundingClientRect();

      const startX = startRect.left + startRect.width / 2 - tableRect.left;
      const startY = startRect.top + startRect.height / 2 - tableRect.top;
      const endX = endRect.left + endRect.width / 2 - tableRect.left;
      const endY = endRect.top + endRect.height / 2 - tableRect.top;
      
      let pathData;

      if (connection.lineDirection === 'right' || connection.lineDirection === 'left') {
        const midX = (startX + endX) / 2;
        pathData = `
          M ${startX} ${startY}
          L ${midX} ${startY}
          L ${midX} ${endY}
          L ${endX} ${endY}
        `;
      } else if (connection.lineDirection === 'down') {
        pathData = `
          M ${startX} ${startY}
          L ${startX} ${endY}
          L ${endX} ${endY}
        `;
      }

      lines.push({
        d: pathData,
        dash: connection.lineType === 'dashed' ? '5, 5' : '0'
      });
    }
  });

  return lines;
} */

export function updateSymbolsAndConnections(items, symbolTypes) {
  const symbols = items.map((item, index) => ({    
    id: item.uniquecode,
    row: index, // 전체 인덱스를 사용하여 row 설정
    col: parseInt(item.symbols_col),
    type: symbolTypes[item.symbols_col],
    contents: item.contents
  }));

  const connections = [];  
  items.forEach((item) => {
    for (let i = 1; i <= 3; i++) {
      if (item[`end_symbol${i}`]) {
        connections.push({
          startSymbol: item.uniquecode, // 문자열 ID 사용
          endSymbol: item[`end_symbol${i}`], // 문자열 ID 사용
          lineType: item[`line_type${i}`],
          lineDirection: item[`line_direction${i}`]
        });
      }
    }
  });

  const maxRow = Math.max(...symbols.map(symbol => symbol.row));
  const maxCol = Math.max(...symbols.map(symbol => symbol.col));

  const rows = Array.from({ length: maxRow + 1 }, (_, i) => i + 1);
  const cols = Array.from({ length: maxCol + 1 }, (_, i) => i + 1);

  return { symbols, connections, rows, cols };
}