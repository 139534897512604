<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Evaluation Worker</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="modalTable">
              <tr>
                <td>
                <div class="form-group date-time-group">
                  <label>Date(Year/Month/Day):</label>
                  <div class="date-time-inputs">
                    <input class="timeInput" type="text" id="year" v-model="searchDate.year"  />
                    <input class="timeInput" type="text" id="month" v-model="searchDate.month" />
                    <input class="timeInput" type="text" id="day" v-model="searchDate.day" />                  
                  </div> 
                </div>  
                <div class="form-group">
                  <label class="modalLabel" for="employeenumber">Employee Number</label>
                  <input class="modalInput" type="text" id="employeenumber" v-model="formInputs.employeenumber" />
                </div>    
                <div class="form-group">
                  <div>
                  <label class="modalLabel" for="type">Type:</label>                  
                    <input class="radio" type="radio" id="new" value="new" v-model="formInputs.type">
                    <label class="modalLabel"  for="new">New</label>
                    <input class="radio"  type="radio" id="normal" value="normal" v-model="formInputs.type">
                    <label class="modalLabel" for="normal">Normal</label>
                  </div>
                </div>                
                <div class="form-group">
                  <label class="modalLabel" for="line">Line:</label>
                  <select class="modalSelect" id="line" v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
                    <option></option>          
                  </select>              
                </div>
                <div class="form-group">
                  <label class="modalLabel"  for="station">Station:</label>
                  <select class="modalSelect"  id="station" v-model="formInputs.station">
                    <option v-for="station in uniqueStations" :key="station">{{ station }}</option>                    
                    <option></option>
                  </select>              
                </div>
                </td>
                <td>
                <div class="form-group date-time-group">
                  <label class="modalLabel">Work Start Date(Year/Month/Day):</label>
                  <div class="date-time-inputs">
                    <input class="timeInput" type="text" id="year" v-model="formInputs.startyear"  />
                    <input class="timeInput" type="text" id="month" v-model="formInputs.startmonth" />
                    <input class="timeInput" type="text" id="day" v-model="formInputs.startday" />                  
                  </div> 
                </div>  
                <div class="form-group">
                  <label class="modalLabel" for="oscompany">Company:</label>
                  <select class="modalSelect" id="oscompany" v-model="formInputs.oscompany" >
                    <option>MAX POWER</option><option>MEGA STAFF</option><option>ZYX</option><option>Inkatom</option>
                    <option>LSEVP</option><option></option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="modalLabel"  for="name">Name:</label>
                  <input class="modalInput" type="text" id="name" v-model="formInputs.name" />
                </div>
                <div class="form-group">
                  <label class="modalLabel" for="surname">Surname:</label>
                  <input class="modalInput" type="text" id="surname" v-model="formInputs.surname" />
                </div>
                <div class="form-group">
                  <label class="modalLabel" for="position">Position:</label>
                  <select class="modalSelect" type="text" id="position" v-model="formInputs.position">
                    <option>Leader</option><option>Technician</option>
                    <option>Senior Operator</option><option>Junior Operator</option>
                    <option>Senior Inspector</option><option>Inspector</option>
                    <option>Logistics</option><option>Forklift</option><option>Truck</option>
                    <option>Maintenance</option>
                  </select>
                </div>           
                <div class="form-group date-time-group">
                  <label class="modalLabel">Next Evaluation Date(Year/Month/Day):</label>
                  <div class="date-time-inputs">
                    <input class="timeInput" type="text" id="year" v-model="formInputs.nextyear"  />
                    <input class="timeInput" type="text" id="month" v-model="formInputs.nextmonth" />
                    <input class="timeInput" type="text" id="day" v-model="formInputs.nextday" />                  
                  </div> 
                </div>  
                <div class="form-group">
                  <label class="modalLabel" for="remark">Remark</label>
                  <input class="modalInput" type="text" id="remark" v-model="formInputs.remark" />
                </div>      
              </td>
            </tr>
            </table>            
            <button class="saveButton" type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  
  import { reactive, ref, watch } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, fetchLine, fetchStation, } from "../../utils.js"; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "evaluation"            
        const uniqueLines = ref([]);   
        const uniqueStations = ref([]);  
        const saveStatus = ref(''); 
        const isProcessing = ref(false);      
                
        const formInputs = reactive({
          employeenumber: "", type: "", line: "", station: "", startyear: "", startmonth: "", startday: "", 
          oscompany: "",  name: "", surname: "", position: "", nextyear: "", nextmonth: "", nextday: "", remark: "",
          workmethod: "", quality: "", workattitude: "", threefive: "",
        });        

        const searchDate = reactive({ ...getCurrentDate(), });     
        
        const submitForm = async () => {
          if (!formInputs.line || !formInputs.type || !formInputs.employeenumber ) {
            alert("Please fill in all the fields: line, type and employeenumber.");
            return;
          }

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            type: "type",
            line: 'line',
            station: 'station',
            startyear: 'startyear',
            startmonth: 'startmonth',
            startday: 'startday',
            oscompany: 'oscompany',
            employeenumber: "employeenumber",
            name : "name",
            surname : "surname",             
            position : "position", 
            workmethod : 'workmethod',
            quality : 'quality',
            workattitude : 'workattitude',
            threefive : 'threefive',           
            nextyear : 'nextyear',
            nextmonth : 'nextmonth',
            nextday : 'nextday',            
            remark : "remark",           
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              type: formInputs.type,
              line: formInputs.line,
              station: formInputs.station,
              startyear: formInputs.startyear,
              startmonth: formInputs.startmonth,
              startday: formInputs.startday,
              oscompany: formInputs.oscompany,
              employeenumber: formInputs.employeenumber,
              name : formInputs.name,
              surname : formInputs.surname,
              position : formInputs.position,
              workmethod : formInputs.workmethod,
              quality : formInputs.quality,
              workattitude : formInputs.workattitude,
              threefive : formInputs.threefive,
              nextyear : formInputs.nextyear,
              nextmonth : formInputs.nextmonth,
              nextday : formInputs.nextday,
              remark : formInputs.remark,
            }
         };

         try { await axios.post('/api/post', formData); saveStatus.value = 'Completed'; }
         catch (error) { console.error('Error during submission:', error); saveStatus.value = 'Error'; }
         finally { isProcessing.value = false;}         
         closeModal();
        };

      const closeModal = () => { emit("onClose"); };

      const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
      const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };
  
      watch(() => formInputs.line, (newLine) => { loadStations(newLine); });
      
      const fetchname = () => {
        const employeeTableName = 'lineworkerlist';
        const columnName = 'employeenumber';        
        axios
          .get('/api/where', { params: { value: formInputs.employeenumber, table_name: employeeTableName, column_name: columnName } })
          .then((res) => {
            const filteredData = res.data.filter(data => 
              data.line === formInputs.line && data.station === formInputs.station);

            if (filteredData.length > 0) {
              const employeeData = filteredData[0];
                formInputs.oscompany = employeeData.oscompany;                  
                formInputs.position = employeeData.position;
                formInputs.name = employeeData.name;
                formInputs.surname = employeeData.surname;       
                formInputs.startyear = employeeData.startyear;
                formInputs.startmonth = employeeData.startmonth;
                formInputs.startday = employeeData.startday;
            } else {
                formInputs.name = "";
                formInputs.surname = "";
                formInputs.oscompany = "";
                formInputs.position = "";
                formInputs.startyear = "";
                formInputs.startmonth = "";
                formInputs.startday = "";
            }
          })
          .catch((error) => {
            console.error('Error fetching target data:', error.message);
          });
      };

      watch([() => formInputs.employeenumber, () => formInputs.line, () => formInputs.station], () => { fetchname(); });

      watch(() => formInputs.type, () => {
        if (formInputs.type === "new") {
          // Registration의 경우, 시작 날짜로부터 3개월 후의 날짜를 계산
          const startDate = new Date(formInputs.startyear, formInputs.startmonth - 1, formInputs.startday);
          startDate.setMonth(startDate.getMonth() + 3);
          formInputs.nextyear = startDate.getFullYear();
          formInputs.nextmonth = startDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줌
          formInputs.nextday = startDate.getDate();
        } else if (formInputs.type === "normal") {
          // Normal의 경우, 현재 날짜로부터 1년 후의 날짜를 계산
          const currentDate = new Date(searchDate.year, searchDate.month - 1, searchDate.day);
          currentDate.setFullYear(currentDate.getFullYear() + 1);
          formInputs.nextyear = currentDate.getFullYear();
          formInputs.nextmonth = currentDate.getMonth() + 1;
          formInputs.nextday = currentDate.getDate();
        }
      });

      watch([() => formInputs.startyear, () => formInputs.startmonth, () => formInputs.startday], () => {
        if (formInputs.type === "new") {
          const startDate = new Date(formInputs.startyear, formInputs.startmonth - 1, formInputs.startday);
          startDate.setMonth(startDate.getMonth() + 3);
          formInputs.nextyear = startDate.getFullYear();
          formInputs.nextmonth = startDate.getMonth() + 1;
          formInputs.nextday = startDate.getDate();
        }
      });  
      
      watch(() => formInputs.position, (newPosition) => {
        if (newPosition !== 'Maintenance') {
          formInputs.workmethod = 'Trained';
          formInputs.quality = 'Trained';
          formInputs.workattitude = 'Trained';
          formInputs.threefive = 'Trained';
        } else {
          formInputs.workmethod = 'Trained';
          formInputs.quality = '';
          formInputs.workattitude = 'Trained';
          formInputs.threefive = 'Trained';          
        }
      });
  
      return { dbTableName, formInputs, searchDate, submitForm, closeModal, uniqueLines, uniqueStations,
               saveStatus, isProcessing };
    },     

    props: {  onSubmit: { type: Function, required: true, },  }, 


};
  
  </script>
    
  <style scoped>
  
  * { margin: 0; padding: 0; box-sizing: border-box; }
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }  
  .modal-wrapper { width: 100%;  max-width: 800px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  .modalLabel { font-weight: bold; }

  .modalInput { width: 100%; font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  .modalSelect { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; }

  .saveButton {
      background-color: #4CAF50; color: white; padding: 5px 20px; border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px; display: inline-block; transition-duration: 0.4s;
  }  

  .saveButton[type="submit"]:hover { background-color: #45a049; }

  .modalTable { table-layout: fixed; width: 100%; }  
  .modalTable td { width: 20%; vertical-align: top; padding: 0 15px; }  
  .date-time-inputs { display: flex; gap: 5px;}  
  .timeInput { width: 50px; size: 8px; font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  .radio { margin-left: 10px; margin-right: 5px; }
</style>