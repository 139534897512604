<template>
  <div class="checkTarget">      
    <hr>
    <div class="parent">
      <div class="addbutton">        
        <button class="button" @click="showModal = true">+ Add</button>   
        <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
        <input id="file-input" type="file" @change="onFileChange" accept=".xlsx, .xls" style="display: none" />
        <span class="fileName">{{ fileName }}</span>   
        <button class="excelbutton" @click="uploadExcel('machinedata')">Upload</button>
        <button class="excelbutton" @click="downloadExcel">Down Load</button>  
      </div>
    </div>
    <div class="table-container">      
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line_name">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Line'" :key="index" >{{ row.line }}</td>
          <td v-if="header === 'Station'" :key="index" @click="editCellValue(targetTableName, row.id, 'station', $event)">{{ row.station }}</td>
          <td v-if="header === 'Row No'" :key="index" @click="editCellValue(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
          <td v-if="header === 'DEL'" :key="index" ><button :class="['del', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="deleteRow(targetTableName, row.id)">DEL</button></td>                  
        </template>   
      </tr>
    </tbody>     
    </table> <br>
    <h3>ROW_NO Standard</h3>
    <table class="rownostandard">
      <tr>
        <th>Project</th><th>Line</th><th>Station</th>
      </tr>
      <tr>
        <td>1 digit</td><td>2 digit</td><td>2 digit</td>
      </tr>
      <tr>
        <td colspan="3">Example : 10101 (VW: 1 / TR3 SMT: 01 / #010 loading and Buffer : 01)</td>                
      </tr>
    </table>

    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import Modal from "./machineDataModal.vue";
import axios from "axios";
import readXlsxFile from 'read-excel-file';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

export default {         
    components: { Modal, },    
    data() {        
        return {
        excelFile: null,
        fileName: "",
        };
    },
    methods: {
        onFileChange(event) {
        this.excelFile = event.target.files ? event.target.files[0] : null;
        this.fileName = this.excelFile ? this.excelFile.name : "";
        },
        async uploadExcel(tableName) {
        if (!this.excelFile) {
            alert('Please select an Excel file.');
            return;
        }

        try {
            const rows = await readXlsxFile(this.excelFile);
            const columns = rows[0];
            const data = rows.slice(1);

            console.log('column :', columns.join(', '));
            data.forEach((row, index) => {
            console.log(`value row${index + 1} :`, row.join(', '));
            });

            console.log('Sending data to API:', { data: rows, tableName: tableName });
            const response = await axios.post('/api/upload-excel', {
            data: rows,
            tableName: tableName,
            });

            console.log(response);
            alert('File uploaded successfully.');
        } catch (error) {
            console.error(error);
            alert('Error uploading the file.');
        }
        },

        canDelClick() {    
          const currentUser = this.$route.meta.currentUser;
          console.log('currentUser', currentUser)
          if (currentUser) {            
              return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3  ;
          } else {
              return false;
          }
        },
    },
    setup() {   
        const targetTableName = "machinedata";            
        const target_headers = ['Line', 'Station', 'Row No', 'DEL'];
        const targetList = reactive({ data: [], })
        const showModal = ref(false); 

        onMounted(() => { searchData(); settingCall(); });   

        const searchData = async () => {         

          const formData = {
            tableName: targetTableName,
          };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => {
              console.log("Response from /api/target:", res.data);
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const targetItem = computed(() => {
          return targetList.data.map(d => {
            return { ...d, };
          });
        });     
        
        const callSearchData = () => { searchData(targetTableName); settingCall(); };
             
        const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {              
              try {                
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });                
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
        };  

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error fetching target data:", error.message); });
        };
        
        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(targetList.data, { header: target_headers.value });


          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "Machine Data");


          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "MachineData.xlsx");
        };

        const deleteRow = async (tableName, rowId) => {
          // Show a confirmation window and store the user's choice
          const userConfirmed = confirm("Are you sure you want to delete this row?");

          // If the user clicks "OK", proceed with the deletion
          if (userConfirmed) {
            try {
              await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });
              callSearchData();
            } catch (error) {
              console.error("Error deleting row:", error);
            }
          }
        };

        return { targetItem, targetList, editCellValue, target_headers, targetTableName, showModal, downloadExcel, callSearchData, deleteRow };        
    }
  }
</script>

<style scoped>
  .table-container {  overflow-x: hidden;  position: relative; }

  .table-header,
  .table-body {
    overflow: hidden;
    width: 100%;  
  }

  .table-body {
    max-height: 350px; 
    overflow-y: auto;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
  }

  .table thead th {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f8f9fa;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  .table tbody + tbody { border-top: 2px solid #dee2e6; }
  .table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075); }
  .target { font-family: Arial, Helvetica, sans-serif; }

  .target .table {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .target .table td { text-align: center; }
  .target .table tr:nth-child(odd) { background-color: #f8f9fa; }

  .target .table tr:hover {
    background-color: #e9ecef;
    cursor: pointer;
  }
  .target hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));
    margin-bottom: 1rem;
  }

  .button {
      padding: 5px 10px;
      background-color: #3498db;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 14px;   
      font-weight: 600;
      margin-right: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

  .parent { display: flex; justify-content: space-between; }
  .addbutton { margin-left: auto;  }

  .custom-file-upload {
    display: inline-block;  padding: 4px 12px;  cursor: pointer;
    background-color: #c49105;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }

  .custom-file-upload:hover { background-color: #f3a359; }

  .excelbutton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .excelbutton:hover { background-color: #009625; }
  
  .rownostandard {
    width: 50%;
    max-width: 50%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .rownostandard th,
  .rownostandard td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #000000;
    text-align: center;
  }

  .rownostandard thead th {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f8f9fa;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    vertical-align: bottom;
    border-bottom: 2px solid #000000;
  }

  .rownostandard tbody + tbody { border-top: 2px solid #000000; }
.del {
  padding: 4px 4px;
  background-color: #bfbebe;
  color: #000000; border: 10px; border-color: #000000;
  font-size: 10px; font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}
</style>