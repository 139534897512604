import { reactive, computed, watchEffect } from 'vue';
import { addData } from './data.js';

const evaluationData = reactive({ data: [] });

watchEffect(() => { evaluationData.data = addData[2].data;});

const hasGradeExemption = (row) => {
  // 같은 line, station, employeenumber를 가진 다음 데이터의 grade가 'E', 'N', 'T'인지 확인
  const nextData = evaluationData.data.find(data =>
    data.line === row.line &&
    data.station === row.station &&
    data.employeenumber === row.employeenumber &&
    data.id > row.id
  );
  return nextData && ['E', 'N', 'T'].includes(nextData.grade);
};

const evahasExpired = computed(() => {
  return evaluationData.data.some(row => {
    const currentDate = new Date();
    const expireDate = new Date(row.nextyear, row.nextmonth - 1, row.nextday);
    const diffTime = expireDate - currentDate;  
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
    return diffDays <0 && !hasGradeExemption(row);
  });
});

const evaisNearExpiry = computed(() => {
  return evaluationData.data.some(row => {
    const currentDate = new Date();
    const expireDate = new Date(row.nextyear, row.nextmonth - 1, row.nextday);
    const diffTime = expireDate - currentDate;  
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));            
    return diffDays > 0 && diffDays <= 15 && !hasGradeExemption(row);
  });
});

export const evaluationtableColor = computed(() => {  
  if (evahasExpired.value) {
    return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // 빨간색
  } else if (evaisNearExpiry.value) {
    return { backgroundColor: 'rgba(255, 255, 0, 0.7)' }; // 노란색
  }
  return { backgroundColor: 'transparent' }; // Transparent color
});