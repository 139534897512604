import { reactive, computed, ref } from 'vue';
import axios from "axios";
import { fetchData} from '../../utils.js'

export function useForm() {    
  const targetTableName = "checksheettarget";      
  const formInputs = reactive({ line: "" });
  const uniqueLines = ref([]);        
  const target_headers = ['Line name', 'Process Row', 'Process', 'Model', 'Item', 'Lower Limit', 'Standard Value', 'Upper Limit', 'Unit', 'Update Date', 'Acceptance Criteria', 'Remark', 'Row No', 'Except'];
  const targetList = reactive({ data: [], })
  const showModal = ref(false);         

  const searchData = async () => {         
    if (formInputs.line === "ALL" ) {formInputs.line="";}
    const formData = {
      tableName: targetTableName,
      line: formInputs.line,
    };
    axios.get('/api/targetList', { params: formData })
      .then((res) => { targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); })
      .catch((error) => { console.error("Error fetching target data:", error.message);});
    };

  const targetItem = computed(() => { return targetList.data.map(d => { return { ...d, }; }); });     
  
  const callSearchData = () => { if(!formInputs.line) {return;} searchData();};

  const loadLines = async () => {
    uniqueLines.value = await fetchData('checksheettarget', 'line');
    uniqueLines.value.sort((a, b) => a.localeCompare(b));
  };
  
  loadLines();    

  return { targetItem, targetList, target_headers, targetTableName, showModal, callSearchData,
            formInputs, uniqueLines };        
}