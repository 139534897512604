<template>
    <div class="inkatom">
      <div class="act">
        <h class="pagetitle">INKATOM 1st Factory</h>           
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>           
        <button class="button" @click="showModal = true">+ Result</button>                
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" >{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers ">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index"  @click="editCellValue(dbTableName, row.id, header, $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-2P12S': row.model === '2P12S', 'model-3P8S': row.model === '3P8S', 'model-evo2p': row.model === 'EVO_2P', 'model-evo3p': row.model === 'EVO_3P'}" @click="editCellValue(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok"  @click="editCellValue(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'Verification'" :key="index" class="verification"  @click="editCellValue(dbTableName, row.id, 'verification', $event)">{{ row.verification }}</td>                
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Heat Stacking'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_heatstacking === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_heatstacking', $event)">{{ row.ng_heatstacking }}</td>
                <td v-if="header === 'Busbar Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_busbardamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_busbardamage', $event)">{{ row.ng_busbardamage }}</td>
                <td v-if="header === 'FPCB Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_fpcbdamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_fpcbdamage', $event)">{{ row.ng_fpcbdamage }}</td>
                <td v-if="header === 'Weld Line Position'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_weldlineposition === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_weldlineposition', $event)">{{ row.ng_weldlineposition }}</td>
                <td v-if="header === 'Welding NG'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_weldingng === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_weldingng', $event)">{{ row.ng_weldingng }}</td>
                <td v-if="header === 'Final Slot'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_finalslot === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_finalslot', $event)">{{ row.ng_finalslot }}</td>
                <td v-if="header === 'Frame Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_framedamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_framedamage', $event)">{{ row.ng_framedamage }}</td>
                <td v-if="header === 'EOL Real'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eolreal === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_eolreal', $event)">{{ row.ng_eolreal }}</td>
                <td v-if="header === 'Coating'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coating === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_coating', $event)">{{ row.ng_coating }}</td>                
                <td v-if="header === 'Cover Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coverdamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_coverdamage', $event)">{{ row.ng_coverdamage }}</td>                
                <td v-if="header === 'ETC'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_etc === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_etc', $event)">{{ row.ng_etc }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>                                
                <td v-if="header === 'Work Order'" :key="index"  @click="editCellValue(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td>                 
                <td v-if="header === 'Leader'" :key="index"  @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td>                 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'row_no'" :key="index"  @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="3">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>   
          <td>{{ sums.shiftSums[1].verification }}</td>          
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[1].ng_busbardamage }}</td>
          <td>{{ sums.shiftSums[1].ng_fpcbdamage }}</td>
          <td>{{ sums.shiftSums[1].ng_weldlineposition }}</td>
          <td>{{ sums.shiftSums[1].ng_weldingng }}</td>
          <td>{{ sums.shiftSums[1].ng_finalslot }}</td>    
          <td>{{ sums.shiftSums[1].ng_framedamage }}</td>
          <td>{{ sums.shiftSums[1].ng_eolreal }}</td>
          <td>{{ sums.shiftSums[1].ng_coating }}</td>
          <td>{{ sums.shiftSums[1].ng_coverdamage }}</td>          
          <td>{{ sums.shiftSums[1].ng_etc }}</td>  
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>   
          <td>{{ sums.shiftSums[2].verification }}</td>          
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[2].ng_busbardamage }}</td>
          <td>{{ sums.shiftSums[2].ng_fpcbdamage }}</td>
          <td>{{ sums.shiftSums[2].ng_weldlineposition }}</td>
          <td>{{ sums.shiftSums[2].ng_weldingng }}</td>
          <td>{{ sums.shiftSums[2].ng_finalslot }}</td>    
          <td>{{ sums.shiftSums[2].ng_framedamage }}</td>
          <td>{{ sums.shiftSums[2].ng_eolreal }}</td>
          <td>{{ sums.shiftSums[2].ng_coating }}</td>
          <td>{{ sums.shiftSums[2].ng_coverdamage }}</td>          
          <td>{{ sums.shiftSums[2].ng_etc }}</td>  
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>
          <td>3</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[3].target }}</td>
          <td>{{ sums.shiftSums[3].input }}</td>
          <td>{{ sums.shiftSums[3].ok }}</td>   
          <td>{{ sums.shiftSums[3].verification }}</td>          
          <td>{{ sums.shiftSums[3].ng }}</td>
          <td>{{ sums.shiftSums[3].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[3].ng_busbardamage }}</td>
          <td>{{ sums.shiftSums[3].ng_fpcbdamage }}</td>
          <td>{{ sums.shiftSums[3].ng_weldlineposition }}</td>
          <td>{{ sums.shiftSums[3].ng_weldingng }}</td>
          <td>{{ sums.shiftSums[3].ng_finalslot }}</td>    
          <td>{{ sums.shiftSums[3].ng_framedamage }}</td>
          <td>{{ sums.shiftSums[3].ng_eolreal }}</td>
          <td>{{ sums.shiftSums[3].ng_coating }}</td>
          <td>{{ sums.shiftSums[3].ng_coverdamage }}</td>          
          <td>{{ sums.shiftSums[3].ng_etc }}</td>  
          <td>{{ ((sums.shiftSums[3].ng) / (sums.shiftSums[3].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>
         <th rowspan="4">Model</th>
          <td>2P12S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['2P12S'].target }}</td>
          <td>{{ sums.modelSums['2P12S'].input }}</td>
          <td>{{ sums.modelSums['2P12S'].ok}}</td>    
          <td>{{ sums.modelSums['2P12S'].verification}}</td>             
          <td>{{ sums.modelSums['2P12S'].ng}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_heatstacking}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_busbardamage}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_fpcbdamage}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_weldlineposition}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_weldingng}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_finalslot}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_framedamage}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_eolreal}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_coating}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_coverdamage}}</td>
          <td>{{ sums.modelSums['2P12S'].ng_etc}}</td>
          <td>{{ ((sums.modelSums['2P12S'].ng ) / (sums.modelSums['2P12S'].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>         
          <td>3P8S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['3P8S'].target }}</td>
          <td>{{ sums.modelSums['3P8S'].input }}</td>
          <td>{{ sums.modelSums['3P8S'].ok}}</td>
          <td>{{ sums.modelSums['3P8S'].verification}}</td>             
          <td>{{ sums.modelSums['3P8S'].ng}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_heatstacking}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_busbardamage}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_fpcbdamage}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_weldlineposition}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_weldingng}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalslot}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_framedamage}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolreal}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coverdamage}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_etc}}</td>
          <td>{{ ((sums.modelSums['3P8S'].ng ) / (sums.modelSums['3P8S'].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>          
          <td>EVO 2P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.modelSums['EVO_2P'].input }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok}}</td>   
          <td>{{ sums.modelSums['EVO_2P'].verification}}</td>             
          <td>{{ sums.modelSums['EVO_2P'].ng}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_heatstacking}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_busbardamage}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_fpcbdamage}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_weldlineposition}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_weldingng}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_finalslot}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_framedamage}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_eolreal}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_coating}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_coverdamage}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_etc}}</td>
          <td>{{ ((sums.modelSums['EVO_2P'].ng ) / (sums.modelSums['EVO_2P'].input) * 100).toFixed(1) }}%</td>     
        </tr>
        <tr>          
          <td>EVO 3P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.modelSums['EVO_3P'].input }}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok}}</td>   
          <td>{{ sums.modelSums['EVO_3P'].verification}}</td>             
          <td>{{ sums.modelSums['EVO_3P'].ng}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_heatstacking}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_busbardamage}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_fpcbdamage}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_weldlineposition}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_weldingng}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_finalslot}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_framedamage}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_eolreal}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_coating}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_coverdamage}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_etc}}</td>
          <td>{{ ((sums.modelSums['EVO_3P'].ng ) / (sums.modelSums['EVO_3P'].input) * 100).toFixed(1) }}%</td>     
        </tr>
        <tr>
         <th colspan="3">1st Factory Total</th>         
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target + sums.shiftSums[3].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input + sums.shiftSums[3].input }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok + sums.shiftSums[2].ok + sums.shiftSums[3].ok }}</td>   
          <td class="verification" >{{ sums.shiftSums[1].verification + sums.shiftSums[2].verification + sums.shiftSums[3].verification }}</td>                 
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.shiftSums[3].ng }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td> <!-- obj.ng_heatstacking 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>              
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.shiftSums[3] ].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>                 
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.shiftSums[3].ng ) / (sums.shiftSums[1].input + sums.shiftSums[2].input + sums.shiftSums[3].input) * 100).toFixed(1) }}%</td>                    
        </tr>   
      </table>    
      </div>                  
    
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    

  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./inkatom_modal_1st.vue";


export default {      
    components: { Modal, },        
    
    setup() {
        const lineName = "inkatom1st"  //설비 변경시 필수 교체        
        const dbTableName = "inkatom1st";  //searchData에 있는 값도 바꿔야 함 
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'Verification',
                         'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position",
                         "Welding NG", 'Final Slot', 'Frame Damage', 'EOL Real', 'Coating',
                         'Cover Damage', 'ETC', 'NG Rate', 'Work Order', 'Leader', 'Work Time', 'Remark',
                         'row_no']; //설비 변경시 필수 교체

        const prTableData = reactive({ data: [], });    
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};             
        };            

        const calculateInputAndNgRate = (ok, ng, ) => {
          const input = ok + ng;    
          let ngRate = 0;
      
          if (ng !== 0) { ngRate = ((ng / input) * 100).toFixed(1); }          
          return { input, ngRate,};
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, } = 
            calculateInputAndNgRate(d.ok, d.ng, );
            return { ...d, input, ngRate, }; 
          });
        });   

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
            console.log('Requesting data with:', {
              tablename: tableName,
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
            });

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };

            prTableData.data = [];

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'inkatom1st':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName); settingCall();
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let shiftSums = {
            1: { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0,
                 ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0,
                 ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, }, 
          
            2: { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0,
                 ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0,
                 ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, }, 

            3: { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0,
                 ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0,
                 ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, }, 
            };

          let modelSums = {
            "2P12S" : { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0,
                        ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0,
                        ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, }, 
            "3P8S" : { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0,
                        ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0,
                        ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, }, 
            "EVO_2P" : { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0,
                        ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0,
                        ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, }, 
            "EVO_3P" : { target: 0, input: 0, ok: 0, verification: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0,
                        ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0,
                        ng_coating: 0, ng_coverdamage: 0, ng_etc: 0, }, 
            };

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].ok += row.ok;  
              shiftSums[row.shift].verification += row.verification;  
              shiftSums[row.shift].ng += row.ng;    
              shiftSums[row.shift].ng_heatstacking += row.ng_heatstacking;
              shiftSums[row.shift].ng_busbardamage += row.ng_busbardamage;
              shiftSums[row.shift].ng_fpcbdamage += row.ng_fpcbdamage;
              shiftSums[row.shift].ng_weldlineposition += row.ng_weldlineposition;
              shiftSums[row.shift].ng_weldingng += row.ng_weldingng;
              shiftSums[row.shift].ng_finalslot += row.ng_finalslot;
              shiftSums[row.shift].ng_framedamage += row.ng_framedamage;
              shiftSums[row.shift].ng_eolreal += row.ng_eolreal;
              shiftSums[row.shift].ng_coating += row.ng_coating;
              shiftSums[row.shift].ng_coverdamage += row.ng_coverdamage;              
              shiftSums[row.shift].ng_etc += row.ng_etc;              
              }

            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {              
              modelSums[row.model].target += row.target;            
              modelSums[row.model].input += row.input;
              modelSums[row.model].ok += row.ok;  
              modelSums[row.model].verification += row.verification;                       
              modelSums[row.model].ng += row.ng;    
              modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
              modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
              modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
              modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
              modelSums[row.model].ng_weldingng += row.ng_weldingng;
              modelSums[row.model].ng_finalslot += row.ng_finalslot;
              modelSums[row.model].ng_framedamage += row.ng_framedamage;
              modelSums[row.model].ng_eolreal += row.ng_eolreal;
              modelSums[row.model].ng_coating += row.ng_coating;
              modelSums[row.model].ng_coverdamage += row.ng_coverdamage;              
              modelSums[row.model].ng_etc += row.ng_etc;              
            }
          }

          return { shiftSums, modelSums, };
        });

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error download excel data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });          

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "Inkatom");          

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "Inkatom1st.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, 
                  item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 sums, callSearchData, downloadExcel, sendDate,
               
                 };        
    },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
  margin-bottom: 10px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }


.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, 
.button:hover { background-color: #2980b9; }
  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input { width : 80px; margin-right: 5px; padding : 5px; }

label { font-size: 14px; font-weight: 600; margin-right: 8px; }
.sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; }

table { width: 80%;}

.custom-table { font-size: 10px; width: 80%; overflow-x: auto; white-space: nowrap; }
.model-2P12S { background-color: #fafc83; } 
.model-3P8S { background-color: #e07510; } 
.model-evo2p { background-color: #04c192; }
.model-evo3p { background-color: #c2c2c2; }
.ok { background-color: #94b7f8; font-weight: bold; }
.verification { background-color: #e7f67a; font-weight: bold; }
.ng { background-color: #fa7979; font-weight: bold; }
.zero-value_ng { background-color: #fa7979; color: #fa7979; }
.ng_type { background-color: #fdb08d;}
.zero-value_ngtype { background-color: #fdb08d; color: #fdb08d; }

.shift1 { background-color: rgb(174, 240, 147); }
.shift2 { background-color: rgba(248, 250, 145, 0.979); }
</style>