<template>
    <div class="ford1fr">            
      <div class="act">               
         <h class="pagetitle">Ford#1 Front</h>         
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downExcel">Excel</button>  
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>         
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCell(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-4P8S': row.model === '4P8S', 'model-4P7S': row.model === '4P7S' , 'model-3P10S': row.model === '3P10S', 'model-3P8S': row.model === '3P8S'}" @click="editCell(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCell(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCell(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>                
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCell(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> 
                <td v-if="header === 'Fake Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> 

                <td v-if="header === 'Laser Marking Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lasermarkingsum === 0 }" @click="editCell(dbTableName, row.id, 'ng_lasermarkingsum', $event)">{{ row.ng_lasermarkingsum}}</td>
                <td v-if="header === 'LM Heat Staking'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingheatstaking === 0 }" @click="editCell(dbTableName, row.id, 'ng_lasermarkingheatstaking', $event)">{{ row.ng_lasermarkingheatstaking }}</td>
                <td v-if="header === 'LM Busbar'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingbusbar === 0 }" @click="editCell(dbTableName, row.id, 'ng_lasermarkingbusbar', $event)">{{ row.ng_lasermarkingbusbar }}</td>
                <td v-if="header === 'LM Assembly'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingassembly === 0 }" @click="editCell(dbTableName, row.id, 'ng_lasermarkingassembly', $event)">{{ row.ng_lasermarkingassembly }}</td>
                <td v-if="header === 'LM QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingqrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_lasermarkingqrcode', $event)">{{ row.ng_lasermarkingqrcode }}</td>
                
                <td v-if="header === 'Middle Insp. Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_middleinspsum === 0 }" @click="editCell(dbTableName, row.id, 'ng_middleinspsum', $event)">{{ row.ng_middleinspsum }}</td>
                <td v-if="header === 'MI Rivet height'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinsprivetheight === 0 }" @click="editCell(dbTableName, row.id, 'ng_middleinsprivetheight', $event)">{{ row.ng_middleinsprivetheight }}</td>
                <td v-if="header === 'MI Damaged rivet'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinspdamagedrivet === 0 }" @click="editCell(dbTableName, row.id, 'ng_middleinspdamagedrivet', $event)">{{ row.ng_middleinspdamagedrivet }}</td>
                <td v-if="header === 'MI Assembly'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinspassembly === 0 }" @click="editCell(dbTableName, row.id, 'ng_middleinspassembly', $event)">{{ row.ng_middleinspassembly }}</td>
                <td v-if="header === 'MI QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinspqrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_middleinspqrcode', $event)">{{ row.ng_middleinspqrcode }}</td>
                
                <td v-if="header === 'AOI NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_aoingsum === 0 }" @click="editCell(dbTableName, row.id, 'ng_aoingsum', $event)">{{ row.ng_aoingsum }}</td>
                <td v-if="header === 'AOI Soldering'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingsoldering === 0 }" @click="editCell(dbTableName, row.id, 'ng_aoingsoldering', $event)">{{ row.ng_aoingsoldering }}</td>
                <td v-if="header === 'AOI Heat Staking'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingheatstaking === 0 }" @click="editCell(dbTableName, row.id, 'ng_aoingheatstaking', $event)">{{ row.ng_aoingheatstaking }}</td>
                <td v-if="header === 'AOI Riveting'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingriveting === 0 }" @click="editCell(dbTableName, row.id, 'ng_aoingriveting', $event)">{{ row.ng_aoingriveting }}</td>
                <td v-if="header === 'AOI QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingqrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_aoingqrcode', $event)">{{ row.ng_aoingqrcode }}</td>

                <td v-if="header === 'Coating NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingngsum === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingngsum', $event)">{{ row.ng_coatingngsum }}</td>
                <td v-if="header === 'Insfficient Coating'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingnginsufficientcoating === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingnginsufficientcoating', $event)">{{ row.ng_coatingnginsufficientcoating }}</td>
                <td v-if="header === 'Coating height'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingngcoatingheight === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingngcoatingheight', $event)">{{ row.ng_coatingngcoatingheight }}</td>
                <td v-if="header === 'Excessive coating'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingngexcessivecoating === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingngexcessivecoating', $event)">{{ row.ng_coatingngexcessivecoating }}</td>
                <td v-if="header === 'Coating QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingngqrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingngqrcode', $event)">{{ row.ng_coatingngqrcode }}</td>

                <td v-if="header === 'EOL NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eolngsum === 0 }" @click="editCell(dbTableName, row.id, 'ng_eolngsum', $event)">{{ row.ng_eolngsum }}</td>
                <td v-if="header === 'Open circuit'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolngopencircuit === 0 }" @click="editCell(dbTableName, row.id, 'ng_eolngopencircuit', $event)">{{ row.ng_eolngopencircuit }}</td>
                <td v-if="header === 'Over resistance'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolngoverresistance  === 0 }" @click="editCell(dbTableName, row.id, 'ng_eolngoverresistance', $event)">{{ row.ng_eolngoverresistance }}</td>
                <td v-if="header === '2P connectors'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolng2pconnectors === 0 }" @click="editCell(dbTableName, row.id, 'ng_eolng2pconnectors', $event)">{{ row.ng_eolng2pconnectors }}</td>
                <td v-if="header === 'EOL QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolngqrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_eolngqrcode', $event)">{{ row.ng_eolngqrcode }}</td>

                <td v-if="header === 'LQC NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lqcngsum === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngsum', $event)">{{ row.ng_lqcngsum }}</td>
                <td v-if="header === 'LQC Busbars'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngbusbar === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngbusbar', $event)">{{ row.ng_lqcngbusbar }}</td>
                <td v-if="header === 'LQC Carrier'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngcarrier === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngcarrier', $event)">{{ row.ng_lqcngcarrier }}</td>
                <td v-if="header === 'LQC Bubbles'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngbubblesincoating  === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngbubblesincoating', $event)">{{ row.ng_lqcngbubblesincoating }}</td>
                <td v-if="header === 'LQC FPCB'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngfpcb === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngfpcb', $event)">{{ row.ng_lqcngfpcb }}</td>
                <td v-if="header === 'LQC Connector'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngconnector === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngconnector', $event)">{{ row.ng_lqcngconnector }}</td>
                <td v-if="header === 'LQC Damaged Rivets'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngdamagedrivets === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngdamagedrivets', $event)">{{ row.ng_lqcngdamagedrivets }}</td>
                <td v-if="header === 'LQC Bottom pins'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngbottompins === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngbottompins', $event)">{{ row.ng_lqcngbottompins }}</td>
                <td v-if="header === 'LQC QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngqrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_lqcngqrcode', $event)">{{ row.ng_lqcngqrcode }}</td>

                <td v-if="header === 'Final Insp. Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_finalinspngsum === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngsum', $event)">{{ row.ng_finalinspngsum }}</td>
                <td v-if="header === 'FI Busbars'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngbusbar === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngbusbar', $event)">{{ row.ng_finalinspngbusbar }}</td>
                <td v-if="header === 'FI Carrier'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngcarrier === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngcarrier', $event)">{{ row.ng_finalinspngcarrier }}</td>
                <td v-if="header === 'FI Bubbles'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngbubblesincoating === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngbubblesincoating', $event)">{{ row.ng_finalinspngbubblesincoating }}</td>
                <td v-if="header === 'FI FPCB'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngfpcb === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngfpcb', $event)">{{ row.ng_finalinspngfpcb }}</td>
                <td v-if="header === 'FI Connector'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngconnector === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngconnector', $event)">{{ row.ng_finalinspngconnector }}</td>
                <td v-if="header === 'FI Damaged rivets'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngdamagedrivets === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngdamagedrivets', $event)">{{ row.ng_finalinspngdamagedrivets }}</td>
                <td v-if="header === 'FI Bottom pins'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngbottompins === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngbottompins', $event)">{{ row.ng_finalinspngbottompins }}</td>
                <td v-if="header === 'FI QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngqrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_finalinspngqrcode', $event)">{{ row.ng_finalinspngqrcode }}</td>
                
                <td v-if="header === 'Fake Laser Marking Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lasermarkingsum === 0 }" @click="editCell(dbTableName, row.id, 'fn_lasermarkingsum', $event)">{{ row.fn_lasermarkingsum }}</td> 
                <td v-if="header === 'FLM Heat Staking'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lasermarkingheatstaking === 0 }" @click="editCell(dbTableName, row.id, 'fn_lasermarkingheatstaking', $event)">{{ row.fn_lasermarkingheatstaking }}</td>
                <td v-if="header === 'FLM QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lasermarkingqrcode === 0 }" @click="editCell(dbTableName, row.id, 'fn_lasermarkingqrcode', $event)">{{ row.fn_lasermarkingqrcode }}</td>
                
                <td v-if="header === 'Fake Middle Insp. Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_middleinspsum === 0 }" @click="editCell(dbTableName, row.id, 'fn_middleinspsum', $event)">{{ row.fn_middleinspsum }}</td> 
                <td v-if="header === 'FMI Rivet height'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_middleinsprivetheight === 0 }" @click="editCell(dbTableName, row.id, 'fn_middleinsprivetheight', $event)">{{ row.fn_middleinsprivetheight }}</td>
                <td v-if="header === 'FMI QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_middleinspqrcode === 0 }" @click="editCell(dbTableName, row.id, 'fn_middleinspqrcode', $event)">{{ row.fn_middleinspqrcode }}</td>

                <td v-if="header === 'Fake AOI Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_aoingsum === 0 }" @click="editCell(dbTableName, row.id, 'fn_aoingsum', $event)">{{ row.fn_aoingsum }}</td> 
                <td v-if="header === 'FA Soldering'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingsoldering === 0 }" @click="editCell(dbTableName, row.id, 'fn_aoingsoldering', $event)">{{ row.fn_aoingsoldering }}</td>
                <td v-if="header === 'FA Heat Staking pin'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingheatstaking === 0 }" @click="editCell(dbTableName, row.id, 'fn_aoingheatstaking', $event)">{{ row.fn_aoingheatstaking }}</td>
                <td v-if="header === 'FA Riveting'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingriveting === 0 }" @click="editCell(dbTableName, row.id, 'fn_aoingriveting', $event)">{{ row.fn_aoingriveting }}</td>
                <td v-if="header === 'FA QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingqrcode === 0 }" @click="editCell(dbTableName, row.id, 'fn_aoingqrcode', $event)">{{ row.fn_aoingqrcode }}</td>

                <td v-if="header === 'Fake Coating Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_coatingsum === 0 }" @click="editCell(dbTableName, row.id, 'fn_coatingsum', $event)">{{ row.fn_coatingsum }}</td> 
                <td v-if="header === 'FC Insufficient coating'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatinginsufficientcoating === 0 }" @click="editCell(dbTableName, row.id, 'fn_coatinginsufficientcoating', $event)">{{ row.fn_coatinginsufficientcoating }}</td>
                <td v-if="header === 'FC Bubbles'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatingbubbles === 0 }" @click="editCell(dbTableName, row.id, 'fn_coatingbubbles', $event)">{{ row.fn_coatingbubbles }}</td>
                <td v-if="header === 'FC Excessive coating'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatingexcessivecoating === 0 }" @click="editCell(dbTableName, row.id, 'fn_coatingexcessivecoating', $event)">{{ row.fn_coatingexcessivecoating }}</td>
                <td v-if="header === 'FC QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatingqrcode === 0 }" @click="editCell(dbTableName, row.id, 'fn_coatingqrcode', $event)">{{ row.fn_coatingqrcode }}</td>

                <td v-if="header === 'Fake EOL Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_eolsum === 0 }" @click="editCell(dbTableName, row.id, 'fn_eolsum', $event)">{{ row.fn_eolsum }}</td> 
                <td v-if="header === 'FE Open circuit'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eolopencircuit === 0 }" @click="editCell(dbTableName, row.id, 'fn_eolopencircuit', $event)">{{ row.fn_eolopencircuit }}</td>
                <td v-if="header === 'FE Over resistance'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eoloverresistance === 0 }" @click="editCell(dbTableName, row.id, 'fn_eoloverresistance', $event)">{{ row.fn_eoloverresistance }}</td>
                <td v-if="header === 'FE 2P connectors'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eol2pconnectors === 0 }" @click="editCell(dbTableName, row.id, 'fn_eol2pconnectors', $event)">{{ row.fn_eol2pconnectors }}</td>
                <td v-if="header === 'FE QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eolqrcode === 0 }" @click="editCell(dbTableName, row.id, 'fn_eolqrcode', $event)">{{ row.fn_eolqrcode }}</td>                

                <td v-if="header === 'Fake LQC Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lqcsum === 0 }" @click="editCell(dbTableName, row.id, 'fn_lqcsum', $event)">{{ row.fn_lqcsum }}</td> 
                <td v-if="header === 'FL Busbars'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lqcbusbars === 0 }" @click="editCell(dbTableName, row.id, 'fn_lqcbusbars', $event)">{{ row.fn_lqcbusbars }}</td>
                <td v-if="header === 'FL QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lqcqrcode === 0 }" @click="editCell(dbTableName, row.id, 'fn_lqcqrcode', $event)">{{ row.fn_lqcqrcode }}</td>

                <td v-if="header === 'Fake Final Insp. Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_finalinspsum === 0 }" @click="editCell(dbTableName, row.id, 'fn_finalinspsum', $event)">{{ row.fn_finalinspsum }}</td> 
                <td v-if="header === 'FFI Busbars'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_finalinspbusbar === 0 }" @click="editCell(dbTableName, row.id, 'fn_finalinspbusbar', $event)">{{ row.fn_finalinspbusbar }}</td>
                <td v-if="header === 'FFI QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_finalinspqrcode === 0 }" @click="editCell(dbTableName, row.id, 'fn_finalinspqrcode', $event)">{{ row.fn_finalinspqrcode }}</td>

                <td v-if="header === 'NG Comp. Sum'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_sum === 0 }" @click="editCell(dbTableName, row.id, 'nc_sum', $event)">{{ row.nc_sum }}</td>
                <td v-if="header === 'NG Comp. Carrier'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_carrier === 0 }" @click="editCell(dbTableName, row.id, 'nc_carrier', $event)">{{ row.nc_carrier }}</td>
                <td v-if="header === 'NG Comp. Busbar'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_busbar === 0 }" @click="editCell(dbTableName, row.id, 'nc_busbar', $event)">{{ row.nc_busbar }}</td>
                <td v-if="header === 'NG Comp. Connector'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_connector === 0 }" @click="editCell(dbTableName, row.id, 'nc_connector', $event)">{{ row.nc_connector }}</td>
                <td v-if="header === 'NG Comp. FPCB'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_fpcb === 0 }" @click="editCell(dbTableName, row.id, 'nc_fpcb', $event)">{{ row.nc_fpcb }}</td>

                <td v-if="header === 'Remark'" :key="index" @click="editCell(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCell(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCell(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCell(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCell(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCell(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCell(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCell(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
         <tr> <!--Shift 1 Subtotal-->
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{  shift1FnTotal }}</td>
          <td>{{ ((shift1FnTotal)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingassembly}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspsum}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspassembly}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingsum}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingriveting}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngopencircuit}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngoverresistance}}</td>
          <td>{{ sums.shiftSums[1].ng_eolng2pconnectors}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngbusbar}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngcarrier}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngfpcb}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngconnector}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngbottompins}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngbusbar}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngcarrier}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngfpcb}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngconnector}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngbottompins}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[1].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_middleinspsum}}</td>
          <td>{{ sums.shiftSums[1].fn_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[1].fn_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingsum}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingriveting}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingsum}}</td>
          <td>{{ sums.shiftSums[1].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingbubbles}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_eolsum}}</td>
          <td>{{ sums.shiftSums[1].fn_eolopencircuit}}</td>
          <td>{{ sums.shiftSums[1].fn_eoloverresistance}}</td>
          <td>{{ sums.shiftSums[1].fn_eol2pconnectors}}</td>
          <td>{{ sums.shiftSums[1].fn_eolqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_lqcsum}}</td>
          <td>{{ sums.shiftSums[1].fn_lqcbusbars}}</td>
          <td>{{ sums.shiftSums[1].fn_lqcqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_finalinspsum}}</td>
          <td>{{ sums.shiftSums[1].fn_finalinspbusbar}}</td>
          <td>{{ sums.shiftSums[1].fn_finalinspqrcode}}</td>
          <td>{{ sums.shiftSums[1].nc_sum}}</td>
          <td>{{ sums.shiftSums[1].nc_carrier}}</td>
          <td>{{ sums.shiftSums[1].nc_busbar}}</td>
          <td>{{ sums.shiftSums[1].nc_connector}}</td>
          <td>{{ sums.shiftSums[1].nc_fpcb}}</td>          
        </tr>
        <tr> <!--Shfit 2 Subtotal-->
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{  shift2FnTotal }}</td>
          <td>{{ ((shift2FnTotal)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingassembly}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspsum}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspassembly}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingsum}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingriveting}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngopencircuit}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngoverresistance}}</td>
          <td>{{ sums.shiftSums[2].ng_eolng2pconnectors}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngbusbar}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngcarrier}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngfpcb}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngconnector}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngbottompins}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngbusbar}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngcarrier}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngfpcb}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngconnector}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngbottompins}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[2].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_middleinspsum}}</td>
          <td>{{ sums.shiftSums[2].fn_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[2].fn_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingsum}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingriveting}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingsum}}</td>
          <td>{{ sums.shiftSums[2].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingbubbles}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_eolsum}}</td>
          <td>{{ sums.shiftSums[2].fn_eolopencircuit}}</td>
          <td>{{ sums.shiftSums[2].fn_eoloverresistance}}</td>
          <td>{{ sums.shiftSums[2].fn_eol2pconnectors}}</td>
          <td>{{ sums.shiftSums[2].fn_eolqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_lqcsum}}</td>
          <td>{{ sums.shiftSums[2].fn_lqcbusbars}}</td>
          <td>{{ sums.shiftSums[2].fn_lqcqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_finalinspsum}}</td>
          <td>{{ sums.shiftSums[2].fn_finalinspbusbar}}</td>
          <td>{{ sums.shiftSums[2].fn_finalinspqrcode}}</td>
          <td>{{ sums.shiftSums[2].nc_sum}}</td>
          <td>{{ sums.shiftSums[2].nc_carrier}}</td>
          <td>{{ sums.shiftSums[2].nc_busbar}}</td>
          <td>{{ sums.shiftSums[2].nc_connector}}</td>
          <td>{{ sums.shiftSums[2].nc_fpcb}}</td>     
        </tr>
        <tr> <!--4P8S Subtotal-->
         <th rowspan="4">Model</th>
          <td>4P8S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['4P8S'].target }}</td>
          <td>{{ sums.modelSums['4P8S'].input }}</td>
          <td>{{ sums.modelSums['4P8S'].ok }}</td>
          <td>{{ sums.modelSums['4P8S'].ng }}</td>
          <td>{{ ((sums.modelSums['4P8S'].ng ) / (sums.modelSums['4P8S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model4p8sFnTotal }}</td>          
          <td>{{ ((model4p8sFnTotal)/(sums.modelSums['4P8S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_sum}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_connector}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_fpcb}}</td>               
        </tr>
        <tr> <!--4P7S Subtotal-->
          <td>4P7S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['4P7S'].target }}</td>
          <td>{{ sums.modelSums['4P7S'].input }}</td>
          <td>{{ sums.modelSums['4P7S'].ok }}</td>
          <td>{{ sums.modelSums['4P7S'].ng }}</td>
          <td>{{ ((sums.modelSums['4P7S'].ng ) / (sums.modelSums['4P7S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model4p7sFnTotal }}</td>
          <td>{{ ((model4p7sFnTotal)/(sums.modelSums['4P7S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_sum}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_connector}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_fpcb}}</td>
        </tr>
        <tr> <!--3P10S Subtotal-->
          <td>3P10S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['3P10S'].target }}</td>
          <td>{{ sums.modelSums['3P10S'].input }}</td>
          <td>{{ sums.modelSums['3P10S'].ok }}</td>
          <td>{{ sums.modelSums['3P10S'].ng }}</td>
          <td>{{ ((sums.modelSums['3P10S'].ng ) / (sums.modelSums['3P10S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model3p10sFnTotal }}</td>
          <td>{{ ((model3p10sFnTotal)/(sums.modelSums['3P10S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_sum}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_connector}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_fpcb}}</td>
        </tr>
        <tr> <!--3P8S Subtotal-->
          <td>3P8S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['3P8S'].target }}</td>
          <td>{{ sums.modelSums['3P8S'].input }}</td>
          <td>{{ sums.modelSums['3P8S'].ok }}</td>
          <td>{{ sums.modelSums['3P8S'].ng }}</td>
          <td>{{ ((sums.modelSums['3P8S'].ng ) / (sums.modelSums['3P8S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model3p8sFnTotal }}</td>
          <td>{{ ((model3p8sFnTotal)/(sums.modelSums['3P8S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_sum}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_connector}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_fpcb}}</td>
        </tr>
        <tr> <!--Total-->
         <th colspan="3">Ford#1 Front Total</th>         
          <td>{{ sums.prtotalSums.target }}</td>
          <td>{{ sums.prtotalSums.input  }}</td>
          <td class="ok" >{{ sums.prtotalSums.ok }}</td>
          <td class="ng" >{{ sums.prtotalSums.ng }}</td>
          <td class="ng">{{ ((sums.prtotalSums.ng) / (sums.prtotalSums.input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ fnTotal }} </td>
          <td class="fn">{{ ((fnTotal )/(sums.prtotalSums.input) * 100).toFixed(1) }}%</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_lasermarkingsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingheatstaking}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingbusbar}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingassembly}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_middleinspsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinsprivetheight}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinspdamagedrivet}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinspassembly}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinspqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_aoingsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingsoldering}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingheatstaking}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingriveting}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_coatingngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingnginsufficientcoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingngcoatingheight}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingngexcessivecoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingngqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_eolngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolngopencircuit}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolngoverresistance}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolng2pconnectors}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolngqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_lqcngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngbusbar}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngcarrier}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngbubblesincoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngfpcb}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngconnector}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngdamagedrivets}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngbottompins}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_finalinspngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngbusbar}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngcarrier}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngbubblesincoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngfpcb}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngconnector}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngdamagedrivets}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngbottompins}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lasermarkingsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lasermarkingheatstaking}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lasermarkingqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_middleinspsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_middleinsprivetheight}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_middleinspqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_aoingsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingsoldering}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingheatstaking}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingriveting}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_coatingsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatinginsufficientcoating}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatingbubbles}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatingexcessivecoating}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatingqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_eolsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eolopencircuit}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eoloverresistance}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eol2pconnectors}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eolqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lqcsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lqcbusbars}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lqcqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_finalinspsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_finalinspbusbar}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_finalinspqrcode}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_sum}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_carrier}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_busbar}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_connector}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_fpcb}}</td>
        </tr>           
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall"> <!--QWall part-->
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader --> 
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCell(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index" ></td>
            <td v-if="header === 'Input'" :key="index" ></td>
            <td v-if="header === 'OK'" :key="index" @click="editCell(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" @click="editCell(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Laser Marking'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_lasermarking', $event)">{{ qw.ng_lasermarking }}</td>
            <td v-if="header === 'Middle Station'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_middlestation', $event)">{{ qw.ng_middlestation }}</td>
            <td v-if="header === 'AOI'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_aoi', $event)">{{ qw.ng_aoi }}</td>
            <td v-if="header === 'Coating'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_coating', $event)">{{ qw.ng_coating }}</td>
            <td v-if="header === 'EOL'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_eol', $event)">{{ qw.ng_eol }}</td>
            <td v-if="header === 'Final'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_final', $event)">{{ qw.ng_final }}</td>
          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCell(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Shift Name'" :key="index" @click="editCell(dtTableName, dt.id, 'shiftname', $event)">{{ dt.shiftname }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" :line-name="lineName"/>
      <dtModal v-if="showdtModal" @onClose="showdtModal = false; callSearchData();" :line-name="lineName"/>         
      <drModal v-if="showdrModal" @onClose="showdrModal = false; callSearchData();" :line-name="lineName"/> 
      <qwModal v-if="showqwModal" @onClose="showqwModal = false; callSearchData();" :line-name="lineName"/> 
      <rdModal v-if="showrdModal" @onClose="showrdModal = false; callSearchData();" :line-name="lineName"/> 
  </div>
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import { downloadExcel, editCellValue} from "../../utils.js";
import { searchData, calculateSums } from "../productionUtils.js";
import { targets } from "../../lineData.js";

import Modal from "./ford1fr_prm.vue";
import qwModal from "./ford1fr_qwm.vue";
import dtModal from "./ford1fr_prdtm.vue";
import drModal from "./ford1fr_prdrm.vue";
import rdModal from "./ford1fr_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },  

    methods: {
      downExcel () { downloadExcel([this.prTableData.data, this.dtTableData.data, this.drTableData.data, this.qwTableData.data], "Production Data.xlsx", [], ["PR Data", "DT Data", "DR Data", "QW Data"]);},
      editCell(tableName, id, header, event) {
          const currentValue = event.target.innerText;
          const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
          if (newValue !== null && newValue !== currentValue) {
              editCellValue(tableName, id, header, newValue, this.callSearchData);
          }
      },
    },  

    setup() {
        const lineName = "Ford 1 Front"  //설비 변경시 필수 교체        
        const selectedLine = targets.find(target => target.name === lineName);
        if (!selectedLine) { throw new Error(`Line name "${lineName}" not found in targets`); }

        const dbTableName = selectedLine.pr;
        const dtTableName = selectedLine.dt;
        const drTableName = selectedLine.dr;
        const qwTableName = selectedLine.qw;
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'NG', "NG Rate", "Fake Sum", "Fake Rate",
                         "Laser Marking Sum", 'LM Heat Staking', 'LM Busbar', 'LM Assembly', 'LM QR code',
                         'Middle Insp. Sum', 'MI Rivet height', 'MI Damaged rivet', 'MI Assembly', 'MI QR code',
                         'AOI NG Sum', 'AOI Soldering', 'AOI Heat Staking', 'AOI Riveting', 'AOI QR code', 
                         'Coating NG Sum', 'Insfficient Coating', 'Coating height', 'Excessive coating', 'Coating QR code',
                         'EOL NG Sum', 'Open circuit', 'Over resistance', '2P connectors', 'EOL QR code',
                         'LQC NG Sum', 'LQC Busbars', 'LQC Carrier', 'LQC Bubbles', 'LQC FPCB', 'LQC Connector', "LQC Damaged Rivets", 'LQC Bottom pins', 'LQC QR code',
                         'Final Insp. Sum', 'FI Busbars', 'FI Carrier', 'FI Bubbles', 'FI FPCB', 'FI Connector', 'FI Damaged rivets', 'FI Bottom pins', 'FI QR code',
                         'Fake Laser Marking Sum', 'FLM Heat Staking', 'FLM QR code',
                         'Fake Middle Insp. Sum', 'FMI Rivet height', 'FMI QR code',
                         'Fake AOI Sum', 'FA Soldering', 'FA Heat Staking pin', 'FA Riveting', 'FA QR code', 
                         'Fake Coating Sum', 'FC Insufficient coating', 'FC Bubbles', 'FC Excessive coating', 'FC QR code',
                         'Fake EOL Sum', 'FE Open circuit', 'FE Over resistance', 'FE 2P connectors', 'FE QR code',
                         'Fake LQC Sum', 'FL Busbars', 'FL QR code',
                         'Fake Final Insp. Sum', 'FFI Busbars', 'FFI QR code',
                         'NG Comp. Sum', 'NG Comp. Carrier', 'NG Comp. Busbar', 'NG Comp. Connector', 'NG Comp. FPCB',
                         'Remark', 'Work Order', 'Supervisor', 'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Shift Name', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], });   

        //Repeat Data part
        const showrdModal = ref(false);         
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, fn_lasermarkingsum, fn_middleinspsum, fn_aoingsum, fn_coatingsum, fn_eolsum, fn_lqcsum, fn_finalinspsum,) => {
          const input = ok + ng;
          const fakeng = fn_lasermarkingsum + fn_middleinspsum + fn_aoingsum + fn_coatingsum + fn_eolsum + fn_lqcsum + fn_finalinspsum
          let ngRate = 0;
          let fn_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          return { input, ngRate, fakeng, fn_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(d.ok, d.ng, d.fn_lasermarkingsum, d.fn_middleinspsum, d.fn_aoingsum, d.fn_coatingsum, d.fn_eolsum, d.fn_lqcsum, d.fn_finalinspsum );
            return { ...d, input, ngRate, fakeng, fn_ngRate, }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const callSearchData = async () => {
          try {
            const { sendDateData } = await sendDate();
            const tableNames = [dbTableName, dtTableName, drTableName, qwTableName];
            const result = await searchData(tableNames, sendDateData);
            
            prTableData.data = result.prTableData;
            dtTableData.data = result.dtTableData;
            drTableData.data = result.drTableData;
            qwTableData.data = result.qwTableData;
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        };

        //데이터 더하기 위한 Template, shift, model 설정
        const sumsTemplate = {
          target: 0, input: 0, ok: 0, ng: 0, 
          ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
          ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
          ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
          ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
          ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
          ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
          ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
          fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
          fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
          fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
          fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
          fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
          fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
          nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0,
        };

        const shiftKeys = [1, 2];         
        const modelKeys = ["4P8S", "4P7S", "3P10S", "3P8S"];

        const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys, qwItem.value,));
        //끝

        const fnTotal = computed(() => {
          return (
            sums.value.prtotalSums.fn_lasermarkingsum + sums.value.prtotalSums.fn_middleinspsum +
            sums.value.prtotalSums.fn_aoingsum + sums.value.prtotalSums.fn_coatingsum + 
            sums.value.prtotalSums.fn_eolsum + 
            sums.value.prtotalSums.fn_lqcsum + sums.value.prtotalSums.fn_finalinspsum            
          );
        });

        const shift1FnTotal = computed(() => {
          return (
            sums.value.shiftSums[1].fn_lasermarkingsum + sums.value.shiftSums[1].fn_middleinspsum +
            sums.value.shiftSums[1].fn_aoingsum + sums.value.shiftSums[1].fn_coatingsum + 
            sums.value.shiftSums[1].fn_eolsum + 
            sums.value.shiftSums[1].fn_lqcsum + sums.value.shiftSums[1].fn_finalinspsum 
          );
        });
        const shift2FnTotal = computed(() => {
          return (
            sums.value.shiftSums[2].fn_lasermarkingsum + sums.value.shiftSums[2].fn_middleinspsum +
            sums.value.shiftSums[2].fn_aoingsum + sums.value.shiftSums[2].fn_coatingsum + 
            sums.value.shiftSums[2].fn_eolsum + 
            sums.value.shiftSums[2].fn_lqcsum + sums.value.shiftSums[2].fn_finalinspsum                   
          );
        });        
        const model4p8sFnTotal = computed(() => {
          return (
            sums.value.modelSums['4P8S'].fn_lasermarkingsum + sums.value.modelSums['4P8S'].fn_middleinspsum +
            sums.value.modelSums['4P8S'].fn_aoingsum + sums.value.modelSums['4P8S'].fn_coatingsum + 
            sums.value.modelSums['4P8S'].fn_eolsum + 
            sums.value.modelSums['4P8S'].fn_lqcsum + sums.value.modelSums['4P8S'].fn_finalinspsum                   
          );
        });
        const model4p7sFnTotal = computed(() => {
          return (
            sums.value.modelSums['4P7S'].fn_lasermarkingsum + sums.value.modelSums['4P7S'].fn_middleinspsum +
            sums.value.modelSums['4P7S'].fn_aoingsum + sums.value.modelSums['4P7S'].fn_coatingsum + 
            sums.value.modelSums['4P7S'].fn_eolsum + 
            sums.value.modelSums['4P7S'].fn_lqcsum + sums.value.modelSums['4P7S'].fn_finalinspsum                   
          );
        });
        const model3p10sFnTotal = computed(() => {
          return (
            sums.value.modelSums['3P10S'].fn_lasermarkingsum + sums.value.modelSums['3P10S'].fn_middleinspsum +
            sums.value.modelSums['3P10S'].fn_aoingsum + sums.value.modelSums['3P10S'].fn_coatingsum + 
            sums.value.modelSums['3P10S'].fn_eolsum + 
            sums.value.modelSums['3P10S'].fn_lqcsum + sums.value.modelSums['3P10S'].fn_finalinspsum                   
          );
        });
        const model3p8sFnTotal = computed(() => {
          return (
            sums.value.modelSums['3P8S'].fn_lasermarkingsum + sums.value.modelSums['3P8S'].fn_middleinspsum +
            sums.value.modelSums['3P8S'].fn_aoingsum + sums.value.modelSums['3P8S'].fn_coatingsum + 
            sums.value.modelSums['3P8S'].fn_eolsum + 
            sums.value.modelSums['3P8S'].fn_lqcsum + sums.value.modelSums['3P8S'].fn_finalinspsum                   
          );
        });        

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel, 
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
                 showrdModal,
                 fnTotal, shift1FnTotal, shift2FnTotal, model4p8sFnTotal, model4p7sFnTotal, model3p10sFnTotal, model3p8sFnTotal,
                 };        
    },
}
</script>

<style scoped src="../0_formStyle/line/style_line_pr.css"></style>