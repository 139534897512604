<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Cleaning Cycle</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">                        
            <div class="form-group">
              <label for="line">Line:</label>
              <select class="line-name-input" id="line" v-model="formInputs.line">
                <option v-for="line in uniqueLines" :key="line">{{ line }}</option>          
              </select> 
            </div>                  
            <div class="form-group">
              <label for="sation">Station:</label>              
              <select id="line" v-model="formInputs.station">
                <option v-for="station in uniqueStations" :key="station">{{ station }}</option>          
              </select> 
            </div>
            <div class="form-group">
              <label for="part">Part:</label>
              <input type="text" id="part" v-model="formInputs.part" />
            </div>
            <div class="form-group">
              <label for="cycle">Cycle:</label>
              <select id="cycle" v-model="formInputs.cycle">
                <option>1 day</option><option>1 week</option><option>2 week</option><option>3 week</option>
                <option>1 month</option><option>2 month</option><option>3 month</option><option>6 month</option><option>1 year</option>
              </select>
            </div>  
            <div class="form-group">
              <label for="week">Week:</label>
              <select id="week" v-model="formInputs.week">
                <option>mon</option><option>tue</option><option>wed</option><option>thu</option>
                <option>fri</option><option>sat</option><option>sun</option><option></option>
              </select>
            </div>  
            <div class="form-group">
              <label for="row">Row:</label>
              <input type="text" id="row" v-model="formInputs.row_no" />                
            </div>              
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>   
            {{ saveStatus }}     
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>    
import { ref, watch } from 'vue';  
import axios from 'axios';   
import { fetchData, getColumnMapping, makeFormData, useFormInputs } from "../utils.js"; 
  
export default {    
  props: { onSubmit: { type: Function, required: true }, dbTableName: { type: String, required: true } },            
  setup(props, { emit }) {           
    const saveStatus = ref(''); 
    const isProcessing = ref(false);      
    const uniqueLines = ref();
    const uniqueStations = ref();        
    
    const { formInputs, dataForm } = useFormInputs();
    
    const submitForm = async () => {
      if (!formInputs.line || !formInputs.station || !formInputs.cycle ) {
        alert("Please fill in all the fields: line, station and cycle .");
        return;
      }

      isProcessing.value = true; 
      saveStatus.value = 'Processing...';

      const columnMapping = getColumnMapping(undefined, formInputs);  
      const formData = makeFormData(undefined, formInputs, props.dbTableName, columnMapping); 
      
      try {
        await axios.post('/api/post', formData); 
        saveStatus.value = 'Completed'; 
      } catch (error) {          
        saveStatus.value = 'Error'; 
      } finally {
        isProcessing.value = false; 
      }
      
      closeModal();
    };

    const closeModal = () => { emit("onClose"); };

    const loadLines = async () => {
      const lines = await fetchData('machinedata', 'line');
      const excludedLines = ["EV2020 60FR", "EV2020 60RR", "EV2020 4060FR", "EV2020 4060RR",
        "Common", "Leader", "Maintenance", "Packing", "Production Logistic", "Technician",
        "2nd Factory Compressors", "2nd Factory Boilers","2nd Factory Dust collectors",
        "2nd Factory AHU","2nd Factory Transformer","1st Factory Compressor 1",
        "1st Factory Compressor 2","1st Factory Boiler 1","1st Factory Boiler 2",
        "1st Factory Dust collector 1","1st Factory Dust collector 2","1st Factory Dust collector 3",
        "1st Factory Dust collector 4","1st Factory NEW AHU","1st Factory OLD AHU",
        "1st Factory AHU SMT","1st Factory Transformer",
      ];
      uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
      uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines();     

    const loadStations = async (line) => { uniqueStations.value = await fetchData('machinedata', 'station', [{ column: 'line', value: line }]);};
    loadStations();      
    
    watch(() => formInputs.line, (newLine) => { loadStations(newLine); });

    return { formInputs, submitForm, closeModal, uniqueLines, uniqueStations, dataForm, saveStatus};
  },      
};
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 300px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 50px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }
</style>