<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Result Verification</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable">
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <th style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</th>                        
                <td>
                  <input class="dateInput" type="text" v-model="searchDate.year" />
                  <input class="dateInput" type="text" v-model="searchDate.month" />
                  <input class="dateInput" type="text" v-model="searchDate.day" />
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <th style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Shift</th> 
                <td>
                  <select id="line" v-model="formInputs.shift">
                    <option>1</option><option>2</option><option>3</option>
                  </select>              
                </td>     
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">
                <th style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Line</th> 
                <td>
                  <select id="line" v-model="formInputs.line">
                    <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                  </select>              
                </td>                
              </tr>          
              <tr style="border: 1px solid rgb(156, 156, 156);">
              <th style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Type</th>                  
                <td>
                  <select v-model="formInputs.type">
                    <option>Physical</option><option>SAP</option>
                  </select>                  
                </td>
              </tr>
              <tr style="border: 1px solid rgb(156, 156, 156);">  
                <th style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Quantity</th>                  
                <td>
                  <input v-model="formInputs.quantity" />                  
                </td>                
              </tr>
            </table><br>
           
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}          
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, ref,  } from 'vue'; 
  import axios from 'axios';   
  import { getCurrentDate, fetchLine, } from '../utils.js';
   
  export default {                
    setup(props, { emit }) {
  
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const dbTableName = "resultverification"            
        const uniqueLines = ref([]);  
         
        const formInputs = reactive({ shift: "", line: "", ok: "", });       
        
        const searchDate = reactive({ ...getCurrentDate(), });        
    
        const submitForm = async () => {
         
          isProcessing.value = true; 
          saveStatus.value = 'Processing...';                 

          const columnMapping = { year: 'year', month: 'month' , day: 'day', shift: 'shift', line: 'line', type: 'type', quantity: 'quantity', }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,            
              shift: formInputs.shift,
              line: formInputs.line,
              type: formInputs.type,             
              quantity: formInputs.quantity, 
            }
         };
         
          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error';
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };           

        const closeModal = () => {  emit("onClose");  };    

        const loadLines = async () => {
          const lines = await fetchLine();
          const excludedLines = ["EV2020 4060kW", "EV2020 60kW", "2nd Factory Compressors", "2nd Factory Boilers",
                "2nd Factory Dust collectors", "2nd Factory AHU", "2nd Factory Transformer", "1st Factory Compressor 1",
                "1st Factory Compressor 2", "1st Factory Boiler 1", "1st Factory Boiler 2", "1st Factory Dust collector 1",
                "1st Factory Dust collector 2", "1st Factory Dust collector 3", "1st Factory Dust collector 4", "1st Factory NEW AHU",
                "1st Factory OLD AHU", "1st Factory AHU SMT", "1st Factory Transformer", "Packing", "Production Logistic",
                "Maintenance", "Leader", "Technician", "Common",
          ];
          uniqueLines.value = lines.filter(line => !excludedLines.includes(line));
        }; loadLines();      
          
        return { searchDate, dbTableName, formInputs, submitForm, closeModal,                   
                  uniqueLines, saveStatus, isProcessing,                              
        }; 
    },     

    props: { onSubmit: { type: Function, required: true, }, }, 
  };
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 300px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  .readWindow[readonly] { background-color: #f0f0f0; width: 80px !important; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }
  select, input {width: 170px;}
  
  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; } 
  .itemTable tr {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .nameInput { margin-left: 5px; margin-right: 5px; width: 100px; } 
  .costInput { margin-left: 5px; margin-right: 5px; width: 350px; } 
  .qtyInput { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .unitSelect { margin-left: 5px; margin-right: 5px; width: 80px; } 
  .payConditionInput { margin-left: 5px; margin-right: 5px; width: 300px; } 
  .dateInput { width: 50px; size: 8px; margin-right: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }  
  .subDateInput { width: 50px; size: 8px; margin-left: 3px; margin-right: 3px; }  
  .titleInput { width: 663px; margin-left: 5px; }  
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;} 
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 10px; margin-right: 10px; padding: 0px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }
  .failurestandard {margin-left:10px; font-weight: bold};
</style>