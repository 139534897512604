import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import { targets,} from '../../lineData'; // Import the targets and tableNames from lineData.js

export const createPeriodReportUtils = (tableNames) => {
    const state = reactive({
        data: {},
        targetList: [],
        settings: [],
        showModal: ref(false),
        startYear: ref(''),
        startMonth: ref(''),
        startDay: ref(''),
        endYear: ref(''),
        endMonth: ref(''),
        endDay: ref(''),
    });

    tableNames.forEach(tableName => {
        state.data[tableName] = reactive({ data: [] });
    });

    const fetchData = async (tableGroup, dateRange) => {
        if (!dateRange || !dateRange.startYear || !dateRange.startMonth || !dateRange.startDay || !dateRange.endYear || !dateRange.endMonth || !dateRange.endDay) {
            throw new Error('Invalid date range provided');
        }

        const formData = {
            tableNames: tableGroup.join(','),
            startYear: dateRange.startYear,
            startMonth: dateRange.startMonth,
            startDay: dateRange.startDay,
            endYear: dateRange.endYear,
            endMonth: dateRange.endMonth,
            endDay: dateRange.endDay
        };

        try {
            const res = await axios.get('/api/searchPeriod', { params: formData });
            const responseData = res.data;

            responseData.forEach(tableData => {
                const { tableName, data } = tableData;
                state.data[tableName].data = data || [];                
            });
        } catch (error) {
            console.error("Error fetching table data:", error.message);
        }
    };

    const fetchAllData = async (dateRange) => {
        if (!dateRange) {
            throw new Error('Date range parameter is required for fetchAllData');
        }

        // 테이블 이름을 그룹으로 나누어 각 그룹별로 fetchData를 호출합니다.
        const tableGroups = [];
        for (let i = 0; i < tableNames.length; i += 12) {
            tableGroups.push(tableNames.slice(i, i + 12));
        }

        for (const tableGroup of tableGroups) {
            await fetchData(tableGroup, dateRange);
        }
    };

    const fetchTargets = async (dateRange) => {
        const formData = { tableName: 'failuretarget', year: dateRange.startYear };

        try {
            const res = await axios.get('/api/targetList', { params: formData });
            state.targetList = res.data;
        } catch (error) {
            console.error("Error fetching target data:", error.message);
        }
    };

    const fetchWorkList = async (dateRange) => {
        if (!dateRange || !dateRange.startYear || !dateRange.startMonth || !dateRange.startDay || !dateRange.endYear || !dateRange.endMonth || !dateRange.endDay) {
            throw new Error('Invalid date range provided');
        }

        const formData = {
            tableName: 'maintenanceworklist',
            startYear: dateRange.startYear,
            startMonth: dateRange.startMonth,
            startDay: dateRange.startDay,
            endYear: dateRange.endYear,
            endMonth: dateRange.endMonth,
            endDay: dateRange.endDay,
            type: 'failure'
        };

        try {
            const res = await axios.get('/api/searchPeriod', { params: formData });
            state.data['maintenanceworklist'] = reactive({ data: res.data });          
        } catch (error) {
            console.error(`Error fetching data for table maintenanceworklist:`, error.message);
        }
    };

    const sums = computed(() => {
        const sumsObject = {};

        targets.forEach(target => {
            if (target.pr) {
                const workMinutes = state.data[target.pr]?.data || [];
                sumsObject[target.pr] = { work_minute: 0 };
                workMinutes.forEach(row => {
                    sumsObject[target.pr].work_minute += row.work_minute;
                });
            }
            sumsObject[target.name] = { time: 0, count: 0 };
        });

        if (state.data['maintenanceworklist'] && state.data['maintenanceworklist'].data) {
            state.data['maintenanceworklist'].data.forEach(row => {
                const tableName = row.line;
                if (!sumsObject[tableName]) {
                    sumsObject[tableName] = { work_minute: 0, time: 0, count: 0 };
                }
                sumsObject[tableName].time += row.time || 0;
                sumsObject[tableName].count += 1;
            });     
        }      
       
        return sumsObject;
    });

    const calculateRowData = (targetName) => {
        const targetConfig = targets.find(t => t.name === targetName);
        if (!targetConfig) return {};

        const sumsObject = sums.value;
        
        // Handle EV2020 60kW and EV2020 4060kW work minute summing
        let prData = { work_minute: 0 };
        if (targetName === 'EV2020 60kW') {
            const ev2020_60frpr = sumsObject['ev2020_60frpr']?.work_minute || 0;
            const ev2020_60rrpr = sumsObject['ev2020_60rrpr']?.work_minute || 0;
            prData.work_minute = ev2020_60frpr + ev2020_60rrpr;
        } else if (targetName === 'EV2020 4060kW') {
            const ev2020_4060frpr = sumsObject['ev2020_4060frpr']?.work_minute || 0;
            const ev2020_4060rrpr = sumsObject['ev2020_4060rrpr']?.work_minute || 0;
            prData.work_minute = ev2020_4060frpr + ev2020_4060rrpr;
        } else {
            prData = sumsObject[targetConfig.pr] || { work_minute: 0 };
        }

        const dtData = sumsObject[targetName] || { time: 0, count: 0 };
        const targetData = state.targetList.find(t => t.line === targetName) || { mttr: 0, mtbf: 0, failurerate: 0 };

        const workMinute = prData.work_minute;
        const failureTime = dtData.time;
        const failureCount = dtData.count;
        const mttrTarget = targetData.mttr;
        const actualMttr = failureCount ? (failureTime / failureCount).toFixed(1) : 0;
        const achieveMttr = actualMttr ? ((mttrTarget / actualMttr) * 100).toFixed(1) : "No Failure";
        const mtbfTarget = targetData.mtbf;
        const actualMtbf = failureCount ? (workMinute / failureCount).toFixed(1) : 0;
        const achievMmtbf = actualMtbf ? ((actualMtbf / mtbfTarget) * 100).toFixed(1) : "No Failure";
        const failureRateTarget = parseFloat(targetData.failurerate).toFixed(1);
        const actualFailureRate = workMinute ? ((failureTime / workMinute) * 100).toFixed(1) : 0;
        const achieveFailureRate = actualFailureRate ? ((failureRateTarget / actualFailureRate) * 100).toFixed(1) : 0;

        return {
            workMinute, failureTime, failureCount,
            mttrTarget, actualMttr, achieveMttr, mtbfTarget, actualMtbf, achievMmtbf,
            failureRateTarget, actualFailureRate, achieveFailureRate
        };
    };

    return {
        state,
        fetchAllData,
        fetchTargets,
        fetchWorkList,
        calculateRowData,
        sums
    };
};

export const settingValue = async () => {
    const settingData = reactive({ data: [] });
    const formData = { tableName: 'setting' };
    try {
        const res = await axios.get('/api/targetList', { params: formData });
        settingData.data = res.data;
    } catch (error) {
        console.error("Error fetching settings:", error.message);
    }
    return { settingData };
};
