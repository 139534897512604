<template>
    <div class="request">
      <h class="pagetitle">Material Request</h>         
      <Modal v-if="showModal" @onClose="showModal = false; callSearchData();"/>            
      <sendModal v-if="show_send_Modal" :row-id="selectedRowId" @onClose="show_send_Modal = false; callSearchData();" ></sendModal>        
      <form class="searchBar"> 
        <button class="searchButton" @click.prevent="setFilter('ALL')">All</button>
        <button class="searchButton" @click.prevent="setFilter('pink')">24H</button>
        <button class="searchButton" @click.prevent="setFilter('yellow')">48H</button>
        <button class="factoryButton" @click.prevent="setFilter('1st')">1st</button>
        <button class="factoryButton" @click.prevent="setFilter('2nd')">2nd</button>
        <button class="button" @click.prevent="showModal = true">+ Request</button>              
        <table clasee="explain">
          <tr><td class="colorexplain48"></td><td class="colorexplain48text">24~48 hours left based on Due Day, but not all requested materials have been sent.</td></tr>
          <tr><td class="colorexplain24"></td><td class="colorexplain24text">0~24 hours left based on Due Day, but not all requested materials have been sent.</td></tr>
          <tr><td class="colorexplaincon"></td><td class="colorexplaincontext">All requested materials have been sent, but production does not "CONFIRM"</td></tr>
        </table>              
        <div class="excelFile right-align">
        {{ fileName }}
        <label for="file-input" class="custom-file-upload">Choose file</label>
        <input id="file-input" type="file" @change="onFileChange" accept=".xlsx, .xls" style="display: none" />
        <button @click="uploadExcel('materialrequest')">List Upload</button> 
        <button class="custom-file-upload" @click.prevent="Excel">Down Load</button>           
        </div>       
      </form>          
        <P></P>     
        <div class="tables">
          <table id="productionResult" class="custom-table">            
          <tr>
            <th colspan="12">Planning</th>
            <th colspan="7">Logistic</th>
            <th>Production</th>
          </tr>
          <tr>          
            <th v-for="(header, index) in rqHeaders" :key="index">{{ header }}</th>          
          </tr>                      
            <tr v-for="row in item" :key="row.id" :class="rowColor(row)">
              <template v-for="(header, index) in rqHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'DEL'" :key="index"><button class="delButton" :class="{ disabledButton: !canDel() }"  type="submit"  @click="deleteRow('materialrequest', row.id)" :disabled="!canDel()" >DEL</button></td>
                <td v-if="header === 'Month'" :key="index" >{{ row.month  }}</td>    
                <td v-if="header === 'Day'" :key="index" >{{ row.day  }}</td>    
                <td v-if="header === 'Time'" :key="index" >{{ row.request_time  }}</td>                
                <td v-if="header === 'Factory'" :key="index" >{{ row.factory }}</td>                
                <td v-if="header === 'Material Code'" :key="index" >{{ row.materialcode  }}</td>                
                <td v-if="header === 'Material Name'" :key="index" >{{ row.materialname  }}</td>                
                <td v-if="header === 'Quantity'" :key="index" >{{ row.quantity }}</td>                
                <td v-if="header === 'Unit'" :key="index" >{{ row.unit }}</td>
                <td v-if="header === 'Due Month'" :key="index" >{{ row.due_month  }}</td>    
                <td v-if="header === 'Due Day'" :key="index" >{{ row.due_day  }}</td>    
                <td v-if="header === 'Due Time'" :key="index" >{{ row.due_time  }}</td>                  
                <td v-if="header === 'Detail'" :key="index" class="detail-cell" >{{ row.detail }}</td>                 
                <td v-if="header === 'Send'" :key="index" ><button class="sendButton" type="submit"   @click="openSendModal(row)">SEND</button> </td>                   
                <td v-if="header === 'Send Month'" :key="index" >{{ row.send_month  }}</td>    
                <td v-if="header === 'Send Day'" :key="index" >{{ row.send_day  }}</td>    
                <td v-if="header === 'Send Time'" :key="index" >{{ row.send_time  }}</td>    
                <td v-if="header === 'Send Quantity'" :key="index" >{{ row.send_quantity }}</td>    
                <td v-if="header === 'Send Unit'" :key="index" >{{ row.send_unit }}</td>    
                <td v-if="header === 'Send Detail'" :key="index" class="detail-cell" >{{ row.send_detail }}</td>    
                <td v-if="header === 'Confirm'" :key="index"><button class="confirmButton" :class="{ disabledButton: !canConfirm(row) }"  type="submit"  @click="handleClick(row)" :disabled="!canConfirm(row)" >CONFIRM</button></td>
              </template>
          </tr>
    </table> 
  </div>
</div>
</template>

  <script>    
    import axios from "axios";
    import readXlsxFile from 'read-excel-file';
    import { ref, reactive, computed, onMounted } from 'vue';
    import Modal from "./requestModal.vue";
    import sendModal from "./sendModal.vue";
    import * as XLSX from "xlsx";
    import { saveAs } from 'file-saver';
  
    export default {
      components: { Modal, sendModal },
    
      data() {
        return {
          excelFile: null,
          fileName: "",          
          columnMapping: {            
            year: 'year',
            month: 'month',
            day: 'day',
            request_time: 'request_time',
            factory: 'factory',
            materialcode: 'materialcode',
            materialname: 'materialname',
            quantity: 'quantity',
            unit: 'unit',
            due_year: 'due_year',
            due_month: 'due_month',
            due_day: 'due_day',
            due_time: 'due_time',
            detail: 'detail',
            send_year: 'send_year',
            send_month: 'send_month',
            send_day: 'send_day',
            send_time: 'send_time',
            send_quantity: 'send_quantity',
            send_unit: 'send_unit',
            confirm: 'confirm',
            confirm_year: 'confirm_year',
            confirm_month: 'confirm_month',
            confirm_day: 'confirm_day',
            confirm_time: 'confirm_time',
          }
        }
      },

      methods: {

        handleClick(row) {
          this.confirmSend(row);
          this.callSearchData();
        },

        openSendModal(row) {
          this.selectedRowId = row.id;
          this.show_send_Modal = true;
        },

          //현재 날짜로 기본 설정 -시작-
        getCurrentDate() {
          let today = new Date();        
          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();  
          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        },
        getCurrentTime() {
          let today = new Date();
          const adjustedHour = String(today.getHours()).padStart(2, '0');
          const adjustedMinute = String(today.getMinutes()).padStart(2, '0');
          return `${adjustedHour}:${adjustedMinute}`;
        },  

        canConfirm(row) {          
          const isQuantityValid = row.send_quantity >= row.quantity;          
          const currentUser = this.$route.meta.currentUser;
          const isUserValid = currentUser && (currentUser.id === 1 || currentUser.id === 2 
                                              || currentUser.id === 3 || currentUser.id === 4
                                              || currentUser.id === 5 || currentUser.id === 6
                                              || currentUser.id === 7 || currentUser.id === 12       
                                              || currentUser.id === 13   
                                             );         
          return isQuantityValid && isUserValid;
        },

        canDel() {                    
          const currentUser = this.$route.meta.currentUser;
          const isUserValid = currentUser && (currentUser.id === 1 || currentUser.id === 14 );         
          return isUserValid;
        },

        /*
        canConfirm(row) {
         return row.send_quantity >= row.quantity;
        },*/         
        
      async confirmSend(row) {  
        if (this.canConfirm(row)) {
        console.log('confirmSend called with row:', row);
        
        const formData = {
          id: row.id,
          tableName: 'materialrequest',
          columnMapping: this.columnMapping,
          values: {
            year: row.year,
            month: row.month,
            day: row.day,                
            request_time: row.request_time,                     
            factory: row.factory,                             
            materialcode: row.materialcode,            
            materialname: row.materialname,    
            quantity: row.quantity,
            unit: row.unit,
            due_year: row.due_year,
            due_month: row.due_month,
            due_day: row.due_day,
            due_time: row.due_time, 
            detail: row.detail,                   
            send_year: row.send_year,
            send_month: row.send_month,
            send_day: row.send_day,
            send_time: row.send_time, 
            send_quantity: row.send_quantity,
            send_unit: row.send_unit, 
            send_detail: row.send_detail, 
            confirm: 'Confirmed', 
            confirm_year: this.getCurrentDate().year, 
            confirm_month: this.getCurrentDate().month, 
            confirm_day: this.getCurrentDate().day, 
            confirm_time: this.getCurrentTime(),           
          }
        };

        const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

          if (hasUndefinedValue) {
            console.error('Undefined value found in formData.values:', formData.values);
            return;
          }

          formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
          console.log('formData:', formData);
              console.log('Mapping:', this.columnMapping);
          try {
              const response = await axios.put(`/api/materialput/${row.id}`, formData);
              console.log('Server response:', response);
              console.log('Data updated successfully');          
            } catch (error) {
              console.error('Error updating data:', error);
            }
        }
      },

      onFileChange(event) {
        this.excelFile = event.target.files ? event.target.files[0] : null;
        this.fileName = this.excelFile ? this.excelFile.name : "";
        },
        async uploadExcel(tableName) {
        if (!this.excelFile) {
            alert('Please select an Excel file.');
            return;
        }

        try {
            const rows = await readXlsxFile(this.excelFile);
            const columns = rows[0];
            const data = rows.slice(1);

            console.log('column :', columns.join(', '));
            data.forEach((row, index) => {
            console.log(`value row${index + 1} :`, row.join(', '));
            });

            console.log('Sending data to API:', { data: rows, tableName: tableName });
            const response = await axios.post('/api/upload-excel', {
            data: rows,
            tableName: tableName,
            });

            console.log(response);
            alert('File uploaded successfully.');
        } catch (error) {
            console.error(error);
            alert('Error uploading the file.');
        }
      },
    },
      setup() {
        const rqTableName = "materialrequest";  //searchData에 있는 값도 바꿔야 함
        const rqTableData = reactive({ data: [], });  
        const showModal = ref(false);
        const show_send_Modal = ref(false);
        const rqHeaders = ['DEL', 'Month','Day', 'Time', 'Factory', 'Material Code', 'Material Name', 'Quantity',
                           'Unit', 'Due Month', 'Due Day', 'Due Time', 'Detail', 'Send', 'Send Month', 'Send Day', 'Send Time', "Send Quantity", 
                           "Send Unit", "Send Detail", "Confirm", ]; 
    
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });
  
        const searchData = async (tableName) => {      
            const formData = {
              tableName: tableName,
              values: {}
            };

            console.log('Requesting data with:', formData);
  
           axios.get('/api/materialRequest', { params: { ...formData, } })
              .then((res) => {                
                switch (tableName) {  
                  case 'materialrequest':
                    rqTableData.data = res.data;
                    break;
                }
              });
          };

          const filter = ref("ALL");

        /*const setFilter = (value) => {
          filter.value = value;
        };*/
        const setFilter = (value) => {
          if (value === '1st') {
            filter.value = '1';
          } else if (value === '2nd') {
            filter.value = '2';
          } else {
            filter.value = value;
          }
        };

        const rowColor = (row) => {
          const currentDate = new Date();
          const rowDate = new Date(
            currentDate.getFullYear(),
            row.due_month - 1,
            row.due_day,
            ...row.due_time.split(":")
          );
          const twentyFourHours = 24 * 60 * 60 * 1000;
          const fortyEightHours = 48 * 60 * 60 * 1000;

          const timeDifference = rowDate - currentDate;

          if (row.send_quantity < row.quantity) {
            if (timeDifference <= twentyFourHours) {
              return "pink";
            } else if (timeDifference <= fortyEightHours) {
              return "yellow";
            }
          } else if (row.send_quantity >= row.quantity && row.confirm !== "Confirmed") {
            return "pending";
          }
          return "";
        };

        /*
        const item = computed(() => {
          return rqTableData.data.filter(row => {
            if (filter.value === 'ALL') {
              return true;
            } else {
              return rowColor(row) === filter.value;
            }
          });
        });
        */
        const item = computed(() => {
          return rqTableData.data.filter(row => {
            if (filter.value === 'ALL') {
              return true;
            } else if (filter.value === '1') {
              return row.factory === '1' || row.factory === 'LS1';
            } else if (filter.value === '2') {
              return row.factory === '2' || row.factory === 'LS2';
            } else {
              return rowColor(row) === filter.value;
            }
          });
        });

        const deleteRow = async (tableName, rowId) => {
        // Show a confirmation window and store the user's choice
        const userConfirmed = confirm("Are you sure you want to delete this row?");

        // If the user clicks "OK", proceed with the deletion
        if (userConfirmed) {
          try {
            await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });
            callSearchData();
          } catch (error) {
            console.error("Error deleting row:", error);
          }
        }
      };
        
        const callSearchData = () => { searchData(rqTableName); settingCall(); };

        const autoRefreshInterval = 60 * 1000; // 60 seconds (in milliseconds)

          const startAutoRefresh = () => {
              setInterval(() => {
                callSearchData();
              }, autoRefreshInterval);
          }

          startAutoRefresh();   

          const settingData = reactive({ data: [], });        

          const settingCall = async () => {         
              const tableName ='setting';
              const formData = { tableName: tableName };
              axios.get('/api/targetList', { params: formData })
              .then((res) => { settingData.data = res.data; })
              .catch((error) => { console.error("Error download excel data:", error.message); });
          };
          
          const Excel = () => {
            const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
            const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
            if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
            const wb = XLSX.utils.book_new();

            //각 Table 이름을 다 쳐줘야 한다. sheet로 나뉘어서 저장됨 
            //희한하게 header 부분은 본 vue 파일에 있는 각 설비의 headers 부분을 pr,dt,qw에 똑같이 넣으면 잘 나온다.                   
            const request_wsPr = XLSX.utils.json_to_sheet(rqTableData.data, { header: rqHeaders.value });          

            //각 sheet 이름을 다 쳐줘야 한다
            XLSX.utils.book_append_sheet(wb, request_wsPr, "request Data");          

            // Write the workbook and create a Blob
            const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
            const blob = new Blob([wbout], { type: "application/octet-stream" });

            // Save the file
            saveAs(blob, "Material Request.xlsx");
          };
          //설비 추가시 엑셀 다운로드 부분 열어서 수정해야 됨

          onMounted(() => {
            searchData(rqTableName); settingCall();
          });

         return { rqHeaders, showModal, show_send_Modal, searchDate, item, Excel, callSearchData,  rowColor, setFilter, deleteRow 
         }
        }
      
    }
  </script>
  
  <style scoped>
    .request { 
      flex: 1; 
      margin-left: 10px;
      position: relative; /* Add this line */
      transform: scale(1);
      transform-origin: top left;
    }    

     .button {
        padding: 5px 5px;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 14px;   
        font-weight: 600;
        margin-left: 10px;
        cursor: pointer;        
        transition: background-color 0.3s;    
        width: 150px;    
      }

      .sendButton {
        padding: 5px 5px;
        background-color: #0d6d00;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 11px;   
        font-weight: 600;
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;        
        transition: background-color 0.3s;        
      }
      .confirmButton {
        padding: 5px 5px;
        background-color: #1e00ca;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 11px;   
        font-weight: 600;
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;        
        transition: background-color 0.3s;        
      }

      .delButton {
        padding: 5px 5px;
        background-color: #0058bc;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 11px;   
        font-weight: 600;
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;        
        transition: background-color 0.3s;        
      }

      .disabledButton {
        background-color: gray;
        cursor: not-allowed;
      }

      .searchBar {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        margin-left: 20px;
        width: 100%;
      }
      .searchBar > *:not(:last-child) {
        margin-right: 5px;
      }

      .searchBar select, 
      .searchBar input
        {
        width : 100px;  
        margin-right: 5px;
        padding : 5px;
      }

      label {
        font-size: 14px;
        font-weight: 600;
        margin-right: 8px;
      }



td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    position: sticky;
    top: 0;    
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}

.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}
.detail-cell {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;  
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #347003;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;  
  margin-right : 10px;
  margin-left : 10px;
}

.custom-file-upload:hover {
  background-color: #009625;
}

.excelFile {
  display: flex;
  align-items: center;  
  justify-content: flex-end;
  width: 600px;
  padding: 0 10px;  
  margin-bottom: 10px;
}

.right-align {
  margin-left: auto;
}

button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .searchButton{
    padding: 5px 5px;
    background-color: #9c9c9c;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    width: 100px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .factoryButton{
    padding: 5px 5px;
    background-color: #04c595;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    width: 100px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .yellow { background-color: yellow; }
  .pink { background-color: pink; }
  .pending { background-color: rgb(125, 138, 250); }

  .colorexplain24 { background-color : pink; text-align: left; border: 1px}
  .colorexplain48 { background-color : yellow; text-align: left; border: 1px}
  .colorexplaincon { background-color : rgb(125, 138, 250); text-align: left; border: 1px}  
  .colorexplain24text { text-align: left; border: 1px; font-weight: 600;}
  .colorexplain48text { text-align: left; border: 1px; font-weight: 600;}
  .colorexplaincontext { text-align: left; border: 1px; font-weight: 600;}

</style>