<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Downtime</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
              </div>                                    
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option v-for="model in models" :key="model">{{ model }}</option>
              </select>           
            </div>            
            <div class="form-group">
              <label for="start_hour">Start time</label>
              <div class="time-inputs">
                  <label for="start_hour">H:</label>
                  <input type="number" id="start_hour" v-model="startHour" min="0" max="23" />
                  <label for="start_minute">M:</label>
                  <input type="number" id="start_minute" v-model="startMinute" min="0" max="59" />
              </div>
            </div>            
            <div class="form-group">
              <label for="end_hour">End time</label>
              <div class="time-inputs">
                  <label for="end_hour">H:</label>
                  <input type="number" id="end_hour" v-model="endHour" min="0" max="23" />
                  <label for="end_minute">M:</label>
                  <input type="number" id="end_minute" v-model="endMinute" min="0" max="59" />
              </div>
            </div>  
            <div class="time">
              <label for="time">Time:</label>
              <input type="number" id="time" v-model="formInputs.time" readonly class="readWindow" />              
            </div>
          </td>
          <td>          
            <div class="form-group">
              <label for="class1">CLASS 1:</label>
              <select id="class1" v-model="formInputs.class1">
                <option v-for="class1 in uniqueClass1" :key="class1">{{ class1 }}</option>                
              </select>                              
            </div>
            <div class="form-group">
              <label for="class2">CLASS 2:</label>
              <select id="class2" v-model="formInputs.class2">
                <option v-for="class2 in uniqueClass2" :key="class2">{{ class2 }}</option>                
              </select>   
            </div>                
            <div class="form-group">
              <label for="class3">CLASS 3:</label>
              <select id="station" v-model="formInputs.class3">
                <option v-for="station in uniqueStations" :key="station">{{ station }}</option>
              </select>  
            </div>
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="detail">Detail:</label>
              <textarea id="detail" v-model="formInputs.detail" rows="4" cols="25"></textarea>              
            </div>          
          </td>
          </tr>
          </table>                          
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>            
            {{ saveStatus }}           
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>

<script>
import { useForm } from './0_formStyle/modal/form_dt_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
<style scoped src="./0_formStyle/modal/style_dt_modal.css"></style>