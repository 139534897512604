<template>
  <div class="planning">
    <div class="act">
      <form class="searchBar">
        <button class="nav-button" @click.prevent="fetchDataForPrevWeek">Previous week</button>
        <span class="week-number">{{ currentDateAndWeek }}</span>
        <button class="nav-button" @click.prevent="fetchDataForNextWeek">Next week</button>   
        <div class="file-upload-group">
          <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
          <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
          <span class="fileName">{{ fileName }}</span>   
          <button class="listuploadbutton" @click.prevent="upExcel('weekplan')">Upload</button>
          <button class="downLoadButton" @click.prevent="downExcel">Down Load</button>                    
        </div>
      </form>
    </div>
    <div class="tables">   
      <table id="weekplanning" class="custom-table">
        <thead>
          <tr>         
            <th>Line</th>
            <th>Shift</th>
            <th v-for="(day, index) in days" :key="index">{{ day.toUpperCase() }}<br>{{ weekDates[index].toLocaleDateString() }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(line, lineIndex) in lineName" :key="lineIndex">
            <template v-for="(shift, shiftIndex) in dayNight" :key="shiftIndex">
              <tr>        
                <td v-if="shiftIndex === 0" :rowspan="dayNight.length">
                  <button class="modalbutton" @click="showModal(line)">{{ line }}</button>
                </td>
                <td :class="{'day': shift === 'Day', 'night': shift === 'Night'}">{{ shift }}</td>
                <td :class="{'day': shift === 'Day', 'night': shift === 'Night'}" v-for="(day, dayIndex) in days" :key="dayIndex">
                  <p>Model : {{ data[line]?.[shift]?.[day]?.model[day]?.value || '' }}</p>
                  <p>QTY: <button @click="openEditModal(data[line]?.[shift]?.[day]?.planquantity[day])">{{ data[line]?.[shift]?.[day]?.planquantity[day]?.value || '' }}</button></p>
                  <p>Work Order : {{ data[line]?.[shift]?.[day]?.workorder[day]?.value || '' }}</p>
                  <p>L: {{ data[line]?.[shift]?.[day]?.leader[day]?.value || '' }} / T: {{ data[line]?.[shift]?.[day]?.technician[day]?.value || '' }} / O: {{ data[line]?.[shift]?.[day]?.operator[day]?.value || '' }} / Q: {{ data[line]?.[shift]?.[day]?.qualityworker[day]?.value || '' }}</p>
                  <p>Notice : {{ data[line]?.[shift]?.[day]?.notice[day]?.value || '' }}</p>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>      
    </div>
    <!--<Modal v-if="isModalVisible" :selectedLine="selectedLine" @onClose="isModalVisible = false; callSearchData();" />-->
    <Modal v-if="isModalVisible" :selectedLine="selectedLine" @onClose="isModalVisible = false; " />
    <editModal v-if="show_edit_Modal" :row-id="selectedRowId" @onClose="show_edit_Modal = false; callSearchData();" />
  </div>
</template>

<script>
import { ref, onMounted, reactive, } from 'vue';
import Modal from './planning_modal.vue';
import editModal from './editModal.vue';
import { setupPlan } from './planUtils.js';
import { onFileChange, uploadExcel, downloadExcel } from "../../utils.js"; 

export default {
  components: { Modal, editModal },
  methods: {
    openEditModal(data) { this.selectedRowId = data.id;  this.show_edit_Modal = true; },
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
    downExcel() { downloadExcel(this.item, "Week Plan.xlsx"); },
  },
  data() { return { show_edit_Modal: false, selectedRowId: null, fileName: "", }; },
  setup() { 
    const dbTableName = "weekplan";
    const { days, currentDateAndWeek, db, item, createItem, searchData, weekDates, prevWeek, nextWeek, lineName, dayNight, dbCol } = setupPlan();

    const data = reactive({});

    const fetchDataForPrevWeek = () => { prevWeek(); callSearchData(); };
    const fetchDataForNextWeek = () => { nextWeek(); callSearchData(); };

    const initializeDataStructure = () => {
      const initialData = {};
      lineName.forEach(line => {
        initialData[line] = {};
        dayNight.forEach(shift => {
          initialData[line][shift] = {};
          days.forEach(day => {
            initialData[line][shift][day] = {};
            dbCol.forEach(col => {
              initialData[line][shift][day][col] = createItem(db[`${line} ${shift}`], col);
            });
          });
        });
      });
      Object.assign(data, initialData);
    };

    const isModalVisible = ref(false);
    const selectedLine = ref('');    

    const showModal = (lineName) => { selectedLine.value = lineName; isModalVisible.value = true; };
    const closeModal = () => { isModalVisible.value = false; };

    const callSearchData = async () => {
      try { await searchData(dbTableName); initializeDataStructure();}
      catch (error) { console.error("Error attendance:", error.message);}
    };

    onMounted(() => { callSearchData(); });    
    
    return { data, days, currentDateAndWeek, weekDates, lineName, dayNight, fetchDataForPrevWeek, fetchDataForNextWeek,      
            callSearchData, showModal, closeModal, isModalVisible, selectedLine, item
    };
  }
};
</script>

<style scoped>
.act { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; margin-left : 20px;}
.nav-button { background-color: #007bff; color: white; border: none; border-radius: 4px; cursor: pointer; font-weight: bold; padding: 0.1rem 0.5rem; transition: background-color 0.3s;}
.nav-button:hover { background-color: #0056b3;}
.week-number { font-size: 1.2rem; font-weight: bold; margin: 0 1rem;}
button { background-color: #9cfca1; color: rgb(0, 0, 0); border: none; border-radius: 4px; cursor: pointer; font-weight: bold; padding: 0.1rem 0.5rem; transition: background-color 0.3s;}
button:hover { background-color: #65af7b;}

th { position: sticky;  top: 0; background-color: white;  z-index: 10; background-color: rgb(230, 230, 230) ;
    border : 1px solid black; text-align : center; padding: 2px 10px; font-size : 12px;
}

td { background-color: rgb(255, 255, 255); border : 1px solid black; text-align : center;
    padding: 2px 10px; font-size : 10px;     
}
.tables { display: flex; flex-direction: column; align-items: stretch; padding-left: 20px; padding-right: 20px; height: 700px; overflow: auto;} 
.custom-table { font-size: 10px; width: 80%; overflow-x: auto; white-space: nowrap;} 

.day{ background-color: rgb(250, 245, 171); font-weight: bold; text-align: left; margin-left: 10px;}
.night{ background-color: rgb(181, 221, 240); font-weight: bold; text-align: left; margin-left: 10px;}

.searchBar { display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center; margin-bottom: 0px; margin-left: 20px;}
.excelFile { display: flex; align-items: center; justify-content: flex-end; width: 700px; padding: 0 10px; margin-bottom: 0px; }

.file-upload-group { display: flex; align-items: center; margin-left: 10px;}
.custom-file-upload {
  display: inline-block;  padding: 4px 12px;  cursor: pointer;
  background-color: #c49105;  color: #ffffff; margin-left: 10px;
  border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
}
.custom-file-upload:hover { background-color: #f3a359; }
.listuploadbutton {
  padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px;
  font-size: 14px; font-weight: 600; margin-left: 10px; cursor: pointer; transition: background-color 0.3s;        
}
.modalbutton{
  padding: 5px 5px; background-color: #b4b4b4; color: #000000; border: none; border-radius: 4px;
  font-size: 14px; font-weight: 600; margin-left: 0px; cursor: pointer; transition: background-color 0.3s;        
}
.downLoadButton {
  display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
  border-radius: 4px; font-size: 14px; transition: background-color 0.3s;  margin-right : 10px; margin-left : 10px;
}
.downLoadButton:hover { background-color: #009625;}
</style>
