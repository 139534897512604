<template>
    <div class="maxpowerdb">            
        <h class="pagetitle">LS Attendance List</h>
        <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">                
                <div class="start">
                    <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                    <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                    <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
                </div>
                <div class="separator">~</div>
                <div class="end">
                    <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                    <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                    <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
                </div>
                <div class="searchtarget">
                    Company :
                    <select class="searchtargetselect" v-model="oscompany" placeholder="OS Company">
                        <option>LSCP</option>
                        <option></option>
                    </select>
                </div>
                <div class="searchTd">
                    <button type="submit" @click="callSearchData">Search</button>                        
                </div>
                <div class="searchTd">
                    <button class="custom-file-download" @click.prevent="Excel">Down Load</button>     
                </div>   
                <div class="searchTd">                        
                    <label for="file-input" class="custom-file-choose">Choose file</label>                
                    <input id="file-input" type="file" @change="onFileChange" accept=".xlsx, .xls" style="display: none" />
                    <span class="fileName">{{ fileName }}</span>                    
                </div>
                <div class="searchTd">
                    <button class="custom-file-upload" @click="uploadExcel('attendancels')">Upload</button>
                </div>                                       
            </div>      
        </form>   
        </div>     
        <div class="table">
            <table>
                <tr>
                    <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                </tr>                    
                <tr v-for="row in item" :key="row.id">                    
                    <td>{{ row.year }}</td>
                    <td>{{ row.month }}</td>
                    <td>{{ row.day }}</td>                    
                    <td>{{ row.oscompany }}</td>
                    <td>{{ row.employeenumber }}</td>                    
                    <td>{{ row.team }}</td> 
                    <td>{{ row.position }}</td>                   
                    <td>{{ row.name }}</td>
                    <td>{{ row.surname }}</td>
                    <td>{{ row.start_time_card }}</td>
                    <td>{{ row.end_time_card }}</td>
                    <td>{{ row.start_time_work }}</td>
                    <td>{{ row.end_time_work }}</td>
                    <td>{{ row.overtime_50 }}</td>
                    <td>{{ row.overtime_100 }}</td>
                    <td>{{ row.overtime_200 }}</td>
                    <td>{{ row.work_time }}</td>
                    <td>{{ row.latecometowork }}</td>
                    <td>{{ row.earlygotohome }}</td>
                    <td><button class="sendButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>                                                           
                </tr>
            </table>                        
        </div>  
        <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                                 
    </div>
    
</template>

<script>
import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import readXlsxFile from 'read-excel-file';

import modifyModal from "./attendanceModify.vue";

export default {  
    data() {        
        return {
        excelFile: null,
        fileName: "",
        };
    },

    methods: {
        openSendModal(row) {
            this.selectedRowId = row.id;
            this.showMoModal = true;
        },
        canClick() {            
            // Access currentUser from the $route object
            const currentUser = this.$route.meta.currentUser;
            console.log('currentUser', currentUser)
            if (currentUser) {
                return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 28;
            } else {
                return false;
            }
        },
        onFileChange(event) {
        this.excelFile = event.target.files ? event.target.files[0] : null;
        this.fileName = this.excelFile ? this.excelFile.name : "";
        },
        async uploadExcel(tableName) {
        if (!this.excelFile) {
            alert('Please select an Excel file.');
            return;
        }

        try {
            const rows = await readXlsxFile(this.excelFile);
            const columns = rows[0];
            const data = rows.slice(1);

            console.log('column :', columns.join(', '));
            data.forEach((row, index) => {
            console.log(`value row${index + 1} :`, row.join(', '));
            });

            console.log('Sending data to API:', { data: rows, tableName: tableName });
            const response = await axios.post('/api/upload-excel', {
            data: rows,
            tableName: tableName,
            });

            console.log(response);
            alert('File uploaded successfully.');
        } catch (error) {
            console.error(error);
            alert('Error uploading the file.');
        }
        },     
    },

    components: { modifyModal },        

    setup() {        
        const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const oscompany = ref();
        const dbTableName = 'attendancels';     
        const showMoModal = ref(false);        
        const headers = ['Year', 'Month', 'Day', 'Company', 'Employee Number', 'Team', 'Position', 'First Name', 'Surname', 'Start_card', 'End_card', 'Start_work', 'End_work',
                         'Overtime_50', 'Overtime_100', 'Overtime_200', 'Work_time', 'Late for work','Leave early', 'Modify', ];

        const dbData = reactive({ data: [], });
        
        const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, oscompany.value]; return {sendDateData};};     
        const searchData = async (tableName) => {
            const sendDateData = await sendDate();
            console.log('Requesting data with:', {
                tablename: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endtYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],
                oscompany: sendDateData.sendDateData[6],
            });
            const formData = {        
                tableName: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],
                oscompany: sendDateData.sendDateData[6],
            };  
            console.log('Requesting data with:', formData);
            axios.get('/api/searchPeriod', { params: {...formData, },})
                .then((res) => {
                    dbData.data = res.data.sort((a, b) => {
                        if (a.month < b.month) return -1; if (a.month > b.month) return 1;  
                        if (a.day < b.day) return -1; if (a.day > b.day) return 1;  
                        if (a.oscompany < b.oscompany) return -1; if (a.oscompany > b.oscompany) return 1;    
                        if (a.team < b.team) return -1; if (a.team > b.team) return 1;                                                                                            
                        if (a.name < b.name) return -1; if (a.name > b.name) return 1;

                        return 0;
                    });
                    filterLatestData();
                });
        }

        const settingData = reactive({ data: [], });      

        const callSearchData = async () => {         
            const tableName = 'setting';
            const formData = { tableName: tableName };

            try {
                const res = await axios.get('/api/targetList', { params: formData });
                settingData.data = res.data;

                const attendanceSetting = settingData.data.find(s => Object.hasOwn(s, 'attendance'));  
                const isAttendanceEnabled = attendanceSetting && attendanceSetting.attendance === 1;
                if (!isAttendanceEnabled) {
                    alert("Attendance is currently disabled.");
                    return;
                }

                searchData(dbTableName);                           
            
            } catch (error) {
                console.error("Error attendance:", error.message);
            }
        };               

        const filterLatestData = () => {
            const latestDataMap = dbData.data.reduce((acc, item) => {
                const key = `${item.year}-${item.month}-${item.day}-${item.oscompany}-${item.employeenumber}`;                
                
                if (!acc[key] || acc[key].id < item.id) {
                    acc[key] = item;
                }
                return acc;
            }, {});            

            dbData.data = Object.values(latestDataMap);
        };

        const item = computed(() => {
            return dbData.data.map(d => {
                // start_time_work와 end_time_work를 분으로 변환
                const startTime = d.start_time_work.split(':');
                const endTime = d.end_time_work.split(':');
                const startMinutes = parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
                const endMinutes = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);

                // work_time 계산 (분 단위)
                const workMinutes = endMinutes - startMinutes;

                // work_time을 "hh:mm" 형식으로 변환
                const workHours = Math.floor(workMinutes / 60);
                const workRemainingMinutes = workMinutes % 60;
                const workTimeFormatted = `${workHours.toString().padStart(2, '0')}:${workRemainingMinutes.toString().padStart(2, '0')}`;

                // 계산된 work_time을 객체에 추가
                return { ...d, work_time: workTimeFormatted };
            });
            });

    

        const deleteRow = async (tableName, rowId) => {
            // Show a confirmation window and store the user's choice
            const userConfirmed = confirm("Are you sure you want to delete this row?");

            // If the user clicks "OK", proceed with the deletion
            if (userConfirmed) {
            try {
                await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });
                callSearchData();
            } catch (error) {
                console.error("Error deleting row:", error);
            }
            }
        };  
        
        const Excel = () => {
            const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
            const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
            if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
            const wb = XLSX.utils.book_new();

            //각 Table 이름을 다 쳐줘야 한다. sheet로 나뉘어서 저장됨 
            //희한하게 header 부분은 본 vue 파일에 있는 각 설비의 headers 부분을 pr,dt,qw에 똑같이 넣으면 잘 나온다.                   
            const request_wsPr = XLSX.utils.json_to_sheet(dbData.data, { header: headers.value });          

            //각 sheet 이름을 다 쳐줘야 한다
            XLSX.utils.book_append_sheet(wb, request_wsPr, "Attendance");          

            // Write the workbook and create a Blob
            const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
            const blob = new Blob([wbout], { type: "application/octet-stream" });

            // Save the file
            saveAs(blob, "Attendance.xlsx");
            };

        return { headers, item, callSearchData, deleteRow, dbTableName, showMoModal, 
                startYear, startMonth, startDay, endYear, endMonth, endDay, oscompany, sendDate, Excel,}        
    }}

</script>

<style scoped> 
    .searchtarget {border: 0px; }
    .searchtargetselect {width : 200px}
    .sendButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none;
                border-radius: 4px; font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px;
                margin-left: 5px; margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .confirmButton { padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
                    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .cancelButton { padding: 5px 5px; background-color: #f38f4d; color: #fff; border: none; border-radius: 4px; 
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px;
                    cursor: pointer; transition: background-color 0.3s;        
    }
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .table { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #1619bd; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload:hover { background-color: #1834d3; }

    .custom-file-choose { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #914702; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-choose:hover { background-color: #da7604; }

    .custom-file-download { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-download:hover { background-color: #009625; }

    button { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
    .start,.end { display: flex; flex-direction: row; border: 0px; }
    .start input,.end input { margin-right: 5px;}
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }
    .searchTd { border: 0px; }
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; }
    input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }
    .countTable {margin-left:20px; width: 50%; }
    .horizontal-form { margin-top: 10px; margin-bottom: 10px; margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>