<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify ledger</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>    
                  <div class="form-group">
                    <label for="name">Factory:</label>
                    <div>
                      <select id="line" v-model="formInputs.factory">                        
                        <option>1</option><option>2</option>
                      </select>   
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="name">Line:</label>
                    <div>
                      <select id="line" v-model="formInputs.line">
                        <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
                        <option>Transformer</option><option>Compressor</option><option>Boiler</option>              
                        <option>Dust Collector</option><option>AHU</option><option>Chiller</option>
                      </select>   
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="station">Station:</label>
                    <select id="station" v-model="formInputs.station">
                      <option v-for="station in uniqueStations" :key="station">{{ station }}</option>                      
                      <option>ALL</option><option></option>
                    </select>              
                  </div>   
                  <div class="form-group">
                    <label for="part">Part:</label>
                    <div>
                      <input type="text" id="part" v-model="formInputs.part" />                  
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="type">Image Upload:</label>                  
                    <div>           
                      <input type="file" @change="handleFileUpload('image')($event)" /><br>              
                      <button class="fileUploadButton" type="button" @click="fileUpload('image')">Image Upload</button>
                      <button class="fileUploadButton" type="button" @click="delFile('image')">Image Delete</button><br>
                      {{ fileSaveStatus.image }}               
                    </div><br>
                  </div>                  
                  <div class="form-group">
                    <label for="maker">Maker:</label>
                    <div>
                      <input type="text" id="maker" v-model="formInputs.maker" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="grade">Grade:</label>
                    <div>
                      <input type="text" id="grade" v-model="formInputs.grade" />                  
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="code">Code:</label>
                    <div>
                      <input type="text" id="code" v-model="formInputs.code" />                  
                    </div>
                  </div>   
                </td>
                <td>
                  <div class="form-group">
                    <label for="size">Size(W/D/H)(cm):</label>
                    <div>
                      <input type="text" id="size" v-model="formInputs.size" />                  
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="weight">Weight(kg):</label>
                    <div>
                      <input type="text" id="weight" v-model="formInputs.weight" />
                    </div>
                  </div>                  
                  <div class="form-group">
                    <label for="voltageone">Voltage 1:</label>
                    <div>
                      <input type="text" id="voltageone" v-model="formInputs.voltageone" />                  
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="phaseone">Phase 1:</label>
                    <div>
                      <input type="text" id="phaseone" v-model="formInputs.phaseone" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="voltagetwo">Voltage 2:</label>
                    <div>
                      <input type="text" id="voltagetwo" v-model="formInputs.voltagetwo" />                  
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="phasetwo">Phase 2:</label>
                    <div>
                      <input type="text" id="phasetwo" v-model="formInputs.phasetwo" />                  
                    </div>
                  </div>    
                  <div class="form-group">
                    <label for="frequency">Frequency(Hz):</label>
                    <div>
                      <input type="text" id="frequency" v-model="formInputs.frequency" />                  
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="airpressure">Air Pressure(bar):</label>
                    <div>
                      <input type="text" id="airpressure" v-model="formInputs.airpressure" />                  
                    </div>
                  </div>   
                </td>
                <td>
                  <div class="form-group">
                    <label for="installdate">Install Date(year/month/day):</label>
                    <div>
                      <input type="text" id="installdate" v-model="formInputs.installdate" />         
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="price">Price(PLN):</label>
                    <div>
                      <input type="text" id="price" v-model="formInputs.price" />         
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="tacttimee">Tace Time(sec):</label>
                    <div>
                      <input type="text" id="tacttime" v-model="formInputs.tacttime" />         
                    </div>
                  </div>     
                  <div class="form-group">
                    <label for="operator">Number of Operator:</label>
                    <div>
                      <input type="text" id="operator" v-model="formInputs.operator" />         
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="contents">Contents:</label>
                    <div>
                      <textarea id="contents" v-model="formInputs.contents" rows="4" cols="25"></textarea>      
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="history">History:</label>
                    <div>
                      <textarea id="history" v-model="formInputs.history" rows="4" cols="25"></textarea>      
                    </div>
                  </div>    
                  <div class="form-group">
                    <label for="type">File Upload:</label>                  
                    <div>           
                      <input type="file" @change="handleFileUpload('file')($event)" /><br>              
                      <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button>
                      <button class="fileUploadButton" type="button" @click="delFile('file')">File Delete</button><br>
                      {{ fileSaveStatus.file }}               
                    </div><br>
                  </div>                     
                 </td>
             </tr>
          </table>        
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';   
  import { fetchLine, fetchStation, uploadFile, fileDel} from "../../utils.js"; 
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "equipmentledger"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const selectedFiles = reactive({ image: null, file: null });     
        const fileSaveStatus = reactive({ image: '', file: '' });             
        const uniqueLines = ref([]);    
        const uniqueStations = ref([]); 
        const uploadType = 'equipmentLedger';        

        const formInputs = reactive({
          factory: "", id: "", line: "", station: "", part: "", image: "", imagepath: "", maker: "", grade: "", size: "", weight: "",
          voltageone: "", phaseone: "", voltagetwo: "", phasetwo: "", frequency: "", airpressure: "", installdate: "",
          price: "", tacttime: "", operator: "", contents: "", history: "", code: "", file: "", filepath: "",
        });

        const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

        const submitForm = async () => {
          
          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {
            factory: 'factory',
            line: 'line',
            station: 'station',
            part: 'part',
            image: 'image',
            imagepath: 'imagepath',
            maker: 'maker',
            grade: 'grade',
            size: 'size',
            weight: 'weight',
            voltageone: 'voltageone',
            phaseone: 'phaseone',
            voltagetwo: 'voltagetwo',
            phasetwo: 'phasetwo',
            frequency: 'frequency',
            airpressure: 'airpressure',
            installdate: 'installdate',
            price: 'price',
            tacttime: 'tacttime',
            operator: 'operator',
            contents: 'contents',
            history: 'history',
            code: 'code',
            file: 'file',
            filepath: 'filepath'    
          };

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              factory: formInputs.factory,
              line: formInputs.line,
              station: formInputs.station,
              part: formInputs.part,
              image: formInputs.image,
              imagepath: formInputs.imagepath,
              maker: formInputs.maker,
              grade: formInputs.grade,
              size: formInputs.size,
              weight: formInputs.weight,
              voltageone: formInputs.voltageone,
              phaseone: formInputs.phaseone,
              voltagetwo: formInputs.voltagetwo,
              phasetwo: formInputs.phasetwo,
              frequency: formInputs.frequency,
              airpressure: formInputs.airpressure,
              installdate: formInputs.installdate,
              price: formInputs.price,
              tacttime: formInputs.tacttime,
              operator: formInputs.operator,
              contents: formInputs.contents,              
              history: formInputs.history,
              code: formInputs.code,
              file: formInputs.file,
              filepath: formInputs.filepath
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData);    
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false;
          }         
              
         closeModal();
        };
        const closeModal = () => { emit("onClose"); };  
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id,
              formInputs.factory = rowData.factory,
              formInputs.line = rowData.line,
              formInputs.station = rowData.station,
              formInputs.part = rowData.part,
              formInputs.image = rowData.image,
              formInputs.imagepath = rowData.imagepath,
              formInputs.maker = rowData.maker,
              formInputs.grade = rowData.grade,
              formInputs.size = rowData.size,
              formInputs.weight = rowData.weight,
              formInputs.voltageone = rowData.voltageone,
              formInputs.phaseone = rowData.phaseone,
              formInputs.voltagetwo = rowData.voltagetwo,
              formInputs.phasetwo = rowData.phasetwo,
              formInputs.frequency = rowData.frequency,
              formInputs.airpressure = rowData.airpressure,
              formInputs.installdate = rowData.installdate,
              formInputs.price = rowData.price,
              formInputs.tacttime = rowData.tacttime,
              formInputs.operator = rowData.operator,
              formInputs.contents = rowData.contents,              
              formInputs.history = rowData.history,
              formInputs.code = rowData.code,
              formInputs.file = rowData.file,
              formInputs.filepath = rowData.filepath

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

      const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
      const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };
  
      watch(() => formInputs.line, (newLine) => { loadStations(newLine); });

      const fileUpload = (type) => { if (formInputs[`${type}path`]) { alert("First delete file");return; }
            uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);
      };  
      const delFile = (type) => fileDel(type, formInputs, fileSaveStatus);  

      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing,
                selectedFiles, handleFileUpload, fileSaveStatus, fileUpload, delFile, uniqueLines, uniqueStations
      };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   

.modal-wrapper { width: 100%;  max-width: 900px; }

.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}

.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

.modal-header h3 { font-size: 1.2rem; }

.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

label { font-weight: bold; }

input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

input[type="number"] { width: 100%;}  

.readWindow[readonly] { background-color: #f0f0f0; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s;
}

button[type="submit"]:hover { background-color: #45a049; }

table { table-layout: fixed; width: 100%; }

td { width: 20%; vertical-align: top; padding: 0 15px; }

.time-inputs { display: flex; gap: 5px; }
.time-inputs input[type="number"] { width: 50px; }
.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 50px; size: 8px; }

.radio { margin-left: 10px; margin-right: 5px; }
.fileUploadButton {
  background-color: #c6c6c6; color: rgb(0, 0, 0);
  padding: 5px 5px; margin-top: 10px; margin-right: 10px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
  display: inline-block; transition-duration: 0.4s;
}
</style>