<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Invest</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>    
                  <div class="form-group date-time-group">
                    <label>Year/Month/Day:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.year"  />
                      <input type="text" id="month" v-model="formInputs.month" />
                      <input type="text" id="day" v-model="formInputs.day" />                  
                    </div> 
                  </div>  
                  <div class="form-group">                    
                    <label for="category">Category:</label>
                    <div>
                      <select id="category" v-model="formInputs.category">
                        <option>Current</option><option>Expansion</option><option>IT</option>
                      </select>   
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="reason">Reason:</label>
                    <div>
                      <input type="text" id="reason" v-model="formInputs.reason" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="item">Item:</label>
                    <div>
                      <input type="text" id="item" v-model="formInputs.item" />          
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="Company">Company:</label>
                    <div>
                      <input type="text" id="company" v-model="formInputs.company" />                  
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="department">Department:</label>                  
                    <div>
                      <input type="text" id="department" v-model="formInputs.department" />                  
                    </div>
                  </div>  
                </td>
                <td>
                  <div class="form-group date-time-group">
                    <label>Report Date(Y/M/D):</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.reyear"  />
                      <input type="text" id="month" v-model="formInputs.remonth" />
                      <input type="text" id="day" v-model="formInputs.reday" />                  
                    </div> 
                  </div>    
                  <div class="form-group">
                    <label for="pr">PR Number:</label>
                    <div>
                      <input type="text" id="pr" v-model="formInputs.pr" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="po">PO Number:</label>
                    <div>
                      <input type="text" id="po" v-model="formInputs.po" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="asset">Asset Number:</label>
                    <div>
                      <input type="text" id="asset" v-model="formInputs.asset" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="carryoverprice">Carry Over Cost:</label>
                    <div>
                      <input type="number" id="carryoverprice" v-model="formInputs.carryoverprice" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="newprice">New Cost:</label>
                    <div>
                      <input type="number" id="newprice" v-model="formInputs.newprice" />                  
                    </div>
                  </div> 
                  <div class="form-group">
                    <label for="offer">Offer Cost:</label>
                    <div>
                      <input type="number" id="offer" v-model="formInputs.offer" />                  
                    </div>
                  </div> 
                </td>
                <td>
                  <div class="form-group">
                    <label for="type">File Upload:</label>                  
                    <div>           
                      <input type="file" @change="handleFileUpload('file')($event)" /><br>              
                      <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button><br>
                      {{ fileSaveStatus.file }}               
                    </div><br>
                  </div>  
                  <div class="form-group">
                    <label for="remark">Remark:</label>
                    <div>
                      <textarea id="remark" v-model="formInputs.remark" rows="4" cols="25"></textarea>      
                    </div>
                  </div>                       
                 </td>
             </tr>
          </table>       
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>    
import { reactive, ref,} from 'vue';  
import axios from 'axios';   
import { uploadFile,} from "../utils.js"; 
  
export default {                
  setup(props, { emit }) {     
      
      const dbTableName = "invest"    
      const saveStatus = ref(''); 
      const isProcessing = ref(false);         
      const selectedFiles = reactive({ image: null, file: null });     
      const fileSaveStatus = reactive({ image: '', file: '' });  
      const uploadType = 'invest';    
      
      const formInputs = reactive({
        year:"", month:"", day:"", category:"", reason:"", item:"", company:"", department:"", reyear: "", remonth: "", reday: "", reportdate:"", pr:"", po:"", asset:"",
        carryoverprice:"0", newprice:"0", offer:"0", file: "", filepath: "", remark: "",
        cashout1: "0", cashout2: "0", cashout3: "0", cashout4: "0", cashout5: "0",
      });       

      const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

      const submitForm = async () => {    
        
        if (!formInputs.year || !formInputs.item ) { alert("Please fill in all the fields: year and item"); return; }

        isProcessing.value = true; 
        saveStatus.value = 'Processing...';

        formInputs.reportdate = `${formInputs.reyear}-${formInputs.remonth}-${formInputs.reday}`;

        const columnMapping = {
          year: 'year',
          month: 'month',
          day: 'day',
          category: 'category',
          reason: 'reason',
          item: 'item',
          company: 'company',
          department: 'department',
          reportdate: 'reportdate',
          pr: 'pr',
          po: 'po',
          asset: 'asset',
          carryoverprice: 'carryoverprice',
          newprice: 'newprice',
          offer: 'offer',        
          file: 'file',
          filepath: 'filepath',
          remark: 'remark',
          cashout1: 'cashout1',
          cashout2: 'cashout2',
          cashout3: 'cashout3',
          cashout4: 'cashout4',
          cashout5: 'cashout5',
        };

        const formData = {
          tableName: dbTableName,
          columnMapping: columnMapping,
          values: {
            year: formInputs.year,
            month: formInputs.month,
            day: formInputs.day,
            category: formInputs.category,
            reason: formInputs.reason,
            item: formInputs.item,
            company: formInputs.company,
            department: formInputs.department,
            reportdate: formInputs.reportdate,
            pr: formInputs.pr,
            po: formInputs.po,
            asset: formInputs.asset,
            carryoverprice: formInputs.carryoverprice,
            newprice: formInputs.newprice,
            offer: formInputs.offer,        
            file: formInputs.file,
            filepath: formInputs.filepath,
            remark: formInputs.remark,  
            cashtou1: formInputs.cashout1,  
            cashtou2: formInputs.cashout2,  
            cashtou3: formInputs.cashout3,  
            cashtou4: formInputs.cashout4,  
            cashtou5: formInputs.cashout5,  
          }
        };
        
        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error'; 
        } finally {
          isProcessing.value = false; 
        }
        
        closeModal();
      };

      const closeModal = () => { emit("onClose"); };      
      
      const fileUpload = (type) => uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);  

    return {  dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing,
              handleFileUpload, fileUpload, fileSaveStatus, selectedFiles, 
    };
  },     

  props: {  onSubmit: { type: Function,  required: true, },}, 

};

</script>
    
  <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 800px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }  
    .radio { margin-left: 10px; margin-right: 5px; }
    .disabled { background-color: #ccc; cursor: not-allowed; }
    .fileUploadButton {
      background-color: #c6c6c6; color: rgb(0, 0, 0);
      padding: 5px 5px; margin-top: 10px; margin-right: 10px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
      display: inline-block; transition-duration: 0.4s;
    }
</style>