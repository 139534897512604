<template>
    <footer>&copy;<b>LSEVP Production WEB / Made by Kwon</b></footer>    
</template>

<style scoped>
footer {
    text-align: center;
    padding: 5px;
    border-top: 1px solid #eee;
    background: #f7f7f7;
    margin-top: 10px;
}
</style>