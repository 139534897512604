<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Spare</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>     
                  <div class="form-group">
                    <label for="type">Image Upload:</label>                  
                    <div>           
                      <input type="file" @change="handleFileUpload('image')($event)" /><br>              
                      <button class="fileUploadButton" type="button" @click="fileUpload('image')">Image Upload</button>
                      <button class="fileUploadButton" type="button" @click="delFile('image')">File Delete</button>
                      {{ fileSaveStatus.image }}               
                    </div><br>
                  </div>                  
                  <div class="form-group">
                    <label for="code">Item Code:</label>
                    <div>
                      <input type="text" id="code" v-model="formInputs.code" />                  
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="name">Item Name:</label>
                    <div>
                      <input type="text" id="name" v-model="formInputs.name" />                  
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="specification">Specification:</label>
                    <div>
                      <input type="text" id="specification" v-model="formInputs.specification" />                  
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="maker">Maker:</label>
                    <div>
                      <input type="text" id="maker" v-model="formInputs.maker" />
                    </div>
                  </div>  
                </td>
                <td>
                  <div class="form-group">
                    <label for="qty">Quantity:</label>
                    <div>
                      <input type="number" id="qty" v-model="formInputs.qty" />                  
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="limitqty">Limit Quantity:</label>
                    <div>
                      <input type="number" id="limitqty" v-model="formInputs.limitqty" />                  
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="factory">Factory:</label>
                    <div>
                      <input class="radio" type="radio" id="1st" value="1" v-model="formInputs.factory">
                      <label for="1st">1st</label>
                      <input class="radio"  type="radio" id="2nd" value="2" v-model="formInputs.factory">
                      <label for="2nd">2nd</label>
                    </div>
                  </div>   
                  <div class="form-group">
                    <label for="location">Location:</label>
                    <div>
                      <input type="text" id="location" v-model="formInputs.location" />         
                    </div>
                  </div>  
               </td>
               <td>                       
                <div class="form-group">
                  <label for="unitcostone">Unit Cost(PLN):</label>
                  <div>
                    <input type="text" id="unitcostone" v-model="formInputs.unitcostone" />                  
                  </div>
                </div>   
                <div class="form-group">
                  <label for="unitcosttwo">Unit Cost(EUR):</label>
                  <div>
                    <input type="text" id="unitcosttwo" v-model="formInputs.unitcosttwo" />                  
                  </div>
                </div>       
                <div class="form-group">
                  <label for="purchase">Purchase(on going):</label>
                  <div>
                    <select id="remark" v-model="formInputs.purchase">
                      <option>purchasing</option><option></option>
                    </select>
                  </div>
                </div>           
                <div class="form-group">
                  <label for="remark">Remark:</label>
                  <div>
                    <input type="text" id="remark" v-model="formInputs.remark" />
                  </div>
                </div>               
               </td>
             </tr>
          </table>  
          <div>
            <p class="codeTitle">Code Standard</p>
            <table class="codeTable">              
              <tr>
                <th>Location Code</th><th>Serial Number</th>
              </tr>
              <td>2 Digit<br>Office Supply: 10<br>Office Cabinet: 20<br>Production Cabinet : 30</td>
              <td>4 Digit</td>
            </table>
          </div>         
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';   
  import { uploadFile, fileDel } from '../../utils.js';  
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "productionspare"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const selectedFiles = reactive({ image: null, });     
        const fileSaveStatus = reactive({ image: '', });    
        const uploadType = 'productionSpare';          

        const formInputs = reactive({
            id: "", image: "", imagepath: "", code: "", name: "", specification: "", maker: "", qty: "0", factory: "", location: "", 
            limitqty: "", unitcostone: "", unitcosttwo: "", remark: "", purchase: "",
        });

        const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

        const submitForm = async () => {
          
          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {
            image: 'image',
            imagepath: 'imagepath',
            code: 'code',  
            name: 'name',            
            specification: 'specification',
            maker: 'maker',
            qty: 'qty',
            factory: 'factory',
            location: 'location',            
            limitqty: 'limitqty',            
            unitcostone: 'unitcostone',
            unitcosttwo: 'unitcosttwo',
            remark: 'remark', 
          purchase: 'purchase'
          };

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              image: formInputs.image,
              imagepath: formInputs.imagepath,
              code: formInputs.code,    
              name: formInputs.name,            
              specification: formInputs.specification,
              maker: formInputs.maker,
              qty: formInputs.qty,
              factory: formInputs.factory,
              location: formInputs.location,            
              limitqty: formInputs.limitqty,            
              unitcostone: formInputs.unitcostone,
              unitcosttwo: formInputs.unitcosttwo,
              remark: formInputs.remark, 
              purchase: formInputs.purchase
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData);    
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false;
          }         
              
         closeModal();
        };
        const closeModal = () => { emit("onClose"); };  
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id,
              formInputs.image = rowData.image,
              formInputs.imagepath = rowData.imagepath,
              formInputs.code = rowData.code,    
              formInputs.name = rowData.name,            
              formInputs.specification = rowData.specification,
              formInputs.maker = rowData.maker,
              formInputs.qty = rowData.qty,
              formInputs.limitqty = rowData.limitqty,
              formInputs.factory = rowData.factory,              
              formInputs.location = rowData.location,              
              formInputs.unitcostone = rowData.unitcostone,
              formInputs.unitcosttwo = rowData.unitcosttwo,              
              formInputs.remark = rowData.remark,
              formInputs.purchase = rowData.purchase   

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

          const fileUpload = (type) => { if (formInputs[`${type}path`]) { alert("First delete file");return; }
            uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);
      };  
          const delFile = (type) => fileDel(type, formInputs, fileSaveStatus);  

      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing,
                selectedFiles, handleFileUpload, fileSaveStatus, fileUpload, delFile
      };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   

.modal-wrapper { width: 100%;  max-width: 900px; }

.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}

.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

.modal-header h3 { font-size: 1.2rem; }

.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

label { font-weight: bold; }

input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

input[type="number"] { width: 100%;}  

.readWindow[readonly] { background-color: #f0f0f0; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s;
}

button[type="submit"]:hover { background-color: #45a049; }

table { table-layout: fixed; width: 100%; }

td { width: 20%; vertical-align: top; padding: 0 15px; }

.time-inputs { display: flex; gap: 5px; }
.time-inputs input[type="number"] { width: 50px; }
.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 50px; size: 8px; }

.radio { margin-left: 10px; margin-right: 5px; }
.fileUploadButton {
  background-color: #c6c6c6; color: rgb(0, 0, 0);
  padding: 5px 5px; margin-top: 10px; margin-right: 10px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
  display: inline-block; transition-duration: 0.4s;
}
.codeTitle {font-size: 16px; font-weight:bold; }
.codeTable {width: 50%; font-size: 14px; text-align: center; margin-bottom: 20px;}
.codeTable th {font-weight: bold; background-color: rgb(176, 176, 176); border-color: gray; border-width: 1px;}
.codeTable td {font-weight: 10; border-color: gray; border-width: 1px; }
</style>