<!-- 다운타임은 dbtablename과 linename 바꾸고, 내역 테이블 붙히고, class1,2,3 확인해서 바꾸면 끝남  -->
<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Downtime</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
            <tr>            
              <td>
              <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
              </div>                                     
              <div class="form-group">
                <label for="shift">Shift:</label>
                <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
              </div>
              <div class="form-group">
                <label for="shiftname">Shift Name:</label>
                <input type="text" id="shiftname" v-model="formInputs.shiftname" />
              </div>
              </td>
              <td>
              <div class="form-group">
                <label for="model">Model:</label>
                <select id="model" v-model="formInputs.model">            
                  <option>4P8S</option><option>4P7S</option><option>3P10S</option><option>3P8S</option>
                </select>              
              </div>            
              <div class="form-group">
                <label for="start_hour">Start time</label>
                <div class="time-inputs">
                    <label for="start_hour">H:</label>
                    <input type="number" id="start_hour" v-model="startHour" min="0" max="23" />
                    <label for="start_minute">M:</label>
                    <input type="number" id="start_minute" v-model="startMinute" min="0" max="59" />
                </div>
              </div>            
              <div class="form-group">
                <label for="end_hour">End time</label>
                <div class="time-inputs">
                    <label for="end_hour">H:</label>
                    <input type="number" id="end_hour" v-model="endHour" min="0" max="23" />
                    <label for="end_minute">M:</label>
                    <input type="number" id="end_minute" v-model="endMinute" min="0" max="59" />
                </div>
              </div>  
              <div class="time">
                <label for="time">Time:</label>
                <input type="number" id="time" v-model="formInputs.time" readonly class="readWindow" />              
              </div>
              </td>
              <td>
              <div class="form-group">
                <label for="class1">CLASS 1:</label>
                <select id="class1" v-model="formInputs.class1" @change="updateOptions()">            
                  <option>PRO</option><option>PRP</option><option>MFA</option><option>TPM</option>
                  <option>QAC</option><option></option>
                </select>              
              </div>
              <div class="form-group">
                <label for="class2">CLASS 2:</label>
                <select id="class2" v-model="formInputs.class2">            
                  <option v-for="option in class2Options" :key="option">{{ option }}</option>
                </select>
              </div>                
              <div class="form-group">
                <label for="class3">CLASS 3:</label>
                <select id="class3" v-model="formInputs.class3">
                  <option v-for="option in class3Options" :key="option">{{ option }}</option>
                </select>
              </div>
              <div class="form-group" style="display: flex; flex-direction: column;">
                <label for="detail">Detail:</label>
                <textarea id="detail" v-model="formInputs.detail" rows="4" cols="25"></textarea>              
              </div>
              <div class="form-group">
                <label for="note">Note:</label>
                <input type="text" id="note" v-model="formInputs.note" readonly class="readWindow" /> 
              </div>         
              </td>
            </tr>
          </table>         
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref} from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {
    //setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "ford1frdt"      
        const lineName ="ford1fr";    

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          shift: "",
          shiftname: "",
          model: "",
          start_time: "",
          end_time: "",
          time: "",
          class1: "",
          class2: "",        
          class3: "",          
          detail: "",      
          note: "",    
        });
        // -끝-  
        

        const calculateTimeDifference = (start_time, end_time) => {
          if (!start_time || !end_time) {
            return "";
          }
          const [startHour, startMinute] = start_time.split(":").map(Number);
          const [endHour, endMinute] = end_time.split(":").map(Number);

          const startDate = new Date();
          startDate.setHours(startHour, startMinute);

          const endDate = new Date();
          endDate.setHours(endHour, endMinute);

          let timeDifference = (endDate - startDate) / 1000 / 60;

          if (timeDifference < 0) {
            timeDifference += 24 * 60;
          }

          return timeDifference;
        };

        //시작시간에 따라서 shift 및 시간 계산값 자동 입력 -시작-
        watch(
          () => [formInputs.start_time, formInputs.end_time],
          ([start_time, end_time]) => {
            // Update shift based on start_time
            const startHour = start_time.split(":")[0];
            formInputs.shift = startHour >= 6 && startHour <= 17 ? 1 : 2;

            // Update time based on start_time and end_time
            formInputs.time = calculateTimeDifference(start_time, end_time);
          }
        );
        //시작시간에 따라서 shift 및 시간 계산값 자동 입력 -끝-

        //시작시간과 끝시간을 시,분으로 나눠서 입력할 수 있게 하는거 -시작-
        const startHour = computed({
          get: () => {
            const [hour] = formInputs.start_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.start_time.split(":");
            formInputs.start_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const startMinute = computed({
          get: () => {
            const [, minute] = formInputs.start_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.start_time.split(":");
            formInputs.start_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });

        const endHour = computed({
          get: () => {
            const [hour] = formInputs.end_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.end_time.split(":");
            formInputs.end_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const endMinute = computed({
          get: () => {
            const [, minute] = formInputs.end_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.end_time.split(":");
            formInputs.end_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });
      //시작시간과 끝시간을 시,분으로 나눠서 입력할 수 있게 하는거 -끝-
      
      //년.월.일을 지금 시작으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
        const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝

        //input, select에 있는 id 이름과 database table 이름을 맞추는거, 
        //그래서 필히 id 이름이 database table 이름과 동일해야 함
        //시작 
        const getColumnMapping = () => {
          const inputs = document.querySelectorAll("input, select, textarea");
          const mapping = {};

          inputs.forEach(input => {
            let inputId = input.id;

            if (inputId === "start_hour" || inputId === "start_minute") {
              inputId = "start_time";
            } else if (inputId === "end_hour" || inputId === "end_minute") {
              inputId = "end_time";
            }

            if (!mapping[inputId]) {
              if (inputId === "start_time") {
                mapping[inputId] = "start_time";
              } else if (inputId === "end_time") {
                mapping[inputId] = "end_time";
              } else {
                mapping[inputId] = input.id;
              }
            }
          });

          return mapping;
        };
        //끝 

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
            alert("Please fill in all the fields: year, month, day and model.");
            return;
          }

          const columnMapping = getColumnMapping();

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              shift: formInputs.shift,
              shiftname: formInputs.shiftname,
              model: formInputs.model,
              start_time: formInputs.start_time,
              end_time: formInputs.end_time,
              time: formInputs.time,
              class1: formInputs.class1,
              class2: formInputs.class2,
              class3: formInputs.class3,
              detail: formInputs.detail,     
              note: formInputs.note,     
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };
        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    

        const class2Options = ref([]); // Add this line to define class2Options as a ref
        const class3Options = ref([]); // Add this line to define class2Options as a ref

        //class1 에 따라 Cla22 선택 변경 -시작-
        const updateClass2Options = () => {
          if (formInputs.class1 === 'PRO') {
            class2Options.value = ['SCO','UCO','SCM','UCM','CMA','NMA','NOP','NWO', 'TES','END', 'STT', ''];
          } else if (formInputs.class1 === 'PRP') {
            class2Options.value = ['PRS', 'PRC', 'PST', 'ACR', 'WIR', 'IRT', 'EPN', 'CLE', 'BRP', 'IMP', ''];
          } else if (formInputs.class1 === 'MFA') {
            class2Options.value = ['POW', 'COL', 'SVA', 'SEC', 'NMO', 'PLC', 'HUM', 'UOR', 'SEN', 'SOF', 'OTH', ''];
          } else if (formInputs.class1 === 'TPM') {
            class2Options.value = ['MEC', 'ELE', 'AIR', 'UTI', 'NSP', 'PLC', 'POW', 'PTR', 'OTH', ''];
          } else if (formInputs.class1 === 'QAC') {
            class2Options.value = ['QPR', 'QMT', 'HUM', 'IMP', 'NMR', 'OTH',''];                    
          } else {
            class2Options.value = [''];
          }
        };

        const updateClass3Options = () => {
          if (formInputs.class1 === 'PRO') {
            class3Options.value = ['LAM', 'HET', 'FPC', 'RIS', 'RIV', 'BOT', 'MID', 'RHI', 'SOL', 'AOI', 'COA', 'UVC', 'EOL', 'COV', 'FIN', 'LTS', 'CON', ''];
          } else if (formInputs.class1 === 'QAC') {
            class3Options.value = ['LAM', 'HET', 'FPC', 'RIS', 'RIV', 'BOT', 'MID', 'RHI', 'SOL', 'AOI', 'COA', 'UVC', 'EOL', 'COV', 'FIN', 'LTS', 'CON', ''];
          } else if (formInputs.class1 === 'PRP') {
            class3Options.value = ['LAM', 'HET', 'FPC', 'RIS', 'RIV', 'BOT', 'MID', 'RHI', 'SOL', 'AOI', 'COA', 'UVC', 'EOL', 'COV', 'FIN', 'LTS', 'CON', ''];
          } else if (formInputs.class1 === 'TPM') {
            class3Options.value = ['LAM', 'HET', 'FPC', 'RIS', 'RIV', 'BOT', 'MID', 'RHI', 'SOL', 'AOI', 'COA', 'UVC', 'EOL', 'COV', 'FIN', 'LTS', 'CON', ''];
          } else if (formInputs.class1 === 'MFA') {
            class3Options.value = ['LAM', 'HET', 'FPC', 'RIS', 'RIV', 'BOT', 'MID', 'RHI', 'SOL', 'AOI', 'COA', 'UVC', 'EOL', 'COV', 'FIN', 'LTS', 'CON', ''];
          } else {
            class3Options.value = [''];
          }
        };

        //class1 에 따라 Cla22 선택 변경 -끝-

        const updateOptions = () => {
            updateClass2Options();
            updateClass3Options();
          };

        watch(() => formInputs.class1, () => {
          updateOptions();
          combineValues();
        });

        watch(() => formInputs.class2, () => {
          combineValues();
        });

        watch(() => formInputs.class3, () => {
          combineValues();
        });

        const combineValues = () => {
          let formattedLineName = lineName.replace(/\s+/g, "").toLowerCase();
          let class1 = formInputs.class1.toLowerCase();
          let class2 = formInputs.class2.toLowerCase();
          let class3 = formInputs.class3.toLowerCase();

          const row = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName);

          let item1 = "";
          let item2 = "";
          let item3 = "";

          if (row) {
            if (class1) {
              const matchingclass1 = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName && row.class1.toLowerCase() === class1);
              if (matchingclass1) {
                item1 = matchingclass1.item1;
              }
            }

          if (class2) {
            const matchingclass2 = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName && row.class2.toLowerCase() === class2);
            if (matchingclass2) {
              item2 = matchingclass2.item2;
            }
          }

          if (class3) {
            const matchingclass3 = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName && row.class3.toLowerCase() === class3);
            if (matchingclass3) {
              item3 = matchingclass3.item3;
            }
          }
        }
        formInputs.note = `${item1}_${item2}_${item3}`.replace(/(^_|_$)/g, '');
      };

      const data = ref([
      //Ford#1 Front
        {line_name: 'ford1fr', class1: 'PRO', item1: 'Production', class2: 'SCO', item2: 'Scheduled Change Order', class3: 'LAM', item3: 'Laser Marking'},
        {line_name: 'ford1fr', class1: 'PRP', item1: 'Production Process', class2: 'UCO', item2: 'Unscheduled Change Order', class3: 'HET', item3: 'Heat Stacking'},
        {line_name: 'ford1fr', class1: 'MFA', item1: 'Machine Failure', class2: 'SCM', item2: 'Scheduled Changover', class3: 'FPC', item3: 'FPCB Assembly'},
        {line_name: 'ford1fr', class1: 'TPM', item1: 'Maintenance', class2: 'UCM', item2: 'Unscheduled Changover', class3: 'RIS', item3: 'Rivet Supply'},
        {line_name: 'ford1fr', class1: 'QAC', item1: 'Quality Control', class2: 'CMA', item2: 'Change Material', class3: 'RIV', item3: 'Rivering'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'NMA', item2: 'No Material', class3: 'BOT', item3: 'Bottom Heatstacking'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'NOP', item2: 'No Operator', class3: 'MID', item3: 'Middle Inspection'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'NWO', item2: 'No Work order', class3: 'RHI', item3: 'Rivet Height Inspection'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'TES', item2: 'Tests', class3: 'SOL', item3: 'SOldering'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'END', item2: 'End of production', class3: 'AOI', item3: 'AOI'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'QPR', item2: 'Production block', class3: 'COA', item3: 'Coating'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'QMT', item2: 'Material block', class3: 'UVC', item3: 'UV Coating'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'HUM', item2: 'Human Mistake', class3: 'EOL', item3: 'EOL'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'IMP', item2: 'Incoming material problem', class3: 'COV', item3: 'Cover Inspection'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'NMR', item2: 'No Material Released', class3: 'FIN', item3: 'Final Insepction'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'OTH', item2: 'Other', class3: 'LTS', item3: 'LTS'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'PRS', item2: 'Program setup', class3: 'CON', item3: 'Conveyors'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'PRC', item2: 'Parameters change', class3: 'null', item3: 'null'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'PST', item2: 'Process setting', class3: 'null', item3: 'null'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'ACR', item2: 'Acrylic replacement', class3: 'null', item3: 'null'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'WIR', item2: 'Solder wire replacement', class3: 'null', item3: 'null'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'IRT', item2: 'Iron tip replacement', class3: 'null', item3: 'null'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'EPN', item2: 'EOL change the pin', class3: 'null', item3: 'null'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'CLE', item2: 'Cleaning machine', class3: 'null', item3: 'null'},
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'BRP', item2: 'Barcode Problem', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'IMP', item2: 'Incoming material problem', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'MEC', item2: 'Mechanical damage', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'ELE', item2: 'Electrical problem', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'AIR', item2: 'Pneumatic problem', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'UTI', item2: 'Utility', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'NSP', item2: 'No spare parts', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'PLC', item2: 'PLC logic problem', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'POW', item2: 'No Power', class3: 'null', item3: 'null'},       
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'PTR', item2: 'Part replacement', class3: 'null', item3: 'null'},                
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'COL', item2: 'Collision', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'SVA', item2: 'Servo Alarm', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'SEC', item2: 'Server crash', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'NMO', item2: 'No memory', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'HUM', item2: 'Human mistake', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'UOR', item2: 'Unable to origin', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'SEN', item2: 'Sensors problem', class3: 'null', item3: 'null'},        
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'SOF', item2: 'Software problem', class3: 'null', item3: 'null'},                
        {line_name: 'ford1fr', class1: 'null', item1: 'null', class2: 'STT', item2: 'Start of Production', class3: 'null', item3: 'null'},                 
      ]);

          
          return { searchDate, dbTableName, formInputs, submitForm, closeModal, lineName,
                  calculateTimeDifference, startHour, startMinute, endHour, endMinute,
                  class2Options, class3Options, updateOptions, combineValues};

        },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },            
    }, 
  };
  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 1000px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 100px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>