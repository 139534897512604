<template>
  <div class="container">        
    <div class="RequestLog">
      <h class="pagetitle">Movement Log</h>        
      <form @submit.prevent="sendDate()" class="searchBar">
          <table>  <!--검색 및 엑셀 버튼 -->
              <tr>
                  <td class="start">
                      <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
                      <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
                      <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
                  </td>
                  <td class="separator">~</td>
                  <td class="end">
                      <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
                      <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
                      <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
                  </td>
                  <td class="materialcode">
                      <input type="text" id="code" v-model="materialCode" placeholder="Material Code" />
                  </td>
                  <td class="searchTd">
                      <button type="submit" @click="callSearchData">Search</button>                      
                  </td>
                  <td class="searchTd">
                      <button class="moveButton" @click="moshowModal = true">Movement</button>   
                  </td>            
                  <td class="searchTd">
                      <button class="excelDownload" @click="downExcel">Excel</button>                                              
                  </td>
              </tr>
          </table>      
      </form>
    </div>            
    <div class="tables">
        <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
        <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
              <td v-if="header === 'Year'" :key="index" >{{ row.year  }}</td>    
              <td v-if="header === 'Month'" :key="index" >{{ row.month  }}</td>    
              <td v-if="header === 'Day'" :key="index" >{{ row.day  }}</td>    
              <td v-if="header === 'From'" :key="index" >{{ row.fromlocation  }}</td>                
              <td v-if="header === 'To'" :key="index" >{{ row.tolocation }}</td>                
              <td v-if="header === 'Material Code'" :key="index" >{{ row.materialcode  }}</td>                
              <td v-if="header === 'Material Name'" :key="index" >{{ row.materialname  }}</td>                
              <td v-if="header === 'Quantity'" :key="index" >{{ row.quantity }}</td>                
              <td v-if="header === 'Unit'" :key="index" >{{ row.unit }}</td>    
              <td v-if="header === 'Batch'" :key="index" >{{ row.batch }}</td>    
              <td v-if="header === 'Confirm'" :key="index" class="list" :class="{ 'gray-background': row.confirm == 'Done' }">
                <button class="confirmButton" :class="{ 'disabledButton': row.confirm == 'Done' || !canConfirmClick() }" type="submit" @click="handleClickReal(row)" :disabled="!canConfirmClick()">Confirm</button></td>
              <td v-if="header === 'SAP'" :key="index" class="list" :class="{ 'gray-background': row.sap == 'Done' }">
                <button class="confirmButton" :class="{ 'disabledButton': row.sap == 'Done' || !canConfirmClick() }" type="submit" @click="handleClickSAP(row)" :disabled="!canConfirmClick()">SAP</button></td>  
              <td v-if="header === 'DEL'" :key="index" ><button :class="['del', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>              
              <td v-if="header === 'Remark'" :key="index" >{{ row.remark }}</td>    
            </template>              
        </tr>
  </table>    
  </div>
  <moModal  v-if="moshowModal" @onClose="moshowModal = false; callSearchData();"/>        
</div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import { deleteRow, downloadExcel} from "../../utils.js"; 

import moModal from "./stockMovement.vue";

export default {
 components: { moModal }, 

  data() {
    return {    
      columnMapping: {            
        year: 'year', month: 'month', day: 'day', fromlocation: 'fromlocation',
        tolocation: 'tolocation', materialcode: 'materialcode',
        materialname: 'materialname', unit: 'unit', quantity: 'quantity', 
        batch: 'batch', confirm: 'confirm', sap: 'sap' , remark: 'remark'
      }
    }
  },

methods: {
  handleClickReal(row) { this.confirmSendReal(row); this.callSearchData(); },
  handleClickSAP(row) { this.confirmSendSAP(row); this.callSearchData(); },  
  canConfirmClick() {                    
          const currentUser = this.$route.meta.currentUser;
          const isUserValid = currentUser && (currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 4 || currentUser.id === 12 || currentUser.id === 13 || currentUser.id === 17 || currentUser.id === 18 );         
          return isUserValid;
    },
  canDelClick() {    
      const currentUser = this.$route.meta.currentUser;      
      if (currentUser) {            
          return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 4 || currentUser.id === 12 || currentUser.id === 17 ;
      } else {
          return false;
      }
  },
  async confirmSendReal(row) {  
      if (this.canConfirmClick()) {
      console.log('confirmSend called with row:', row);      
      const formData = {
        id: row.id,
        tableName: 'stockmovement',
        columnMapping: this.columnMapping,
        values: {
          year: row.year,
          month: row.month,
          day: row.day,                                                
          fromlocation: row.fromlocation,                             
          tolocation: row.tolocation,            
          materialcode: row.materialcode,    
          materialname: row.materialname,
          unit: row.unit,
          quantity: row.quantity,
          batch: row.batch,
          confirm: "Done",    
          sap: row.sap,
          remark: row.remark     
        }
      };
      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

        if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
        }

        formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
        console.log('formData:', formData);
            console.log('Mapping:', this.columnMapping);
        try {
            const response = await axios.put(`/api/materialput/${row.id}`, formData);
            console.log('Server response:', response);
            console.log('Data updated successfully');          
          } catch (error) {
            console.error('Error updating data:', error);
          }
      }
    },
  async confirmSendSAP(row) {  
      if (this.canConfirmClick()) {
      console.log('confirmSend called with row:', row);      
      const formData = {
        id: row.id,
        tableName: 'stockmovement',
        columnMapping: this.columnMapping,
        values: {
          year: row.year,
          month: row.month,
          day: row.day,                                                
          fromlocation: row.fromlocation,                             
          tolocation: row.tolocation,            
          materialcode: row.materialcode,    
          materialname: row.materialname,
          unit: row.unit,
          quantity: row.quantity,
          batch: row.batch,
          confirm: row.confirm,    
          sap: "Done",     
          remark: row.remark             
        }
      };
      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

        if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
        }

        formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
        console.log('formData:', formData);
            console.log('Mapping:', this.columnMapping);
        try {
            const response = await axios.put(`/api/materialput/${row.id}`, formData);
            console.log('Server response:', response);
            console.log('Data updated successfully');          
          } catch (error) {
            console.error('Error updating data:', error);
          }
      }
    },  
  downExcel() { downloadExcel(this.item, "Movement Log.xlsx"); },
  delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
},

setup() {
  const moshowModal = ref(false); 
  const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const materialCode = ref();

  const dbTableName = 'stockmovement';  
  const headers = ['Year', 'Month','Day', 'From', 'To', 'Material Code', 'Material Name', 'Quantity', 'Unit', 'Batch', 'Confirm', 'SAP', 'DEL', 'Remark' ];    
  const tableData = reactive({ data: [], });      

  const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value , materialCode.value]; return {sendDateData};};     
  const searchData = async (tableName) => {
    const sendDateData = await sendDate();     
    const formData = {        
      tableName: tableName,
      startYear: sendDateData.sendDateData[0],
      startMonth: sendDateData.sendDateData[1],
      startDay: sendDateData.sendDateData[2],
      endYear: sendDateData.sendDateData[3],
      endMonth: sendDateData.sendDateData[4],
      endDay: sendDateData.sendDateData[5],
      materialCode: sendDateData.sendDateData[6],
    };      
    axios.get('/api/searchPeriod', { params: formData })
      .then((res) => { tableData.data = res.data; });
  };   

  const callSearchData = () => { searchData(dbTableName); };  
  const item = computed(() => {return tableData.data.map(d => {return { ...d,};});});  

  return {
    startYear, startMonth, startDay, endYear, endMonth, endDay, materialCode,
    headers, dbTableName, item,
    searchData, callSearchData, sendDate, moshowModal
  };
},
};
</script>

<style scoped>
 * { box-sizing: border-box;}

.container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: auto; margin-right: auto;}
.start,.end { display: flex; flex-direction: row; border: 0px;}
.materialcode {border: 0px;}
.start input,.end input,.materialcode input { margin-right: 5px;}

.separator { font-size: 24px; margin: 0 10px; border: 0px;}
.searchTd { border: 0px;}

input::placeholder { color: gray;}

label { font-size: 14px; font-weight: 600; margin-right: 8px;}

select,input[type="number"],input[type="text"] {
  padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
  font-size: 14px; width: 100%; max-width: 120px;
} 

button {
  padding: 5px 5px; background-color: #979797;
  color: #000000; border: none;
  border-radius: 4px; font-size: 14px;
  font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}
.del {
  padding: 4px 4px;
  background-color: #bfbebe;
  color: #000000; border: 10px; border-color: #000000;
  font-size: 10px; font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}

button:hover { background-color: #555555;}

.excelDownload {
  background-color: #347003;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
  padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
}
.excelDownload :hover { background-color: #009625; }

.RequestLog{ width: 500px;}
.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 20px; margin-left: 20px; 
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }

.searchBar select,.searchBar input{ width : 120px; margin-right: 5px; padding : 5px; } 

label { font-size: 14px; font-weight: 600; margin-right: 8px; }

td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }

th { 
  position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ;
  border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; 
}
.tables {
  display: flex; flex-direction: column; align-items: stretch;
  padding-left : 20px; padding-right : 20px;
}

table { width: 80%; }
.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; }
.disabledButton { background-color: gray !important; cursor: not-allowed !important; }
.confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.gray-background { background-color: gray;}  
.moveButton {padding: 5px 5px;  background-color: #3498db;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-right: 3px;
    cursor: pointer; transition: background-color 0.3s;
}

</style>